import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';

import { VehicleNewService } from 'src/app/service/vehicle-new.service';
// import { VehicleService } from 'src/app/service/vehicle.service';
import { ItineraryType, ServiceConfiguration } from 'src/app/config';
import { Agenda } from 'src/app/model/agenda.object';
import { DispatcherBoardAgendaObject } from 'src/app/model/dispatcher-board-agenda.object';
import { DateTools } from 'src/app/tools/DateTools';
import { Haversine } from 'src/app/tools/Haversine';
import { Itinerary } from 'src/app/model/itinerary.object';
import { WarehouseService } from 'src/app/service/warehouse.service';


@Component({
  selector: 'app-r-dispatcher-board-row',
  templateUrl: './r-dispatcher-board-row.component.html',
  styleUrls: ['./r-dispatcher-board-row.component.css'],
  providers: [ WarehouseService ]
})
export class RDispatcherBoardRowComponent implements OnInit, OnDestroy {
  
  private _subs: Array<Subscription> = [];
  private _showDays: Array<number> = [0,7]; // ServiceConfiguration.vehicle.dispatcher_board_days;
  private _agendaData: Array<any> = [];
  private _distanceAndTimeDiffs: Array<any> = [];

  private _reloadEventsSubs: Subscription;
  @Input() reloadEvents: Observable<boolean>;

  private _vehicle: any = null;
  @Input()
  public set vehicle(value: any) {
    if (value && value != this._vehicle) {
      this._vehicle = value;
      this._days = this.prepareDays();
      this.loadAgendaInterval();
      // NO NEED TO RELOAD vehicle
      // this._subs.push(
      //   this._vehicleService.getVehicleCache(this._vehicle.car_key, true).subscribe(
      //     vehicle => {
      //       if (vehicle) {
      //         this._vehicle = vehicle;
      //         this._days = this.prepareDays();
      //         this.loadAgendaInterval();
      //       }
      //     }
      //   )
      // );
    }
  }
  public get vehicle(): any {
    return this._vehicle;
  }

  private _loadingAgenda: boolean = false;
  public get loadingAgenda(): boolean {
    return this._loadingAgenda;
  }
  
  private _columnWidth = ServiceConfiguration.vehicle.dispatcher_board_day_width;
  get columnWidth(): string {
    return this._columnWidth;
  }

  private _hours = [
    [0, 1, 2, 3, 4, 5],
    [6, 7, 8, 9, 10, 11],
    [12, 13, 14, 15, 16, 17],
    [18, 19, 20, 21, 22, 23]
  ];
  get hours(): Array<Array<number>> {
    return this._hours;
  }

  private _days: Array<Date> = [];
  get days(): Array<Date> {
    return this._days;
  }

  private _currentDate: Date = new Date();
  get currentDate(): Date {
    return this._currentDate;
  }


  constructor(
    private _vehicleService: VehicleNewService,
    private _warehouseServ: WarehouseService,
    private _datePipe: DatePipe,
    private _changeDetector: ChangeDetectorRef,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    // https://stackoverflow.com/a/51074896
    this._reloadEventsSubs = this.reloadEvents.subscribe(
      () => {
        if (this._vehicle) {
          // reload agenda with possibly new obligation
          this._days = this.prepareDays();
          this.loadAgendaInterval();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(
      sub => sub.unsubscribe()
    );
    
    this._reloadEventsSubs.unsubscribe();
  }

  private loadAgendaInterval(): void {
    // load agendas for all vehicles separately
    let dates = this.getDates(this._showDays[0], this._showDays[1]);
    let tf: string = this._datePipe.transform(dates[0], 'yyyy-MM-dd');
    let tt: string = this._datePipe.transform(dates[1], 'yyyy-MM-dd');

    this._subs.push(
      this._vehicleService.getVehicleAgendaNoCache(this._vehicle, tf, tt).subscribe(
        vehicleWithAgenda => {
          if (vehicleWithAgenda) {
            // update agenda in vehicle
            this._vehicle = vehicleWithAgenda;
            this.buildAgendaData();
          }
        }
      )
    );
  }

  /********************************************************/
  /**** Getters ****/
  /********************************************************/
  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }
  
  get formattedLastVehicleLoad(): string {
    if (!this._vehicleService.lastLoadDate) {
        return '-';
    }
    return DateTools.formatLocaleString(this._vehicleService.lastLoadDate, '%hours:%minutes');
  }

  get boardWidth() {
    return (parseFloat(this.columnWidth) * this._days.length) + 'px';
  }

  get start(): Date {
    return this._days[0];
  }

  get end(): Date {
    return this._days[this._days.length - 1];
  }

  isWeekend(date: Date): boolean {
    return [0, 6].indexOf(date.getDay()) > -1;
  }

  isToday(date: Date): boolean {
    return date.toDateString() == this._currentDate.toDateString();
  }

  isThisHour(date: Date, hour?: number): boolean {
    return this.isToday(date) && this._currentDate.getHours() === (!isNaN(hour) ? hour : date.getHours());
  }

  getDayString(date: Date): string {
    return this._datePipe.transform(date, 'EEEE');
  }

  getDateString(date: Date): string {
    return DateTools.formatLocaleString(date, '%day.%month.%year');
  }
  
  private getDates(howMuch1: number, howMuch2: number): Array<Date> {
    let dates = [];
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(this._currentDate.getDate() + howMuch1);
    dates.push(date);
    date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(this._currentDate.getDate() + howMuch2);
    dates.push(date);
    return dates;
  }

  getDataByVehicle(): Array<DispatcherBoardAgendaObject> {
    if (!this._agendaData) {
      return [];
    }
    return this._agendaData;
  }

  getDiffsByVehicle(): Array<any> {
    if (!this._distanceAndTimeDiffs) {
      return [];
    }
    return this._distanceAndTimeDiffs;
  }

  private getAgendaLengthInDays(agenda: Agenda): number {
    let length = 0;
    if (agenda.itinerary && agenda.itinerary.length) {
      // let end = agenda.itinerary[agenda.itinerary.length - 1].arrival_time.getTime();
      let end = agenda.last_itinerary_time_with_limit;
      if (end > this._days[this._days.length - 1].getTime()) {
        end = this._days[this._days.length - 1].getTime();
      }
      // let start = agenda.itinerary[0].arrival_time.getTime();
      let start = agenda.day.getTime();
      if (start < this._days[0].getTime()) {
        start = this._days[0].getTime();
      }
      length = end - start;
      length = (length < 0 ? 0 : length) / 1000 / 60 / 60 / 24;
    }
    return length;
  }


  /********************************************************/
  /**** Data building ****/
  /********************************************************/
  private prepareDays(): Array<Date> {
    let dates: Array<Date> = [];
    for (let i = this._showDays[0]; i <= this._showDays[1]; i++) {
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(this._currentDate.getDate() + i);
      dates.push(date);
    }
    // last day -> increment hours to 23:59:59
    dates[dates.length - 1].setHours(23, 59, 59);
    return dates;
  }

  // main method for building
  private buildAgendaData() {
    this._agendaData = [];
    this._distanceAndTimeDiffs = [];
    let data = [];
    let start = this._days[0].getTime();
    let end = this._days[this._days.length - 1].getTime();

    if (this._vehicle.agenda) {
      // sort agendas according to arrival_time
      // vehicle.agenda.sort((a, b) => (a.day > b.day) ? 1 : -1);
      this._vehicle.agenda.sort(
        (a, b) => {
          if (a.day > b.day) {
            return 1;
          }
          else if (a.day < b.day) {
            return -1;
          }
          else {
            // same - prefer first created agenda
            if (a.obligation_key > b.obligation_key) {
              return 1;
            }
            return -1;
          }
          return 1;
        }
      );

      this._vehicle.agenda.forEach(
        agenda => {
          let value = new DispatcherBoardAgendaObject(agenda, this._vehicle);
          if (value.agenda.itinerary.length) {
            // let startAgenda = value.agenda.itinerary[0].arrival_time.getTime();
            let startAgenda = value.day.getTime();
            let endAgenda = value.agenda.last_itinerary_time_with_limit;
            if (
              (startAgenda < start && endAgenda <= end && endAgenda >= start) ||
              (startAgenda > start && endAgenda <= end) ||
              (startAgenda < start && endAgenda > end) ||
              (startAgenda > start && endAgenda > end && startAgenda <= end)
            ) {
              data.push(value);
            }

            // save offset and width
            this.setAgendaInfoWidth(value);
            this.setAgendaInfoOffset(value);
          }
          
          if (value.agenda.piece_shipment || value.agenda.transshipment) {
            // load info about warehouse events
            let time_from_date: Date = new Date();
            // using first itinerary time
            let first: Itinerary = value.agenda.first_itinerary;
            if (first) {
              time_from_date = first.arrival_time;
            }
            // resize tf interval (without that some events could be missing)
            let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
            let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
            let filterObj: any = {
              tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
              tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
              on: agenda.order_number_standard
            };
            this._subs.push(
              this._warehouseServ.getEvents(filterObj).subscribe(
                response => {
                  if (response) {
                    console.log(response);
                    // find unloading (naskladneni)
                    value.agenda.warehouse_event_unloading = response.find(
                      e => e.type == ItineraryType.UNLOADING
                    );

                    // find loading (vyskladneni)
                    value.agenda.warehouse_event_loading = response.find(
                      e => e.type == ItineraryType.LOADING
                    );
                  }
                }
              )
            );
          }
        }
      );
      /*this._vehicle.agenda.forEach(
        agenda => {
          let value = new DispatcherBoardAgendaObject(agenda, this._vehicle);
          // not displaying status H Sklad
          if (value.agenda.status != 'H') {
            if (value.agenda.itinerary.length) {
              // let startAgenda = value.agenda.itinerary[0].arrival_time.getTime();
              let startAgenda = value.day.getTime();
              let endAgenda = value.agenda.last_itinerary_time_with_limit;
              if (
                (startAgenda < start && endAgenda <= end && endAgenda >= start) ||
                (startAgenda > start && endAgenda <= end) ||
                (startAgenda < start && endAgenda > end) ||
                (startAgenda > start && endAgenda > end && startAgenda <= end)
              ) {
                data.push(value);
              }

              // save offset and width
              this.setAgendaInfoWidth(value);
              this.setAgendaInfoOffset(value);
            }
          }
        }
      )*/
    }
    // save initialized data
    this._agendaData = data;
    
    let dispatcherAgendas: Array<DispatcherBoardAgendaObject> = this.getDataByVehicle();
    // sort agendas according to arrival_time
    dispatcherAgendas.sort((a, b) => (a.day > b.day) ? 1 : -1);

    dispatcherAgendas.forEach(
      dispAgenda => {
        this.setConflictingTop(dispAgenda);
      }
    );
    this.setConflictingHeight();

    // third loop - computing vehicle row heights and building time/distance differences
    // 50px is brown header
    let startOffsetTop: number = 50;
    this._vehicle.vehicleRowOffsetTop = startOffsetTop;
    this._vehicle.vehicleRowHeight = 25 + this._vehicle.timeSegmentsHeight;  // 25px is height of vehicle-row-header
    startOffsetTop += this._vehicle.vehicleRowHeight;

    // compute time/distance diffs
    this.buildDistanceAndTimeDiff();

    this._changeDetector.detectChanges();

    // horizontal centering on selected agenda after rebuilding
    // if (this.selectedAgenda) {
    //   // centering using scrolling
    //   let dashboardParent = document.getElementById('content-left-parent');
    //   // let dashboardParent = document.getElementById('dashboard-outlet');
    //   // today current hour in decimal number
    //   let center: number = this.selectedAgenda.infoOffset + (this.selectedAgenda.infoWidth / 2);
    //   // current scroll X on page
    //   let horizontalScrollX = window.pageXOffset || document.documentElement.scrollLeft;

    //   // center in window
    //   let scrollX: number = center - horizontalScrollX - (window.innerWidth / 2);
    //   if (scrollX < 1) scrollX = 0;
    //   // smoothly scrolling
    //   dashboardParent.scroll({top: 0, left: scrollX, behavior: 'smooth'});
    // }
  }

  // set horizontal offset left of agenda segment
  setAgendaInfoOffset(obj: DispatcherBoardAgendaObject): void {
    let start = this._days[0];
    let oneDayLengthInPx = this._columnWidth;
    let agendaStart = obj.day;
    let agendaEnd = obj.agenda.last_itinerary_time_with_limit;
    // let agendaEnd = obj.agenda.itinerary[obj.agenda.itinerary.length - 1].arrival_time;

    let length = agendaStart.getTime() - start.getTime();
    length = length / 1000 / 60 / 60 / 24;
    let offset = length * parseFloat(oneDayLengthInPx);

    if (agendaStart.getTime() < start.getTime()) {
      obj.infoOffset = 0;
    }
    else if (agendaEnd < start.getTime()) {
      obj.infoOffset = -1;
    }
    else if (offset < 0) {
      obj.infoOffset = -1;
    }
    else {
      obj.infoOffset = Math.round(offset);
    }
  }

  // set width of agenda segment
  setAgendaInfoWidth(obj: DispatcherBoardAgendaObject): void {
    obj.infoWidth = this.getAgendaLengthInDays(obj.agenda) * parseFloat(this._columnWidth);
  }
  
  // set top aligment when confict in time segments
  setConflictingTop(dispatcherAgenda: DispatcherBoardAgendaObject) {
    let dispatcherAgendas = this.getDataByVehicle();

    // width and offset of given agenda
    let left = dispatcherAgenda.infoOffset;
    let right = dispatcherAgenda.infoWidth + left;

    dispatcherAgenda.conflictRow = 0;
    let conflictedRows: Array<number> = [];
    // if conflicting increment top align -> later start is downer
    dispatcherAgendas.forEach(
      dispAgenda2 => {
        if (dispAgenda2 !== dispatcherAgenda) {
          let left2 = dispAgenda2.infoOffset;
          let right2 = dispAgenda2.infoWidth + left2;
          if ((left >= left2 && left < right2) || (left >= left2 && right <= right2)) {
            // confict -> save conflicted row
            conflictedRows.push(dispAgenda2.conflictRow);
          }
        }
      }
    );
    
    // naive but good approach - loop until finding first free row (not conflicted)
    const MAXCONFLICT = 42;
    for (let i = 0; i < MAXCONFLICT; i++) {
      if (!conflictedRows.includes(i)) {
        dispatcherAgenda.conflictRow = i;
        break;
      }
    }
  }

  // height of time segments
  setConflictingHeight() {
    // find max conflicts => possibility computing height
    let maxConflicts: number = 0;
    let dispatcherAgendas = this.getDataByVehicle();
    dispatcherAgendas.forEach(
      a => {
        if (a.conflictRow > maxConflicts) {
          maxConflicts = a.conflictRow;
        }
      }
    );

    // default timesegment row height is 80px
    this._vehicle.timeSegmentsHeight = 80 + maxConflicts * 50;
    // default hourline height is 60px
    this._vehicle.hourLineHeight = 60 + maxConflicts * 50;
    // default hourline height is 30px
    this._vehicle.halfhourLineHeight = 30 + maxConflicts * 50;
    // default today marker height is 105px
    this._vehicle.todayMarker = 105 + maxConflicts * 50;
  }

  // method for computing time and distance difference between agendas in 1 row
  buildDistanceAndTimeDiff(): void {
    // init array into diffs object
    this._distanceAndTimeDiffs = [];

    let dispatcherAgendas: Array<DispatcherBoardAgendaObject> = this.getDataByVehicle();
    // number of conflict rows and removing duplicates
    let rows = dispatcherAgendas.map(a => a.conflictRow);
    let uniqueRows = rows.filter(
      (item, pos) => {
        return rows.indexOf(item) == pos;
      }
    );
    // for each row just compute time/distance diff
    uniqueRows.forEach(
      r => {
        let rowDispAgendas = dispatcherAgendas.filter(a => a.conflictRow == r);
        rowDispAgendas.forEach(
          (dispAgenda, index) => {
            if (index != 0 && dispAgenda && rowDispAgendas[index - 1]) {
              // time difference computing
              // time of ending last itinerary of first agenda
              let endFirst: number = rowDispAgendas[index - 1].end.getTime();
              // time of loading limit
              let loadingLimit: number = rowDispAgendas[index - 1].last_itinerary.loading_time_limit;
              if (loadingLimit != 0 && !loadingLimit) loadingLimit = 50;
              loadingLimit = loadingLimit * 60 * 1000;
              // time of starting first itinerary of second agenda
              let startSecond: number = dispAgenda.day.getTime();

              let timeDiff = DateTools.getHumanReadHoursAndMinutes(startSecond - endFirst - loadingLimit);
              

              // distance difference computing
              let dist: number = null;
              let endFirstPos = rowDispAgendas[index - 1].last_itinerary.gps_coord;
              let startSecondPos = dispAgenda.first_itinerary.gps_coord;
              if (endFirst && startSecondPos) {
                dist = Haversine.haversine(
                  { latitude: startSecondPos.googleLatLang.lat, longitude: startSecondPos.googleLatLang.lng },
                  { latitude: endFirstPos.googleLatLang.lat, longitude: endFirstPos.googleLatLang.lng }
                );
                // empiric constant (tahle vzdalenost je vzdusnou carou, tak ji trochu nafukujeme)
                dist *= 1.33;
                dist = Math.round(dist * 10) / 10;
              }

              // style offset(left, top) centering between two agendas
              let offsetDiff: number = dispAgenda.infoOffset - (rowDispAgendas[index - 1].infoOffset + rowDispAgendas[index - 1].infoWidth);
              let offsetLeft: number = offsetDiff / 2;
              offsetLeft += rowDispAgendas[index - 1].infoOffset + rowDispAgendas[index - 1].infoWidth;
              offsetLeft -= 27;

              if (this._distanceAndTimeDiffs) {
                // using custom format with only these 4 properties (no object defined)
                this._distanceAndTimeDiffs.push(
                  {
                    timeDiff: timeDiff,
                    distanceDiff: dist,
                    offsetLeft: offsetLeft,
                    offsetTop: dispAgenda.conflictOffsetTop,
                    offsetDiff: offsetDiff
                  }
                );
              } 
            }
          }
        )
      }
    );
  }

  
  /********************************************************/
  /**** Selection ****/
  /********************************************************/
  // agenda segment object clicked by user
  public selectedAgenda: DispatcherBoardAgendaObject = null;
  setSelectedAgenda(event: any, agenda: DispatcherBoardAgendaObject): void {
    if (agenda) {
      // stop propagation (two click events in template)
      event.stopPropagation();
    }
    this.selectedAgenda = agenda;
  }

  
  /* routing feature */
  openObligationNewTab(obligation_key: number) {
    let url: any = null;
    let queryParams: any = {
      obligation_key: obligation_key,
      reloadPossible: true
    }
    url = this._router.serializeUrl(
      this._router.createUrlTree([{outlets: {left: 'ta1-obligation/company_obligation', right: 'cars'}}], {queryParams: queryParams})
    );
    window.open('#' + url, '_blank');
  }
}
