import {Make} from "./make.object";

export class Model {
  //  private _make_id:number;
  //  private _make_name:string;
  private _model_id: number;
  get model_id(): number {
    return this._model_id;
  }
  set model_id(value: number) {
    this._model_id = value;
  }

  private _name: string;
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  private _make: Make;
  get make(): Make {
    return this._make;
  }
  set make(value: Make) {
    this._make = value;
  }
}
