export class Colors {

  /* accepts parameters
  * https://stackoverflow.com/a/17243070
  * h Object = {h:x, s:y, v:z}
  * OR 
  * h, s, v
  */
  static HSVtoRGB(h: any, s: number, v: number): any {
    let r, g, b, i, f, p, q, t;

    if (arguments.length === 1) {
      s = h.s, v = h.v, h = h.h;
    }
    
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);

    switch (i % 6) {
      case 0: r = v, g = t, b = p; break;
      case 1: r = q, g = v, b = p; break;
      case 2: r = p, g = v, b = t; break;
      case 3: r = p, g = q, b = v; break;
      case 4: r = t, g = p, b = v; break;
      case 5: r = v, g = p, b = q; break;
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  }
  
  // method for selecting constrast light or black text color
  // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  static computeColorForBackgroundColor(hexa_string: string): string {
    let color: string = '#ffffff';
    // compute rgb values from hexa string
    let rgb: any = Colors.hexToRgb(hexa_string);

    // check if we should use dark text color
    if (rgb && (rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114) > 186) {
      color = '#000000';
    }

    return color;
  }
  
  // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  static hexToRgb(hex): any {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  
  // return hexadecimal string of color
  // https://stackoverflow.com/a/5624139
  static rgbToHex(r, g, b): string {
    return "#" + Colors.componentToHex(r) + Colors.componentToHex(g) + Colors.componentToHex(b);
  }

  // normalization '0' -> '00'
  static componentToHex(c): string {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  // method serves as dictionary to get color for vehicle according to given index (actually only in google map component for directions + infoWindow color)
  static getColor(index: number): string {
    let color: string = '#0000ff';
    switch (index) {
        case 0: color = '#800000'; break;
        case 1: color = '#d45500'; break;
        case 2: color = '#ffcc00'; break;
        case 3: color = '#c8ab37'; break;
        case 4: color = '#89a02c'; break;
        case 5: color = '#2ca05a'; break;
        case 6: color = '#2ca089'; break;
        case 7: color = '#00aad4'; break;
        case 8: color = '#3771c8'; break;
        case 9: color = '#5500d4'; break;
        case 10: color = '#892ca0'; break;
        case 11: color = '#d40055'; break;
        case 12: color = '#000000'; break;
        case 13: color = '#999999'; break;
        case 14: color = '#d40000'; break;
        case 15: color = '#ff6600'; break;
        case 16: color = '#ffdd55'; break;
        case 17: color = '#decd87'; break;
        case 18: color = '#bcd35f'; break;
        case 19: color = '#5fd38d'; break;
        case 20: color = '#87decd'; break;
        case 21: color = '#55ddff'; break;
        case 22: color = '#87aade'; break;
        case 23: color = '#9955ff'; break;
        case 24: color = '#cd87de'; break;
        case 25: color = '#ff5599'; break;
        case 26: color = '#4d4d4d'; break;
        case 27: color = '#f2f2f2'; break;
        default: break;
    }
    return color;
  }
}