import { Component, ChangeDetectorRef, AfterViewChecked, ChangeDetectionStrategy } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { PathLocationStrategy } from "@angular/common";
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { MessageService } from "./service/message.service";
import { AuthenticationService } from "./service/authentication.service";
import { StorageService } from "./service/storage.service";
import { TruckManagerLayoutService } from "./service/truck-manager-layout.service";
import { UserConfigurationService } from "./service/user-configuration.service";
import { NotificationService } from "./service/notification-service";
import { GoogleMapMarker } from "./model/google-map-marker.object";
import { User } from "./model/user.object";
import { IS_DEMO, StorageKeys } from "./config";
import { TruckManagerAnimations } from "./animations/truck-manager.animations";
import { CompanyService } from "./service/company.service";
// import { Angular2Wamp } from "./angular2-wamp/angular2-wamp";
// import { HttpClientService } from "./service/http-client.service";
// import { GoogleMapsApiService } from "./service/google-maps-api.service";
// import { FilesService } from "./service/files.service";
// import { DestinationRoutesService } from "./service/destination-routes.service";
// import { WebsocketService } from "./service/websocket.service";
// import { WebConnectivityService } from "./service/web.connectivity.service";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    // AuthenticationService,
    // TruckManagerLayoutService,
    // HttpClientService,
    // VehicleService,
    // MessageService,
    // StorageService,
    // WebsocketService,
    // Angular2Wamp,
    // WebConnectivityService,
    // UserConfigurationService,
    // NotificationService,
    // CompanyService,
    // GoogleMapsApiService,
    // FilesService,
    // DestinationRoutesService,
    PathLocationStrategy
  ],
  animations: TruckManagerAnimations
})

export class AppComponent implements AfterViewChecked {

  private _navigationSubscription: any;
  private _subscribed: Array<Subscription> = [];

  private _lazyLoading: boolean = false;
  public get lazyLoading(): boolean {
    return this._lazyLoading;
  }

  private _fullPageDashboardFirstTime: boolean = false;

  // key in local storage
  private DASHBOARD_VISIBLE = StorageKeys.dashboard.visible;
  private _storage_dashboard_visible: boolean = true;

  private _hideDashboardTimeout: number;
  private _messagesNewDialogLive: boolean = !this._messageService.newMessagesDialogDisabled;
  private _authStorageChecked: boolean = false;

  public readonly LOCALES: Array<{locale: string, name: string}> = [
    { locale: 'cs', name: 'CZ' },
    { locale: 'sk', name: 'SK' },
    { locale: 'en', name: 'EN' },
    { locale: 'ru', name: 'RU' }
  ];

  private _locale: {locale: string, name: string} = { locale: 'cs', name: 'CZ' };
  public get locale(): {locale: string, name: string} {
    return this._locale;
  }

  setLocale(new_locale: string) {
    // console.log(window.location.href);
    // console.log(locale);
    let url: string = window.location.href;
    url = url.replace('/' + this._locale.locale + '/', '/' + new_locale + '/');
    window.open(url, '_self');
  }

  constructor(
    private _layout: TruckManagerLayoutService,
    private _notification: NotificationService,
    private _authService: AuthenticationService,
    private _companyService: CompanyService,
    private _messageService: MessageService,
    private _storageServ: StorageService,
    private _confService: UserConfigurationService,
    private _router: Router,
    private _title: Title,
    private _cdr: ChangeDetectorRef,
  ) {
    // set locale according to window url
    if (window.location.href) {
      if (window.location.href.includes('/cs/')) {
        this._locale = { locale: 'cs', name: 'CZ' };
      }
      else if (window.location.href.includes('/sk/')) {
        this._locale = { locale: 'sk', name: 'SK' };
      }
      else if (window.location.href.includes('/en/')) {
        this._locale = { locale: 'en', name: 'EN' };
      }
      else if (window.location.href.includes('/ru/')) {
        this._locale = { locale: 'ru', name: 'RU' };
      }
    }

    // TRANS eu redirecting - handle code
    // https://flaviocopes.com/urlsearchparams/
    const params = new URLSearchParams(window.location.search);
    if (params.has('code') && params.has('state')) {
      let queryParams: any = {
        code: params.get('code'), 
        state: params.get('state'), 
        reloadPossible: true
      };
      // redirecting to obligation list component
      let url = this._router.serializeUrl(
        this._router.createUrlTree(
          [{outlets: {left: 'ta1-obligation/obligation_list'}}], 
          {queryParams: queryParams}
        )
      );
      this._router.navigateByUrl(url);
    }

    // 3 full pages handling
    this._navigationSubscription = this._router.events.subscribe(
      (e: any) => {
        if (e instanceof NavigationEnd) {
          let fullPage1: string = 'left:all-in-one-map';
          let fullPage2: string = 'left:dispatcher-board';
          let fullPage3: string = 'left:messages-read';
          let url = e.urlAfterRedirects;

          // disabled dashboard only for the first time of loading full page component
          if (!this._fullPageDashboardFirstTime) {
            // hide right dashboard when opening full page component
            if (url && (url.includes(fullPage1) || url.includes(fullPage2) || url.includes(fullPage3))) {
              this._fullPageDashboardFirstTime = true;
              this._storage_dashboard_visible = false;
              this.hideDashboard();
            }
          }
        }
        
        if (e instanceof RouteConfigLoadStart) {
          this._lazyLoading = true;
        } 
        else if (e instanceof RouteConfigLoadEnd) {
          this._lazyLoading = false;
        }
      }
    );

    this._subscribed.push(
      this._authService.authenticationResult.subscribe(
        (user: User) => {
          if (user && !IS_DEMO && this._dashboardVisible === 'inactive' && this._storage_dashboard_visible) {
            this._dashboardVisible = 'active';
            this.activateDashboard();
          } 
          else if (!user && !IS_DEMO && this._dashboardVisible === 'active') {
            this._dashboardVisible = 'inactive';
          }
        }
      ),
      this._authService.storageChecked.subscribe(
        authStorageChecked => {
          if (authStorageChecked) {
            this._authStorageChecked = authStorageChecked;
          }
        }
      ),
      this._notification.getAlerts.subscribe(
        alerts => {
          this._alerts = alerts;
        }
      ),
      this._companyService.getUserAccountsObservable().subscribe(
        user_accounts => {
          if (user_accounts && user_accounts.length) {
            if (this._authService.user) {
              this._authService.user.user_account = user_accounts.find(
                u => u.username == this._authService.user.username
              );
            }
          }
        }
      )
    );
    

    // possibly hide dashboard
    let tmp: string = _storageServ.getItem(this.DASHBOARD_VISIBLE, true);
    if (tmp == 'false' && !IS_DEMO) {
      this._storage_dashboard_visible = false;
      this.hideDashboard();
    }

    if (IS_DEMO) {
      this._dashboardVisible = 'active';
      this.activateDashboard();
    }

    // scroll events
    window.addEventListener('scroll', this.scroll, true);
  }

  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // HOT FIX for ExpressionChangedAfterItHasBeenCheckedError
    this._cdr.detectChanges();
  }

  public setDefaultTitle() {
    this._title.setTitle("TruckManager");
  }

  private _runningIframe: boolean = true;
  get runningIframe(): boolean {
    return this._runningIframe;
  }

  private _alerts: Array<any> = [];
  get alerts(): Array<any> {
    return this._alerts;
  }

  get isDashboardVisible(): boolean {
    return this._layout.isDashboardVisible;
  }

  get isDemoOnlyDashboard(): boolean {
    return IS_DEMO && window.location.href.includes('onlyDashboard=true');
  }

  get dashboardMenuShown(): boolean {
    return this._layout.dashboardMenuShown;
  }

  get dashboardMenuWidth(): string {
    return this._layout.dashboardMenuWidth;
  }

  get availableIcons(): any {
    return GoogleMapMarker.icons;
  }

  // get locale(): string {
  //   return 'cs';
  //   // return this._layout.locale;
  // }

  // get locales(): Array<string> {
  //   return this._layout.locales;
  // }

  // get locales(): Array<string> {
  //   return ['CZ', 'SK', 'EN', 'UK'];
  // }

  get login_modal_visible(): boolean {
    return (
      this._authStorageChecked && 
      (this._layout.login_modal_visible || (!IS_DEMO && !this.isAuthenticated))
    );
  }

  get user(): User {
    return this._layout.user;
  }

  get newMessagesCount(): number {
    return this._layout.newMessagesCount;
  }

  // get isDashboardFullMode(): boolean {
  //   return this._layout.isDashboardFullMode;
  // }

  get isFullscreenRoute(): boolean {
    return this._layout.isFullscreenRoute;
  }

  get staticContentResource(): string {
    return this._layout.staticContentResource;
  }

  get isDashboardControlsVisible(): boolean {
    return this._layout.isDashboardControlsVisible;
  }

  get staticContentName(): string {
    return this._layout.staticContentName;
  }

  private _dashboardMenu: string = 'inactive';
  get dashboardMenu(): string {
    return this._dashboardMenu;
  }

  get dashboard(): string {
    return (this.isDashboardControlsVisible ? this._dashboardMenu : 'inactive');
    // + (this._layout.isDashboardFullMode || BrowserTools.isXSWidth() ? '-full' : '');
  }

  private _dashboardVisible: string = 'inactive';
  get dashboardVisible(): string {
    return this._dashboardVisible;
    // return this._layout.isDashboardFullMode ? 'dashboardfull' : this._dashboardVisible;
  }

  get messagesNewDialogIsLive(): boolean {
    return this._messagesNewDialogLive;
  }

  private _contentType: string;
  get contentType(): string {
    return this._contentType;
  }

  toggleDashboard() {
    this._layout.toggleDashboard();
    this._dashboardVisible = this._dashboardVisible === 'active' ? 'inactive' : 'active';
    if (this._dashboardVisible === 'inactive') {
      this._dashboardMenu = 'inactive';
    }
    
    // to local storage
    this._storage_dashboard_visible = this.isDashboardVisible.valueOf();
    this._storageServ.setItem(this.DASHBOARD_VISIBLE, this._storage_dashboard_visible.toString(), true);
  }

  hideDashboard() {
    this._layout.hideDashboard();
    this._dashboardVisible = 'inactive';
    this._dashboardMenu = 'inactive';
  }

  activateDashboard() {
    this._dashboardVisible = 'active';
    this._layout.activateDashboard();
  }

  toggleDashboardMenu(notFromMenuItem?: boolean) {
    this._dashboardMenu = this._dashboardMenu === 'active' ? 'inactive' : 'active';
    if (this._dashboardMenu === 'active') {
      this.createHideDashboardMenuTimeout();
    }
  }

  closeDashboardMenu() {
    this._layout.closeDashboardMenu()
  }

  isDashboardMenuVisible(): boolean {
    return this._layout.isDashboardMenuVisible();
  }

  changeDashboardMenuSection() {
    this.toggleDashboardMenu(false);
    if (this._dashboardVisible === 'inactive') {
      this._dashboardVisible = 'active';
      this.activateDashboard();
    }
  }

  // setLocale(language: string) {
  //   // this._layout.setLocale(language);
  // }

  localeMappedName(locale: string) {
    switch (locale) {
      case 'cs':
        return 'cz';
    }
    return locale;
  }

  get isAuthenticated(): boolean {
    return this._layout.isAuthenticated();
  }

  showLoginDialog(): void {
    this._layout.showLoginDialog();
  }

  loginDialogClosed(event: any): void {
    this._layout.loginDialogClosed(event);
  }

  hoverDashboardMenuStart() {
    this.clearHideDashboardMenuTimeout();
  }

  hoverDashboardMenuEnd() {
    this.createHideDashboardMenuTimeout();
  }

  loginDialogVisible(): boolean {
    return !IS_DEMO;
  }

  private createHideDashboardMenuTimeout() {
    this.clearHideDashboardMenuTimeout();
    this._hideDashboardTimeout = window.setTimeout(() => {
      this._dashboardMenu = 'inactive';
    }, 3000)
  }

  private clearHideDashboardMenuTimeout() {
    if (this._hideDashboardTimeout) {
      window.clearTimeout(this._hideDashboardTimeout);
    }
  }

  public routeRightMenu(route_right: string) {
    // keep previous route query params
    let previous_route: any = this._router.parseUrl(this._router.url);
    let params = previous_route.queryParams;
    params['rightMenu'] = true;
    // open dashbord
    let url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {right: route_right}}], {queryParams: params} 
      )
    );
    this._router.navigateByUrl(url);
  }
  

  // custom scroll event
  scroll = (event): void => {
    let contentParent: any = document.getElementById('content-left-parent');
    if (contentParent) {
      if (contentParent.scrollTop + contentParent.clientHeight >= contentParent.scrollHeight) {
        this._layout.contentLeftScrolledBottom = true;
      }
    }
  };

  logout(): void {
    this._authService.logout();
  }
  
  // this._messageService.getNewMessages(false).subscribe(
  //   (newMessages) => {
  //     this._messagesNewDialogLive = newMessages.length !== 0;
  //     if (!this._confService.configuration.defaultMessagesConfiguration.new_messages_dialog_show) {
  //       if (this._messagesNewDialogLive && this._dashboardVisible === 'inactive') {
  //         console.log('HERE WE GO')
  //         this.toggleDashboard();
  //       }
  //     }
  //   }
  // ),
}
