import {DateTools} from "../tools/DateTools";

export class DriverCard {
  private _card_no: string;
  get card_no(): string {
    return this._card_no;
  }
  set card_no(value: string) {
    this._card_no = value;
  }

  private _company_key: number;
  get company_key(): number {
    return this._company_key;
  }
  set company_key(value: number) {
    this._company_key = value;
  }

  private _driver_key: number;
  get driver_key(): number {
    return this._driver_key;
  }
  set driver_key(value: number) {
    this._driver_key = value;
  }

  private _driver_name: string;
  get driver_name(): string {
    return this._driver_name;
  }
  set driver_name(value: string) {
    this._driver_name = value;
  }

  private _expiry_date: string;
  get expiry_date(): string {
    return this._expiry_date;
  }
  set expiry_date(value: string) {
    this._expiry_date = value;
    if (this._expiry_date) {
      this._expiryDate = new Date(this._expiry_date);
      // condition for colour class
      let currentTime = new Date().getTime();
      let expiryTime = this.expiryDate.getTime();
      const diffTime = currentTime - expiryTime;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < -45) this._expiry_class = 'text-success';
      else if (diffDays >= -45 && diffDays < 0) this._expiry_class = 'text-warning';
      else this._expiry_class = 'text-danger';
    }
  }

  private _expiryDate: Date;
  public get expiryDate(): Date {
    return this._expiryDate;
  }

  private _expiry_class: string = 'text-success';
  public get expiry_class(): string {
    return this._expiry_class;
  }

  private _holder_name: string;
  get holder_name(): string {
    return this._holder_name;
  }
  set holder_name(value: string) {
    this._holder_name = value;
  }

  private _last_download: string;
  get last_download(): string {
    return this._last_download;
  }
  set last_download(value: string) {
    this._last_download
    if (value) {
      this._last_downloadDate = DateTools.isoFix(value.toString());
      // condition for colour class
      let currentTime = new Date().getTime();
      let expiryTime = new Date(this.last_downloadDate).getTime();
      const diffTime = currentTime - expiryTime;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 28) this._last_download_class = 'text-danger';
      else this._last_download_class = 'text-success';
    }
  }

  private _last_downloadDate: Date;
  public get last_downloadDate(): Date {
    return this._last_downloadDate;
  }
  
  private _last_download_class: string = 'text-success';
  public get last_download_class(): string {
    return this._last_download_class;
  }

  private _send_reminder: boolean = false;
  get send_reminder(): boolean {
    return this._send_reminder;
  }
  set send_reminder(value: boolean) {
    this._send_reminder = value;
  }
}
