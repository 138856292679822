import { Agenda } from "./agenda.object";

export class GanttDriveDiagramEvent {

  static EVENT_DRIVING: string = 'driving';
  static EVENT_PLACEHOLDER: string = 'placeholder';
  
  static DISPLAY_WIDTH: number = 2880; // 2880 minutes ~ 48 hod

  get isDriving(): boolean {
    return this.type === GanttDriveDiagramEvent.EVENT_DRIVING;
  }
  
  get isPlaceholder(): boolean {
    return this.type === GanttDriveDiagramEvent.EVENT_PLACEHOLDER;
  }

  constructor(
    private _agenda: Agenda,
    private _type: string,
    private _eventStartDate: Date,
    private _eventEndDate: Date
  ) {
    if (this.agenda && this.agenda.order_comp_book) {
      this._agenda_comp_book_formatted = '';
      this._agenda_comp_book_formatted += this.agenda.order_comp_book.company + ', ';
      this._agenda_comp_book_formatted += this.agenda.order_comp_book.city + ', ';
      this._agenda_comp_book_formatted += this.agenda.order_comp_book.country;
    } 

    // compute total time percentage
    this.setTotalTimePercentage();
  }

  get agenda(): Agenda {
    return this._agenda;
  }

  private _agenda_comp_book_formatted: string = '';
  get agenda_comp_book_formatted(): string {
    return this._agenda_comp_book_formatted;
  }
  
  get type(): string {
    return this._type;
  }

  private _distanceTotal: number = 0;
  get distanceTotal(): number {
    return this._distanceTotal;
  }
  set distanceTotal(value: number) {
    this._distanceTotal = Math.round(value);
  }

  private _timeTotal: number = 0;
  get timeTotal(): number {
    return this._timeTotal;
  }
  set timeTotal(value: number) {
    this._timeTotal = value;
  }

  private _timeCompleted: number = 0;
  get timeCompleted(): number {
    return this._timeCompleted;
  }
  set timeCompleted(value: number) {
    this._timeCompleted = value;
  }

  private _distanceCompleted: number = 0;
  get distanceCompleted(): number {
    return this._distanceCompleted;
  }
  set distanceCompleted(value: number) {
    this._distanceCompleted = Math.round(value);
  }

  get completedPercentage(): number {
    let byDistance: number = Math.round((this._distanceCompleted / this._distanceTotal) * 100);
    if (byDistance > 100) {
      return 100;
    }
    return byDistance;
  }

  get event_info(): string {
    let diff = this.distanceCompleted - this.distanceTotal;
    return (diff > 0 ? '+' : '') + diff + '/' + this.distanceTotal;
  }

  get eventStartDate(): Date {
    return this._eventStartDate;
  }

  get eventEndDate(): Date {
    return this._eventEndDate;
  }
  
  get event_time_diff(): number {
    return this._eventEndDate.getTime() - this._eventStartDate.getTime()
  }

  private _totalTimePercentage: number = 0;
  get totalTimePercentage(): number {
    return this._totalTimePercentage;
    // return Math.floor(((this.event_time_diff / 60000) / GanttDriveDiagramEvent.DISPLAY_WIDTH) * 100);
  }
  setTotalTimePercentage(): void {
    let percentage = ((this.event_time_diff / 60000) / GanttDriveDiagramEvent.DISPLAY_WIDTH) * 100;
    // round to 1 decimal
    this._totalTimePercentage = Math.floor(percentage * 10) / 10;
  }

  // isCompleted() {
  //   switch (true) {
  //     case this.isDriving:
  //       return this._distanceTotal <= this._distanceCompleted;
  //     case this.isPlaceholder:
  //       // pause is fully completed every time
  //       return true;
  //     default:
  //       return true;
  //   }
  // }

  // get formated_time_string(): string {
  //   return GeoPosition.parseMilisecondsToHHMMString(this.timeTotal - this.timeCompleted);
  // }

  // get formated_event_time_diff(): string {
  //   return GeoPosition.parseMilisecondsToHHMMString(this.event_time_diff);
  // }
  
  // get formatted_event_end(): string {
  //   return DateTools.formatLocaleString(this._eventEndDate);
  // }

  // get event_time_diff_from_current() {
  //   return this._eventEndDate.getTime() - this._currentDate.getTime();
  // }
  
  // get passedTimePercentage(): number {
  //   if (this._currentDate >= this._eventEndDate) {
  //     return 100;
  //   } else if (this._currentDate <= this._eventStartDate) {
  //     return 0;
  //   } else {
  //     let minutesTotal = (this.eventEndDate.getTime() - this.eventStartDate.getTime()) / 60000;
  //     let minutesPassed = (this._currentDate.getTime() - this.eventStartDate.getTime()) / 60000;
  //     let passed = /*Math.floor*/((minutesPassed / minutesTotal) * 100);
  //     if (passed > 100) {
  //       return 100;
  //     } else {
  //       return Math.floor(passed);
  //     }
  //   }
  // }
  
  // get minutes(): number {
  //   return this.event_time_diff / 60000;
  // }
  
  // addTimeCompleted(time: number) {
  //   this._timeCompleted += time;
  // }

  // addDistanceCompleted(distance: number) {
  //   this._distanceCompleted += distance;
  // }
}
