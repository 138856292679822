import { DateTools } from '../tools/DateTools';
import { GeoPosition } from './geo-position.object';
import { GoogleMapMarker } from './google-map-marker.object';

export class FuelcardEvent {
  public readonly isTrackingEvent: boolean = true;
  
  private _pos_key: number;
  get pos_key(): number {
    return this._pos_key;
  }
  set pos_key(value: number) {
    this._pos_key = value;
  }

  private _created_time: any;
  public get created_time(): any {
    return this._created_time;
  }
  public set created_time(value: any) {
    this._created_time = value;
  }

  
  private _imported_by: string;
  public get imported_by(): string {
    return this._imported_by;
  }
  public set imported_by(value: string) {
    this._imported_by = value;
  }

  private _import_template: string;
  public get import_template(): string {
    return this._import_template;
  }
  public set import_template(value: string) {
    this._import_template = value;
  }

  private _file_name: string;
  public get file_name(): string {
    return this._file_name;
  }
  public set file_name(value: string) {
    this._file_name = value;
  }

  private _file_size: number;
  public get file_size(): number {
    return this._file_size;
  }
  public set file_size(value: number) {
    this._file_size = value;
  }

  private _period_start: any;
  public get period_start(): any {
    return this._period_start;
  }
  public set period_start(value: any) {
    this._period_start = value;
  }

  private _period_end: any;
  public get period_end(): any {
    return this._period_end;
  }
  public set period_end(value: any) {
    this._period_end = value;
  }

  private _records_total: number;
  public get records_total(): number {
    return this._records_total;
  }
  public set records_total(value: number) {
    this._records_total = value;
  }

  private _records_imported: number;
  public get records_imported(): number {
    return this._records_imported;
  }
  public set records_imported(value: number) {
    this._records_imported = value;
  }

  private _fuel_total: number;
  public get fuel_total(): number {
    return this._fuel_total;
  }
  public set fuel_total(value: number) {
    this._fuel_total = value;
  }

  private _fuel_imported: number;
  public get fuel_imported(): number {
    return this._fuel_imported;
  }
  public set fuel_imported(value: number) {
    this._fuel_imported = value;
  }

  private _fuel_price_total: number;
  public get fuel_price_total(): number {
    return this._fuel_price_total;
  }
  public set fuel_price_total(value: number) {
    this._fuel_price_total = value;
  }

  private _fuel_price_imported: number;
  public get fuel_price_imported(): number {
    return this._fuel_price_imported;
  }
  public set fuel_price_imported(value: number) {
    this._fuel_price_imported = value;
  }

  private _additives_total: number;
  public get additives_total(): number {
    return this._additives_total;
  }
  public set additives_total(value: number) {
    this._additives_total = value;
  }

  private _additives_imported: number;
  public get additives_imported(): number {
    return this._additives_imported;
  }
  public set additives_imported(value: number) {
    this._additives_imported = value;
  }

  private _additives_price_total: number;
  public get additives_price_total(): number {
    return this._additives_price_total;
  }
  public set additives_price_total(value: number) {
    this._additives_price_total = value;
  }

  private _additives_price_imported: number;
  public get additives_price_imported(): number {
    return this._additives_price_imported;
  }
  public set additives_price_imported(value: number) {
    this._additives_price_imported = value;
  }

  private _toll_total: number;
  public get toll_total(): number {
    return this._toll_total;
  }
  public set toll_total(value: number) {
    this._toll_total = value;
  }

  private _toll_imported: number;
  public get toll_imported(): number {
    return this._toll_imported;
  }
  public set toll_imported(value: number) {
    this._toll_imported = value;
  }

  private _others_total: number;
  public get others_total(): number {
    return this._others_total;
  }
  public set others_total(value: number) {
    this._others_total = value;
  }

  private _others_imported: number;
  public get others_imported(): number {
    return this._others_imported;
  }
  public set others_imported(value: number) {
    this._others_imported = value;
  }

  private _imported_time: any;
  public get imported_time(): any {
    return this._imported_time;
  }
  public set imported_time(value: any) {
    this._imported_time = value;
    if (this._imported_time) {
      this._importedTimeDate = DateTools.apiIsoToDate(value);
    }
  }

  // custom
  private _importedTimeDate: Date = null;
  public get importedTimeDate(): Date {
    return this._importedTimeDate;
  }
  public set importedTimeDate(value: Date) {
    this._importedTimeDate = value;
  }

  get apiObject(): any {
    return { 
      created_time : this.created_time,
      imported_time:this.imported_time,
      import_template: this.import_template,
      file_name:this.file_name,
      file_size:this.file_size,
      period_start:this.period_start,
      period_end:this.period_end, 
      records_total:this.records_total,
      records_imported:this.records_imported,
      fuel_total:this.fuel_total,
      fuel_imported:this.fuel_imported,
      fuel_price_total:this.fuel_price_total,
      fuel_price_imported:this.fuel_price_imported,
      additives_total:this.additives_total,
      additives_imported:this.additives_imported,
      additives_price_total:this.additives_price_total,
      additives_price_imported:this.additives_price_imported,
      toll_total:this.toll_total,
      toll_imported:this.toll_imported,
      others_total:this.others_total,
      others_imported:this.others_imported
    }
  }
    
}