<div *ngIf="sendingEmail" class="container text-center py-3">
  <h6 i18n>Aktuálně probíhá odesílání emailu...</h6>
  <h6 i18n>Může to trvat i několik sekund...</h6>
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<!-- logy/informace o emailech -->
<div *ngIf="emailsOnly.length && !sendingEmail" class="mt-4">
  <div class="row">
    <div class="col-sm-12">
      <h6><strong i18n>Odeslané emaily</strong></h6>
    </div>
  </div>

  <div class="table-responsive normal-font-size">
    <table class="table table-sm">
      <thead class="text-bold">
        <tr>
          <th i18n>Čas odeslání</th>
          <th i18n>Předmět</th>
          <th i18n>Odesílatel</th>
          <th i18n>Příjemce</th>
          <th><ng-container i18n>Soubory</ng-container>&nbsp;
            <a class="badge badge-pill badge-warning text-center" href="javascript:void(0)" 
            ngbPopover="PDF faktury lze nalézt pouze v detailu emailu" container="body" placement="top auto">?</a>
          </th>
          <th i18n>Detail</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let header of emailsOnly">
          <td class="align-middle">
            {{header.sent_date_object ? (header.sent_date_object | date: 'dd.MM.yyyy HH:mm') : ''}}
          </td>

          <td class="align-middle">
            {{header.subject ? header.subject : ''}}
          </td>

          <td class="align-middle">
            {{header.from ? header.from : ''}}
          </td>

          <td class="align-middle">
            {{header.to ? header.to : ''}}
          </td>

          <td class="align-middle">
            <span class="badge badge-pill badge-primary pointer" (click)="loadingAttachment = false"
            [ngbPopover]="filesPopover" [ngbTooltip]="filesTooltip"
            [autoClose]="'outside'" placement="left auto" container="body">
              {{header.filesObjects ? header.filesObjects.length : '0'}}
            </span>

            <ng-template #filesTooltip>
              <ng-container i18n>Počet přiložených souborů</ng-container>: 
              {{header.filesObjects ? header.filesObjects.length : '0'}}
            </ng-template>

            <ng-template #filesPopover>
              <div *ngIf="header.filesObjects?.length">
                <h6 class="normal-font-size" i18n>Přiložené soubory</h6>
                <h6 *ngIf="loadingAttachment" class="text-primary normal-font-size text-truncate d-flex">
                  <div class="mr-2" i18n>Otevírám zvolený soubor...</div>
                  <div class="spinner-wrapper">
                    <div class="spinner-border text-primary" style="width:1rem; height:1rem;" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </h6>
                
                <div class="row p-1" [style.width]="widthPopoverOfFiles(header)">
                  <div *ngFor="let file of header.filesObjects" class="p-1" style="width:130px">
                    <a (click)="openAttachmentNewTab(file)" href="javascript:void(0)">
                      <div *ngIf="file.thumbnail" class="thumbnail border rounded shadow-sm">
                        <img class="thumbnail" [ngbTooltip]="file.name"
                        [src]="file.thumbnail.content | safeResource" alt="Náhled"/>
                      </div>
                      <div *ngIf="!file.thumbnail" class="thumbnail border rounded shadow-sm">
                        <img class="thumbnail" [ngbTooltip]="file.name" width="130px"
                        src="assets/icons-new/folder_document.svg" alt="Náhled"/>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </td>

          <td class="align-middle">
            <img src="assets/icons-new/zoom_in.svg" alt="zoom_in" height="20px" class="pointer" 
            (click)="showEmailDetail(header.email_msg_id)" 
            ngbTooltip="Zobrazit detail emailu" i18n-ngbTooltip placement="left"/>
          </td>
          
          <td class="align-middle">
            <img src="assets/icons-new/refresh.svg" alt="zoom_in" height="20px" class="pointer" 
            (click)="resendEmail(header)" 
            ngbTooltip="Odeslat stejný email znovu" i18n-ngbTooltip placement="left"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div *ngIf="printing" class="container text-center py-3">
  <h6 i18n>Aktuálně probíhá tisk PDF na serveru...</h6>
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<div class="row mt-2">
  <!-- logy/informace o tisku PDF -->
  <div *ngIf="printingsOnly.length && !printing" class="col-md-6">
    <h6>
      <strong i18n>Tisk PDF</strong>
    </h6>
    <div class="table-responsive normal-font-size">
      <table class="table table-sm">
        <thead class="text-bold">
          <tr>
            <th i18n>Čas tisku</th>
            <th i18n>PDF</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let header of printingsOnly">
            <td class="align-middle">
              {{header.sent_date_object ? (header.sent_date_object | date: 'dd.MM.yyyy HH:mm') : ''}}
            </td>

            <td class="align-middle">
              <img src="assets/icons-new/zoom_in.svg" 
              alt="zoom_in" height="20px" class="pointer" 
              (click)="openPrintedPDF(header.email_msg_id)" 
              ngbTooltip="Otevřít PDF" i18n-ngbTooltip placement="left" container="body"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- upominky -->
  <div *ngIf="invoice.reminders?.length" class="col-md-6">
    <h6><strong i18n>Odeslané upomínky</strong></h6>
    
    <div class="table-responsive normal-font-size">
      <table class="table table-sm">
        <tbody>
          <tr *ngFor="let reminder of invoice.reminders">
            <td class="align-middle">
              <span>{{reminder.number}}. <ng-container i18n>upomínka</ng-container> - </span>
              <span *ngIf="reminder.createdDate">
                {{reminder.createdDate | date:'dd.MM.yyyy hh:mm'}}
              </span> 
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
