import { UserPermission } from "../config";

export class UserAccount {

  private _access_rights: string;
  public get access_rights(): string {
    return this._access_rights;
  }
  public set access_rights(value: string) {
    this._access_rights = value;
  }
  
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _lang: string;
  public get lang(): string {
    return this._lang;
  }
  public set lang(value: string) {
    this._lang = value;
  }

  private _person_key: number = null;
  public get person_key(): number {
    return this._person_key;
  }
  public set person_key(value: number) {
    this._person_key = value;
  }

  private _password: string;
  public get password(): string {
    return this._password;
  }
  public set password(value: string) {
    this._password = value;
  }

  private _person: string;
  public get person(): string {
    return this._person;
  }
  public set person(value: string) {
    this._person = value;
  }

  private _settings: string;
  public get settings(): string {
    return this._settings;
  }
  public set settings(value: string) {
    this._settings = value;
  }

  private _username: string;
  public get username(): string {
    return this._username;
  }
  public set username(value: string) {
    this._username = value;
  }

  private _ui_lang: string;
  public get ui_lang(): string {
    return this._ui_lang;
  }
  public set ui_lang(value: string) {
    this._ui_lang = value;
  }

  private _ui_settings: string;
  public get ui_settings(): string {
    return this._ui_settings;
  }
  public set ui_settings(value: string) {
    this._ui_settings = value;
  }

  // custom attribute used for filtering cars in vehicle_list, dispatcher_board, map-all-cars
  private _marked: boolean;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }

  // custom
  private _person_name: string = null;
  public get person_name(): string {
    return this._person_name;
  }
  public set person_name(value: string) {
    this._person_name = value;
  }

  // permissions manage
  private _permissions: Array<string> = [];
  public get permissions(): Array<string> {
    return this._permissions;
  }
  public set permissions(value: Array<string>) {
    this._permissions = value ? value : [];
  }

  // custom set method for permission initialization
  setPermissionsArray(): void {
    // superuser has all permissions
    if (!this._permissions.includes(UserPermission.COMPANY_SUPERUSER)) {
      this.companySuperuser = false;

      if (!this._permissions.includes(UserPermission.COMPANY_DETAILS_MANAGE))
        this.companyDetailsManage = false;
      if (!this._permissions.includes(UserPermission.OBLIGATION_READ))
        this.obligationRead = false;
      if (!this._permissions.includes(UserPermission.OBLIGATION_MANAGE))
        this.obligationManage = false;
      if (!this._permissions.includes(UserPermission.ORDER_READ))
        this.orderRead = false;
      if (!this._permissions.includes(UserPermission.ORDER_MANAGE))
        this.orderManage = false;
      if (!this._permissions.includes(UserPermission.INVOICE_READ))
        this.invoiceRead = false;
      if (!this._permissions.includes(UserPermission.INVOICE_MANAGE))
        this.invoiceManage = false;
      if (!this._permissions.includes(UserPermission.STATISTICS_SALES_PROFIT))
        this.statisticsSalesProfit = false;
      if (!this._permissions.includes(UserPermission.STATISTICS_OVERVIEW_RANKINGS))
        this.statisticsOverviewRankings = false;
      if (!this._permissions.includes(UserPermission.STATISTICS_INVOICES_OVERVIEW))
        this.statisticsInvoicesOverview = false;
      if (!this._permissions.includes(UserPermission.STATISTICS_OBLIGATIONS_OVERVIEW))
        this.statisticsObligationsOverview = false;
      if (!this._permissions.includes(UserPermission.EXPORT_OBLIGATIONS))
        this.exportObligations = false;
      if (!this._permissions.includes(UserPermission.EXPORT_INVOICES))
        this.exportInvoices = false;
      if (!this._permissions.includes(UserPermission.EXPORT_ORDERS))
        this.exportOrders = false;
      if (!this._permissions.includes(UserPermission.EXPORT_ADRESS_BOOK))
        this.exportAddressBook = false;
      if (!this._permissions.includes(UserPermission.HISTORY_ALLOW))
        this.historyAllow = false;
      if (!this._permissions.includes(UserPermission.STATEMENTS_ALLOW))
        this.statementsAllow = false;
      if (!this._permissions.includes(UserPermission.STATEMENTS_EPD_ALLOW))
        this.statementsEPDAllow = false;
      if (!this._permissions.includes(UserPermission.STATEMENTS_CN_ALLOW))
        this.statementsCNAllow = false;
      if (!this._permissions.includes(UserPermission.STATEMENTS_KJ_ALLOW))
        this.statementsKJAllow = false;
      if (!this._permissions.includes(UserPermission.STATEMENTS_HVV_ALLOW))
        this.statementsHVVAllow = false;
      if (!this._permissions.includes(UserPermission.STATEMENTS_EXPORT_INVOICES_ALLOW))
        this.statementsExportInvoicesAllow = false;
      if (!this._permissions.includes(UserPermission.SERVICES_ALLOW))
        this.servicesAllow = false;
      if (!this._permissions.includes(UserPermission.CARS_ALLOW))
        this.carsAllow = false;
      if (!this._permissions.includes(UserPermission.REMINDERS_ALLOW))
        this.remindersAllow = false;
      if (!this._permissions.includes(UserPermission.SETTINGS_TEXT_ALLOW))
        this.settingsTextAllow = false;
      if (!this._permissions.includes(UserPermission.SETTINGS_USER_ALLOW))
        this.settingsUserAllow = false;
      if (!this._permissions.includes(UserPermission.OTHER_COSTS_USERS))
        this.otherCostsAllow = false;
    }
  }


  public companySuperuser: boolean = true;
  public companyDetailsManage: boolean = true;
  public obligationRead: boolean = true;
  public obligationManage: boolean = true;
  public orderRead: boolean = true;
  public orderManage: boolean = true;
  public invoiceRead: boolean = true;
  public invoiceManage: boolean = true;
  public statisticsSalesProfit: boolean = true;
  public statisticsOverviewRankings: boolean = true;
  public statisticsInvoicesOverview: boolean = true;
  public statisticsObligationsOverview: boolean = true;
  public exportObligations: boolean = true;
  public exportInvoices: boolean = true;
  public exportOrders: boolean = true;
  public exportAddressBook: boolean = true;
  public historyAllow: boolean = true;
  public statementsAllow: boolean = true;
  public statementsEPDAllow: boolean = true;
  public statementsCNAllow: boolean = true;
  public statementsKJAllow: boolean = true;
  public statementsHVVAllow: boolean = true;
  public statementsDZAllow: boolean = true;
  public statementsExportInvoicesAllow: boolean = true;
  public servicesAllow: boolean = true;
  public carsAllow: boolean = true;
  public remindersAllow: boolean = true;
  public settingsTextAllow: boolean = true;
  public settingsUserAllow: boolean = true;
  public otherCostsAllow: boolean = true;

  get apiObject(): any {
    return {
      username: this._username,
      password: this._password,
      person_key: this._person_key,
      permissions: this._permissions,
      company_key: this._company_key,
      ui_lang: this._ui_lang,
      ui_settings: this._ui_settings
    };
  }
}
