import { Component, HostBinding, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";

import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";
import { WebConnectivityService } from "../../service/web.connectivity.service";
import { MessageService } from "../../service/message.service";
import { StorageService } from "../../service/storage.service";
import { Vehicle } from "../../model/vehicle.object";
import { GoogleMapMarker } from "../../model/google-map-marker.object";
import { BrowserTools } from "../../tools/BrowserTools";
import { DateTools } from "../../tools/DateTools";

import { RVehicleGridDialogComponent } from "../r-vehicle-grid-dialog/r-vehicle-grid-dialog.component";
import { VehicleNewService } from "src/app/service/vehicle-new.service";

declare var document: any;

@Component({
  selector: 'vehicle-grid',
  templateUrl: './r-vehicle-grid.component.html',
  styleUrls: ['./r-vehicle-grid.component.css']
})
export class RVehicleGridComponent implements OnDestroy {

  @HostBinding('class') class = 'fill';

  private _beforeWasFull: boolean;
  private _controlsVisibleBefore: boolean;
  private _subscriptions: Array<Subscription> = [];

  private _vehicles: Array<Vehicle> = [];

  private _columns: number = 1;
  private _rows: number = 1;
  private _show_new_messages: boolean = false;
  private _dialogNewMessageBefore: boolean;
  private _connDown: boolean = false;
  private _map_heigth: string;

  private _compositionWidth: string;
  get compositionWidth(): string {
    return this._compositionWidth;
  }

  private _aetr_show: boolean = true;
  get aetr_show(): boolean {
    return this._aetr_show;
  }

  private _gantt_show: boolean = true;
  get gantt_show(): boolean {
    return this._gantt_show;
  }

  private _last_cargo_graphic_show: boolean = true;
  get last_cargo_graphic_show(): boolean {
    return this._last_cargo_graphic_show;
  }

  private _last_cargo_text_show: boolean = true;
  get last_cargo_text_show(): boolean {
    return this._last_cargo_text_show;
  }

  private _show_map: boolean = true;
  get show_map(): boolean {
    return this._show_map;
  }

  private _trailer_show: boolean = false;
  get trailer_show(): boolean {
    return this._trailer_show;
  }

  private _co_driver_show: boolean = false;
  get co_driver_show(): boolean {
    return this._co_driver_show;
  }

  private _note_show: boolean = false;
  get note_show(): boolean {
    return this._note_show;
  }

  private _truncate_note: number;
  get truncate_note(): number {
    return this._truncate_note;
  }

  private _truncate_city: number;
  get truncate_city(): boolean {
    return this._truncate_city > 0;
  }

  private _font_size: string = '100%';
  get font_size(): string {
    return this._font_size;
  }
  
  private _composition: Array<Array<Vehicle>> = [];
  get composition(): Array<Array<Vehicle>> {
    return this._composition;
  }

  constructor(
    private _layout: TruckManagerLayoutService,
    private _vehicleService: VehicleNewService,
    private _route: ActivatedRoute,
    private _webConn: WebConnectivityService,
    private _messageService: MessageService,
    private _storage: StorageService
  ) {
    this._dialogNewMessageBefore = _messageService.newMessagesDialogDisabled;
    // this._beforeWasFull = this._layout.isDashboardFullMode;
    this._controlsVisibleBefore = this._layout.isDashboardControlsVisible;
    // this._layout.isDashboardFullMode = true;
    let setupFromStorage: any;
    if (setupFromStorage = this._storage.getItem(RVehicleGridDialogComponent.CONFIGURATION_STORAGE_KEY, true)) {
      setupFromStorage = JSON.parse(setupFromStorage);
      this._rows = setupFromStorage.rows;
      this._columns = setupFromStorage.columns;
      this._aetr_show = setupFromStorage.aetr_show;
      this._gantt_show = setupFromStorage.gantt_show;
      this._last_cargo_graphic_show = setupFromStorage.last_cargo_graphic_show;
      this._last_cargo_text_show = setupFromStorage.last_cargo_text_show;
      this._compositionWidth = !isNaN(setupFromStorage.width) ? setupFromStorage.width + 'px' : setupFromStorage.width;
      this._map_heigth = setupFromStorage.map_height + 'px';
      this._show_map = setupFromStorage.show_map;
      this._show_new_messages = setupFromStorage.show_new_messages;
      this._trailer_show = setupFromStorage.trailer_show;
      this._co_driver_show = setupFromStorage.co_driver_show;
      this._note_show = setupFromStorage.note_show;
      if (setupFromStorage.truncate_note) {
        this._truncate_note = setupFromStorage.truncate_note;
      }
      if (setupFromStorage.truncate_city) {
        this._truncate_city = setupFromStorage.truncate_city;
      }

      this._font_size = setupFromStorage.font_size;


    } else {
      switch (true) {
        case window.innerWidth >= 225:
          this._font_size = 80 + '%';
          break;
        case window.innerWidth < 285:
          this._font_size = 60 + '%';
          break;
      }
      this._compositionWidth = window.innerWidth + 'px';
    }
    // setting font based map polyline weight
    // let fontPercentage = parseFloat(this._font_size);
    // GoogleMapCarComponent.RendererOptions.polylineOptions.strokeWeight *= (fontPercentage / 100);

    this._subscriptions.push(this._vehicleService.getVehicles().subscribe(
      vehicles => {
        let currentVehicles: Array<Vehicle> = [];
        let needNewInstance = false;
        vehicles.forEach(
          vehicle => {
            if (!setupFromStorage || !setupFromStorage.keys || setupFromStorage.keys.indexOf(vehicle.car_key) > -1) {
              if (this._vehicles.indexOf(vehicle) === -1) {
                needNewInstance = true;
              }
              currentVehicles.push(vehicle);
            }
          }
        );
        if (needNewInstance) {
          this._vehicles = currentVehicles;
          this.buildComposition(!setupFromStorage || !setupFromStorage.columns || !setupFromStorage.rows);
        }
      }
    ));
    _messageService.newMessagesDialogDisabled = !this._show_new_messages;
    this._subscriptions.push(
      _webConn.connectionDown.subscribe(
        isDown => this._connDown = isDown
      )
    );
    this._layout.isDashboardControlsVisible = false;
  }

  ngOnDestroy() {
    this._layout.isDashboardControlsVisible = this._controlsVisibleBefore;
    // this._layout.isDashboardFullMode = this._beforeWasFull;
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._messageService.newMessagesDialogDisabled = true;
  }
  

  get changeDetect(): Observable<Vehicle> {
    return this._vehicleService.vehicleDataChanged;
  }

  get icons(): any {
    return GoogleMapMarker.icons;
  }

  get mapHeight(): string {
    if (!this._map_heigth) {
      this._map_heigth = parseInt(this._compositionWidth) * (9 / 16) + 'px';
    }
    return this._map_heigth;
  }

  get lastLoadDate(): Date {
    return this._vehicleService.lastLoadDate;
  }

  get formattedLastLoadDate(): string {
    return DateTools.formatLocaleString(this.lastLoadDate, '%hours:%minutes');
  }

  get isConnDown(): boolean {
    return this._connDown;
  }

  get font_size_number(): number {
    return parseInt(this._font_size);
  }

  get tankVolumeHorizontal() {
    let beVertical = (BrowserTools.isMobileBrowser() && window.innerWidth < 600) || (!BrowserTools.isMobileBrowser() && window.innerWidth < 600);
    return beVertical;
  }

  toggleFullScreen() {
    if (!this.isFullScreen()) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  isFullScreen(): boolean {
    return (typeof document.fullscreenElement !== 'undefined' || typeof document.webkitFullscreenElement !== 'undefined');
  }

  isNotMobile() {
    return !BrowserTools.isMobileBrowser();
  }

  private setComposition(base: number) {
    let column: number, row: number, vehiclesLength: number = this._vehicles.length;
    let diff = vehiclesLength / base;
    switch (true) {
      case base < diff && window.innerHeight < window.innerWidth:
        column = diff;
        row = base;
        break;
      case base > diff && window.innerHeight < window.innerWidth:
        column = base;
        row = diff;
        break;
      case base < diff && window.innerHeight > window.innerWidth:
        row = diff;
        column = base;
        break;
      case base > diff && window.innerHeight > window.innerWidth:
        row = base;
        column = diff;
        break;
    }
    this._columns = column;
    this._rows = row;
  }

  private buildComposition(requireFullBuildUp: boolean) {
    if (requireFullBuildUp) {
      let square: number;
      let ideal = (square = Math.sqrt(this._vehicles.length)) % 1 == 0;
      let two = (this._vehicles.length / 2) % 1 == 0;
      let three = (this._vehicles.length / 3) % 1 == 0;
      let four = (this._vehicles.length / 4) % 1 == 0;
      let five = (this._vehicles.length / 5) % 1 == 0;
      let six = two && three;

      switch (true) {
        case ideal:
          this._columns = square;
          this._rows = square;
          break;
        case six:
          this.setComposition(6);
          break;
        case five:
          this.setComposition(5);
          break;
        case four:
          this.setComposition(4);
          break;
        case three:
          this.setComposition(3);
          break;
        case two:
          this.setComposition(2);
          break;

      }
    }
    let composition = [];
    let index: number = 0;
    let currentVehicle: Vehicle = this._vehicles[index];
    for (let j = 0; j < this._rows; j++) {
      let row = [];
      for (let i = 0; i < this._columns; i++) {
        row.push(currentVehicle);
        currentVehicle = this._vehicles[++index];
        if (!currentVehicle) {
          break;
        }
      }
      composition.push(row);
      if (!currentVehicle) {
        break;
      }
    }
    this._composition = composition;
    //subtraction 4 cause of 2px border on each vehicle window in template
    this._compositionWidth = (((window.innerWidth) / this._columns) - 6) + 'px';
  }
}
