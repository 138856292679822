import { Attachment } from "./attachment.object";
import { DriverCard } from "./driver-card.object";

export class Driver {
  constructor(
  ) {
  }

  private _update: Array<string> = [];
  get update(): Array<string> {
    return this._update;
  }
  set update(value: Array<string>) {
    this._update = value;
  }

  private _driver_key: number;
  get driver_key(): number {
    return this._driver_key;
  }
  set driver_key(value: number) {
    this._driver_key = value;
  }

  private _name: string;
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    this._update.push("name");
  }

  private _active: boolean;
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
    this._update.push("active");
  }

  private _adr: number;
  get adr(): number {
    return this._adr;
  }
  set adr(value: number) {
    this._adr = value;
    this._update.push("adr");
  }

  private _company_key: number;
  get company_key(): number {
    return this._company_key;
  }
  set company_key(value: number) {
    this._company_key = value;
  }

  private _currency: string;
  get currency(): string {
    return this._currency;
  }
  set currency(value: string) {
    this._currency = value;
    this._update.push("currency");
  }

  private _expenses: boolean;
  get expenses(): boolean {
    return this._expenses;
  }
  set expenses(value: boolean) {
    this._expenses = value;
    this._update.push("expenses");
  }

  private _phone: string | any;
  get phone(): string | any {
    return this._phone;
  }
  set phone(value: string | any) {
    this._phone = value;
    this._update.push("phone");
  }

  private _surcharge_holiday: number;
  get surcharge_holiday(): number {
    return this._surcharge_holiday;
  }
  set surcharge_holiday(value: number) {
    this._surcharge_holiday = value;
    this._update.push("surcharge_holiday");
  }

  private _surcharge_night: number;
  get surcharge_night(): number {
    return this._surcharge_night;
  }
  set surcharge_night(value: number) {
    this._surcharge_night = value;
    this._update.push("surcharge_night");
  }

  private _surcharge_overtime: number;
  get surcharge_overtime(): number {
    return this._surcharge_overtime;
  }
  set surcharge_overtime(value: number) {
    this._surcharge_overtime = value;
    this._update.push("surcharge_overtime");
  }

  private _surcharge_saturday: number;
  get surcharge_saturday(): number {
    return this._surcharge_saturday;
  }
  set surcharge_saturday(value: number) {
    this._surcharge_saturday = value;
    this._update.push("surcharge_saturday");
  }
  
  private _surcharge_sunday: number;
  get surcharge_sunday(): number {
    return this._surcharge_sunday;
  }
  set surcharge_sunday(value: number) {
    this._surcharge_sunday = value;
    this._update.push("surcharge_sunday");
  }

  private _wage_cargo: number;
  get wage_cargo(): number {
    return this._wage_cargo;
  }
  set wage_cargo(value: number) {
    this._wage_cargo = value;
    this._update.push("wage_cargo");
  }

  private _wage_cargo_mins: number;
  get wage_cargo_mins(): number {
    return this._wage_cargo_mins;
  }
  set wage_cargo_mins(value: number) {
    this._wage_cargo_mins = value;
    this._update.push("wage_cargo_mins");
  }

  private _wage_cargo_multiple_only: boolean;
  get wage_cargo_multiple_only(): boolean {
    return this._wage_cargo_multiple_only;
  }
  set wage_cargo_multiple_only(value: boolean) {
    this._wage_cargo_multiple_only = value;
    this._update.push("wage_cargo_multiple_only");
  }

  private _wage_cleaning: number;
  get wage_cleaning(): number {
    return this._wage_cleaning;
  }
  set wage_cleaning(value: number) {
    this._wage_cleaning = value;
    this._update.push("wage_cleaning");
  }

  private _wage_hourly: number;
  get wage_hourly(): number {
    return this._wage_hourly;
  }
  set wage_hourly(value: number) {
    this._wage_hourly = value;
    this._update.push("wage_hourly");
  }

  private _wage_hourly_in_car: number;
  get wage_hourly_in_car(): number {
    return this._wage_hourly_in_car;
  }
  set wage_hourly_in_car(value: number) {
    this._wage_hourly_in_car = value;
    this._update.push("wage_hourly_in_car");
  }

  private _wage_km: number | any;
  get wage_km(): number | any {
    return this._wage_km;
  }
  set wage_km(value: number | any) {
    this._wage_km = value;
    this._update.push("wage_km");
  }

  private _wage_monthly: number;
  get wage_monthly(): number {
    return this._wage_monthly;
  }
  set wage_monthly(value: number) {
    this._wage_monthly = value;
    this._update.push("wage_monthly");
  }

  private _wage_preparation: number;
  get wage_preparation(): number {
    return this._wage_preparation;
  }
  set wage_preparation(value: number) {
    this._wage_preparation = value;
    this._update.push("wage_preparation");
  }

  private _driver_documents: Array<Attachment> = [];
  public get driver_documents(): Array<Attachment> {
    return this._driver_documents;
  }
  public set driver_documents(value: Array<Attachment>) {
    this._driver_documents = value;
  }

  private _tacho_card: DriverCard;
  public get tacho_card(): DriverCard {
    return this._tacho_card;
  }
  public set tacho_card(value: DriverCard) {
    this._tacho_card = value;
    if (this._tacho_card) {
      this._card_no = this._tacho_card.card_no;
    }
  }

  // this attribute is used for create/update (not GET)
  private _card_no: string;
  public get card_no(): string {
    return this._card_no;
  }
  public set card_no(value: string) {
    this._update.push("card_no");
    this._card_no = value;
  }

  // custom
  private _marked: boolean = false;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }

  public get apiObject(): any {
    let data: any = {
      active: this.active,
      adr: this.adr,
      currency: this.currency,
      card_no: this.card_no,
      expenses: this.expenses,
      name: this.name,
      phone: this.phone,
      surcharge_holiday: this.surcharge_holiday, 
      surcharge_night: this.surcharge_night,
      surcharge_overtime: this.surcharge_overtime,
      surcharge_saturday: this._surcharge_sunday,
      surcharge_sunday: this._surcharge_sunday,
      wage_cargo: this.wage_cargo,
      wage_cargo_mins: this.wage_cargo_mins,
      wage_cargo_multiple_only: this.wage_cargo_multiple_only,
      wage_cleaning: this.wage_cleaning,
      wage_hourly: this.wage_hourly,
      wage_hourly_in_car: this.wage_hourly_in_car,
      wage_km: this.wage_km,
      wage_monthly: this.wage_monthly,
      wage_preparation: this.wage_preparation
    };

    // if (this.card_no && this.card_no.length) {
    // data.card_no = this.card_no;
    // }

    return data;
  }
}