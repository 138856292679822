import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { VehicleListConfigurationInterface } from "src/app/interface/vehicle-list-configuration.interface";
import { UserConfigurationService } from "src/app/service/user-configuration.service";

// declare let gtag: Function;

@Component({
  selector: 'div#vehicle-list',
  templateUrl: './r-vehicle-list.component.html',
  styleUrls: ['./r-vehicle-list.component.css']
})
export class RVehicleListComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];

  private _vehicleListConfig: VehicleListConfigurationInterface;
  public get vehicleListConfig(): VehicleListConfigurationInterface {
    return this._vehicleListConfig;
  }

  constructor(
    private _configurationServ: UserConfigurationService
  ) {
    // load user configuration  
    this._vehicleListConfig = this._configurationServ.configuration.defaultVehicleListConfiguration;

    // subscibe changes
    this._subscribed.push(
      this._configurationServ.configChanged.subscribe(
        () => {
          this._vehicleListConfig = this._configurationServ.configuration.defaultVehicleListConfiguration;
        }
      )
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      s => { s.unsubscribe(); }
    );
    this._subscribed = [];
  }
}