import { EventEmitter } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HomeStandKey } from "./home-stand-key.object";
import { Model } from "./model.object";
import { Trailer } from "./trailer.object";
import { GeoPosition } from "./geo-position.object";
import { CargoActivity } from "./cargo-activity.object";
import { Agenda } from "./agenda.object";
import { GoogleMapMarker } from "./google-map-marker.object";
import { GoogleLatLng } from "./google-lat-lng.object";
import { Message } from "./message.object";
import { AetrDataCollectionObject } from "./aetr-data-collection.object";
import { defaultLengthUnit, ServiceType, TrackingStatus } from "../config";
import { DateTools } from "../tools/DateTools";
import { Haversine } from "../tools/Haversine";
import { VehicleHealthObject } from "./vehicle-health.object";
import { TrackingEventDataObject } from "./tracking-event-data.object";
import { AetrHistoryCollectionObject } from "./aetr-history-collection.object";
import { ServiceEventObject } from "./service-event.object";
import { AetrActivityDataInterface } from "../interface/aetr-activity-data.interface";
import { ManualTrackingTargetInterface } from "../interface/manual-tracking-target.interface";
import { ServiceTask } from "./service-task.object";
import { ExternalImport } from "./external-import.object";
import { ExpressRoute } from "./express-route.object";
import { Driver } from "./driver.object";
import { SimCard } from "./simcard.object";
import { Person } from "./person.object";
import { TrackingEvent } from "./tracking-event.object";


export class Vehicle {

  static TONNAGE_SUCCESS: string = 'success';
  static TONNAGE_DANGER: string = 'danger';

  static DETAIL_TYPE_LAST_POSITION_MAP: string = 'last-position';
  static DETAIL_TYPE_LAST_CARGO_MAP: string = 'last-cargo';
  static DETAIL_TYPE_AGENDA_DETAIL: string = 'agenda-detail';
  static DETAIL_TYPE_VEHICLE_MESSAGES: string = 'vehicle-messages';
  static DETAIL_TYPE_DIAGNOSTICS: string = 'vehicle-diagnostics';
  static DETAIL_TYPE_ACTIVITY_HISTORY: string = 'activity-history';
  static DETAIL_TYPE_VEHICLE_INFO: string = 'vehicle-info';

  // status constants
  static LOADING_START = ServiceType.LOADING_START;
  static LOADING_END = ServiceType.LOADING_END;
  static UNLOADING_START = ServiceType.UNLOADING_START;
  static UNLOADING_END = ServiceType.UNLOADING_END;
  static MOVING_FULL = TrackingStatus.MOVE;
  static MOVING_EMPTY = 'm';
  static INTERRUPTION = TrackingStatus.INTERRUPTION;
  static MOVING_LEFT_FULL = 'M_left';
  static MOVING_LEFT_EMPTY = 'm_left';
  static MOVING_RIGHT_FULL = 'M_right';
  static MOVING_RIGHT_EMPTY = 'm_right';
  static SLEEP = TrackingStatus.SLEEP_START;
  static SLEEP_END = TrackingStatus.SLEEP_END;
  static FERRY = TrackingStatus.FERRY;
  static STOP = TrackingStatus.STOP_START;
  static STOP_END = TrackingStatus.STOP_END;

  static MARKER_ICON_LOADING = 'L';
  static MARKER_ICON_UNLOADING = 'U';
  static MARKER_ICON_START = 'C';
  static MARKER_ICON_END = 'D';
  
  private readonly DRIVING_DIRECTION_LEFT: string = '_left';
  private readonly DRIVING_DIRECTION_RIGHT: string = '_right';

  private _map_show: boolean = false;
  private _map_cargo_show: boolean = false;
  private _agenda_detail_show: boolean = false;
  private _markersMap: Array<GoogleMapMarker> = [];
  private _markersMapCargo: Array<GoogleMapMarker> = [];
  private _aetrLoading: boolean = false;
  private _aetrLoaded: EventEmitter<AetrDataCollectionObject> = new EventEmitter();
  private _latestAetrLoading: boolean = false;
  
  private _delayedLoading: boolean = false;
  public get delayedLoading(): boolean {
    return this._delayedLoading;
  }
  public set delayedLoading(value: boolean) {
    this._delayedLoading = value;
  }

  private _healthLoading: boolean = false;
  public get healthLoading(): boolean {
    return this._healthLoading;
  }

  /* Array of changed properties in vehicle list */
  private _update: Array<string> = [];
  get update(): Array<string> {
    return this._update;
  }
  set update(value: Array<string>) {
    this._update = value;
  }

  private _detail_visible: boolean = false;
  set detail_visible(visible: boolean) {
    this._detail_visible = visible;
  }
  get is_detail_visible(): boolean {
    return this._detail_visible;
  }

  private _detail_type: string;
  get detail_type(): string {
    return this._detail_type;
  }
  set detail_type(type: string) {
    if (type &&
      [
        Vehicle.DETAIL_TYPE_AGENDA_DETAIL,
        Vehicle.DETAIL_TYPE_LAST_CARGO_MAP,
        Vehicle.DETAIL_TYPE_LAST_POSITION_MAP,
        Vehicle.DETAIL_TYPE_VEHICLE_MESSAGES,
        Vehicle.DETAIL_TYPE_DIAGNOSTICS,
        Vehicle.DETAIL_TYPE_ACTIVITY_HISTORY,
        Vehicle.DETAIL_TYPE_VEHICLE_INFO
      ].indexOf(
        type
      ) === -1
    ) {
      throw new Error('Undefined type "' + type + '"');
    }
    this._detail_type = type;
  }

  isAgendaDetailType(): boolean {
    return this._detail_type === Vehicle.DETAIL_TYPE_AGENDA_DETAIL;
  }

  isVehicleMessagesDetailType(): boolean {
    return this._detail_type === Vehicle.DETAIL_TYPE_VEHICLE_MESSAGES;
  }

  isLastPositionMapDetailType(): boolean {
    return this._detail_type === Vehicle.DETAIL_TYPE_LAST_POSITION_MAP;
  }

  isLastCargoMapDetailType(): boolean {
    return this._detail_type === Vehicle.DETAIL_TYPE_LAST_CARGO_MAP;
  }

  isDiagnosticsDetailType() {
    return this._detail_type === Vehicle.DETAIL_TYPE_DIAGNOSTICS;
  }

  isActivityDetailType() {
    return this._detail_type === Vehicle.DETAIL_TYPE_ACTIVITY_HISTORY;
  }

  isInfoType() {
    return this._detail_type === Vehicle.DETAIL_TYPE_VEHICLE_INFO;
  }

  // custom property to identify specific index in array 
  // (now using only for google map directions color)
  private _index: number = 0;
  public get index(): number {
    return this._index;
  }
  public set index(value: number) {
    this._index = value;
  }

  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }

  private _company_key: number;
  get company_key(): number {
    return this._company_key;
  }
  set company_key(value: number) {
    this._company_key = value;
  }

  private _autopilot: boolean = false;
  public get autopilot(): boolean {
    return this._autopilot;
  }
  public set autopilot(value: boolean) {
    this._autopilot = value;
  }

  private _sim_imsi: string;
  get sim_imsi(): string {
    return this._sim_imsi;
  }
  set sim_imsi(value: string) {
    this._sim_imsi = value;
    this._update.push("sim_imsi");
  }
  
  // custom
  private _simcard: SimCard;
  public get simcard(): SimCard {
    return this._simcard;
  }
  public set simcard(value: SimCard) {
    this._simcard = value;
  }
  
  // custom
  private _sim_number: string;
  public get sim_number(): string {
    return this._sim_number;
  }
  public set sim_number(value: string) {
    this._sim_number = value;
  }

  private _make: string;
  get make(): string {
    return this._make;
  }
  set make(value: string) {
    this._make = value;
    // make is obsolete and we update just model (viz doc)
    this._update.push("model_id");
  }

  private _color: string;
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._color = value;
    this._update.push("color");
  }

  // custom - specific gold tariff color
  private _isGoldTariff: boolean = false;
  public get isGoldTariff(): boolean {
    return this._isGoldTariff;
  }
  public set isGoldTariff(value: boolean) {
    this._isGoldTariff = value;
  }

  private _production_year: Date;
  get production_year(): Date {
    return this._production_year;
  }
  set production_year(value: Date) {
    this._production_year = new Date(value);
    if (this._production_year) {
      this._production_year_number = this._production_year.getFullYear();

      // formatted age of vehicle in year
      let date: Date = new Date();
      let currentYear: number = date.getFullYear();
      let diff = currentYear - this._production_year_number;
      this._age = '~' + diff;
      if (diff <= 1) {
        this._age_year_formatted = $localize`rok`;
      }
      else if (diff > 1 && diff < 5) {
        this._age_year_formatted = $localize`roky`;
      }
      else {
        this._age_year_formatted = 'let';
      }
    }
  }
  
  private _production_year_number: number;
  get production_year_number(): number {
    return this._production_year_number;
  }
  set production_year_number(value: number) {
    this._production_year_number = value;
    if (value) {
      // set on June 30 of selected year
      this._production_year = new Date(value.toString() + '-06-30');
    }
    this._update.push("production_year");
  }

  private _accounting_center: string;
  public get accounting_center(): string {
    return this._accounting_center;
  }
  public set accounting_center(value: string) {
    this._accounting_center = value;
    this._update.push("accounting_center");
  }
  
  private _pre_accounting: string;
  public get pre_accounting(): string {
    return this._pre_accounting;
  }
  public set pre_accounting(value: string) {
    this._pre_accounting = value;
    this._update.push("pre_accounting");
  }

  private _age: string;
  public get age(): string {
    return this._age;
  }
  public set age(value: string) {
    this._age = value;
  }
  
  private _age_year_formatted: string;
  public get age_year_formatted(): string {
    return this._age_year_formatted;
  }
  public set age_year_formatted(value: string) {
    this._age_year_formatted = value;
  }

  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    this._update.push("type");
  }

  private _tonnage: number = 0;
  get tonnage(): number {
    return this._tonnage;
  }
  set tonnage(value: number) {
    this._tonnage = value;
    this._update.push("tonnage");
  }

  private _capacity: number;
  get capacity(): number {
    return this._capacity;
  }
  set capacity(value: number) {
    this._capacity = value;
    this._update.push("capacity");
  }

  private _length: number;
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
    this._update.push("length");
  }

  private _width: number;
  get width(): number {
    return this._width;
  }
  set width(value: number) {
    this._width = value;
    this._update.push("width");
  }

  private _height: number;
  get height(): number {
    return this._height;
  }
  set height(value: number) {
    this._height = value;
    this._update.push("height");
  }

  private _adr: string;
  get adr(): string {
    return this._adr;
  }
  set adr(value: string) {
    this._adr = value;
    this._update.push("adr");
  }

  private _offer: Boolean;
  get offer(): Boolean {
    return this._offer;
  }
  set offer(value: Boolean) {
    this._offer = value;
  }

  private _pos_vicinity: number;
  get pos_vicinity(): number {
    return this._pos_vicinity;
  }
  set pos_vicinity(value: number) {
    this._pos_vicinity = value;
  }

  private _description: string;
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
    this._update.push("description");
  }

  private _person_key: number;
  get person_key(): number {
    return this._person_key;
  }
  set person_key(value: number) {
    this._person_key = value;
    this._update.push("person_key");
  }

  // custom
  private _person: Person;
  public get person(): Person {
    return this._person;
  }
  public set person(value: Person) {
    this._person = value;
  }
  
  // custom
  private _person_name: string;
  public get person_name(): string {
    return this._person_name;
  }
  public set person_name(value: string) {
    this._person_name = value;
  }

  private _driver_key: number;
  get driver_key(): number {
    return this._driver_key;
  }
  set driver_key(value: number) {
    this._driver_key = value;
    this._update.push("driver_key");
  }

  private _driver2_key: number;
  get driver2_key(): number {
    return this._driver2_key;
  }
  set driver2_key(value: number) {
    this._driver2_key = value;
    this._update.push("driver2_key");
  }

  // custom
  private _driver: Driver;
  public get driver(): Driver {
    return this._driver;
  }
  public set driver(value: Driver) {
    this._driver = value;
  }

  // custom
  private _driver2: Driver;
  public get driver2(): Driver {
    return this._driver2;
  }
  public set driver2(value: Driver) {
    this._driver2 = value;
  }

  // custom
  private _driver_name: string;
  public get driver_name(): string {
    return this._driver_name;
  }
  public set driver_name(value: string) {
    this._driver_name = value;
  }
  
  // custom
  private _driver_phone: string;
  public get driver_phone(): string {
    return this._driver_phone;
  }
  public set driver_phone(value: string) {
    this._driver_phone = value;
  }
  
  // custom
  private _driver2_name: string;
  get driver2_name(): string {
    return this._driver2_name;
  }
  set driver2_name(value: string) {
    this._driver2_name = value;
  }

  private _home_stand: HomeStandKey;
  get home_stand(): HomeStandKey {
    return this._home_stand;
  }
  set home_stand(value: HomeStandKey) {
    this._home_stand = value;
  }

  private _home_stand_name: string;
  get home_stand_name(): string {
    return this._home_stand_name;
  }
  set home_stand_name(value: string) {
    this._home_stand_name = value;
  }

  private _home_stand_key: number;
  get home_stand_key(): number {
    return this._home_stand_key;
  }
  set home_stand_key(value: number) {
    this._home_stand_key = value;
    this._update.push("home_stand_key");
  }

  // custom
  private _alt_home_stands: Array<HomeStandKey> = [];
  public get alt_home_stands(): Array<HomeStandKey> {
    return this._alt_home_stands;
  }
  public set alt_home_stands(value: Array<HomeStandKey>) {
    this._alt_home_stands = value;
  }

  private _export_allowed: Boolean;
  get export_allowed(): Boolean {
    return this._export_allowed;
  }
  set export_allowed(value: Boolean) {
    this._export_allowed = value;
  }

  private _export_requested: Boolean;
  get export_requested(): Boolean {
    return this._export_requested;
  }
  set export_requested(value: Boolean) {
    this._export_requested = value;
  }

  private _export_from;
  get export_from() {
    return this._export_from;
  }
  set export_from(value) {
    this._export_from = value;
  }

  private _export_to;
  get export_to() {
    return this._export_to;
  }
  set export_to(value) {
    this._export_to = value;
  }

  private _export_type;
  get export_type() {
    return this._export_type;
  }
  set export_type(value) {
    this._export_type = value;
  }

  private _last_modif: Date;
  get last_modif(): Date {
    return this._last_modif;
  }
  set last_modif(value) {
    if (value) {
      this._last_modif = DateTools.isoFix(value.toString());
    }
  }

  private _axles;
  get axles() {
    return this._axles;
  }
  set axles(value) {
    this._axles = value;
    this._update.push("axles");
  }

  private _amortization;
  get amortization() {
    return this._amortization;
  }
  set amortization(value) {
    this._amortization = value;
    this._update.push("amortization");
  }

  private _leasing;
  get leasing() {
    return this._leasing;
  }
  set leasing(value) {
    this._leasing = value;
    this._update.push("leasing");
  }

  private _currency: string;
  get currency(): string {
    return this._currency;
  }
  set currency(value: string) {
    this._currency = value;
  }

  private _euro_emission: number;
  get euro_emission(): number {
    return this._euro_emission;
  }
  set euro_emission(value: number) {
    this._euro_emission = value;
    this._update.push("euro_emission");
  }

  private _car_tonnage: number;
  get car_tonnage(): number {
    return this._car_tonnage;
  }
  set car_tonnage(value: number) {
    this._car_tonnage = value;
    this._update.push("car_tonnage");
  }

  private _car_length: number;
  get car_length(): number {
    return this._car_length;
  }
  set car_length(value: number) {
    this._car_length = value;
  }

  private _car_width: number;
  get car_width(): number {
    return this._car_width;
  }
  set car_width(value: number) {
    this._car_width = value;
  }

  private _car_height: number;
  get car_height(): number {
    return this._car_height;
  }
  set car_height(value: number) {
    this._car_height = value;
  }

  private _liability_insurance;
  get liability_insurance() {
    return this._liability_insurance;
  }
  set liability_insurance(value) {
    this._liability_insurance = value;
    this._update.push("liability_insurance");
  }

  private _accident_insurance;
  get accident_insurance() {
    return this._accident_insurance;
  }
  set accident_insurance(value) {
    this._accident_insurance = value;
    this._update.push("accident_insurance");
  }

  private _cargo_insurance;
  get cargo_insurance() {
    return this._cargo_insurance;
  }
  set cargo_insurance(value) {
    this._cargo_insurance = value;
    this._update.push("cargo_insurance");
  }

  private _trace_stops: number;
  get trace_stops(): number {
    return this._trace_stops;
  }
  set trace_stops(value: number) {
    this._trace_stops = value;
  }

  private _consuption_max: number;
  get consuption_max(): number {
    return this._consuption_max;
  }
  set consuption_max(value: number) {
    this._consuption_max = value;
  }

  private _inactive: Boolean;
  get inactive(): Boolean {
    return this._inactive;
  }
  set inactive(value: Boolean) {
    this._inactive = value;
    this._update.push("inactive");
  }

  private _speed_coef: number;
  get speed_coef(): number {
    return this._speed_coef;
  }
  set speed_coef(value: number) {
    this._speed_coef = value;
    this._update.push("speed_coef");
  }

  private _eco_speed: number;
  get eco_speed(): number {
    return this._eco_speed;
  }
  set eco_speed(value: number) {
    this._eco_speed = value;
    this._update.push("eco_speed");
  }

  private _eco_acceleration_coef: number;
  get eco_acceleration_coef(): number {
    return this._eco_acceleration_coef;
  }
  set eco_acceleration_coef(value: number) {
    this._eco_acceleration_coef = value;
  }

  private _eco_braking_coef: number;
  get eco_braking_coef(): number {
    return this._eco_braking_coef;
  }
  set eco_braking_coef(value: number) {
    this._eco_braking_coef = value;
  }

  private _model: Model;
  get model(): Model {
    return this._model;
  }
  set model(value: Model) {
    this._model = value;
  }

  private _number_plate: string;
  get number_plate(): string {
    return this._number_plate;
  }
  set number_plate(value: string) {
    this._number_plate = value;
    this._update.push("number_plate");
  }

  private _trailer: Trailer;
  get trailer(): Trailer {
    return this._trailer;
  }
  set trailer(value: Trailer) {
    this._trailer = value;
    if (this._trailer && this._trailer.model && this._trailer.model.make) {
      this._trailer_icon = 'assets/makes/' + this._trailer.model.make.name + '.svg';
    }
  }

  // custom
  get number_plate_trailer(): string {
    return (this.trailer ? this.trailer.number_plate : '');
  }
  
  // custom
  private _trailer_icon: string = null;
  public get trailer_icon(): string {
    return this._trailer_icon;
  }
  public set trailer_icon(value: string) {
    this._trailer_icon = value;
  }

  private _engine_power: number;
  get engine_power(): number {
    return this._engine_power;
  }
  set engine_power(value: number) {
    this._engine_power = value;
    this._update.push("engine_power");
  }

  private _my_property: boolean;
  get my_property(): boolean {
    return this._my_property;
  }
  set my_property(value: boolean) {
    this._my_property = value;
    this._update.push("my_property");
  }

  private _leasing_until: Date;
  get leasing_until(): Date {
    return this._leasing_until;
  }
  set leasing_until(value: Date) {
    this._leasing_until = value;
    this._update.push("leasing_until");
  }

  private _road_tax: number;
  get road_tax(): number {
    return this._road_tax;
  }
  set road_tax(value: number) {
    this._road_tax = value;
    this._update.push("road_tax");
  }

  private _vin: string;
  get vin(): string {
    return this._vin;
  }
  set vin(value: string) {
    this._vin = value;
    this._update.push("vin");
  }

  private _trailer_key: number;
  get trailer_key(): number {
    return this._trailer_key;
    // return (this.trailer ? this.trailer.trailer_key : null);
  }
  set trailer_key(value: number) {
    this._trailer_key = value;
    this._update.push("trailer_key");
  }

  private _model_id: number;
  get model_id(): number {
    return this._model_id;
  }
  set model_id(value: number) {
    this._model_id = value;
    this._update.push("model_id");
  }

  private _make_id: number;
  get make_id(): number {
    return this._make_id;
  }
  set make_id(value: number) {
    this._make_id = value;
  }

  private _trailer_key_duplicit: boolean;
  get trailer_key_duplicit(): boolean {
    return this._trailer_key_duplicit;
  }
  set trailer_key_duplicit(value: boolean) {
    this._trailer_key_duplicit = value;
  }

  private _driver_key_duplicit: boolean;
  get driver_key_duplicit(): boolean {
    return this._driver_key_duplicit;
  }
  set driver_key_duplicit(value: boolean) {
    this._driver_key_duplicit = value;
  }

  private _driver2_key_duplicit: boolean;
  get driver2_key_duplicit(): boolean {
    return this._driver2_key_duplicit;
  }
  set driver2_key_duplicit(value: boolean) {
    this._driver2_key_duplicit = value;
  }

  private _sim_imsi_duplicit: boolean;
  get sim_imsi_duplicit(): boolean {
    return this._sim_imsi_duplicit;
  }
  set sim_imsi_duplicit(value: boolean) {
    this._sim_imsi_duplicit = value;
  }

  private _taInBoxes: any;
  get taInBoxes(): any {
    return this._taInBoxes;
  }
  set taInBoxes(value: any) {
    this._taInBoxes = value;
  }

  private _cargo_activity_type: string;
  get cargo_activity_type(): string {
    return this._cargo_activity_type;
  }
  set cargo_activity_type(value: string) {
    this._cargo_activity_type = value;
  }

  private _cargo_activity_status: string;
  get cargo_activity_status(): string {
    return this._cargo_activity_status;
  }
  set cargo_activity_status(value: string) {
    this._cargo_activity_status = value;
  }

  private _cargo_activity_title: string;
  get cargo_activity_title(): string {
    return this._cargo_activity_title;
  }
  set cargo_activity_title(value: string) {
    this._cargo_activity_title = value;
  }

  private _consumption_avg: number;
  get consumption_avg(): number {
    return this._consumption_avg;
  }
  set consumption_avg(value: number) {
    this._consumption_avg = value;
    this._update.push("consumption_avg");
  }

  private _consumption_normed: number;
  public get consumption_normed(): number {
    return this._consumption_normed;
  }
  public set consumption_normed(value: number) {
    this._consumption_normed = value;
    this._update.push("consumption_normed");
  }

  private _consumption_increase_per_ton: number;
  public get consumption_increase_per_ton(): number {
    return this._consumption_increase_per_ton;
  }
  public set consumption_increase_per_ton(value: number) {
    this._consumption_increase_per_ton = value;
    this._update.push("consumption_increase_per_ton");
  }

  private _last_refueling_liters: number;
  get last_refueling_liters(): number {
    return this._last_refueling_liters;
  }
  set last_refueling_liters(value: number) {
    this._last_refueling_liters = value;
  }

  private _last_refueling_distance: number;
  get last_refueling_distance(): number {
    return this._last_refueling_distance;
  }
  set last_refueling_distance(value: number) {
    this._last_refueling_distance = value;
  }

  private _last_refueling_time: string;
  get last_refueling_time(): string {
    return this._last_refueling_time;
  }
  set last_refueling_time(value: string) {
    this._last_refueling_time = value;
  }

  private _tank_volume: number = 0.0;
  get tank_volume(): number {
    if (!this._tank_volume) {
      return 0.0;
    }
    return this._tank_volume;
  }
  set tank_volume(value: number) {
    this._tank_volume = value;
    this._update.push("tank_volume");
  }
  
  private _tank_level: number;
  public get tank_level(): number {
    return this._tank_level;
  }
  public set tank_level(value: number) {
    this._tank_level = value;
  }
  

  /******************************************/
  /* Messaging */
  /******************************************/
  private _messages: Array<Message> = [];
  get messages(): Array<Message> {
    return this._messages;
  }
  
  addMessage(message: Message): number {
    if (message && this._messages.indexOf(message) === -1) {
      return this._messages.push(message) - 1;
    }
    return -1;
  }

  isMoving(): boolean {
    let notMovingStatus = [
      Vehicle.STOP, Vehicle.SLEEP, Vehicle.UNLOADING_START, Vehicle.LOADING_START
    ];
    return (
      notMovingStatus.indexOf(this.truck_status) === -1 ||
      notMovingStatus.indexOf(this.real_truck_status) === -1
    )
  }

  private _marked: boolean = true;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }
  
  private _broadcastMarked: boolean = true;
  public get broadcastMarked(): boolean {
    return this._broadcastMarked;
  }
  public set broadcastMarked(value: boolean) {
    this._broadcastMarked = value;
  }
  
  
  /******************************************/
  /* Last Cargo Activity */
  /******************************************/
  private _last_cargo_activity: CargoActivity;
  get last_cargo_activity(): CargoActivity {
    return this._last_cargo_activity;
  }
  set last_cargo_activity(value: CargoActivity) {
    if (value) {
      this._last_cargo_activity = value;
      value.vehicle = this;
    }
  }

  isDoingService() {
    if (this.last_cargo_activity && [Vehicle.UNLOADING_START, Vehicle.LOADING_START].indexOf(this.last_cargo_activity.type) > -1) {
      return true;
    }
    return false;
  }

  haveFinishedService() {
    if (this.last_cargo_activity && [Vehicle.UNLOADING_END, Vehicle.LOADING_END].indexOf(this.last_cargo_activity.type) > -1) {
      return true;
    }
    return false;
  }


  /******************************************/
  /* Last Position */
  /******************************************/
  private _last_position: GeoPosition;
  get last_position(): GeoPosition {
    return this._last_position;
  }
  set last_position(value: GeoPosition) {
    this._last_position = value;
    // compute tonnage percentage
    if (!this.last_position) {
      this._tonnage_percentage = 0;
      this._tonnage_progress_bar = 0;
    }
    else {
      if (this.last_position.cargo_weight == 0 && this.tonnage == 0) {
        this._tonnage_percentage = 0;
        this._tonnage_progress_bar = 0;
      }
      else if (this.tonnage == 0) {
        this._tonnage_progress_bar = 100;
        this._tonnage_percentage = 101;
      }
      else {
        let percentage = Math.round(this.last_position.cargo_weight / (this.tonnage / 100));
        this._tonnage_percentage = percentage;
        if (percentage > 100) {
          percentage = 100;
        }
        this._tonnage_progress_bar = percentage;
      }

      // find any old agenda
      this._oldAgendas = false;
      if (this._last_position.order_number && this._last_position.order_number.length) {
        this._last_position.order_number.forEach(
          order_number => {
            if (!this._last_position.agendas || !this._last_position.agendas[order_number]) {
              // some old agenda founded
              this._oldAgendas = true;
            }
          }
        );
      }
    }
  }

  // custom
  private _oldAgendas: boolean = false;
  public get oldAgendas(): boolean {
    return this._oldAgendas;
  }

  lastRefueledBefore(days: number): boolean {
    if (!this._last_position || this._last_position.time.getTime() + days * 24 * 60 * 60 * 1000 > Date.now()) {
      return false;
    }
    if (this._last_refueling_time) {
      let ms = days * 24 * 60 * 60 * 1000;
      return Date.now() > DateTools.isoFix(this._last_refueling_time).getTime() + ms;
    } else {
      return true;
    }
  }

  getCurrentPositionMarker(): GoogleMapMarker {
    let marker = new GoogleMapMarker();
    if (this.last_position) {
      let pos = new GoogleLatLng(this.last_position.googleLatLang.lat, this.last_position.googleLatLang.lng);
      marker.position = pos;
      let icon = this.truck_status;
      if (icon == Vehicle.MOVING_FULL || icon == Vehicle.MOVING_EMPTY) {
        icon += this.drivingDirection;
      };
      marker.zIndex = 1000;
      marker.icon = GoogleMapMarker.icons[icon];
      marker.vehicle = this;
      return marker;
    }
    return null;
  }

  get tonnage_overweight(): boolean {
    if (this.last_position) {
      return this.last_position.cargo_weight > this.tonnage;
    }
    return false;
  }

  // custom
  private _tonnage_percentage: number = 0;
  public get tonnage_percentage(): number {
    return this._tonnage_percentage;
  }
  
  // max 100 percent
  private _tonnage_progress_bar: number = 0;
  public get tonnage_progress_bar(): number {
    return this._tonnage_progress_bar;
  }

  private _drivingDirection: string = this.DRIVING_DIRECTION_LEFT;
  get drivingDirection(): any {
    if (this._last_position && this._trackingData.length > 1) {
      let lastCoordinates = this._last_position.googleLatLang.lng;
      let beforeLastCoordinates = this._trackingData[this._trackingData.length - 2].geoPos.googleLatLang.lng;
      // console.log(this.number_plate);
      // console.log(lastCoordinates);
      // console.log(beforeLastCoordinates);
      // console.log('----------------------');
      if (lastCoordinates > beforeLastCoordinates) {
        this._drivingDirection = this.DRIVING_DIRECTION_RIGHT;
      }
    }
    return this._drivingDirection;
  }

  get real_truck_status(): string {
    if (!this.last_position) {
      return ''
    }
    return this.last_position.truck_status;
  }

  get truck_status(): string {
    if (!this.last_position) {
      return '';
    }
    let truck_status_name = this.last_position.truck_status;
    if (truck_status_name === Vehicle.STOP || truck_status_name == Vehicle.SLEEP) {
      if (this.last_cargo_activity) {
        switch (this.last_cargo_activity.type) {
          case Vehicle.LOADING_START:
          case Vehicle.UNLOADING_START:
            if (Haversine.haversine({
              latitude: this.last_cargo_activity.city_gps.googleLatLang.lat,
              longitude: this.last_cargo_activity.city_gps.googleLatLang.lng
            }, {
              latitude: this.last_position.googleLatLang.lat,
              longitude: this.last_position.googleLatLang.lng
            }) <= 1) {
              return this.last_cargo_activity.type;
            }

        }
      }
    }
    if (truck_status_name == Vehicle.MOVING_FULL && this.last_position.cargo_status != Vehicle.LOADING_END) {
      truck_status_name = Vehicle.MOVING_EMPTY;
    }
    return truck_status_name;
  }

  get truck_status_name() {
    let status = this.truck_status;
    if (!status) {
      // default for new cars (without last_position) is STOP
      return GoogleMapMarker.icons['='].url;
    }
    return GoogleMapMarker.icons[status].url;
  }

  isDriving(): boolean {
    if (this.last_position && [Vehicle.MOVING_EMPTY, Vehicle.MOVING_FULL, Vehicle.MOVING_LEFT_EMPTY, Vehicle.MOVING_LEFT_FULL, Vehicle.MOVING_RIGHT_EMPTY, Vehicle.MOVING_RIGHT_FULL].indexOf(this.last_position.truck_status) > -1) {
      return true;
    }
    return false;
  }


  /******************************************/
  /* Agenda */
  /******************************************/
  private _agenda: Array<Agenda> = [];
  get agenda(): Array<Agenda> {
    return this._agenda;
  }
  set agenda(value: Array<Agenda>) {
    if (value) {
      if (value.length) {
        // reinit last_position agendas dictionary
        if (this.last_position) {
          this.last_position.agendas = {};
        }

        value.forEach(
          agenda => {
            if (this.last_position && this.last_position.order_number) {
              if (this.last_position.order_number.includes(agenda.order_number)) {
                // creating dictionary
                this.last_position.agendas[agenda.order_number] = agenda;
                // this.last_position.order_customer = agenda.payer_company;
                // this.last_position.itin = agenda.itinerary;
              }
            }
            agenda.vehicle = this;
          }
        );
      }
      this._agenda = value;
      this.rebuildTrackedPositionBasedData();

      // find any old agenda
      this._oldAgendas = false;
      if (this._last_position) {
        if (this._last_position.order_number && this._last_position.order_number.length) {
          this._last_position.order_number.forEach(
            order_number => {
              if (!this._last_position.agendas || !this._last_position.agendas[order_number]) {
                // some old agenda founded
                this._oldAgendas = true;
              }
            }
          )
        }
      }
    }
  }

  removeAgenda(agenda: Agenda): Agenda {
    let index = this._agenda.indexOf(agenda);
    if (index > -1) {
      this._agenda[index].vehicle = null;
      return this._agenda.splice(index, 1)[0];
    }
    return null;
  }

  addAgenda(agenda: Agenda) {
    if (this._agenda.indexOf(agenda) === -1) {
      agenda.vehicle = this;
      this._agenda.push(agenda);
    }
  }

  private _agenda_detail: Agenda | void;
  get agenda_detail(): Agenda | void {
    return this._agenda_detail;
  }
  setAgendaDetail(obligation_key: number) {
    this._agenda_detail = null;
    this._agenda.forEach(
      agenda => {
        if (agenda.obligation_key === obligation_key) {
          this._agenda_detail = agenda;
          return false;
        }
      }
    )
  }

  get path(): Array<GoogleLatLng> {
    if (this._temporaryManualTrackedData.length) {
      return this._temporaryManualTrackedData;
    }
    /* Now we are removing tracked positions from Agenda... */
    let path: Array<GoogleLatLng> = [];
    this.agenda.forEach(
      agenda => {
        if (this.last_position && this.last_position.order_number.includes(agenda.order_number)) {
          if (agenda.tracked_positions) {
            agenda.tracked_positions.forEach(
              position => {
                path.push(position.googleLatLang);
              }
            );
          }
        }
      }
    );
    return path;
  }

  get direction(): Array<GoogleLatLng> {
    if (this._temporaryManualTrackingDirection.length) {
      return this._temporaryManualTrackingDirection;
    }
    let direction: Array<GoogleLatLng> = [];
    this.agenda.forEach(
      agenda => {
        if (this.last_position && this.last_position.order_number) {
          if (this.last_position.order_number.includes(agenda.order_number)) {
            agenda.itinerary.forEach(
              itinerary => {
                if (itinerary.gps_coord && itinerary.gps_coord.googleLatLang) {
                  direction.push(itinerary.gps_coord.googleLatLang);
                }
                else {
                  // at least 1 invalid/null gps coord
                  direction = [];
                  return;
                }
              }
            );
          }
        } 
      }
    );
    return direction;
  }

  get order_customer(): string {
    let order_customer = '';
    if (this.agenda && this.last_position) {
      let orderN = this.last_position.order_number;
      if (orderN) {
        this.agenda.forEach(
          (agenda: Agenda) => {
            if (orderN.includes(agenda.order_number) && agenda.order_comp_book) {
              order_customer = agenda.order_comp_book.company ? agenda.order_comp_book.company : '';
              order_customer += agenda.order_comp_book.city ? ', ' + agenda.order_comp_book.city : '';
              order_customer += agenda.order_comp_book.country ? ', ' + agenda.order_comp_book.country : '';
              return false;
            }
          }
        );
      }
    }
    return order_customer;
  }


  /******************************************/
  /* Systemova hlaseni */
  /******************************************/
  private _reachEvents: any = {
    SEL: false,
    SEU: false,
    SEF: false,
    SED: false,
    TEI: false,
    SES: false,
    BCR: false,
    BHO: false,
    GPS: 0
  };

  // system reports
  get reachEvents(): any {
    return this._reachEvents;
  }
  set reachEvents(value: any) {
    this._reachEvents = value;
  }

  get reportSEL(): boolean {
    return this._reachEvents.SEL;
  }
  set reportSEL(value: boolean) {
    this._reachEvents.SEL = value;
    this._update.push("SEL");
  }

  get reportSEU(): boolean {
    return this._reachEvents.SEU;
  }
  set reportSEU(value: boolean) {
    this._reachEvents.SEU = value;
    this._update.push("SEU");
  }

  get reportSEF(): boolean {
    return this._reachEvents.SEF;
  }
  set reportSEF(value: boolean) {
    this._reachEvents.SEF = value;
    this._update.push("SEF");
  }

  get reportSED(): boolean {
    return this._reachEvents.SED;
  }
  set reportSED(value: boolean) {
    this._reachEvents.SED = value;
    this._update.push("SED");
  }

  get reportTEI(): boolean {
    return this._reachEvents.TEI;
  }
  set reportTEI(value: boolean) {
    this._reachEvents.TEI = value;
    this._update.push("TEI");
  }

  get reportSES(): boolean {
    return this._reachEvents.SES;
  }
  set reportSES(value: boolean) {
    this._reachEvents.SES = value;
    this._update.push("SES");
  }

  get reportBCR(): boolean {
    return this._reachEvents.BCR;
  }
  set reportBCR(value: boolean) {
    this._reachEvents.BCR = value;
    this._update.push("BCR");
  }

  get reportBHO(): boolean {
    return this._reachEvents.BHO;
  }
  set reportBHO(value: boolean) {
    this._reachEvents.BHO = value;
    this._update.push("BHO");
  }

  get reportGPS(): number {
    return this._reachEvents.GPS;
  }
  set reportGPS(value: number) {
    this._reachEvents.GPS = value;
  }

  get drivers(): string {
    return (!this.driver2_name ? this.driver_name : this.driver_name + " + " + this.driver2_name);
  }

  
  /******************************************/
  /* Aetr */
  /******************************************/
  private _aetr_lazyload: Function;
  set aetr_lazyload(loader: Function) {
    this._aetr_lazyload = loader;
    this._aetr = null;
    let emit = !(this._aetr);
    this._aetrLoading = false;
    if (emit) {
      this._aetrLoaded.emit(this._aetr);
    }
  }

  private _aetr: AetrDataCollectionObject;
  get aetr(): AetrDataCollectionObject {
    if (!this._aetr && this._aetr_lazyload && !this._aetrLoading) {
      this._aetrLoading = true;
      this._aetr_lazyload().subscribe(
        aetr => {
          this._aetr = aetr;
          this._aetrLoading = false;
          this._aetrLoaded.emit(this._aetr);
        },
        erro => {
          this._aetrLoading = false;
        }
      );
    }
    return this._aetr;
  }
  set aetr(value: AetrDataCollectionObject) {
    this._aetr = value;
    this._aetrLoaded.emit(this._aetr);
  }

  haveAetr(): boolean {
    return this._aetr !== null;
  }

  get aetr_loaded(): Observable<AetrDataCollectionObject> {
    return this._aetrLoaded.asObservable();
  }


  // aetrHistory we get from api /aetrs/<ck>/info
  private _aetrHistory: AetrHistoryCollectionObject;

  aetrHistory(reload?: boolean): AetrHistoryCollectionObject {
    if ((this._aetrHistory == null || reload) && !this._aetrHistoryLoading && (this._aetrHistoryLazyLoad instanceof Function)) {
      this._aetrHistoryLoading = true;
      this._aetrHistoryLazyLoad().subscribe(
        (collection) => {
          this._aetrHistory = collection;
          this._aetrHistoryLazyLoad = null;
          this._aetrHistoryLoading = false;
        },
        () => {
          this._aetrHistoryLazyLoad = null;
          this._aetrHistoryLoading = false;
        }
      )
    }
    return this._aetrHistory;
  }

  private _aetrHistoryLazyLoad: Function;
  get aetrHistoryLazyLoad() {
    return this._aetrHistoryLazyLoad;
  }
  set aetrHistoryLazyLoad(value: Function) {
    this._aetrHistoryLazyLoad = value;
  }

  private _aetrHistoryLoading: boolean = false;
  public get aetrHistoryLoading(): boolean {
    return this._aetrHistoryLoading;
  }

  private _latestAetr: BehaviorSubject<Array<AetrActivityDataInterface>> = new BehaviorSubject(null);
  get latestAetr(): Observable<Array<AetrActivityDataInterface>> {
    if (this._latestAetr.value === null && !this._latestAetrLoading && this._latestAetrLazyLoad instanceof Function) {
      this._latestAetrLoading = true;
      this._latestAetrLazyLoad().subscribe(
        (aetr_last) => {
          this._latestAetrLazyLoad = null;
          this._latestAetrLoading = false;
          this._latestAetr.next(aetr_last);
        },
        () => {
          this._latestAetrLazyLoad = null;
        }
      )
    }
    return this._latestAetr.asObservable();
  }

  private _latestAetrLazyLoad: Function;
  set latestAetrLazyLoad(value: Function) {
    this._latestAetrLazyLoad = value;
  }


  /******************************************/
  /* Health */
  /******************************************/
  private _health_lazyload: Function;
  public get health_lazyload(): Function {
    return this._health_lazyload;
  }
  public set health_lazyload(loader: Function) {
    this._health_lazyload = loader;
    this._health = null;
    this._healthLoading = false;
  }

  private _health: VehicleHealthObject;
  get health(): VehicleHealthObject {
    if (!this._health && this._health_lazyload && !this._healthLoading) {
      this._healthLoading = true;
      this._health_lazyload().subscribe(
        (health: VehicleHealthObject) => {
          this._health = health;
          this._healthLoading = false;
        },
        () => {
        }
      );
      this.health_lazyload = null;
    }
    return this._health;
  }
  set health(health: VehicleHealthObject) {
    this._health = health;
  }

  get health_icon_title(): string {
    if (this._health) {
      if (!this._health.isOlderThanMinutes(20)) {
        if (!this._health.charging) {
          return $localize`Mimo napájení`;
        } 
        else {
          return $localize`Vše OK`;
        }
      } 
      else {
        return $localize`Problém s datovým spojením`;
      }
    } 
    else {
      return $localize`Žádná data o stavu zařízení`;
    }
  }

  get health_icon_src(): string {
    if (this.health) {
      if (!this.health.isOlderThanMinutes(20)) {
        if (!this.health.charging) {
          return 'assets/icons-new/icon-status-power-off.svg';
        } 
        else {
          return 'assets/icons-new/icon-status-ok.svg';
        }
      } 
      else {
        return 'assets/icons-new/icon-status-noconnection.svg';
      }
    } 
    else {
      return 'assets/icons-new/icon-status-noinformation.svg';
    }
  }

  get health_connection(): boolean {
    if (!this.health) {
      return false;
    }
    else if (this.health.isOlderThanMinutes(20)) {
      return false;
    }
    return true;
  }

  get trackingLastTimeFormatted(): string {
    if (this.health) {
      return this.health.formattedDate;
    }
    return 'N\\A';
  }

  get trackingTmVersionFormatted(): string {
    if (this.health) {
      return this.health.tm_version;
    }
    return 'N\\A';
  }
  
  get trackingDeviceCodenameFormatted(): string {
    if (this.health) {
      return this.health.device_codename;
    }
    return 'N\\A';
  }

  get trackingDeviceBatteryChargeLevelFormatted(): string {
    if (this.health) {
      return this.health.batteryLevelFormatted;
    }
    return 'N\\A';
  }

  get trackingDeviceRuntimeFormatted(): string {
    if (this.health) {
      return this.health.runtimePdaFormatted;
    }
    return 'N\\A';
  }

  get trackingTmRuntimeFormatted(): string {
    if (this.health) {
      return this.health.runtimeTmFormatted;
    }
    return 'N\\A';
  }


  /******************************************/
  /* Tank/fueling/speed */
  /******************************************/
  private _tank_gauge_percentage: number;
  get tank_gauge_percentage(): number {
    return this._tank_gauge_percentage;
  }
  set tank_gauge_percentage(value: number) {
    this._tank_gauge_percentage = value;
  }

  get tank_volume_color(): string {
    if (this.fuelPercentage === 101 || this.lastRefueledBefore(14)) {
      return 'inverse';
    }
    if (this.fuelPercentage < 15) {
      return 'warning';
    } else {
      return 'primary';
    }
  };

  get fuelPercentage(): number {
    if (!isNaN(this._tank_gauge_percentage)) {
      return this._tank_gauge_percentage;
    }
    if (this._consumption_avg == 0) {
      return 101;
    }
    let percentage = 0;
    if (this._tank_volume !== null && this._last_refueling_distance !== null && this._last_refueling_liters !== null && this._consumption_avg !== null) {
      percentage = Math.round(((this._tank_volume + this._last_refueling_liters - (this._last_refueling_distance * this._consumption_avg / 100.0)) / this._tank_volume) * 100)
    }
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  }

  get driveRange(): string {
    let range = 0;
    if (this.tank_level) {
      range = (this._tank_volume * (this.tank_level / 100) / this._consumption_avg) * 100;
    }
    else if (this.fuelPercentage) {
      range = (this._tank_volume * (this.fuelPercentage / 100) / this._consumption_avg) * 100;
    }
    return Math.round(range) + ' ' + defaultLengthUnit;
  }

  get consumptionInfo(): string {
    if (this._consumption_avg) {
      return Math.round(this._consumption_avg * 100) / 100 + 'l/100km';
    } else {
      return 'N/A'
    }
  }

  get lastRefuelingInfo(): string {
    if (this._last_refueling_time) {
      let refuelingAt = DateTools.isoFix(this._last_refueling_time);
      return DateTools.formatLocaleString(refuelingAt)
    } else {
      return 'N/A';
    }
  }

  speedPercentage(compareSpeed: number): number {
    if (!this._eco_speed) {
      return 0;
    } else {
      return Math.round((compareSpeed / this._eco_speed) * 10000) / 100;
    }
  }

  
  /******************************************/
  /* Road exceptions */
  /******************************************/
  private _roadExceptions = null;
  roadExceptions() {
    return this._roadExceptions;
  }

  
  /******************************************/
  /* Start/stop */
  /******************************************/
  private _accumulatedStopTime: number = 0;
  get accumulatedStopTime(): number {
    return this._accumulatedStopTime;
  }

  private _accumulatedStopTimeInterval: number = 0;
  startStopInterval() {
    if (!this._accumulatedStopTimeInterval) {
      this._accumulatedStopTime = 0;
      this._accumulatedStopTimeInterval = window.setInterval(
        () => {
          this._accumulatedStopTime += 10000
        },
        10000
      )
    }
  }

  stopStopInterval() {
    if (this._accumulatedStopTimeInterval) {
      window.clearInterval(this._accumulatedStopTimeInterval);
    }
  }

  
  /******************************************/
  /* Events tracking */
  /******************************************/
  private _trackingDataChange: EventEmitter<boolean> = new EventEmitter();
  get trackingDataChange(): Observable<boolean> {
    return this._trackingDataChange.asObservable();
  }
  
  private _trackingData: Array<TrackingEventDataObject> = [];
  get trackingData(): Array<TrackingEventDataObject> {
    // this._trackingData.length === 0 && 
    if (this._trackingDataLazyload && !this._trackingDataLazyloading) {
      this._trackingDataLazyloading = true;
      this._trackingDataLazyload().subscribe(
        (response: Array<TrackingEventDataObject>) => {
          this._trackingData = response;
          this._trackingDataLazyloading = false;
          this._trackingDataLazyload = null;
          this._trackingDataLoaded.next(this._trackingData);
        },
        () => {
          this._trackingDataLazyload = null;
          this._trackingDataLazyloading = false;
        }
      )
    }
    return this._trackingData;
  }

  private _trackingDataLoaded: BehaviorSubject<Array<TrackingEventDataObject>> = new BehaviorSubject(this._trackingData);
  get trackingDataLoaded(): Observable<Array<TrackingEventDataObject>> {
    return this._trackingDataLoaded.asObservable();
  }
  
  private _trackingDataLazyloading: boolean = false;
  public get trackingDataLazyloading(): boolean {
    return this._trackingDataLazyloading;
  }

  private _trackingDataLazyload: Function;
  set trackingDataLazyload(value: Function) {
    this._trackingDataLazyload = value;
  }

  triggerDataChange() {
    this._trackingDataChange.emit(true);
  }

  addTrackingData(value: TrackingEventDataObject) {
    this._trackingData.push(value);
    this._trackingDataLoaded.next(this._trackingData);
    this._trackingDataChange.emit(true);
  }

  emitTrackingDataChange(): void {
    this._trackingDataChange.emit(true);
  }

  resetTrackingData() {
    this._trackingData = [];
    this._trackingDataLoaded.next(this._trackingData);
    this._trackingDataChange.emit(true);
  }
  
  rebuildTrackedPositionBasedData() {
    this._markersMap.forEach(
      marker => {
        marker.map = null;
      }
    );
    this._markersMapCargo.forEach(
      marker => {
        marker.map = null;
      }
    );
    this._markersMap = [];
    this._markersMapCargo = [];
  }

  // use to render all agenda tracking event paths
  private _agendaTrackingEvents: Array<TrackingEvent> = [];
  public get agendaTrackingEvents(): Array<TrackingEvent> {
    return this._agendaTrackingEvents;
  }
  public set agendaTrackingEvents(value: Array<TrackingEvent>) {
    this._agendaTrackingEvents = value;
  }

  private _polylines: Array<any> = [];
  public get polylines(): Array<any> {
    return this._polylines;
  }
  public set polylines(value: Array<any>) {
    this._polylines = value;
  }

  
  /******************************************/
  /* Events service */
  /******************************************/
  // service events we get here from api /aetrs/<ck>/service
  private _latestServiceEvents: BehaviorSubject<Array<ServiceEventObject>> = new BehaviorSubject([]);
  
  latestServiceEvents(reload: boolean): Observable<Array<ServiceEventObject>> {
    if ((this._latestServiceEvents.value.length === 0 || reload) && !this._latestServiceEventsLoading && this._latestServiceEventsLazyLoad instanceof Function) {
      this._latestServiceEventsLoading = true;
      this._latestServiceEventsLazyLoad().subscribe(
        (collection) => {
          this._latestServiceEventsLazyLoad = null;
          this._latestServiceEventsLoading = false;
          this._latestServiceEvents.next(collection);
        },
        () => {
          this._latestServiceEventsLazyLoad = null;
        }
      )
    }
    return this._latestServiceEvents.asObservable();
  }

  private _latestServiceEventsLoading: boolean = false;
  public get latestServiceEventsLoading(): boolean {
    return this._latestServiceEventsLoading;
  }

  private _latestServiceEventsLazyLoad: Function = null;
  get latestServiceEventsLazyLoad(): Function {
    return this._latestServiceEventsLazyLoad;
  }
  set latestServiceEventsLazyLoad(value: Function) {
    this._latestServiceEventsLazyLoad = value;
  }

  addServiceEvent(serviceEvent: ServiceEventObject) {
    let data = this._latestServiceEvents.value.slice();
    data.push(serviceEvent);
    this._latestServiceEvents.next(data);
  }

  get latestServiceEventsData(): Array<ServiceEventObject> {
    return this._latestServiceEvents.value;
  }

  
  /******************************************/
  /* Manual tracking ~ destination route */
  /******************************************/
  private _destRouteChange: EventEmitter<boolean> = new EventEmitter();
  get destRouteChange(): Observable<boolean> {
    return this._destRouteChange.asObservable();
  }

  emitDestRouteChange(): void {
    this._destRouteChange.emit(true);
  }

  private _temporaryManualTrackingDirection: Array<GoogleLatLng> = [];
  get temporaryManualTrackingDirection(): Array<GoogleLatLng> {
    return this._temporaryManualTrackingDirection;
  }
  set temporaryManualTrackingDirection(value: Array<GoogleLatLng>) {
    this._temporaryManualTrackingDirection = value;
    // this._trackingDataChange.emit(true);
    this._destRouteChange.emit(true);
  }

  private _temporaryManualTracking: Array<GoogleMapMarker> = [];
  get temporaryManualTracking(): Array<GoogleMapMarker> {
    return this._temporaryManualTracking;
  }
  set temporaryManualTracking(value: Array<GoogleMapMarker>) {
    this._temporaryManualTracking = value;
    // this._trackingDataChange.emit(true);
    this._destRouteChange.emit(true);
  }

  private _temporaryManualTrackedData: Array<GoogleLatLng> = [];
  get temporaryManualTrackedData(): Array<GoogleLatLng> {
    return this._temporaryManualTrackedData;
  }
  set temporaryManualTrackedData(value: Array<GoogleLatLng>) {
    this._temporaryManualTrackedData = value;
    // this._trackingDataChange.emit(true);
    this._destRouteChange.emit(true);
  }

  private _temporaryManualTrackingTarget: ManualTrackingTargetInterface;
  get temporaryManualTrackingTarget(): ManualTrackingTargetInterface {
    return this._temporaryManualTrackingTarget;
  }
  set temporaryManualTrackingTarget(value: ManualTrackingTargetInterface) {
    this._temporaryManualTrackingTarget = value;
  }

  private _temporaryManualTrackingWaypoints: Array<any> = [];
  get temporaryManualTrackingWaypoints(): Array<any> {
    return this._temporaryManualTrackingWaypoints;
  }
  set temporaryManualTrackingWaypoints(value: Array<any>) {
    this._temporaryManualTrackingWaypoints = value;
  }

  private _temporaryManualTrackingStart: Date;
  get temporaryManualTrackingStart(): Date {
    return this._temporaryManualTrackingStart;
  }
  set temporaryManualTrackingStart(value: Date) {
    this._temporaryManualTrackingStart = value;
  }

  private _manualTrackingDirectionRender: any;
  get manualTrackingDirectionRender(): any {
    return this._manualTrackingDirectionRender;
  }
  set manualTrackingDirectionRender(value: any) {
    this._manualTrackingDirectionRender = value;
    // this._trackingDataChange.emit(true);
    this._destRouteChange.emit(true);
  }

  // flag if manual tracking has been renderen on map
  private _manualTrackingDirectionRendered: boolean = false;
  public get manualTrackingDirectionRendered(): boolean {
    return this._manualTrackingDirectionRendered;
  }
  public set manualTrackingDirectionRendered(value: boolean) {
    this._manualTrackingDirectionRendered = value;
  }

  
  resetManualTemporaryTracking() {
    this._temporaryManualTrackingDirection = [];
    this._temporaryManualTracking = [];
    this._temporaryManualTrackedData = [];
    this._temporaryManualTrackingTarget = null;
    this._temporaryManualTrackingWaypoints = [];
    this._temporaryManualTrackingStart = null;
    // this._trackingDataChange.emit(false);
    this._destRouteChange.emit(true);
  }

  temporaryManualTrackingTargetReached() {
    this._temporaryManualTrackingTarget.targetReachedMessageSent = true;
  }

  getTemporaryManualTrackingDataToCache() {
    let positions = [];
    this.temporaryManualTrackedData.forEach(
      data => {
        positions.push(data.lat + '::' + data.lng);
      }
    );
    let directions = [];
    this.temporaryManualTrackingDirection.forEach(
      data => {
        directions.push(data.lat + '::' + data.lng);
      }
    );
    return JSON.stringify(
      {
        start: (this.temporaryManualTracking.length ? this.temporaryManualTracking[0].position.lat() + '::' + this.temporaryManualTracking[0].position.lng() : null),
        end: (this.temporaryManualTracking.length ? this.temporaryManualTracking[1].position.lat() + '::' + this.temporaryManualTracking[1].position.lng() : null),
        tracking: positions,
        directions: directions,
        target: this.temporaryManualTrackingTarget,
        waypoints: this._temporaryManualTrackingWaypoints,
        dateStart: this._temporaryManualTrackingStart,
      }
    );
  }


  /******************************************/
  /* Service tasks/plans */
  /******************************************/
  private _tasksPlannedPrice: number = 0;
  public get tasksPlannedPrice(): number {
    return this._tasksPlannedPrice;
  }
  public set tasksPlannedPrice(value: number) {
    this._tasksPlannedPrice = value;
  }
  
  private _tasksNotPlannedPrice: number = 0;
  public get tasksNotPlannedPrice(): number {
    return this._tasksNotPlannedPrice;
  }
  public set tasksNotPlannedPrice(value: number) {
    this._tasksNotPlannedPrice = value;
  }

  // trzby vozidla ~ cena vsech zakazek vozidla
  private _vehicleProfits: number = 0;
  public get vehicleProfits(): number {
    return this._vehicleProfits;
  }
  public set vehicleProfits(value: number) {
    this._vehicleProfits = value;
  }

  private _loadingTasks: boolean = false;
  public get loadingTasks(): boolean {
    return this._loadingTasks;
  }
  public set loadingTasks(value: boolean) {
    this._loadingTasks = value;
  }

  private _tasks: Array<ServiceTask> = [];
  public get tasks(): Array<ServiceTask> {
    return this._tasks;
  }
  public set tasks(value: Array<ServiceTask>) {
    this._tasks = value;
    if (this._tasks) {
      this._tasksPlannedPrice = 0;
      this._tasksNotPlannedPrice = 0;
      // TODO other than our currency with exchange rate might occur
      // this._tasks.forEach(
      //   t => {
      //     if (t.price) {
      //       // planned
      //       if (t.type == 'A' && !t.isNakupTask) {
      //         this._tasksPlannedPrice += t.price;
      //       }
      //       else if (!t.isNakupTask) {
      //         this._tasksNotPlannedPrice += t.price;
      //       }
      //     }
      //   }
      // );
    }
  }

  private _batteryTasks: Array<ServiceTask> = [];
  public get batteryTasks(): Array<ServiceTask> {
    return this._batteryTasks;
  }
  public set batteryTasks(value: Array<ServiceTask>) {
    this._batteryTasks = value;
    if (this._batteryTasks.length) {
      // using setter - last made task
      this.batteryTask = this._batteryTasks[this._batteryTasks.length - 1];
    }
  }
  
  private _parkingTasks: Array<ServiceTask> = [];
  public get parkingTasks(): Array<ServiceTask> {
    return this._parkingTasks;
  }
  public set parkingTasks(value: Array<ServiceTask>) {
    this._parkingTasks = value;
    if (this._parkingTasks.length) {
      // using setter - last made task
      this.parkingTask = this._parkingTasks[this._parkingTasks.length - 1];
    }
  }

  private _garancniTasks: Array<ServiceTask> = [];
  public get garancniTasks(): Array<ServiceTask> {
    return this._garancniTasks;
  }
  public set garancniTasks(value: Array<ServiceTask>) {
    this._garancniTasks = value;
    if (this._garancniTasks.length) {
      // using setter
      this.garancniTask = this._garancniTasks[0];
    }
  }

  private _certifikatTasks: Array<ServiceTask> = [];
  public get certifikatTasks(): Array<ServiceTask> {
    return this._certifikatTasks;
  }
  public set certifikatTasks(value: Array<ServiceTask>) {
    this._certifikatTasks = value;
    if (this._certifikatTasks.length) {
      // using setter - following planned task (without date)
      this.certifikatTask = this._certifikatTasks.find(t => !t.completed_date);
      if (!this._certifikatTask) {
        // last made task
        this.certifikatTask = this._certifikatTasks[this._certifikatTasks.length - 1];
      }
    }
  }
  
  private _nakupTasks: Array<ServiceTask> = [];
  public get nakupTasks(): Array<ServiceTask> {
    return this._nakupTasks;
  }
  public set nakupTasks(value: Array<ServiceTask>) {
    this._nakupTasks = value;
    if (this._nakupTasks.length) {
      // using setter
      this.nakupTask = this._nakupTasks[0];
    }
  }
  
  private _oilTasks: Array<ServiceTask> = [];
  public get oilTasks(): Array<ServiceTask> {
    return this._oilTasks;
  }
  public set oilTasks(value: Array<ServiceTask>) {
    this._oilTasks = value;
    if (this._oilTasks.length) {
      // using setter - following planned task (without date)
      this.oilTask = this._oilTasks.find(t => !t.completed_date);
      if (!this._oilTask) {
        // last made task
        this.oilTask = this._oilTasks[this._oilTasks.length - 1];
      }
    }
  }
  
  private _pneu1Tasks: Array<ServiceTask> = [];
  public get pneu1Tasks(): Array<ServiceTask> {
    return this._pneu1Tasks;
  }
  public set pneu1Tasks(value: Array<ServiceTask>) {
    this._pneu1Tasks = value;
    if (this._pneu1Tasks.length) {
      // using setter - last made task
      this.pneu1Task = this._pneu1Tasks[this._pneu1Tasks.length - 1];
    }
  }

  private _pneu2Tasks: Array<ServiceTask> = [];
  public get pneu2Tasks(): Array<ServiceTask> {
    return this._pneu2Tasks;
  }
  public set pneu2Tasks(value: Array<ServiceTask>) {
    this._pneu2Tasks = value;
    if (this._pneu2Tasks.length) {
      // using setter - last made task
      this.pneu2Task = this._pneu2Tasks[this._pneu2Tasks.length - 1];
    }
  }
  
  private _pneu3Tasks: Array<ServiceTask> = [];
  public get pneu3Tasks(): Array<ServiceTask> {
    return this._pneu2Tasks;
  }
  public set pneu3Tasks(value: Array<ServiceTask>) {
    this._pneu3Tasks = value;
    if (this._pneu3Tasks.length) {
      // using setter - last made task
      this.pneu3Task = this._pneu3Tasks[this._pneu3Tasks.length - 1];
    }
  }

  private _tachoZalohaTasks: Array<ServiceTask> = [];
  public get tachoZalohaTasks(): Array<ServiceTask> {
    return this._tachoZalohaTasks;
  }
  public set tachoZalohaTasks(value: Array<ServiceTask>) {
    this._tachoZalohaTasks = value;
    if (this._tachoZalohaTasks.length) {
      // using setter - following planned task (without date)
      this.zalohaTask = this._tachoZalohaTasks.find(t => !t.completed_date);
      if (!this._zalohaTask) {
        // last made task
        this.zalohaTask = this._tachoZalohaTasks[this._tachoZalohaTasks.length - 1];
      }
    }
  }
  
  private _tachoKalibraceTasks: Array<ServiceTask> = [];
  public get tachoKalibraceTasks(): Array<ServiceTask> {
    return this._tachoKalibraceTasks;
  }
  public set tachoKalibraceTasks(value: Array<ServiceTask>) {
    this._tachoKalibraceTasks = value;
    if (this._tachoKalibraceTasks.length) {
      // using setter - following planned task (without date)
      this.kalibraceTask = this._tachoKalibraceTasks.find(t => !t.completed_date);
      if (!this._kalibraceTask) {
        // last made task
        this.kalibraceTask = this._tachoKalibraceTasks[this._tachoKalibraceTasks.length - 1];
      }
    }
  }

  private _terminovanaTasks: Array<ServiceTask> = [];
  public get terminovanaTasks(): Array<ServiceTask> {
    return this._terminovanaTasks;
  }
  public set terminovanaTasks(value: Array<ServiceTask>) {
    this._terminovanaTasks = value;
    if (this._terminovanaTasks.length) {
      // using setter - following planned task (without date)
      this.terminovanaTask = this._terminovanaTasks.find(t => !t.completed_date);
      if (!this._terminovanaTask) {
        // last made task
        this.terminovanaTask = this._terminovanaTasks[this._terminovanaTasks.length - 1];
      }
    }
  }
  
  private _technickaTasks: Array<ServiceTask> = [];
  public get technickaTasks(): Array<ServiceTask> {
    return this._technickaTasks;
  }
  public set technickaTasks(value: Array<ServiceTask>) {
    this._technickaTasks = value;
    if (this._technickaTasks.length) {
      // using setter - following planned task (without date)
      this.technickaTask = this._technickaTasks.find(t => !t.completed_date);
      if (!this._technickaTask) {
        // last made task
        this.technickaTask = this._technickaTasks[this._technickaTasks.length - 1];
      }
    }
  }

  private _zarukaTasks: Array<ServiceTask> = [];
  public get zarukaTasks(): Array<ServiceTask> {
    return this._zarukaTasks;
  }
  public set zarukaTasks(value: Array<ServiceTask>) {
    this._zarukaTasks = value;
    if (this._zarukaTasks.length) {
      // using setter
      this.zarukaTask = this._zarukaTasks[0];
    }
  }


  /****************************************************/
  // main tasks
  private _nakupTask: ServiceTask = null;
  public get nakupTask(): ServiceTask {
    return this._nakupTask;
  }
  public set nakupTask(value: ServiceTask) {
    this._nakupTask = value;
    if (this._nakupTask && this._nakupTask.completedBeforeKm) {
      this._nakupKm = this._nakupTask.completedBeforeKm;
    }
    else if (this._last_position) {
      this._nakupKm = this._last_position.odometer;
    }
  }

  // custom - number of km we have driven with this car
  private _nakupKm: number = null; 
  public get nakupKm(): number {
    return this._nakupKm;
  }

  // main zaruka task
  private _zarukaTask: ServiceTask = null;
  public get zarukaTask(): ServiceTask {
    return this._zarukaTask;
  }
  public set zarukaTask(value: ServiceTask) {
    this._zarukaTask = value;
  }

  // main garancni task
  private _garancniTask: ServiceTask = null;
  public get garancniTask(): ServiceTask {
    return this._garancniTask;
  }
  public set garancniTask(value: ServiceTask) {
    this._garancniTask = value;
    if (this._garancniTask && this._garancniTask.dueRemainingDays) {
      this._garancniDays = this._garancniTask.dueRemainingDays;
    }
    else if (this._garancniTask && this._garancniTask.completedBeforeDays) {
      this._garancniDays = this._garancniTask.completedBeforeDays;
    }
  }

  private _garancniDays: number = null;
  public get garancniDays(): number {
    return this._garancniDays;
  }

  // main oil task (planned)
  private _oilTask: ServiceTask = null;
  public get oilTask(): ServiceTask {
    return this._oilTask;
  }
  public set oilTask(value: ServiceTask) {
    this._oilTask = value;
    if (this._oilTask && this._oilTask.dueRemainingKm) {
      this._oilKm = this._oilTask.dueRemainingKm;
    }
    else if (this._oilTask && this._oilTask.completedBeforeKm) {
      this._oilKm = this._oilTask.completedBeforeKm;
    }
  }

  private _oilKm: number = null;
  public get oilKm(): number {
    return this._oilKm;
  }

  // main certifikat task (planned)
  private _certifikatTask: ServiceTask = null;
  public get certifikatTask(): ServiceTask {
    return this._certifikatTask;
  }
  public set certifikatTask(value: ServiceTask) {
    this._certifikatTask = value;
    if (this._certifikatTask && this._certifikatTask.dueRemainingDays) {
      this._certifikatDays = this._certifikatTask.dueRemainingDays;
    }
    else if (this._certifikatTask && this._certifikatTask.completedBeforeDays) {
      this._certifikatDays = this._certifikatTask.completedBeforeDays;
    }
  }

  private _certifikatDays: number = null;
  public get certifikatDays(): number {
    return this._certifikatDays;
  }

  // main terminovana task (planned)
  private _terminovanaTask: ServiceTask = null;
  public get terminovanaTask(): ServiceTask {
    return this._terminovanaTask;
  }
  public set terminovanaTask(value: ServiceTask) {
    this._terminovanaTask = value;
    if (this._terminovanaTask && this._terminovanaTask.dueRemainingDays) {
      this._terminovanaDays = this._terminovanaTask.dueRemainingDays;
    }
    else if (this._terminovanaTask && this._terminovanaTask.completedBeforeDays) {
      this._terminovanaDays = this._terminovanaTask.completedBeforeDays;
    }
  }

  private _terminovanaDays: number = null;
  public get terminovanaDays(): number {
    return this._terminovanaDays;
  }

  // main technicka task (planned)
  private _technickaTask: ServiceTask = null;
  public get technickaTask(): ServiceTask {
    return this._technickaTask;
  }
  public set technickaTask(value: ServiceTask) {
    this._technickaTask = value;
    if (this._technickaTask && this._technickaTask.dueRemainingDays) {
      this._technickaDays = this._technickaTask.dueRemainingDays;
    }
    else if (this._technickaTask && this._technickaTask.completedBeforeDays) {
      this._technickaDays = this._technickaTask.completedBeforeDays;
    }
  }

  private _technickaDays: number = null;
  public get technickaDays(): number {
    return this._technickaDays;
  }
  
  // main zaloha tacho task (planned)
  private _zalohaTask: ServiceTask = null;
  public get zalohaTask(): ServiceTask {
    return this._zalohaTask;
  }
  public set zalohaTask(value: ServiceTask) {
    this._zalohaTask = value;
    if (this._zalohaTask && this._zalohaTask.dueRemainingDays) {
      this._zalohaDays = this._zalohaTask.dueRemainingDays;
    }
    else if (this._zalohaTask && this._zalohaTask.completedBeforeDays) {
      this._zalohaDays = this._zalohaTask.completedBeforeDays;
    }
  }

  private _zalohaDays: number = null;
  public get zalohaDays(): number {
    return this._zalohaDays;
  }
  
  // main kalibrace tacho task (planned)
  private _kalibraceTask: ServiceTask = null;
  public get kalibraceTask(): ServiceTask {
    return this._kalibraceTask;
  }
  public set kalibraceTask(value: ServiceTask) {
    this._kalibraceTask = value;
    if (this._kalibraceTask && this._kalibraceTask.dueRemainingDays) {
      this._kalibraceDays = this._kalibraceTask.dueRemainingDays;
    }
    else if (this._kalibraceTask && this._kalibraceTask.completedBeforeDays) {
      this._kalibraceDays = this._kalibraceTask.completedBeforeDays;
    }
  }

  private _kalibraceDays: number = null;
  public get kalibraceDays(): number {
    return this._kalibraceDays;
  }
  
  // last pneu1 task (last made)
  private _pneu1Task: ServiceTask = null;
  public get pneu1Task(): ServiceTask {
    return this._pneu1Task;
  }
  public set pneu1Task(value: ServiceTask) {
    this._pneu1Task = value;
    if (this._pneu1Task && this._pneu1Task.completedBeforeKm) {
      this._pneu1Km = this._pneu1Task.completedBeforeKm;
    }
  }

  private _pneu1Km: number = null;
  public get pneu1Km(): number {
    return this._pneu1Km;
  }
  
  // last pneu2 task (last made)
  private _pneu2Task: ServiceTask = null;
  public get pneu2Task(): ServiceTask {
    return this._pneu2Task;
  }
  public set pneu2Task(value: ServiceTask) {
    this._pneu2Task = value;
    if (this._pneu2Task && this._pneu2Task.completedBeforeKm) {
      this._pneu2Km = this._pneu2Task.completedBeforeKm;
    }
  }

  private _pneu2Km: number = null;
  public get pneu2Km(): number {
    return this._pneu2Km;
  }

  // last pneu3 task (last made)
  private _pneu3Task: ServiceTask = null;
  public get pneu3Task(): ServiceTask {
    return this._pneu3Task;
  }
  public set pneu3Task(value: ServiceTask) {
    this._pneu3Task = value;
    if (this._pneu3Task && this._pneu3Task.completedBeforeKm) {
      this._pneu3Km = this._pneu3Task.completedBeforeKm;
    }
  }

  private _pneu3Km: number = null;
  public get pneu3Km(): number {
    return this._pneu3Km;
  }

  // last battery task (last made)
  private _batteryTask: ServiceTask = null;
  public get batteryTask(): ServiceTask {
    return this._batteryTask;
  }
  public set batteryTask(value: ServiceTask) {
    this._batteryTask = value;
    if (this._batteryTask && this._batteryTask.completedBeforeKm) {
      this._batteryKm = this._batteryTask.completedBeforeKm;
    }
  }

  private _batteryKm: number = null;
  public get batteryKm(): number {
    return this._batteryKm;
  }

  // last parking task (last made)
  private _parkingTask: ServiceTask = null;
  public get parkingTask(): ServiceTask {
    return this._parkingTask;
  }
  public set parkingTask(value: ServiceTask) {
    this._parkingTask = value;
    if (this._parkingTask && this._parkingTask.dueRemainingDays) {
      this._parkingDays = this._parkingTask.dueRemainingDays;
    }
    else if (this._parkingTask && this._parkingTask.completedBeforeDays) {
      this._parkingDays = this._parkingTask.completedBeforeDays;
    }
  }

  private _parkingDays: number = null;
  public get parkingDays(): number {
    return this._parkingDays;
  }


  /******************************************/
  /* Dispatcher Board */
  /******************************************/
  /* Styles used in new version of dispatcher board */
  private _vehicleRowOffsetTop: number = 0;
  public get vehicleRowOffsetTop(): number {
    return this._vehicleRowOffsetTop;
  }
  public set vehicleRowOffsetTop(value: number) {
    this._vehicleRowOffsetTop = value;
  }

  private _vehicleRowHeight: number = 105;
  public get vehicleRowHeight(): number {
    return this._vehicleRowHeight;
  }
  public set vehicleRowHeight(value: number) {
    this._vehicleRowHeight = value;
  }

  private _timeSegmentsHeight: number = 80;
  public get timeSegmentsHeight(): number {
    return this._timeSegmentsHeight;
  }
  public set timeSegmentsHeight(value: number) {
    this._timeSegmentsHeight = value;
  }

  private _hourLineHeight: number = 60;
  public get hourLineHeight(): number {
    return this._hourLineHeight;
  }
  public set hourLineHeight(value: number) {
    this._hourLineHeight = value;
  }
  
  private _halfhourLineHeight: number = 30;
  public get halfhourLineHeight(): number {
    return this._halfhourLineHeight;
  }
  public set halfhourLineHeight(value: number) {
    this._halfhourLineHeight = value;
  }
  
  private _todayMarker: number = 105;
  public get todayMarker(): number {
    return this._todayMarker;
  }
  public set todayMarker(value: number) {
    this._todayMarker = value;
  }
  

  /******************************************/
  /* Maps All Vehicles */
  /******************************************/
  private _showPathsAndAgenda: boolean = false;
  public get showPathsAndAgenda(): boolean {
    return this._showPathsAndAgenda;
  }
  public set showPathsAndAgenda(value: boolean) {
    this._showPathsAndAgenda = value;
  }

  // directions of vehicle agenda (could be show on click / hide on click)
  private _allMapAgendaDirections: Array<any> = [];
  public get allMapAgendaDirections(): Array<any> {
    return this._allMapAgendaDirections;
  }
  public set allMapAgendaDirections(value: Array<any>) {
    this._allMapAgendaDirections = value;
  }

  // array for google.maps.DirectionsRenderer objects
  private _allMapAgendaDirectionsRenderers: Array<any> = [];
  public get allMapAgendaDirectionsRenderers(): Array<any> {
    return this._allMapAgendaDirectionsRenderers;
  }
  public set allMapAgendaDirectionsRenderers(value: Array<any>) {
    this._allMapAgendaDirectionsRenderers = value;
  }
  
  // directions of vehicle agenda (could be show on click / hide on click)
  private _allMapAgendaMarkers: Array<GoogleMapMarker> = [];
  public get allMapAgendaMarkers(): Array<GoogleMapMarker> {
    return this._allMapAgendaMarkers;
  }
  public set allMapAgendaMarkers(value: Array<GoogleMapMarker>) {
    this._allMapAgendaMarkers = value;
  }


  /******************************************/
  /* rFMS */
  /******************************************/
  private _fmsSource: string = null;
  public get fmsSource(): string {
    return this._fmsSource;
  }
  public set fmsSource(value: string) {
    this._fmsSource = value;
  }

  // default fms import
  private _fmsImport: ExternalImport = null;
  public get fmsImport(): ExternalImport {
    return this._fmsImport;
  }
  public set fmsImport(value: ExternalImport) {
    this._fmsImport = value;
  }

  // all fms imports for car? (theoreticaly there might be multiple imports)
  private _fmsImports: Array<ExternalImport> = [];
  public get fmsImports(): Array<ExternalImport> {
    return this._fmsImports;
  }
  public set fmsImports(value: Array<ExternalImport>) {
    this._fmsImports = value;
  }

  private _fmsEmailRequest: boolean = false;
  public get fmsEmailRequest(): boolean {
    return this._fmsEmailRequest;
  }
  public set fmsEmailRequest(value: boolean) {
    this._fmsEmailRequest = value;
  }
  

  /******************************************/
  /* Express routes */
  /******************************************/
  private _expressRoutes: Array<ExpressRoute> = [];
  public get expressRoutes(): Array<ExpressRoute> {
    return this._expressRoutes;
  }
  public set expressRoutes(value: Array<ExpressRoute>) {
    this._expressRoutes = value;
  }
}