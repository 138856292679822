import { VehicleHealthDataInterface } from "../interface/vehicle-health-data.interface";
import { DateTools } from "../tools/DateTools";

export class VehicleHealthObject {

  // constructor(private _data: VehicleHealthDataInterface) {
  //   this._time = DateTools.isoFix(this._data.time);
  // }

  constructor() {
  }

  private _car_key: number;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }

  private _service_key: number;
  public get service_key(): number {
    return this._service_key;
  }
  public set service_key(value: number) {
    this._service_key = value;
  }
  
  private _apn: string;
  public get apn(): string {
    return this._apn;
  }
  public set apn(value: string) {
    this._apn = value;
  }

  private _battery_level: number;  // percentage of device battery
  public get battery_level(): number {
    return this._battery_level;
  }
  public set battery_level(value: number) {
    this._battery_level = value;
    this._batteryLevelFormatted = (this._battery_level | 0) + '%';
  }

  // custom
  private _batteryLevelFormatted: string;
  get batteryLevelFormatted(): string {
    return this._batteryLevelFormatted;
  }

  private _battery_temperature: number;
  public get battery_temperature(): number {
    return this._battery_temperature;
  }
  public set battery_temperature(value: number) {
    this._battery_temperature = value;
  }
  
  private _charging: boolean;
  public get charging(): boolean {
    return this._charging;
  }
  public set charging(value: boolean) {
    this._charging = value;
  }

  private _data_allowed: boolean;
  public get data_allowed(): boolean {
    return this._data_allowed;
  }
  public set data_allowed(value: boolean) {
    this._data_allowed = value;
  }
  
  private _data_roaming: boolean;
  public get data_roaming(): boolean {
    return this._data_roaming;
  }
  public set data_roaming(value: boolean) {
    this._data_roaming = value;
  }
  
  private _device_codename: string;
  public get device_codename(): string {
    return this._device_codename;
  }
  public set device_codename(value: string) {
    this._device_codename = value;
  }
  
  private _doze_canceled: boolean;
  public get doze_canceled(): boolean {
    return this._doze_canceled;
  }
  public set doze_canceled(value: boolean) {
    this._doze_canceled = value;
  }

  private _free_space_internal: number;
  public get free_space_internal(): number {
    return this._free_space_internal;
  }
  public set free_space_internal(value: number) {
    this._free_space_internal = value;
  }
  
  private _free_space_card: number;
  public get free_space_card(): number {
    return this._free_space_card;
  }
  public set free_space_card(value: number) {
    this._free_space_card = value;
  }

  private _gps_signal_available: boolean;
  public get gps_signal_available(): boolean {
    return this._gps_signal_available;
  }
  public set gps_signal_available(value: boolean) {
    this._gps_signal_available = value;
  }
  
  private _gps_satellites: number;
  public get gps_satellites(): number {
    return this._gps_satellites;
  }
  public set gps_satellites(value: number) {
    this._gps_satellites = value;
  }
  
  private _gps_accuracy: number;
  public get gps_accuracy(): number {
    return this._gps_accuracy;
  }
  public set gps_accuracy(value: number) {
    this._gps_accuracy = value;
  }
  
  private _gps_signal_strength: number;
  public get gps_signal_strength(): number {
    return this._gps_signal_strength;
  }
  public set gps_signal_strength(value: number) {
    this._gps_signal_strength = value;
  }
  
  private _gsm_signal_strength: number;
  public get gsm_signal_strength(): number {
    return this._gsm_signal_strength;
  }
  public set gsm_signal_strength(value: number) {
    this._gsm_signal_strength = value;
  }

  private _install_type: string;
  public get install_type(): string {
    return this._install_type;
  }
  public set install_type(value: string) {
    this._install_type = value;
  }
  
  private _logging: boolean;
  public get logging(): boolean {
    return this._logging;
  }
  public set logging(value: boolean) {
    this._logging = value;
  }
  
  private _pos_gps: string;
  public get pos_gps(): string {
    return this._pos_gps;
  }
  public set pos_gps(value: string) {
    this._pos_gps = value;
  }
  
  private _runtime_tm: number;
  public get runtime_tm(): number {
    return this._runtime_tm;
  }
  public set runtime_tm(value: number) {
    this._runtime_tm = value;
    if (this._runtime_tm) {
      this._runtimeTmFormatted = (this._runtime_tm ? (Math.floor(this._runtime_tm/60)) : 0) + ' hod';
    }
  }
  
  // custom
  private _runtimeTmFormatted: string;
  get runtimeTmFormatted(): string {
    return this._runtimeTmFormatted;
  }

  private _runtime_pda: number;
  public get runtime_pda(): number {
    return this._runtime_pda;
  }
  public set runtime_pda(value: number) {
    this._runtime_pda = value;
    if (this._runtime_pda) {
      this._runtimePdaFormatted = (this._runtime_pda ? (Math.floor(this._runtime_pda/60)) : 0) + ' hod';
    }
  }

  // custom
  private _runtimePdaFormatted: string;
  get runtimePdaFormatted(): string {
    return this._runtimePdaFormatted; 
  }
  
  private _tm_version: string;
  public get tm_version(): string {
    return this._tm_version;
  }
  public set tm_version(value: string) {
    this._tm_version = value;
  }
  
  private _time: string;
  public get time(): string {
    return this._time;
  }
  public set time(value: string) {
    this._time = value;
    if (this._time) {
      this._timeDate = DateTools.isoFix(this._time);
      this._formattedDate = DateTools.formatLocaleString(this._timeDate);
    }
  }

  // custom
  private _timeDate: Date;
  public get timeDate(): Date {
    return this._timeDate;
  }

  // custom
  private _formattedDate: string;
  public get formattedDate(): string {
    return this._formattedDate;
  }
  
  // custom
  isOlderThanMinutes(minutes: number): boolean {
    if (!this.time) {
      return false;
    }
    let ms = minutes * 60 * 1000;
    return ((Date.now() - ms) > this._timeDate.getTime());
  }

  private _tracking_mode: string;
  public get tracking_mode(): string {
    return this._tracking_mode;
  }
  public set tracking_mode(value: string) {
    this._tracking_mode = value;
  }
  
  private _tracking_bluetooth: any;
  public get tracking_bluetooth(): any {
    return this._tracking_bluetooth;
  }
  public set tracking_bluetooth(value: any) {
    this._tracking_bluetooth = value;
  }


  // OBSOLETE
  // private _tracking_without_power: boolean;
  // public get tracking_without_power(): boolean {
  //   return this._tracking_without_power;
  // }
  // public set tracking_without_power(value: boolean) {
  //   this._tracking_without_power = value;
  // }
  // private _time: Date;
  // get time(): Date {
  //   return this._time;
  // }

  // get charging(): boolean {
  //   return this._data.charging;
  // }

  // get formattedDate(): string {
  //   return DateTools.formatLocaleString(this.time);
  // }

  // get tmVersion(): string {
  //   return this._data.tm_version;
  // }

  // get deviceCodename(): string {
  //   return this._data.device_codename;
  // }

  // get batteryLevel(): number {
  //   return this._data.battery_level;
  // }

  // get batteryLevelFormatted(): string {
  //   return (this.batteryLevel | 0) + '%';
  // }

  // get runtimeTm(): number {
  //   return this._data.runtime_tm;
  // }

  // get runtimeTmFormatted(): string {
  //   return (this.runtimeTm ? (Math.floor(this.runtimeTm/60)) : 0) + ' hod';
  // }

  // get runtimePda(): number {
  //   return this._data.runtime_pda;
  // }
  
  // get runtimePdaFormatted(): string {
  //   return (this.runtimePda ? (Math.floor(this.runtimePda/60)) : 0) + ' hod';
  // }
}