import { DateTools } from "../tools/DateTools";
import { AddressBookItemAttachment } from "./address-book-item-attachment";
import { AddressBookItemCar } from "./address-book-item-car";
import { AddressBookItemPricelist } from "./address-book-item-pricelist";
import { Person } from "./person.object";

export class AddressBookItem {
  private _account: string;
  public get account(): string {
    return this._account;
  }
  public set account(value: string) {
    this._account = value;
  }

  private _bank: string;
  public get bank(): string {
    return this._bank;
  }
  public set bank(value: string) {
    this._bank = value;
  }

  private _book_key: number;
  public get book_key(): number {
    return this._book_key;
  }
  public set book_key(value: number) {
    this._book_key = value;
  }
  
  private _blocked: boolean;
  public get blocked(): boolean {
    return this._blocked;
  }
  public set blocked(value: boolean) {
    this._blocked = value;
  }

  private _cin: string;
  public get cin(): string {
    return this._cin;
  }
  public set cin(value: string) {
    this._cin = value;
  }

  private _city: string;
  public get city(): string {
    return this._city;
  }
  public set city(value: string) {
    this._city = value;
  }
  
  private _city_invoice: string;
  public get city_invoice(): string {
    return this._city_invoice;
  }
  public set city_invoice(value: string) {
    this._city_invoice = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _company: string;
  public get company(): string {
    return this._company;
  }
  public set company(value: string) {
    this._company = value;
  }

  private _company_invoice: string;
  public get company_invoice(): string {
    return this._company_invoice;
  }
  public set company_invoice(value: string) {
    this._company_invoice = value;
  }

  private _country: string;
  public get country(): string {
    return this._country;
  }
  public set country(value: string) {
    this._country = value;
  }

  private _country_invoice: string;
  public get country_invoice(): string {
    return this._country_invoice;
  }
  public set country_invoice(value: string) {
    this._country_invoice = value;
  }

  private _edoc_required_itinerary: boolean;
  public get edoc_required_itinerary(): boolean {
    return this._edoc_required_itinerary;
  }
  public set edoc_required_itinerary(value: boolean) {
    this._edoc_required_itinerary = value;
  }

  private _edoc_required_documents: boolean;
  public get edoc_required_documents(): boolean {
    return this._edoc_required_documents;
  }
  public set edoc_required_documents(value: boolean) {
    this._edoc_required_documents = value;
  }

  private _edoc_required_invoice: boolean;
  public get edoc_required_invoice(): boolean {
    return this._edoc_required_invoice;
  }
  public set edoc_required_invoice(value: boolean) {
    this._edoc_required_invoice = value;
  }
  
  private _edoc_required_agreement: boolean;
  public get edoc_required_agreement(): boolean {
    return this._edoc_required_agreement;
  }
  public set edoc_required_agreement(value: boolean) {
    this._edoc_required_agreement = value;
  }

  private _gps_coord: string;
  public get gps_coord(): string {
    return this._gps_coord;
  }
  public set gps_coord(value: string) {
    this._gps_coord = value;
    if (this._gps_coord) {
      this._autocomplete_value_gps_coord = this._gps_coord;
    }
  }

  private _autocomplete_value_gps_coord: any;
  public get autocomplete_value_gps_coord(): any {
    return this._autocomplete_value_gps_coord;
  }
  public set autocomplete_value_gps_coord(value: any) {
    this._autocomplete_value_gps_coord = value;
  }

  private _iban: string;
  public get iban(): string {
    return this._iban;
  }
  public set iban(value: string) {
    this._iban = value;
  }
  
  private _loading_at_weekend: boolean;
  public get loading_at_weekend(): boolean {
    return this._loading_at_weekend;
  }
  public set loading_at_weekend(value: boolean) {
    this._loading_at_weekend = value;
  }

  private _loading_at_work_days: boolean;
  public get loading_at_work_days(): boolean {
    return this._loading_at_work_days;
  }
  public set loading_at_work_days(value: boolean) {
    this._loading_at_work_days = value;
  }

  private _maturity: number;
  public get maturity(): number {
    return this._maturity;
  }
  public set maturity(value: number) {
    this._maturity = value;
  }

  private _obligation_token: string;
  public get obligation_token(): string {
    return this._obligation_token;
  }
  public set obligation_token(value: string) {
    this._obligation_token = value;
  }

  private _street: string;
  public get street(): string {
    return this._street;
  }
  public set street(value: string) {
    this._street = value;
  }
  
  private _street_invoice: string;
  public get street_invoice(): string {
    return this._street_invoice;
  }
  public set street_invoice(value: string) {
    this._street_invoice = value;
  }

  private _swift: string;
  public get swift(): string {
    return this._swift;
  }
  public set swift(value: string) {
    this._swift = value;
  }

  private _tin: string;
  public get tin(): string {
    return this._tin;
  }
  public set tin(value: string) {
    this._tin = value;
  }

  private _updated: string;
  public get updated(): string {
    return this._updated;
  }
  public set updated(value: string) {
    this._updated = value;
    if (this._updated) {
      this._updatedDate = DateTools.apiIsoToDate(this._updated);
    }
  }

  private _updatedDate: Date;
  public get updatedDate(): Date {
    return this._updatedDate;
  }
  public set updatedDate(value: Date) {
    this._updatedDate = value;
  }

  private _work_day_begin: string;
  public get work_day_begin(): string {
    return this._work_day_begin;
  }
  public set work_day_begin(value: string) {
    this._work_day_begin = value;
  }

  private _work_day_end: string;
  public get work_day_end(): string {
    return this._work_day_end;
  }
  public set work_day_end(value: string) {
    this._work_day_end = value;
  }
  
  private _zip: string;
  public get zip(): string {
    return this._zip;
  }
  public set zip(value: string) {
    this._zip = value;
  }

  private _zip_invoice: string;
  public get zip_invoice(): string {
    return this._zip_invoice;
  }
  public set zip_invoice(value: string) {
    this._zip_invoice = value;
  }

  get order_comp_book_formatted(): string {
    return this.company + ', ' + this.city + ', ' + this.country;
  }

  private _original_documents: boolean;
  public get original_documents(): boolean {
    return this._original_documents;
  }
  public set original_documents(value: boolean) {
    this._original_documents = value;
  }
  
  private _notify_sms: boolean;
  public get notify_sms(): boolean {
    return this._notify_sms;
  }
  public set notify_sms(value: boolean) {
    this._notify_sms = value;
  }

  private _notify_email: boolean;
  public get notify_email(): boolean {
    return this._notify_email;
  }
  public set notify_email(value: boolean) {
    this._notify_email = value;
  }
  
  private _customer_tracking: boolean;
  public get customer_tracking(): boolean {
    return this._customer_tracking;
  }
  public set customer_tracking(value: boolean) {
    this._customer_tracking = value;
  }

  private _car_dests: Array<AddressBookItemCar> = [];
  public get car_dests(): Array<AddressBookItemCar> {
    return this._car_dests;
  }
  public set car_dests(value: Array<AddressBookItemCar>) {
    this._car_dests = value;

    // supporting array, but now all book items should have only one car_dest object
    if (this._car_dests.length) {
      this._car_dest = this._car_dests[0];
    }
    else {
      this._car_dest = null;
    }
  }

  private _car_dest: AddressBookItemCar = new AddressBookItemCar();
  public get car_dest(): AddressBookItemCar {
    return this._car_dest;
  }
  public set car_dest(value: AddressBookItemCar) {
    this._car_dest = value;
  }
  
  private _pricelist: Array<AddressBookItemPricelist> = [];
  public get pricelist(): Array<AddressBookItemPricelist> {
    return this._pricelist;
  }
  public set pricelist(value: Array<AddressBookItemPricelist>) {
    this._pricelist = value;
    if (this._pricelist) {
      this._pricelist.sort((a, b) => {
        // sort by codes primary
        if (a.code != b.code) {
          return a.code.localeCompare(b.code);
        }
        // sort by valid_until secondary
        else if (a.valid_until != b.valid_until) {
          if (!a.valid_until) return -1;
          if (!b.valid_until) return 1;
          return a.valid_until.localeCompare(b.valid_until);
        }
        // sort by incl_limit
        else if (a.incl_limit != b.incl_limit) {
          if (!a.incl_limit) return -1;
          if (!b.incl_limit) return 1;
          return a.incl_limit < b.incl_limit ? -1 : 1;
        }
        else {
          // sort by price otherwise 
          if (a.price <= b.price) return -1;
          else return 1;
        }
      });
    }
  }

  // custom
  private _persons: Array<Person> = [];
  public get persons(): Array<Person> {
    return this._persons;
  }
  public set persons(value: Array<Person>) {
    this._persons = value;
  }

  // custom
  private _detailOpen: boolean = false;
  public get detailOpen(): boolean {
    return this._detailOpen;
  }
  public set detailOpen(value: boolean) {
    this._detailOpen = value;
  }

  // custom
  private _attachments: Array<AddressBookItemAttachment> = [];
  public get attachments(): Array<AddressBookItemAttachment> {
    return this._attachments;
  }
  public set attachments(value: Array<AddressBookItemAttachment>) {
    this._attachments = value;
  }
  

  get apiObject(): any {
    return {
      account: this.account,
      blocked: this.blocked,
      cin: this.cin,
      city: this.city,
      city_invoice: this.city_invoice,
      company: this.company,
      company_invoice: this.company_invoice,
      country: this.country,
      country_invoice: this.country_invoice,
      customer_tracking: this.customer_tracking,
      edoc_required_itinerary: this._edoc_required_itinerary,
      edoc_required_documents: this._edoc_required_documents,
      edoc_required_invoice: this._edoc_required_invoice,
      edoc_required_agreement: this._edoc_required_agreement,
      iban: this._iban,
      gps_coord: this._gps_coord,
      maturity: this._maturity,
      notify_email: this._notify_email,
      notify_sms: this._notify_sms,
      original_documents: this._original_documents,
      street: this._street,
      street_invoice: this._street_invoice,
      swift: this._swift,
      tin: this._tin,
      zip: this._zip,
      zip_invoice: this._zip_invoice
    }
  }
}
