<!-- <div *ngIf="onTheWay" class="row">
  <div class="col-sm-6">
    <div class="form-group">
      <label>Maximální vzdálenost</label>
      <input class="form-control" type="number" name="serchrangeStartOnWay"/>
      <input class="form-control" type="number" name="serchrangeEndOnWay"/>
    </div>
  </div>
</div>

<div *ngIf="backHome" class="row">
  <div class="col-sm-6">
    <div class="form-group">
      <label>Maximální vzdálenost xxxxxxxxxxxx</label>
      <input class="form-control" type="number" name="serchrangeStartHome"/>
      <input class="form-control" type="number" name="serchrangeEndHome"/>
    </div>
  </div>
</div> -->

<div *ngIf="agenda && response?.payload" class="d-flex justify-content-end mb-0">
  <div class="mr-2" i18n>Mapa</div>
  <div class="custom-control custom-switch" placement="bottom auto" container="body"
  ngbTooltip="Zobrazit výsledky na mapě" i18n-ngbTooltip>
    <input class="custom-control-input" type="checkbox" [id]="showMapSwitchId + agenda.obligation_key" 
    [(ngModel)]="showMap" (ngModelChange)="detectChanges()"/>
    <label class="custom-control-label text-nowrap normal-font-size mb-0" [for]="showMapSwitchId + agenda.obligation_key">
    </label>
  </div>
</div>

<div *ngIf="!showMap && response?.payload">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Počet Nakl/Vykl</th>
        <th>Datum</th>
        <th>Z</th>
        <th>Přejezd</th>
        <th>Do</th>
        <th>Přejezd</th>
        <th>Typ návěsu</th>
        <th>Délka</th>
        <th>Hmotnost</th>
        <th>Odkaz</th>
      </tr>
    </thead>
    <tbody *ngIf="backHome">
      <tr *ngFor="let item of response.payload">
        <!-- <td>{{item.loadingPlaces[0].earliestLoadingDateObject}}</td>         <td>{{this.computeTransitOnRoad2(item)}}</td> -->
        <td>{{item.loadingPlaces.length}}</td>
        <td>{{item.earliestLoadingDateObject ? (item.earliestLoadingDateObject | date: 'dd.MM.yyyy') : ''}}</td>
        <td>{{item.loadingPlaces[0].address.city}}</td>
        <td>{{this.computeTransit(item)}}Km</td>
        <td>{{item.loadingPlaces[item.loadingPlaces.length -1].address.city}}</td>
        <td>{{this.computeTransit2(item)}}Km</td>
        <td>{{item.vehicleProperties.body[0]}}</td>
        <td>{{item.length_m}}m</td>
        <td>{{item.weight_t}}t</td>
        <td>
          <a [href]="item.deeplink" target="_blank">
            <img src="assets/icons-new/Timocom_mini.svg"  height="18px">
          </a>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="onTheWay">
      <tr *ngFor="let item of response.payload">
        <td>{{item.loadingPlaces.length}}</td>
        <!-- <td>{{item.loadingPlaces[0].earliestLoadingDateObject}}</td>         <td>{{this.computeTransitOnRoad2(item)}}</td> -->
        <td>{{item.earliestLoadingDateObject ? (item.earliestLoadingDateObject | date: 'dd.MM.yyyy') : ''}}</td>
        <td>{{item.loadingPlaces[0].address.city}}</td>
        <td>{{computeTransitOnRoad(item)}}Km</td>
        <td>{{item.loadingPlaces[item.loadingPlaces.length -1].address.city}}</td>
        <td>{{computeTransitOnRoad2(item)}}Km</td>
        <td>{{item.vehicleProperties.body[0]}}</td>
        <td>{{item.length_m}}m</td>
        <td>{{item.weight_t}}t</td>
        <td>
          <a [href]="item.deeplink" target="_blank">
            <img src="assets/icons-new/Timocom_mini.svg"  height="18px">
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>


<div *ngIf="showMap && response?.payload">
  <div class="google-map-wrapper" style="position: relative;" style="height: 60vh">
    <div class="googleMapTimocom fill"
      [groupMarkers]="groupMarkers"
      [properties]="{ scrollwheel: true, zoom: 7 }" 
      [buildMapOnOpen]="true">
    </div>
  </div>
</div>