<!-- <div id="myModal" class="modal" role="dialog" style="display: block"> -->
<div class="modal fade" id="broadcastModal" data-backdrop="static" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="broadcastModalLabel" i18n>Hromadná zpráva řidičům</h4>
        <button (click)="closeDialog()" type="button" class="close">&times;</button>
      </div>
      <form (submit)="sendMessages()">
        <div class="modal-body">
          <h6 i18n>Tato zpráva bude odeslána těmto vozidlům/řidičům:</h6>
          
          <div *ngIf="loadingVehicles" class="spinner-wrapper py-12">
            <div class="spinner-border text-primary" style="width:2rem; height:2rem;" role="status">
              <span class="sr-only"></span>
            </div>
          </div>

          <div *ngIf="!loadingVehicles" class="mt-3">
            <h6 *ngFor="let v of vehicles; let idx=index">
              <div class="custom-control custom-switch">
                <input class="custom-control-input" type="checkbox" [id]="'mark' + v.car_key"
                [(ngModel)]="v.broadcastMarked" [ngModelOptions]="{ standalone: true }"/> 
                <label class="custom-control-label" [for]="'mark' + v.car_key">
                  {{v.number_plate}}, {{v.driver_name}}
                </label>
              </div>
            </h6>
          </div>

          <textarea name="message" class="form-control mt-2" [(ngModel)]="message" 
          placeholder="Vaše zpráva..." i18n-placeholder
          style="height:150px; overflow-wrap:break-word; white-space:pre-wrap">
          </textarea>
        </div>
        <div class="modal-footer">
          <button class="btn-success btn" type="submit" [disabled]="!vehicles.length">
            <ng-container i18n>Odeslat</ng-container>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <div class="modal-backdrop fade in"></div> -->