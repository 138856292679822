<!-- No itinerary found info -->
<h6 *ngIf="!loading && (!itinerary || !itinerary.length) && 
  (!serviceEventsOutOfAgenda || !serviceEventsOutOfAgenda.length)" class="mt-2">
  <ng-container i18n>Dnes nebyly zaznamenány žádné nakládky / vykládky</ng-container>
</h6>

<!-- Loading Spinner -->
<div *ngIf="loading" class="spinner-wrapper py-4">
  <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
    <span class="sr-only"></span>
  </div>
</div>

<!-- Planned itinerary from agenda -->
<div *ngIf="!loading && itinerary?.length">
  <div class="clearfix">
    <table class="col-sm-12 table-bordered table-striped">
      <tbody>
        <tr>
          <td class="bg-secondary text-light">
            <strong class="mx-2">
              <span i18n>PLÁNOVANÉ</span> ({{itinerary.length}} <span i18n>položek</span>)
            </strong>
          </td>
        </tr>
    
        <tr *ngFor="let it of itinerary" [class]="it.css_class">
          <td class="w-100 px-1">
            <div class="d-inline-flex w-100">
              <div class="d-flex">
                <div class="padding-top-5 pointer my-auto" (click)="openCurrentPositionDetail(it)">
                  <img [src]="it.typeIcon()" class="pointer" width="30" height="30"/>
                </div>
                <div class="padding-top-5 my-auto">
                  <img *ngIf="!it.hasStarted && !it.isDone" width="30" height="30" class="pointer"
                  src="assets/icons-new/navigate_cross_red_transition_1.svg"
                  ngbTooltip="Nezahájena" data-toggle="modal" data-target="#agendaServiceModal"
                  (click)="itineraryToEvent = it; hotfixRemoveBackdropModal();" i18n-ngbTooltip/>
                  <img *ngIf="it.hasStarted && !it.isDone" width="30" height="30" class="pointer"
                  src="assets/icons-new/check-orange-transition.svg"
                  ngbTooltip="Probíhá" data-toggle="modal" data-target="#agendaServiceModal"
                  (click)="itineraryToEvent = it; hotfixRemoveBackdropModal();" i18n-ngbTooltip/>
                  <img *ngIf="it.isDone" width="30" height="30" 
                  src="assets/icons-new/check-green-transition.svg"
                  ngbTooltip="Dokončena" i18n-ngbTooltip/>
                </div>
              </div>
              <div class="text-center my-auto" style="min-width:50px; width:50px;">
                <div *ngIf="it.hasStarted" ngbTooltip="Čas zahájení" i18n-ngbTooltip>
                  <span *ngIf="!it.startTime">--:--</span>
                  <span *ngIf="it.startTime">
                    {{it.startTime | date:'HH:mm'}}
                  </span>
                </div>
                <div *ngIf="it.isDone" ngbTooltip="Čas dokončení" i18n-ngbTooltip>
                  <strong *ngIf="it.endTime">
                    {{it.endTime | date:'HH:mm'}}
                  </strong>
                </div>
                <div *ngIf="it.isDone" ngbTooltip="Doba trvání" i18n-ngbTooltip>
                  {{it.durationFormatted}}
                </div>
                <!-- viscofan feature  -->
                <div *ngIf="!it.hasStarted && it.agenda?.order_comp_book_key == 26646" 
                class="padding-top-5 pointer my-auto">
                  <span class="pointer ml-1" class="pointer ml-1" 
                  data-toggle="modal" data-target="#viscofanModal" 
                  (click)="agendaToViscofan = it.agenda"
                  ngbTooltip="Viscofan incident" container="body" placement="left auto">
                    <img src="assets/icons-new/message.svg" width="28px" height="28px"/>
                  </span>
                </div>
              </div>
              <div class="text-left">
                <div class="pointer diary-truncate text-truncate" (click)="openItineraryAgendaDetail(it)">
                  <strong>{{it.numberPlateCar()}}</strong>,
                  <span>{{it.vehicle.driver_name}}</span>,
                  <strong>{{it.order_comp_book_name}}</strong>
                </div>
                
                <div class="pointer diary-truncate text-truncate" (click)="openItineraryAgendaDetail(it)">
                  {{it.address}}
                </div>
                
                <!-- <div *ngIf="!it.isDone" class="diary-truncate">
                  ~{{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                </div> -->
                <div *ngIf="!it.isDone" class="d-flex justify-content-between">
                  <div>~{{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}</div>
                  <div *ngIf="it.obligation">
                    <a href="javascript:void(0)" (click)="openObligationNewTab(it.obligation.obligation_key)">
                      ZA{{it.obligation.order_number_standard}}
                    </a>
                  </div>
                </div>
                <div *ngIf="it.isDone" class="d-flex justify-content-between diary-truncate">
                  <div class="pointer text-truncate" (click)="openItineraryAgendaDetail(it)">
                    {{it.weightServiceEvent}},
                    {{it.lengthMetersServiceEvent}},
                    {{it.containersOutServiceEvent}}/{{it.containersInServiceEvent}}
                    {{it.ware_type ? it.ware_type : 'PAL'}}
                    {{it.note ? ',' + it.note : ''}}
                  </div>
                  <!-- show also badge for files of completed unloading itinerary -->
                  <div *ngIf="it.type == ItineraryType.UNLOADING" class="text-nowrap">
                    <div *ngIf="it.agenda?.loadingFiles" class="spinner-grow text-primary" 
                    style="width:16px; height:16px;" role="status">
                      <span class="sr-only"></span>
                    </div>
                    
                    <ng-container *ngIf="!it.agenda.loadingFiles">
                      <span *ngIf="!it.agenda.files.length" class="badge badge-dark pointer" 
                      ngbTooltip="Žádné přiložené soubory" 
                      container="body" placement="auto" i18n-ngbTooltip>
                        0
                      </span>
                      
                      <span *ngIf="it.agenda.files.length" 
                        class="badge badge-dark pointer"
                        [ngbPopover]="filesPopover" [ngbTooltip]="filesTooltip"
                        [autoClose]="'outside'" placement="left auto" container="body"
                        (click)="downloadThumbnails(it.agenda);">
                        {{it.agenda.files.length}}
                      </span>

                      <ng-template #filesTooltip>
                        <ng-container i18n>Počet přiložených souborů</ng-container>: 
                        {{it.agenda.files.length}}
                        <br>ZA{{it.agenda.order_number}}
                      </ng-template>

                      <ng-template #filesPopover>
                        <div *ngIf="it.agenda.files.length">
                          <h6 class="normal-font-size">
                            <ng-container i18n>Přiložené soubory</ng-container>
                            - ZA{{it.agenda.order_number}}
                          </h6>
                          <h6 *ngIf="downloadingFile" class="text-primary normal-font-size text-truncate d-flex">
                            <div class="mr-2" i18n>Otevírám zvolený soubor...</div>
                            <div class="spinner-wrapper">
                              <div class="spinner-border text-primary" style="width:1rem; height:1rem;" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                          </h6>

                          <div class="row p-1" [style.width]="widthPopoverOfFiles(it.agenda)">
                            <div *ngFor="let file of it.agenda.files" class="p-0 px-2 mb-2 position-relative" style="width:130px">
                              <!-- loading thumbnail -->
                              <div *ngIf="file.loadingThumbnail" class="w-100 text-center">
                                <div class="spinner-wrapper">
                                  <div class="spinner-border text-primary" style="width:1rem; height:1rem;" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>
                              </div>
                              <!-- thumbnail loaded -->
                              <div *ngIf="!file.loadingThumbnail">
                                <a (click)="downloadAttachment(file)" href="javascript:void(0)">
                                  <div *ngIf="file.thumbnail" class="thumbnail border rounded shadow" [ngbTooltip]="file.name">
                                    <img class="thumbnail" [src]="file.thumbnail.content | safeResource" alt="Náhled"/>
                                  </div>
                                  <div *ngIf="!file.thumbnail" class="thumbnail border rounded shadow" [ngbTooltip]="file.name">
                                    <img class="thumbnail" src="assets/icons-new/folder_document.svg" alt="Náhled"/>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>

                      <span *ngIf="it.agenda.files.length && !it.agenda.email_headers.length" 
                      class="pointer ml-1" (click)="agendaToSend = it.agenda"
                      ngbTooltip="Odeslat dokumenty" container="body" placement="left auto" i18n-ngbTooltip>
                        <img src="assets/icons-new/mail.svg" height="18px"/>
                      </span>
            
                      <span *ngIf="it.agenda.files.length && it.agenda.email_headers.length" 
                      class="pointer ml-1" container="body" placement="left auto"
                      [ngbPopover]="emailsInfoPopover" ngbTooltip="Dokumenty odeslány" i18n-ngbTooltip>
                        <img src="assets/icons-new/mail-green.svg" height="18px"/>
                      </span>
            
                      <ng-template #emailsInfoPopover>
                        <div *ngIf="it.agenda.email_headers?.length">
                          <div *ngFor="let header of it.agenda.email_headers; let i = index">
                            <a href="javascript:void(0)" (click)="showEmailDetail(header.email_msg_id)">
                              {{i+1}}) 
                              <ng-container i18n>Email odeslán</ng-container> - 
                              {{header.sent_date_object ? (header.sent_date_object | date: 'dd.MM.yyyy HH:mm') : ''}}
                            </a>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- details -->
            <div class="w-100">
              <!-- agenda detail -->
              <div class="agenda-detail" 
                *ngIf="itineraryAgendaDetail === it && it.agenda" 
                [agenda]="it.agenda">
              </div>

              <!-- map -->
              <div *ngIf="currentPositionDetailItinerary === it && it.agenda && vehicle">
                <r-vehicle-manual-tracking-itinerary 
                  [itinerary]="it"
                  [vehicle]="vehicle"
                  [heightMap]="'180px'"
                  (hideMap)="hideMapEvent($event)">
                </r-vehicle-manual-tracking-itinerary>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div> 
  
<!-- Custom/not planned itinerary (out of agenda) -->
<div *ngIf="serviceEventsOutOfAgenda?.length" [class.mt-3]="itinerary?.length">
  <div class="clearfix">
    <table class="col-sm-12 table-bordered table-striped">
      <tbody>
        <tr>
          <td colspan="9" class="bg-secondary text-light">
            <strong class="mx-2">
              <span i18n>NEPLÁNOVANÉ</span> ({{serviceEventsOutOfAgenda?.length}} <span i18n>položek</span>)
            </strong>
          </td>
        </tr>
    
        <tr *ngFor="let it of itineraryOutOfAgenda">
          <td class="w-100 px-1">
            <div class="d-inline-flex w-100">
              <div class="d-flex">
                <div class="padding-top-5 my-auto">
                  <img [src]="it.typeIcon()" width="30" height="30"/>
                </div>
                <div class="padding-top-5 my-auto">
                  <img *ngIf="!it.hasStarted && !it.isDone" width="30" height="30" 
                  src="assets/icons-new/navigate_cross_red_transition_1.svg"
                  ngbTooltip="Nezahájena" i18n-ngbTooltip/>
                  <img *ngIf="it.hasStarted && !it.isDone" width="30" height="30" 
                  src="assets/icons-new/check-orange-transition.svg"
                  ngbTooltip="Probíhá" i18n-ngbTooltip/>
                  <img *ngIf="it.isDone" width="30" height="30" 
                  src="assets/icons-new/check-green-transition.svg"
                  ngbTooltip="Dokončena" i18n-ngbTooltip/>
                </div>
              </div>
              <div class="text-center" style="min-width:50px; width:50px;">
                <div *ngIf="it.hasStarted" ngbTooltip="Čas zahájení" i18n-ngbTooltip>
                  <span *ngIf="!it.startTime">--:--</span>
                  <span *ngIf="it.startTime">
                    {{it.startTime | date:'HH:mm'}}
                  </span>
                </div>
                <div *ngIf="it.isDone" ngbTooltip="Čas dokončení" i18n-ngbTooltip>
                  <strong *ngIf="it.endTime">
                    {{it.endTime | date:'HH:mm'}}
                  </strong>
                </div>
                <div *ngIf="it.isDone" ngbTooltip="Doba trvání" i18n-ngbTooltip>
                  {{it.durationFormatted}}
                </div>
              </div>
              <div class="text-left">
                <div class="diary-truncate text-truncate">
                  <strong>{{it.numberPlateCar()}}</strong>,
                  <span>{{it.vehicle.driver_name}}</span><strong *ngIf="it.note">, {{it.note}}</strong>
                </div>
                <div class="diary-truncate text-truncate">
                  {{it.address}}
                </div>
                <div *ngIf="it.isDone" class="diary-truncate text-truncate">
                  {{it.weightServiceEvent}},
                  {{it.lengthMetersServiceEvent}},
                  {{it.containersOutServiceEvent}}/{{it.containersInServiceEvent}}
                  {{it.ware_type ? it.ware_type : 'PAL'}}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!-- modal for creating service event of loading/unloading via api -->
<div class="modal fade" role="dialog" tabindex="-1" id="agendaServiceModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title" i18n>Ruční vytvoření nakl/vykl pomocí TruckAgendy</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body bg-secondary-dark">
        <div *ngIf="newServiceEvent" class="container text-left rounded bg-secondary-dark">
          <h4 class="text-white my-2">
            {{newServiceEvent.type == ItineraryType.LOADING ? 'Nakládka' : 'Vykládka'}}
          </h4>
          
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white" i18n>Zakázka</label>
                <input class="form-control text-white" type="text"
                style="background-color: #5f5f5f !important;"
                [ngModel]="newServiceEvent.order_number" disabled/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white" i18n>Vozidlo</label>
                <input class="form-control text-white" type="text"
                style="background-color: #5f5f5f !important;"
                [ngModel]="newServiceEvent.car_key" disabled/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="text-white" i18n>Datum a čas</label>
                <input class="form-control text-white bg-secondary-dark" type="datetime-local"
                [(ngModel)]="newServiceEvent.time_custom"/>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white" i18n>Hmotnost</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.weight"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white" i18n>Ložná délka</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.length"/>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white" i18n>Odevzdal prázdné (obaly/palety)</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.containers_out"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white" i18n>Naložil s nákladem (obaly/palety)</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.containers_in"/>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="text-white" i18n>Poznámka</label>
                <textarea class="form-control text-white bg-secondary-dark" 
                placeholder="Poznámka až 100 znaků" i18n-placeholder
                [(ngModel)]="newServiceEvent.descr">
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-secondary-dark">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
        <button class="btn btn-success" type="button" data-dismiss="modal"
        (click)="createNewServiceEvent()" i18n>Vytvořit</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for sending report to VISCOFAN -->
<div class="modal fade" role="dialog" tabindex="-1" id="viscofanModal">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title" i18n>Viscofan incident</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label i18n>Typ události</label>
          <select class="form-control" [(ngModel)]="viscofanReportType">
            <option [ngValue]="null" i18n>Zvolte</option>
            <option *ngFor="let type of VISCOFAN_REPORT_TYPES" [value]="type">
              {{type}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label i18n>Zpráva</label>
          <textarea class="form-control" placeholder="Zadejte vaši zprávu o události"
          [(ngModel)]="viscofanMessage" i18n-placeholder></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" type="button" [disabled]="!viscofanReportType"
        (click)="sendReportToViscofan()">
          <ng-container i18n>Odeslat</ng-container>
        </button>
        <button class="btn btn-light" type="button" data-dismiss="modal">
          <ng-container i18n>Zavřít</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal for sending attachments via email -->
<div class="modal fade" role="dialog" tabindex="-1" id="sendAttachmentsModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <div *ngIf="agendaToSend">
          <h4 class="modal-title">
            <ng-container i18n>Odeslat doklady</ng-container> - {{agendaToSend.order_number}}
          </h4>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="agendaToSend">
          <app-ta1-obligation-emailing #myEmailingChild
          [agenda]="agendaToSend"
          (emailHasBeenSent)="eventAfterEmailSent($event)"
          [attachments]="attachmentsToSend">
          </app-ta1-obligation-emailing>
        </div>
      </div>
    </div>
  </div>
</div>