import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { StorageKeys } from 'src/app/config';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { TrackingEvent } from 'src/app/model/tracking-event.object';
import { StorageService } from 'src/app/service/storage.service';
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';
import { Colors } from 'src/app/tools/Colors';

declare var google: any;

@Component({
  selector: 'div.googleMapTimocom',
  templateUrl: './google-map-timocom.component.html',
  styleUrls: ['./google-map-timocom.component.css']
})
export class GoogleMapTimocomComponent implements OnInit, OnDestroy {

  // usage in components - timocom
  
  private _subscriptions: Array<Subscription> = [];

  private _map: any;
  private _initialized: boolean = false;
  private _bounds: any;
  private _infoWindows: Array<any> = [];
  private _polyLines: Array<any> = [];

  private _DEFAULT_ZOOM: number = 7;

  private _key: number;
  get key(): number {
    return this._key;
  }

  private _trafficLayerShow: boolean = true;
  private _trafficLayer: any = null;
  get trafficLayer(): any {
    return this._trafficLayer;
  }
  
  // inputs
  private _properties = {};
  @Input()
  set properties(properties: any) {
    this._properties = properties;
    if (this._properties && this._properties['zoom']) {
      this._DEFAULT_ZOOM = this._properties['zoom'];
    }
  }

  @Input() rebuildMap: Observable<boolean> = new Observable();
  @Input() clearMap: Observable<boolean> = new Observable();

  private _buildMapOnOpen: boolean = false;
  public get buildMapOnOpen(): boolean {
    return this._buildMapOnOpen;
  }
  @Input()
  public set buildMapOnOpen(value: boolean) {
    this._buildMapOnOpen = value;
    if (this._buildMapOnOpen) {
      this.buildData();
      this._initialized = true;
    }
  }
  
  // fix condition for using in diary-mobile
  private _buildOnMarkersInit: boolean = false;
  @Input()
  set buildOnMarkersInit(value: boolean) {
    this._buildOnMarkersInit = value;
  }

  // mainRoad coordinates
  private _mainRoadCoordinates: Array<any> = [];
  public get mainRoadCoordinates(): Array<any> {
    return this._mainRoadCoordinates;
  }
  @Input()
  public set mainRoadCoordinates(value: Array<any>) {
    this._mainRoadCoordinates = value;
  }

  // load on the road coordinates
  private _posibleLoadsCoordinates: Array<any> = [];
  public get posibleLoadsCoordinates(): Array<any> {
    return this._posibleLoadsCoordinates;
  }
  @Input()
  public set posibleLoadsCoordinates(value: Array<any>) {
    this._posibleLoadsCoordinates = value;
  }

  
  private _groupMarkers: Array<Array<GoogleMapMarker>> = [];
  @Input()
  public set groupMarkers(value: Array<Array<GoogleMapMarker>>) {
    //console.log("group Markers");
    //console.log(this._groupMarkers);

    this.clearMarkers();
    this.clearData(false);

    // if (this._buildOnMarkersInit) {
    //   this.rebuildData(false);
    // }

    this._groupMarkers = value;
  }
  public get groupMarkers(): Array<Array<GoogleMapMarker>> {
    return this._groupMarkers;
  }

  private _markersDefault: Array<GoogleMapMarker> = [];
  @Input()
  public set markersDefault(value: Array<GoogleMapMarker>) {
    this.markersDefault = [];
    this._markersDefault = value;
  }
  public get markersDefault(): Array<GoogleMapMarker> {
    return this._markersDefault;
  }



  // markers could be provided from component
  private _markers: Array<GoogleMapMarker> = [];
  @Input()
  set markers(value: Array<GoogleMapMarker>) {
    this._markers = value;
    // remove null marker placeholders (company-obligation)
    if (this._markers) {
      this._markers = this._markers.filter(m => m);
    }
  }

  // outputs
  private _clickEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  get clicked(): Observable<any> {
    return this._clickEmitter.asObservable();
  }


  constructor(
    private _elRef: ElementRef,
    private _storageService: StorageService,
    private _externalApiRequestServ: ExternalApiRequestService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
    // get traffic layer cookie
    let traffic: string = this._storageService.getItem(StorageKeys.map.traffic_layer, true);
    this._trafficLayerShow = (traffic == 'false') ? false : true;
  }



  ngOnInit(): void {
    // added event listener - parent could sent order to rebuild map
    this._subscriptions.push(
      this.rebuildMap.subscribe(
        (rebuild: boolean) => {
          if (rebuild) {
            this.rebuildData(false);
          }
        }
      ),
      this.clearMap.subscribe(
        (clear: boolean) => {
          if (clear) {
            this.clearData(true);
          }
        }
      )
    );

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-obligation';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }
  
  ngOnDestroy() {
    this.clearMarkers();
    this.clearData(true);
    this.markers = [];
    
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._subscriptions = [];
  }

  clearData(dropMap: boolean) {
    //console.log('clearData');
    // info windows
    this._infoWindows.forEach(
      infoWindow => {
        infoWindow.close();
        infoWindow.setMap(null);
      }
    );
    this._infoWindows = [];
    // polylines
    this._polyLines.forEach(
      polyLine => {
        polyLine.setMap(null);
      }
    );
    this._polyLines = [];
    this._bounds = null;
    // drop map
    if (dropMap) {
      this._map = null;
    }
  }

  clearMarkers(): void {
    //console.log('clearMarkers');
    // markers
    this._markers.forEach(
      marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
          marker.infoWindow = null;
        }
        marker.clearEvents();
        marker.map = null;
      }
    );
    this._markers = [];
  }

  
  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private _rebuildTimeout: number = null;
  public get rebuildTimeout(): number {
    return this._rebuildTimeout;
  }

  private _building: boolean = false;
  
  private rebuildData(dropMap: boolean) {
    if (!this._initialized) {
      return;
    }
    if (!this._building && this._rebuildTimeout === null) {
      this._rebuildTimeout = window.setTimeout(
        () => {
          this.clearData(dropMap);
          this.buildData();
          this._rebuildTimeout = null;
        },
        1000
      )
    }
  }
  
  private buildData() {
    this._building = true;

    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));
      
      this._map.addListener('click', (data) => {
        this._clickEmitter.emit(data);
      });
    }

    this._markers = [];
    if (this._groupMarkers.length) {
      this._bounds = new google.maps.LatLngBounds();
      this.groupMarkers.forEach(
        (line : Array<GoogleMapMarker> )=> {   
          // draw polylines    
          if (line[0] && line[1] && line[0]) {
            let poliline = new google.maps.Polyline({
              path: [
                line[0].position,
                line[1].position
              ],
              geodesic: true,
              strokeColor: Colors.getColor(Math.floor(Math.random() * 27)),
              strokeOpacity: 1.0,
              strokeWeight: 2,
              zIndex: 1000,
              map: this._map
            });

            // set up info window
            let htmlContent: string = '<div style="font-weight:bold; padding:2px; border-radius:7px; background:' + '#ffffff' + '; color:#000000">';
            htmlContent += line[0].tonage + '</div>';
            let infoWindow = new google.maps.InfoWindow({
              content: htmlContent,
            });
            
            poliline.addListener('mouseover', (event) => {
              poliline.setOptions({strokeColor: '#00FFAA',strokeWeight: 4});
              infoWindow.setPosition(event.latLng);
              infoWindow.open(this._map);
            })
            poliline.addListener('mouseout',()=>{
              poliline.setOptions({strokeColor:Colors.getColor(Math.floor(Math.random() * 27)),strokeWeight: 2});
              infoWindow.close();
            })
            poliline.addListener("click", ()=>{
              window.open(line[0].hipperling);
            })

            

          this._polyLines.push(poliline);
        }
          // draw markers
          if(this._markersDefault){
            this._markersDefault.forEach((marker:GoogleMapMarker,index) =>{
              marker = this.setMarker(marker);
              this._markers.push(marker);
              if (marker.position) {
                this._bounds.extend(marker.position);
              }
            });
          }

          line.forEach(
            (marker: GoogleMapMarker, index) => {
              // setup marker and bounds
              marker = this.setMarker(marker);
              this._markers.push(marker);
              if (marker.position) {
                this._bounds.extend(marker.position);
              }
            }
          );
        }
      );
      }


    if (this._map) {
      if (this._bounds && this._markers.length > 1) {
        this._map.fitBounds(this._bounds);
      } 
      else {
        if (this._markers.length) {
          this._map.setCenter(this._markers[0].position);
        }
        this._map.setZoom(this._DEFAULT_ZOOM);
      }

      // possibly draw traffic layer
      if (this._trafficLayerShow && !this._trafficLayer) {
        this.showTrafficLayer();
      }
    }

    this._building = false;
  }


  /*************************************************************/
  // Markers methods
  /*************************************************************/
  private setMarker(marker: GoogleMapMarker): GoogleMapMarker {
    marker.map = this._map;
    // set info window
    if (marker.infoWindowContent && !marker.infoWindow) {
      if (marker.autoInfoWindowOpen) {
        let infowindow: any = new google.maps.InfoWindow({});
        this._infoWindows.push(infowindow);
        marker.infoWindow = infowindow;
        infowindow.setContent(marker.infoWindowContent);
        infowindow.open(this._map, marker.getGoogleMarker());
      }
      else {
        marker.addListener('click', () => {
          // toggle info window
          if (marker.infoWindow) {
            // close
            marker.infoWindow.close();
            marker.infoWindow = null;
          }
          else {
            // open
            let infowindow: any = new google.maps.InfoWindow({});
            this._infoWindows.push(infowindow);
            marker.infoWindow = infowindow;
            infowindow.setContent(marker.infoWindowContent);
            infowindow.open(this._map, marker.getGoogleMarker());
          }
        });
      }
    } 
    return marker;
  }

  
  /*************************************************************/
  // Traffic methods
  /*************************************************************/
  showTrafficLayer() {
    this._trafficLayer = new google.maps.TrafficLayer();
    this._trafficLayer.setMap(this._map);
  }

  toggleTrafficLayer() {
    if (!this._trafficLayer) {
      this._trafficLayer = new google.maps.TrafficLayer();
    }

    if (this._trafficLayer.getMap() == null){
      // traffic layer is disabled.. enable it
      this._trafficLayer.setMap(this._map);
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'true', true);
    }
    else {
      // traffic layer is enabled.. disable it
      this._trafficLayer.setMap(null);        
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'false', true);     
    }
  }
}