import {AetrCollectionPartInterface} from "../interface/aetr-collection-part.interface";

export class AetrDataCollectionObject {

  static AETR_PART_TYPE_DRIVE = 'D';
  static AETR_PART_TYPE_SLEEP = 'S';

  constructor(
    private _pastSegment: AetrCollectionPartInterface,
    private _currentSegment: AetrCollectionPartInterface,
    private _futureSegment?: AetrCollectionPartInterface
  ) {
    if (_pastSegment) {
      this._segments.push(_pastSegment);
    }
    if (_currentSegment) {
      this._segments.push(_currentSegment);
    }
    if (_futureSegment) {
      this._segments.push(_futureSegment);
    }
  }

  private _segments: Array<AetrCollectionPartInterface> = [];
  get segments(): Array<AetrCollectionPartInterface> {
    return this._segments;
  }

  get pastSegment(): AetrCollectionPartInterface {
    return this._pastSegment;
  }

  get currentSegment(): AetrCollectionPartInterface {
    return this._currentSegment;
  }

  get futureSegment(): AetrCollectionPartInterface {
    return this._futureSegment;
  }

  isSegmentDrive(aetr_part: AetrCollectionPartInterface) {
    return aetr_part.type === AetrDataCollectionObject.AETR_PART_TYPE_DRIVE;
  }

  isSegmentSleep(aetr_part: AetrCollectionPartInterface) {
    return aetr_part.type === AetrDataCollectionObject.AETR_PART_TYPE_SLEEP;
  }

  isSegmentPast(aetr_part: AetrCollectionPartInterface): boolean {
    return aetr_part === this._pastSegment;
  }

  isSegmentCurrent(aetr_part: AetrCollectionPartInterface): boolean {
    return aetr_part === this._currentSegment;
  }

  isSegmentFuture(aetr_part: AetrCollectionPartInterface): boolean {
    return aetr_part === this._futureSegment;
  }

  get isPastAndCurrentSegmentSleep(): boolean {
    return (this.isSegmentSleep(this.pastSegment) && this.isSegmentSleep(this.currentSegment));
  }

  private _work_shift_start: string;
  public get work_shift_start(): string {
    return this._work_shift_start;
  }
  public set work_shift_start(value: string) {
    this._work_shift_start = value;
  }

  private _work_shift_duration: number;
  public get work_shift_duration(): number {
    return this._work_shift_duration;
  }
  public set work_shift_duration(value: number) {
    this._work_shift_duration = value;
  }
  
  private _work_shift_duration_dbg: string;
  public get work_shift_duration_dbg(): string {
    return this._work_shift_duration_dbg;
  }
  public set work_shift_duration_dbg(value: string) {
    this._work_shift_duration_dbg = value;
  }

  // format 0:54h
  private _work_shift_duration_string: string;
  public get work_shift_duration_string(): string {
    return this._work_shift_duration_string;
  }
  public set work_shift_duration_string(value: string) {
    this._work_shift_duration_string = value;
  }

  private _work_shift_errors: Array<any> = [];
  public get work_shift_errors(): Array<any> {
    return this._work_shift_errors;
  }
  public set work_shift_errors(value: Array<any>) {
    this._work_shift_errors = value;
  }
  
  private _work_shift_errors_alt: any;
  public get work_shift_errors_alt(): any {
    return this._work_shift_errors_alt;
  }
  public set work_shift_errors_alt(value: any) {
    this._work_shift_errors_alt = value;
  }
}
