<div class="col-12 col-sm-12 col-md-12 dashboard-head messages-read">
  <i class="rightMenuImg" id="dashboard-msgRead"></i>
  <i id="dashboard-broadcast" class="rightMenuImg pointer" 
  (click)="showBroadcast()" ngbTooltip="Hromadná zpráva všem řidičům" i18n-ngbTooltip></i>
  <div class="d-inline-block my-0" style="width: 170px; line-height: 40px;">
    <div class="input-group input-group-lg my-0">
      <input class="form-control" type="date" [(ngModel)]="selectedDate"/>
      <div class="input-group-append">
        <button class="btn btn-warning" type="button" (click)="searchMessagesDate()">
          <img height="18px" src="assets/icons-new/search-solid.svg"/>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <ng-container *ngIf="messages && messages.length > 0"> -->
<messages-day-grouped-list 
  [dateFrom]="dateFrom" 
  [dateTo]="dateTo"
  [messages]="messages" 
  [reply]="true">
</messages-day-grouped-list>
<!-- </ng-container> -->
<!-- <ng-container *ngIf="messages && !messages.length && !loading">
  <h5 class="mx-3 my-2">Žádné zprávy</h5>
</ng-container> -->

<div *ngIf="!loading" class="w-100 text-center my-3">
  <button class="btn btn-primary" (click)="loadNextMessages()">
    <ng-container i18n>Zobrazit předchozí den</ng-container>
  </button>
</div>

<ng-container *ngIf="loading">
  <div class="spinner-wrapper py-3">
    <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="broadcastVisible">
  <div class="broadcast-message-dialog" (close)="hideBroadcast()"></div>
</ng-container>