import { Component, Input } from "@angular/core";

import { MessageAttachmentObject } from "../../model/message-attachment.object";

@Component({
  selector: 'div.multiMediaAttachmentComponent',
  templateUrl: './r-multi-media-attachment.component.html',
  styleUrls: ['./r-multi-media-attachment.component.css']
  // templateUrl: '/app/templates/multi-media-attachment.component.html?v=' + Date.now(),
})
export class RMultiMediaAttachmentComponent {
  private _dialogVisible: boolean = false;

  private _attachment: MessageAttachmentObject;
  get attachment(): MessageAttachmentObject {
    return this._attachment;
  }
  @Input()
  set attachment(attachment: MessageAttachmentObject) {
    if (attachment.isVideo() || attachment.isAudio()) {
      this._attachment = attachment;
    }
  }

  get visible(): boolean {
    return this._dialogVisible;
  }

  showDialog() {
    this._dialogVisible = true;
  }

  closeDialog() {
    this._dialogVisible = false;
  }
}