import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { MessageService } from "../../service/message.service";
import { Vehicle } from "../../model/vehicle.object";
import { Message } from "../../model/message.object";

@Component({
  selector: 'vehicle-messages-list',
  templateUrl: './r-vehicle-messages-list.component.html',
  styleUrls: ['./r-vehicle-messages-list.component.css']
  // templateUrl: '/app/templates/vehicle-messages-list.component.html?v=' + (Date.now())
})
export class RVehicleMessagesListComponent implements OnInit, OnDestroy {

  private _subscribed: Subscription;

  private _vehicleMessages: Array<Message> = [];
  get vehicle_messages(): Array<Message> {
    return this._vehicleMessages;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
  }

  constructor(
    private _messagesService: MessageService
  ) {
  }

  ngOnInit() {
    this._subscribed = this._messagesService.getMessagesHistory(-6, 7).subscribe(
      messagesByDay => {
        let newMessages: Array<Message> = [];
        for (let key in messagesByDay) {
          let messages = messagesByDay[key];
          messages.forEach(
            message => {
              if (message.car_key && message.car_key === this._vehicle.car_key) {
                newMessages.push(message);
              }
            }
          );
        }
        this._vehicleMessages = newMessages;
        this._vehicleMessages.sort(
          (message, messageB) => {
            if (message.time > messageB.time) {
              return -1;
            } else if (message.time < messageB.time) {
              return 1;
            }
            return 0;
          }
        );
      }
    )
  }

  ngOnDestroy() {
    this._subscribed.unsubscribe();
  }

  get loadingMessagesHistory(): boolean {
    return this._messagesService.loadingMessagesHistory;
  }
}
