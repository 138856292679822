<div id="parent">
  <div class="fixed-top" style="z-index: 10000000" *ngIf="alerts.length">
    <div *ngFor="let alert of alerts" class="alert alert-{{alert.type}}" role="alert">
      <img *ngIf="alert.type === 'danger'" src="assets/icons-new/sign_warning.svg" 
      alt="sign_warning" height="18px"/>
      <img *ngIf="alert.type === 'success'" src="assets/icons-new/check-solid.svg" 
      alt="check-solid" height="18px"/>
      {{alert.message}}
      
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div *ngIf="!isDemoOnlyDashboard" id="wide" [@wide]='dashboardVisible'>
    <div id="menu-left-parent" *ngIf="isAuthenticated && !isFullscreenRoute">
      <menu-left></menu-left>
    </div>
    <div id="content-left-parent">
      <router-outlet name="left"></router-outlet>
      <!-- lazy loading spinner -->
      <div *ngIf="lazyLoading" class="text-center">
        <div class="spinner-wrapper py-4">
          <div class="spinner-border text-primary" style="width:4rem; height:4rem;" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isDashboardControlsVisible" id="dashboard-menuTop" class="clearfix">
    <div *ngIf="dashboardVisible !== 'dashboardfull'" id="dashboard-close"
      class="rightMenuImg pointer board-{{(dashboardVisible == 'active') ? 'open' : 'close'}}" 
      (click)="toggleDashboard()"></div>
    <div *ngIf="loginDialogVisible()" id="dashboard-user"
      class="rightMenuImg {{isAuthenticated ? 'user' : 'lock'}}" (click)="showLoginDialog()" 
      placement="bottom" [ngbTooltip]="(isAuthenticated ? 'Detail uživatele' : '')"></div>
    <div class="rightMenuImg noselect pointer" id="dashboard-menu-hide" (click)="toggleDashboardMenu(true)">
      <span [@unreadMessagesIcon]="dashboardMenu" *ngIf="newMessagesCount > 0" class="sub-icon-text">{{newMessagesCount}}</span>
    </div>
  </div>

  <div id="dashboard" [@dashboardVisible]="dashboardVisible" class="tab-content sidebar-nav container">
  <!-- {{isDashboardFullMode ? 'full' : ''}} [defaultShow]="true" -->
    <div *ngIf="isDashboardVisible" id="dashboard-outlet" [@dashboard]="dashboard" style="overflow-y:auto; height:100%;" cdkDropList cdkDropListSortingDisabled>
      <router-outlet name="right"></router-outlet>
      <!-- [ngClass]="fill" -->
    </div>
    <ul *ngIf="isDashboardControlsVisible" id="dashboard-menu" [@dashboardMenu]="dashboardMenu" (mouseenter)="hoverDashboardMenuStart()" (mouseleave)="hoverDashboardMenuEnd()" >
      <li class="rightMenuImg menu" id="dashboard-carList" container="body" placement="left" [ngbTooltip]="tipContent1">
        <a (click)="changeDashboardMenuSection(); setDefaultTitle(); routeRightMenu('cars')" routerLinkActive="active"></a>
        <ng-template #tipContent1><div class="text-nowrap" i18n>Auta - aktuální přehled</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-carLoc" container="body" placement="left" [ngbTooltip]="tipContent2">
        <a target="_blank" [routerLink]="{outlets: {left:'all-in-one-map'}}" routerLinkActive="active" [queryParams]="{ reloadPossible: false }"></a>
        <ng-template #tipContent2><div class="text-nowrap" i18n>Mapa - všechna auta</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-dispBoard" container="body" placement="left" [ngbTooltip]="tipContent3">
        <a target="_blank" [routerLink]="{outlets: {left:'dispatcher-board'}}" routerLinkActive="active" [queryParams]="{ reloadPossible: false }"></a>
        <ng-template #tipContent3><div class="text-nowrap" i18n>Plachta dispečera</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-diary" container="body" placement="left" [ngbTooltip]="tipContent4">
        <a (click)="changeDashboardMenuSection(); setDefaultTitle(); routeRightMenu('itinerary-dairy')" routerLinkActive="active"></a>
        <ng-template #tipContent4><div class="text-nowrap" i18n>Diář - seznam dnešních nakládek a vykládek</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-obligation-diary" container="body" placement="left" [ngbTooltip]="tipContent5">
        <a (click)="changeDashboardMenuSection(); setDefaultTitle(); routeRightMenu('it-obligation-diary')" routerLinkActive="active"></a>
        <ng-template #tipContent5><div class="text-nowrap" i18n>Plánovač speditéra</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-msgRead" container="body" placement="left" [ngbTooltip]="tipContent6">
        <a target="_blank" [routerLink]="{outlets: {left:'messages-read'}}" routerLinkActive="active" [queryParams]="{ reloadPossible: false }"></a>
        <ng-template #tipContent6><div class="text-nowrap" i18n>Všechny zpávy</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-msgNew" container="body" placement="left" [ngbTooltip]="tipContent7">
        <a (click)="changeDashboardMenuSection(); setDefaultTitle(); routeRightMenu('messages-new')" routerLinkActive="active"></a>
        <span *ngIf="newMessagesCount > 0" class="sub-icon-text"
        (click)="changeDashboardMenuSection(); setDefaultTitle(); routeRightMenu('messages-new')">
          {{newMessagesCount}}
        </span>
        <ng-template #tipContent7><div class="text-nowrap" i18n>Nepřečtené zprávy</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-settings" container="body" placement="left" [ngbTooltip]="tipContent8">
        <a (click)="changeDashboardMenuSection(); setDefaultTitle(); routeRightMenu('user-settings')" routerLinkActive="active"></a>
        <ng-template #tipContent8><div class="text-nowrap" i18n>Nastavení pravého pruhu</div></ng-template>
      </li>

      <li class="rightMenuImg menu" id="dashboard-language">
        <!-- <a (click)="changeDashboardMenuSection(); setDefaultTitle(); routeRightMenu('locale-settings')"> -->
        <a href="javascript:void(0)">{{locale.name}}</a>
        <ul *ngIf="LOCALES.length > 1" class="transitions" #container [style.width]="LOCALES.length*50+'px'" [style.left]="((LOCALES.length*50)*(-1))+'px'">
          <li *ngFor="let obj of LOCALES">
            <a class="pointer" (click)="setLocale(obj.locale)">
              {{obj.name}}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<login-dialog (closed)="loginDialogClosed($event)" [open]="login_modal_visible"></login-dialog>

<ng-container *ngIf="messagesNewDialogIsLive">
  <messages-new-dialog></messages-new-dialog>
</ng-container>


<!-- Modal for sending email to support -->
<div class="modal fade" role="dialog" tabindex="-1" id="authModal" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title" i18n>Přihlášení vypršelo</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <h6 class="col-md-12">
            <ng-container i18n>Prosím odhlašte se a přihlašte znovu.</ng-container>
          </h6>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="logout()" data-dismiss="modal">
          <ng-container i18n>Odhlásit</ng-container>
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          <ng-container i18n>Zavřít</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>