import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';

declare var google: any;

@Component({
  selector: 'div.googleMapFavourites',
  templateUrl: './google-map-favourites.component.html',
  styleUrls: ['./google-map-favourites.component.css']
})
export class GoogleMapFavouritesComponent implements OnInit, OnDestroy {
  
  // usage in component - ta4-code-often-used-pos

  private _map: any;
  private _initMarker: GoogleMapMarker = null;
  private _initialized: boolean = false;
  private _centeredSwitch: boolean = false;
  private _bounds: any;

  private _key: number;
  get key(): number {
    return this._key;
  }
  
  private _marker: GoogleMapMarker = null;
  @Input()
  set marker(marker: GoogleMapMarker) {
    let same: boolean = marker === this._initMarker;
    this._initMarker = marker;

    if (!same && this._initialized) {
      this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      this._firstBuildCenterMarker = false;
      this.rebuildData(false);
    }
  }
  
  private _properties = {};
  @Input()
  set properties(properties: any) {
    this._properties = properties;
  }
  
  private _centerOnMarkers: boolean = false;
  @Input()
  set centerOnMarkers(value: boolean) {
    this._centerOnMarkers = value;
  }

  private _displayFocusOnMarker: boolean = false;
  @Input()
  set displayFocusOnMarker(value: boolean) {
    this._displayFocusOnMarker = value;
  }
  get displayFocusOnMarker(): boolean {
    return this._displayFocusOnMarker;
  }

  // first opening map invokes max centering of given marker on hybrid map
  private _firstBuildCenterMarker: boolean = false;

  constructor(
    private _elRef: ElementRef,
    private _externalApiRequestServ: ExternalApiRequestService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
  }

  ngOnInit(): void {
    this.buildData();
    this._initialized = true;

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-favourites';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }
  
  ngOnDestroy() {
    this.clearData(true);
  }

  private clearData(dropMap: boolean) {
    if (this._marker) {
      this._marker.clearEvents();
      this._marker.map = null;
    }
    if (this._initMarker) {
      this._initMarker.clearEvents();
      this._initMarker.map = null;
    }
    this._marker = this._initMarker;
    if (dropMap) {
      this._map = null;
    }
  }

  
  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private _rebuildTimeout: number = null;
  private _building: boolean = false;
  
  private rebuildData(dropMap: boolean) {
    if (!this._initialized) {
      return;
    }
    if (!this._building && this._rebuildTimeout === null) {
      this._rebuildTimeout = window.setTimeout(
        () => {
          this.clearData(dropMap);
          this.buildData();
          this._rebuildTimeout = null;
        },
        1000
      )
    }
  }

  private buildData() {
    this._building = true;
    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));
    }
    this._marker = this._initMarker;

    // center to marker
    if (!this._firstBuildCenterMarker) {
      this._firstBuildCenterMarker = true;
      this._centeredSwitch = false;
      this.centerToFirstMarker();
    }

    if (this._marker) {
      this.setMarker(this._marker);
      this._bounds = new google.maps.LatLngBounds();
      this._bounds.extend(this._marker.position);
    }

    this._building = false;
  }
  

  /*************************************************************/
  // Markers managing
  /*************************************************************/
  private setMarker(marker: GoogleMapMarker): GoogleMapMarker {
    marker.map = this._map;
    marker.addListener('click', () => {
      if (this._centerOnMarkers) {
        this.centerToMarker(marker);
      }
    });
    return marker;
  }


  /*************************************************************/
  // Centering stuff
  /*************************************************************/
  centerToMarker(marker: GoogleMapMarker) {
    this.centerToPosition(marker.position);
  }

  centerToFirstMarker() {
    if (this._initMarker) {
      this.centerToPosition(this._initMarker.position);
    }
  }

  private centerToPosition(position: any) {
    if (!this._centeredSwitch) {
      let bounds: any = new google.maps.LatLngBounds();
      bounds.extend(position);
      this._map.fitBounds(bounds);
      this._map.setZoom(18);
      this._map.setCenter(position);
      this._map.setMapTypeId(google.maps.MapTypeId.HYBRID);

      // used as switch now
      this._centeredSwitch = true;
    } 
    else {
      this.rebuildData(false);
      this._map.setZoom(10);
      this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      this._centeredSwitch = false;
    }
  }
}
