<div *ngIf="agenda" class="container pt-1 border-top text-left">
  <div class="row">
    <div class="col-12">
      <a class="font-weight-bold" href="javascript:void(0)" 
      (click)="openObligation(agenda.obligation_key)">
        {{agenda.order_number_standard}}
      </a> - 
      {{agenda.statusToString}}, 
      <span *ngIf="!getPersonNameByUsername(agenda.updated_by)">Global</span>
      <span *ngIf="getPersonNameByUsername(agenda.updated_by)">
        {{getPersonNameByUsername(agenda.updated_by)}}</span>,
      {{agenda.created_time_date | date: 'dd.MM.yyyy HH:mm'}}
    </div>

    <div *ngIf="agenda.order_comp_book" class="col-12 font-weight-bold">
      {{agenda.order_comp_book.company}} ({{agenda.order_comp_book.country}})
    </div>
    
    <div class="col-12 mb-2">
      {{agenda.payer_contact}},
      <a *ngIf="agenda.payer_phone" href="tel:{{agenda.clean_payer_phone}}">{{agenda.payer_phone}}</a>,
      <a *ngIf="agenda.payer_email" href="mailto:{{agenda.payer_email}}">{{agenda.payer_email}}</a>
    </div>

    <div class="col-12"><ng-container i18n>Zakázka</ng-container>:</div>
    <div *ngFor="let it of agenda.itinerary; let i=index" class="col-12 mb-1">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          {{i+1}})
          <img [src]="it.typeIcon()" class="mx-1" alt="icon" height="16px"/>
          <span *ngIf="it.arrival_time">{{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}</span>
          <img *ngIf="it.completed" class="ml-2" ngbTooltip="Dokončena" placement="auto"
          src="assets/icons-new/check-solid.svg" height="16px"/>
        </div>

        <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING || 
          it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTOMATIC">
          {{it.weight ? it.weight : 0}}t,
          {{it.ware_pcs ? it.ware_pcs : 0}}
          {{it.ware_pcs ? it.ware_type : ''}}
        </div>
      </div>
      
      <div>
        {{it.address}}
      </div>
    </div>

    <!-- Show also  -->
    <ng-container *ngIf="agenda.express_route">
      <div class="col-12 mt-3"><ng-container i18n>Trasa</ng-container> (LTL):</div>
      <div *ngFor="let it of agenda.express_route.itineraryOrdered; let i=index" class="col-12 mb-1">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            {{i+1}})
            <img [src]="it.typeIcon()" class="mx-1" alt="icon" height="16px"/>
            <span *ngIf="it.arrival_time">{{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}</span>
            <img *ngIf="it.completed" class="ml-2" ngbTooltip="Dokončena" placement="auto"
            src="assets/icons-new/check-solid.svg" height="16px" i18n-ngbTooltip/>
          </div>
  
          <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING || 
            it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTOMATIC">
            {{it.weight ? it.weight : 0}}t,
            {{it.ware_pcs ? it.ware_pcs : 0}}
            {{it.ware_pcs ? it.ware_type : ''}}
          </div>
        </div>
        
        <div>
          {{it.address}}
        </div>
      </div>
    </ng-container>
  </div>
</div>