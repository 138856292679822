import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { AuthenticationService } from './authentication.service';
import { ExternalApiRequest } from '../model/external-api-request.object';
import { IS_DEMO, ServiceConfiguration } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ExternalApiRequestService {

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService
  ) { }

  // GET /internal/logs/external-api-request/
  getRequestLogs(): Observable<Array<any>> {
    let result: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.internal.apiRequestsLogs;
      this._http.get(url).subscribe(
        response => {
          console.log(response);
          result.next(response);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // POST /internal/logs/external-api-request/
  createRequestLog(request: ExternalApiRequest): void {
    // let result: BehaviorSubject<any> = new BehaviorSubject(null);
    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = request.apiObject;
      if (this._authService.user) {
        data.company_key = this._authService.user.company_key;
      }
      let url: string = ServiceConfiguration.internal.apiRequestsLogs;

      this._http.post(url, data).subscribe(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
        }
      );
    }
  }
}
