<div *ngIf="loading" class="dashboard-body text-center py-3">
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
    <span class="sr-only"></span>
    </div>
  </div>
  </div>

<div *ngIf="!loading">
  <div *ngIf="!skenButton && !cmrButton && !loadButton  && !gpsButton && !allGpsButton" 
  class="container border rounded border-secondary" style="background-color: black; min-height: 800px;">
    <h4 class="my-2" style="color: rgb(255,255,255);" i18n>Diář řidiče</h4>
    <div>
      <ul class="nav nav-tabs" role="tablist" style="background-color: black; color: blanchedalmond;">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" role="tab" data-toggle="tab" href="#tab-1">
            {{vehicle.number_plate}}
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" role="tab" data-toggle="tab" href="#tab-2" (click)="driverclick(vehicle.driver_key)">
            {{vehicle.driver_name}}
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" role="tab" data-toggle="tab" href="#tab-3" (click)="driverclick(vehicle.driver2_key)">
            {{vehicle.driver_name}}
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" role="tabpanel" id="tab-1">
          <p class="text-white mb-0" i18n>Poslední změna: 27.3.2021, 16:51</p>
          <button class="btn btn-primary my-2 mr-3" type="button" style="width: 100%;" (click)="allGpsClick()">Trasa</button>
          
          <div *ngFor="let itinerari of itineraries">
          
            <div>
              <div *ngIf="itinerari._completed" style="opacity: 0.5;">
              <div>
                <div class="d-inline-flex" >
                  <div *ngIf="itinerari.type == ItineraryType.LOADING">
                    <img class="mt-3 mr-3" src="assets/icons-new/loading-outline-white.svg" style="width: 36px;"/>
                  </div>
                  <div *ngIf="itinerari.type == ItineraryType.UNLOADING">
                    <img class="mt-3 mr-3" src="assets/icons-new/unloading-outline-white.svg" style="width: 36px;"/>
                  </div>
                  <div>
                    <p class="text-white  mb-0" style="font-size:140%;">
                      <strong>{{getType(itinerari.type)}} {{itinerari.arrival_time | date: 'dd.MM.yyyy HH:mm'}}</strong>
                    </p>
                    <p class="text-white mb-0" style="font-size:140%;">
                      <ng-container i18n>Lze od</ng-container>&nbsp;{{getTimeInPrintableFormat(itinerari.work_day_begin)}}&nbsp; 
                      <ng-container i18n>do</ng-container>&nbsp;{{getTimeInPrintableFormat(itinerari.work_day_end)}}
                    </p>
                    <p class="text-white mb-0" style="font-size:140%;">{{itinerari.order_number}}</p>
                  </div>
                </div>
              </div>
              <div class="bg-dark border rounded border-white px-2 mt-2">
                <p class="text-white mb-0 font-size:400px" style="font-size:140%;">
                  {{itinerari.agenda.payer_comp_book.company}} {{itinerari.address}}
                </p>
                <p class="text-white mb-0" style="font-size:140%;">
                  <ng-container i18n>CODE</ng-container>: {{itinerari.agenda.code}}
                </p>
                <p class="text-white mb-0" style="font-size:140%;">
                  {{itinerari.agenda.weight}}t Pozn: {{itinerari.note}}
                </p>
              </div>
              
              
              <button class="btn btn-primary my-2 mr-3" type="button" style="width: 20%;" (click)="gpsClick(itinerari,true)" >
                <ng-container i18n>Naviguj</ng-container>
              </button>
              <button *ngIf="itinerari.type==ItineraryType.LOADING" class="btn btn-success my-2 mr-3" type="button" style="width: 20%;" (click)="loadClick(itinerari)">
                <ng-container i18n>Nalož</ng-container>
              </button>
              <button *ngIf="itinerari.type==ItineraryType.UNLOADING" class="btn btn-warning my-2 mr-3" type="button" style="width: 20%;" (click)="loadClick(itinerari)">
                <ng-container i18n>Vylož</ng-container>
              </button>
              <button class="btn btn-secondary my-2 mr-3" type="button" style="width: 20%;" (click)="skenClick(20000)">
                <ng-container i18n>Skenuj</ng-container>
              </button>
              <button class="btn btn-danger my-2 mr-3" type="button" style="width: 20%;"  (click)="cmrClick(25000)">
                CMR
              </button> 
              </div>
              <div *ngIf="!itinerari._completed">
                <div>
                  <div class="d-inline-flex" >
                    <div *ngIf="itinerari.type == ItineraryType.LOADING">
                      <img class="mt-3 mr-3" src="assets/icons-new/loading-outline-white.svg" style="width: 36px;"/>
                    </div>
                    <div *ngIf="itinerari.type == ItineraryType.UNLOADING">
                      <img class="mt-3 mr-3" src="assets/icons-new/unloading-outline-white.svg" style="width: 36px;"/>
                    </div>
                    <div>
                      <p class="text-white mb-0" style="font-size:140%;">
                        <strong>{{getType(itinerari.type)}} {{itinerari.arrival_time | date: 'dd.MM.yyyy HH:mm'}}</strong>
                      </p>
                      <p class="text-white mb-0" style="font-size:140%;">
                        <ng-container i18n>Lze od</ng-container>&nbsp;{{getTimeInPrintableFormat(itinerari.work_day_begin)}} 
                        <ng-container i18n>do</ng-container>&nbsp;{{getTimeInPrintableFormat(itinerari.work_day_end)}}
                      </p>
                      <p class="text-white mb-0" style="font-size:140%;">{{itinerari.order_number}}</p>
                    </div>
                  </div>
                </div>
                <div class="bg-dark border rounded border-white px-2 mt-2">
                  <p class="text-white mb-0 font-size:400px" style="font-size:140%;">
                    {{itinerari.agenda.payer_comp_book.company}}  {{itinerari.address}}
                  </p>
                  <p class="text-white mb-0" style="font-size:140%;">
                    <ng-container i18n>CODE</ng-container>: {{itinerari.agenda.code}}
                  </p>
                  <p class="text-white mb-0" style="font-size:140%;">
                    {{itinerari.agenda.weight}}t Pozn: {{itinerari.note}}
                  </p>
                </div>
                
                
                <button class="btn btn-primary my-2 mr-3" type="button" style="width: 20%;" 
                (click)="gpsClick(itinerari,true)">
                  <ng-container i18n>Naviguj</ng-container>
                </button>
                <button *ngIf="itinerari.type == ItineraryType.LOADING" class="btn btn-success my-2 mr-3" type="button" style="width: 20%;" (click)="loadClick(itinerari)">
                  <ng-container i18n>Nalož</ng-container>
                </button>
                <button *ngIf="itinerari.type == ItineraryType.UNLOADING" class="btn btn-warning my-2 mr-3" type="button" style="width: 20%;" (click)="loadClick(itinerari)">
                  <ng-container i18n>Vylož</ng-container>
                </button>
                <button class="btn btn-secondary my-2 mr-3" type="button" style="width: 20%;" (click)="skenClick(20000)">
                  <ng-container i18n>Skenuj</ng-container>
                </button>
                <button class="btn btn-danger my-2 mr-3" type="button" style="width: 20%;"  (click)="cmrClick(25000)">CMR</button> 
              </div>
            </div>
          </div>
        </div>
        
        <div class="tab-pane" role="tabpanel" id="tab-2">
          <div class="container border rounded border-secondary" style="height: 834px;">
            <h4 class="my-2" style="color: rgb(255,255,255);">Diář řidiče</h4>
            <div>
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="tab-2">
                  <p class="text-white mb-0">
                    <ng-container i18n>Poslední změna</ng-container>: 27.3.2021, 16:51
                  </p>
                  <div *ngIf="selectedDriver">
                    <div *ngIf="selectedDriver.driver_documents.length">
                      <div *ngFor="let file of selectedDriver.driver_documents">
                        <a (click)="downloadAttachment(file)" href="javascript:void(0)">
                        <div class="d-flex">
                          <img class="mr-2" src="assets/icons-new/document_white.svg" height="52"/>
                          <div style="width: 100%;">
                            <h5 class="text-white">{{file.name}}</h5>
                            <div class="w-100"></div>
                            <span class="text-white">{{file.modifiedDate | date:'dd.MM.yyyy HH:mm'}}</span><span class="text-white float-right">{{file.sizeKB}}KB</span>
                          </div>
                        </div>
                        </a>
                        <hr class="my-1" style="background: var(--white);" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" role="tabpanel" id="tab-3">
          <p>Content for tab 3.</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="gpsButton">
    <!-- Mapa -->
    <r-diary-mobile-map 
      [itinerary] = "selectedItinerary"
      (hideMap) = "hideMapEvent($event)" >
    </r-diary-mobile-map>
  </div>

  <div *ngIf="allGpsButton">
    <!-- Mapa -->
    <div class="google-map-wrapper mx-1 my-2" style="position: relative; height: 500px;">
      <!-- [style.height.px]="500" -->
      <div class="googleMapObligation fill"
        [properties]="{ scrollwheel: true, zoom: 7 }" 
        [buildMapOnOpen]="true"
        [buildOnMarkersInit]="true"
        [markers]="markers"
        [rebuildMap]="rebuildMapSubject">
      </div>
    </div>
    <div class="text-center my-2">
      <button class="btn btn-warning mx-2" (click)="allGpsButton = false">
        <ng-container i18n>ZPĚT</ng-container>
      </button>
    </div>
  </div>


  <div *ngIf="skenButton">
    <img id="sken" src="assets/images/skenovani.gif" class="w-100"> 
  </div>

  <div *ngIf="cmrButton">
    <img src="assets/images/cmr_print.gif" class="w-100">
  </div>

  <div *ngIf="loadButton">
    <div class="container border rounded border-secondary" style="background-color: black;">
      <h4 class="my-2" style="color: rgb(255, 255, 255);" i18n>Nakládka</h4>
      <div class="table-responsive table-borderless mb-1" style="border-style: none; background-color:black ;">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="text-left text-white">
                <ng-container i18n>Číslo zakázky</ng-container>:
              </td>
              <input [(ngModel)]="this.loadOrderNumber" style="background-color: black; color: floralwhite;">
            </tr>
            <tr>
              <td class="text-white">
                <ng-container i18n>Hmotnost</ng-container>:
              </td>
              <input [(ngModel)]="this.loadWeight" style="background-color: black; color: floralwhite;">
            </tr>
            <tr>
              <td class="text-white">
                <ng-container i18n>Ložná délka</ng-container>:
              </td>
              <input style="background-color: black; color: floralwhite;">
            </tr>
            <tr>
              <td class="text-center text-white-50" colspan="2">
                <strong i18n>VRATNÉ OBALY / PALETY</strong>
              </td>
            </tr>
            <tr>
              <td class="text-white">
                <ng-container i18n>Odevzdal prázdné</ng-container>:
              </td>
              <input style="background-color: black; color: floralwhite;">
            </tr>
            <tr>
              <td class="text-white">
                <ng-container i18n>Naložil s nákladem</ng-container>:
              </td>
              <input style="background-color: black; color: floralwhite;"> 
            </tr>
            <tr>
              <td class="text-center text-white-50" colspan="2">
                <strong i18n>POZNÁMKA</strong>
              </td>
            </tr>
            <tr>
              <td>
                <input style="background-color: black; color: floralwhite; width: 100px;" 
                placeholder="Až 100 znaků pro další informace" i18n-placeholder> 
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button (click)="loadUnloadExit(false)" class="btn btn-secondary my-5 px-4" type="button">
          <ng-container i18n>ZPĚT</ng-container>
        </button>

        <button (click)="loadUnloadExit(true);" class="btn btn-secondary m-5 px-4" type="button">
          <ng-container i18n>NALOŽ</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
