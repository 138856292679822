import { Numbers } from "../tools/Numbers";
import { Invoice } from "./invoice.object";

export class ServiceTask {
  constructor() {
  }

  private _TODAY: Date = new Date();

  private _task_key: number;
  public get task_key(): number {
    return this._task_key;
  }
  public set task_key(value: number) {
    this._task_key = value;
  }
  
  private _car_key: number;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }

  private _trailer_key: number;
  public get trailer_key(): number {
    return this._trailer_key;
  }
  public set trailer_key(value: number) {
    this._trailer_key = value;
  }

  private _amortization_interval: number;
  public get amortization_interval(): number {
    return this._amortization_interval;
  }
  public set amortization_interval(value: number) {
    this._amortization_interval = value;
  }

  private _amortization_unit: string;
  public get amortization_unit(): string {
    return this._amortization_unit;
  }
  public set amortization_unit(value: string) {
    this._amortization_unit = value;
    if (this._amortization_unit == 'D') {
      this._amortizationUnitFormatted = 'dní';
    }
    else if (this._amortization_unit == 'K') {
      this._amortizationUnitFormatted = 'km';
    }
  }

  // custom atributte
  private _amortizationUnitFormatted: string = '';
  public get amortizationUnitFormatted(): string {
    return this._amortizationUnitFormatted;
  }
  public set amortizationUnitFormatted(value: string) {
    this._amortizationUnitFormatted = value;
  }

  private _code: string;
  public get code(): string {
    return this._code;
  }
  public set code(value: string) {
    this._code = value;
  }

  private _currency: string;
  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
  }
  
  // prefered date is completedDate (second is dueDate)
  private _date: Date = null;
  public get date(): Date {
    return this._date;
  }

  // datum uskutecneni udrzby
  private _completed_date: string;
  public get completed_date(): string {
    return this._completed_date;
  }
  public set completed_date(value: string) {
    this._completed_date = value;
    if (value) {
      this._completedDate = new Date(value);
      this._date = this._completedDate;
      this._completedYear = this._completedDate.getFullYear();
      
      // init days before
      let diffTime: number = this._TODAY.getTime() - this._completedDate.getTime();
      let diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this._completedBeforeDays = diffDays;
    }
  }

  private _completedDate: Date;
  public get completedDate(): Date {
    return this._completedDate;
  }

  private _completedYear: number;
  public get completedYear(): number {
    return this._completedYear;
  }

  private _completedBeforeDays: number = 0;
  public get completedBeforeDays(): number {
    return this._completedBeforeDays;
  }

  // tachometr pri provedeni udrzby
  private _completed_km: number;
  public get completed_km(): number {
    return this._completed_km;
  }
  public set completed_km(value: number) {
    this._completed_km = value;
  }
  
  private _completedBeforeKm: number = 0;
  public get completedBeforeKm(): number {
    return this._completedBeforeKm;
  }

  // planovany datum pro provedeni udrzby
  private _due_date: string;
  public get due_date(): string {
    return this._due_date;
  }
  public set due_date(value: string) {
    this._due_date = value;
    if (value) {
      this._dueDate = new Date(value);
      if (!this.completedDate) {
        this._date = this._dueDate;
      }
      // init days remaining
      let diffTime: number = this._TODAY.getTime() - this._dueDate.getTime();
      let diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this._dueRemainingDays = diffDays;
    }
  }

  private _dueDate: Date;
  public get dueDate(): Date {
    return this._dueDate;
  }
  
  private _dueRemainingDays: number = 0;
  public get dueRemainingDays(): number {
    return this._dueRemainingDays;
  }

  // odometer value planned
  private _due_km: number = 0;
  public get due_km(): number {
    return this._due_km;
  }
  public set due_km(value: number) {
    this._due_km = value;
  }

  // vehicle icon
  private _make_icon: string = '';
  public get make_icon(): string {
    return this._make_icon;
  }
  public set make_icon(value: string) {
    this._make_icon = value;
  }

  // vehicle number_plate
  private _number_plate: string = '';
  public get number_plate(): string {
    return this._number_plate;
  }
  public set number_plate(value: string) {
    this._number_plate = value;
  }


  // custom for remaining days/km for planned tasks
  private _vehicleOdometer: number = 0;
  public get vehicleOdometer(): number {
    return this._vehicleOdometer;
  }
  public set vehicleOdometer(value: number) {
    this._vehicleOdometer = value;
    // km computing
    if (this._vehicleOdometer) {
      if (this._due_km) {
        this._dueRemainingKm = this._vehicleOdometer - this._due_km;
      }
      if (this._completed_km) {
        this._completedBeforeKm = this._vehicleOdometer - this._completed_km;
      }
    }
    this.computeColorClass();
    this.computeDepriciated();
  }
  
  private _dueRemainingKm: number = 0;
  public get dueRemainingKm(): number {
    return this._dueRemainingKm;
  }

  private _insurance_benefits: number;
  public get insurance_benefits(): number {
    return this._insurance_benefits;
  }
  public set insurance_benefits(value: number) {
    this._insurance_benefits = value;
  }

  private _price: number;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
  }

  // price exchanged to home currency
  private _price_exchanged: number;
  public get price_exchanged(): number {
    return this._price_exchanged;
  }
  public set price_exchanged(value: number) {
    this._price_exchanged = value;
  }

  // api attribute, but now is current computing preferred
  private _price_depriciated: number;
  public get price_depriciated(): number {
    return this._price_depriciated;
  }
  public set price_depriciated(value: number) {
    this._price_depriciated = value;
  }

  // custom
  private _current_depriciated: number = 0;
  public get current_depriciated(): number {
    return this._current_depriciated;
  }

  // custom
  private _current_depriciated_percent: number = 0;
  public get current_depriciated_percent(): number {
    return this._current_depriciated_percent;
  }
  
  get current_depriciated_formatted(): string {
    return (
      (this._current_depriciated ? Numbers.toOurPriceFormat(this._current_depriciated) : 0) 
      + ' ' + this.currency + ' (' + this.current_depriciated_percent + '%)'
    );
  }

  computeDepriciated(): void {
    if (this.amortization_interval) {
      // days
      if (this.amortization_unit == 'D') {
        if (this.completedBeforeDays < this.amortization_interval) {
          this._current_depriciated_percent = this.completedBeforeDays / this._amortization_interval;
          this._current_depriciated = this._current_depriciated_percent * this._price;
          // to percent
          this._current_depriciated_percent = this._current_depriciated_percent * 100; 
        }
        else if ((this.completed_date)) {
          this._current_depriciated_percent = 100;
          this._current_depriciated = this._price;
        }
      }
      // kilometres
      else if (this.amortization_unit == 'K') {
        if (this.vehicleOdometer) {
          if (this.completedBeforeKm < this.amortization_interval) {
            this._current_depriciated_percent = this.completedBeforeKm / this.amortization_interval;
            this._current_depriciated = this._current_depriciated_percent * this._price;
            // to percent
            this._current_depriciated_percent = this._current_depriciated_percent * 100; 
          }
          else if (this.completed_km) {
            this._current_depriciated_percent = 100;
            this._current_depriciated = this._price;
          }
        }
      }
    }
    // round percent 2 decimals
    this._current_depriciated_percent = Math.round(this._current_depriciated_percent * 100) / 100;
  }
  
  private _note: string;
  public get note(): string {
    return this._note;
  }
  public set note(value: string) {
    this._note = value;
  }

  private _reminder1: number;
  public get reminder1(): number {
    return this._reminder1;
  }
  public set reminder1(value: number) {
    this._reminder1 = value;
  }

  private _reminder2: number;
  public get reminder2(): number {
    return this._reminder2;
  }
  public set reminder2(value: number) {
    this._reminder2 = value;
  }
  
  private _reminder1_sent: string;
  public get reminder1_sent(): string {
    return this._reminder1_sent;
  }
  public set reminder1_sent(value: string) {
    this._reminder1_sent = value;
  }

  private _reminder2_sent: string;
  public get reminder2_sent(): string {
    return this._reminder2_sent;
  }
  public set reminder2_sent(value: string) {
    this._reminder2_sent = value;
  }

  // 'A' ~ planovana
  // 'B' ~ neplanovana
  // 'C' ~ nadstandard
  // 'D' ~ poplatky
  static TYPE_PLANNED: string = 'A';
  static TYPE_NOTPLANNED: string = 'B';
  static TYPE_NADSTANDARD: string = 'C';
  static TYPE_PAYMENTS: string = 'D';

  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  public get isPlanned(): boolean {
    return this._type == 'A';
  }
  

  // A - Mýto
  // B - Baterie (nákup)
  // C - Ostatní náklady
  // D - Nákup náhradních dílů
  // G - Garanční prohlídka
  // H - Havárie (oprava)
  // I - Pojištění
  // M - Mytí
  // N - Nákup (pořízení vozidla)
  // O - Olej (výměna oleje a filtrů)
  // L - Certifikát "L"
  // P - Pneu hnací náprava
  // Q - Pneu vlečená náprava
  // R - Pneu 3
  // S - Termínovaná prohlídka
  // T - Technická prohlídka
  // V - Kalibrace tacho
  // W - Záloha tacho
  // X - Ostatní
  // Y - Nadstandard
  // Z - Záruka do
  private _task: string;
  public get task(): string {
    return this._task;
  }
  public set task(value: string) {
    this._task = value;
    this.initTaskColor();
  }

  // task categories getters
  static MYTO: string = 'A';
  static BATTERY: string = 'B';
  static PARKING: string = 'C';
  static DILY_NAKUP: string = 'D';
  static GARANCNI: string = 'G';
  static HAVARIE: string = 'H';
  static POJISTENI: string = 'I';
  static L_CERTIFIKAT: string = 'L';
  static MYTI: string = 'M';
  static NAKUP: string = 'N';
  static OIL: string = 'O';
  static PNEU1: string = 'P';
  static PNEU2: string = 'Q';
  static PNEU3: string = 'R';
  static TACHO_KALIBRACE: string = 'V';
  static TACHO_ZALOHA: string = 'W';
  static TERMINOVANA: string = 'S';
  static TECHNICKA: string = 'T';
  static OTHERS: string = 'X';
  static NADSTANDARD: string = 'Y';
  static ZARUKA: string = 'Z';

  // task names
  static MYTO_NAME: string = $localize`Mýto`;
  static BATTERY_NAME: string = $localize`Baterie (pořízení)`;
  static PARKING_NAME: string = $localize`Parkování`;
  static DILY_NAKUP_NAME: string = $localize`Nákup náhradních dílů`;
  static GARANCNI_NAME: string = $localize`Garanční prohlídka`;
  static HAVARIE_NAME: string = $localize`Oprava (havárie)`;
  static POJISTENI_NAME: string = $localize`Pojištění`;
  static L_CERTIFIKAT_NAME: string = $localize`Certifikát "L`;
  static MYTI_NAME: string = $localize`Mytí`;
  static NAKUP_NAME: string = $localize`Nákup/pořízení vozidla`;
  static OIL_NAME: string = $localize`Olej (výměna oleje a filtrů)`;
  static PNEU1_NAME: string = $localize`Pneu 1 - hnací náprava`;
  static PNEU2_NAME: string = $localize`Pneu 2 - vlečená náprava`;
  static PNEU3_NAME: string = $localize`Pneu 3`;
  static TACHO_KALIBRACE_NAME: string = $localize`Kalibrace tacho`;
  static TACHO_ZALOHA_NAME: string = $localize`Záloha tacho`;
  static TERMINOVANA_NAME: string = $localize`Termínovaná prohlídka`;
  static TECHNICKA_NAME: string = $localize`STK + Emise`;
  static OTHERS_NAME: string = $localize`Ostatní`;
  static NADSTANDARD_NAME: string = $localize`Nadstandard`;
  static ZARUKA_NAME: string = $localize`Záruka do`;

  // task color
  private _task_color: string;
  public get task_color(): string {
    return this._task_color;
  }
  initTaskColor(): void {
    switch (this._task) {
      case ServiceTask.HAVARIE:
        this._task_color = 'redBackgroundRow'; break;
      case ServiceTask.MYTI:
        this._task_color = 'blueBackgroundRow'; break;
      case ServiceTask.NAKUP:
        this._task_color = 'yellowBackgroundRow'; break;
      case ServiceTask.OIL:
        this._task_color = 'orangeBackgroundRow'; break;
      case ServiceTask.PNEU1:
        this._task_color = 'lightGrayBackgroundRow'; break;
      case ServiceTask.PNEU2:
        this._task_color = 'lightGrayBackgroundRow'; break;
      case ServiceTask.PNEU3:
        this._task_color = 'lightGrayBackgroundRow'; break;
      case ServiceTask.TACHO_KALIBRACE:
        this._task_color = 'greenBackgroundRow'; break;
      case ServiceTask.TECHNICKA:
        this._task_color = 'greenBackgroundRow'; break;
      case ServiceTask.NADSTANDARD:
        this._task_color = 'purpleBackgroundRow'; break;
      default:
        this._task_color = ''; break;
    }
  }

  get task_name(): string {
    switch (this._task) {
      case ServiceTask.MYTO:
        return ServiceTask.MYTO_NAME;
      case ServiceTask.BATTERY:
        return ServiceTask.BATTERY_NAME;
      case ServiceTask.PARKING:
        return ServiceTask.PARKING_NAME;
      case ServiceTask.DILY_NAKUP:
        return ServiceTask.DILY_NAKUP_NAME;
      case ServiceTask.GARANCNI:
        return ServiceTask.GARANCNI_NAME;
      case ServiceTask.HAVARIE:
        return ServiceTask.HAVARIE_NAME;
      case ServiceTask.POJISTENI:
        return ServiceTask.POJISTENI_NAME;
      case ServiceTask.L_CERTIFIKAT:
        return ServiceTask.L_CERTIFIKAT_NAME;
      case ServiceTask.MYTI:
        return ServiceTask.MYTI_NAME;
      case ServiceTask.NAKUP:
        return ServiceTask.NAKUP_NAME;
      case ServiceTask.OIL:
        return ServiceTask.OIL_NAME;
      case ServiceTask.PNEU1:
        return ServiceTask.PNEU1_NAME;
      case ServiceTask.PNEU2:
        return ServiceTask.PNEU2_NAME;
      case ServiceTask.PNEU3:
        return ServiceTask.PNEU3_NAME;
      case ServiceTask.TACHO_KALIBRACE:
        return ServiceTask.TACHO_KALIBRACE_NAME;
      case ServiceTask.TACHO_ZALOHA:
        return ServiceTask.TACHO_ZALOHA_NAME;
      case ServiceTask.TERMINOVANA:
        return ServiceTask.TERMINOVANA_NAME;
      case ServiceTask.TECHNICKA:
        return ServiceTask.TECHNICKA_NAME;
      case ServiceTask.OTHERS:
        return ServiceTask.OTHERS_NAME;
      case ServiceTask.NADSTANDARD:
        return ServiceTask.NADSTANDARD_NAME;
      case ServiceTask.ZARUKA:
        return ServiceTask.ZARUKA_NAME;
      default: 
        return '-';
    }
  }

  get isMytoTask(): boolean {
    return this.task == ServiceTask.MYTO;
  }

  get isBatteryTask(): boolean {
    return this.task == ServiceTask.BATTERY;
  }
  
  get isParkingTask(): boolean {
    return this.task == ServiceTask.PARKING;
  }
  
  get isDilyNakupTask(): boolean {
    return this.task == ServiceTask.DILY_NAKUP;
  }

  get isGarancniTask(): boolean {
    return this.task == ServiceTask.GARANCNI;
  }
  
  get isHavarieTask(): boolean {
    return this.task == ServiceTask.HAVARIE;
  }
  
  get isCertifikatTask(): boolean {
    return this.task == ServiceTask.L_CERTIFIKAT;
  }

  get isMytiTask(): boolean {
    return this.task == ServiceTask.MYTI;
  }
  
  get isNakupTask(): boolean {
    return this.task == ServiceTask.NAKUP;
  }
  
  get isOilTask(): boolean {
    return this.task == ServiceTask.OIL;
  }
  
  get isPneu1Task(): boolean {
    return this.task == ServiceTask.PNEU1;
  }
  
  get isPneu2Task(): boolean {
    return this.task == ServiceTask.PNEU2;
  }
  
  get isPneu3Task(): boolean {
    return this.task == ServiceTask.PNEU3;
  }

  get isTachoZaloha(): boolean {
    return this.task == ServiceTask.TACHO_ZALOHA;
  }

  get isTachoKalibrace(): boolean {
    return this.task == ServiceTask.TACHO_KALIBRACE;
  }

  get isTerminovanaTask(): boolean {
    return this.task == ServiceTask.TERMINOVANA;
  }

  get isTechnickaTask(): boolean {
    return this.task == ServiceTask.TECHNICKA;
  }

  get isOthersTask(): boolean {
    return this.task == ServiceTask.OTHERS || !this.task;
  }
  
  get isNadstandardTask(): boolean {
    return this.task == ServiceTask.NADSTANDARD;
  }
  
  get isZarukaTask(): boolean {
    return this.task == ServiceTask.ZARUKA;
  }
  

  private _colorClass: string = 'text-success'; 
  public get colorClass(): string {
    return this._colorClass;
  }
  computeColorClass(): void {
    if (this.isParkingTask || this.isCertifikatTask || this.isGarancniTask || this.isTerminovanaTask || this.isTechnickaTask || this.isTachoZaloha || this.isTachoKalibrace) {
      if (this.dueRemainingDays >= 0) 
        this._colorClass = 'text-danger';
      else if (this.dueRemainingDays > (-1)*this.reminder1) 
        this._colorClass = 'text-warning';
      else 
        this._colorClass = 'text-success';
    }
    else if (this.isPneu1Task || this.isPneu2Task || this.isPneu3Task || this.isBatteryTask) {
      if (this.vehicleOdometer && this.completedBeforeKm && this.amortization_interval) {
        if (this.completedBeforeKm > this.amortization_interval) 
          this._colorClass = 'text-warning';
        else
          this._colorClass = 'text-success';
      }
      else {
        this._colorClass = 'text-warning';
      }
    }
    else if (this.isOilTask) {
      if (this.vehicleOdometer) {
        if (this.dueRemainingKm >= 0) 
          this._colorClass = 'text-danger';
        else if (this.dueRemainingKm > (-1)*this.reminder1) 
          this._colorClass = 'text-warning';
        else 
          this._colorClass = 'text-success';
      }
      else {
        this._colorClass = 'text-warning';
      }
    }
  }

  // custom - specific gold tariff color
  private _isGoldTariff: boolean = false;
  public get isGoldTariff(): boolean {
    return this._isGoldTariff;
  }
  public set isGoldTariff(value: boolean) {
    this._isGoldTariff = value;
  }

  // custom - received invoices of service tasks
  private _invoices: Array<Invoice> = [];
  public get invoices(): Array<Invoice> {
    return this._invoices;
  }
  public set invoices(value: Array<Invoice>) {
    this._invoices = value;
  }
  

  get apiObject(): any {
    return {
      car_key: this.car_key,
      trailer_key: this.trailer_key,
      amortization_interval: this.amortization_interval,
      amortization_unit: this.amortization_unit,
      code: this.code,
      currency: this.currency,
      completed_date: this.completed_date && this.completed_date.length ? this.completed_date : null,
      completed_km: this.completed_km,
      due_date: this.due_date && this.due_date.length ? this.due_date : null,
      due_km: this.due_km,
      insurance_benefits: this.insurance_benefits,
      price: this.price,
      price_depriciated: this.price_depriciated,
      note: this.note,
      reminder1: this.reminder1,
      reminder2: this.reminder2,
      reminder1_sent: this.reminder1_sent,
      reminder2_sent: this.reminder2_sent,
      task: this.task,
      type: this.type
    }
  }
}