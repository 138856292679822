<div class="col-12 col-sm-12 col-md-12 dashboard-head messages-read">
  <i class="rightMenuImg" id="dashboard-msgRead"></i>
  <a class="float-left" (click)="reloadMessages()" 
  placement="bottom auto" container="body" ngbTooltip="Obnovit" i18n-ngbTooltip>
    {{lastLoadTime}}
  </a>
  <i id="dashboard-broadcast" class="rightMenuImg pointer" (click)="showBroadcast()"
    placement="bottom" container="body" ngbTooltip="Hromadná zpráva všem řidičům" i18n-ngbTooltip></i>

  <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only"></span>
  </div>
  <i *ngIf="!loading" id="dashboard-refresh" class="rightMenuImg pointer" (click)="reloadMessages()"
    placement="bottom" container="body" ngbTooltip="Obnovit" i18n-ngbTooltip></i>
</div>

<div id="dashboard-carList-div">
  <messages-day-grouped-list [reply]="true" [messages]="messages"></messages-day-grouped-list>
</div>
<ng-container *ngIf="broadcastVisible">
  <div class="broadcast-message-dialog" (close)="hideBroadcast()"></div>
</ng-container>
