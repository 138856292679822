<div class="board-body" style="width: 100%; overflow: auto;">
  <div *ngIf="loadingAgenda" class="text-center">
    <div class="spinner-wrapper py-4">
      <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
  
  <div class="dispatcher-board-wrapper" [style.width]="boardWidth" (click)="setSelectedAgenda($event, null)">
    <div *ngIf="!loadingAgenda" class="container-fluid">
      <div *ngIf="!vehicle">
        <h6 i18n>Nepodařilo se načíst vozidla</h6>
      </div>
    
      <div *ngIf="vehicle" class="row">
        <div class="col-12">
          <div class="row dispatcher-board-vehicle">
            <div class="col-12 no-padding">
              <ng-container *ngFor="let day of days">
                <div class="float-left" [style.width]="columnWidth">
                  <div class="container-fluid">
                    <div class="row vehicle-row-header" [class.bg-danger]="isWeekend(day)" [class.text-white]="isWeekend(day)">
                      <div class="col-6 text-left px-3 border-left border-dark">
                        {{getDateString(day)}} {{getDayString(day)}}
                      </div>
                      <div class="col-6 text-right px-3">
                        <span [class.bold]="('vehicle_list_highlight'|configuration:'vehicle') === 'drivers'">
                          {{vehicle.driver_name}}
                        </span> 
                        <span [class.bold]="('vehicle_list_highlight'|configuration:'vehicle') === 'plates'">
                          {{vehicle.number_plate}} + {{vehicle.trailer ? vehicle.trailer.number_plate : ''}}
                        </span>
                      </div>
                    </div>
                    <div class="row vehicle-row">
                      <div class="col-12 time-segments" [style.height.px]="vehicle.timeSegmentsHeight">
                        <div class="row">
                          <div class="col-3" *ngFor="let hour_group of hours">
                            <div class="row">
                              <div *ngFor="let hour of hour_group" class="col-2 no-padding">
                                <!-- hour text (00-23h) -->
                                <div class="hour-text">
                                  {{(hour > 9) ? hour : '0' + hour}}
                                </div>
                                <!-- lines for representanting hour/halfhour timeline -->
                                <div class="border-left hour-line" 
                                [class.border-dark]="hour == 0" 
                                [style.height.px]="vehicle.hourLineHeight">
                                  <div class="halfhour-line-offset">
                                  </div>
                                  <div class="border-right w-50 halfhour-line" 
                                  [style.height.px]="vehicle.halfhourLineHeight">
                                  </div>
                                </div>
                                <!-- show current time via blue line + vehicle status icon -->
                                <ng-container *ngIf="isThisHour(day,hour)">
                                  <div class="today-marker bg-primary"
                                  [style.height.px]="vehicle.todayMarker"
                                  [style.margin-top.px]="-vehicle.todayMarker">
                                  </div>
                                  <img class="vehStatusIcon" [src]="vehicle.truck_status_name"/>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- agendas -->
            <ng-container *ngIf="getDataByVehicle().length">
              <ng-container *ngFor="let dispAgenda of getDataByVehicle()">
                <ng-container *ngIf="dispAgenda.infoWidth > 0 && dispAgenda.infoOffset > -1">
                  <div class="dispatcher-board-agenda"
                  [class.selected]="dispAgenda===selectedAgenda"
                  [style.width.px]="dispAgenda.infoWidth"
                  [style.left.px]="dispAgenda.infoOffset"
                  [style.top.px]="dispAgenda.conflictOffsetTop" 
                  placement="bottom auto" container="body" [ngbPopover]="popoverContent"
                  (click)="setSelectedAgenda($event, dispAgenda)">
                    <!-- progress bar -->
                    <div [style.cursor]="dispAgenda===selectedAgenda && dispAgenda.enableMove ? 'move' : 'pointer'">
                      <!-- top part -->
                      <div class="d-flex justify-content-between px-1 border-bottom border-secondary radius {{dispAgenda.backgroundColorClass}}">
                        <div class="text-truncate">
                          {{dispAgenda.order_comp_book}}
                        </div>
                      </div>
                      <!-- bottom part -->
                      <div class="progress position-relative">
                        <div [class]="'clearfix progress-bar greenBackground' + dispAgenda.getDrivenDistanceCssClass()" 
                        [style.width]="dispAgenda.getDrivenDistancePercentage()+'%'">
                        </div>
                        <span *ngIf="dispAgenda.infoWidth > 180" class="centered-text text-left pl-1">
                          {{dispAgenda.start_location_shortcode}} ({{dispAgenda.agenda?.weight}}t)
                        </span>
                        <span  *ngIf="!dispAgenda.express_delivery" class="centered-text text-center">
                          {{dispAgenda.length_and_driven}}
                        </span>
                        <span *ngIf="dispAgenda.infoWidth > 180" class="centered-text text-right pr-1">
                          {{dispAgenda.finish_location_shortcode}}
                        </span>
                      </div>
                    </div>

                    <!-- bootstrap popover template -->
                    <ng-template #popoverContent>
                      <div class="rounded p-1" style="background: #cccccc">
                        <div *ngIf="dispAgenda.agenda">
                          <div>
                            <a class="text-nowrap" href="javascript:void(0)"
                            (click)="openObligationNewTab(dispAgenda.agenda.obligation_key)">
                              {{dispAgenda.agenda.order_number}}
                            </a>
                          </div>
                          <div *ngIf="dispAgenda.agenda.order_comp_book">
                            <b>
                              {{dispAgenda.agenda.order_comp_book.company}},
                              {{dispAgenda.agenda.order_comp_book.city}},
                              {{dispAgenda.agenda.order_comp_book.country}}
                            </b>
                          </div>
                          <div *ngIf="dispAgenda.agenda_itinerary?.length">
                            <div *ngFor="let it of dispAgenda.agenda_itinerary; let isLast=last;">
                              <div class="d-flex align-items-center mb-2"><div *ngIf="it.type != 'W' && it.type != 'V' && it.type != 'T'; else warehouseIcon" style="width: 30px">
                                <img [src]="it.typeIcon()" alt="icon" width="25px" height="25px"/>
                              </div>
                              <ng-template #warehouseIcon>
                                <div *ngIf="it.type == 'W' || it.type == 'V'" style="width: 30px">
                                  <img *ngIf="dispAgenda.shipmentFirstPart" src="assets/icons-new/collection.svg" 
                                  alt="icon" width="25px" height="25px"/>
                                  <img *ngIf="dispAgenda.shipmentSecondPart" src="assets/icons-new/delivery.svg" 
                                  alt="icon" width="25px" height="25px"/>
                                </div>
                                <div *ngIf="it.type == 'T'" style="width: 30px">
                                  <img *ngIf="dispAgenda.shipmentFirstPart" src="assets/icons-new/transship-unload.svg" 
                                  alt="icon" width="25px" height="25px"/>
                                  <img *ngIf="dispAgenda.shipmentSecondPart" src="assets/icons-new/transship-load.svg" 
                                  alt="icon" width="25px" height="25px"/>
                                </div>
                              </ng-template>
                                <div>
                                  <div class="d-flex">
                                    <div>{{it.arrival_time_formatted}}</div>
                                    <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING" class="ml-2">
                                      {{it.weight ? it.weight : 0}}t,
                                      {{it.ware_pcs ? it.ware_pcs : 0}}{{it.ware_pcs && it.ware_type ? it.ware_type : 'ks'}}
                                      (<ng-container i18n>Lze</ng-container>&nbsp;
                                      {{it.work_day_begin_formatted}}-{{it.work_day_end_formatted}})
                                    </div>
                                    <div>
                                      <img *ngIf="it.completed" class="ml-2" ngbTooltip="Dokončena" placement="auto"
                                      src="assets/icons-new/check-solid.svg" height="16px"/>
                                    </div>
                                  </div>
                                  <div class="text-truncate" style="max-width: 500px;">
                                    {{it.address}}
                                  </div>
                                  <div *ngIf="isLast">
                                    <ng-container i18n>Limit času na vykládku</ng-container>:
                                    {{it.loading_time_limit}}min
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <b><ng-container i18n>Trasa</ng-container>: {{dispAgenda.agenda.route_length}}km</b>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- time/distance difference -->
            <ng-container *ngIf="getDiffsByVehicle().length">
              <ng-container *ngFor="let diffs of getDiffsByVehicle()">
                <div class="dispatcher-board-agenda-diffs text-center"
                [style.left.px]="diffs.offsetLeft" 
                [style.top.px]="diffs.offsetTop">
                  <div *ngIf="diffs.offsetDiff && diffs.offsetDiff >= 50">
                    <!-- time hh:mm -->
                    <h6>{{diffs.timeDiff}}h</h6>
                    <!-- distance in km -->
                    <h6 *ngIf="diffs.distanceDiff || diffs.distanceDiff == 0">
                      ~{{diffs.distanceDiff}}km
                    </h6>
                    <h6 *ngIf="!diffs.distanceDiff && diffs.distanceDiff != 0">?km</h6>
                  </div>
                  
                  <div *ngIf="diffs.offsetDiff && diffs.offsetDiff > 5 && diffs.offsetDiff < 50">
                    <span class="badge badge-pill badge-dark pointer px-1" 
                    style="margin-left: 7px; margin-top: 12px"
                    [ngbPopover]="popoverDiff" container="body" placement="bottom auto">
                      h/km
                    </span>
                    <ng-template #popoverDiff>
                      <!-- time hh:mm - distance in km -->
                      <span>
                        {{diffs.timeDiff}}h&nbsp;~{{diffs.distanceDiff}}km
                      </span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
