import { AddressBookItem } from '../model/address-book-item.object';
import { Invoice } from '../model/invoice.object';
import { Obligation } from '../model/obligation.object';
import { Order } from '../model/order.object';
import { ObligationTools } from './ObligationTools';
import { InvoiceTools } from './InvoiceTools';
import { Person } from '../model/person.object';
import { Email } from '../model/email.object';
import { ItineraryType } from '../config';

// Tools for building order objects (used in more services)
export class OrderTools {
    
  // method for creating order objects from api objects
  static buildOrdersFromData(data: Array<any>): any {
    let orders: Array<Order> = [];
    data.forEach(
      o => {
        let order: Order = OrderTools.buildOrder(o);
        orders.push(order);
      }
    );
    return orders;
  }
  
  // method for creating a single invoice object
  static buildOrder(o: any): Order {
    let order: Order = new Order();

    // nested json objects/arrays
    if (o.invoices && o.invoices.length) {
      o.invoices = InvoiceTools.buildInvoicesFromData(o.invoices);
    }
    if (o.obligations && o.obligations.length) {
      o.obligations = ObligationTools.buildObligationsFromData(o.obligations);
    }
    // we prefer array here, not null
    if (!o.email_msg_ids) o.email_msg_ids = [];
    if (!o.email_headers) o.email_headers = [];
    if (o.email_headers && o.email_headers.length) {
      let headersArr = new Array<Email>();
      o.email_headers.forEach(
        (x, index) => {
          let header = new Email();
          for (let key in x) {
            header[key] = x[key];
          }
          // save also id of email
          if (o.email_msg_ids[index]) {
            header.email_msg_id = o.email_msg_ids[index];
          }
          headersArr.push(header);
        }
      );
      o.email_headers = headersArr;
    }
    if (o.order_comp_book) {
      let order_comp_book = new AddressBookItem();
      for (let key in o.order_comp_book) {
        order_comp_book[key] = o.order_comp_book[key];
      }
      o.order_comp_book = order_comp_book;

      if (o.order_comp_book.company && o.order_comp_book.city && o.order_comp_book.tin) {
        o.order_comp_name_long = o.order_comp_book.company + ', ' + o.order_comp_book.city + ', ' + o.order_comp_book.tin;
      } 
      else {
        o.order_comp_name_long = '';
      }
    }
    if (o.person) {
      let person: Person = new Person();
      for (let key in o.person) {
        person[key] = o.person[key];
      }
      o.person = person;
    }

    for (let key in o) {
      order[key] = o[key];
    }

    return order;
  }

  
  // method for setting background color according to status of order
  static setBackground(status: string): string {
    switch (status) {
      case 'A':
        return 'blueBackground';
      case 'B':
        return 'greenBackground';
      case 'C':
        return 'grayBackground';
      case 'L':
        return 'greenLightBackground';
      case 'U':
        return 'redBackground';
      default:
        return '';
    }
  }

  // method for setting background color of table row according to status of order(with alpha)
  static setBackgroundTableRow(status: string): string {
    switch (status) {
      case 'A':
        return 'blueBackgroundRow';
      case 'B':
        return 'greenBackgroundRow';
      case 'C':
        return 'grayBackgroundRow';
      case 'L':
        return 'greenLightBackgroundRow';
      case 'U':
        return 'redBackgroundRow';
      default:
        return '';
    }
  }

  static parseNotePrice(o: Order): void {
    if (!o.note_price || !o.note_price.length) {
      return;
    }
    // decimal comma to dot
    o.note_price = o.note_price.replace(/,/g, '.');

    // splitting by space
    let arr: Array<string> = o.note_price.split(' ');

    let tunaNaklString: string = arr.find(s => s.startsWith('#tuna-nakl='));
    let tunaVyklString: string = arr.find(s => s.startsWith('#tuna-vykl='));
    let tunaString: string = arr.find(s => s.startsWith('#tuna='));
    
    let tunaNaklNumber: number = null;
    let tunaVyklNumber: number = null;
    let tunaNumber: number = null;

    if (tunaNaklString || tunaVyklString || tunaString) {
      // reinit price
      o.contracted_price = 0;
      
      if (tunaNaklString) {
        tunaNaklString = tunaNaklString.replace('#tuna-nakl=', '');
        tunaNaklNumber = parseFloat(tunaNaklString);
        if (tunaNaklNumber) {
          let weight: number = 0;
          if (o.obligations) {
            o.obligations.forEach(
              ob => {
                if (ob.itinerary) {
                  ob.itinerary.forEach(
                    it => {
                      if (it.weight && it.type == ItineraryType.LOADING) {
                        weight += it.weight;
                      }
                    }
                  );
                }
              }
            );
          }
          o.contracted_price += weight * tunaNaklNumber;
        }
      }
      if (tunaVyklString) {
        tunaVyklString = tunaVyklString.replace('#tuna-vykl=', '');
        tunaVyklNumber = parseFloat(tunaVyklString);
        if (tunaVyklNumber) {
          let weight: number = 0;
          if (o.obligations) {
            o.obligations.forEach(
              ob => {
                if (ob.itinerary) {
                  ob.itinerary.forEach(
                    it => {
                      if (it.weight && it.type == ItineraryType.UNLOADING) {
                        weight += it.weight;
                      }
                    }
                  );
                }
              }
            );
          }
          o.contracted_price += weight * tunaVyklNumber;
        }
      }
      if (tunaString && !tunaNaklString && !tunaVyklString) {
        tunaString = tunaString.replace('#tuna=', '');
        tunaNumber = parseFloat(tunaString);
        if (tunaNumber) {
          let weight: number = 0;
          if (o.obligations) {
            o.obligations.forEach(
              ob => {
                if (ob.weight) {
                  weight += ob.weight;
                }
              }
            );
          }
          o.contracted_price += weight * tunaNumber;
        }
      }
    }
  }
}
