<div id="map_canvas_{{itinerary.agenda.vehicle.car_key}}"
  class="googleMapManualTracking position-relative"
  [style.height]="heightMap"
  [vehicle]="itinerary.agenda.vehicle"
  [markers]="itineraryMarker"
  [markerDestRoute]="markerDestRoute"
  [createDirectionDestRoute]="itinerary.manualTrackingMode" 
  (directionsChange)="directionsChange($event)">
  <!-- [type]="'map'" [centerOnMarkers]="true" -->
</div>
<div class="text-center my-2" >
  <button class="btn btn-success mx-2" (click)="saveVehicleTarget(itinerary)">
    <ng-container i18n>Uložit dojezdový cíl</ng-container>
  </button>
  <button  class="btn btn-warning mx-2" (click)="mapClose()">
    <ng-container i18n>ZPĚT</ng-container>
  </button>
</div>






