export class ExternalApiRequest {

  private _request_id: number;
  public get request_id(): number {
    return this._request_id;
  }
  public set request_id(value: number) {
    this._request_id = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _created_time: string;
  public get created_time(): string {
    return this._created_time;
  }
  public set created_time(value: string) {
    this._created_time = value;
  }

  private _domain: string;
  public get domain(): string {
    return this._domain;
  }
  public set domain(value: string) {
    this._domain = value;
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  private _descr: string;
  public get descr(): string {
    return this._descr;
  }
  public set descr(value: string) {
    this._descr = value;
  }

  private _price: number;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
  }

  get apiObject(): any {
    return {
      domain: this._domain,
      type: this._type,
      descr: this._descr,
      price: this._price
    };
  }
}