import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IS_DEMO, ItineraryType, ServiceConfiguration } from "../config";
import { connections } from "../connect";
import { DateTools } from "../tools/DateTools";
import { ObligationTools } from "../tools/ObligationTools";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { Obligation } from "../model/obligation.object";
import { Itinerary } from "../model/itinerary.object";
import { Message } from "../model/message.object";
import { Attachment } from "../model/attachment.object";
import { WoodSystemLog } from '../model/wood-system-log.object';
import { Email } from "../model/email.object";
import { InvoiceTools } from "../tools/InvoiceTools";
import { ObligationCosts } from "../model/obligation-costs.object";

@Injectable({
  providedIn: 'root',
})
export class ObligationService {

  private _apiUrl: string = ServiceConfiguration.obligations.api;

  private _obligations: BehaviorSubject<Array<Obligation>> = new BehaviorSubject([]);
  private _obligationsCache: Array<Obligation> = [];
  private _loadingObligations: boolean = false;
  private _loadingNextPage: boolean = false;

  private _obligationNumbers: BehaviorSubject<Array<Obligation>> = new BehaviorSubject([]);
  private _obligationNumbersCache: Array<Obligation> = [];

  private _obligation: BehaviorSubject<Obligation> = new BehaviorSubject(null);
  private _obligationCache: Obligation = null;
  private _loadingObligation: boolean = false;

  private _messages: BehaviorSubject<Array<Message>> = new BehaviorSubject([]);
  private _messagesCache: Array<Message> = [];
  private _loadingMessages: boolean = false;

  private _filters: any = {};
  private _sorts: any = {};
  private _page: number = 0;
  private _size: number = 0;

  private _filterNames: Array<string> = [
    'order',
    'order_partial',
    'tf',
    'tt',
    'year',
    'series',
    'status',
    'company',
    'company_key',
    'car',
    'car_key',
    'currency',
    'updated',
    'favourite',
    'note',
    'mode',
    'itin_addr',
    'itin_country',
    'itin_zip',
    'itin_city',
    'itin_tf',
    'itin_tt',
    'itin_last_tf',
    'itin_last_tt',
    'has_order',
    'issued_invoice',
    'received_invoice',
    'person_key',
    'has_order_std_no',
    'has_order_company_key',
    'delivery'
  ];

  private _sortNames: Array<string> = [
    'number',
    'custom_order_number',
    'series',
    'status',
    'company',
    'car',
    'created',
    'itin_arrival',
    'itin_last_arrival',
    'express_route'
  ];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }
  
  get loadingObligations(): boolean {
    return this._loadingObligations;
  }

  get loadingNextPage(): boolean {
    return this._loadingNextPage;
  }
  
  get loadingObligation(): boolean {
    return this._loadingObligation;
  }

  get loadingMessages(): boolean {
    return this._loadingMessages;
  }

  public clearObligationsCache(): void {
    this._obligationsCache = [];
  }

  public obligationsFilteredTotalRecords: number = 0;
  public obligationsFilteredTotalPages: number = 0;

  // method for getting obligations with given filter object
  getObligationsFiltered(filterObj: any, sortObj: any, page: number = 0, size: number = 50): Observable<Array<Obligation>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // init attributtes
      this._filters = filterObj;
      this._sorts = sortObj;
      this._page = page;
      this._size = size;
      // default url without any filter
      let url: string = this._apiUrl + '?page=' + this._page + '&size=' + this._size;

      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);
      
      // console.log(url);
      // set loading flags
      this._loadingObligations = true;
      // new version..
      // if (this._page > 0) {
      //   this._loadingNextPage = true;
      // }

      // handling full response (with headers) not just body as usual
      let httpOptions = {
        observe: 'response' as 'body'
      };

      this._http.get(url, httpOptions).subscribe(
        response => {
          if (response && response.body) {
            if (response.headers) {
              if (response.headers.get('X-TM-API-Total-Records')) {
                this.obligationsFilteredTotalRecords = response.headers.get('X-TM-API-Total-Records');
              }
              if (response.headers.get('X-TM-API-Total-Pages')) {
                this.obligationsFilteredTotalPages = response.headers.get('X-TM-API-Total-Pages');
              }
            } 
            this._obligationsCache = ObligationTools.buildObligationsFromData(response.body);
            this._obligations.next(this._obligationsCache);
          }
        },
        error => {
          // handle error
          console.log(error);
          this._obligationsCache = [];
          this._obligations.next(this._obligationsCache);
          this._loadingObligations = false;
          this._loadingNextPage = false;
        },
        () => {
          this._loadingObligations = false;
          this._loadingNextPage = false;
        }
      );
    }

    return this._obligations.asObservable();
  }


  private _loadingObligations2: boolean = false;
  get loadingObligations2(): boolean {
    return this._loadingObligations2;
  }

  // method for getting obligations with given filter object
  // same as above not using observable attribute
  getObligationsFilteredRequest(filterObj: any, sortObj: any, page: number = 0, size: number = 50): Observable<Array<Obligation>> {
    let result: BehaviorSubject<Array<Obligation>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = this._apiUrl + '?page=' + page + '&size=' + size;

      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);

      this._loadingObligations2 = true;
      this._http.get(url).subscribe(
        response => {
          let obligations = ObligationTools.buildObligationsFromData(response);
          this._loadingObligations2 = false;
          result.next(obligations);
        },
        error => {
          // handle error
          console.log(error);
          this._loadingObligations2 = false;
          result.next([]);
        }
      );
    }

    return result.asObservable();
  }
  
  // method for creating url string for filtering
  filterUrl(filterObj: any) {
    let result = '';
    let filterKeys: Array<string> = Object.keys(filterObj);
    filterKeys.forEach(
      key => {
        // check possible filters for obligations
        if (this._filterNames.includes(key)) {
          result += '&' + key + '=' + filterObj[key];
        }
      }
    );
    return result;
  }
  
  // method for creating url string for sorting
  sortUrl(sortObj: any) {
    let result = '';
    let sortKeys: Array<string> = Object.keys(sortObj);
    sortKeys.forEach(
      key => {
        // check possible sorts for obligations (should be always only one sort param)
        if (this._sortNames.includes(key)) {
          result += '&sort=' + key + ',' + sortObj[key];
        }
      }
    );
    return result;
  }


  // method for getting obligation with given key
  getObligation(obligation_key: number): Observable<Obligation> {
    this._loadingObligation = true;
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.get(this._apiUrl + obligation_key).subscribe(
        response => {
          if (response) {
            this._obligationCache = ObligationTools.buildObligation(response);
            this._obligation.next(this._obligationCache);
            this._loadingObligation = false;
          }
        },
        error => {
          // handle error
          console.log(error);
          this._loadingObligation = false;
        }
      );
    }

    return this._obligation.asObservable();
  }

  // method for getting obligation with given key
  getObligationToComponent(obligation_key: number): Observable<any> {
    // if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
    return this._http.get(this._apiUrl + obligation_key);
  }

  // method for getting obligation with given key
  getObligationOrderNumber(order_number_standard: string): Observable<Array<Obligation>> {
    if (order_number_standard && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(this._apiUrl + '?order=' + order_number_standard + '&sort=created,desc').subscribe(
        response => {
          this._obligationNumbersCache = ObligationTools.buildObligationsFromData(response);
          // slice number of obligations to max constant value 5
          if (this._obligationNumbersCache.length > 5) {
            this._obligationNumbersCache = this._obligationNumbersCache.slice(0, 5);
          }
          this._obligationNumbers.next(this._obligationNumbersCache);
        },
        error => {
          // handle error
          this._obligationNumbersCache = [];
          this._obligationNumbers.next(this._obligationNumbersCache);
          console.log(error);
        }
      );
    }

    return this._obligationNumbers.asObservable();
  }

  // method for getting obligation with given key
  getObligationOrderNumberRequest(order_number_standard: string): Observable<Array<Obligation>> {
    let result: BehaviorSubject<Array<Obligation>> = new BehaviorSubject([]);
    
    if (order_number_standard && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(this._apiUrl + '?order=' + order_number_standard + '&sort=created,desc').subscribe(
        response => {
          let obligations = ObligationTools.buildObligationsFromData(response);
          // slice number of obligations to max constant value 5
          if (obligations.length > 5) {
            obligations = obligations.slice(0, 5);
          }
          result.next(obligations);
        },
        error => {
          // handle error
          result.next([]);
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }


  // using in gen-for-invoicing - order query includes also custom_order_number condition
  getObligationOrderNumber2(order_number: string): Observable<Obligation> {
    let obligationSubject: BehaviorSubject<Obligation> = new BehaviorSubject(null);

    if (order_number && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(this._apiUrl + '?order=' + order_number + '&sort=created,desc').subscribe(
        response => {
          if (response && response.length) {
            let arr: Array<Obligation> = ObligationTools.buildObligationsFromData(response);
            // must be the first one now
            obligationSubject.next(arr[0]);
          }
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return obligationSubject.asObservable();
  }


  // using in gen-for-invoicing - order query includes also custom_order_number condition
  getObligationOrderNumberToComponent(order_number: string): Observable<Array<any>> {
    return this._http.get(this._apiUrl + '?order=' + order_number + '&sort=created,desc');
  }

  // method for getting obligation with given delivery_note
  getObligationDeliveryNote(delivery_note: string): Observable<Array<Obligation>> {
    let obligationSubject: BehaviorSubject<Array<Obligation>> = new BehaviorSubject([]);

    if (delivery_note && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(this._apiUrl + '?delivery=' + delivery_note + '&sort=created,desc').subscribe(
        response => {
          if (response) {
            let obligations = ObligationTools.buildObligationsFromData(response);
            // slice number of obligations to max constant value 5
            if (obligations.length > 5) {
              obligations = obligations.slice(0, 5);
            }
            obligationSubject.next(obligations);
          }
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return obligationSubject.asObservable();
  }

  // mode=files of obligation - filled just attachments and files array
  getObligationFiles(obligation_key: number): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    if (obligation_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.obligations.api;
      url += obligation_key.toString();
      url += '/?mode=files';

      this._http.get(url).subscribe(
        response => {
          if (response) {
            result.next(response);
          }
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // method for updating obligation properties
  updateObligation(obligation: Obligation, showAlert: boolean = true): Observable<Obligation> {
    let updateOblig: BehaviorSubject<Obligation> = new BehaviorSubject(null);
    let data: any = obligation.apiObjectUpdate;

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.put(this._apiUrl + obligation.obligation_key, data).subscribe(
        response => {
          if (showAlert) {
            // alert
            let alertSuccess: string = $localize`Parametry zakázky %Oblig byly úspěšně upraveny.`;
            alertSuccess = alertSuccess.replace('%Oblig', 'ZA' + obligation.order_number_standard);
            this._notificationService.alert(alertSuccess, 'success', 4000);
          }
          
          // observable next
          let oblig = ObligationTools.buildObligation(response);
          updateOblig.next(oblig);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě parametrů zakázky %Oblig.`;
          alertError = alertError.replace('%Oblig', 'ZA' + obligation.order_number_standard);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return updateOblig.asObservable();
  }

  // method for creating new obligation
  createObligation(obligation: Obligation, itinerary: Array<Itinerary>): Observable<Obligation> {
    let newObligation: BehaviorSubject<Obligation> = new BehaviorSubject(null);
    let data: any = obligation.apiObjectCreate;

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // subnumbers defined
      if (obligation.allow_subnumber && obligation.subnumber > 1) {
        // create first obligation
        this._http.post(this._apiUrl, data).subscribe(
          response => {
            //console.log(response);
            // success alert
            let alertSuccess: string = $localize`Zakázka %Oblig byla úspěšně vytvořena.`;
            alertSuccess = alertSuccess.replace('%Oblig', 'ZA' + obligation.order_number_standard);
            this._notificationService.alert(alertSuccess, 'success', 4000);
            
            // observable next
            let oblig: Obligation = ObligationTools.buildObligation(response);
            newObligation.next(oblig);
            
            let day_increment: number = 0;

            // create other subobligations with the same number
            for (let i=2; i <= obligation.subnumber; i++) {
              data['number'] = oblig.number;
              data['subnumber'] = i;

              // slice copy
              let tmp_itinerary: Array<Itinerary> = itinerary.slice();

              // increment itinerary arrival time with defined days interval
              if (obligation.subnumber_interval > 0) {
                // increment
                day_increment += obligation.subnumber_interval;

                // handle weekend skip
                if (tmp_itinerary[0] && tmp_itinerary[0].arrival_time_custom) {
                  let arrival_time: Date = new Date(tmp_itinerary[0].arrival_time_custom);
                  // increment by interval
                  arrival_time = new Date(
                    arrival_time.getTime() + day_increment*24*60*60*1000
                  );
                  // skip saturday and sunday
                  if (arrival_time.getDay() == 0 || arrival_time.getDay() == 6) {
                    while (arrival_time.getDay() == 0 || arrival_time.getDay() == 6) {
                      // increment day to skip weekend
                      day_increment += 1;
                      // increment by one day
                      arrival_time = new Date(
                        arrival_time.getTime() + 24*60*60*1000
                      );
                    }
                  }
                }
              }

              // creating subobligations
              this.postCreateObligation(data, tmp_itinerary, day_increment);
            }
          },
          error => {
            console.log(error);
            // error alert
            let alertError: string = $localize`Chyba při vytváření zakázky %Oblig.`;
            alertError = alertError.replace('%Oblig', "");
            this._notificationService.alert(alertError, 'error', 4000);
          }
        );
      }
      // no subnumbers, create only one obligation
      else {
        // creating obligation(subnumber 1)
        this._http.post(this._apiUrl, data).subscribe(
          response => {
            if (response.obligation_key) {
              // console.log(response);
              let oblig = ObligationTools.buildObligation(response);
              newObligation.next(oblig);
    
              // success alert
              let alertSuccess: string = $localize`Zakázka %Oblig byla úspěšně vytvořena.`;
              alertSuccess = alertSuccess.replace('%Oblig', 'ZA' + response.order_number_standard);
              this._notificationService.alert(alertSuccess, 'success', 4000);
            }
          },
          error => {
            console.log(error);
            // error alert
            let alertError: string = $localize`Chyba při vytváření zakázky %Oblig.`;
            alertError = alertError.replace('%Oblig', "");
            this._notificationService.alert(alertError, 'error', 4000);
          }
        );
      }
    }

    return newObligation.asObservable();
  }

  // core method for posting data of obligation
  postCreateObligation(data: any, itinerary: Array<Itinerary>, day_increment: number = 0): void {
    // let newObligation: BehaviorSubject<Obligation> = new BehaviorSubject(null);

    this._http.post(this._apiUrl, data).subscribe(
      response => {
        if (response.obligation_key) {
          // success alert
          let alertSuccess: string = $localize`Zakázka %Oblig byla úspěšně vytvořena.`;
          alertSuccess = alertSuccess.replace('%Oblig', 'ZA' + response.order_number_standard);
          this._notificationService.alert(alertSuccess, 'success', 4000);

          if (itinerary) {
            // console.log(response);
            let newOblig = ObligationTools.buildObligation(response);
            
            // save itinerary objects of new obligation
            itinerary.forEach(
              it => {
                this.createItinerary(newOblig, it, day_increment).subscribe();
              }
            );
          }
        }
      },
      error => {
        console.log(error);
        // error alert
        let alertError: string = $localize`Chyba při vytváření zakázky %Oblig.`;
        alertError = alertError.replace('%Oblig', "");
        this._notificationService.alert(alertError, 'error', 4000);
      }
    );
    // return newObligation.asObservable();
  }

  // method for deleting obligation
  deleteObligation(obligation: Obligation): void {
    if (obligation && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {

      this._http.delete(this._apiUrl + obligation.obligation_key).subscribe(
        response => {
          // OK <=> 204 no content
          let alertSuccess: string = $localize`Zakázka %Oblig byla odstraněna.`;
          alertSuccess = alertSuccess.replace('%Oblig', 'ZA' + obligation.order_number_standard);
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          // new get request for current filters of obligations and subscribe
          this.getObligationsFiltered(this._filters, this._sorts, this._page, this._size).subscribe();
        },
        error => {
          // handle error
          console.log(error);
          let alertError: string = $localize`Chyba při odstranění zakázky %Oblig.`;
          alertError = alertError.replace('%Oblig', 'ZA' + obligation.order_number_standard);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }


  /**********************************************************************************/
  /* Instant messages attached to obligation methods */
  /**********************************************************************************/
  // method for getting all instant messages
  getMessagesOfObligation(obligation_key: number): Observable<Array<Message>> {
    const KEY: string = '<OBLIGATION_KEY>';

    if (obligation_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._loadingMessages = true;

      this._http.get(ServiceConfiguration.obligations.apiObligMsg.replace(KEY, obligation_key.toString())).subscribe(
        response => {
          if (response) {
            let messages: any = response;
            this._messagesCache = [];

            if (messages instanceof Array) {
              messages.forEach(
                message_data => {
                  let message: Message = ObligationTools.buildMessage(message_data);
                  this._messagesCache.push(message);
                }
              )
            }
            // console.log(response);
            this._messages.next(this._messagesCache);
          }
        },
        error => {
          console.log(error);
          this._loadingMessages = false;
        },
        () => {
          this._loadingMessages = false;
        }
      );
    }

    return this._messages.asObservable();
  }

  // method for creating association between message and obligation
  postMessageToObligation(obligation_key: number, msg_key: number): Observable<any> {
    const KEY: string = '<OBLIGATION_KEY>';
    
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    let data: any = {
      'obligation_key': obligation_key,
      'msg_key': msg_key
    };
    
    if (obligation_key && msg_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.post(ServiceConfiguration.obligations.apiObligMsg.replace(KEY, obligation_key.toString()), data).subscribe(
        response => {
          // console.log(response);
          // success alert
          this._notificationService.alert(
            $localize`Zpráva byla úspěšně odeslána a přidána k zakázce.`, 'success', 3500
          );
          // observable next
          result.next({success: true});
        },
        error => {
          console.log(error);
          // error alert
          this._notificationService.alert(
            $localize`Chyba při přidružení zprávy k zakázce.`, 'error', 3500
          );
          // observable next
          result.next(error);
        }
      );
    }

    return result.asObservable();
  }


  /**********************************************************************************/
  /* Attachments of obligation methods */
  /**********************************************************************************/
  private _downloadingAttachment: boolean = false;
  public get downloadingAttachment(): boolean {
    return this._downloadingAttachment;
  }

  downloadAttachment(attachment: Attachment, order_number_standard: string= null): void {
    // loading flag
    this._downloadingAttachment = true;
    attachment.downloadingAttachment = true;

    this.getAttachment(attachment.url).subscribe(
      x => {
        console.log(x);
        var newBlob = new Blob([x], { type: x.type ? x.type : "application/pdf" });
        // unset downloading flag
        this._downloadingAttachment = false;
        attachment.downloadingAttachment = false;

        // save data to attachment fileContent property
        let blob = URL.createObjectURL(newBlob);
        // create <a> tag dinamically
        let fileLink = document.createElement('a');
        fileLink.href = blob;
        if (order_number_standard) {
          // same name as obligation order_number_standard
          fileLink.download = 'ZA' + order_number_standard;
        }
        else {
          // it forces the name of the downloaded file
          fileLink.download = attachment.name;
        }
        // triggers the click event
        fileLink.click();
      },
      error => {
        console.log(error);
      }
    );
  }

  // method for opening attachment in new tab
  openAttachmentNewTab(attachment: Attachment): void {
    // already downloaded?
    if (attachment && attachment.fileContent && attachment.fileContent.content) {
      // open new tab (does not work when ad block is activated)
      let w = window.open(attachment.fileContent.content, '_blank');
      if (w) {
        setTimeout(() => w.document.title = attachment.name, 200);
      }
      return;
    }

    // loading flag
    this._downloadingAttachment = true;
    attachment.downloadingAttachment = true;

    this.getAttachment(attachment.url).subscribe(
      x => {
        console.log(x);
        var newBlob = new Blob([x], { type: x.type ? x.type : "application/pdf" });
        // unset downloading flag
        this._downloadingAttachment = false;
        attachment.downloadingAttachment = false;

        // if .txt, .odt -> download - bad encoding in displaying in new tab
        if (newBlob.type == 'text/plain' || newBlob.type == 'application/vnd.oasis.opendocument.text') {
          // save data to attachment fileContent property
          let textBlob = URL.createObjectURL(newBlob);
          // create <a> tag dinamically
          let fileLink = document.createElement('a');
          fileLink.href = textBlob;
          // it forces the name of the downloaded file
          fileLink.download = attachment.name;
          // triggers the click event
          fileLink.click();
          return;
        }

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && (navigator as any).msSaveOrOpenBlob) {
          (navigator as any).msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        // open new tab (does not work when ad block is activated)
        let w = window.open(data, '_blank');
        if (w) {
          setTimeout(() => w.document.title = attachment.name, 200);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // method for downloading/opening attachment of obligation
  getAttachment(attachment_link: string): Observable<Blob> {
    // "https://app2.truckmanager.eu/nettedev/api/v1/files/company/52135-Objednavka1.pdf?name=Objednavka1.pdf"
    return this._http.get(connections.apiUrl + encodeURI(attachment_link), {responseType: 'blob'});
  }

  // method for adding attachment to obligation
  addAttachment(obligation_key: number, file: File): Observable<any> {
    const KEY: string = '<OBLIGATION_KEY>';

    let formData = new FormData();
    formData.append('file', file);

    let url: string = ServiceConfiguration.obligations.apiObligAttachment.replace(KEY, obligation_key.toString());
    return this._http.post(url, formData);
  }

  // method for deleting attachment from obligation
  deleteAttachment(obligation: Obligation, attachment: Attachment): void {
    if (!obligation || !attachment) return;
    
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiObligAttachmentId;
      url = url.replace('<OBLIGATION_KEY>', obligation.obligation_key.toString());
      url = url.replace('%ID%', attachment.id.toString());
      this._http.delete(url).subscribe(
        response => {
          // console.log(response);
          let alert: string = $localize`Přiložený soubor %Name byl úspěšně odstraněn.`;
          alert = alert.replace('%Name', attachment.name);
          this._notificationService.alert(alert, 'success', 3500);
        },
        error => {
          console.log(error);
          // error alert
          let alert: string = $localize`Chyba při odstranění přiloženého souboru %Name.`;
          alert = alert.replace('%Name', attachment.name);
          this._notificationService.alert(alert, 'error', 3500);
        }
      );
    }
  }

  // method for downloading thumbnail of attachment
  downloadThumbnail(attachment: Attachment): void {
    if (attachment.url && attachment.url.includes('files/')) {
      let thumbUrl: string = '';
      if (attachment.url.includes('/files/company/')) {
        thumbUrl = attachment.url.replace('company/', 'company/thumb/');
      }
      else {
        thumbUrl = attachment.url + '/thumb';
      }

      // set flag for loading thumbnail
      attachment.loadingThumbnail = true;

      this.getAttachment(thumbUrl).subscribe(
        response => {
          let newBlob: any = new Blob([response], { type: response.type });
  
          if (response.type !== '' && (response.type.match(/image|pdf|text/)) && response.size) {
            attachment.thumbnail = {
              content: URL.createObjectURL(newBlob),
              type: response.type
            };
          }
          
          // set flag for loading thumbnail
          attachment.loadingThumbnail = false;
        },
        error => {
          // set flag after error loading
          console.log(error);
          // set flag for loading thumbnail
          attachment.loadingThumbnail = false;
        }
      );
    }
  }


  /**********************************************************************************/
  /* Itinerary methods */
  /**********************************************************************************/
  // GET address-book - managing (ta2-search component)
  getItineraryLatest(searchText: string, type: string): Observable<Array<Itinerary>> {
    let result: BehaviorSubject<Array<Itinerary>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiItineraryLatest;
      url = url.replace('<TYPE>', type);

      this._http.get(url).subscribe(
        response => {
          if (response && response.length) {
            let itinerary = ObligationTools.buildItineraryFromArray(response);
            if (searchText.length) {
              // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
              let normalized_text = searchText.toLowerCase().normalize("NFKD").replace(/\p{Diacritic}/gu, "");
              itinerary = itinerary.filter(
                it => it.address && it.address.toLowerCase().normalize("NFKD").replace(/\p{Diacritic}/gu, "").includes(normalized_text)
              )
            }
            if (itinerary.length > 5) {
              itinerary = itinerary.slice(0, 5);
            }
            result.next(itinerary);
            console.log(itinerary);
          }
        },
        error => {
          // handle error
          console.log(error);
          result.next([]);
        }
      );
    }

    return result.asObservable();
  }

  // method for creating new itinerary
  createItinerary(obligation: Obligation, itinerary: Itinerary, day_increment: number = 0): Observable<Itinerary> {
    let itinerarySubject: BehaviorSubject<Itinerary> = new BehaviorSubject(null);

    // TODO aktualne davame konstantu 45 min
    let tmpDate: Date = itinerary.arrival_time_custom ? new Date(itinerary.arrival_time_custom) : null;
    let arrival_time_max: string = tmpDate ?  DateTools.toIsoWithoutMilisec(new Date(tmpDate.getTime() + 45*60*1000)) : null;

    // initialize data
    let data: any = {
      position: itinerary.position,
      route_position: itinerary.route_position,
      type: itinerary.type,
      address: itinerary.address,
      place_name: itinerary.place_name,
      place_city: itinerary.place_city,
      place_country: itinerary.place_country,
      place_street: itinerary.place_street,
      place_zip: itinerary.place_zip,
      gps_coord: itinerary.gps_coord_string,
      arrival_time: itinerary.arrival_time_custom ? DateTools.toIsoWithoutMilisec(new Date(itinerary.arrival_time_custom)) : null,
      arrival_period_days: itinerary.arrival_period_days,
      note: itinerary.note,
      route_part_length: itinerary.route_part_length
    };
    
    if (itinerary.type == ItineraryType.LOADING || itinerary.type == ItineraryType.UNLOADING) {
      // add atributtes for loading/unloading
      data.arrival_time_max = arrival_time_max;
      data.additional_activities = itinerary.additional_activities;
      data.weight = itinerary.weight;
      data.length = itinerary.length;
      data.ware_pcs = itinerary.ware_pcs;
      data.ware_type = itinerary.ware_type;
      data.work_day_begin = itinerary.work_day_begin;
      data.work_day_end = itinerary.work_day_end;
      data.notify_completed = itinerary.notify_completed;
      data.notify_arriving = itinerary.notify_arriving;
      data.loading_time_limit = itinerary.loading_time_limit;
      data.loading_time_real = itinerary.loading_time_real;
      data.line_key = itinerary.line_key;
      data.completed = itinerary.completed;
      data.code = itinerary.code;
      data.destination = itinerary.destination;
    }
    else if (itinerary.type == 'W') { 
      // add attributes to warehouse 
      data.weight = itinerary.weight;
      data.length = itinerary.length;
      data.ware_pcs = itinerary.ware_pcs;
      data.ware_type = itinerary.ware_type;
    }

    if (day_increment > 0) {
      if (itinerary.arrival_time_custom) {
        let arrival_time: Date = new Date(itinerary.arrival_time_custom);
        // increment by interval
        arrival_time = new Date(
          arrival_time.getTime() + day_increment*24*60*60*1000
        );
        data.arrival_time = DateTools.toIsoWithoutMilisec(arrival_time);
      }
    }

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.post(this._apiUrl + obligation.obligation_key + '/itinerary/', data).subscribe(
        response => {
          // console.log(response);
          let it = ObligationTools.buildItinerary(response);
          itinerarySubject.next(it);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při vytváření - ` + this.typeToString(itinerary) + ' %Pos'; 
          alertError = alertError.replace('%Pos', itinerary.position.toString());
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return itinerarySubject.asObservable();
  }

  // method for updating itinerary
  updateItinerary(obligation: Obligation, itinerary: Itinerary): Observable<Itinerary> {
    let itinerarySubject: BehaviorSubject<Itinerary> = new BehaviorSubject(null);

    // TODO aktualne davame konstantu 45 min
    let tmpDate: Date = itinerary.arrival_time_custom ? new Date(itinerary.arrival_time_custom) : null;
    let arrival_time_max: string = tmpDate ?  DateTools.toIsoWithoutMilisec(new Date(tmpDate.getTime() + 45*60*1000)) : null;

    // initialize data
    let data: any = {
      position: itinerary.position,
      route_position: itinerary.route_position,
      type: itinerary.type,
      address: itinerary.address,
      place_name: itinerary.place_name,
      place_city: itinerary.place_city,
      place_country: itinerary.place_country,
      place_street: itinerary.place_street,
      place_zip: itinerary.place_zip,
      gps_coord: itinerary.gps_coord_string,
      arrival_time: itinerary.arrival_time_custom ? DateTools.toIsoWithoutMilisec(new Date(itinerary.arrival_time_custom)) : null,
      arrival_period_days: itinerary.arrival_period_days,
      note: itinerary.note,
      route_part_length: itinerary.route_part_length,
      completed: itinerary.completed
    };

    if (itinerary.type == ItineraryType.LOADING || itinerary.type == ItineraryType.UNLOADING) {
      // add atributtes for loading/unloading
      data.arrival_time_max = arrival_time_max;
      data.additional_activities = itinerary.additional_activities;
      data.weight = itinerary.weight;
      data.length = itinerary.length;
      data.ware_pcs = itinerary.ware_pcs;
      data.ware_type = itinerary.ware_type;
      data.work_day_begin = itinerary.work_day_begin;
      data.work_day_end = itinerary.work_day_end;
      data.notify_completed = itinerary.notify_completed;
      data.notify_arriving = itinerary.notify_arriving;
      data.loading_time_limit = itinerary.loading_time_limit;
      data.loading_time_real = itinerary.loading_time_real;
      data.line_key = itinerary.line_key;
      data.code = itinerary.code;
      data.destination = itinerary.destination;
    }
    else if (itinerary.type == 'W') { 
      // add attributes to warehouse 
      data.weight = itinerary.weight;
      data.length = itinerary.length;
      data.ware_pcs = itinerary.ware_pcs;
      data.ware_type = itinerary.ware_type;
    }

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.put(this._apiUrl + obligation.obligation_key + '/itinerary/' + itinerary.itinerary_key, data).subscribe(
        response => {
          let it = ObligationTools.buildItinerary(response);
          // console.log(response);
          itinerarySubject.next(it);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při úpravě %Type %Pos.`;
          alertError = alertError.replace('%Pos', itinerary.position.toString()).replace('%Type', this.typeToString(itinerary));
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return itinerarySubject.asObservable();
  }

  // allowing catching errors in component
  updateItineraryInComponent(obligation: Obligation, itinerary: Itinerary): Observable<any> {
    // TODO aktualne davame konstantu 45 min
    let tmpDate: Date = itinerary.arrival_time_custom ? new Date(itinerary.arrival_time_custom) : null;
    let arrival_time_max: string = tmpDate ?  DateTools.toIsoWithoutMilisec(new Date(tmpDate.getTime() + 45*60*1000)) : null;

    // initialize data
    let data: any = {
      position: itinerary.position,
      route_position: itinerary.route_position,
      type: itinerary.type,
      address: itinerary.address,
      place_name: itinerary.place_name,
      place_city: itinerary.place_city,
      place_country: itinerary.place_country,
      place_street: itinerary.place_street,
      place_zip: itinerary.place_zip,
      gps_coord: itinerary.gps_coord_string,
      arrival_time: itinerary.arrival_time_custom ? DateTools.toIsoWithoutMilisec(new Date(itinerary.arrival_time_custom)) : null,
      arrival_period_days: itinerary.arrival_period_days,
      note: itinerary.note,
      route_part_length: itinerary.route_part_length,
      completed: itinerary.completed
    };

    if (itinerary.type == ItineraryType.LOADING || itinerary.type == ItineraryType.UNLOADING) {
      // add atributtes for loading/unloading
      data.arrival_time_max = arrival_time_max;
      data.additional_activities = itinerary.additional_activities;
      data.weight = itinerary.weight;
      data.length = itinerary.length;
      data.ware_pcs = itinerary.ware_pcs;
      data.ware_type = itinerary.ware_type;
      data.work_day_begin = itinerary.work_day_begin;
      data.work_day_end = itinerary.work_day_end;
      data.notify_completed = itinerary.notify_completed;
      data.notify_arriving = itinerary.notify_arriving;
      data.loading_time_limit = itinerary.loading_time_limit;
      data.loading_time_real = itinerary.loading_time_real;
      data.line_key = itinerary.line_key;
    }
    else if (itinerary.type == 'W') { 
      // add attributes to warehouse 
      data.weight = itinerary.weight;
      data.length = itinerary.length;
      data.ware_pcs = itinerary.ware_pcs;
      data.ware_type = itinerary.ware_type;
    }

    let url: string = this._apiUrl + obligation.obligation_key + '/itinerary/' + itinerary.itinerary_key;
    return this._http.put(url, data);
  }

  // method for deleting itinerary
  deleteItinerary(obligation: Obligation, itinerary: Itinerary): Observable<any> {
    let itinerarySubject: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.delete(this._apiUrl + obligation.obligation_key + '/itinerary/' + itinerary.itinerary_key).subscribe(
        response => {
          //console.log(response);
          itinerarySubject.next({'success': true});
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při odstranění %Type %Pos.`;
          alertError = alertError.replace('%Pos', itinerary.position.toString()).replace('%Type', this.typeToString(itinerary));
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return itinerarySubject.asObservable();
  }

  // method for text represention of itinerary type
  public typeToString(itinerary: Itinerary): string {
    if (!itinerary) return '';
    
    if (itinerary.type == ItineraryType.OLD_LOADING || itinerary.type == ItineraryType.LOADING) 
      return $localize`Nakládky`;
    else if (itinerary.type == ItineraryType.OLD_UNLOADING || itinerary.type == ItineraryType.UNLOADING)  
      return $localize`Vykládky`;
    else if (itinerary.type == ItineraryType.OLD_TRANSIT || itinerary.type == ItineraryType.TRANSIT)  
      return $localize`Tranzitu`;
    else if (itinerary.type == ItineraryType.OLD_MOVING || itinerary.type == ItineraryType.MOVING)  
      return $localize`Přejezdu`;
    else if (itinerary.type == ItineraryType.OLD_FUELING || itinerary.type == ItineraryType.FUELING)  
      return $localize`Tankování`;

    return '';
  }


  /**********************************************************************************/
  /* External - wood system logs */
  /**********************************************************************************/
  getWoodSystemLogs(car_key: number, intent: string): Observable<Array<WoodSystemLog>> {
    let logSubject: BehaviorSubject<Array<WoodSystemLog>> = new BehaviorSubject([]);

    if (car_key && intent && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.external.apiWoodSystem.replace('%CAR_KEY%', car_key.toString());
      url = url.replace('%INTENT%', intent.toUpperCase());
      
      this._http.get(url).subscribe(
        response => {
          if (response) {
            let logs = this.buildLogsFromData(response);
            logSubject.next(logs);
          }
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return logSubject.asObservable();
  }

  // method for creating wood system logs objects
  private buildLogsFromData(data: Array<any>): any {
    if (!data) return [];

    let logs: Array<WoodSystemLog> = [];
    data.forEach(
      o => {
        let log: WoodSystemLog = this.buildWoodSystemLog(o);
        logs.push(log);
      }
    );
    
    if (logs.length) {
      logs = logs.sort((a, b) => (a.log_id > b.log_id) ? 1 : -1);
    }
    
    return logs;
  }
  
  // method for creating a single wood system log object
  private buildWoodSystemLog(o: any): WoodSystemLog {
    if (!o) return null;
    
    let log: WoodSystemLog = new WoodSystemLog();
    for (let key in o) {
      log[key] = o[key];
    }
    return log;
  }

  

  /**********************************************************************************/
  /* Methods used in dispatcher board (agenda/obligations) */
  /**********************************************************************************/
  updateObligationCustom(obligation_key: number, apiObject: any, successAlert: boolean = true): Observable<any> {
    // response dont needed but we could catch event when obligation is updated
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.put(this._apiUrl + obligation_key, apiObject).subscribe(
        response => {
          // alert
          if (successAlert) {
            let alertSuccess: string = $localize`Parametry zakázky %Oblig byly úspěšně upraveny.`;
            alertSuccess = alertSuccess.replace('%Oblig', 'ZA' + response.order_number_standard);
            this._notificationService.alert(alertSuccess, 'success', 4000);
          }
          result.next(response);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě parametrů zakázky %Oblig.`;
          alertError = alertError.replace('%Oblig', '');
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  updateItineraryCustom(obligation_key: number, itinerary: Itinerary, apiObject: any): void {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.put(this._apiUrl + obligation_key + '/itinerary/' + itinerary.itinerary_key, apiObject).subscribe(
        response => {
          // console.log(response);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při úpravě %Type %Pos.`;
          alertError = alertError.replace('%Pos', itinerary.position.toString()).replace('%Type', this.typeToString(itinerary));
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }


  /**********************************************************************************/
  /* Obligation emailing service methods */
  /**********************************************************************************/
  /* Methods for email invoicing */
  private _sendingEmail: boolean = false;
  public get sendingEmail(): boolean {
    return this._sendingEmail;
  }

  private _sendCompleted: boolean = false;
  public set sendCompleted(value: boolean) {
    this._sendCompleted = value;
  }
  public get sendCompleted(): boolean {
    return this._sendCompleted;
  }

  private _sendOK: boolean = false;
  public set sendOK(value: boolean) {
    this._sendOK = value;
  }
  public get sendOK(): boolean {
    return this._sendOK;
  }

  // send email
  sendEmail(emailObj: any): Observable<any> {
    let email: BehaviorSubject<any> = new BehaviorSubject(null);
    // console.log(emailObj);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._sendingEmail = true;
      
      let url: string = ServiceConfiguration.obligations.apiObligEmail.replace(
        '<OBLIGATION_KEY>', emailObj.obligation_key
      );
      
      this._http.post(url, emailObj).subscribe(
        response => {
          console.log(response);
          this._sendingEmail = false;
          this._sendCompleted = true;
          this._sendOK = true;
          // success alert
          let alertSuccess: string = $localize`Email byl úspěšně odeslán.`;
          this._notificationService.alert(alertSuccess, 'success', 3500);
          email.next(response);
        },
        error => {
          console.log(error);
          this._sendingEmail = false;
          this._sendCompleted = true;
          // error alert
          let alertError: string = $localize`Nastala chyba při odesílání emailu.`;
          this._notificationService.alert(alertError, 'error', 3500);
        }
      );
    }
    
    return email.asObservable();
  }

  getEmails(obligation_key: number): Observable<Array<Email>> {
    let emails: BehaviorSubject<Array<Email>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiObligEmails;
      url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());

      this._http.get(url).subscribe(
        response => {
          let arr: Array<Email> = [];
          if (response) {
            for (let email_msg_id in response) {
              let header: Email = InvoiceTools.buildInvoiceEmail(response[email_msg_id]);
              header.email_msg_id = email_msg_id;
              arr.push(header);
            }
          }
          emails.next(arr);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return emails.asObservable();
  }

  // method for email header
  getEmailHeader(message_id: string): Observable<Email> {
    let emailHeader: BehaviorSubject<Email> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.header.replace('%MESSAGE_ID', message_id);
      this._http.get(url).subscribe(
        response => {
          let header: Email = InvoiceTools.buildInvoiceEmail(response);
          emailHeader.next(header);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return emailHeader.asObservable();
  }

  // method for loading detail html page about email sent
  getEmailShow(message_id: string): Observable<any> {
    let showHtml: BehaviorSubject<any> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.show.replace('%MESSAGE_ID', message_id);
      // let headers: HttpHeaders = new HttpHeaders();

      this._http.get(url, {responseType: "text"}).subscribe(
        response => {
          // console.log(response);
          showHtml.next(response);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return showHtml.asObservable();
  }


  // GET /obligations/<OBLIGATION_KEY>/complete-token
  // create external link for creating service event without authentication
  createExternalToken(obligation_key: number): Observable<any>  {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (obligation_key && this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.obligations.apiExternalServiceEventToken;
      url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());

      this._http.get(url).subscribe(
        response => {
          // console.log(response);
          // observable next
          result.next(response);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // GET /obligations/new-obligations-token?book=<BOOK_KEY>
  // create external token for netOrder creating (~ external obligation)
  createNewObligationToken(book_key: number): Observable<any>  {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (book_key && this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.obligations.apiExternalObligationCreate;
      url = url.replace('<BOOK_KEY>', book_key.toString());

      this._http.get(url).subscribe(
        response => {
          // console.log(response);
          let alert: string = $localize`Povolení netObjednávky bylo úspěšně vytvořeno.`;
          this._notificationService.alert(alert, 'success', 4000);
          // observable next
          result.next(response);
        },
        error => {
          // handle error
          let alert: string = $localize`Chyba při vytváření povolení netObjednávky.`;
          this._notificationService.alert(alert, 'error', 4000);
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // Viscofan import - CORAX user specific method
  importObligationToViscofan(obligation_key: number): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    let const_data: any = { action: 'import' };
    let url: string = ServiceConfiguration.obligations.apiViscofanImport;
    url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());

    this._http.post(url, const_data).subscribe(
      response => {
        if (response) {
          // success alert
          this._notificationService.alert(
            $localize`Úspěšný import do Viscofan.`, 'success', 4000
          );
          result.next(response);
        }
      },
      error => {
        console.log(error);
        // error alert
        this._notificationService.alert(
          $localize`Chyba při importu do Viscofan.`, 'error', 4000
        );
      }
    );
    return result.asObservable();
  }

  // Viscofan report - CORAX user specific method
  reportObligationToViscofan(obligation_key: number, data: any): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    let url: string = ServiceConfiguration.obligations.apiViscofanImport;
    url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());

    this._http.post(url, data).subscribe(
      response => {
        if (response) {
          // success alert
          this._notificationService.alert(
            $localize`Úspěšný report do Viscofan.`, 'success', 4000
          );
          result.next(response);
        }
      },
      error => {
        console.log(error);
        // error alert
        this._notificationService.alert(
          $localize`Chyba při reportu do Viscofan.`, 'error', 4000
        );
      }
    );
    return result.asObservable();
  }

  
  /**********************************************************************************/
  /* Preview PDF methods */
  /**********************************************************************************/
  // method for downloading/opening preview of obligation pdf
  getPreview(obligation: Obligation, lng: string): Observable<Blob> {
    let url: string = ServiceConfiguration.obligations.apiObligPreview;
    url = url.replace('<OBLIGATION_KEY>', obligation.obligation_key.toString());
    url = url.replace('<LANG>', lng.toUpperCase().trim());
    return this._http.get(url, {responseType: 'blob'});
  }


  /**********************************************************************************/
  /**********************************************************************************/
  /* Obligation emailing service methods */
  /**********************************************************************************/
  /* Methods for email invoicing */
  private _sendingAgreementEmail: boolean = false;
  public get sendingAgreementEmail(): boolean {
    return this._sendingAgreementEmail;
  }

  private _sendAgreementCompleted: boolean = false;
  public set sendAgreementCompleted(value: boolean) {
    this._sendAgreementCompleted = value;
  }
  public get sendAgreementCompleted(): boolean {
    return this._sendAgreementCompleted;
  }

  private _sendAgreementOK: boolean = false;
  public set sendAgreementOK(value: boolean) {
    this._sendAgreementOK = value;
  }
  public get sendAgreementOK(): boolean {
    return this._sendAgreementOK;
  }

  // method for downloading/opening preview of obligation preview pdf
  getAgreementPreview(obligation: Obligation, lng: string): Observable<Blob> {
    let url: string = ServiceConfiguration.obligations.apiObligAgreementPreview;
    url = url.replace('<OBLIGATION_KEY>', obligation.obligation_key.toString());
    url = url.replace('<LANG>', lng.toUpperCase().trim());
    return this._http.get(url, {responseType: 'blob'});
  }

  // send email
  sendAgreementEmail(emailObj: any, lng: string): Observable<any> {
    let email: BehaviorSubject<any> = new BehaviorSubject(null);
    // console.log(emailObj);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._sendingAgreementEmail = true;
      
      let url: string = ServiceConfiguration.obligations.apiObligAgreementEmail.replace(
        '<OBLIGATION_KEY>', emailObj.obligation_key
      );
      url = url.replace('<LANG>', lng.toUpperCase().trim());
      
      this._http.post(url, emailObj).subscribe(
        response => {
          console.log(response);
          this._sendingAgreementEmail = false;
          this._sendAgreementCompleted = true;
          this._sendAgreementOK = true;
          // success alert
          let alertSuccess: string = $localize`Email byl úspěšně odeslán.`;
          this._notificationService.alert(alertSuccess, 'success', 3500);
          email.next(response);
        },
        error => {
          console.log(error);
          this._sendingAgreementEmail = false;
          this._sendAgreementCompleted = true;
          // error alert
          let alertError: string = $localize`Nastala chyba při odesílání emailu.`;
          this._notificationService.alert(alertError, 'error', 3500);
        }
      );
    }
    
    return email.asObservable();
  }


  
  // PUT /obligations/complete-itinerary?token=<TOKEN>&oblig_key=<OBLIGATION_KEY>&itin_key=<ITINERARY_KEY>
  completeOrderItinerary(token: string | null, obligation_key: number | null, itin_key: number | null, data: any): Observable<any> {
    let url: string = ServiceConfiguration.obligations.apiOrderItineraryComplete;
    if (token && obligation_key && itin_key) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());
      url = url.replace('<ITINERARY_KEY>', itin_key.toString());
    }
    
    // handling all response (with headers) not just body as usual
    let httpOptions = {
      observe: 'response' as 'body'
    };

    return this._http.put(url, data, httpOptions);
  }


  /****************************************/
  /* File renaming */
  /****************************************/
  // GET /obligations/<OBLIGATION_KEY>/rename-file/<NAME>?name=<NEWNAME>'
  renameFile(obligation_key: number, name: string, new_name: string): void {
    let url: string = ServiceConfiguration.obligations.apiObligRenameFile;
    url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());
    url = url.replace('<NAME>', name);
    url = url.replace('<NEWNAME>', new_name);

    // handling all response (with headers) not just body as usual
    // let httpOptions = {
    //   headers: new HttpHeaders({
    //     'Accept': '*',
    //   })
    // };

    this._http.get(url).subscribe(
      response => {
        console.log(response);
        // success alert
        let alertSuccess: string = $localize`Soubor byl úspěšně přejmenován.`;
        this._notificationService.alert(alertSuccess, 'success', 4000);
      },
      error => {
        console.log(error);
        // success alert
        let alertError: string = $localize`Chyba při přejmenování souboru.`;
        this._notificationService.alert(alertError, 'error', 4000);
      }
    )
  }

  
  /***************************************************************/
  /* Obligation costs */
  /***************************************************************/
  // method for loading obligation costs patterns
  getObligationCostsPatterns(): Observable<Array<ObligationCosts>> {
    let result: BehaviorSubject<Array<ObligationCosts>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCosts;

      this._http.get(url).subscribe(
        response => {
          let arr: Array<ObligationCosts> = ObligationTools.buildObligationCostsFromData(response);
          result.next(arr);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // method for creating new obligation costs pattern
  createCostsPattern(costs: ObligationCosts): Observable<ObligationCosts> {
    let result: BehaviorSubject<ObligationCosts> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCosts;
      // post data
      // return this._http.post(url, item.apiObject);

      this._http.post(url, costs.apiObjectCostsPattern).subscribe(
        response => {
          let created = ObligationTools.buildObligationCosts(response);
          let alertSuccess: string = 'Vzor vícenákladu byl úspěšně vytvořen.';
          this._notificationService.alert(alertSuccess, 'success', 4000);
          result.next(created);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = 'Chyba při vytváření vzoru vícenákladu';
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }
  
  // method for updating obligation costs pattern
  updateCostsPattern(costs: ObligationCosts): Observable<ObligationCosts> {
    let result: BehaviorSubject<ObligationCosts> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCosts;
      url += costs.cost_key.toString();
      // put data
      // return this._http.put(url, item.apiObject);

      this._http.put(url, costs.apiObjectCostsPattern).subscribe(
        response => {
          // observable next
          let updated = ObligationTools.buildObligationCosts(response);
          let alertSuccess: string = 'Vzor vícenákladu byl úspěšně upraven.';
          this._notificationService.alert(alertSuccess, 'success', 4000);
          result.next(updated);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = 'Chyba při úpravě vzoru vícenákladu.';
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }

  // method for deleting obligation costs pattern
  deleteCostsPattern(costs: ObligationCosts): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCosts;
      url += costs.cost_key.toString();
      // delete request
      // return this._http.delete(url);

      this._http.delete(url).subscribe(
        response => {
          //console.log(response);
          let alertSuccess: string = 'Vzor vícenákladu byl úspěšně odstraněn.';
          this._notificationService.alert(alertSuccess, 'success', 4000);
          result.next({'success': true});
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = 'Chyba při odstranění vzoru vícenákladu.';
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }

  
  // method for loading obligation costs
  getObligationCosts(obligation: Obligation): Observable<Array<ObligationCosts>> {
    let result: BehaviorSubject<Array<ObligationCosts>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCostsKey;
      url = url.replace('<OBLIGATION_KEY>', obligation.obligation_key.toString());

      this._http.get(url).subscribe(
        response => {
          let arr: Array<ObligationCosts> = ObligationTools.buildObligationCostsFromData(response);
          result.next(arr);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // method for creating new obligation cost
  createCosts(costs: ObligationCosts): Observable<ObligationCosts> {
    let result: BehaviorSubject<ObligationCosts> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCostsKey;
      url = url.replace('<OBLIGATION_KEY>', costs.obligation_key.toString());
      // post data
      // return this._http.post(url, item.apiObject);

      this._http.post(url, costs.apiObject).subscribe(
        response => {
          let created = ObligationTools.buildObligationCosts(response);
          result.next(created);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = 'Chyba při vytvoření vícenákladu.';
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }
  
  // method for updating obligation costs
  updateCosts(costs: ObligationCosts): Observable<ObligationCosts> {
    let result: BehaviorSubject<ObligationCosts> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCostsKey;
      url = url.replace('<OBLIGATION_KEY>', costs.obligation_key.toString());
      url += costs.cost_key.toString();
      // put data
      // return this._http.put(url, item.apiObject);

      this._http.put(url, costs.apiObject).subscribe(
        response => {
          // observable next
          let updated = ObligationTools.buildObligationCosts(response);
          result.next(updated);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = 'Chyba při úpravě vícenákladu.';
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }

  // method for deleting obligation costs
  deleteCosts(costs: ObligationCosts): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.obligations.apiCostsKey;
      url = url.replace('<OBLIGATION_KEY>', costs.obligation_key.toString());
      url += costs.cost_key.toString();
      // delete request
      // return this._http.delete(url);

      this._http.delete(url).subscribe(
        response => {
          //console.log(response);
          result.next({'success': true});
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = 'Chyba při odsrtanění vícenákladu.';
          this._notificationService.alert(alertError, 'error', 4000);
          result.next(undefined);
        }
      );
    }
    return result.asObservable();
  }
}
