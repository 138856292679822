<ng-template #messageStatusIcons>
  <div *ngIf="!message.system_msg" [ngSwitch]="message.direction_to_car" class="text-center">
    <div *ngSwitchCase="false">
      <img *ngIf="!message.unread" src="assets/icons-new/eye-green.svg" 
      alt="eye-green" height="18px"/>
      <img *ngIf="message.unread" src="assets/icons-new/eye-red.svg" 
      alt="eye-red" height="18px"/>
    </div>
    <div *ngSwitchCase="true">
      <div *ngIf="message.sent">
        <img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"
        ngbTooltip="{{message.sent_time_formatted}}" placement="bottom" container="body"/>
      </div>
      <div *ngIf="!message.sent">
        <img src="assets/icons-new/garbage_can.svg" alt="garbage_can" height="18px"/>
      </div>
      <div [ngSwitch]="message.read_by_tm">
        <div *ngSwitchDefault>
          <img src="assets/icons-new/eye-green.svg" alt="eye-green" height="18px"
          ngbTooltip="{{message.read_by_tm_formatted}}" placement="bottom" container="body"/>
        </div>
        <img *ngSwitchCase="null" src="assets/icons-new/eye-red.svg" 
        alt="eye-red" height="18px"/>
      </div>
    </div>
  </div>
  <div *ngIf="message.system_msg" class="text-center" >
    <img *ngIf="!message.unread" src="assets/icons-new/eye-green.svg" 
    alt="eye-green" height="18px"/>
    <img *ngIf="message.unread" src="assets/icons-new/eye-red.svg" 
    alt="eye-red" height="18px"/>
  </div>
</ng-template>

<ng-template #messageAttachmentDisplay>
  <div *ngIf="displayAttachment" 
  class="message-attachment" 
  [attachment]="message.attachment" 
  [msg_key]="message.msg_key"></div>
</ng-template>

<ng-template #segmentedContent>
  <ng-container *ngFor="let segment of messageSegments">
    <!-- obligation info -->
    <div *ngIf="message?.obligation">
      <a (click)="openObligationNewTab(message.obligation.obligation_key)"
      href="javascript:void(0)">ZA{{message.obligation.order_number_standard}}</a>
      <span *ngIf="message.obligation.order_comp_book">, {{message.obligation.order_comp_book.company}}</span>
      <!-- <span *ngIf="message.obligation.isWienerberger" class="ml-1">
        <a href="javascript:void(0)'" (click)="setObligationToWienerberger(message.obligation)"
        [ngbPopover]="linksPopover" container="body" placement="right auto">
          <img src="assets/icons-new/wienerberger_small.svg" height="16px" 
          class="rounded shadow"/>
        </a>
        <ng-template #linksPopover>
          <div>
            <a href="javascript:void(0)" data-toggle="modal" data-target="#wienerbergerModal"
            (click)="setWcpWienerberger()">
              https://www.wcp-doprava.cz/doprava/wzi.php?czak={{message.obligation.order_number}}
            </a>
          </div>
          <div>
            <a href="javascript:void(0)" data-toggle="modal" data-target="#wienerbergerModal"
            (click)="setTondachWienerberger()">
              https://www.tondach-doprava.cz/doprava/wzi.php?czak={{message.obligation.order_number}}
            </a>
          </div>
        </ng-template>
      </span> -->
    </div>
    <!-- itinerary info -->
    <div *ngIf="message?.itinerary">
      {{message.itinerary.notify_completed}}
      <span i18n>plánovaná</span><span>&nbsp;</span>
      <span *ngIf="message.itinerary.type == 'L'" i18n>nakládka</span>
      <span *ngIf="message.itinerary.type == 'U'" i18n>vykládka</span>
      <span *ngIf="message.itinerary.arrival_time">
        {{message.itinerary.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
      </span>
      ({{message.itinerary.work_day_begin_formatted}}-{{message.itinerary.work_day_end_formatted}})
    </div>
    <!-- order info -->
    <div *ngIf="message?.order">
      <a (click)="openOrderNewTab(message.order.order_key)"
      href="javascript:void(0)">OB{{message.order.order_number_standard}}</a>
      <span *ngIf="message.order.order_comp_book">, {{message.order.order_comp_book.company}}</span>
      <span *ngIf="message.order.spz">, {{message.order.spz}}</span> 
      <span *ngIf="message.order.driver_name">, {{message.order.driver_name}}</span>
      <span *ngIf="message.order.driver_phone">, {{message.order.driver_phone}}</span>
      <span *ngIf="message.order.obligations?.length && message.order.obligations[0].isWienerberger" class="ml-1">
        <a href="javascript:void(0)" (click)="setOrderToWienerberger(message.order)"
        data-toggle="modal" data-target="#wienerbergerModal">
          <img src="assets/icons-new/wienerberger_small.svg" height="16px" 
          class="rounded shadow"/>
        </a>
        <!-- <ng-template #linksPopover>
          <div>
            <a href="javascript:void(0)" (click)="setWcpWienerberger()"
            data-toggle="modal" data-target="#wienerbergerModal">
              https://www.wcp-doprava.cz/doprava/wzi.php?czak={{message.order.obligations[0].order_number}}
            </a>
          </div>
          <div>
            <a href="javascript:void(0)" (click)="setTondachWienerberger()"
            data-toggle="modal" data-target="#wienerbergerModal">
              https://www.tondach-doprava.cz/doprava/wzi.php?czak={{message.order.obligations[0].order_number}}
            </a>
          </div>
        </ng-template> -->
      </span>
    </div>
    <!-- message content -->
    <ng-container *ngIf="segment.type === 'text'">
      <span class="font-italic">{{segment.value}}</span>
    </ng-container>
    <ng-container *ngIf="segment.type === 'breaker'"><br/></ng-container>
    <ng-container *ngIf="segment.type === 'tracking'">
      <a class="pointer" href="javascript:void(0)" (click)="openManualTrackingConfiguration(segment.value)">
        {{segment.value}}
      </a>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #messageContainer>
  <div [class]="(message.direction_to_car === false && message.unread ? 'bold' : '') + ' text-left'" 
  style="overflow-wrap: initial;word-break: initial;white-space: initial;">
    <ng-container *ngIf="message.direction_to_car && message.read_by_tm && message.unread">
      <strong i18n>Vaše zpráva byla přečtena</strong><br/>
    </ng-container>
    <ng-template [ngTemplateOutlet]="segmentedContent"></ng-template>
    <!-- <img src="assets/icons-new/redo.svg" alt="reply" height="18px"/> -->
  </div>
</ng-template>

<div class="message {{message.message_row_background_color}} row" [id]="'message'+message.msg_key">
  <!-- center - message metadata -->
  <div class="col-4">
    <div>
      <strong>{{message.vehicle?.number_plate}}</strong> {{message.vehicle?.driver_name}}
    </div>
    <div class="row no-gutters">
      <div class="col-8">
        <img *ngIf="message.system_msg" src="assets/icons-new/system2.svg" alt="system2" height="25px"/>
        <img *ngIf="!message.system_msg && message.direction_to_car" src="assets/icons-new/user.svg" alt="user" height="25px"/>
        <img *ngIf="!message.system_msg && !message.direction_to_car" src="assets/icons-new/truck.svg" alt="truck" height="25px"/>
        <a *ngIf="message.obligation?.edoc_url && message.obligation?.edoc_required_itinerary"
        [href]="message.obligation.edoc_url" target="_blank">
          <img src="assets/icons-new/link.svg" alt="truck" height="25px"
          [ngbTooltip]="message.obligation.edoc_url" container="body" placement="right auto"/>
        </a>
      </div>
      <div class="col-4">
        <ng-template [ngTemplateOutlet]="messageStatusIcons"></ng-template>
      </div>
    </div>
    <div *ngIf="message.time">{{message.time | date: 'dd.MM. HH:mm'}}</div>
  </div>

  <!-- center - message content -->
  <div class="col-7 {{(replyPossible ? 'message-content replyable rounded pointer' : '')}}" 
  (click)="toggleReply()">
    <div class="row text-center">
      <div class="col-sm-{{displayAttachment ? 8 : 12}}">
        <ng-template [ngTemplateOutlet]="messageContainer"></ng-template>
        <span *ngIf="ignoreAttachment" i18n>S číslem zakazky</span>
      </div>
      <div class="col-4" *ngIf="displayAttachment">
        <ng-template [ngTemplateOutlet]="messageAttachmentDisplay"></ng-template>
      </div>
    </div>
  </div>

  <!-- end - mark as read / delete message -->
  <div class="col-1 text-right">
    <div *ngIf="message.unread && !deleting">
      <a href="javascript:void(0)" (click)="readMessage()"
      ngbTooltip="Označit jako přečtenou" container="body" placement="left auto" i18n-ngbTooltip>
        <img src="assets/icons-new/check-solid.svg" alt="check" height="18px"/>
      </a>
    </div>

    <div>
      <div *ngIf="deleting" class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only"></span>
      </div>
      <a *ngIf="!deleting" href="javascript:void(0)"
      ngbTooltip="Odstranit zprávu" container="body" placement="left auto" i18n-ngbTooltip
      data-toggle="modal" data-target="#deleteMessageModal">
        <img src="assets/icons-new/garbage_can.svg" alt="eye-green" height="18px"/>
      </a>
    </div>
  </div>

  <!-- <div class="col-4 text-right">
    <a *ngIf="message.unread && !deleting" href="javascript:void(0)" (click)="readMessage()"
    ngbTooltip="Označit jako přečtenou" container="body" placement="left auto">
      <img src="assets/icons-new/check-solid.svg" alt="check" height="18px"/>
    </a>

    <a *ngIf="!deleting" href="javascript:void(0)" class="ml-3"
    ngbTooltip="Odstranit zprávu" container="body" placement="left auto"
    data-toggle="modal" data-target="#deleteMessageModal">
      <img src="assets/icons-new/garbage_can.svg" alt="eye-green" height="18px"/>
    </a>
    
    <div *ngIf="deleting" class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only"></span>
    </div>
  </div> -->

  <!-- <div class="col-sm-3 col-4 text-left">
    <div class="row no-gutters">
      <div class="col-8">
        <img *ngIf="message.system_msg" src="assets/icons-new/system2.svg" alt="system2" height="25px"/>
        <img *ngIf="!message.system_msg && message.direction_to_car" src="assets/icons-new/user.svg" alt="user" height="25px"/>
        <img *ngIf="!message.system_msg && !message.direction_to_car" src="assets/icons-new/truck.svg" alt="truck" height="25px"/>
      </div>
      <div class="col-4">
        <ng-template [ngTemplateOutlet]="messageStatusIcons"></ng-template>
      </div>
    </div>
  </div> -->
  <!-- <div class="col-sm-{{message.direction_to_car && (message.forwarded_to_email || message.forwarded_to_sms) ? 8 : 9}} col-{{message.direction_to_car && (message.forwarded_to_email || message.forwarded_to_sms) ? 6 : 7}}">
    <div class="row text-center">
      <div class="col-sm-{{displayAttachment ? 8 : 12}} message-content {{(replyPossible ? 'replyable pointer' : '')}}">
        <ng-template [ngTemplateOutlet]="messageContainer"></ng-template>
        <span *ngIf="ignoreAttachment">S číslem zakazky</span>
      </div>
      <div  class="col-4" *ngIf="displayAttachment">
        <ng-template [ngTemplateOutlet]="messageAttachmentDisplay"></ng-template>
      </div>
    </div>
  </div> -->
  <!-- <div *ngIf="message.direction_to_car && (message.forwarded_to_email || message.forwarded_to_sms)">
    <div class="col-sm-1 col-2 text-center">
      <div *ngIf="message.forwarded_to_email">
        <img src="assets/icons-new/at-solid.svg" alt="at" height="18px"/>
      </div>
      <div *ngIf="message.forwarded_to_sms">
        <img src="assets/icons-new/sms-solid.svg" alt="sms" height="18px"
        [ngbTooltip]="message.forwarded_to_sms_time_formatted" placement="bottom" container="body"/>
      </div>
    </div>
  </div> -->

  <!-- <div class="col-12">
    <div *ngIf="message.time">{{message.time | date: 'dd.MM. HH:mm'}}</div>
  </div> -->
</div>

<!-- reply - message-write component -->
<ng-container *ngIf="replyPossible">
  <message-write *ngIf="visible" [vehicle]="vehicle"></message-write>
</ng-container>


<!-- modal for confirmation of deleting -->
<div class="modal fade" role="dialog" tabindex="-1" id="deleteMessageModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-danger shadow">
        <h4 class="modal-title" i18n>Odstranění zprávy</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="message" class="mb-3">
          <div>{{message.time | date: 'HH:mm'}}</div>
          <div>{{message.msg_raw}}</div>
        </div>

        <h5 i18n>Opravdu si přejete odstranit tuto zprávu?</h5>
        <h6 i18n>Pozor! Po odstranění již nelze zprávu obnovit.</h6>
      </div>
      <div class="modal-footer">
        <button class="btn btn-lg btn-outline-secondary" type="button" data-dismiss="modal">
          <ng-container i18n>NE</ng-container>
        </button>
        <button class="btn btn-lg btn-danger" type="button" (click)="deleteMessage()" data-dismiss="modal">
          <ng-container i18n>ANO</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
