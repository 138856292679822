<ng-container *ngIf="haveDriver()">
  <div *ngIf="!aetr_collection">
    <div class="spinner-border spinner-border-sm" role="status" style="width: 1rem; height: 1rem;">
      <span class="sr-only"></span>
    </div>
    <span class="ml-1" i18n>Načítám AETR</span>
  </div>
  <ng-container *ngIf="aetr_collection">
    <div class="row">
      <ng-container *ngFor="let segment of aetr_collection.segments">
        <!-- Past segment -->
        <ng-container *ngIf="aetr_collection.isSegmentPast(segment)">
          <div *ngIf="aetr_collection.isSegmentSleep(segment)" 
          class="col-5 aetr-pause aetr-part text-truncate">
            <ng-container i18n>Spánek</ng-container>
            <span> {{duration(segment)}}h </span>
            <ng-container i18n>do</ng-container>
            <span> {{endDay(segment)}} {{end(segment)}}h</span>
          </div>

          <!-- aetr-OK -->
          <div *ngIf="aetr_collection.isSegmentDrive(segment) && (!aetrErrors || !aetrErrors.length)" 
          class="col-7 aetr-driving aetr-part text-truncate">
            <span class="text-warning"><ng-container i18n>Výkon</ng-container>: {{workloadPast}} </span>
            <ng-container i18n>Jízda</ng-container>
            <span> {{duration(segment)}}h {{doneDriving(segment)}} ({{formatDriveStyle(segment)}})</span>
          </div>
          <!-- aetr-error -->
          <div *ngIf="aetr_collection.isSegmentDrive(segment) && aetrErrors?.length" 
          class="bg-danger col-7 aetr-driving aetr-part text-truncate"
          [ngbTooltip]="aetrErrorsTooltip" container="body" placement="left auto">
            <span class="text-warning"><ng-container i18n>Výkon</ng-container>: {{workloadPast}} </span>
            <ng-container i18n>Jízda</ng-container>
            <span> {{duration(segment)}}h {{doneDriving(segment)}} ({{formatDriveStyle(segment)}})</span>
          </div>
        </ng-container>

        <!-- Current segment -->
        <ng-container *ngIf="aetr_collection.isSegmentCurrent(segment)">
          <div *ngIf="aetr_collection.isSegmentSleep(segment)" 
          class="aetr-pause aetr-part d-flex justify-content-center text-truncate"
          [ngClass]="aetr_collection.isPastAndCurrentSegmentSleep ? 'col-7' : 'col-5'">
            <ng-template #sleepZeroRemaining>
              <strong class="text-success align-self-center" i18n>Připraven k jízdě</strong>
            </ng-template>
            <ng-container *ngIf="segment.remaining; else sleepZeroRemaining">
              {{workloadCurrent}} 
              (<ng-container i18n>od</ng-container> {{startDay(segment)}} {{start()}}h)
            </ng-container>
          </div>
          <ng-container *ngIf="aetr_collection.isSegmentDrive(segment)">
            <!-- aetr-OK -->
            <div *ngIf="!aetrErrors || !aetrErrors.length" 
            class="aetr-driving col-7 aetr-part text-truncate"> 
              <span class="text-warning">
                <ng-container i18n>Výkon:</ng-container> {{workloadCurrent}}
              </span> 
              <ng-container i18n>Jízda</ng-container>
              <span> {{duration(segment)}}h {{doneDriving(segment)}} ({{formatDriveStyle(segment)}})</span>
            </div>
            <!-- aetr-error -->
            <div *ngIf="aetrErrors?.length" 
            class="aetr-driving col-7 aetr-part bg-danger text-truncate"
            [ngbTooltip]="aetrErrorsTooltip" container="body" placement="left auto"> 
              <span class="text-warning"><ng-container i18n>Výkon</ng-container>: {{workloadCurrent}} </span>
              <ng-container i18n>Jízda</ng-container>
              <span> {{duration(segment)}}h {{doneDriving(segment)}} ({{formatDriveStyle(segment)}})</span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #aetrErrorsTooltip>
  <div *ngIf="aetrErrors?.length">
    <div class="border-bottom mb-2">AETR <ng-container i18n>chyby</ng-container></div>
    <div *ngFor="let error of aetrErrors; let i = index">
      {{(i+1) + ')'}} {{error}}
    </div>
  </div>
</ng-template>