import { ItineraryType, ObligationStatus } from "../config";
import { DateTools } from "../tools/DateTools";
import { ObligationTools } from "../tools/ObligationTools";
import { Itinerary } from "./itinerary.object";
import { Obligation } from "./obligation.object";
import { Vehicle } from "./vehicle.object";

export class ExpressRoute {
  private _express_key: number;
  public get express_key(): number {
    return this._express_key;
  }
  public set express_key(value: number) {
    this._express_key = value;
  }
  
  private _car_key: number;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }

  private _car: Vehicle = null;
  public get car(): Vehicle {
    return this._car;
  }
  public set car(value: Vehicle) {
    this._car = value;

    // compute estimation of route length
    this.computeRouteLengthEstimated(true);
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _created_time: string;
  get created_time(): string {
    return this._created_time;
  }
  set created_time(value: string) {
    this._created_time = value;
    if (value) {
      this._created_time_date = DateTools.apiIsoToDate(value);
    }
  }
  
  private _created_time_date: Date;
  public get created_time_date(): Date {
    return this._created_time_date;
  }
  
  private _date_realization: string;
  public get date_realization(): string {
    return this._date_realization;
  }
  public set date_realization(value: string) {
    this._date_realization = value;
    if (this._date_realization) {
      this._realizationDate = new Date(this._date_realization);
    }
  }
  
  // date object
  private _realizationDate: Date;
  public get realizationDate(): Date {
    return this._realizationDate;
  }

  private _description: string;
  public get description(): string {
    return this._description;
  }
  public set description(value: string) {
    this._description = value;
  }

  private _route_length: number;
  public get route_length(): number {
    return this._route_length;
  }
  public set route_length(value: number) {
    this._route_length = value;
  }
  
  private _route_length_real: number;
  public get route_length_real(): number {
    return this._route_length_real;
  }
  public set route_length_real(value: number) {
    this._route_length_real = value;
    this._route_transit_length_real = this._route_length_real + this._transit_length_real;
  }

  private _transit_length_real: number;
  public get transit_length_real(): number {
    return this._transit_length_real;
  }
  public set transit_length_real(value: number) {
    this._transit_length_real = value;
    this._route_transit_length_real = this._route_length_real + this._transit_length_real;
  }

  private _route_transit_length_real: number = 0;
  public get route_transit_length_real(): number {
    return this._route_transit_length_real;
  }

  private _number: number;
  public get number(): number {
    return this._number;
  }
  public set number(value: number) {
    this._number = value;
  }
  
  private _subnumber: number = 1;
  public get subnumber(): number {
    return this._subnumber;
  }
  public set subnumber(value: number) {
    this._subnumber = value;
  }

  private _series: number;
  public get series(): number {
    return this._series;
  }
  public set series(value: number) {
    this._series = value;
  }

  // same format as obligation
  private _order_number_standard: string;
  public get order_number_standard(): string {
    return this._order_number_standard;
  }
  public set order_number_standard(value: string) {
    this._order_number_standard = value;
    if (this._order_number_standard.length > 2) {
      this._order_number_standard = this._order_number_standard.substring(0, this._order_number_standard.length - 2);
    }
  }

  private _status: string;
  public get status(): string {
    return this._status;
  }
  public set status(value: string) {
    this._status = value;
    this._backgroundColorClass = ObligationTools.setBackground(this._status);
    this._backgroundColorRowClass = ObligationTools.setBackgroundTableRow(this._status);
  }
  
  // custom property for color in list according to status
  private _backgroundColorClass: string = '';
  get backgroundColorClass(): string {
    return this._backgroundColorClass;
  }
  set backgroundColorClass(value: string) {
    this._backgroundColorClass = value;
  }
  
  private _backgroundColorRowClass: string = '';
  get backgroundColorRowClass(): string {
    return this._backgroundColorRowClass;
  }
  set backgroundColorRowClass(value: string) {
    this._backgroundColorRowClass = value;
  }

  private _updated_by: string;
  public get updated_by(): string {
    return this._updated_by;
  }
  public set updated_by(value: string) {
    this._updated_by = value;
  }
  
  private _updated_time: string;
  public get updated_time(): string {
    return this._updated_time;
  }
  public set updated_time(value: string) {
    this._updated_time = value;
  }
  
  private _year: number = new Date().getFullYear();
  public get year(): number {
    return this._year;
  }
  public set year(value: number) {
    this._year = value;
  }

  private _invoicingBaborovsky: boolean = false;
  public get invoicingBaborovsky(): boolean {
    return this._invoicingBaborovsky;
  }
  public set invoicingBaborovsky(value: boolean) {
    this._invoicingBaborovsky = value;
  }

  // custom array
  private _obligations: Array<Obligation> = [];
  public get obligations(): Array<Obligation> {
    return this._obligations;
  }
  public set obligations(value: Array<Obligation>) {
    this._obligations = value;
    
    // also init express itinerary array
    if (this._obligations) {
      this._itineraryOrdered = [];
      this._obligations.forEach(
        o => {
          if (o.itinerary) {
            o.itinerary.forEach(
              it => {
                // save circular reference
                it.obligation = o;
              }
            );
      
            if (o.piece_shipment && (o.status == ObligationStatus.K_VYRIZENI || o.status == ObligationStatus.SKLAD_SVOZ)) {
              // sberna preprava - status k vyrizeni / svoz
              let noUnloadings = o.itinerary.filter(it => it.type != ItineraryType.UNLOADING);
              this._itineraryOrdered = this._itineraryOrdered.concat(noUnloadings);
            }
            else if (o.piece_shipment) {
              // sberna preprava
              let noLoadings = o.itinerary.filter(it => it.type != ItineraryType.LOADING);
              this._itineraryOrdered = this._itineraryOrdered.concat(noLoadings);
            }
            else {
              this._itineraryOrdered = this._itineraryOrdered.concat(o.itinerary);
            }
          }
        }
      );
      
      // sort
      // this._itineraryOrdered.sort((a, b) => (a.arrival_time_custom > b.arrival_time_custom) ? 1 : -1);
      this._itineraryOrdered.sort((a, b) => (a.route_position > b.route_position) ? 1 : -1);

      // compute estimation of route length
      this.computeRouteLengthEstimated(true);

      // compute current weight for all itinerary
      this.computeCurrentWeight();

      // handle Baborovsky/Wienerberger specific invoicing (DIČ CZ00015253)
      if (this._obligations.find(o => o.order_comp_book && o.order_comp_book.tin == 'CZ00015253')) {
        this._invoicingBaborovsky = true;
      }
    }
  }

  // total sum of obligations contracted_price (its been transferred into home currency)
  private _obligationsPriceSum: number = 0;
  public get obligationsPriceSum(): number {
    return this._obligationsPriceSum;
  }
  public set obligationsPriceSum(value: number) {
    this._obligationsPriceSum = value;
  }

  computeObligationsPriceSum(): void {
    this._obligationsPriceSum = 0;
    this._obligations.forEach(
      o => {
        if (o.exchange_price) {
          this._obligationsPriceSum += o.exchange_price;
        }
      }
    );
  }

  // total sum of obligations price with costs (its been transferred into home currency)
  private _obligationsPriceTotalCostSum: number = 0;
  public get obligationsPriceTotalCostSum(): number {
    return this._obligationsPriceTotalCostSum;
  }
  public set obligationsPriceTotalCostSum(value: number) {
    this._obligationsPriceTotalCostSum = value;
  }

  computeObligationsPriceCostTotalSum(): void {
    this._obligationsPriceTotalCostSum = 0;
    this._obligations.forEach(
      o => {
        if (o.price_total_costs) {
          this._obligationsPriceTotalCostSum += o.price_total_costs;
        }
      }
    );
  }

  computeCurrentWeight(): void {
    let currentWeight: number = 0;
    // compute current weight
    this._itineraryOrdered.forEach(
      it => {
        if (it.obligation.piece_shipment && (it.obligation.status == ObligationStatus.K_VYRIZENI || it.obligation.status == ObligationStatus.SKLAD_SVOZ)) {
          // sberna preprava - status k vyrizeni / svoz
          if (it.type != ItineraryType.UNLOADING) {
            // compute current weight
            if (it.weight) {
              currentWeight += it.weight;
            }
            it.currentWeight = Math.round(currentWeight * 100) / 100;
          }
        }
        else if (it.obligation.piece_shipment) {
          // sberna preprava - pokracovani
          if (it.type != ItineraryType.LOADING) {
            // compute current weight
            if (it.weight) {
              currentWeight -= it.weight;
            }
            it.currentWeight = Math.round(currentWeight * 100) / 100;
          }
        }
        else {
          // compute current weight
          if (it.weight) {
            if (it.type == ItineraryType.LOADING) {
              currentWeight += it.weight;
            }
            else if (it.type == ItineraryType.UNLOADING) {
              currentWeight -= it.weight;
            }
          }
          it.currentWeight = Math.round(currentWeight * 100) / 100;
        }
      }
    );
  }

  // custom array of itinerary in arrival_time order
  private _itineraryOrdered: Array<Itinerary> = [];
  public get itineraryOrdered(): Array<Itinerary> {
    return this._itineraryOrdered;
  }
  public set itineraryOrdered(value: Array<Itinerary>) {
    this._itineraryOrdered = value;
  }

  private _routeLengthEstimated: number = 0;
  public get routeLengthEstimated(): number {
    return this._routeLengthEstimated;
  }
  
  computeRouteLengthEstimated(home_moving: boolean): void {
    // compute estimation of route length
    this._routeLengthEstimated = ObligationTools.computeRouteLengthEstimation(this._itineraryOrdered);
         
    if (home_moving) {
      if (this._itineraryOrdered.length && this._car && this._car.home_stand) {
        if (this._itineraryOrdered[0].gps_coord) {
          // start distance
          let homeStartDist = ObligationTools.computeDistanceEstimation(
            this._car.home_stand.geoPosition, 
            this._itineraryOrdered[0].gps_coord.googleLatLang
          );
          if (homeStartDist) {
            this._routeLengthEstimated += Math.abs(Math.round(homeStartDist));
          }
        }
        if (this._itineraryOrdered[0].gps_coord) {
          // end distance
          let homeEndDist = ObligationTools.computeDistanceEstimation(
            this._car.home_stand.geoPosition, 
            this._itineraryOrdered[this._itineraryOrdered.length - 1].gps_coord.googleLatLang
          );
          if (homeEndDist) {
            this._routeLengthEstimated += Math.abs(Math.round(homeEndDist));
          }
        }
      }
    }
  }

  // flag used in dispatcher board component
  private _isOutOfInterval: boolean = false;
  public get isOutOfInterval(): boolean {
    return this._isOutOfInterval;
  }
  public set isOutOfInterval(value: boolean) {
    this._isOutOfInterval = value;
  }
  
  public get apiObject(): any {
    return {
      car_key: this.car_key,
      date_realization: this.date_realization,
      description: this.description,
      route_length: this.route_length,
      route_length_real: this.route_length_real,
      transit_length_real: this.transit_length_real,
      subnumber: 1,
      series: this._series,
      status: this._status,
      year: this._year
    };
  }
}
    