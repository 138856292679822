import {Token} from "./token.object";
import { UserAccount } from "./user-account.object";

export class User {

  constructor(
    private _username: string,
    private _token: Token,
    private _company_key: number,
    private _company_name: string,
    private _display_key: number,
    private _person: string,
    private _admittance: Date
  ) {
    if (this._admittance) {
      // plus 3-4 days
      this._admittanceDateExtended = new Date(this._admittance.getTime() + 4*24*60*60*1000);
    }
  }


  get username(): string {
    return this._username;
  }

  get token(): Token {
    return this._token;
  }

  get company_key(): number {
    return this._company_key;
  }

  get company_name(): string {
    return this._company_name;
  }

  get display_key(): number {
    return this._display_key;
  }

  get person(): string {
    return this._person;
  }

  get admittance(): Date {
    return this._admittance;
  }

  // custom
  private _admittanceDateExtended: Date = null;
  public get admittanceDateExtended(): Date {
    return this._admittanceDateExtended;
  }

  // custom
  private _user_account: UserAccount = new UserAccount();
  public get user_account(): UserAccount {
    return this._user_account;
  }
  public set user_account(value: UserAccount) {
    this._user_account = value;
  }


  toString(): string {
    return JSON.stringify({
      username: this._username,
      token: this._token.toString(),
      company_key: this._company_key,
      company_name: this._company_name,
      display_key: this._display_key,
      person: this._person,
      admittance: JSON.stringify(this._admittance)
    });
  }
}
