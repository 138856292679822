import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { IS_DEMO, ServiceConfiguration } from "../config";
import { Trailer } from "../model/trailer.object";
import { Model } from "../model/model.object";
import { Make } from "../model/make.object";

@Injectable({
  providedIn: 'root',
})
export class TrailerService {

  private _apiUrl: string = ServiceConfiguration.trailers.api;
  private _trailersCache: Array<Trailer> = [];
  private _apiUrlAll: string = ServiceConfiguration.trailers.apiInactive;
  private _allTrailers: BehaviorSubject<Array<Trailer>> = new BehaviorSubject([]);
  private _allTrailersCache: Array<Trailer> = [];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  private _trailers: BehaviorSubject<Array<Trailer>> = new BehaviorSubject([]);
  get trailers(): Array<Trailer> {
    return this._trailers.getValue();
  }

  private _loadingTrailers: boolean = false;
  get loadingTrailers(): boolean {
    return this._loadingTrailers;
  }

  private _loadingAllTrailers: boolean = false;
  get loadingAllTrailers(): boolean {
    return this._loadingAllTrailers;
  }

  public clearTrailersCache(): void {
    this._trailersCache = [];
    this._trailers.next([]);
  }

  public clearAllTrailersCache(): void {
    this._allTrailersCache = [];
    this._allTrailers.next([]);
  }

  getTrailers(): Observable<Array<Trailer>> {
    if (!this._trailersCache.length && !this._loadingTrailers) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadTrailers();
      }
    }
    return this._trailers.asObservable();
  }

  getAllTrailers(): Observable<Array<Trailer>> {
    if (!this._allTrailersCache.length && !this._loadingAllTrailers) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadAllTrailers();
      }
    }
    return this._allTrailers.asObservable();
  }

  loadTrailers() {
    this._loadingTrailers = true;
    this._http.get(this._apiUrl)
      .subscribe(
        response => {
          this._trailersCache = this.buildTrailersFromData(response);
          this._trailers.next(this._trailersCache);
        },
        error => {
          // handle error
          console.log(error);
          this._loadingTrailers = false;
        },
        () => {
          // finally
          this._loadingTrailers = false;
        }
      );
  }

  loadAllTrailers() {
    this._loadingAllTrailers = true;
    this._http.get(this._apiUrlAll)
      .subscribe(
        response => {
          this._allTrailersCache = this.buildTrailersFromData(response);
          this._allTrailers.next(this._allTrailersCache);
        },
        error => {
          // handle error
          console.log(error);
          this._loadingAllTrailers = false;
        },
        () => {
          // finally
          this._loadingAllTrailers = false;
        }
      );
  }

  // method for updating trailer properties
  public updateTrailer(trailer: Trailer): Observable<any> {
    let updTrailer: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {

      let data = {};
      trailer.update.forEach(
        key => {
          if (key == "model_id") {
            data["model"] = trailer.model_id;
          } else {

            data[key] = trailer[key];
          }
        }
      );

      this._http.put(this._apiUrl + '/' + trailer.trailer_key, data)
        .subscribe(
          response => {
            // remove all updated values
            trailer.update = [];
            updTrailer.next(response);
          },
          error => {
            // handle error
            console.log(error);
            this._notificationService.alert(
              $localize`Chyba úpravy návěsu %SPZ`.replace('%SPZ', trailer.number_plate),
              'error', 3000
            );
          },
          () => {
            // finally
            this._notificationService.alert(
              $localize`Návěs %SPZ byl úspěšně upraven.`.replace('%SPZ', trailer.number_plate),
              'success', 3000
            );
          }
        );
    }

    return updTrailer.asObservable();
  }

  // method for creating new trailer
  createTrailer(trailer: Trailer): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // initialize data
      let data = {
        number_plate: trailer.number_plate,
        type: trailer.type,
        model: trailer.model_id,
        production_year: trailer.production_year,
        tonnage: trailer.tonnage,
        inactive: false
      };

      // set loading flag
      this._loadingAllTrailers = true;

      this._http.post(this._apiUrl + '/', data).subscribe(
        success => {
          if (success) {
            if (success.trailer_key) {
              let trailer = this.buildTrailer(success);
              this._allTrailersCache.push(trailer);
              this._allTrailers.next(this._allTrailersCache);

              // success alert
              this._notificationService.alert(
                $localize`Návěs %SPZ byl úspěšně vytvořen.`.replace('%SPZ', trailer.number_plate),
                'success', 3000
              );
            }
          } else {
            console.log(success);
            // error alert
            this._notificationService.alert(
              $localize`Chyba při vytváření návěsu %SPZ`.replace('%SPZ', trailer.number_plate),
              'error', 3000
            );
          }
        },
        error => {
          this._loadingAllTrailers = false;
          console.log(error);
          // error alert
          this._notificationService.alert(
            $localize`Chyba při vytváření návěsu %SPZ`.replace('%SPZ', trailer.number_plate),
            'error', 3000
          );
        },
        () => {
          this._loadingAllTrailers = false;
        }
      );
    }

    return this._allTrailers.asObservable();
  }

  // method for creating trailer objects from api objects
  private buildTrailersFromData(data: Array<any>): any {
    let trailers: Array<Trailer> = [];
    data.forEach(
      t => {
        let trailer = this.buildTrailer(t);
        trailers.push(trailer);
      }
    );
    return trailers;
  }

  // method for creating a single trailer object
  private buildTrailer(t: any): Trailer {
    let trailer: Trailer = new Trailer();

    // nested json objects
    if (t.model) {
      let model = new Model();
      for (let key in t.model) {
        model[key] = t.model[key];
      }
      t.model = model;
      t.model_id = model.model_id;

      if (t.model.make) {
        let make = new Make();
        for (let key in t.model.make) {
          make[key] = t.model.make[key];
        }
        t.model.make = make;
        t.make = make.name;
      }
    }

    for (let key in t) {
      trailer[key] = t[key];
    }
    // clear array of setter changes for possible update
    trailer.update = [];

    return trailer;
  }
}
