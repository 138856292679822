<!-- default resource -->
<iframe *ngIf="!isAuthenticated && !isDemo" name="unAuthenticated" 
[src]="contentResource | safeResource"></iframe>

<div *ngIf="isAuthenticated" class="col-12 board-header">
  <div class="board-header-text text-truncate">
    <!-- icon -->
    <img height="50px" class="mr-3 align-top" src="assets/icons-new/table.svg" alt="board"/>

    <!-- time / connection error -->
    <span *ngIf="!notConnected" class="align-middle">{{lastVehicleLoad | date: 'HH:mm'}}</span>
    <b *ngIf="notConnected" class="align-middle" 
    style="cursor:default; font-size:36px; line-height:0px; color:red" 
    ngbTooltip="Chyba spojení" container="body" placement="bottom auto">
      {{'{!}'}}
    </b>

    <!-- plachta dispecera -->
    <span class="align-middle ml-2" i18n>Plachta</span>

    <div class="d-inline-block align-middle ml-3 pt-1" style="font-size: 17px">
      <div class="custom-control custom-control-inline custom-checkbox">
        <input class="custom-control-input" type="checkbox" id="liteCheck" [(ngModel)]="liteVersion"/>
        <label class="custom-control-label mb-0" for="liteCheck">
          LITE 
        </label>
      </div>
    </div>
    
    <div *ngIf="loadingObligations || loadingExpressRoutes" class="d-inline-block pt-2 ml-5">
      <div class="spinner-wrapper">
        <div class="spinner-grow text-dark" style="width:1.5rem; height:1.5rem;" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>

    <!-- obligations feature - zakazky k vyrizeni -->
    <div *ngIf="!loadingObligations" class="position-relative d-inline-block ml-3"
    [ngbTooltip]="obligationsTooltip" container="body" placement="bottom auto"
    data-toggle="modal" data-target="#obligationsEModal">
      <img height="46px" class="align-top pointer" src="assets/icons-new/folders2.svg" alt="icon"/>
      <span class="badge badge-light pointer" style="font-size:11px; position:absolute; bottom:5px; right:0px;">
        {{obligationsStatusE.length}}
      </span>
    </div>
    
    <ng-template #obligationsTooltip>
      <div *ngIf="obligationsStatusE.length">
        {{obligationsStatusE.length}} <span i18n>zakázek k vyřízení</span>
      </div>
      <div *ngIf="!obligationsStatusE.length">
        <ng-container i18n>Žádné zakázky k vyřízení</ng-container>
      </div>
    </ng-template>

    
    <!-- express routes feature - trasy k vyrizeni -->
    <div *ngIf="!loadingExpressRoutes && !loadingObligations" class="position-relative d-inline-block ml-3"
    [ngbTooltip]="expressRoutesTooltip" container="body" placement="bottom auto"
    data-toggle="modal" data-target="#expressRoutesEModal">
      <img height="46px" class="align-top pointer" src="assets/icons-new/route.svg" alt="route"/>
      <span class="badge badge-light pointer" 
      style="font-size:11px; position:absolute; bottom:5px; right:0px;">
        {{expressRoutesStatusE.length}}
      </span>
    </div>
    
    <ng-template #expressRoutesTooltip>
      <div *ngIf="expressRoutesStatusE.length">
        {{expressRoutesStatusE.length}} <span i18n>dokládkových tras k vyřízení</span>
      </div>
      <div *ngIf="!expressRoutesStatusE.length">
        <ng-container i18n>Žádné dokládkové trasy k vyřízení</ng-container>
      </div>
    </ng-template>

    <!-- obligations feature - oblibene zakazky -->
    <div *ngIf="!loadingObligations" class="position-relative d-inline-block ml-3"
    [ngbTooltip]="obligationsFavouriteTooltip" container="body" placement="bottom auto"
    data-toggle="modal" data-target="#obligationsFavouriteModal">
      <img height="46px" class="align-top pointer" src="assets/icons-new/star.svg" alt="icon"/>
      <span class="badge badge-light pointer" style="font-size:11px; position:absolute; bottom:5px; right:0px;">
        {{obligationsFavourite.length}}
      </span>
    </div>
    
    <ng-template #obligationsFavouriteTooltip>
      <div *ngIf="obligationsFavourite.length">
        {{obligationsFavourite.length}} <ng-container i18n>oblíbených zakázek</ng-container>
      </div>
      <div *ngIf="!obligationsFavourite.length">
        <ng-container i18n>Žádné oblíbené zakázky</ng-container>
      </div>
    </ng-template>

    
    <!-- filtering cars according to persons/vehicles -->
    <div *ngIf="!loadingObligations" class="position-relative d-inline-block ml-3"
    ngbTooltip="Filtr podle vozidla/dispečera" container="body" placement="bottom auto"
    data-toggle="modal" data-target="#vehicleFilterModal">
      <img height="46px" class="align-top pointer" 
      src="assets/icons-new/businesspeople2.svg" alt="icon"/>
      <span class="badge badge-light pointer" 
      style="font-size:11px; position:absolute; bottom:5px; right:0px;">
        {{vehicles.length}}
      </span>
    </div>
  </div>
</div>

<div *ngIf="isAuthenticated && !validAccess" class="board-body">
  <div class="container text-left py-4">
    <h4>
      <span i18n>Váš přístup vypršel. Máte-li zájem o další využívání, předplaťte si přístup</span>
      <a href="javascript:void(0)" (click)="openSubsNewTab()" i18n>zde</a>.
    </h4>
  </div>
</div>

<div *ngIf="isAuthenticated && tariffOnlyAgenda">
  <div class="container text-left py-4">
    <h4 i18n>Váš tarif neumožňuje zobrazení vozidel</h4>
  </div>
</div>

<!-- FULL version -->
<div *ngIf="isAuthenticated && validAccess && !tariffOnlyAgenda && !liteVersion" class="board-body">
  <div *ngIf="loadingVehicles" class="text-center" [style.width.px]="windowInnerWidth">
    <div class="spinner-wrapper py-4">
      <div class="spinner-border text-primary" style="width:5rem; height:5rem;" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
  
  <div class="dispatcher-board-wrapper" (click)="setSelectedAgenda($event, null)">
    <div *ngIf="!loadingVehicles" class="container-fluid">
      <div *ngIf="!vehicles || !vehicles.length">
        <h6 i18n>Nepodařilo se načíst vozidla</h6>
      </div>

    
      <div *ngIf="vehicles?.length" class="row">
        <div class="col-12">
          <!-- Left Arrow -->
          <!-- [style.lineHeight.px]="innerHeight" [style.height.px]="(outerHeight + 30)"  -->
          <div class="move-backward" (click)="moveBackward()" placement="right auto" [ngbTooltip]="moveBackwardTitle"
          [style.height.px]="arrowColumnHeight - 20" [style.lineHeight.px]="innerHeight">
            <img src="assets/icons-new/navigate_left.svg" alt="navigate_left" height="18px"/>
          </div>

          <ng-container *ngFor="let vehicle of vehicles; let i = index">
            <div class="row dispatcher-board-vehicle">
              <div class="col-12 no-padding">
                <ng-container *ngFor="let day of days">
                  <div class="float-left" [style.width]="columnWidth">
                    <div class="container-fluid">
                      <div class="row vehicle-row-header" [class.bg-danger]="isWeekend(day)" [class.text-white]="isWeekend(day)">
                        <div class="col-6 text-left px-3 border-left border-dark">
                          {{getDateString(day)}} {{getDayString(day)}}
                        </div>
                        <div class="col-6 text-right px-3">
                          <span [class.bold]="('vehicle_list_highlight'|configuration:'vehicle') === 'drivers'">
                            {{vehicle.driver_name}}
                          </span> 
                          <span [class.bold]="('vehicle_list_highlight'|configuration:'vehicle') === 'plates'">
                            {{vehicle.number_plate}} + {{vehicle.trailer ? vehicle.trailer.number_plate : ''}}
                          </span>
                        </div>
                      </div>
                      <div class="row vehicle-row">
                        <div class="col-12 time-segments" [style.height.px]="vehicle.timeSegmentsHeight">
                          <div class="row">
                            <div class="col-3" *ngFor="let hour_group of hours">
                              <div class="row">
                                <div *ngFor="let hour of hour_group" class="col-2 no-padding">
                                  <!-- hour text (00-23h) -->
                                  <div class="hour-text">
                                    {{(hour > 9) ? hour : '0' + hour}}
                                  </div>
                                  <!-- lines for representanting hour/halfhour timeline -->
                                  <div class="border-left hour-line" 
                                  [class.border-dark]="hour == 0" 
                                  [style.height.px]="vehicle.hourLineHeight">
                                    <div class="halfhour-line-offset">
                                    </div>
                                    <div class="border-right w-50 halfhour-line" 
                                    [style.height.px]="vehicle.halfhourLineHeight">
                                    </div>
                                  </div>
                                  <!-- show current time via blue line + vehicle status icon -->
                                  <ng-container *ngIf="isThisHour(day,hour)">
                                    <div class="today-marker bg-primary"
                                    [style.height.px]="vehicle.todayMarker"
                                    [style.margin-top.px]="-vehicle.todayMarker">
                                    </div>
                                    <img class="vehStatusIcon pointer" [src]="vehicle.truck_status_name"
                                    (click)="lastCargoVehicle = vehicle; openDragAndDropDiv()"/>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              
              <!-- <div class="bg-primary border position-relative" style="width:150px; height: 150px;"
              mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event)">
                <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
              </div> -->

              <!-- agendas -->
              <ng-container *ngIf="getDataByVehicle(vehicle).length">
                <ng-container *ngFor="let dispAgenda of getDataByVehicle(vehicle); let isLast=last;">
                  <ng-container *ngIf="dispAgenda.infoWidth > 0 && dispAgenda.infoOffset > -1">
                    <div class="dispatcher-board-agenda"
                    [class.selected]="dispAgenda===selectedAgenda"
                    [style.width.px]="dispAgenda.infoWidth"
                    [style.left.px]="dispAgenda.infoOffset"
                    [style.top.px]="dispAgenda.conflictOffsetTop" 
                    placement="bottom auto" container="body" 
                    [ngbPopover]="popoverContent" #agendaPopover="ngbPopover"
                    (click)="setSelectedAgenda($event, dispAgenda)"
                    cdkDrag
                    [cdkDragDisabled]="!dispAgenda.enableMove || dispAgenda !== selectedAgenda"
                    (cdkDragEnded)="cdkDrop($event, agendaPopover)" 
                    mwlResizable 
                    [enableGhostResize]="true" 
                    (resizeEnd)="onResizeEnd($event)"
                    [validateResize]="validate">
                      <!-- progress bar -->
                      <div [style.cursor]="dispAgenda===selectedAgenda && dispAgenda.enableMove ? 'move' : 'pointer'">
                        <!-- top part -->
                        <div [class]="'d-flex justify-content-between px-1 border-bottom border-secondary radius ' + dispAgenda.backgroundColorClass">
                          <div class="text-truncate">
                            <a *ngIf="isLast" class="mr-1" style="position: relative; z-index: 42"
                            (click)="setAgendaToTimocomOnTheWay($event, dispAgenda.agenda);"
                            ngbTooltip="Vytížení během trasy" container="body" placement="left auto" i18n-ngbTooltip>
                              <span class="badge badge-pill badge-success pointer">?</span>
                            </a>
                            <span>{{dispAgenda.order_comp_book}}</span>
                          </div>
                          <div *ngIf="dispAgenda.infoWidth > 240" class="text-nowrap">
                            <span>ZA{{dispAgenda.agenda.order_number}}</span>
                            <a *ngIf="isLast" class="ml-1" style="position: relative; z-index: 42"
                            (click)="setAgendaToTimocomBackHome($event, dispAgenda.agenda);"
                            ngbTooltip="Vytížení domů" container="body" placement="right auto" i18n-ngbTooltip>
                              <span class="badge badge-pill badge-success pointer">?</span>
                            </a>
                          </div>
                          <!-- <div *ngIf="!dispAgenda.agenda_started" class="text-right" style="width:14px; line-height:18px;">
                            <img src="assets/icons-new/navigate_cross_red.svg" 
                            alt="navigate_cross_red" height="14px" class="pointer"
                            ngbTooltip="Stornovat zakázku" placement="left auto" container="body"
                            data-toggle="modal" data-target="#cancelModal"/>
                          </div> -->
                        </div>
                        <!-- bottom part -->
                        <div class="progress position-relative">
                          <div [class]="'clearfix progress-bar greenBackground' + dispAgenda.getDrivenDistanceCssClass()" 
                          [style.width]="dispAgenda.getDrivenDistancePercentage()+'%'">
                          </div>
                          <span *ngIf="dispAgenda.infoWidth > 200" class="centered-text text-left pl-1">
                            {{dispAgenda.start_location_shortcode}} ({{dispAgenda.agenda?.weight}}t)
                          </span>
                          <span *ngIf="!dispAgenda.express_delivery" class="centered-text text-center">
                            {{dispAgenda.length_and_driven}}
                          </span>
                          <span *ngIf="dispAgenda.infoWidth > 200" class="centered-text text-right pr-1">
                            {{dispAgenda.finish_location_shortcode}}
                          </span>
                        </div>
                      </div>

                      <!-- handler for resizing -->
                      <div *ngIf="dispAgenda===selectedAgenda" class="resize-handle-right" 
                      mwlResizeHandle [resizeEdges]="{ right: true }">
                        <span>></span>
                      </div>
                      
                      <!-- bootstrap popover template -->
                      <ng-template #popoverContent>
                        <div class="rounded p-1" style="background: #cccccc">
                          <div *ngIf="dispAgenda.agenda?.express_delivery; else noExpressOptions">
                            <a (click)="openExpressRouteNewTab(dispAgenda.agenda.express_key)"
                            href="javascript:void(0)">
                              <ng-container i18n>Detail dokládkové trasy</ng-container>
                            </a>
                          </div>
                          <ng-template #noExpressOptions>
                            <div class="d-flex justify-content-between border-bottom py-2">
                              <div class="mx-1">
                                <button class="btn btn-primary" 
                                (click)="dispAgenda.enableMove=true; detectChanges();">
                                  <ng-container i18n>Přesunout</ng-container>
                                </button>
                              </div>
                              <div class="mx-1">
                                <button class="btn btn-warning" 
                                data-toggle="modal" data-target="#statusModal">
                                  <ng-container i18n>K vyřízení</ng-container>
                                </button>
                              </div>
                              <div class="mx-1">
                                <button class="btn btn-secondary" 
                                data-toggle="modal" data-target="#cancelModal">
                                  <ng-container i18n>Stornovat</ng-container>
                                </button>
                              </div>
                            </div>
                          </ng-template>
                          <div *ngIf="dispAgenda.agenda">
                            <div>
                              <a class="text-nowrap" href="javascript:void(0)"
                              (click)="openObligationNewTab(dispAgenda.agenda.obligation_key)">
                                {{dispAgenda.agenda.order_number}}
                              </a>
                              ({{dispAgenda.agenda.contracted_price | price}} {{dispAgenda.agenda.currency}})
                            </div>
                            <div *ngIf="dispAgenda.agenda.order_comp_book">
                              <b>
                                {{dispAgenda.agenda.order_comp_book.company}},
                                {{dispAgenda.agenda.order_comp_book.city}},
                                {{dispAgenda.agenda.order_comp_book.country}}
                              </b>
                            </div>
                            <div>
                              <span *ngIf="dispAgenda.agenda.payer_contact; else noPayerContact">
                                <span>{{dispAgenda.agenda.payer_contact}}</span>
                              </span>
                              <ng-template #noPayerContact><em i18n>kontakt chybí</em></ng-template>,
                              <span *ngIf="dispAgenda.agenda.payer_phone; else noPayerPhone">
                                <a [href]="'tel:' + dispAgenda.agenda.payer_phone">{{dispAgenda.agenda.payer_phone}}</a>
                              </span>
                              <ng-template #noPayerPhone><em i18n>tel. chybí</em></ng-template>,
                              <span *ngIf="dispAgenda.agenda.payer_email; else noPayerEmail">
                                <a [href]="'mailto:' + dispAgenda.agenda.payer_email">{{dispAgenda.agenda.payer_email}}</a>
                              </span>
                              <ng-template #noPayerEmail><em i18n>email chybí</em></ng-template>
                            </div>

                            <div *ngIf="dispAgenda.agenda_itinerary?.length">
                              <div *ngFor="let it of dispAgenda.agenda_itinerary; let isLast=last;">
                                <div class="d-flex align-items-center mb-2">
                                  <div *ngIf="it.type != 'W' && it.type != 'V' && it.type != 'T'; else warehouseIcon" style="width: 30px">
                                    <img [src]="it.typeIcon()" alt="icon" width="25px" height="25px"/>
                                  </div>
                                  <ng-template #warehouseIcon>
                                    <div *ngIf="it.type == 'W' || it.type == 'V'" style="width: 30px">
                                      <img *ngIf="dispAgenda.shipmentFirstPart" src="assets/icons-new/collection.svg" 
                                      alt="icon" width="25px" height="25px"/>
                                      <img *ngIf="dispAgenda.shipmentSecondPart" src="assets/icons-new/delivery.svg" 
                                      alt="icon" width="25px" height="25px"/>
                                    </div>
                                    <div *ngIf="it.type == 'T'" style="width: 30px">
                                      <img *ngIf="dispAgenda.shipmentFirstPart" src="assets/icons-new/transship-unload.svg" 
                                      alt="icon" width="25px" height="25px"/>
                                      <img *ngIf="dispAgenda.shipmentSecondPart" src="assets/icons-new/transship-load.svg" 
                                      alt="icon" width="25px" height="25px"/>
                                    </div>
                                  </ng-template>
                                  <div>
                                    <div class="d-flex">
                                      <div>{{it.arrival_time_formatted}}</div>
                                      <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING" class="ml-2">
                                        {{it.weight ? it.weight : 0}}t,
                                        {{it.ware_pcs ? it.ware_pcs : 0}}{{it.ware_pcs && it.ware_type ? it.ware_type : 'ks'}}
                                        (Lze {{it.work_day_begin_formatted}}-{{it.work_day_end_formatted}})
                                      </div>
                                      <div>
                                        <img *ngIf="it.completed" class="ml-2" ngbTooltip="Dokončena" placement="auto"
                                        src="assets/icons-new/check-solid.svg" height="16px"/>
                                      </div>
                                    </div>
                                    <div class="text-truncate" style="max-width: 500px;">
                                      {{it.address}}
                                    </div>
                                    <div *ngIf="isLast">
                                      <ng-container i18n>Limit času na vykládku</ng-container>: 
                                      {{it.loading_time_limit}}min
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <b>
                                <ng-container i18n>Trasa</ng-container>: 
                                {{dispAgenda.agenda.route_length}}km
                              </b>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- time/distance difference -->
              <ng-container *ngIf="getDiffsByVehicle(vehicle).length">
                <ng-container *ngFor="let diffs of getDiffsByVehicle(vehicle)">
                  <div class="dispatcher-board-agenda-diffs text-center"
                  [style.left.px]="diffs.offsetLeft" 
                  [style.top.px]="diffs.offsetTop">
                    <div *ngIf="diffs.offsetDiff && diffs.offsetDiff >= 50">
                      <!-- time hh:mm -->
                      <h6>{{diffs.timeDiff}}h</h6>
                      <!-- distance in km -->
                      <h6 *ngIf="diffs.distanceDiff || diffs.distanceDiff == 0">
                        ~{{diffs.distanceDiff}}km
                      </h6>
                      <h6 *ngIf="!diffs.distanceDiff && diffs.distanceDiff != 0">?km</h6>
                    </div>
                    
                    <div *ngIf="diffs.offsetDiff && diffs.offsetDiff > 5 && diffs.offsetDiff < 50">
                      <span class="badge badge-pill badge-dark pointer px-1" 
                      style="margin-left: 7px; margin-top: 12px"
                      [ngbPopover]="popoverDiff" container="body" placement="bottom auto">
                        h/km
                      </span>
                      <ng-template #popoverDiff>
                        <!-- time hh:mm - distance in km -->
                        <span>
                          {{diffs.timeDiff}}h&nbsp;~{{diffs.distanceDiff}}km
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
    
          <!-- Right Arrow -->
          <!-- [style.lineHeight.px]="innerHeight" [style.height.px]="(outerHeight + 30)" -->
          <div class="move-forward" (click)="moveForward()" placement="left auto" [ngbTooltip]="moveForwardTitle"
          [style.height.px]="arrowColumnHeight - 20" [style.lineHeight.px]="innerHeight">
            <img src="assets/icons-new/navigate_right.svg" alt="navigate_right" height="18px"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- drag and drop div - tm-agenda itinerary -->
<div id="dragAndDropDiv" class="border border-primary rounded p-2" cdkDrag>
  <div class="d-flex justify-content-between mb-3">
    <div cdkDragHandle>
      <img src="assets/icons-new/arrow_cross.svg" height="24px"
      style="cursor: all-scroll;"/>
    </div>
    <div>
      <button class="btn btn-sm btn-outline-secondary p-1" style="width:23px; height:23px" 
      (click)="hideDragAndDropDiv()">
        X
      </button>
    </div>
  </div>
  <div *ngIf="lastCargoVehicle" style="overflow-y: auto; height: 370px;">
    <r-last-cargo-diary [vehicle]="lastCargoVehicle">
    </r-last-cargo-diary>
  </div>
</div>

<!-- LITE version -->
<div *ngIf="isAuthenticated && validAccess && !tariffOnlyAgenda && liteVersion" class="board-body">
  <h3 style="font-size:130px" i18n>Probíhá vývoj nové verze plachty</h3>
</div>

<!-- Demo info div  -->
<div *ngIf="isDemo" class="demo-intro border border-primary rounded shadow-lg p-2">
  <ng-container i18n>Zkuste například několik hodin průběžně sledovat</ng-container><br>
  <span i18n>na dynamické</span>&nbsp;<strong i18n>dispečerské plachtě</strong>
  &nbsp;<span i18n>průběh přeprav</span>:<br>
  <span style="background: #A3CFFF;">&nbsp; &nbsp;&nbsp;
  </span>&nbsp; <span i18n>Celovozová přeprava byla zahájena</span><br>
  <span style="background: #84d4c9;">&nbsp; &nbsp;&nbsp;
  </span>&nbsp; <span i18n>Sběrná přeprava - svoz</span><br>
  <span style="background: #00a88f;">&nbsp; &nbsp;&nbsp;
  </span>&nbsp; <span i18n>Sběrná přeprava - rozvoz</span><br>
  <span style="background: #FFBBC1;">&nbsp; &nbsp;&nbsp;
  </span>&nbsp; <span i18n>Stav K fakturaci - řidič označil poslední vykládku</span><br>
  <span style="background: #E5C4E6;">&nbsp; &nbsp;&nbsp;
  </span>&nbsp; <span i18n>Fakturováno - byla vystavena a odeslána faktura</span>
</div>


<!-- modal for confirmation of canceling (storno) of selected agenda -->
<div class="modal fade" role="dialog" tabindex="-1" id="cancelModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-danger shadow">
        <h5 class="modal-title" *ngIf="selectedAgenda?.agenda">
          <span i18n>Stornování zakázky</span> {{selectedAgenda.agenda.order_number}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="selectedAgenda?.agenda">
          <h6>
            <span i18n>Stav zakázky</span> 
            <b> {{selectedAgenda.agenda.order_number}} </b> 
            <span i18n>bude změněn na </span> 
            <b i18n>Stornovaná.</b>
          </h6>
          <h6 i18n>Stav zakázky lze kdykoliv změnit v listu zakázek.</h6>

          <div class="my-3">
            <div *ngIf="selectedAgenda.agenda.order_comp_book">
              <b>
                {{selectedAgenda.agenda.order_comp_book.company}},
                {{selectedAgenda.agenda.order_comp_book.city}},
                {{selectedAgenda.agenda.order_comp_book.country}}
              </b>
            </div>
            <div *ngIf="selectedAgenda.agenda.itinerary?.length">
              <div *ngFor="let it of selectedAgenda.agenda.itinerary">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <div>
                    <span *ngIf="it.type == ItineraryType.LOADING">
                      <img src="assets/icon/icon-car-state/loading.svg" alt="Nakládka" height="16px"/>
                    </span>
                    <span *ngIf="it.type == ItineraryType.UNLOADING">
                      <img src="assets/icon/icon-car-state/unloading.svg" alt="Vykládka" height="16px"/>
                    </span>
                    <span>{{it.arrival_time_formatted}} (lze {{it.work_day_begin_form_formatted}} - {{it.work_day_end_formatted}})</span>
                  </div>
                  <div>{{it.address}}</div>
                </div>
              </div>
            </div>
          </div>

          <h6 i18n>Pro potvrzení klikněte na tlačítko "Stornuj"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger" type="button" 
        (click)="setStatusStorno();" data-dismiss="modal">Stornuj</button>
        <button class="btn btn-light" type="button" data-dismiss="modal">Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for confirmation of updating number_plate and itinerary arrival_times of selected/moved/resized agenda -->
<div class="modal fade" role="dialog" tabindex="-1" id="updateModal" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" *ngIf="selectedAgenda?.agenda">
          <span i18n>Úprava zakázky</span> {{selectedAgenda.agenda.order_number}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="reinitSelectedAgenda()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="selectedAgenda?.agenda">
          <h6>
            <span i18n>Parametry zakázky</span> 
            <b>
              {{selectedAgenda.agenda.order_number}}
              <span *ngIf="selectedAgenda.agenda.order_comp_book">
                ({{selectedAgenda.agenda.order_comp_book.company}}, 
                {{selectedAgenda.agenda.order_comp_book.city}}, 
                {{selectedAgenda.agenda.order_comp_book.country}})
              </span>
            </b> 
            <span i18n>budou na základě vašeho přesunutí upraveny</span>.
          </h6>

          <h6 class="mt-3 text-secondary"><ng-container i18n>Původní</ng-container>:</h6>
          <div class="text-secondary">
            <div *ngIf="selectedAgenda.agenda.vehicle">
              <b>{{selectedAgenda.agenda.vehicle.driver_name}}, {{selectedAgenda.agenda.vehicle.number_plate}}</b>
            </div>
            <div *ngIf="selectedAgenda.agenda.itinerary?.length">
              <div *ngFor="let it of selectedAgenda.agenda.itinerary">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <span *ngIf="it.type == ItineraryType.LOADING">
                    <img src="assets/icon/icon-car-state/loading.svg" alt="Nakládka" height="16px"/>
                  </span>
                  <span *ngIf="it.type == ItineraryType.UNLOADING">
                    <img src="assets/icon/icon-car-state/unloading.svg" alt="Vykládka" height="16px"/>
                  </span>
                  <span>
                    {{it.arrival_time_formatted}} 
                    (<span i18n>lze</span> {{it.work_day_begin_formatted}} - {{it.work_day_end_formatted}})
                  </span>
                  <span class="ml-2">{{it.address}}</span>
                </div>
              </div>
            </div>
          </div>

          <h6 class="mt-3"><span i18n>Nové</span>:</h6>
          <div class="mb-3">
            <div *ngIf="movedVehicle">
              <b>{{movedVehicle.driver_name}}, {{movedVehicle.number_plate}}</b>
            </div>
            <div *ngIf="movedItinerary?.length">
              <div *ngFor="let it of movedItinerary">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <span *ngIf="it.type == ItineraryType.LOADING">
                    <img src="assets/icon/icon-car-state/loading.svg" alt="Nakládka" height="16px"/>
                  </span>
                  <span *ngIf="it.type == ItineraryType.UNLOADING">
                    <img src="assets/icon/icon-car-state/unloading.svg" alt="Vykládka" height="16px"/>
                  </span>
                  <span>
                    {{it.arrival_time_formatted}} 
                    (<span i18n>lze</span> {{it.work_day_begin_formatted}} - {{it.work_day_end_formatted}})
                  </span>
                  <span class="ml-2">{{it.address}}</span>
                </div>
              </div>
            </div>
          </div>

          <h6 i18n>Pro potvrzení klikněte na tlačítko "Potvrdit"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" (click)="updateMovedAgenda();" data-dismiss="modal">
          <ng-container i18n>Potvrdit</ng-container>
        </button>
        <button class="btn btn-light" type="button" data-dismiss="modal" 
        (click)="reinitSelectedAgenda()">
          <ng-container i18n>Zavřít</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal for confirmation of canceling (storno) of selected agenda -->
<div class="modal fade" role="dialog" tabindex="-1" id="statusModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" *ngIf="selectedAgenda?.agenda">
          <span i18n>Změna zakázky</span> {{selectedAgenda.agenda.order_number}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="selectedAgenda?.agenda">
          <h6>
            <span i18n>Stav zakázky</span> <b>{{selectedAgenda.agenda.order_number}}</b> 
            <span i18n>bude změněn na</span> <b i18n>K vyřízení.</b>
          </h6>
          <h6 i18n>Stav zakázky lze kdykoliv změnit v listu zakázek.</h6>

          <div class="my-3">
            <div *ngIf="selectedAgenda.agenda.order_comp_book">
              <b>
                {{selectedAgenda.agenda.order_comp_book.company}},
                {{selectedAgenda.agenda.order_comp_book.city}},
                {{selectedAgenda.agenda.order_comp_book.country}}
              </b>
            </div>
            <div *ngIf="selectedAgenda.agenda.itinerary?.length">
              <div *ngFor="let it of selectedAgenda.agenda.itinerary">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <div>
                    <span *ngIf="it.type == ItineraryType.LOADING">
                      <img src="assets/icon/icon-car-state/loading.svg" alt="Nakládka" height="16px"/>
                    </span>
                    <span *ngIf="it.type == ItineraryType.UNLOADING">
                      <img src="assets/icon/icon-car-state/unloading.svg" alt="Vykládka" height="16px"/>
                    </span>
                    <span>{{it.arrival_time_formatted}} (lze {{it.work_day_begin_formatted}} - {{it.work_day_end_formatted}})</span>
                  </div>
                  <div>{{it.address}}</div>
                </div>
              </div>
            </div>
          </div>

          <h6 i18n>Pro potvrzení klikněte na tlačítko "Potvrdit"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning" type="button" data-dismiss="modal" (click)="setStatusE()">
          <ng-container i18n>Potvrdit</ng-container>
        </button>
        <button class="btn btn-light" type="button" data-dismiss="modal">
          <ng-container i18n>Zavřít</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal for selecting obligations with status E (k vyrizeni) -->
<div class="modal fade" role="dialog" tabindex="-1" id="obligationsEModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title"i18n>Plánovat zakázky k vyřízení</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">x</span>
        </button>
      </div>
      <div class="modal-body">
        <h6 *ngIf="!obligationsStatusE.length" i18n>Nemáte žádné zakázky určené k vyřízení</h6>

        <ngb-accordion *ngIf="obligationsStatusE.length" #acc="ngbAccordion">
          <ngb-panel *ngFor="let obligation of obligationsStatusE" [id]="'toggle' + obligation.obligation_key">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="btn text-left w-100" (click)="obligationSuggest(obligation)">
                <div class="row normal-font-size pointer">
                  <div class="col-sm-9">
                    <div class="mb-1">
                      <strong>{{obligation.order_number_standard}}</strong>,
                      <strong class="text-nowrap">{{obligation.order_comp_name}}</strong>
                      <span *ngIf="obligation.weight">, {{obligation.weight}}t</span>
                      <span *ngIf="obligation.route_length">, {{obligation.route_length}}km</span>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <p class="text-nowrap text-right mb-1">
                      {{obligation.contracted_price | price}} {{obligation.currency}}
                    </p>
                  </div>
                  <div *ngIf="obligation.itinerary.length" class="col-sm-12">
                    <div *ngFor="let it of obligation.itinerary" [class.text-danger]="it.inPast">
                      <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                        <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>
      
                        <span *ngIf="it.arrival_time" class="text-nowrap">
                          {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                        <span class="mb-1">-&nbsp;{{it.address}}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="obligation.isOutOfInterval" class="col-sm-12 text-danger mt-1">
                    <strong i18n>POZOR! Tato zakázka je mimo zobrazený interval na plachtě.</strong>
                  </div>
                </div>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <table class="table table-striped table-hover">
                <tbody>
                  <tr *ngFor="let v of vehiclesSuggested">
                    <td class="align-middle py-1">
                      <span ngbTooltip="Adresa poslední plánované vykládky" container="body" placement="auto">
                        {{v.last_itinerary_address}}
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <span ngbTooltip="Vzdálenost od poslední plánované vykládky" container="body" placement="auto">
                        ~{{v.distance}}km
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <span ngbTooltip="Čas poslední plánované vykládky" container="body" placement="auto">
                        {{v.last_itinerary_time | date: 'dd.MM.yyyy HH:mm'}}
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <b>{{v.vehicle.number_plate}}</b>
                    </td>
                    <td class="align-middle py-1">
                      <span *ngIf="v.vehicle.driver_key">{{v.vehicle.driver_name}}</span>
                    </td>
                    <td class="text-right py-1">
                      <button class="btn btn-primary px-2 py-1" type="button" (click)="setSelectedVehicle(v.vehicle)"
                      data-dismiss="modal" data-toggle="modal" data-target="#updateObligationEModal">
                        <span i18n>Zadat originální čas</span>
                      </button>
                    </td>
                    <td class="text-right py-1">
                      <button class="btn btn-success px-2 py-1" type="button" (click)="setSelectedVehicle(v.vehicle, true)"
                      data-dismiss="modal" data-toggle="modal" data-target="#updateObligationEModalOptimal">
                        <span i18n>Zadat optimální čas</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for confirmation of updating number_plate from obligationsEModal -->
<div class="modal fade" role="dialog" tabindex="-1" id="updateObligationEModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" *ngIf="obligationSelected">
          <span i18n>Úprava zakázky</span> {{obligationSelected.order_number_standard}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="obligationSelected && vehicleSelected">
          <h6 class="mb-3">
            <span i18n>Zakázka</span>
            <b>{{obligationSelected.order_number_standard}}, {{obligationSelected.order_comp_name}}</b> 
            <span i18n>bude zahájena a bude přiřazena k vozidlu</span> 
            <b>{{vehicleSelected.number_plate}}</b>
          </h6>

          <div class="row normal-font-size">
            <div class="col-sm-12">
              <div>
                <strong>{{obligationSelected.order_number_standard}}</strong>,
                <strong class="text-nowrap">{{obligationSelected.order_comp_name}}</strong>
                <span *ngIf="obligationSelected.weight">, {{obligationSelected.weight}}t</span>
                <span *ngIf="obligationSelected.route_length">, {{obligationSelected.route_length}}km</span>
                <span *ngIf="obligationSelected.contracted_price">, 
                  {{obligationSelected.contracted_price | price}} {{obligationSelected.currency}}
                </span>
              </div>
              <div>
                <strong><span i18n>Vozidlo</span>: 
                  {{vehicleSelected.number_plate}}, {{vehicleSelected.driver_name}}
                </strong>
              </div>
              <div class="mb-1">
                <strong i18n>Stav: zahájená</strong>
              </div>
            </div>
            <div *ngIf="obligationSelected.itinerary?.length" class="col-sm-12">
              <div *ngFor="let it of obligationSelected.itinerary" [class.text-danger]="it.inPast">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>

                  <span *ngIf="it.arrival_time" class="text-nowrap">
                    {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                  </span>
                  <span class="text-nowrap mb-1">-&nbsp;{{it.address}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="obligationSelected.isOutOfInterval" class="col-sm-12 text-danger mt-1">
              <strong i18n>POZOR! Tato zakázka je mimo zobrazený interval na plachtě.</strong>
            </div>
          </div>

          <h6 class="mt-3" i18n>Pro potvrzení klikněte na tlačítko "Potvrdit"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" 
        (click)="updateSelectedObligation();" data-dismiss="modal" i18n>Potvrdit</button>
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for confirmation of updating number_plate from obligationsEModal using optimal suggestion of times -->
<div class="modal fade" role="dialog" tabindex="-1" id="updateObligationEModalOptimal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" *ngIf="obligationSelected">
          <span i18n>Úprava zakázky</span> {{obligationSelected.order_number_standard}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="obligationSelected && vehicleSelected">
          <h6>
            <span i18n>Zakázka</span>
            <b>{{obligationSelected.order_number_standard}}, {{obligationSelected.order_comp_name}}</b> 
            <span i18n>bude zahájena a bude přiřazena k vozidlu</span> 
            <b>{{vehicleSelected.number_plate}}</b>
          </h6>
          <h6 class="mb-3">
            <span i18n>Časy nakládek a vykládek budou změněny na základě návrhu 
              podle času poslední vykládky vozidla
            </span>
            <b>{{vehicleSelected.number_plate}}</b>
          </h6>

          <div class="row normal-font-size">
            <div class="col-sm-12">
              <div>
                <strong>{{obligationSelected.order_number_standard}}</strong>,
                <strong class="text-nowrap">{{obligationSelected.order_comp_name}}</strong>
                <span *ngIf="obligationSelected.weight">, {{obligationSelected.weight}}t</span>
                <span *ngIf="obligationSelected.route_length">, {{obligationSelected.route_length}}km</span>
                <span *ngIf="obligationSelected.contracted_price">, 
                  {{obligationSelected.contracted_price | price}} {{obligationSelected.currency}}
                </span>
              </div>
              <div>
                <strong><span i18n>Vozidlo</span>: 
                  {{vehicleSelected.number_plate}}, {{vehicleSelected.driver_name}}
                </strong>
              </div>
              <div class="mb-1">
                <strong><span i18n>Stav</span>: <span i18n>zahájená</span></strong>
              </div>
            </div>
            <div *ngIf="obligationSelected.itinerary?.length" class="col-sm-12">
              <div *ngFor="let it of obligationSelected.itinerary" [class.text-danger]="it.inPast">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>

                  <span *ngIf="it.arrival_time" class="text-nowrap">
                    {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                  </span>
                  <span class="text-nowrap mb-1">-&nbsp;{{it.address}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="obligationSelected.isOutOfInterval" class="col-sm-12 text-danger mt-1">
              <strong i18n>POZOR! Tato zakázka je mimo zobrazený interval na plachtě.</strong>
            </div>
          </div>

          <h6 class="mt-3" i18n>Pro potvrzení klikněte na tlačítko "Potvrdit"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" 
        (click)="updateSelectedObligation(true);" data-dismiss="modal" i18n>Potvrdit</button>
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for selecting express routes with status E (k vyrizeni) -->
<div class="modal fade" role="dialog" tabindex="-1" id="expressRoutesEModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" i18n>Plánovat dokládkové trasy k vyřízení</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">x</span>
        </button>
      </div>
      <div class="modal-body">
        <h6 *ngIf="!expressRoutesStatusE.length" i18n>Nemáte žádné trasy určené k vyřízení</h6>

        <ngb-accordion *ngIf="expressRoutesStatusE.length" #acc="ngbAccordion">
          <ngb-panel *ngFor="let e of expressRoutesStatusE" [id]="'toggle' + e.express_key">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="btn text-left w-100" (click)="expressRouteSuggest(e)">
                <div class="row normal-font-size pointer">
                  <div class="col-sm-12">
                    <div class="mb-1">
                      <strong>TR{{e.order_number_standard}}</strong>
                      <!-- <strong class="text-nowrap">{{obligation.order_comp_name}}</strong>
                      <span *ngIf="obligation.weight">, {{obligation.weight}}t</span>
                      <span *ngIf="obligation.route_length">, {{obligation.route_length}}km</span> -->
                    </div>
                  </div>
                  <div *ngIf="e.itineraryOrdered?.length" class="col-sm-12">
                    <div *ngFor="let it of e.itineraryOrdered" [class.text-danger]="it.inPast">
                      <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                        <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>
      
                        <span *ngIf="it.arrival_time" class="text-nowrap">
                          {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                        <span class="mb-1">-&nbsp;{{it.address}}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="e.isOutOfInterval" class="col-sm-12 text-danger mt-1">
                    <strong i18n>POZOR! Tato zakázka je mimo zobrazený interval na plachtě.</strong>
                  </div>
                </div>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <table *ngIf="e.itineraryOrdered?.length" class="table table-striped table-hover">
                <tbody>
                  <tr *ngFor="let v of vehiclesSuggested">
                    <td class="align-middle py-1">
                      <span ngbTooltip="Adresa poslední plánované vykládky" container="body" placement="auto">
                        {{v.last_itinerary_address}}
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <span ngbTooltip="Vzdálenost od poslední plánované vykládky" container="body" placement="auto">
                        ~{{v.distance}}km
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <span ngbTooltip="Čas poslední plánované vykládky" container="body" placement="auto">
                        {{v.last_itinerary_time | date: 'dd.MM.yyyy HH:mm'}}
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <b>{{v.vehicle.number_plate}}</b>
                    </td>
                    <td class="align-middle py-1">
                      <span *ngIf="v.vehicle.driver_key">{{v.vehicle.driver_name}}</span>
                    </td>
                    <td class="text-right py-1">
                      <button class="btn btn-primary px-2 py-1" type="button" 
                      (click)="setSelectedVehicleExpressRoute(v.vehicle)" data-dismiss="modal" 
                      data-toggle="modal" data-target="#updateExpressRouteEModal">
                        <ng-container i18n>Zadat originální čas</ng-container>
                      </button>
                    </td>
                    <td class="text-right py-1">
                      <button class="btn btn-success px-2 py-1" type="button" 
                      (click)="setSelectedVehicleExpressRoute(v.vehicle, true)" data-dismiss="modal"
                      data-toggle="modal" data-target="#updateExpressRouteEModalOptimal">
                        <ng-container i18n>Zadat optimální čas</ng-container>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for confirmation of updating number_plate from expressRoutesEModal -->
<div class="modal fade" role="dialog" tabindex="-1" id="updateExpressRouteEModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" *ngIf="expressRouteSelected">
          <span i18n>Úprava dokládkové trasy</span> {{expressRouteSelected.order_number_standard}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="expressRouteSelected && vehicleSelected">
          <h6 class="mb-3">
            <span i18n>Trasa</span> <b>{{expressRouteSelected.order_number_standard}}</b> 
            <span i18n>bude zahájena a bude přiřazena k vozidlu</span> 
            <b>{{vehicleSelected.number_plate}}</b>
          </h6>

          <div class="row normal-font-size">
            <div class="col-sm-12">
              <div>
                <strong>TR{{expressRouteSelected.order_number_standard}}</strong>,
                <!-- <strong class="text-nowrap">{{obligationSelected.order_comp_name}}</strong>
                <span *ngIf="obligationSelected.weight">, {{obligationSelected.weight}}t</span>
                <span *ngIf="obligationSelected.route_length">, {{obligationSelected.route_length}}km</span>
                <span *ngIf="obligationSelected.contracted_price">, 
                  {{obligationSelected.contracted_price | price}} {{obligationSelected.currency}}
                </span> -->
              </div>
              <div>
                <strong><span i18n>Vozidlo</span>: 
                  {{vehicleSelected.number_plate}}, {{vehicleSelected.driver_name}}
                </strong>
              </div>
              <div class="mb-1">
                <strong i18n>Stav: zahájená</strong>
              </div>
            </div>
            <div *ngIf="expressRouteSelected.itineraryOrdered?.length" class="col-sm-12">
              <div *ngFor="let it of expressRouteSelected.itineraryOrdered" [class.text-danger]="it.inPast">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>

                  <span *ngIf="it.arrival_time" class="text-nowrap">
                    {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                  </span>
                  <span class="text-nowrap mb-1">-&nbsp;{{it.address}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="expressRouteSelected.isOutOfInterval" class="col-sm-12 text-danger mt-1">
              <strong i18n>POZOR! Tato zakázka je mimo zobrazený interval na plachtě.</strong>
            </div>
          </div>

          <h6 class="mt-3" i18n>Pro potvrzení klikněte na tlačítko "Potvrdit"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" 
        (click)="updateSelectedExpressRoute();" data-dismiss="modal" i18n>Potvrdit</button>
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for confirmation of updating number_plate from expressRoutesEModal using optimal suggestion of times -->
<div class="modal fade" role="dialog" tabindex="-1" id="updateExpressRouteEModalOptimal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" *ngIf="expressRouteSelected">
          <span i18n>Úprava trasy</span> {{expressRouteSelected.order_number_standard}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="expressRouteSelected && vehicleSelected">
          <h6>
            <span i18n>Trasa</span> <b>{{expressRouteSelected.order_number_standard}}</b> 
            <span i18n>bude zahájena a bude přiřazena k vozidlu</span>
            <b>{{vehicleSelected.number_plate}}</b>
          </h6>
          <h6 class="mb-3">
            <span i18n>Časy nakládek a vykládek budou změněny na základě návrhu 
              podle času poslední vykládky vozidla
            </span>
            <b>{{vehicleSelected.number_plate}}</b>
          </h6>

          <div class="row normal-font-size">
            <div class="col-sm-12">
              <div>
                <strong>TR{{expressRouteSelected.order_number_standard}}</strong>,
                <!-- <strong class="text-nowrap">{{obligationSelected.order_comp_name}}</strong>
                <span *ngIf="obligationSelected.weight">, {{obligationSelected.weight}}t</span>
                <span *ngIf="obligationSelected.route_length">, {{obligationSelected.route_length}}km</span>
                <span *ngIf="obligationSelected.contracted_price">, 
                  {{obligationSelected.contracted_price | price}} {{obligationSelected.currency}}
                </span> -->
              </div>
              <div>
                <strong><span i18n>Vozidlo</span>: 
                  {{vehicleSelected.number_plate}}, {{vehicleSelected.driver_name}}
                </strong>
              </div>
              <div class="mb-1">
                <strong i18n>Stav: zahájená</strong>
              </div>
            </div>
            <div *ngIf="expressRouteSelected.itineraryOrdered?.length" class="col-sm-12">
              <div *ngFor="let it of expressRouteSelected.itineraryOrdered" [class.text-danger]="it.inPast">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>

                  <span *ngIf="it.arrival_time" class="text-nowrap">
                    {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                  </span>
                  <span class="text-nowrap mb-1">-&nbsp;{{it.address}}</span>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="expressRouteSelected.isOutOfInterval" class="col-sm-12 text-danger mt-1">
              <strong>POZOR! Tato zakázka je mimo zobrazený interval na plachtě.</strong>
            </div> -->
          </div>

          <h6 class="mt-3" i18n>Pro potvrzení klikněte na tlačítko "Potvrdit"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" 
        (click)="updateSelectedExpressRoute(true);" data-dismiss="modal" i18n>Potvrdit</button>
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for selecting favourite obligations -->
<div class="modal fade" role="dialog" tabindex="-1" id="obligationsFavouriteModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" i18n>Duplikovat oblíbené zakázky</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h6 *ngIf="!obligationsFavourite.length" i18n>Nemáte žádné zakázky oblíbené zakázky</h6>

        <ngb-accordion *ngIf="obligationsFavourite.length" #acc="ngbAccordion">
          <ngb-panel *ngFor="let obligation of obligationsFavourite" [id]="'toggle' + obligation.obligation_key">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="btn text-left w-100" (click)="obligationSuggest(obligation)">
                <div class="row normal-font-size pointer">
                  <div class="col-sm-9">
                    <div class="mb-1">
                      <strong>{{obligation.order_number_standard}}</strong>,
                      <strong class="text-nowrap">{{obligation.order_comp_name}}</strong>
                      <span *ngIf="obligation.weight">, {{obligation.weight}}t</span>
                      <span *ngIf="obligation.route_length">, {{obligation.route_length}}km</span>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <p class="text-nowrap text-right mb-1">
                      {{obligation.contracted_price | price}} {{obligation.currency}}
                    </p>
                  </div>
                  <div *ngIf="obligation.itinerary.length" class="col-sm-12">
                    <div *ngFor="let it of obligation.itinerary">
                      <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                        <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>
                        <span class="mb-1">{{it.address}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <table class="table table-striped table-hover">
                <tbody>
                  <tr *ngFor="let v of vehiclesSuggested">
                    <td class="align-middle py-1">
                      <span ngbTooltip="Adresa poslední plánované vykládky" container="body" placement="auto">
                        {{v.last_itinerary_address}}
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <span ngbTooltip="Vzdálenost od poslední plánované vykládky" container="body" placement="auto">
                        ~{{v.distance}}km
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <span ngbTooltip="Čas poslední plánované vykládky" container="body" placement="auto">
                        {{v.last_itinerary_time | date: 'dd.MM.yyyy HH:mm'}}
                      </span>
                    </td>
                    <td class="align-middle py-1">
                      <b>{{v.vehicle.number_plate}}</b>
                    </td>
                    <td class="align-middle py-1">
                      <span *ngIf="v.vehicle.driver_key">{{v.vehicle.driver_name}}</span>
                    </td>
                    <td class="text-right py-1">
                      <button class="btn btn-success py-1" type="button" (click)="setObligationDuplicate(v.vehicle)"
                      data-dismiss="modal" data-toggle="modal" data-target="#updateObligationFavouriteModal">
                        <ng-container i18n>Duplikovat a zadat optimální čas</ng-container>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for confirmation of creating duplicate of selected favourite obligation -->
<div class="modal fade" role="dialog" tabindex="-1" id="updateObligationFavouriteModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" *ngIf="obligationSelected">
          <span i18n>Duplikovat oblíbenou zakázku</span> {{obligationSelected.order_number_standard}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="obligationSelected && obligationDuplicate && vehicleSelected">
          <h6 class="mb-3">
            <span i18n>Bude provedena duplikace oblíbené zakázky</span> 
            <b>{{obligationSelected.order_number_standard}}, {{obligationSelected.order_comp_name}}</b> 
            <span i18n>a bude přiřazena k vozidlu</span> <b>{{vehicleSelected.number_plate}}</b>
          </h6>

          <div class="row normal-font-size">
            <div class="col-sm-12">
              <div>
                <strong class="text-nowrap">{{obligationDuplicate.order_comp_name}}</strong>
                <span *ngIf="obligationDuplicate.weight">, {{obligationDuplicate.weight}}t</span>
                <span *ngIf="obligationDuplicate.route_length">, {{obligationDuplicate.route_length}}km</span>
                <span *ngIf="obligationDuplicate.contracted_price">, 
                  {{obligationDuplicate.contracted_price | price}} {{obligationDuplicate.currency}}
                </span>
              </div>
              <div>
                <strong>
                  <ng-container i18n>Vozidlo</ng-container>: 
                  {{vehicleSelected.number_plate}}, {{vehicleSelected.driver_name}}
                </strong>
              </div>
              <div class="mb-1">
                <strong i18n>Stav: zahájená</strong>
              </div>
            </div>
            <div *ngIf="obligationDuplicate.itinerary?.length" class="col-sm-12">
              <div *ngFor="let it of obligationDuplicate.itinerary" [class.text-danger]="it.inPast">
                <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
                  <img class="d-inline-block mr-1" [src]="it.typeIcon()" height="18px"/>
  
                  <span *ngIf="it.arrival_time" class="text-nowrap">
                    {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                  </span>
                  <span class="text-nowrap mb-1">-&nbsp;{{it.address}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="obligationDuplicate.isOutOfInterval" class="col-sm-12 text-danger mt-1">
              <strong i18n>POZOR! Tato zakázka je mimo zobrazený interval na plachtě.</strong>
            </div>
          </div>

          <h6 class="mt-3" i18n>Pro potvrzení klikněte na tlačítko "Potvrdit"</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" 
        (click)="duplicateSelectedObligation();" data-dismiss="modal">
          <ng-container i18n>Potvrdit</ng-container>
        </button>
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for marking vehicles to be shown -->
<div class="modal fade" role="dialog" tabindex="-1" id="vehicleFilterModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" i18n>Výběr vozidel dle SPZ či dispečera</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <app-vehicle-filter></app-vehicle-filter>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- modal for timocom  from last to home-->
<div class="modal fade" role="dialog" tabindex="-1" id="timocomModalBackHome">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title">
          <div *ngIf="agendaToTimocomBackHome">
            <span i18n>Náklady pro</span> 
            {{agendaToTimocomBackHome.vehicle ? agendaToTimocomBackHome.vehicle.number_plate : ''}}.
            <span i18n>Z</span>
            {{agendaToTimocomBackHome.last_itinerary ? agendaToTimocomBackHome.last_itinerary.place_city : ''}}
            <span i18n>do</span>
            {{agendaToTimocomBackHome.vehicle?.home_stand ? agendaToTimocomBackHome.vehicle.home_stand.name : ''}}
          </div>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="saveCokkie()">×</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="agendaToTimocomBackHome">
          <app-timocom [agenda]="agendaToTimocomBackHome"
                       [company]="company"
                       [onTheWay]="timocomOnTheWay" 
                       [backHome]="timocomBackHome"
                       [reload]="reloadTimocomBackHome"
                       [serchrangeStartOnWay]="startToFinalStart"
                       [serchrangeEndOnWay]="startToFinalEnd"
                       [serchrangeStartHome]="finalToHomeStart"
                       [serchrangeEndHome]="finalToHomeEnd"
                       ></app-timocom>
        </ng-container>
      </div>
      <div class="modal-footer">
        <div *ngIf="agendaToTimocomBackHome" class="row w-100">
        
          <div class="col-sm-4">
            <label>
              Max. přejezd z
            </label>
            <input class="form-control" max="200" type="number" 
            [(ngModel)]="finalToHomeStart" (ngModelChange)="saveCokkie()"/>
          </div>
          <div class="col-sm-4">
            <label>
              Max. přejezd do
            </label>
            <input class="form-control" type="number" 
            [(ngModel)]="finalToHomeEnd" max="200" (ngModelChange)="saveCokkie()"/>
          </div>
          <div class="col-sm-2">
            <button class="btn btn-primary w-100 h-100" type="button" 
            (click)="performReloadTimocomBackHome();" i18n>Vyhledat</button>
          </div>
          <div class="col-sm-2">
            <button class="btn btn-light w-100 h-100" type="button" data-dismiss="modal" i18n>Zavřít</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- modal for timocom  from first to last-->
<div class="modal fade" role="dialog" tabindex="-1" id="timocomModalOnTheWay">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title">
          <div *ngIf="agendaToTimocomOnTheWay">
            <span i18n>Náklady pro </span> 
            {{agendaToTimocomOnTheWay.vehicle ? agendaToTimocomOnTheWay.vehicle.number_plate : ''}}.
            <span i18n>Z</span>
            {{agendaToTimocomOnTheWay.first_itinerary ? agendaToTimocomOnTheWay.first_itinerary.place_city : ''}}
            <span i18n>do</span>
            {{agendaToTimocomOnTheWay.last_itinerary ? agendaToTimocomOnTheWay.last_itinerary.place_city : ''}}
          </div>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="saveCokkie()">×</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="agendaToTimocomOnTheWay">
          <app-timocom 
            [agenda]="agendaToTimocomOnTheWay" 
            [company]="company" 
            [onTheWay]="timocomOnTheWay" 
            [backHome]="timocomBackHome"
            [reload]="reloadTimocomOnTheWay"
            [serchrangeStartOnWay]="startToFinalStart"
            [serchrangeEndOnWay]="startToFinalEnd"
            [serchrangeStartHome]="finalToHomeStart"
            [serchrangeEndHome]="finalToHomeEnd">
          </app-timocom>
        </ng-container>
      </div>
      <div class="modal-footer">
        <div *ngIf="agendaToTimocomOnTheWay" class="row w-100">
          <div class="col-sm-4">
            <label>
              Max. přejezd z
              {{agendaToTimocomOnTheWay.first_itinerary ? agendaToTimocomOnTheWay.first_itinerary.place_city : ''}}
            </label>
            <input class="form-control" type="number" 
            [(ngModel)]="startToFinalStart" (ngModelChange)="saveCokkie()"/>
          </div>
          <div class="col-sm-4">
            <label>
              Max. přejezd do
              {{agendaToTimocomOnTheWay.last_itinerary ? agendaToTimocomOnTheWay.last_itinerary.place_city : ''}}
            </label>
            <input class="form-control" type="number" 
            [(ngModel)]="startToFinalEnd"  (ngModelChange)="saveCokkie()"/>
          </div>
          <div class="col-sm-2">
            <button class="btn btn-primary w-100 h-100" type="button" 
            (click)="performReloadTimocomOnTheWay();" i18n>Vyhledat</button>
          </div>
          <div class="col-sm-2">
            <button class="btn btn-light w-100 h-100" type="button" data-dismiss="modal"  i18n>Zavřít</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>