import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.css']
})
export class NoPermissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
