import { ModuleWithProviders, NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { LeftTmComponent } from './components/left-tm/left-tm.component';
import { RDispatcherBoardComponent } from './components/r-dispatcher-board/r-dispatcher-board.component';
import { RItineraryDiaryComponent } from './components/r-itinerary-diary/r-itinerary-diary.component';
import { RMessagesHistoryComponent } from './components/r-messages-history/r-messages-history.component';
import { RMessagesNewComponent } from './components/r-messages-new/r-messages-new.component';
import { RVehicleAllInOneMapComponent } from './components/r-vehicle-all-in-one-map/r-vehicle-all-in-one-map.component';
import { RVehicleDispositionsConfigurationComponent } from './components/r-vehicle-dispositions-configuration/r-vehicle-dispositions-configuration.component';
import { RVehicleListComponent } from './components/r-vehicle-list/r-vehicle-list.component';
import { RVehicleManualTrackingConfigurationComponent } from './components/r-vehicle-manual-tracking-configuration/r-vehicle-manual-tracking-configuration.component';
import { RVehicleGridComponent } from "./components/r-vehicle-grid/r-vehicle-grid.component";
import { RItObligationDiaryComponent } from "./components/r-it-obligation-diary/r-it-obligation-diary.component";
import { RUserConfigurationListComponent } from './components/r-user-configuration-list/r-user-configuration-list.component';


export const appRoutes: Routes = [
    { path: '', component: RVehicleListComponent, outlet: 'right' },
    { path: '', component: LeftTmComponent, outlet: 'left' },
    { path: 'auto-login', component: AutoLoginComponent, outlet: 'left' },
    { path: 'all-in-one-map', component: RVehicleAllInOneMapComponent, outlet: 'left' },
    { path: 'dispatcher-board', component: RDispatcherBoardComponent, outlet: 'left' },
    { path: 'vehicle-grid', component: RVehicleGridComponent, outlet: 'left' },
    { path: 'messages-read', component: RMessagesHistoryComponent, outlet: 'left' },
    { path: 'messages-new', component: RMessagesNewComponent, outlet: 'right' },
    { path: 'cars', component: RVehicleListComponent, outlet: 'right' },
    { path: 'itinerary-dairy', component: RItineraryDiaryComponent, outlet: 'right' },
    { path: 'it-obligation-diary', component: RItObligationDiaryComponent, outlet: 'right' },
    { path: 'user-settings', component: RUserConfigurationListComponent, outlet: 'right' },
    {
        path: 'dispositions-full/:vehicleId', 
        component: RVehicleDispositionsConfigurationComponent, 
        outlet: 'left'
    },
    {
        path: 'manual-tracking-full/:vehicleId', 
        component: RVehicleManualTrackingConfigurationComponent, 
        outlet: 'left'
    },
    {
        path: 'manual-tracking-full/:vehicleId/:coordinates', 
        component: RVehicleManualTrackingConfigurationComponent, 
        outlet: 'left'
    },
    { 
        path: 'general', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-general/components-module-general.module').then(m => m.ComponentsModuleGeneralModule) 
    },
    { 
        path: 'full', 
        loadChildren: () => import('./components-module-full/components-module-full.module').then(m => m.ComponentsModuleFullModule) 
    },
    { 
        path: 'ta1-obligation', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-ta1-obligation/components-module-ta1-obligation.module').then(m => m.ComponentsModuleTa1ObligationModule) 
    },
    { 
        path: 'ta1-order', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-ta1-order/components-module-ta1-order.module').then(m => m.ComponentsModuleTa1OrderModule) 
    },
    { 
        path: 'ta1-other', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-ta1-other/components-module-ta1-other.module').then(m => m.ComponentsModuleTa1OtherModule) 
    },
    { 
        path: 'ta2', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-ta2/components-module-ta2.module').then(m => m.ComponentsModuleTa2Module) 
    },
    { 
        path: 'ta3', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-ta3/components-module-ta3.module').then(m => m.ComponentsModuleTa3Module) 
    },
    { 
        path: 'ta4', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-ta4/components-module-ta4.module').then(m => m.ComponentsModuleTa4Module) 
    },
    { 
        path: 'tm', 
        outlet: 'left', 
        loadChildren: () => import('./components-module-tm/components-module-tm.module').then(m => m.ComponentsModuleTmModule) 
    }
];


@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })], 
    exports: [RouterModule]
})
export class AppRoutingModule { }


// { path: 'config-in-right', component: RUserConfigurationListComponent, outlet: 'left' },
// { path: 'locale-settings', component: RLocaleSettingsComponent, outlet: 'right' },
// {path: 'contact', component: LeftContactComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'profile', component: LeftProfileComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'subscription', component: LeftSubscriptionComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'mycars', component: TmMycarsComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'services', component: TmServicesComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'carbook', component: TmCarbookComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'fueling_stats', component: Ta1FuelingStatsComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'company_obligation', component: Ta1CompanyObligationComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'company_order', component: Ta1CompanyOrderComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'obligation_list', component: Ta1ObligationListComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'order_list', component: Ta1OrderListComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'obligation_sales', component: Ta1ObligationSalesComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'warehouse-plan', component: Ta1WarehousePlanComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'warehouse-control', component: Ta1WarehouseControlComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'emission-calc', component: Ta1EmissionCalcComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'express_list', component: Ta1ExpressListComponent, outlet: 'left'},
// {path: 'express_plan', component: Ta1ExpressPlanComponent, outlet: 'left'},
// {path: 'invoice_condition', component: Ta3InvoiceConditionComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'invoice_in_condition', component: Ta3InvoiceInConditionComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'invoice_list', component: Ta3InvoiceListComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'gen_for_invoicing', component: Ta3GenForInvoicingComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'palette', component: Ta3PaletteComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'invoice_export', component: Ta3InvoiceExportComponent, outlet: 'left'},
// {path: 'payment_bank', component: Ta3PaymentsComponent, outlet: 'left'},
// {path: 'reminders', component: Ta3RemindersComponent, outlet: 'left'},
// {path: 'fueling', component: Ta3ImportFuelingComponent, outlet: 'left'},
// {path: 'search', component: Ta2SearchComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'company_new', component: Ta2CompanyNewComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'company_import', component: Ta2CompanyImportComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'code_series_obligation', component: Ta4CodeSeriesObligationComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'code_overheads', component: Ta4CodeOverheadsComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'code_invoice_param', component: Ta4CodeInvoiceParamComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'code_order_param', component: Ta4CodeOrderParamComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'code_reminders_param', component: Ta4CodeRemindersParamComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'code_often_used_pos', component: Ta4CodeOftenUsedPosComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'delivery_lines', component: Ta4DeliveryLinesComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'user_edit', component: Ta4UserEditComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'cars-grid', component: RVehicleGridComponent, outlet: 'right'},
// {path: 'code_book_note', component: Ta4CodeBookNoteComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'code_stock', component: Ta4CodeStockComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'info', component: IframeInfoComponent, outlet: 'left'}, obsolete can be removed
// {path: 'tm',component:IframeTmComponent, outlet : 'left'}, -- uz zrejme nebude potreba <=> historie
// {path: 'code_invoice_item', component: Ta4CodeInvoiceItemComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'driver_edit', component: Ta4DriverEditComponent, outlet: 'left', runGuardsAndResolvers: 'always'},
// {path: 'mycars_edit', component: TmMycarsEditComponent, outlet: 'left'}, obsolete can be removed

// export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'});