export class ArrayCompare {
  public static haveSameContent(array1: Array<any>, array2: Array<any>): boolean {
    if (!array1 || !array2 || (array1.length !== array2.length)) {
      return false;
    }
    let hits = 0, lastHit = hits;
    array1.forEach(
      value => {
        array2.forEach(
          value2 => {
            if (value === value2) {
              hits++;
              return false;
            }
          }
        );
      }
    );
    return hits === array1.length;
  }
}
