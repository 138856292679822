<div *ngIf="modalMode" class="row text-left">
  <div class="col-12 mb-3">
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Stav nádrže</ng-container>:</div>
      <div>{{vehicle.tank_level ? vehicle.tank_level : vehicle.fuelPercentage}}%</div>
    </div>
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Dojezd</ng-container>:</div>
      <div>{{vehicle.driveRange}}</div>
    </div>
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Do plné</ng-container>:</div>
      <div>{{vehicle.lastRefuelingInfo}}</div>
    </div>
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Průměrná spotřeba</ng-container>:</div>
      <div>{{vehicle.consumptionInfo}}</div>
    </div>
  </div>
  
  <div class="col-12 mb-3">
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Baterie GPS jednotky</ng-container>:</div>
      <div>{{vehicle.trackingDeviceBatteryChargeLevelFormatted}}</div>
    </div>
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Běh GPS jednotky</ng-container>:</div>
      <div>{{vehicle.trackingDeviceRuntimeFormatted}}</div>
    </div>
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Běh aplikace TM</ng-container>:</div>
      <div>{{vehicle.trackingTmRuntimeFormatted}}</div>
    </div>
  </div>
  
  <div class="col-12">
    <div class="d-flex font-weight-bold">
      <div class="w-50"><ng-container i18n>Vytížení vozidla</ng-container>:</div>
      <div>{{vehicle.tonnage_percentage}}%</div>
    </div>
  </div>
</div>

<!-- <ng-container *ngIf="!modalMode">
  <div style="margin:auto" [style.max-width.px]="width" [style.max-height.px]="width"
  [ngbTooltip]="activeTooltip" placement="right auto">
    <canvas #statusChart [attr.width]="width + 'px'"></canvas>
  </div>
  
  <ng-template #activeTooltip >
    <div class="text-nowrap">
      <ng-container *ngIf="activeHover === 'fuel'">
        Stav nádrže {{vehicle.tank_level ? vehicle.tank_level : vehicle.fuelPercentage}}%<br/>
        Dojezd {{vehicle.driveRange}}<br/>
        Do plné {{vehicle.lastRefuelingInfo}}<br/>
        Průměrná spotřeba {{vehicle.consumptionInfo}}
      </ng-container>
      <ng-container *ngIf="activeHover === 'mobile'">
        Baterie GPS jednotky: {{vehicle.trackingDeviceBatteryChargeLevelFormatted}}<br/>
        Běh GPS jednotky: {{vehicle.trackingDeviceRuntimeFormatted}}<br/>
        Běh aplikace TM: {{vehicle.trackingTmRuntimeFormatted}}
      </ng-container>
      <ng-container *ngIf="activeHover === 'load'">
        Vytížení vozidla: {{vehicle.tonnage_percentage}}%
      </ng-container>
    </div>
  </ng-template>
</ng-container> -->

<!-- <canvas baseChart
    [attr.width]="width + 'px'"
    [datasets]="dataSets"
    [labels]="labels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType">
  </canvas> -->