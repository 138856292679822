export class DrivingExclusion {
  constructor() {
  }

  private _excl_id: number;
  public get excl_id(): number {
    return this._excl_id;
  }
  public set excl_id(value: number) {
    this._excl_id = value;
  }
  
  private _country: string;
  public get country(): string {
    return this._country;
  }
  public set country(value: string) {
    this._country = value;
  }
  
  private _date_from: string;
  public get date_from(): string {
    return this._date_from;
  }
  public set date_from(value: string) {
    this._date_from = value;
    if (this._date_from) {
      this._dateFrom = new Date(this._date_from);
    }
  }
  
  // custom
  private _dateFrom: Date = null;
  public get dateFrom(): Date {
    return this._dateFrom;
  }

  private _date_until: string;
  public get date_until(): string {
    return this._date_until;
  }
  public set date_until(value: string) {
    this._date_until = value;
    if (this._date_until) {
      this._dateUntil = new Date(this._date_until);
    }
  }
  
  // custom
  private _dateUntil: Date = null;
  public get dateUntil(): Date {
    return this._dateUntil;
  }

  private _week_day: any;
  public get week_day(): any {
    return this._week_day;
  }
  public set week_day(value: any) {
    this._week_day = value;
  }

  private _time_from: string;
  public get time_from(): string {
    return this._time_from;
  }
  public set time_from(value: string) {
    this._time_from = value;
  }

  private _time_until: string;
  public get time_until(): string {
    return this._time_until;
  }
  public set time_until(value: string) {
    this._time_until = value;
  }

  private _tonnage: number;
  public get tonnage(): number {
    return this._tonnage;
  }
  public set tonnage(value: number) {
    this._tonnage = value;
  }

  private _valid_when: any;
  public get valid_when(): any {
    return this._valid_when;
  }
  public set valid_when(value: any) {
    this._valid_when = value;
  }

  private _comment: string;
  public get comment(): string {
    return this._comment;
  }
  public set comment(value: string) {
    this._comment = value;
  }
  
  private _moveable: boolean;
  public get moveable(): boolean {
    return this._moveable;
  }
  public set moveable(value: boolean) {
    this._moveable = value;
  }
  
  private _warning: boolean;
  public get warning(): boolean {
    return this._warning;
  }
  public set warning(value: boolean) {
    this._warning = value;
  }
  
  private _public_holiday: boolean;
  public get public_holiday(): boolean {
    return this._public_holiday;
  }
  public set public_holiday(value: boolean) {
    this._public_holiday = value;
  }

}