import { GeoPosition } from "./geo-position.object";

export class AddressFavorite {
  private _oul_key: number;
  public get oul_key(): number {
    return this._oul_key;
  }
  public set oul_key(value: number) {
    this._oul_key = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _nickname: string;
  public get nickname(): string {
    return this._nickname;
  }
  public set nickname(value: string) {
    this._nickname = value;
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  
  private _address_unparsed: string;
  public get address_unparsed(): string {
    return this._address_unparsed;
  }
  public set address_unparsed(value: string) {
    this._address_unparsed = value;
  }
  
  private _street: string = null;
  public get street(): string {
    return this._street;
  }
  public set street(value: string) {
    this._street = value;
  }
  
  private _city: string = null;
  public get city(): string {
    return this._city;
  }
  public set city(value: string) {
    this._city = value;
  }
  
  private _zip: string = null;
  public get zip(): string {
    return this._zip;
  }
  public set zip(value: string) {
    this._zip = value;
  }
  
  private _country: string = null;
  public get country(): string {
    return this._country;
  }
  public set country(value: string) {
    this._country = value;
  }
  
  /*
    postgre object {
      closed: boolean
      open: boolean
      points: Array<string>
      type: string
      value: string
    }
  */
  private _gps_area: any;
  public get gps_area(): any {
    return this._gps_area;
  }
  public set gps_area(value: any) {
    this._gps_area = value;
    if (this._gps_area) {
      this.gps_area_value = this._gps_area.value;
    }
  }

  // "((0,0),(1,0))"
  private _gps_area_value: string = null;
  public get gps_area_value(): string {
    return this._gps_area_value;
  }
  public set gps_area_value(value: string) {
    this._gps_area_value = value;
  }
  
  private _gps_coord: string;
  public get gps_coord(): string {
    return this._gps_coord;
  }
  public set gps_coord(value: string) {
    this._gps_coord = value;
  }
  
  private _gps_coord_object: GeoPosition;
  get gps_coord_object(): GeoPosition {
    return this._gps_coord_object;
  }
  set gps_coord_object(value: GeoPosition) {
    this._gps_coord_object = value;
  }

  private _note: string;
  public get note(): string {
    return this._note;
  }
  public set note(value: string) {
    this._note = value;
  }

  // type 'W' ~ warehouse, type 'V' ~ automatic warehouse
  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
    if (this._type == 'V') {
      this._warehouse_auto = true;
    }
  }

  private _warehouse_auto: boolean = false;
  public get warehouse_auto(): boolean {
    return this._warehouse_auto;
  }
  public set warehouse_auto(value: boolean) {
    this._warehouse_auto = value;
  }


  get apiObject(): any {
    return {
      name: this.name,
      nickname: this.nickname,
      address_unparsed: this.address_unparsed,
      street: this.street,
      city: this.city,
      zip: this.zip,
      country: this.country,
      gps_coord: this.gps_coord,
      gps_area: this.gps_area_value,
      note: this.note,
      type: this.type
    }
  }
}
