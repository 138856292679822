import { Component, OnDestroy, OnInit, HostBinding, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { Title, SafeStyle } from '@angular/platform-browser';
import { CdkDrag } from "@angular/cdk/drag-drop";
import { ResizeEvent } from 'angular-resizable-element';
import { Subscription } from "rxjs";

import { IS_DEMO, ItineraryType, ObligationStatus, ServiceConfiguration, StorageKeys } from "../../config";
import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";
import { WebConnectivityService } from "src/app/service/web.connectivity.service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { WebsocketService } from "src/app/service/websocket.service";
import { ObligationService } from "src/app/service/obligation.service";
import { NotificationService } from "src/app/service/notification-service";
import { WarehouseService } from "src/app/service/warehouse.service";
import { MessageService } from "src/app/service/message.service";
import { CompanyService } from "src/app/service/company.service";
import { DispatcherBoardAgendaObject } from "../../model/dispatcher-board-agenda.object";
import { Company } from "src/app/model/company.object";
import { Agenda } from "../../model/agenda.object";
import { Vehicle } from "../../model/vehicle.object";
import { Itinerary } from "src/app/model/itinerary.object";
import { SafeStylePipe } from "../../pipe/safe-style.pipe";
import { Obligation } from "src/app/model/obligation.object";
import { WebsocketResponse } from "src/app/interface/websocket-response.interface";
import { DateTools } from "../../tools/DateTools";
import { Haversine } from "src/app/tools/Haversine";
import { ExpressRoute } from "src/app/model/express-route.object";
import { ExpressRouteService } from "src/app/service/express-route.service";
import { StorageService } from "src/app/service/storage.service";

// declare let gtag: Function;
declare var $: any;

@Component({
  selector: 'div.dispatcher-board-component',
  templateUrl: './r-dispatcher-board.component.html',
  styleUrls: ['./r-dispatcher-board.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ObligationService,
    ExpressRouteService,
    VehicleNewService,
    WarehouseService,
    SafeStylePipe
  ]
})
export class RDispatcherBoardComponent implements OnInit, OnDestroy {

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._safeStyle.transform('width:' + this.boardWidth);
  }

  get windowInnerWidth(): number {
    return window.innerWidth;
  }

  private _liteVersion: boolean = false;
  public get liteVersion(): boolean {
    return this._liteVersion;
  }
  public set liteVersion(value: boolean) {
    this._liteVersion = value;
    this.detectChanges();
  }

  private _subs: Array<Subscription> = [];
  private _showDays: Array<number> = ServiceConfiguration.vehicle.dispatcher_board_days;
  private _currentDateTiming: number;
  private _agendaData: any = {};
  private _distanceAndTimeDiffs: any = {};
  private _company: Company;
  public get company(): Company {
    return this._company;
  }

  private _vehicles: Array<Vehicle> = [];
  get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }

  private _columnWidth = ServiceConfiguration.vehicle.dispatcher_board_day_width;
  get columnWidth(): string {
    return this._columnWidth;
  }

  private _arrowColumnHeight: number = 1080;
  public get arrowColumnHeight(): number {
    return this._arrowColumnHeight;
  }
  public set arrowColumnHeight(value: number) {
    this._arrowColumnHeight = value;
  }

  private _hours = [
    [0, 1, 2, 3, 4, 5],
    [6, 7, 8, 9, 10, 11],
    [12, 13, 14, 15, 16, 17],
    [18, 19, 20, 21, 22, 23]
  ];
  get hours(): Array<Array<number>> {
    return this._hours;
  }

  private _days: Array<Date> = [];
  get days(): Array<Date> {
    return this._days;
  }

  private _currentDate: Date = new Date();
  get currentDate(): Date {
    return this._currentDate;
  }

  private _updatingObligationByDragging: boolean = false;
  private _updatingFromModal: boolean = false;
  // private _timeoutForUpdate: boolean = false;

  // connection stuff
  private _notConnected: boolean = false;
  get notConnected(): boolean {
    return this._notConnected;
  }

  // flag for just one time scrolling
  private _firstLoadScrolled: boolean = false;
  
  constructor(
    private _layout: TruckManagerLayoutService,
    // private _vehicleService: VehicleService,
    private _vehicleNewService: VehicleNewService,
    private _webConn: WebConnectivityService,
    private _webSocketServ: WebsocketService,
    private _obligationServ: ObligationService,
    private _expressRouteServ: ExpressRouteService,
    private _notificationServ: NotificationService,
    private _warehouseServ: WarehouseService,
    private _messageServ: MessageService,
    private _companyServ: CompanyService,
    private _cdr: ChangeDetectorRef,
    private _title: Title,
    private _safeStyle: SafeStylePipe,
    private _datePipe: DatePipe,
    private _router: Router,
    private _storageServ: StorageService
  ) {
    // custom change detection strategy
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 5000
    );
  }

  ngOnInit() {
    // if (!window.location.href.includes('app2') && !window.location.href.includes('localhost')) {
    //   gtag('config', 'UA-116483982-1', { 'page_path': 'plachta_dispecera' });
    // }
    this.loadCokkie();
    this._title.setTitle("Plachta");

    // reinit current date each minute
    this._currentDateTiming = window.setInterval(
      () => {
        this._currentDate = new Date();
        // this._days = this.prepareDays();
        // this.buildAgendaData();
      },
      60000
    );
    
    window.setTimeout(
      () => {
        this._subs.push(
          this._vehicleNewService.getFilteredVehiclesCache().subscribe(
            (vehicles: Array<Vehicle>) => {
              if (vehicles && vehicles.length && vehicles != this._vehicles) {
                this._vehicles = vehicles;
                this._days = this.prepareDays();
                // this.buildAgendaData();
                
                if (!this._firstLoadScrolled) {
                  this._firstLoadScrolled = true;
                  window.setTimeout(
                    () => {
                      // centering using scrolling
                      let dashboardParent = document.getElementById('content-left-parent');
                      // let dashboardParent = document.getElementById('dashboard-outlet');
                      // assume 3 days
                      let now: number = Math.abs(3 * parseFloat(this.columnWidth)) + 40;
                      // today current hour in decimal number
                      now += Math.abs((this._currentDate.getHours() / 24) * parseFloat(this.columnWidth));
                      // center in window
                      let scrollX: number = now - (window.innerWidth / 2);
                      if (scrollX > 0 && (this.isAuthenticated || this.isDemo)) {
                        dashboardParent.scroll({top: 0, left: scrollX, behavior: 'smooth'});
                      }
                    },
                    2000
                  );
                }

                // load agenda 
                this.loadAgendaInterval();
                // custom detection strategy
                this.detectChanges();
              }
            }
          ),
          // TODO možná by se zde mohl přidat i subscribe pro vehicleDataChanged jako u itinerary-diary
          // mozna by to bylo potreba pro "kompletni zazelenani" zakazky po posledni vykladce
          this._vehicleNewService.vehicleDataChanged.subscribe(
            vehicle => {
              if (vehicle) {
                if (vehicle.agenda && vehicle.agenda.length) {
                  let idx: number = this._vehicles.findIndex(v => v.car_key == vehicle.car_key);
                  if (idx > -1) {
                    this._vehicles[idx] = vehicle;
                    // rebuild dispatcher board
                    this.buildAgendaData();
                    // custom detection strategy
                    this.detectChanges();
                  }
                }
              }
            }
          ),
          this._webSocketServ.companyMessage.subscribe(
            (wsMessage: WebsocketResponse) => {
              if (wsMessage.relation === WebsocketService.RELATIONS.obligation) {
                // 5 seconds must be enough to update also itinerary - we use here window timeout

                // info for user that component will be soon reloaded
                if (!this._updatingObligationByDragging && !this._updatingFromModal) {
                  this._notificationServ.alert(
                    'Došlo ke změně zakázek - stránka bude aktualizována...', 'success', 5000
                  );

                  window.setTimeout(
                    () => {
                      // obligation created -> load vehicle with agenda
                      if (wsMessage.operation == WebsocketService.OPERATIONS.insert) {
                        if (wsMessage.record.car_key && wsMessage.record.obligation_key) {
                          // reloading agenda 
                          this.loadVehicleAgendaInterval(wsMessage.record.car_key);
                        }
                        // piece_shipment or transshipment
                        if (wsMessage.record.car2_key && wsMessage.record.obligation_key) {
                          // reloading agenda 
                          this.loadVehicleAgendaInterval(wsMessage.record.car2_key);
                        }
                      }
                      else if (wsMessage.operation == WebsocketService.OPERATIONS.update) {
                        if (wsMessage.record.obligation_key) {
                          // find updated obligation/agenda
                          let agenda_found: Agenda = null;
                          this._vehicles.forEach(
                            v => {
                              if (!agenda_found && v.agenda) {
                                agenda_found = v.agenda.find(
                                  a => a.obligation_key == wsMessage.record.obligation_key
                                );
                              }
                            }
                          );
            
                          if (agenda_found) {
                            // reloading agenda 
                            this.loadVehicleAgendaInterval(agenda_found.car_key);
            
                            // car_key has been change - reload (possibly second) vehicle with current agenda car_key
                            if (agenda_found.car_key != wsMessage.record.car_key) {
                              // reloading agenda 
                              this.loadVehicleAgendaInterval(wsMessage.record.car_key);
                            }
                            
                            // piece_shipment or transshipment
                            if (wsMessage.record.car2_key) {
                              // reloading agenda 
                              this.loadVehicleAgendaInterval(wsMessage.record.car2_key);
                            }
                            if (agenda_found.car2_key != wsMessage.record.car2_key) {
                              // reloading agenda 
                              this.loadVehicleAgendaInterval(agenda_found.car2_key);
                            }
                          }
                          else {
                            // agenda has not been shown in diary
                            if (wsMessage.record.car_key) {
                              // reloading agenda 
                              this.loadVehicleAgendaInterval(wsMessage.record.car_key);
                            }
                            // piece_shipment or transshipment
                            if (wsMessage.record.car2_key) {
                              // reloading agenda 
                              this.loadVehicleAgendaInterval(wsMessage.record.car2_key);
                            }
                          }
                        }
                      }
                      else if (wsMessage.operation == WebsocketService.OPERATIONS.delete) {
                        // find deleted obligation/agenda
                        let deleted_agenda: Agenda = null;
                        this._vehicles.forEach(
                          v => {
                            if (!deleted_agenda && v.agenda) {
                              deleted_agenda = v.agenda.find(
                                a => a.obligation_key == wsMessage.record.obligation_key
                              );
                            }
                          }
                        );
                        
                        if (deleted_agenda) {
                          if (deleted_agenda.car_key) {
                            // reloading agenda 
                            this.loadVehicleAgendaInterval(deleted_agenda.car_key);
                          }
                          // piece_shipment or transshipment
                          if (deleted_agenda.car2_key) {
                            // reloading agenda 
                            this.loadVehicleAgendaInterval(deleted_agenda.car2_key);
                          }
                        }
                      }
                      // OBSOLETE - bad optimalization
                      // reload vehicles - agenda with new itinerary will be up to date
                      // this._vehicleService.getVehicles(true, false).subscribe();
                      // TEST NEEDED !!!
                      // this._vehicleNewService.getFilteredVehiclesCache(true).subscribe();
                      
                      // load obligations for suggestions
                      window.setTimeout( () => { this.loadObligationsStatusE(); }, 2000); 
                      window.setTimeout( () => { this.loadObligationsFavourite(); }, 4000);
                      window.setTimeout( () => { this.loadExpressRoutesStatusE(); }, 6000);
                    },
                    5000
                  );
                }
                else {
                  if (this._updatingObligationByDragging || this._updatingFromModal) {
                    this._updatingObligationByDragging = false;
                    this._updatingFromModal = false;
                  }
                }
              }
            }
          ),
          // check connection
          this._webConn.connectionDown.subscribe(
            notConnected => {
              this._notConnected = notConnected;
              // custom detection strategy
              this.detectChanges();
            }
          ),
          this._webConn.isConnectionUp().subscribe(
            _ => {
              // this._lastUpTime = lastUpTime;
              this._notConnected = false;
              // custom detection strategy
              this.detectChanges();
            }
          ),
          this._companyServ.getCompanyFullObservable().subscribe(
            company => {
              if (company) {
                this._company = company;
                // custom detection strategy
                this.detectChanges();
              } 
            }
          )
        );
      }, 500
    );

    // hotfix, because authentication is later than OnInit
    // load obligations for suggestions
    window.setTimeout( () => { this.loadObligationsStatusE(); }, 2000); 
    window.setTimeout( () => { this.loadObligationsFavourite(); }, 4000);
    window.setTimeout( () => { this.loadExpressRoutesStatusE(); }, 6000);
  }

  ngOnDestroy() {
    this._subs.forEach(
      sub => sub.unsubscribe()
    );
    if (this._currentDateTiming) {
      window.clearInterval(this._currentDateTiming);
    }
  }

  detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 250
    );
  }

  // load agendas for all vehicles separately
  private loadAgendaInterval(): void {
    let dates = this.getDates(this._showDays[0], this._showDays[1]);
    let tf: string = this._datePipe.transform(dates[0], 'yyyy-MM-dd');
    let tt: string = this._datePipe.transform(dates[1], 'yyyy-MM-dd');

    let currentResponse: number = 0;
    let totalResponses: number = this._vehicles.length;

    this._vehicles.forEach(
      v => {
        this._subs.push(
          this._vehicleNewService.getVehicleAgenda(v.car_key, tf, tt).subscribe(
            vehicleWithAgenda => {
              if (vehicleWithAgenda) {
                // update agenda in vehicle
                v = vehicleWithAgenda;
                currentResponse += 1;
                // all responses -> rebuild dispatcher board
                if (currentResponse == totalResponses) {
                  this.buildAgendaData();
                  // custom detection strategy
                  this.detectChanges();
                }
              }
            }
          )
        );
      } 
    );
  }

  // load agenda for given vehicle
  private loadVehicleAgendaInterval(car_key: number): void {
    let dates = this.getDates(this._showDays[0], this._showDays[1]);
    let tf: string = this._datePipe.transform(dates[0], 'yyyy-MM-dd');
    let tt: string = this._datePipe.transform(dates[1], 'yyyy-MM-dd');
    
    this._subs.push(
      this._vehicleNewService.getVehicleAgenda(car_key, tf, tt).subscribe(
        vehicleWithAgenda => {
          if (vehicleWithAgenda) {
            let idx: number = this._vehicles.findIndex(v => v.car_key == vehicleWithAgenda.car_key);
            if (idx > -1) {
              this._vehicles[idx] = vehicleWithAgenda;
              // rebuild dispatcher board
              this.buildAgendaData();
              // custom detection strategy
              this.detectChanges();
            }
          }
        }
      )
    );
  }


  private loadObligationsStatusE(): void {
    this.detectChanges();
    this._subs.push(
      // get only obligations 'K vyrizeni'
      this._obligationServ.getObligationsFilteredRequest({status: 'E'}, {number: 'desc'}, 0, 1000).subscribe(
        obligations => {
          if (obligations && obligations.length) {
            this._obligationsStatusE = obligations;
            // check if obligations are out of displayed day interval
            this._obligationsStatusE.forEach(
              o => {
                let start = this._days[0];
                let end = this._days[this._days.length - 1];
                if (o.first_itinerary_time && start > o.first_itinerary_time) {
                  o.isOutOfInterval = true;
                }
                if (o.last_itinerary_time && end < o.last_itinerary_time) {
                  o.isOutOfInterval = true;
                }
              }
            );
          }
          else {
            this._obligationsStatusE = [];
          }
          // custom detection strategy
          this.detectChanges();
        }
      )
    );
  }

  private loadObligationsFavourite(): void {
    this.detectChanges();
    this._subs.push(
      // get only favourite obligations
      this._obligationServ.getObligationsFilteredRequest({favourite: true}, {number: 'desc'}, 0, 1000).subscribe(
        obligations => {
          if (obligations && obligations.length) {
            this._obligationsFavourite = obligations;
            // console.log(obligations);
          }
          else {
            this._obligationsFavourite = [];
          }
          // custom detection strategy
          this.detectChanges();
        }
      )
    );
  }

  private loadExpressRoutesStatusE(): void {
    this.detectChanges();
    this._subs.push(
      // get only obligations 'K vyrizeni'
      this._expressRouteServ.getExpressRoutesRequest({status: 'E'}, {number: 'desc'}, 0, 1000).subscribe(
        routes => {
          if (routes && routes.length) {
            this._expressRoutesStatusE = routes;
            // console.log(obligations);
            // check if obligations are out of displayed day interval
            this._expressRoutesStatusE.forEach(
              e => {
                this._subs.push(
                  this._expressRouteServ.getExpressObligations(e).subscribe(
                    obligations => {
                      if (obligations) {
                        // console.log(obligations);
                        e.obligations = obligations;
                        let start = this._days[0];
                        let end = this._days[this._days.length - 1];
                        if (e.itineraryOrdered && e.itineraryOrdered.length) {
                          if (e.itineraryOrdered[0].arrival_time && start > e.itineraryOrdered[0].arrival_time) {
                            e.isOutOfInterval = true;
                          }
                          if (e.itineraryOrdered[e.itineraryOrdered.length-1].arrival_time && end < e.itineraryOrdered[e.itineraryOrdered.length-1].arrival_time) {
                            e.isOutOfInterval = true;
                          }
                        }
                      }
                    }
                  )
                )
              }
            );
          }
          else {
            this._expressRoutesStatusE = [];
          }
          // custom detection strategy
          this.detectChanges();
        }
      )
    );
  }


  /********************************************************/
  /**** Getters ****/
  /********************************************************/
  get isDemo(): boolean {
    return IS_DEMO;
  }

  get loadingVehicles(): boolean {
    return this._vehicleNewService.loadingVehicles;
  }

  // default url of current server
  get contentResource(): string {
    return this._layout.staticContentResource;
  }

  // method for checking if user is authenticated
  get isAuthenticated(): boolean {
    return (IS_DEMO || (!IS_DEMO && this._layout.isAuthenticated()));
  }
  
  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }
  
  private TODAY: Date = new Date();
  // get flag if user has valid access = true, expired access = false
  get validAccess(): boolean {
    if (IS_DEMO) return true;
    if (!this._layout.user || !this._layout.user.admittanceDateExtended) return false;
    return this._layout.user.admittanceDateExtended > this.TODAY;
  }
  
  get tariffOnlyAgenda(): boolean {
    return this._company && (this._company.tariff == '17' || this._company.tariff == '23');
  }
  
  get lastVehicleLoad(): Date {
    return this._vehicleNewService.lastLoadDate;
    // if (!this._vehicleNewService.lastLoadDate) {
    //     return '-';
    // }
    // return DateTools.formatLocaleString(this._vehicleNewService.lastLoadDate, '%hours:%minutes');
  }

  // 80++ is for 40px each side next/prev
  get boardWidth() {
    return (parseFloat(this.columnWidth) * this._days.length) + 80 + 'px';
  }

  get innerHeight(): number {
    return window.innerHeight;
  }

  get outerHeight(): number {
    return window.outerHeight;
  }

  get start(): Date {
    return this._days[0];
  }

  get end(): Date {
    return this._days[this._days.length - 1];
  }

  isWeekend(date: Date): boolean {
    return [0, 6].indexOf(date.getDay()) > -1;
  }

  isToday(date: Date): boolean {
    return date.toDateString() == this._currentDate.toDateString();
  }

  isThisHour(date: Date, hour?: number): boolean {
    return this.isToday(date) && this._currentDate.getHours() === (!isNaN(hour) ? hour : date.getHours());
  }

  getDayString(date: Date): string {
    return this._datePipe.transform(date, 'EEEE');
  }

  getDateString(date: Date): string {
    return DateTools.formatLocaleString(date, '%day.%month.%year');
  }

  getDataByVehicle(vehicle: Vehicle): Array<DispatcherBoardAgendaObject> {
    if (!this._agendaData[vehicle.car_key]) {
      return [];
    }
    return this._agendaData[vehicle.car_key];
  }

  getDiffsByVehicle(vehicle: Vehicle): Array<any> {
    if (!this._distanceAndTimeDiffs[vehicle.car_key]) {
      return [];
    }
    return this._distanceAndTimeDiffs[vehicle.car_key];
  }

  private getDates(howMuch1: number, howMuch2: number): Array<Date> {
    let dates = [];
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(this._currentDate.getDate() + howMuch1);
    dates.push(date);
    date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(this._currentDate.getDate() + howMuch2);
    dates.push(date);
    return dates;
  }

  private getAgendaLengthInDays(agenda: Agenda): number {
    let daysLength = 0;
    if (agenda.itinerary && agenda.itinerary.length) {
      let end = agenda.last_itinerary_time_with_limit;
      if (end > this._days[this._days.length - 1].getTime()) {
        end = this._days[this._days.length - 1].getTime();
      }
      let start = agenda.day.getTime();
      if (start < this._days[0].getTime()) {
        start = this._days[0].getTime();
      }
      daysLength = end - start;
      daysLength = (daysLength < 0 ? 0 : daysLength) / 1000 / 60 / 60 / 24;
    }
    return daysLength;
  }

  
  /********************************************************/
  /**** Data building ****/
  /********************************************************/
  private prepareDays(): Array<Date> {
    let dates: Array<Date> = [];
    for (let i = this._showDays[0]; i <= this._showDays[1]; i++) {
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(this._currentDate.getDate() + i);
      dates.push(date);
    }
    // last day -> increment hours to 23:59:59
    dates[dates.length - 1].setHours(23, 59, 59);
    return dates;
  }

  // main method for building
  private buildAgendaData() {
    this._agendaData = {};
    this._distanceAndTimeDiffs = {};
    let data = {};
    let start = this._days[0].getTime();
    let end = this._days[this._days.length - 1].getTime();

    if (!this._vehicles.length) return;

    this._vehicles.forEach(
      vehicle => {
        if (vehicle.agenda) {
          // sort agendas according to arrival_time
          // vehicle.agenda.sort((a, b) => (a.day > b.day) ? 1 : -1);
          vehicle.agenda.sort(
            (a, b) => {
              if (a.day > b.day) {
                return 1;
              }
              else if (a.day < b.day) {
                return -1;
              }
              else {
                // same - prefer first created agenda
                if (a.obligation_key > b.obligation_key) {
                  return 1;
                }
                return -1;
              }
              return 1;
            }
          );

          vehicle.agenda.forEach(
            agenda => {
              let value = new DispatcherBoardAgendaObject(agenda, vehicle);
              if (!data[vehicle.car_key]) {
                data[vehicle.car_key] = [];
              }

              if (value.agenda.itinerary.length) {
                // let startAgenda = value.agenda.itinerary[0].arrival_time.getTime();
                let startAgenda = value.day.getTime();
                let endAgenda = value.agenda.last_itinerary_time_with_limit;
                if (
                  (startAgenda < start && endAgenda <= end && endAgenda >= start) ||
                  (startAgenda > start && endAgenda <= end) ||
                  (startAgenda < start && endAgenda > end) ||
                  (startAgenda > start && endAgenda > end && startAgenda <= end)
                ) {
                  data[vehicle.car_key].push(value);
                }

                // save offset and width
                this.setAgendaInfoWidth(value);
                this.setAgendaInfoOffset(value);
              }
              
              if (value.agenda.piece_shipment || value.agenda.transshipment) {
                // load info about warehouse events
                let time_from_date: Date = new Date();
                // using first itinerary time
                let first: Itinerary = value.agenda.first_itinerary;
                if (first) {
                  time_from_date = first.arrival_time;
                }
                // resize tf interval (without that some events could be missing)
                let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                let filterObj: any = {
                  tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                  tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                  on: agenda.order_number_standard
                };
                this._subs.push(
                  this._warehouseServ.getEvents(filterObj).subscribe(
                    response => {
                      if (response) {
                        // find unloading (naskladneni)
                        value.agenda.warehouse_event_unloading = response.find(
                          e => e.type == ItineraryType.UNLOADING
                        );
                        // TODO otestovat toto - uprava arrival time, dle realne event
                        // nejprve upravit cas v api u warehouse event
                        // if (value.agenda.warehouse_event_unloading) {
                        //   let warehouse = value.agenda.itinerary.find(
                        //     it => it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO
                        //   );
                        //   warehouse.arrival_time = value.agenda.warehouse_event_unloading.timeDate;
                        //   // save offset and width
                        //   this.setAgendaInfoWidth(value);
                        //   this.setAgendaInfoOffset(value);
                        // }

                        // find loading (vyskladneni)
                        value.agenda.warehouse_event_loading = response.find(
                          e => e.type == ItineraryType.LOADING
                        );
                        // TODO otestovat toto - uprava arrival time, dle realne event
                        // nejprve upravit cas v api u warehouse event
                        // if (value.agenda.warehouse_event_loading) {
                        //   let warehouse = value.agenda.itinerary.find(
                        //     it => it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO
                        //   );
                        //   warehouse.arrival_time = value.agenda.warehouse_event_loading.timeDate;
                        //   // save offset and width
                        //   this.setAgendaInfoWidth(value);
                        //   this.setAgendaInfoOffset(value);
                        // }
                      }
                    }
                  )
                );
              }
            }
          );
        }
      }
    );
    // save initialized data
    this._agendaData = data;
    
    // second loop - computing conflicts
    this._vehicles.forEach(
      vehicle => {
        let dispatcherAgendas: Array<DispatcherBoardAgendaObject> = this.getDataByVehicle(vehicle);
        dispatcherAgendas.forEach(
          dispAgenda => {
            this.setConflictingTop(dispAgenda, vehicle);

            // mark as selected (adding from modal feature)
            if (this._obligationSelected && dispAgenda.agenda.obligation_key == this._obligationSelected.obligation_key) {
              this.selectedAgenda = dispAgenda;
            }
            else if (this._obligationDuplicate && dispAgenda.agenda.obligation_key == this._obligationDuplicate.obligation_key) {
              this.selectedAgenda = dispAgenda;
            }
          }
        );
        this.setConflictingHeight(vehicle);
      }
    );

    // reinit left/right columns height
    this._arrowColumnHeight = 0;

    // third loop - computing vehicle row heights and building time/distance differences
    // 50px is brown header
    let startOffsetTop: number = 50;
    this._vehicles.forEach(
      v => {
        v.vehicleRowOffsetTop = startOffsetTop;
        v.vehicleRowHeight = 25 + v.timeSegmentsHeight;  // 25px is height of vehicle-row-header
        startOffsetTop += v.vehicleRowHeight;

        // compute also arrow left/right columns height
        this._arrowColumnHeight += v.vehicleRowHeight;

        // compute time/distance diffs
        this.buildDistanceAndTimeDiff(v);
      }
    );
    
    // add height of header
    this._arrowColumnHeight += 51;

    // horizontal centering on selected agenda after rebuilding
    if (this.selectedAgenda) {
      // centering using scrolling
      let dashboardParent = document.getElementById('content-left-parent');
      // let dashboardParent = document.getElementById('dashboard-outlet');
      // today current hour in decimal number
      let center: number = this.selectedAgenda.infoOffset + (this.selectedAgenda.infoWidth / 2);
      // current scroll X on page
      let horizontalScrollX = window.pageXOffset || document.documentElement.scrollLeft;

      // center in window
      let scrollX: number = center - horizontalScrollX - (window.innerWidth / 2);
      if (scrollX < 1) scrollX = 0;
      // smoothly scrolling
      dashboardParent.scroll({top: 0, left: scrollX, behavior: 'smooth'});
    }

    // custom detection strategy
    this.detectChanges();
  }

  // set horizontal offset left of agenda segment
  setAgendaInfoOffset(obj: DispatcherBoardAgendaObject): void {
    let start = this._days[0];
    let oneDayLengthInPx = this._columnWidth;
    let agendaStart = obj.day;
    let agendaEnd = obj.agenda.last_itinerary_time_with_limit;
    // let agendaEnd = obj.agenda.itinerary[obj.agenda.itinerary.length - 1].arrival_time;

    let length = agendaStart.getTime() - start.getTime();
    length = length / 1000 / 60 / 60 / 24;
    let offset = length * parseFloat(oneDayLengthInPx);

    if (agendaStart.getTime() < start.getTime()) {
      obj.infoOffset = 0;
    }
    else if (agendaEnd < start.getTime()) {
      obj.infoOffset = -1;
    }
    else if (offset < 0) {
      obj.infoOffset = -1;
    }
    else {
      obj.infoOffset = Math.round(offset);
    }
  }

  // set width of agenda segment
  setAgendaInfoWidth(obj: DispatcherBoardAgendaObject): void {
    obj.infoWidth = this.getAgendaLengthInDays(obj.agenda) * parseFloat(this._columnWidth);
  }
  
  // set top aligment when confict in time segments
  setConflictingTop(dispatcherAgenda: DispatcherBoardAgendaObject, vehicle: Vehicle) {
    let dispatcherAgendas = this.getDataByVehicle(vehicle);

    // width and offset of given agenda
    let left = dispatcherAgenda.day.getTime(); // dispatcherAgenda.infoOffset;
    let right = dispatcherAgenda.end_with_loading_limit; // left + dispatcherAgenda.infoWidth;

    dispatcherAgenda.conflictRow = 0;
    let conflictedRows: Array<number> = [];
    // if conflicting increment top align -> later start is downer
    dispatcherAgendas.forEach(
      dispAgenda2 => {
        if (dispAgenda2 !== dispatcherAgenda) {
          let left2 = dispAgenda2.day.getTime(); // dispAgenda2.infoOffset;
          let right2 = dispAgenda2.end_with_loading_limit; // dispAgenda2.agenda left2 + dispAgenda2.infoWidth;
          if ((left >= left2 && left < right2) || (left >= left2 && right <= right2)) {
            // handle problem with same starting times (first and second would be swapped)
            if (left == left2 && dispAgenda2.conflictRow == 0 && !dispAgenda2.conflictRowSetUp) {
              // void
            }
            else {
              // conflict found -> save conflicted row
              conflictedRows.push(dispAgenda2.conflictRow);
            }
          }
        }
      }
    );

    // naive but working approach - loop until finding first free row (not conflicted)
    const MAXCONFLICT = 42;
    for (let i = 0; i < MAXCONFLICT; i++) {
      if (!conflictedRows.includes(i)) {
        dispatcherAgenda.conflictRow = i;
        // set flag
        dispatcherAgenda.conflictRowSetUp = true;
        break;
      }
    }
  }

  // height of time segments
  setConflictingHeight(vehicle: Vehicle) {
    // find max conflicts => possibility computing height
    let maxConflicts: number = 0;
    let dispatcherAgendas = this.getDataByVehicle(vehicle);
    dispatcherAgendas.forEach(
      a => {
        if (a.conflictRow > maxConflicts) {
          maxConflicts = a.conflictRow;
        }
      }
    );

    // default timesegment row height is 80px
    vehicle.timeSegmentsHeight = 80 + maxConflicts * 50;
    // default hourline height is 60px
    vehicle.hourLineHeight = 60 + maxConflicts * 50;
    // default hourline height is 30px
    vehicle.halfhourLineHeight = 30 + maxConflicts * 50;
    // default today marker height is 105px
    vehicle.todayMarker = 105 + maxConflicts * 50;
  }

  // method for computing time and distance difference between agendas in 1 row
  buildDistanceAndTimeDiff(vehicle: Vehicle): void {
    // init array into diffs object
    this._distanceAndTimeDiffs[vehicle.car_key] = [];

    let dispatcherAgendas: Array<DispatcherBoardAgendaObject> = this.getDataByVehicle(vehicle);
    // number of conflict rows and removing duplicates
    let rows = dispatcherAgendas.map(a => a.conflictRow);
    let uniqueRows = rows.filter(
      (item, pos) => {
        return rows.indexOf(item) == pos;
      }
    );
    // for each row just compute time/distance diff
    uniqueRows.forEach(
      r => {
        let rowDispAgendas = dispatcherAgendas.filter(a => a.conflictRow == r);
        rowDispAgendas.forEach(
          (dispAgenda, index) => {
            if (index != 0) {
              // time difference computing
              // time of ending last itinerary of first agenda
              let endFirst: number = rowDispAgendas[index - 1].end.getTime();
              // time of loading limit
              let loadingLimit: number = rowDispAgendas[index - 1].last_itinerary.loading_time_limit;
              if (loadingLimit != 0 && !loadingLimit) loadingLimit = 50;
              loadingLimit = loadingLimit * 60 * 1000;
              // time of starting first itinerary of second agenda
              let startSecond: number = dispAgenda.day.getTime();

              let timeDiff = DateTools.getHumanReadHoursAndMinutes(startSecond - endFirst - loadingLimit);
              
              // distance difference computing
              let dist: number = null;
              let endFirstPos = rowDispAgendas[index - 1].last_itinerary.gps_coord;
              let startSecondPos = dispAgenda.first_loading ? dispAgenda.first_loading.gps_coord : null;
              if (endFirst && startSecondPos) {
                dist = Haversine.haversine(
                  { latitude: startSecondPos.googleLatLang.lat, longitude: startSecondPos.googleLatLang.lng },
                  { latitude: endFirstPos.googleLatLang.lat, longitude: endFirstPos.googleLatLang.lng }
                );
                // empiric constant (tahle vzdalenost je vzdusnou carou, tak ji trochu nafukujeme)
                dist *= 1.33;
                dist = Math.round(dist); // Math.round(dist * 10) / 10;
              }

              // style offset(left, top) centering between two agendas
              let offsetDiff: number = dispAgenda.infoOffset - (rowDispAgendas[index - 1].infoOffset + rowDispAgendas[index - 1].infoWidth);
              let offsetLeft: number = offsetDiff / 2;
              offsetLeft += rowDispAgendas[index - 1].infoOffset + rowDispAgendas[index - 1].infoWidth;
              offsetLeft -= 27;

              if (this._distanceAndTimeDiffs[vehicle.car_key]) {
                // using custom format with only these 4 properties (no object defined)
                this._distanceAndTimeDiffs[vehicle.car_key].push(
                  {
                    timeDiff: timeDiff,
                    distanceDiff: dist,
                    offsetLeft: offsetLeft,
                    offsetTop: dispAgenda.conflictOffsetTop,
                    offsetDiff: offsetDiff
                  }
                );
              } 
            }
          }
        )
      }
    );
  }
  

  /********************************************************/
  /**** Template time interval moving ****/
  /********************************************************/
  get moveBackwardTitle(): string {
    let dates = this.getDates(this._showDays[0] - this.getMove(), this._showDays[1] - this.getMove());
    return DateTools.formatLocaleString(dates[0]) + ' - ' + DateTools.formatLocaleString(dates[1]);
  }

  get moveForwardTitle(): string {
    let dates = this.getDates(this._showDays[0] + this.getMove(), this._showDays[1] + this.getMove());
    return DateTools.formatLocaleString(dates[0]) + ' - ' + DateTools.formatLocaleString(dates[1]);
  }

  moveForward() {
    this._showDays = this.doMove(this.getMove());
    this._days = this.prepareDays();
    // reload agenda
    this.loadAgendaInterval();
  }

  moveBackward() {
    this._showDays = this.doMove(-this.getMove());
    this._days = this.prepareDays();
    // reload agenda
    this.loadAgendaInterval();
  }

  private getMove() {
    // default always 3 days - its like sliding window
    return 3;
  }

  private doMove(howMuch: number): Array<number> {
    return [
      this._showDays[0] + howMuch,
      this._showDays[1] + howMuch
    ];
  }

  
  /********************************************************/
  /**** Template dragging/extending ****/
  /********************************************************/
  // agenda segment object clicked by user
  public selectedAgenda: DispatcherBoardAgendaObject = null;

  // user does not confirmed dragged agenda
  reinitSelectedAgenda(): void {
    this.buildAgendaData();
  }

  setSelectedAgenda(event: any, agenda: DispatcherBoardAgendaObject): void {
    if (agenda) {
      // reset move flag
      agenda.enableMove = false;
      // stop propagation (two click events in template)
      event.stopPropagation();
    }
    this.selectedAgenda = agenda;

    // custom detection strategy
    this.detectChanges();
  }

  // agenda segment object that has been moved - used for confirmation
  public movedVehicle: Vehicle = null;
  public movedItinerary: Array<Itinerary> = [];

  // handler used after event cdkDragEnded
  cdkDrop(event: any, popoverElement: any) {
    // possibly hide popover after cdkDrop (could be opened)
    // https://stackoverflow.com/questions/74231016/close-ngbpopover-on-click-outside-anywhere-other-than-scrollbar
    if (popoverElement) {
      window.setTimeout(() => { popoverElement.close(); }, 250);
    }

    // reinit moved attributes
    this.movedVehicle = null;
    this.movedItinerary = [];

    let cdkDrag: CdkDrag = event.source;
    if (cdkDrag && this.selectedAgenda) {
      // keep it on the same place (some inter layour in cdkDropList)
      cdkDrag.element.nativeElement.style.left = this.selectedAgenda.infoOffset + 'px';

      // horizontal offset
      // this.movedAgenda.infoOffset = this.roundToQuarterHour(newOffsetX);
      let newOffsetX: number = this.roundToQuarterHour(this.selectedAgenda.infoOffset + event.distance.x);

      // timeline start
      let timelineStart = new Date();
      timelineStart.setHours(0, 0, 0, 0);
      timelineStart.setDate(this._currentDate.getDate() + this._showDays[0]);

      // new start of obligation
      // let newStart: Date = new Date(timelineStart.getTime() + (daysIncrement*24*60*60*1000) + (hoursIncrement*60*60*1000) + (minutesIncrement*60*1000));
      let newStart: Date = new Date(timelineStart.getTime() + this.quarterHoursToMiliseconds(newOffsetX));

      // compute time aligment
      if (this.selectedAgenda.agenda.itinerary.length) {
        this.selectedAgenda.agenda.itinerary.forEach(
          it => {
            // avoid agenda circular dependency in itinerary object
            it.agenda = undefined;
            // custom deep copy of itinerary
            let itCopy: Itinerary = new Itinerary();
            for (let key in it) {
              itCopy[key] = it[key];
            }
            this.movedItinerary.push(itCopy);
          }
        );

        let timeAligment: number = 0;
        // find first nakl/vykl (prejezd, tranzit, tankovani neplanujeme arrival_time)
        let firstLoadUnload: Itinerary = this.movedItinerary.find(
          i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
        );
        if (firstLoadUnload) {
          let obligationStart: Date = firstLoadUnload.arrival_time;
          timeAligment = obligationStart.getTime() - newStart.getTime();
        }

        // change times of all itinerary
        this.movedItinerary.forEach(
          it => {
            if (it.arrival_time) {
              it.arrival_time = new Date(it.arrival_time.getTime() - timeAligment);
            }
          }
        );
      }
      
      // vertical offset
      let newOffsetY: number = this.selectedAgenda.agenda.vehicle.vehicleRowOffsetTop + this.selectedAgenda.conflictOffsetTop + event.distance.y;
      let vehicle: Vehicle = this.findVehicleAccordingToOffsetTop(newOffsetY);
      if (vehicle) {
        this.movedVehicle = vehicle;
      }
      
      // custom detection strategy
      this.detectChanges();
      
      // open modal for confirmation
      (<any>$('#updateModal')).modal('show');
    }
  }

  // method for rounding particular pixel offset to quarter an hour offset
  roundToQuarterHour(pixelsXOffset: number): number {
    // now one hour should be 24px (whole column is 576px)
    let hourColumnWidth: number = parseFloat(this.columnWidth) / 24;
    // width of querter and hours
    let quarterHourWidth: number = hourColumnWidth / 4;

    return Math.round(quarterHourWidth * Math.round(pixelsXOffset/quarterHourWidth));
  }

  // method for computing number of quarter hour segments into miliseconds -> used in time_aligment computing
  quarterHoursToMiliseconds(pixelsXOffset: number): number {
    // now one hour should be 24px (whole column is 576px)
    let hourColumnWidth: number = parseFloat(this.columnWidth) / 24;
    // width of querter and hours
    let quarterHourWidth: number = hourColumnWidth / 4;

    // just number of 15min segments multiplied to miliseconds
    return (pixelsXOffset/quarterHourWidth) * 15 * 60 * 1000;
  }

  // method for finding particular vehicle according to delta in Y axis
  findVehicleAccordingToOffsetTop(yOffset: number): Vehicle {
    return this._vehicles.find(v => yOffset >= v.vehicleRowOffsetTop && yOffset <= (v.vehicleRowOffsetTop + v.vehicleRowHeight));
  }

  // method for updating moved agenda/obligation
  updateMovedAgenda(): void {
    // window alert for demo.truckmanager
    if (IS_DEMO) {
      window.alert("Demoverze neumožňuje úpravy dat.");
      return;
    }

    if (this.selectedAgenda) {
      // move itinerary
      this.selectedAgenda.agenda.itinerary = this.movedItinerary;

      // move agenda segment from one vehicle to another vehicle
      if (this.selectedAgenda.agenda.vehicle.removeAgenda(this.selectedAgenda.agenda)) {
        this.movedVehicle.addAgenda(this.selectedAgenda.agenda);
      }

      // rebuild
      this.buildAgendaData();

      // set flag for not refreshing cars via websocket
      this._updatingObligationByDragging = true;

      // update car_key in obligation
      let dataObligation: any = {
        car_key: this.movedVehicle.car_key
      };

      this._subs.push(
        this._obligationServ.updateObligationCustom(this.selectedAgenda.agenda.obligation_key, dataObligation).subscribe()
      );

      // update arrival_times in obligation itinerary
      this.selectedAgenda.agenda.itinerary.forEach(
        it => {
          if (it.arrival_time) {
            let dataItinerary: any = {
              arrival_time: DateTools.toIsoWithoutMilisec(it.arrival_time)
            };
            this._obligationServ.updateItineraryCustom(this.selectedAgenda.agenda.obligation_key, it, dataItinerary);
          }
        }
      );

      // notify driver
      if (this.movedVehicle) {
        this.sendMessageAboutChange(this.movedVehicle, this.selectedAgenda.agenda.itinerary);
      }

      // custom detection strategy
      this.detectChanges();
    }
    
  }

  // method for setting status storno for given agenda/obligation
  setStatusStorno(): void {
    // window alert for demo.truckmanager
    if (IS_DEMO) {
      window.alert("Demoverze neumožňuje úpravy dat.");
      return;
    }

    if (this.selectedAgenda && this.selectedAgenda.agenda) {
      // notify driver
      if (this.selectedAgenda.agenda.vehicle) {
        this.sendMessageAboutChange(this.selectedAgenda.agenda.vehicle, this.selectedAgenda.agenda.itinerary);
      }

      let data: any = {
        status: 'D'
      };
      
      // set flag for not refreshing cars via websocket
      this._updatingFromModal = true;

      this._subs.push(
        this._obligationServ.updateObligationCustom(this.selectedAgenda.agenda.obligation_key, data).subscribe(
          response => {
            if (response) {
              this.selectedAgenda = null;
              // reload after storno
              this.loadAgendaInterval();
            }
          }
        )
      );
    }
  }

  // method for setting status E (K vyrizeni) for given agenda/obligation
  setStatusE(): void {
    // window alert for demo.truckmanager
    if (IS_DEMO) {
      window.alert("Demoverze neumožňuje úpravy dat.");
      return;
    }

    if (this.selectedAgenda && this.selectedAgenda.agenda) {
      // notify driver
      if (this.selectedAgenda.agenda.vehicle) {
        this.sendMessageAboutChange(this.selectedAgenda.agenda.vehicle, this.selectedAgenda.agenda.itinerary);
      }
      
      let data: any = {
        status: 'E',
        car_key: null
      };

      // set flag for not refreshing cars via websocket
      this._updatingFromModal = true;

      this._subs.push(
        this._obligationServ.updateObligationCustom(this.selectedAgenda.agenda.obligation_key, data).subscribe(
          response => {
            if (response) {
              this.selectedAgenda = null;
              // load agenda 
              this.loadAgendaInterval();
              // load obligations
              this.loadObligationsStatusE();
            }
          }
        )
      );
    }
  }


  /**********************************************/
  /**** Resizable part ****/
  /**** https://www.npmjs.com/package/angular-resizable-element ****/
  /**********************************************/
  validate(event: ResizeEvent): boolean {
    if (event && event.rectangle && event.rectangle.width && event.edges.right) {
      let right: number = -1 * parseInt(event.edges.right.toString());
      let originalWidth: number = event.rectangle.width + right;
      if (event.rectangle.width < originalWidth) {
        return false;
      }
    }
    return true;
  }
  
  onResizeEnd(event: ResizeEvent): void {
    // reinit moved attributes
    this.movedVehicle = null;
    this.movedItinerary = [];

    // lets define min delta for possible to resize (could be null, but sometimes its 1-5px)
    const delta: number = 5;

    // @ts-ignore
    if (this.selectedAgenda && event && event.edges && event.edges.right && event.edges.right > delta) {
      this.movedVehicle = this.selectedAgenda.agenda.vehicle;

      // new right end of obligation
      let right: number = parseInt(event.edges.right.toString());
      let newEndPx: number = this.selectedAgenda.infoOffset + this.selectedAgenda.infoWidth + right;
      let newEndRounded: number = this.roundToQuarterHour(newEndPx);

      // timeline start
      let timelineStart = new Date();
      timelineStart.setHours(0, 0, 0, 0);
      timelineStart.setDate(this._currentDate.getDate() + this._showDays[0]);

      // new end of obligation
      let newEnd: Date = new Date(timelineStart.getTime() + this.quarterHoursToMiliseconds(newEndRounded));

      // compute time aligment
      if (this.selectedAgenda && this.selectedAgenda.agenda.itinerary.length) {
        this.selectedAgenda.agenda.itinerary.forEach(
          it => {
            // avoid agenda circular dependency in itinerary object
            it.agenda = undefined;
            // custom deep copy of itinerary
            let itCopy: Itinerary = new Itinerary();
            for (let key in it) {
              itCopy[key] = it[key];
            }
            this.movedItinerary.push(itCopy);
          }
        );


        // change times of last itinerary
        this.movedItinerary[this.movedItinerary.length - 1].arrival_time = newEnd;
      }
      
      // custom detection strategy
      this.detectChanges();

      // open modal for confirmation
      (<any>$('#updateModal')).modal('show');
    }
    else {
      let msg: string = 'Nelze posunout čas poslední vykládky do minulosti';
      msg += ' - tato funkce nyní umožňuje posunout čas poslední vykládky pouze do budoucna';
      this._notificationServ.alert(msg, 'error', 4500);
    }
  }



  /********************************************************/
  /**** Obligation with status E (k vyrizeni) updating ****/
  /********************************************************/
  private _obligationsStatusE: Array<Obligation> = [];
  public get obligationsStatusE(): Array<Obligation> {
    return this._obligationsStatusE;
  }

  get loadingObligations(): boolean {
    return this._obligationServ.loadingObligations2;
  }

  private _expressRoutesStatusE: Array<ExpressRoute> = [];
  public get expressRoutesStatusE(): Array<ExpressRoute> {
    return this._expressRoutesStatusE;
  }
  
  get loadingExpressRoutes(): boolean {
    return this._expressRouteServ.loadingExpressRoutes;
  }

  private _vehiclesSuggested: Array<any> = [];
  public get vehiclesSuggested(): Array<any> {
    return this._vehiclesSuggested;
  }

  private _obligationSelected: Obligation = null;
  public get obligationSelected(): Obligation {
    return this._obligationSelected;
  }

  private _expressRouteSelected: ExpressRoute = null;
  public get expressRouteSelected(): ExpressRoute {
    return this._expressRouteSelected;
  }

  private _vehicleSelected: Vehicle = null;
  public get vehicleSelected(): Vehicle {
    return this._vehicleSelected;
  }

  // method for optimalizing suggested time
  // work_day after 16:00 -> next work_day 07:00
  // weekend -> next monday 07:00
  optimalizeSuggestedTime(miliseconds: number, itinerary: Itinerary): number {
    let result: Date = new Date(miliseconds);
  
    // TODO work_day hodiny jak desetinna cisla..
    let day_begin: number = 7;
    if (itinerary && itinerary.work_day_begin_minutes) {
      day_begin = Math.round((itinerary.work_day_begin_minutes / 60));
    }
    let day_end: number = 16;
    if (itinerary && itinerary.work_day_end_minutes) {
      day_end = Math.round((itinerary.work_day_end_minutes / 60));
    }

    // optimalize next day only for Loadings and Unloadings
    if (itinerary && (itinerary.type == ItineraryType.LOADING || itinerary.type == ItineraryType.UNLOADING)) {
      if (result.getDay() == 6) {
        // saturday -> monday day_begin
        result.setDate(result.getDate() + 2);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getDay() == 0) {
        // sunday -> monday day_begin
        result.setDate(result.getDate() + 1);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getDay() == 5 && result.getHours() > day_end) {
        // friday after day_end -> monday day_begin
        result.setDate(result.getDate() + 3);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getDay() == 5 && result.getHours() < day_begin) {
        // friday before day_begin -> friday day_begin
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getHours() > day_end) {
        // next work_day at day_begin
        result.setDate(result.getDate() + 1);
        result.setHours(day_begin, 0, 0, 0);
      }
      else if (result.getHours() < day_begin) {
        // set hours day_begin
        result.setHours(day_begin, 0, 0, 0);
      }
    }
    
    // round to 00, 10, 20, 30, 40, 50 minutes
    let minutes: number = result.getMinutes();
    if (minutes > 0) {
      if (minutes <= 10) {
        result.setMinutes(10);
      }
      else if (minutes > 10 && minutes <= 20) {
        result.setMinutes(20);
      }
      else if (minutes > 20 && minutes <= 30) {
        result.setMinutes(30);
      }
      else if (minutes > 30 && minutes <= 40){
        result.setMinutes(40);
      }
      else {
        result.setMinutes(50);
      }
    }

    result.setSeconds(0);
    result.setMilliseconds(0);

    return result.getTime();
  }

  // method for suggesting vehicles to be selected for given obligation
  obligationSuggest(obligation: Obligation): void {
    // TOFIX? we keep here just reference of obligation (shouldnt be a big problem?)
    this._obligationSelected = obligation;
    this._vehiclesSuggested = [];

    let tmpVehicles: Array<Vehicle> = this._vehicles;
    if (obligation.weight) {
      tmpVehicles = tmpVehicles.filter(v => v.tonnage && v.tonnage >= obligation.weight);
    }

    tmpVehicles.forEach(
      v => {
        if (v.agenda && v.agenda.length && obligation.itinerary && obligation.itinerary.length) {
          let first: Itinerary = obligation.itinerary.find(
            i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
          );
          if (first) {
            // distance difference computing
            let dist: number = null;
            let src = first.gps_coord;
            let last = v.agenda[v.agenda.length - 1].last_itinerary;
            let dst = last ? last.gps_coord : null;
            if (src && dst) {
              dist = Haversine.haversine(
                { latitude: src.googleLatLang.lat, longitude: src.googleLatLang.lng },
                { latitude: dst.googleLatLang.lat, longitude: dst.googleLatLang.lng }
              );
              // empiric constant (tahle vzdalenost je vzdusnou carou, tak ji trochu nafukujeme)
              dist *= 1.33;
              dist = Math.round(dist * 10) / 10;

              // save it to suggested vehicle array
              let obj: any = {
                distance: dist,
                last_itinerary_address: last.address_excerpt,
                last_itinerary_time: last.arrival_time,
                vehicle: v
              };
              this._vehiclesSuggested.push(obj);
            }
          }
        }
      }
    );

    if (this._vehiclesSuggested.length) {
      this._vehiclesSuggested.sort((a, b) => (a.distance > b.distance) ? 1 : -1);
    }

    this.detectChanges();
  }

  // method for setting selected vehicle
  setSelectedVehicle(vehicle: Vehicle, optimal: boolean = false) {
    // set vehicle
    this._vehicleSelected = vehicle;

    if (optimal) {
      // find previous computed distance
      let suggest: any = this._vehiclesSuggested.find(obj => obj.vehicle.number_plate == this._vehicleSelected.number_plate);
      if (suggest && suggest.last_itinerary_time) {
        // dle vzorce - cas posledni nakl/vykl + limit (default 50min) + vzdalenost / rychlost 55 km/h 
        let newStart: number = suggest.last_itinerary_time.getTime();
        
        if (this._obligationSelected.first_itinerary && this._obligationSelected.first_itinerary.loading_time_limit) {
          newStart += this._obligationSelected.first_itinerary.loading_time_limit * 60 * 1000;
        }
        else {
          // default 50 min
          newStart += 50 * 60 * 1000;
        }

        if (suggest.distance) {
          newStart += (suggest.distance / 55) * 60 * 60 * 1000;
        }

        // time suggestion optimized
        let first_it: Itinerary = this._obligationSelected.itinerary.find(
          i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
        );
        newStart = this.optimalizeSuggestedTime(newStart, first_it);

        // compute time aligment
        let timeAligment: number = 0;
        timeAligment = this._obligationSelected.first_itinerary_time.getTime() - newStart;

        // change times of all itinerary
        this._obligationSelected.itinerary.forEach(
          it => {
            if (it.arrival_time) {
              it.arrival_time = new Date(it.arrival_time.getTime() - timeAligment);
              // also optimalize
              it.arrival_time = new Date(this.optimalizeSuggestedTime(it.arrival_time.getTime(), it));
              it.arrival_time_custom = this._datePipe.transform(it.arrival_time, 'yyyy-MM-ddTHH:mm');
            }
          }
        );
      }
    }
    
    // custom detection strategy
    this.detectChanges();
    
    // just for sure - hide modal if data-dismiss does not work
    (<any>$('#obligationsEModal')).modal('hide');
  }

  // method for updating selected obligation from modal with obligation status E (K vyrizeni)
  updateSelectedObligation(optimal: boolean = false): void {
    // window alert for demo.truckmanager
    if (IS_DEMO) {
      window.alert("Demoverze neumožňuje úpravy dat.");
      return;
    }

    this._obligationSelected.car_key = this._vehicleSelected.car_key;
    this._obligationSelected.status = "A"; // zahajena
    // set flag for not refreshing cars via websocket
    this._updatingFromModal = true;

    if (optimal) {
      // update also itinerary times
      this._obligationServ.updateObligation(this._obligationSelected).subscribe(
        obligation => {
          let currentRequest: number = 0;
          let totalRequests: number = this._obligationSelected.itinerary.length;
          this._obligationSelected.itinerary.forEach(
            it => {
              this._obligationServ.updateItinerary(this._obligationSelected, it).subscribe(
                response => {
                  if (response) {
                    currentRequest += 1;
                    if (currentRequest == totalRequests) {
                      // load agenda 
                      this.loadAgendaInterval();
                      // load obligations
                      this.loadObligationsStatusE();
                    }
                  }
                }
              );
            }
          );
        }
      );
    }
    else {
      // update only obligation, itinerary keeps original arrival_times
      this._obligationServ.updateObligation(this._obligationSelected).subscribe(
        obligation => {
          // load agenda 
          this.loadAgendaInterval();
          // load obligations
          this.loadObligationsStatusE();
        }
      );
    }

    // notify driver
    if (this._obligationSelected.car && this._obligationSelected.car_key) {
      this.sendMessageAboutChange(this._obligationSelected.car, this._obligationSelected.itinerary);
    }
  }


  /*********************************************/
  /* Suggesting express routes on dispatcher board */
  /*********************************************/
  // method for suggesting vehicles to be selected for given express route
  expressRouteSuggest(e: ExpressRoute): void {
    // TOFIX? we keep here just reference of obligation (shouldnt be a big problem?)
    this._expressRouteSelected = e;
    this._vehiclesSuggested = [];

    let tmpVehicles: Array<Vehicle> = this._vehicles;
    // if (obligation.weight) {
    //   tmpVehicles = tmpVehicles.filter(v => v.tonnage && v.tonnage >= obligation.weight);
    // }

    tmpVehicles.forEach(
      v => {
        if (v.agenda && v.agenda.length && e.itineraryOrdered && e.itineraryOrdered.length) {
          let first: Itinerary = e.itineraryOrdered.find(
            i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
          );
          if (first) {
            // distance difference computing
            let dist: number = null;
            let src = first.gps_coord;
            let last = v.agenda[v.agenda.length - 1].last_itinerary;
            let dst = last ? last.gps_coord : null;
            if (src && dst) {
              dist = Haversine.haversine(
                { latitude: src.googleLatLang.lat, longitude: src.googleLatLang.lng },
                { latitude: dst.googleLatLang.lat, longitude: dst.googleLatLang.lng }
              );
              // empiric constant (tahle vzdalenost je vzdusnou carou, tak ji trochu nafukujeme)
              dist *= 1.33;
              dist = Math.round(dist * 10) / 10;

              // save it to suggested vehicle array
              let obj: any = {
                distance: dist,
                last_itinerary_address: last.address_excerpt,
                last_itinerary_time: last.arrival_time,
                vehicle: v
              };
              this._vehiclesSuggested.push(obj);
            }
          }
        }
      }
    );

    if (this._vehiclesSuggested.length) {
      this._vehiclesSuggested.sort((a, b) => (a.distance > b.distance) ? 1 : -1);
    }

    this.detectChanges();
  }

  // method for setting selected vehicle
  setSelectedVehicleExpressRoute(vehicle: Vehicle, optimal: boolean = false) {
    if (!this._expressRouteSelected) return;
    // set vehicle
    this._vehicleSelected = vehicle;

    if (optimal && this._expressRouteSelected.itineraryOrdered && this._expressRouteSelected.itineraryOrdered[0]) {
      // find previous computed distance
      let suggest: any = this._vehiclesSuggested.find(obj => obj.vehicle.number_plate == this._vehicleSelected.number_plate);
      if (suggest && suggest.last_itinerary_time) {
        // dle vzorce - cas posledni nakl/vykl + limit (default 50min) + vzdalenost / rychlost 55 km/h 
        let newStart: number = suggest.last_itinerary_time.getTime();
        
        if (this._expressRouteSelected.itineraryOrdered && this._expressRouteSelected.itineraryOrdered[0]) {
          if (this._expressRouteSelected.itineraryOrdered[0].loading_time_limit) {
            newStart += this._expressRouteSelected.itineraryOrdered[0].loading_time_limit * 60 * 1000;
          }
          else {
            // default 50 min
            newStart += 50 * 60 * 1000;
          }
        }
        else {
          // default 50 min
          newStart += 50 * 60 * 1000;
        }

        if (suggest.distance) {
          newStart += (suggest.distance / 55) * 60 * 60 * 1000;
        }

        // time suggestion optimized
        let first_it: Itinerary = this._expressRouteSelected.itineraryOrdered.find(
          i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
        );
        newStart = this.optimalizeSuggestedTime(newStart, first_it);

        // compute time aligment
        let timeAligment: number = 0;
        timeAligment = this._expressRouteSelected.itineraryOrdered[0].arrival_time.getTime() - newStart;

        // change times of all itinerary
        this._expressRouteSelected.itineraryOrdered.forEach(
          it => {
            if (it.arrival_time) {
              it.arrival_time = new Date(it.arrival_time.getTime() - timeAligment);
              // also optimalize
              it.arrival_time = new Date(this.optimalizeSuggestedTime(it.arrival_time.getTime(), it));
              it.arrival_time_custom = this._datePipe.transform(it.arrival_time, 'yyyy-MM-ddTHH:mm');
            }
          }
        );
      }
    }
    
    // custom detection strategy
    this.detectChanges();
    
    // just for sure - hide modal if data-dismiss does not work
    (<any>$('#expressRoutesEModal')).modal('hide');
  }

  // method for updating selected obligation from modal with obligation status E (K vyrizeni)
  updateSelectedExpressRoute(optimal: boolean = false): void {
    // window alert for demo.truckmanager
    if (IS_DEMO) {
      window.alert("Demoverze neumožňuje úpravy dat.");
      return;
    }

    if (!this._expressRouteSelected) return;

    this._expressRouteSelected.car_key = this._vehicleSelected.car_key;
    this._expressRouteSelected.status = ObligationStatus.ZAHAJENA;
    // set flag for not refreshing cars via websocket
    this._updatingFromModal = true;

    // update express route
    this._subs.push(
      this._expressRouteServ.updateExpressRoute(this._expressRouteSelected).subscribe()
    );

    if (optimal) {
      // update obligations
      this._expressRouteSelected.obligations.forEach(
        o => {
          o.car_key = this._expressRouteSelected.car_key;
          o.status = ObligationStatus.ZAHAJENA;
          this._subs.push(
            this._obligationServ.updateObligation(o).subscribe()
          );
        }
      );

      // update also itinerary times
      let currentRequest: number = 0;
      let totalRequests: number = this._expressRouteSelected.itineraryOrdered.length;
      this._expressRouteSelected.itineraryOrdered.forEach(
        it => {
          this._obligationServ.updateItinerary(it.obligation, it).subscribe(
            response => {
              if (response) {
                currentRequest += 1;
                if (currentRequest == totalRequests) {
                  // load agenda 
                  this.loadAgendaInterval();
                  // load obligations
                  this.loadObligationsStatusE();
                }
              }
            }
          );
        }
      );
    }
    else {
      if (this._expressRouteSelected.obligations) {
        let currentRequest: number = 0;
        let totalRequests: number = this._expressRouteSelected.obligations.length;
        this._expressRouteSelected.obligations.forEach(
          o => {
            o.car_key = this._expressRouteSelected.car_key;
            o.status = ObligationStatus.ZAHAJENA;
            this._subs.push(
              // update only obligation
              this._obligationServ.updateObligation(o).subscribe(
                response => {
                  if (response) {
                    currentRequest += 1;
                    if (currentRequest == totalRequests) {
                      // load agenda 
                      this.loadAgendaInterval();
                      // load obligations
                      this.loadObligationsStatusE();
                    }
                  }
                }
              )
            );
          }
        );
      }
    }

    // notify driver
    if (this._expressRouteSelected.car && this._expressRouteSelected.car_key) {
      this.sendMessageAboutChange(this._expressRouteSelected.car, this._expressRouteSelected.itineraryOrdered);
    }
  }

  
  /********************************************************/
  /**** Favourite obligations duplicating ****/
  /**** Some attributes are common with upper obligationsStatusE logic ****/
  /********************************************************/
  private _obligationsFavourite: Array<Obligation> = [];
  public get obligationsFavourite(): Array<Obligation> {
    return this._obligationsFavourite;
  }

  private _obligationDuplicate: Obligation = null;
  public get obligationDuplicate(): Obligation {
    return this._obligationDuplicate;
  }

  setObligationDuplicate(vehicle: Vehicle): void {
    // set vehicle
    this._vehicleSelected = vehicle;

    // TOFIX? we keep here just reference (shouldnt be problem)
    this._obligationDuplicate = this._obligationSelected;

    // reinits
    this._obligationDuplicate.favourite = false;
    this._obligationDuplicate.obligation_key = null;
    this._obligationDuplicate.route_length_real = 0;
    this._obligationDuplicate.created_time = null;
    this._obligationDuplicate.created_time_input = null;
    this._obligationDuplicate.status = 'A';
    this._obligationDuplicate.car_key = this._vehicleSelected.car_key;
    this._obligationDuplicate.updated_by = null;

    // find previous computed distance
    let suggest: any = this._vehiclesSuggested.find(obj => obj.vehicle.number_plate == this._vehicleSelected.number_plate);
    if (suggest && suggest.last_itinerary_time) {
      // dle vzorce - cas posledni nakl/vykl + limit (default 50min) + vzdalenost / rychlost 55 km/h 
      let newStart: number = suggest.last_itinerary_time.getTime();
      
      if (this._obligationDuplicate.first_itinerary && this._obligationDuplicate.first_itinerary.loading_time_limit) {
        newStart += this._obligationDuplicate.first_itinerary.loading_time_limit * 60 * 1000;
      }
      else {
        // default 50 min
        newStart += 50 * 60 * 1000;
      }

      if (suggest.distance) {
        newStart += (suggest.distance / 55) * 60 * 60 * 1000;
      }

      // time suggestion optimized
      let first_it: Itinerary = this._obligationDuplicate.itinerary.find(
        i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
      );
      newStart = this.optimalizeSuggestedTime(newStart, first_it);

      // compute time aligment
      let timeAligment: number = 0;
      timeAligment = this._obligationDuplicate.first_itinerary_time.getTime() - newStart;

      // change times of all itinerary
      this._obligationDuplicate.itinerary.forEach(
        it => {        
          it.loading_time_real = 0;
          if (it.arrival_time) {
            it.arrival_time = new Date(it.arrival_time.getTime() - timeAligment);
            // also optimalize
            it.arrival_time = new Date(this.optimalizeSuggestedTime(it.arrival_time.getTime(), it));
            it.arrival_time_custom = this._datePipe.transform(it.arrival_time, 'yyyy-MM-ddTHH:mm');
          }
        }
      );
    }
    
    // custom detection strategy
    this.detectChanges();

    // just for sure - hide modal if data-dismiss does not work
    (<any>$('#obligationsFavouriteModal')).modal('hide');
  }
  
  // method for creating new obligation and itinerary
  duplicateSelectedObligation(): void {
    // window alert for demo.truckmanager
    if (IS_DEMO) {
      window.alert("Demoverze neumožňuje úpravy dat.");
      return;
    }
    
    // set flag for not refreshing cars via websocket
    this._updatingFromModal = true;

    this._obligationServ.createObligation(this._obligationDuplicate, this._obligationDuplicate.itinerary).subscribe(
      obligation => {
        if (obligation) {
          // save new obligation object
          this._obligationDuplicate.obligation_key = obligation.obligation_key;
  
          // save itinerary objects of new obligation
          let currentRequest: number = 0;
          let totalRequests: number = this._obligationDuplicate.itinerary.length;
  
          this._obligationDuplicate.itinerary.forEach(
            it => {
              this._obligationServ.createItinerary(this._obligationDuplicate, it).subscribe(
                (newIt: Itinerary) => {
                  if (newIt && newIt.itinerary_key) {
                    currentRequest += 1;
                    if (currentRequest == totalRequests) {
                      // load agenda 
                      this.loadAgendaInterval();
                      // load obligations
                      this.loadObligationsFavourite();
                    }
                  }
                }
              );
            }
          );
          
          // notify driver
          if (this._obligationDuplicate.car && this._obligationDuplicate.car_key) {
            this.sendMessageAboutChange(this._obligationDuplicate.car, this._obligationDuplicate.itinerary);
          }
        }
      }
    );
  }


  private _isToday(d: Date): boolean {
    const today = new Date();
    return d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear();
  }


  // just notification about diary for driver
  sendMessageAboutChange(vehicle: Vehicle, itinerary: Array<Itinerary>): void {
    let msg: string = "Váš diář byl aktualizován! <#agd:>";
    
    if (itinerary && itinerary.length) {
      // check last itinerary arrival time (send message only for obligations that end today or later)
      let last: Itinerary = itinerary[itinerary.length - 1];
      if (last && last.arrival_time) {
        let time = last.arrival_time;
        let now = new Date()

        if (time.getTime() >= now.getTime() || this._isToday(time)) {
          this._messageServ.sendMessageToVehicle(msg, vehicle).subscribe(
            message => {
              console.log(message);
            },
            error => {
              console.log(error);
            }
          );
        }
      }
    }
  }

  /**************************************/
  /* Manage agenda to Timocom */
  /**************************************/
  public timocomOnTheWay: boolean = false;
  public timocomBackHome: boolean = false;

  public reloadTimocomOnTheWay: number = 0;
  performReloadTimocomOnTheWay(): void {
    this.reloadTimocomOnTheWay += 1;
    this.detectChanges();
  }

  public reloadTimocomBackHome: number = 0;
  performReloadTimocomBackHome(): void {
    this.reloadTimocomBackHome += 1;
    this.detectChanges();
  }

  public agendaToTimocomOnTheWay: Agenda = null;
  setAgendaToTimocomOnTheWay(event: any, agenda: Agenda): void {
    this.agendaToTimocomOnTheWay = agenda;
    this.timocomOnTheWay = true;
    this.timocomBackHome = false;
    event.stopPropagation();
    this.detectChanges();
    // open modal for timocom
    (<any>$('#timocomModalOnTheWay')).modal('show');
  }
  
  public agendaToTimocomBackHome: Agenda = null;
  setAgendaToTimocomBackHome(event: any, agenda: Agenda): void {
    this.agendaToTimocomBackHome = agenda;
    this.timocomOnTheWay = false;
    this.timocomBackHome = true;
    event.stopPropagation();
    this.detectChanges();
    // open modal for timocom
    (<any>$('#timocomModalBackHome')).modal('show');
  }

  public finalToHomeStart: number = 100;
  public finalToHomeEnd: number = 100;
  public startToFinalStart: number = 100;
  public startToFinalEnd: number = 100;

  loadCokkie():void{
    let home_start: string = this._storageServ.getItem(
      StorageKeys.dispatcher_board.finalToHomeStart, true
    );
    if (home_start) {
      this.finalToHomeStart = parseInt(home_start);
    }
    
    let home_end: string = this._storageServ.getItem(
      StorageKeys.dispatcher_board.finalToHomeEnd, true
    );
    if (home_end) {
      this.finalToHomeEnd = parseInt(home_end);
    }
    
    let final_start: string = this._storageServ.getItem(
      StorageKeys.dispatcher_board.startToFinalStart, true
    );
    if (final_start) {
      this.startToFinalStart = parseInt(final_start);
    }
    
    let final_end: string = this._storageServ.getItem(
      StorageKeys.dispatcher_board.startToFinalEnd, true
    );
    if (final_end) {
      this.startToFinalEnd = parseInt(final_end);
    }
  }

  saveCokkie():void{
    this._storageServ.setItem(
      StorageKeys.dispatcher_board.finalToHomeEnd, this.finalToHomeEnd.toString(), true
    );
    this._storageServ.setItem(
      StorageKeys.dispatcher_board.finalToHomeStart, this.finalToHomeStart.toString(), true
    );
    this._storageServ.setItem(
      StorageKeys.dispatcher_board.startToFinalStart, this.startToFinalStart.toString(), true
    );
    this._storageServ.setItem(
      StorageKeys.dispatcher_board.startToFinalEnd, this.startToFinalEnd.toString(), true
    );
    
  }





  
  /************************************************************/
  /* TM-agenda itinerary drag and drop div */
  /************************************************************/
  public lastCargoVehicle: Vehicle = null;

  openDragAndDropDiv(): void {
    let div = document.getElementById('dragAndDropDiv');
    if (div) {
      div.style.display = 'block';
    }
    //console.log(this.lastCargoVehicle);
    this.detectChanges();
  }

  hideDragAndDropDiv(): void {
    let div = document.getElementById('dragAndDropDiv');
    if (div) {
      div.style.display = 'none';
    }
    this.detectChanges();
  }


  /**************************************/
  /* Routing stuff */
  /**************************************/
  openObligationNewTab(obligation_key: number): void {
    let url: any = null;
    let queryParams: any = {
      obligation_key: obligation_key,
      reloadPossible: true
    };
    url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {left: 'ta1-obligation/company_obligation', right: 'cars'}}], 
        {queryParams: queryParams}
      )
    );
    window.open('#' + url, '_blank');
  }
  
  openSubsNewTab(): void {
    let url: any = null;
    let queryParams: any = {
      reloadPossible: true
    };
    url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {left: 'general/subscription', right: 'cars'}}], 
        {queryParams: queryParams}
      )
    );
    window.open('#' + url, '_blank');
  }

  openExpressRouteNewTab(express_route_key: number): void {
    let url: any = null;
    let queryParams: any = {
      express_key: express_route_key,
      reloadPossible: true
    };
    url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {left: 'ta1-obligation/express_plan', right: 'cars'}}], 
        {queryParams: queryParams}
      )
    );
    window.open('#' + url, '_blank');
  }
}

