import {animate, state, style, transition, trigger} from "@angular/animations";
import {slideSide} from "./slide-horizontal.animation";
import {BrowserTools} from "../tools/BrowserTools";

export const TruckManagerAnimations = [
  trigger('dashboardMenu', slideSide),
  trigger('dashboard', [
    state('active', style({
      width: BrowserTools.isMobileBrowser() ? 'calc(100% - 50px)' : (BrowserTools.isLargeSize() ? '400px' : '550px')
    })),
    state('inactive', style({
      marginRight: '0px'
    })),
    state('active-full', style({
      width: 'calc(100% - 50px)',
      marginRight: '0px'
    })),
    state('inactive', style({
      width: '100%',
      marginRight: '0px'
    })),
    transition('inactive <=> active', animate('100ms ease-in'))
  ]),
  //depends on dashboard menu to be hidden
  trigger('unreadMessagesIcon', [
    state('active', style({
      opacity: 0
    })),
    state('inactive', style({
      opacity: 1
    })),
    transition('inactive <=> active', animate('100ms ease-in'))
  ]),
  trigger('dashboardVisible', [
    state('active', style({
      width: '*'
    })),
    state('inactive', style({
      width: '0px'
    })),
    state('dashboardfull', style({
      width: '100%'
    })),
    transition('* <=> *', animate('100ms ease-in'))
  ]),
  trigger('wide', [
    state('active', style({})),
    state('inactive', style({
      width: '100%'
    })),
    state('dashboardfull', style({
      width: '0%'
    })),
    transition('* <=> *', animate('100ms ease-in'))
  ])

];
