import { DateTools } from "../tools/DateTools";
import { Itinerary } from "./itinerary.object";
import { MessageAttachmentObject } from "./message-attachment.object";
import { Obligation } from "./obligation.object";
import { Order } from "./order.object";
import { Vehicle } from "./vehicle.object";

export class Message {

  static TYPE_DISPATCHER = 'D';
  static TYPE_PRIVATE = 'P';
  static TYPE_DRIVERS = 'F';
  static TYPE_CUSTOMER = 'C';

  private _msg_key: number;
  get msg_key(): number {
    return this._msg_key;
  }
  set msg_key(value: number) {
    this._msg_key = value;
  }

  private _company_key: number;
  get company_key(): number {
    return this._company_key;
  }
  set company_key(value: number) {
    this._company_key = value;
  }

  private _direction_to_car: boolean;
  get direction_to_car(): boolean {
    return this._direction_to_car;
  }
  set direction_to_car(value: boolean) {
    this._direction_to_car = value;
  }

  private _person_key: number;
  get person_key(): number {
    return this._person_key;
  }
  set person_key(value: number) {
    this._person_key = value;
  }

  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }

  private _msg: string;
  get msg(): string {
    return this._msg;
  }
  set msg(value: string) {
    this._msg = value;
  }

  private _unread: boolean;
  get unread(): boolean {
    return this._unread;
  }
  set unread(value: boolean) {
    this._unread = value;
  }

  private _forwarded_to_sms: boolean;
  get forwarded_to_sms(): boolean {
    return this._forwarded_to_sms;
  }
  set forwarded_to_sms(value: boolean) {
    this._forwarded_to_sms = value;
  }

  private _time: Date;
  get time(): any | Date {
    return this._time;
  }
  set time(value: any) {
    if (value) {
      this._time = DateTools.isoFix(value.toString());
    }
  }

  private _sent: boolean;
  get sent(): boolean {
    return this._sent;
  }
  set sent(value: boolean) {
    this._sent = value;
  }

  private _forwarded_to_email: boolean;
  get forwarded_to_email(): boolean {
    return this._forwarded_to_email;
  }
  set forwarded_to_email(value: boolean) {
    this._forwarded_to_email = value;
  }

  private _system_msg: boolean;
  get system_msg(): boolean {
    return this._system_msg;
  }
  set system_msg(value: boolean) {
    this._system_msg = value;
  }

  private _time_tm: Date;
  get time_tm(): any | Date {
    return this._time_tm;
  }
  set time_tm(value: any) {
    if (value) {
      this._time_tm = DateTools.isoFix(value.toString());
    }
  }

  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }

  private _driver_key: number;
  get driver_key(): number {
    return this._driver_key;
  }
  set driver_key(value: number) {
    this._driver_key = value;
  }

  private _read_by_tm: Date;
  get read_by_tm(): any | Date {
    return this._read_by_tm;
  }
  set read_by_tm(value: any) {
    if (value) {
      this._read_by_tm = DateTools.isoFix(value.toString());
    }
  }

  private _partner_company_key: number;
  get partner_company_key(): number {
    return this._partner_company_key;
  }
  set partner_company_key(value: number) {
    this._partner_company_key = value;
  }

  private _ignore_processing: boolean;
  get ignore_processing(): boolean {
    return this._ignore_processing;
  }
  set ignore_processing(value: boolean) {
    this._ignore_processing = value;
  }

  private _sent_time: Date;
  get sent_time(): any | Date {
    return this._sent_time;
  }
  set sent_time(value: any) {
    if (value) {
      this._sent_time = DateTools.isoFix(value.toString());
    }
  }

  private _forwarded_to_sms_time: Date;
  get forwarded_to_sms_time(): any | Date {
    return this._forwarded_to_sms_time;
  }
  set forwarded_to_sms_time(value: any) {
    if (value) {
      this._forwarded_to_sms_time = DateTools.isoFix(value.toString());
    }
  }

  private _push_attempt_time: Date;
  get push_attempt_time(): any | Date {
    return this._push_attempt_time;
  }
  set push_attempt_time(value: any) {
    if (value) {
      this._push_attempt_time = DateTools.isoFix(value.toString());
    }
  }

  private _system_msg_time: Date;
  get system_msg_time(): any | Date {
    return this._system_msg_time;
  }
  set system_msg_time(value: any) {
    if (value) {
      this._system_msg_time = DateTools.isoFix(value.toString());
    }
  }

  private _delivery_time: Date;
  get delivery_time(): any | Date {
    return this._delivery_time;
  }
  set delivery_time(value: any) {
    if (value) {
      this._delivery_time = DateTools.isoFix(value.toString());
    }
  }

  private _msg_raw: string;
  get msg_raw(): string {
    return this._msg_raw;
  }
  set msg_raw(value: string) {
    this._msg_raw = value;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  set vehicle(value: Vehicle) {
    this._vehicle = value;
  }

  private _itinerary_key: number = null;
  public get itinerary_key(): number {
    return this._itinerary_key;
  }
  public set itinerary_key(value: number) {
    this._itinerary_key = value;
  }

  // custom
  private _itinerary: Itinerary = null;
  public get itinerary(): Itinerary {
    return this._itinerary;
  }
  public set itinerary(value: Itinerary) {
    this._itinerary = value;
  }

  private _obligation_key: number = null;
  public get obligation_key(): number {
    return this._obligation_key;
  }
  public set obligation_key(value: number) {
    this._obligation_key = value;
  }
  
  // custom
  private _obligation: Obligation = null;
  public get obligation(): Obligation {
    return this._obligation;
  }
  public set obligation(value: Obligation) {
    this._obligation = value;
  }
  
  private _order_key: number = null;
  public get order_key(): number {
    return this._order_key;
  }
  public set order_key(value: number) {
    this._order_key = value;
  }
  
  // custom
  private _order: Order = null;
  public get order(): Order {
    return this._order;
  }
  public set order(value: Order) {
    this._order = value;
  }

  private _attachment: MessageAttachmentObject;
  get attachment(): MessageAttachmentObject {
    return this._attachment;
  }
  set attachment(value: MessageAttachmentObject) {
    if (value instanceof MessageAttachmentObject) {
      this._attachment = value;
    }
  }

  private _reply: boolean = false;
  get reply(): boolean {
    return this._reply;
  }
  
  get replyOpenCssClass(): string {
    return this._reply ? 'open' : '';
  }

  get delivery_time_formatted(): string {
    if (!this._delivery_time) {
      return '-';
    }
    return DateTools.formatLocaleString(this._delivery_time, '%hours:%minutes');
  }

  get time_formatted(): string {
    if (!this._time) {
      return '-';
    }
    return DateTools.formatLocaleString(this._time, '%hours:%minutes');
  }

  get message_row_background_color(): string {
    let color: string = 'system';
    if (this.system_msg && this.order_key) {
      return 'system-order';
    }
    else if (!this.system_msg && !this.direction_to_car) {
      return this.type === Message.TYPE_DISPATCHER ? 'to-car-d' : 'to-car-c';
    }
    else if (!this.system_msg && this.direction_to_car) {
      return this.type === Message.TYPE_DISPATCHER ? 'not-to-car-d' : 'not-to-car-c';
    }
    return color;
  }

  get sent_time_formatted(): string {
    if (!this._sent_time) {
      return '';
    }
    return DateTools.formatLocaleString(this._sent_time, '%day.%month. %hours:%minutes');
  }

  get read_by_tm_formatted(): string {
    if (!this._read_by_tm) {
      return '';
    }
    return DateTools.formatLocaleString(this._read_by_tm, '%day.%month. %hours:%minutes');
  }

  get forwarded_to_sms_time_formatted(): string {
    if (!this._forwarded_to_sms_time) {
      return '';
    }
    return DateTools.formatLocaleString(this._forwarded_to_sms_time, '%day.%month. %hours:%minutes');
  }

  doReply(whatToDo?: boolean) {
    if (!whatToDo) {
      whatToDo = !this._reply;
    }
    this._reply = whatToDo;
  }

  private parseAttachments(msg: string) {
    let attachments = msg.match(/<#\w:(.+)>/);
    if (attachments !== null) {
      attachments.forEach(
        attachment => {
          //this._attachment = new MessageAttachmentObject(ServiceConfiguration.message.filesApi,attachment,this._car_key);
          //multiple attachments?
          return false;
        }
      )
    }
  }
}
