export const secured:boolean = (window.location.protocol.match(/https/) != null);
export const apiHost:string = 'https://app2.truckmanager.eu';
export const connections:any = {
    apiUrl : '/nettedev/api/v1',
    iframeSrcUnathenticated : '/front/%LOCALE%/',
    iframeScrAuthenticated : '/eso/',
    pingUrl : window.location.host,
    websocket : (secured ? 'wss' : 'ws') + "://app2.truckmanager.eu/ws/"
};
export const APP_MODE_FULL:string = 'full';
export const APP_MODE_DEMO:string = 'demo';
export const APP_MODE_FREE:string = 'free';

export const MODE:string = APP_MODE_FULL;

export const IS_DEMO = MODE === APP_MODE_DEMO;
export const IS_FREE = MODE === APP_MODE_FREE;
