import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { IS_DEMO, ServiceConfiguration } from "../config";
import { connections } from "../connect";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { Delegation } from "../model/delegation.object";

@Injectable({
  providedIn: 'root'
})
export class DelegationsService {

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService
  ) { 
  }

  // method for getting obligations with given filter object
  getDelegationsList(): Observable<Array<any>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      return this._http.get(ServiceConfiguration.delegations.api);
    }
  }

  // method for creating new delegation
  createDelegation(action: string, data: any): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.delegations.apiPost.replace('%ACTION%', action);

      return this._http.post(url, data);
    }
  }


  // method for creating delegation objects from api objects
  public buildDelegationsFromData(data: Array<any>): any {
    if (!data) return [];

    let delegations: Array<Delegation> = [];
    data.forEach(
      d => {
        let delegation: Delegation = this.buildDelegation(d);
        delegations.push(delegation);
      }
    );
    return delegations;
  }
  
  // method for creating a single delegation object
  public buildDelegation(d: any): Delegation {
    let delegation: Delegation = new Delegation();

    for (let key in d) {
      delegation[key] = d[key];
    }

    return delegation;
  }
}
