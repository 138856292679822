<div *ngIf="vehicle" id="map_canvas_{{vehicle.car_key}}"
  class="google-map-container googleMapManualTracking position-relative"
  [style.height]="heightMap"
  [vehicle]="vehicle"
  [markers]="itineraryMarker"
  [markerDestRoute]="markerDestRoute"
  [directionsDraggable]="itinerary.manualTrackingMode" 
  [createDirectionDestRoute]="itinerary.manualTrackingMode" 
  (directionsChange)="directionsChange($event)">
  <!-- [obligationTrackingEvents]="trackingEvents" -->
  <!-- [centerOnMarkers]="true" [type]="'map'" -->
</div>

<div class="py-1" *ngIf="vehicle && !itinerary.hasStarted && !itinerary.isDone">
  <div *ngIf="!vehicle.temporaryManualTrackingTarget">
    <div *ngIf="!isTargetForItinerary(itinerary)" class="d-flex justify-content-center">
      <!-- invoke preview of destination route -->
      <div *ngIf="!itinerary.manualTrackingMode">
        <button class="btn btn-warning" 
        (click)="setVehicleTarget(itinerary)">
          <ng-container i18n>Nastavit dojezdový cíl</ng-container>
        </button>
      </div>
      
      <!-- spinner for simulation of loading map -->
      <div *ngIf="itinerary.manualTrackingModeLoading" class="spinner-wrapper py-2">
        <div class="spinner-border text-primary" style="width:2rem; height:2rem;" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
      <div *ngIf="itinerary.manualTrackingMode && !itinerary.manualTrackingModeLoading">
        <button  class="btn btn-success" (click)="saveVehicleTarget(itinerary)">
          <ng-container i18n>Uložit dojezdový cíl</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>