import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IS_DEMO, ServiceConfiguration } from "../config";
import { AuthenticationService } from "./authentication.service";
import { WebsocketService } from "./websocket.service";

@Injectable({
  providedIn: 'root',
})
export class WebConnectivityService {

  private _lastUpTime: Date = new Date();
  private _connectionUp: BehaviorSubject<Date> = new BehaviorSubject(this._lastUpTime);
  private _intervalId: number;
  private _pingInterval: number = ServiceConfiguration.webConnectivity.pingInterval;
  private _pingUrl: string = ServiceConfiguration.webConnectivity.pingUrl;
  private _intervalReconnected: number = ServiceConfiguration.webConnectivity.intervalReconnected;

  constructor(
    private _http: HttpClient, 
    private _websocket: WebsocketService, 
    private _authService: AuthenticationService
  ) {
    // fix for pinging after localization
    if (this._pingUrl.includes('localhost')) {
      this._pingUrl = 'https://app2.truckmanager.eu/cs/';
    }
    else if (this._pingUrl.includes('app2')) {
      this._pingUrl = 'https://app2.truckmanager.eu/cs/';
    }
    else if (this._pingUrl.includes('app')) {
      this._pingUrl = 'https://app.truckmanager.eu/cs/';
    }
    else if (this._pingUrl.includes('app')) {
      this._pingUrl = 'https://app.truckmanager.eu/cs/';
    }
    else if (this._pingUrl.includes('demo2')) {
      this._pingUrl = 'https://demo2.truckmanager.eu/cs/';
    }
    else if (this._pingUrl.includes('demo')) {
      this._pingUrl = 'https://demo.truckmanager.eu/cs/';
    }

    this.startPing();
  }

  private _reconnectedAfterInterval: Subject<boolean> = new Subject();
  get reconnectedAfterInterval(): Observable<boolean> {
    return this._reconnectedAfterInterval.asObservable();
  }

  private _isConnectionDown: boolean = false;
  private set isConnectionDown(value: boolean) {
    this._isConnectionDown = value;
  }

  private _connectionDown: BehaviorSubject<boolean> = new BehaviorSubject(this._isConnectionDown);
  get connectionDown(): Observable<boolean> {
    return this._connectionDown.asObservable();
  }

  isConnectionUp(): Observable<Date> {
    return this._connectionUp.asObservable();
  }

  startPing(): Observable<Date> | boolean {
    if (this._intervalId) {
      return false;
    }
    this._intervalId = window.setInterval(
      () => {
        this.doRequest();
      },
      this._pingInterval
    );
    return this._connectionUp.asObservable();
  }

  stopPing() {
    window.clearInterval(this._intervalId);
    this._intervalId = null;
  }

  private doRequest() {
    let options = {
      responseType: 'text' as 'json'
    };

    return this._http.get<string>(this._pingUrl, options).subscribe(
      success => {
        let wasBefore: boolean = this._isConnectionDown;
        if (wasBefore && ((this._lastUpTime.getTime() - Date.now()) + this._intervalReconnected < 0)) {
          console.warn('PINGING AFTER OFFLINE IN INTERVAL ')
          this._reconnectedAfterInterval.next(true);
        }

        if (this._websocket.connected) {
          this.isConnectionDown = false;
          this._lastUpTime = new Date();
          this._connectionUp.next(this._lastUpTime);
        } 
        else if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
          this.isConnectionDown = true;
          this._websocket.reconnect();
        }

        if ((wasBefore && !this._isConnectionDown) || (!wasBefore && this._isConnectionDown)) {
          this._connectionDown.next(this._isConnectionDown);
        }
      },
      error => {
        console.warn(error);
        this.isConnectionDown = true;
        this._connectionDown.next(this._isConnectionDown);
      }
    );
  }
}
