import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, AfterViewChecked, ChangeDetectionStrategy } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";

import { UserConfigurationService } from "../../service/user-configuration.service";
import { AetrDataCollectionObject } from "../../model/aetr-data-collection.object";
import { Vehicle } from "../../model/vehicle.object";
import { AetrCollectionPartInterface } from "../../interface/aetr-collection-part.interface";
import { DateTools } from "../../tools/DateTools";

@Component({
  selector: 'div.aetr',
  templateUrl: './r-aetr-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./r-aetr-row.component.css']
})
export class RAetrRowComponent implements OnInit, OnDestroy, AfterViewChecked {

  private _subscriptions: Array<Subscription> = [];
  private _sleepInterval: number;
  private _rand;

  private _aetr_collection: AetrDataCollectionObject;
  get aetr_collection(): AetrDataCollectionObject {
    return this._aetr_collection;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    if (vehicle) {
      this._vehicle = vehicle;
    }
  }

  private _aetrErrors: Array<string> = [];
  get aetrErrors(): Array<string> {
    return this._aetrErrors;
  }

  constructor(
    private _datePipe: DatePipe,
    private _cdr: ChangeDetectorRef
  ) {
    this._rand = Math.random();

    // custom change detection strategy
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 10000
    );
  }

  ngOnInit() {
    this._subscriptions.push(
      this._vehicle.aetr_loaded.subscribe(
        aetr => {
          if (!aetr) {
            return this._vehicle.aetr;
          }
          this._aetr_collection = aetr;
          this._aetrErrors = [];
          this._aetrErrors = aetr.work_shift_errors;
          this.buildSleepInterval(this._cdr);

          this.runWorkloadCurrent();
          this.runWorkloadPast();

          // custom detection strategy
          this.detectChanges();
        }
      )
    );
    // this._subscriptions.push(
    //   this._vehicle.latestAetr.subscribe(
    //     (last_aetr: Array<AetrActivityDataInterface>) => {
    //       this._aetrErrors = [];
    //       if (last_aetr && last_aetr.length) {
    //         last_aetr.forEach(
    //           aetr => {
    //             if (aetr.aetr_error) {
    //               // fix of concat aetr errors
    //               this._aetrErrors = this._aetrErrors.concat(aetr.aetr_error);
    //             }
    //           }
    //         );
    //         this._changeDet.detectChanges();
    //       }
    //     }
    //   )
    // );
    this._aetr_collection = this._vehicle.aetr;
    // custom detection strategy
    this.detectChanges();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    if (this._sleepInterval) {
      window.clearInterval(this._sleepInterval);
    }
  }
  
  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // HOT FIX for ExpressionChangedAfterItHasBeenCheckedError
    // this.runWorkloadCurrent();
    // this._cdr.detectChanges();
  }

  detectChanges(): void {
    // detect changes 100 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }

  rand(): number {
    return this._rand;
  }

  // vykon/jizda v minulem segmentu
  public workloadPast: string = '0h';
  runWorkloadPast(): void {
    if (this._aetr_collection && this._aetr_collection.work_shift_start && this._aetr_collection.pastSegment) {
      let segment: AetrCollectionPartInterface = this._aetr_collection.pastSegment;
      let work_shift_start: Date = DateTools.isoFix(this._aetr_collection.work_shift_start);
      // div miliseconds with 1000 and 60 to minutes, and again 60 to hours
      let hours: number = (segment.date_stop.getTime() - work_shift_start.getTime()) / (1000*60*60);
      let minutes: number = ((segment.date_stop.getTime() - work_shift_start.getTime()) / (1000*60)) % 60;
      hours = Math.floor(hours);
      minutes = Math.floor(minutes);

      this.workloadPast = ((hours < 10 ? '0' : '') + hours.toString()) + ':' + ((minutes < 10 ? '0' : '') + minutes.toString());
    }
  }

  // vykon jizda v soucasnem segmentu -> obnovovat pomoci timeoutu
  public workloadCurrent: string = '0h';
  runWorkloadCurrent(): void {
    if (this._aetr_collection && this._aetr_collection.pastSegment) {
      let segment: AetrCollectionPartInterface = this._aetr_collection.pastSegment;
      let now: Date = new Date();
      // div miliseconds with 1000 and 60 to minutes, and again 60 to hours
      let hours: number = (now.getTime() - segment.date_stop.getTime()) / (1000*60*60);
      let minutes: number = ((now.getTime() - segment.date_stop.getTime()) / (1000*60)) % 60;
      hours = Math.floor(hours);
      minutes = Math.floor(minutes);

      this.workloadCurrent = ((hours < 10 ? '0' : '') + hours.toString()) + ':' + ((minutes < 10 ? '0' : '') + minutes.toString());
    }

    window.setTimeout(
      () => { 
        this.runWorkloadCurrent(); 
        // custom detection strategy
        this.detectChanges();
      }, 10000
    );
  }

  duration(segment: AetrCollectionPartInterface): string {
    return DateTools.getHumanReadHoursAndMinutes(segment.length * 1000);
  }

  start(): string {
    let segment: AetrCollectionPartInterface = this._aetr_collection.pastSegment;
    if (segment) {
      return DateTools.formatLocaleString(segment.date_stop, '%hours:%minutes');
    }
    return '0';
    // let start = new Date(Date.now() - segment.length * 1000);
    // return DateTools.formatLocaleString(start, '%hours:%minutes');
  }

  end(segment: AetrCollectionPartInterface): string {
    return DateTools.formatLocaleString(segment.date_stop, '%hours:%minutes');
  }

  doneDriving(segment: AetrCollectionPartInterface): string {
    return Math.round(segment.distance) + UserConfigurationService.global_length_unit;
  }

  endDay(segment: AetrCollectionPartInterface): string {
    let day: Date;
    switch (true) {
      case this.aetr_collection.isSegmentPast(segment):
        day = segment.date_stop;
        break;
      case this.aetr_collection.isSegmentFuture(segment):
        //not yet needed
        break;
      default:
        day = new Date(Date.now() + segment.remaining * 1000);
        break;
    }
    return this._datePipe.transform(day, 'EEEEEE');
  }

  startDay(segment: AetrCollectionPartInterface): string {
    let day: Date;
    switch (true) {
      case this.aetr_collection.isSegmentPast(segment):
        day = segment.date_start;
        break;
      case this.aetr_collection.isSegmentFuture(segment):
        //not yet needed
        break;
      default:
        day = new Date(Date.now() - segment.length * 1000);
        break;
    }
    return this._datePipe.transform(day, 'EEEEEE');
  }

  buildSleepInterval(changeDetector: ChangeDetectorRef) {
    if (this._aetr_collection 
      && this._aetr_collection.currentSegment.type === AetrDataCollectionObject.AETR_PART_TYPE_SLEEP 
      && !this._sleepInterval 
      && this._aetr_collection.currentSegment.remaining > 0) {
      this._sleepInterval = window.setInterval(
        () => {
          if (this._aetr_collection.currentSegment.remaining > 60) {
            this._aetr_collection.currentSegment.remaining -= 60;
          } else {
            this._aetr_collection.currentSegment.remaining = 0;
            window.clearInterval(this._sleepInterval);
          }
          // custom detection strategy
          this.detectChanges();
        }, 60000
      )
    }
  }

  formatDriveStyle(segment: AetrCollectionPartInterface): number {
    return Math.round(segment.drive_style * 10) / 10;
  }

  haveDriver(): boolean {
    return this._vehicle && typeof this._vehicle.driver_key === 'number';
  }
}
