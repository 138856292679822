export class Numbers {

  static round(number: number, precision: number): number | string {
    precision = Math.abs(precision);
    if (precision === 0) {
      return Math.round(number);
    }
    let result = Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision);
    let stringResultParts = result.toString().split('.');
    if (stringResultParts.length === 1 && precision > 0) {
      stringResultParts.push('0'.repeat(precision));
      return stringResultParts.join('.');
    } else if (stringResultParts.length === 2 && stringResultParts[1].length < precision) {
      stringResultParts[1] += '0'.repeat(precision - stringResultParts[1].length);
      return stringResultParts.join('.');
    }
    return result;
  }

  // https://stackoverflow.com/a/149107
  // 1 000.00
  static toOurPriceFormat(number: number): string {
    if (number) {
      let formatted: string = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
      return formatted.replace(".", ",");
    }
    return '';
  }
  
  // https://stackoverflow.com/a/149107
  // 1 000.000
  static toOurWeightFormat(number: number): string {
    if (number) {
      let formatted: string = number.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$& ');
      return formatted.replace(".", ",");
    }
    return '';
  }

  // https://stackoverflow.com/a/2901298/8226013
  static toOurKilometersFormat(number: number): string {
    if (number) {
      let formatted: string = number.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return formatted;
    }
    return '';
  }

}