<!-- MODAL here need fix -->
<ng-container *ngIf="!attachment">
  <ng-container i18n>Poškozená příloha</ng-container>
</ng-container>
<ng-container *ngIf="attachment">
  <a class="pointer" tabindex="1" (click)="showDialog()">
    <img class="pointer" src="assets/icons-new/loudspeaker4.svg" alt="loudspeaker4" height="18px"/>
  </a>
  <div *ngIf="visible">
    <div id="myModal" class="modal" role="dialog" style="display: block">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">

            <h4 class="modal-title">{{attachment.fileName}}</h4>
            <button (click)="closeDialog()" type="button" class="close">&times;</button>
          </div>
          <div class="modal-body messages-dialog">
            <div class="container-fluid">
              <div class="row">
                <video class="col-12" *ngIf="attachment.isVideo()" width="320" height="240" controls>
                  <source [src]="attachment.getFormattedUrlString()|safeResource"
                  [type]="attachment.attachmentType">
                  <ng-container i18n>Prohlížeč nepodporuje přehrání videa</ng-container>
                </video>
                <audio class="col-12" *ngIf="attachment.isAudio()" controls>
                  <source [src]="attachment.getFormattedUrlString()|safeResource"
                  [type]="attachment.attachmentType">
                  <ng-container i18n>Prohlížeč nepodporuje přehrání audia</ng-container>
                </audio>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="modal-backdrop fade in"></div>
  </div>
</ng-container>