declare var google: any;

export class GoogleMapTools {
  /*************************************************************/
  // Map properties / options
  /*************************************************************/
  public static RendererOptions = {
    draggable: false,
    suppressMarkers: true,
    preserveViewport: true,
    polylineOptions: {
      strokeColor: '#0075f2',
      strokeOpacity: 0.4,
      strokeWeight: 10,
      zIndex: 200,
      scaleControl: true,
    }
  };

  public static getProperties(opts: any): any {
    let options: any = Object.assign(
      {
        center: new google.maps.LatLng(49.560852, 15.946655),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scaleControl: true,
        scrollwheel: false,
        zoom: 10
      },
      opts
    );

    return options;
  }
}