import {Pipe, PipeTransform} from "@angular/core";
import {UserConfigurationService} from "../service/user-configuration.service";

@Pipe({
  name: 'configuration'
})
export class ConfigurationPipe implements PipeTransform {

  constructor(private userConfig: UserConfigurationService) {
  }

  transform(configKey: string, section: string): any {
    switch (section) {
      case 'vehicle':
        return this.userConfig.configuration.defaultVehicleListConfiguration[configKey];
      case 'messages':
        return this.userConfig.configuration.defaultMessagesConfiguration[configKey];
    }
    return null;
  }
}
