export class CompanyContact {
  private _contact_key: number;
  public get contact_key(): number {
    return this._contact_key;
  }
  public set contact_key(value: number) {
    this._contact_key = value;
  }

  private _by_name: boolean;
  public get by_name(): boolean {
    return this._by_name;
  }
  public set by_name(value: boolean) {
    this._by_name = value;
  }
  
  private _forward_tm: boolean;
  public get forward_tm(): boolean {
    return this._forward_tm;
  }
  public set forward_tm(value: boolean) {
    this._forward_tm = value;
  }
  
  private _importance: number;
  public get importance(): number {
    return this._importance;
  }
  public set importance(value: number) {
    this._importance = value;
  }

  private _local_tips: boolean;
  public get local_tips(): boolean {
    return this._local_tips;
  }
  public set local_tips(value: boolean) {
    this._local_tips = value;
  }
  
  private _send_tips: boolean;
  public get send_tips(): boolean {
    return this._send_tips;
  }
  public set send_tips(value: boolean) {
    this._send_tips = value;
  }
  
  // types dictionary
  // E - persons email
  // U - accountant (ucetni) email
  // S - servis email
  // T - phone number (pevna linka)
  // G - gsm mobile number
  // F - fax
  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  private _value: string;
  public get value(): string {
    return this._value;
  }
  public set value(value: string) {
    this._value = value;
  }

  get apiObject(): any {
    return {
      by_name: this.by_name,
      forward_tm: this.forward_tm,
      importance: this.importance,
      local_tips: this.local_tips,
      send_tips: this.send_tips,
      type: this.type,
      value: this.value,
    }
  }
}