import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";
import { StorageService } from "../../service/storage.service";
import { Vehicle } from "../../model/vehicle.object";

declare var $: any;

@Component({
  selector: 'vehicle-grid-dialog',
  templateUrl: './r-vehicle-grid-dialog.component.html',
  styleUrls: ['./r-vehicle-grid-dialog.component.css']
})
export class RVehicleGridDialogComponent implements OnInit {

  static CONFIGURATION_STORAGE_KEY = 'gridCompositionDialogConfig';
  private _vehicleContainerWidthCritical: boolean = false;
  private _map_heigth: number;

  public aetr_show: boolean = true;
  public gantt_show: boolean = true;
  public last_cargo_graphic_show: boolean = true;
  public last_cargo_text_show: boolean = true;
  public show_map: boolean = true;
  public show_new_messages: boolean = true;
  public trailer_show: boolean = true;
  public co_driver_show: boolean = true;
  public note_show: boolean = true;
  public truncate_note: number = null;
  public truncate_city: number = null;
  public font_size: string = '100%';
  public close: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private _router: Router, 
    private _layout: TruckManagerLayoutService, 
    private _storage: StorageService
  ) {
    this._map_heigth = parseInt(_layout.getVehicleDetailHeight());
  }

  ngOnInit() {
    let setupFromStorage: any;
    switch (true) {
      case window.innerWidth >= 225:
        this.font_size = 80 + '%';
        break;
      case window.innerWidth < 285:
        this.font_size = 60 + '%';
        break;
    }
    if (setupFromStorage = this._storage.getItem(
      RVehicleGridDialogComponent.CONFIGURATION_STORAGE_KEY, true
    )) {
      setupFromStorage = JSON.parse(setupFromStorage);
      for (let key in setupFromStorage) {
        this[key] = setupFromStorage[key];
      }
    }
    if (this.rows === 0 && this.columns === 0) {
      this.computeComposition();
    }

    // default showed
    (<any>$('#gridModal')).modal('show');
  }

  private _vehicles: Array<Vehicle> = [];
  @Input()
  set vehicles(vehicles: Array<Vehicle>) {
    if (vehicles.length) {
      this._vehicles = vehicles.filter(
        (vehicle: Vehicle) => {
          if (vehicle.last_position) {
            return vehicle;
          }
        }
      );
    }
  }

  @Output()
  get closed(): Observable<boolean> {
    return this.close.asObservable();
  }

  get windowWidth(): number {
    return window.innerWidth;
  }

  get vehicleContainerWidth(): number {
    return this.computeVehicleContainerWidth();
  }

  get vehicleContainerHeight(): string {
    return this._map_heigth + 'px';
  }

  get vehicleCount(): number {
    return this._vehicles.length;
  }

  get windowHeight(): number {
    return window.innerHeight;
  }

  private _columns: number = 0;
  get columns(): number {
    return this._columns;
  }
  set columns(value: number) {
    if (value > this.vehicleCount) {
      value = this.vehicleCount;
    }
    this._columns = value;
    this.recomputeRows();
  }

  private _rows: number = 0;
  get rows(): number {
    return this._rows;
  }
  set rows(value: number) {
    if (value > this.vehicleCount) {
      value = this.vehicleCount;
    }
    this._rows = value;
    this.recomputeColumns();
  }

  get map_height(): number {
    return this._map_heigth
  }
  set map_height(value: number) {
    this._map_heigth = value;
  }

  get map_height_px(): string {
    return this.map_height + 'px';
  }

  confirmSetup(event) {
    let setup = this.getSetup();
    let toStorageSetup = setup;
    this._storage.setItem(
      RVehicleGridDialogComponent.CONFIGURATION_STORAGE_KEY, JSON.stringify(toStorageSetup), true
    );
    window.open('/#' + this._router.serializeUrl(this._router.createUrlTree([{outlets: {right: 'cars-grid'}}])));
    this.closeDialog();
  }

  closeDialog() {
    (<any>$('#gridModal')).modal('hide');
    this.close.emit(true);
  }

  private getSetup(): any {
    let setup = {
      keys: [],
      rows: this.rows,
      columns: this.columns,
      width: this.computeVehicleContainerWidth(),
      map_height: this._map_heigth,
      aetr_show: this.aetr_show,
      gantt_show: this.gantt_show,
      last_cargo_graphic_show: this.last_cargo_graphic_show,
      last_cargo_text_show: this.last_cargo_text_show,
      show_map: this.show_map,
      show_new_messages: this.show_new_messages,
      trailer_show: this.trailer_show,
      co_driver_show: this.co_driver_show,
      note_show: this.note_show,
      truncate_note: this.truncate_note,
      truncate_city: this.truncate_city,
      font_size: this.font_size
    };
    this._vehicles.forEach(
      vehicle => setup.keys.push(vehicle.car_key)
    );

    return setup;
  }

  private computeComposition() {
    let square: number;
    let ideal = (square = Math.sqrt(this._vehicles.length)) % 1 == 0;
    let two = (this._vehicles.length / 2) % 1 == 0;
    let three = (this._vehicles.length / 3) % 1 == 0;
    let four = (this._vehicles.length / 4) % 1 == 0;
    let five = (this._vehicles.length / 5) % 1 == 0;
    let six = two && three;

    switch (true) {
      case ideal:
        this._columns = square;
        this._rows = square;
        break;
      case six:
        this.setComposition(6);
        break;
      case five:
        this.setComposition(5);
        break;
      case four:
        this.setComposition(4);
        break;
      case three:
        this.setComposition(3);
        break;
      case two:
        this.setComposition(2);
        break;

    }
  }

  private setComposition(base: number) {
    let column: number, row: number, vehiclesLength: number = this._vehicles.length;
    let diff = vehiclesLength / base;
    switch (true) {
      case base < diff && this.windowHeight < this.windowWidth:
        column = diff;
        row = base;
        break;
      case base > diff && this.windowHeight < this.windowWidth:
        column = base;
        row = diff;
        break;
      case base < diff && this.windowHeight > this.windowWidth:
        row = diff;
        column = base;
        break;
      case base > diff && this.windowHeight > this.windowWidth:
        row = base;
        column = diff;
        break;
    }
    this._columns = column;
    this._rows = row;
  }

  private computeVehicleContainerWidth(): number {
    return Math.round((this.windowWidth)/**bootstrap padding**/ / this.columns);
  }

  private recomputeRows() {
    this._rows = Math.ceil(this.vehicleCount / this._columns);
  }

  private recomputeColumns() {
    this._columns = Math.ceil(this.vehicleCount / this._rows);
  }
}