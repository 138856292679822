<div class="container-fluid" *ngIf="vehicle?.last_position; else missingLastPosition">
  <div class="row py-2">
    <div class="col-md-5 mb-2">
      <h4 class="modal-title">{{vehicle.number_plate}} {{vehicle.driver_name}}</h4>
    </div>
    <div class="col-md-7 mb-2">
      <form (submit)="search()" class="input-group">
        <input type="text" class="form-control" autofocus="autofocus" 
        id="search-input" name="address" [(ngModel)]="address" placeholder="Zadejte adresu"/>
        <div class="input-group-append">
          <a *ngIf="!loading" (click)="search()" class="btn btn-outline-warning">
            <img src="assets/icons-new/search-solid.svg" alt="search-solid" height="16px"/>
          </a>
          <span *ngIf="loading" class="btn-outline-primary">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          </span>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="errorMessage" class="row text-danger mb-2">
    <div class="col-12">{{errorMessage}}</div>
  </div>

  <ng-container *ngIf="markers.length > 0">
    <div class="row google-map-wrapper mb-2" style="height: 60vh">
      <div class="col-12 googleMapManualTracking"
      [properties]="mapProperties"
      [vehicle]="vehicle"
      [markerDestRoute]="destMarker"
      [drawMarkerDestRoute]="true"
      [directionsDraggable]="true" 
      [createDirectionDestRoute]="createDirectionDestRoute"  
      [drawAllItinerary]="true"
      [clickCallback]="mapClick"
      [rebuildMap]="rebuildMapSubject"
      (directionsChange)="directionsChange($event)">
      <!-- [centerOnMarkers]="true" [type]="'map'" -->
      </div>
    </div>
  </ng-container>

  <div *ngIf="currentMarker" class="row mt-2">
    <div class="col-sm-8">
      <span i18n>Upozornit</span> 
      <input class="mx-2" style="width: 75px;" type="number" [(ngModel)]="targetTolerance"/> 
      <span>km <ng-container i18n>od cíle</ng-container></span>
    </div>
    <div class="col-sm-4 text-right">
      <button class="btn btn-success" (click)="setVehicleTarget()">
        <ng-container i18n>Uložit dojezdový cíl</ng-container>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <span class="text-warning">
        <img src="assets/icons-new/sign_warning.svg" alt="sign_warning" height="18px"/>
        <ng-container i18n>Sledování tímto způsobem je pouze dočasné a pouze na tomto zařízení</ng-container>
      </span>
      <br/>
      <span class="text-success">
        <img src="assets/icons-new/question.svg" alt="question" height="18px"/>
        <ng-container i18n>Kliknutím do mapy můžete upřesnit polohu</ng-container>
      </span>
    </div>
  </div>
</div>

<ng-template #missingLastPosition>
  <div *ngIf="vehicle" class="row">
    <div class="col-12">
      <h4 class="text-danger p-2">
        <ng-container i18n>Vozidlo</ng-container> {{vehicle.number_plate}}
        <ng-container i18n>nemá dosud žádný záznam o poslední pozici.</ng-container>
      </h4>
    </div>
  </div>
</ng-template>