// Do vsech objektu je mozne doplnit mnozstvi dalsich zbyvajicich atributu
export class LastSupportInfo {
  private _id: number;
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
}

export class LastUploadLog {
  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }
}

export class LastService {
  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }

  private _driver_key: number;
  get driver_key(): number {
    return this._driver_key;
  }
  set driver_key(value: number) {
    this._driver_key = value;
  }
}