import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserConfigurationService } from 'src/app/service/user-configuration.service';
import { UserConfigurationInterface } from 'src/app/interface/user-configuration.interface';
import { VehicleListConfigurationInterface } from 'src/app/interface/vehicle-list-configuration.interface';
import { Company } from 'src/app/model/company.object';
import { Person } from 'src/app/model/person.object';
import { VehicleNewService } from 'src/app/service/vehicle-new.service';
import { Vehicle } from 'src/app/model/vehicle.object';
import { HIGHLIGHT_LICENCE_PLATES } from 'src/app/config';
import { CompanyService } from 'src/app/service/company.service';
import { UserAccount } from 'src/app/model/user-account.object';

@Component({
  selector: 'app-vehicle-filter',
  templateUrl: './vehicle-filter.component.html',
  styleUrls: ['./vehicle-filter.component.css']
})
export class VehicleFilterComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];
  
  // user configuration
  private _userConfig: UserConfigurationInterface;
  private _vehiclesConfig: VehicleListConfigurationInterface;
  
  private _company: Company;

  private _vehicles: Array<Vehicle> = [];
  public get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }

  private _persons: Array<Person> = [];
  public get persons(): Array<Person> {
    return this._persons;
  }
  
  private _userAccounts: Array<UserAccount> = [];
  public get userAccounts(): Array<UserAccount> {
    return this._userAccounts;
  }

  constructor(
    private _authServ: AuthenticationService,
    private _configurationServ: UserConfigurationService,
    private _vehicleNewServ: VehicleNewService,
    private _companyServ: CompanyService
  ) {
    // load user configuration  
    this._userConfig = JSON.parse(JSON.stringify(this._configurationServ.configuration));
    this._vehiclesConfig = this._configurationServ.configuration.defaultVehicleListConfiguration;
    
    // process filtered car_keys
    this.setVehicleKeys();
    // process filtered person_keys
    this.setPersonKeys();
  }

  ngOnInit(): void {
    this._subscribed.push(
      // this._authServ.authenticationResult.subscribe(
      //   () => {
      //     // for vehicle keys filter
      //     // this._vehicleKeys = [];
      //   }
      // ),
      this._vehicleNewServ.getAllVehiclesCache().subscribe(
        (vehicles: Array<Vehicle>) => {
          if (vehicles && vehicles.length) {
            this._vehicles = vehicles;
            this.sortByUserConfig();
            this.setVehicleKeys();
          }
          else {
            this._vehicles = [];
          }
        }
      ),
      this._vehicleNewServ.getFilteredVehiclesCache().subscribe(
        (vehicles: Array<Vehicle>) => {
          // update vehicles.marked according to filtered cache
          if (vehicles && vehicles.length) {
            this._vehicles.forEach(
              vehicle => {
                if (vehicles.find(v => v.car_key == vehicle.car_key)) {
                  vehicle.marked = true;
                }
                else {
                  vehicle.marked = false;
                }
              }
            )
          }
        }
      ),
      this._companyServ.getCompanyFullObservable().subscribe(
        company => {
          if (company) {
            this._company = company;
            this._persons = this._company.personsArray;
            this._userAccounts = this._company.userAccountsArray;
            // console.log(this._userAccounts);
            this.setPersonKeys();
            this.setUserKeys();
          } 
        }
      ),
    );
  }

  ngOnDestroy(): void {
  }

  
  getPersonNameByUsername(username: string): string {
    if (username && username.startsWith('usr_')) username = username.substring(4);

    let user: UserAccount = this._userAccounts.find(u => u.username == username);
    if (user) {
      let person: Person = this._persons.find(p => p.person_key == user.person_key);
      if (person) {
        return person.name;
      } 
    }

    return null;
  }
  

  private sortByUserConfig(): any {
    // sorting according to vehicle plate OR driver name
    let sortByNumberPlate = this._vehiclesConfig.vehicle_list_highlight === HIGHLIGHT_LICENCE_PLATES;
    this._vehicles.sort(
      (vehicleA, vehicleB) => {
        let driverA = vehicleA.driver_name;
        let driverB = vehicleB.driver_name;
        let valueA = sortByNumberPlate ? vehicleA.number_plate : driverA;
        let valueB = sortByNumberPlate ? vehicleB.number_plate : driverB;
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }
    );
  }


  /*******************************************/
  /* Vehicle keys management */
  /*******************************************/
  // all vehicle keys
  // private _vehicleKeys = [];
  // get vehicleKeys(): Array<any> {
  //   return this._vehicleKeys;
  // }
  setVehicleKeys(): void {
    // init only once at start
    // if (!this._vehicleKeys.length) {
    //   this._vehicleKeys = [];
    let vehicle_filter: Array<number> = this._vehiclesConfig.vehicle_filter;
    let vehicle_user_filter: Array<string> = this._vehiclesConfig.vehicle_user_filter;

    this._vehicles.forEach(
      (vehicle: Vehicle) => {
        // let vehicle.marked : boolean = false;
        vehicle.marked = false;
        if (vehicle_filter && vehicle_filter.includes(vehicle.car_key)) {
          vehicle.marked = true;
        }

        // this._vehicleKeys.push(
        //   {
        //     value: vehicle.car_key,
        //     name: vehicle.number_plate,
        //     driver_key: vehicle.driver_key,
        //     marked: marked
        //   }
        // );
      }
    );
    
    // if (this._vehicleKeys.length) {
    //   if (this._vehicleKeys.filter(key => key.marked).length == this._vehicles.length) {
    //     // all keys
    //     this.markAllVehicles = true;
    //   }
    // }

    if (this._vehicles.length) {
      if (this._vehicles.filter(v => v.marked).length == this._vehicles.length) {
        // all keys
        this.markAllVehicles = true;
      }
    }
    
    if (!vehicle_filter || !vehicle_filter.length) {
      if (!this.vehicle_user_filter )
      // no storage yet / no filter applied -> show all cars
      this.markAllVehicles = true;
      this._vehicles.forEach(
        (vehicle: Vehicle) => {
          vehicle.marked = true;
        }
      );
    }
  }

  setPersonKeys(): void {
    // init only once at start
    let person_filter: Array<number> = this._vehiclesConfig.vehicle_person_filter;

    this._persons.forEach(
      (p: Person) => {
        p.marked = false;
        // empty filter array => no filter applied
        if (person_filter.includes(p.person_key)) {
          p.marked = true;
        }
      }
    );
    
    if (this._persons.length) {
      if (this._persons.filter(person => person.marked).length == this._persons.length) {
        // all keys
        this.markAllPersons = true;
      }
    }
  }
  
  setUserKeys(): void {
    // init only once at start
    let user_filter: Array<string> = this._vehiclesConfig.vehicle_user_filter;

    this._userAccounts.forEach(
      (u: UserAccount) => {
        u.marked = false;
        // empty filter array => no filter applied
        if (user_filter.includes(u.username)) {
          u.marked = true;
        }
      }
    );
    
    if (this._userAccounts.length) {
      if (this._userAccounts.filter(user => user.marked).length == this._userAccounts.length) {
        // all keys
        this.markAllUsers = true;
      }
    }
  }

  // saving changes to storage
  private saveChanges(): void {
    this._configurationServ.saveUserConfiguration(this._userConfig);
  }


  /*******************************************/
  /* Vehicle list filter (vehicles) */
  /*******************************************/
  // vehicle filtering according to car_key
  get vehicle_filter(): Array<number> {
    return this._userConfig.defaultVehicleListConfiguration.vehicle_filter;
  }
  set vehicle_filter(value: Array<number>) {
    let key: number = this._authServ.user ? this._authServ.user.company_key : 0;
    this._userConfig.defaultVehicleListConfiguration.vehicle_filters[key] = value;
    this._userConfig.defaultVehicleListConfiguration.vehicle_filter = value;

    this.saveChanges();
  }

  // event on user changes ngModel of vehicle key switch
  changeVehicleFilter(): void {
    let filteredKeys: Array<number> = [];
    // this._vehicleKeys.forEach(
    this._vehicles.forEach(
      v => {
        if (v.marked) {
          filteredKeys.push(v.car_key);
        }
      }
    );
    // using setter
    this.vehicle_filter = filteredKeys;

    // if (filteredKeys.length == this._vehicleKeys.length) {
    if (filteredKeys.length == this._vehicles.length) {
      this.markAllVehicles = true;
    }
    else {
      this.markAllVehicles = false;
    }
  }

  // switch to mark/unmark all vehicle keys
  public markAllVehicles: boolean = false;
  resetVehicleFilter() {
    if (this.markAllVehicles) {
      // this._vehicleKeys.forEach(
      this._vehicles.forEach(
        v => {
          v.marked = true;
        }
      );
      // using setter
      // this.vehicle_filter = this._vehicleKeys.map(key => key.value);
      this.vehicle_filter = this._vehicles.map(v => v.car_key);
    }
    else {
      // this._vehicleKeys.forEach(
      this._vehicles.forEach(
        v => {
          v.marked = false;
        }
      );
      // using setter
      this.vehicle_filter = [];
    }
  }
  

  /*******************************************/
  /* Vehicle list filter (persons) - lower priority than vehicle filter */
  /*******************************************/
  // vehicle filtering according to person_key
  get vehicle_person_filter(): Array<number> {
    return this._userConfig.defaultVehicleListConfiguration.vehicle_person_filter;
  }
  set vehicle_person_filter(value: Array<number>) {
    let key: number = this._authServ.user ? this._authServ.user.company_key : 0;
    if (!this._userConfig.defaultVehicleListConfiguration.vehicle_person_filters) {
      this._userConfig.defaultVehicleListConfiguration.vehicle_person_filters = {};
      this._userConfig.defaultVehicleListConfiguration.vehicle_person_filter = [];
    }
    this._userConfig.defaultVehicleListConfiguration.vehicle_person_filters[key] = value;
    this._userConfig.defaultVehicleListConfiguration.vehicle_person_filter = value;

    this.saveChanges();
  }

  // event on user changes ngModel of persons key switch
  changePersonFilter(): void {
    let filteredKeys: Array<number> = [];
    this._persons.forEach(
      person => {
        if (person.marked) {
          filteredKeys.push(person.person_key);
        }
      }
    );
    // using setter
    this.vehicle_person_filter = filteredKeys;
    
    if (filteredKeys.length == this._persons.length) {
      this.markAllPersons = true;
    }
    else {
      this.markAllPersons = false;
    }

    // set vehicles keys according to persons keys
    this.setVehiclesAccordingToPersons();
  }

  // switch to mark/unmark all persons keys
  public markAllPersons: boolean = false;
  resetVehiclePersonFilter() {
    if (this.markAllPersons) {
      this._persons.forEach(
        person => {
          person.marked = true;
        }
      );
      // using setter
      this.vehicle_person_filter = this._persons.map(p => p.person_key);
      
      // set vehicles keys according to persons keys
      this.setVehiclesAccordingToPersons();
    }
    else {
      this._persons.forEach(
        person => {
          person.marked = false;
        }
      );
      // using setter
      this.vehicle_person_filter = [];
    }
  }

  setVehiclesAccordingToPersons(): void {
    // reset marked vehicles
    this._vehicles.forEach(
      v => {
        v.marked = false;
      }
    );
    
    let vehicleKeys: Array<number> = [];
    // mark all vehicles
    this._persons.forEach(
      p => {
        if (p.marked) {
          let personVehicles = this._vehicles.filter(v => v.person_key == p.person_key);
          personVehicles.forEach(
            v => {
              v.marked = true;
              vehicleKeys.push(v.car_key);
            }
          );
        }
      }
    );
    // using setter
    this.vehicle_filter = vehicleKeys;
  }


  /*******************************************/
  /* Vehicle list filter (users) - lower priority than vehicle filter / person filter */
  /*******************************************/
  // vehicle filtering according to user person_key
  get vehicle_user_filter(): Array<string> {
    return this._userConfig.defaultVehicleListConfiguration.vehicle_user_filter;
  }
  set vehicle_user_filter(value: Array<string>) {
    let key: number = this._authServ.user ? this._authServ.user.company_key : 0;
    if (!this._userConfig.defaultVehicleListConfiguration.vehicle_user_filters) {
      this._userConfig.defaultVehicleListConfiguration.vehicle_user_filters = {};
      this._userConfig.defaultVehicleListConfiguration.vehicle_user_filter = [];
    }
    this._userConfig.defaultVehicleListConfiguration.vehicle_user_filters[key] = value;
    this._userConfig.defaultVehicleListConfiguration.vehicle_user_filter = value;

    this.saveChanges();
  }

  // event on user changes ngModel of persons key switch
  changeUserFilter(): void {
    let filteredKeys: Array<string> = [];
    this._userAccounts.forEach(
      user => {
        if (user.marked) {
          filteredKeys.push(user.username);
        }
      }
    );
    // using setter
    this.vehicle_user_filter = filteredKeys;
    
    if (filteredKeys.length == this._persons.length) {
      this.markAllUsers = true;
    }
    else {
      this.markAllUsers = false;
    }

    // set vehicles keys according to persons keys
    this.setVehiclesAccordingToPersons();
  }

  // switch to mark/unmark all persons keys
  public markAllUsers: boolean = false;
  resetVehicleUsersFilter() {
    if (this.markAllUsers) {
      this._userAccounts.forEach(
        user => {
          user.marked = true;
        }
      );
      // using setter
      this.vehicle_user_filter = this._userAccounts.map(u => u.username);
      // set vehicles keys according to persons keys
      // this.setVehiclesAccordingToPersons();
    }
    else {
      this._userAccounts.forEach(
        user => {
          user.marked = false;
        }
      );
      // using setter
      this.vehicle_user_filter = [];
    }
  }
}
