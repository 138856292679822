<!-- <div *ngIf="visible">
<div id="myModal" class="modal" role="dialog" style="display: block"> -->

<div class="modal fade" id="loginModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="loginModal">
          <ng-container *ngIf="user">{{user.username}}</ng-container>
          <ng-container *ngIf="!user" i18n>Přihlášení</ng-container>
          <a *ngIf="user" class="pointer" placement="bottom auto" ngbTooltip="Odhlásit" 
          (click)="logout()" i18n-ngbTooltip>
            <img src="assets/icons-new/door_exit.svg" alt="Warning" width="36px" height="36px" />
          </a>
          <a *ngIf="user" class="pointer" placement="bottom auto" ngbTooltip="Obnovit" 
          (click)="refresh()" i18n-ngbTooltip>
            <img src="assets/icons-new/refresh.svg" alt="Warning" width="36px" height="36px" />
          </a>
        </h4>
        <button *ngIf="closeButtonAvailable()" (click)="close()" type="button" class="close">&times;</button>
      </div>
      <form (submit)="signIn($event)">
        <div class="modal-body">
          <div *ngIf="!user" class="container-fluid">
            <ng-container *ngIf="loginError">
              <div class="row">
                <div class="col-12">
                  <p class="text-danger">
                    <img src="assets/icons-new/sign_warning.svg" alt="sign_warning" height="18px"/>
                    {{loginError}}
                  </p>
                </div>
              </div>
            </ng-container>
            <div class="row">
              <label class="col-6" i18n>Uživatelské jméno</label>
              <span class="col-6">
                <input name="login" class="form-control" type="text" 
                [(ngModel)]="login" placeholder="Už.jméno"/>
              </span>
            </div>
            <br/>
            <div class="row">
              <label class="col-6" i18n>Heslo</label>
              <span class="col-6">
                <input name="password" class="form-control" type="password" [(ngModel)]="password"/>
              </span>
            </div>
          </div>
          <div *ngIf="user">
            <ng-container i18n>Přihlášení vyprší</ng-container> {{user.token.expire|dateFormat}}
          </div>
        </div>
        <div *ngIf="!user" class="modal-footer">
          <button class="btn btn-success" type="submit" i18n>Přihlásit</button>
          <!-- <input type="submit" class="btn btn-success" value="Přihlásit"/> -->
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="modal-backdrop fade in"></div>
</div> -->