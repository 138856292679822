import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { TrackingEvent } from "../model/tracking-event.object";
import { GeoPosition } from '../model/geo-position.object';
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class TrackingEventService {

  private _trackingEvents: BehaviorSubject<Array<TrackingEvent>> = new BehaviorSubject(null);
  private _trackingEventsCache: Array<TrackingEvent> = null;
  private _updateSuccess: boolean = true;
  private _updatesMade: number = 0;

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  private _loadingTrackingEvents: boolean = false;
  get loadingTrackingEvents(): boolean {
    return this._loadingTrackingEvents;
  }
  

  private clearEventsCache(): void {
    this._trackingEventsCache = null;
  }

  // GET /events/tracking
  // tf - time_from, tt - time_to, ck - car_key, dk - driver_key, on - order_number
  getTrackingEvents(filterObj: any): Observable<Array<TrackingEvent>> {
    this.clearEventsCache();

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.trackingEvent.api;
      // add url query
      if (filterObj) {
        let filterKeys: Array<string> = Object.keys(filterObj);
        filterKeys.forEach(
          (key, index) => {
            if (index == 0 && filterObj[key]) {
              url += '?' + key + '=' + filterObj[key];
            }
            else if (filterObj[key]) {
              url += '&' + key + '=' + filterObj[key];
            }
          }
        );
      }

      this._loadingTrackingEvents = true;

      this._http.get(url).subscribe(
        response => {
          // console.log(response);
          this._trackingEventsCache = TrackingEventService.buildTrackingEventsFromData(response);
          this._trackingEvents.next(this._trackingEventsCache);
          this._loadingTrackingEvents = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loadingTrackingEvents = false;
        }
      );
    }

    return this._trackingEvents.asObservable();
  }

  // GET /events/tracking
  // NOT USING ATTRIBUTES BUT CUSTOM INSTANCES
  // tf - time_from, tt - time_to, ck - car_key, dk - driver_key, on - order_number
  getTrackingEventsSeparately(filterObj: any): Observable<Array<TrackingEvent>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.trackingEvent.api;
      // add url query
      if (filterObj) {
        let filterKeys: Array<string> = Object.keys(filterObj);
        filterKeys.forEach(
          (key, index) => {
            if (index == 0 && filterObj[key]) {
              url += '?' + key + '=' + filterObj[key];
            }
            else if (filterObj[key]) {
              url += '&' + key + '=' + filterObj[key];
            }
          }
        );
      }

      return this._http.get(url);
    }
  }


  // method for updating all tracking events that were loaded
  public updateTrackingEvents(number_plate: string, d_key: number, d2_key: number): void {
    // init data to update
    let data: any = {
      driver_key: d_key,
      driver2_key: d2_key
    };

    // init flags for completion of all updates
    this._updateSuccess = true;
    this._updatesMade = 0;

    if (this._trackingEventsCache) {
      this._trackingEventsCache.forEach(
        te => {
          this.updateTrackingEvent(number_plate, te, data).subscribe();
        }
      );
    }
  }

  // PUT /events/tracking/<POS_KEY>
  private updateTrackingEvent(number_plate: string, tEvent: TrackingEvent, dataToUpdate: any): Observable<any> {
    let updatedRecord: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.put(ServiceConfiguration.trackingEvent.api + tEvent.pos_key, dataToUpdate).subscribe(
        response => {
          updatedRecord.next(response);
        },
        error => {
          // handle error
          console.log(error);
          updatedRecord.next(error);
          this._updateSuccess = false;
          this._updatesMade += 1;
          // alert when last update is made
          if (this._updatesMade == this._trackingEventsCache.length) {
            this._notificationService.alert(
              $localize`Chyba úpravy řidičů vozidla %SPZ v historii. (tracking events)`.replace('%SPZ', number_plate),
              'error', 4000
            );
          }
        },
        () => {
          this._updatesMade += 1;
          // alert when last update is made
          if (this._updatesMade == this._trackingEventsCache.length) {
            if (this._updateSuccess) {
              this._notificationService.alert(
                $localize`Řidiči v historii vozidla %SPZ byly úspěšně upraveni. (tracking events)`.replace('%SPZ', number_plate),
                'success', 4000
              );
            } else {
              this._notificationService.alert(
                $localize`Chyba úpravy řidičů vozidla %SPZ v historii. (tracking events)`.replace('%SPZ', number_plate),
                'error', 4000
              );
            }
          }
        }
      );
    }

    return updatedRecord.asObservable();
  }

  // PUT /events/tracking/<POS_KEY>
  public updateTrackingEventCustom(tracking_event: TrackingEvent): Observable<any> {
    let updatedRecord: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.trackingEvent.api + tracking_event.pos_key;
      this._http.put(url, tracking_event.apiObject).subscribe(
        response => {
          updatedRecord.next(response);
        },
        error => {
          // handle error
          console.log(error);
          updatedRecord.next(error);
        }
      );
    }

    return updatedRecord.asObservable();
  }

  // POST /events/tracking
  createEvent(trackingEvent: TrackingEvent): Observable<TrackingEvent> {
    let result: BehaviorSubject<TrackingEvent> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.trackingEvent.api;

      this._http.post(url, trackingEvent.apiObject).subscribe(
        response => {
          if (response) {
            // alert
            let alert: string = $localize`Událost byla úspěšně vytvořena.`;
            this._notificationService.alert(alert, 'success', 4000);
            
            // observable next
            result.next(TrackingEventService.buildTrackingEvent(response));
          }
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při vytváření události.`;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // DELETE /events/tracking/<POS_KEY>
  deleteTrackingEvent(event: TrackingEvent): Observable<boolean> {
    let result: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.trackingEvent.api + event.pos_key;

      this._http.delete(url).subscribe(
        response => {
          // let alert: string = 'Chyba při odstranění události - kontaktujte podporu.';
          // this._notificationService.alert(alert, 'error', 4000);
          result.next(true);
        },
        error => {
          console.error(error);
          let alert: string = $localize`Chyba při odstranění události - kontaktujte podporu.`;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }


  // method for creating tracking events array
  static buildTrackingEventsFromData(data: Array<any>): any {
    let events: Array<TrackingEvent> = [];
    if (data) {
      data.forEach(
        te => {
          let tEvent = TrackingEventService.buildTrackingEvent(te);
          events.push(tEvent);
        }
      );
    }
    return events;
  }

  // method for creating a single tracking event
  static buildTrackingEvent(te: any): TrackingEvent {
    let tEvent: TrackingEvent = new TrackingEvent();
    for (let key in te) {
      tEvent[key] = te[key];
    }
    
    tEvent.geoPos = new GeoPosition();
    tEvent.geoPos.pos_gps = tEvent.pos_gps;
    
    return tEvent;
  }
}
