import { Injectable } from '@angular/core';
import { TimocomFreightSearchObject } from '../model/databank-timocom.object'
import { BehaviorSubject, Observable } from 'rxjs';
import { TimocomApi } from '../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { NotificationService } from './notification-service';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class TimocomService {

  constructor(    
    private _http: HttpClient,
    private _httpAuthorized: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  createFreightOfferProxy(o: TimocomFreightSearchObject): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated()) {    
      let url_proxy: string = TimocomApi.proxy;

      let formData = new FormData();
      formData.append('url', TimocomApi.freight_offer);
      formData.append('method', 'POST');
      formData.append('content-type', 'application/json');
      formData.append('auth', 'Basic ' + btoa('Truck-Agenda:ZA3SQzuHTpStS9OP6E4Jxw'));
      formData.append('data', JSON.stringify(o.apiObject));

      this._httpAuthorized.post(url_proxy, formData).subscribe(
        response => {
          //console.log(response);
          if (response) {
            console.log(JSON.parse(response));
            result.next(JSON.parse(response));
          }
        },
        error => {
          // handle error
          console.log(error);
          let alertError: string = 'Chyba při hledání v Timocom.';
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }


  // POST /ext/freights-api/v1/freight-exchange
  createFreightOffer(o: TimocomFreightSearchObject): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa('Truck-Agenda:ZA3SQzuHTpStS9OP6E4Jxw'),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };


    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated()) {
      let url: string = TimocomApi.freight_offer;
      let data = o.apiObject;
      //let httpOptions = this.getTransHeaders();
      console.log(data);
      console.log(url);
      this._http.post(url, data, httpOptions).subscribe(
        response => {
          console.log(response);
          result.next(response);
          
          //let alertSuccess: string = 'Nabídka nákladu pro TRANS.eu byla úspěšně vytvořena';
          //this._notificationService.alert(alertSuccess, 'success', 4000);
        },
        error => {
          // handle error
          console.log(error);
          //let alertError: string = 'Chyba vytváření nabídky nákladu pro Timocom.';
          //this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

}
