import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CompanyService } from "src/app/service/company.service";
import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";

@Component({
  selector: '<component-router>',
  templateUrl: './left-tm.component.html',
  styleUrls: ['./left-tm.component.css']
})
export class LeftTmComponent {

  constructor(
    private _companyServ: CompanyService,
    private _layout: TruckManagerLayoutService,
    private _router: Router
  ) {
    // route to statistics component
    // let queryParams: any = {
    //   reloadPossible: true
    // }
    // let url = this._router.serializeUrl(
    //   this._router.createUrlTree(
    //     [{outlets: {left: 'statistics'}}], {queryParams: queryParams}
    //   )
    // );
    // this._router.navigateByUrl(url);
  }

  // get flag for detection correct authentication
  get isAuthenticated(): boolean {
    return this._layout.isAuthenticated();
  }
  
  get staticContentResource(): string {
    return this._layout.staticContentResource;
  }

  get company(): string {
    return this._companyServ.company;
  }

  // datum konce pristupu zakaznika
  get admittanceDate(): string {
    if (this._layout.user && this._layout.user.admittance) {
      return this._layout.user.admittance.toLocaleDateString();
    }
    return '';
  }
}