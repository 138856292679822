import { Pipe, PipeTransform } from '@angular/core';
import { CarTypeName } from '../config';

@Pipe({
  name: 'carType'
})
export class CarTypePipe implements PipeTransform {

  transform(value: string): string {
    let name: string = ''
    if (CarTypeName[value]) {
      name = CarTypeName[value];
    }
    return name;
  }

}
