<!-- default resource -->
<iframe name="unAuthenticated" *ngIf="!isAuthenticated" [src]="staticContentResource | safeResource"></iframe>

<div class="container-fluid mt-5">
  <div class="row">
    <div class="col-lg-6 offset-lg-3 text-center">
      <h4><ng-container i18n>Vítejte</ng-container> {{company}}</h4>
      <h5><ng-container i18n>Přístup do</ng-container> {{admittanceDate}}</h5>
    </div>
  </div>
</div>