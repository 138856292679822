<div class="container-fluid" *ngIf="vehicle">
  <div *ngIf="!editableText" class="row py-2">
    <div class="col-md-5">
      <h4>
        <ng-container i18n>Dispozice pro</ng-container> {{vehicle.number_plate}} {{vehicle.driver_name}}
      </h4>
    </div>
    <div class="col-md-7">
      <input id="search-input" type="text" class="form-control" placeholder="Zadejte adresu"
      autofocus="autofocus" name="address" [(ngModel)]="address" i18n-placeholder/>
    </div>
  </div>

  <div *ngIf="errorMessage" class="row text-danger">
    <div class="col-12">{{errorMessage}}</div>
  </div>

  <div class="row mt-1">
    <div class="col-md-6">
      <ng-container *ngIf="!editableText; else bodyEditableText">
        <div class="google-map-wrapper" style="position: relative;">
          <div class="googleMapManualTracking fill" 
            [properties]="mapProperties"  
            [vehicle]="vehicle" 
            [markers]="markers" 
            [waypoints]="waypoints"
            [drawDirections]="true" 
            [directionsDraggable]="true" 
            (directionsChange)="directionsChange($event)" 
            (clicked)="mapClicked($event)">
            <!-- [centerOnMarkers]="true"  -->
          </div>
        </div>
      </ng-container>
      <ng-template #bodyEditableText>
        <div class="row">
          <div class="col-12">
            <div class="google-map-wrapper" style="position: relative;">
              <div class="overlay"></div>
              <div class="googleMapManualTracking fill" 
                [properties]="mapProperties"  
                [vehicle]="vehicle" 
                [markers]="markers" 
                [waypoints]="waypoints"
                [drawDirections]="true" 
                [directionsDraggable]="true" 
                (directionsChange)="directionsChange($event)" 
                (clicked)="mapClicked($event)">
                <!-- [centerOnMarkers]="true"  -->
              </div>
            </div>
            <a class="btn btn-warning full" (click)="editableText = false">
              <ng-container i18n>Upravit body</ng-container>
            </a>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- TODO.. -->
    <div *ngIf="!currentPdf" class="col-md-6">
      <div class="border border-primary rounded position-relative" style="height:240px;">
        <div class="w-75 mt-2 mx-auto font-weight-bold" style="font-size:13px;">
          <ng-container i18n>Vložte soubor s objednávkou ve formátu:</ng-container> pdf, doc (docx), xls (xlsx), sxw, sxc, txt, rtf, ods
        </div>
        <div class="w-100 mt-4 text-center">
          <img height="60px" src="assets/icons-new/folder_document.svg"/>
        </div>
        <input type="file" (change)="dropped($event)" class="full pointer"
        style="opacity:0; position:absolute; left:0; top:0px; height:240px;"/>
      </div>
    </div>
    <div *ngIf="currentPdf" class="col-6">
      <a class="dispositions-current-pdf-close btn btn-danger text-white" (click)="removePdf()">
        <img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/>
      </a>
      <object class="full fill" [data]="currentPdf.content|safeResource" [type]="currentPdf.type">
      </object>
    </div>
  </div>

  <ng-template #createMessage>
    <div class="row mt-3">
      <div class="col-12">
        <ng-template #noMarkers>
          <h6 i18n>Přidejte bod pomocí vyhledávání</h6>
        </ng-template>
        <ng-container *ngIf="markers.length; else noMarkers">
          <div class="row mb-1" *ngFor="let marker of markers; index as i">
            <div class="col-2">
              <select class="form-control" (ngModelChange)="changeMarkerType(marker,$event)" [(ngModel)]="marker.data.type">
                <option [value]="2" i18n>Nakládka</option>
                <option [value]="1" i18n>Vykládka</option>
                <option [value]="3" i18n>Transit</option>
                <option [value]="4" i18n>Tankovaní</option>
                <option [value]="5" i18n>Parkoviště</option>
              </select>
            </div>
            <div class="col-8">
              <div class="row no-gutters">
                <div class="col-6">
                  {{marker.infoWindowContent}}
                </div>
                <div class="col-6">
                  <input type="text" [(ngModel)]="marker.data.additionalText" 
                  placeholder="Vlastní text" class="form-control" i18n-placeholder/>
                </div>
              </div>
            </div>
            <div class="col-2 text-right">
              <a class="btn btn-primary pointer text-white btn-sm mr-1" (click)="moveUp(marker)">
                <img src="assets/icons-new/navigate_up.svg" alt="up" height="18px"/>
              </a>
              <a class="btn btn-primary pointer text-white btn-sm mr-1" (click)="moveDown(marker)">
                <img src="assets/icons-new/navigate_down.svg" alt="down" height="18px"/>
              </a>
              <a class="btn btn-danger pointer text-white btn-sm" (click)="removeMarker(marker)">
                <img src="assets/icons-new/navigate_cross_red.svg" alt="cross" height="18px"/>
              </a>
            </div>
          </div>

          <a class="btn btn-success" (click)="editableText = true" i18n>Vytvořit zprávu</a>
          <span class="ml-1">
            <ng-template #routeLengthLoaded>
              {{routeLength}}km
            </ng-template>
            <ng-container *ngIf="routeLengthReloading; else routeLengthLoaded" >
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only"></span>
              </div>
              km
            </ng-container>
          </span>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="editableText; else createMessage">
    <div class="row mb-2 mt-2">
      <div class="col-6">
        <div class="row no-gutters">
          <div class="col-4">
            <label for="inputOrderNumber" i18n>Číslo zakázky</label>
          </div>
          <div class="col-8">
            <input id="inputOrderNumber" class="form-control" type="text" 
            [(ngModel)]="orderNumber" name="orderNumber"/>
          </div>
        </div>
      </div>
      <div class="col-6">
        <textarea rows="15" cols="150" class="form-control" style="white-space: initial;"
        [(ngModel)]="text" name="text" id="inputText" ></textarea>
        <div>
          <label for="inputGoogleMapsLink" i18n>Trasa do Google Maps</label>
          <input type="checkbox" name="sendGoogleMapsLink" id="inputGoogleMapsLink" 
          [(ngModel)]="sendGoogleMapsLink"/>
        </div>
        <div>
          <a class="btn btn-primary text-white" (click)="sendMessageToVehicle()">
            <ng-container i18n>Odešli zprávu</ng-container>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>