<!-- <div id="myModal" class="modal" role="dialog" style="display: block;"> -->
<div class="modal fade" id="gridModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="gridModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
    <div class="modal-header" id="gridModalLabel">
      <h4>Aktuální rozlišení: {{windowWidth}}px x {{windowHeight}}px</h4>
      <button (click)="closeDialog()" type="button" class="close">&times;</button>
    </div>
    <form class="form-horizontal" (submit)="confirmSetup($event)" target="_blank" method="post">
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <div class="form-group row">
              <label class="col-6">Vozidel zvoleno k zobrazení</label>
              <div class="col-6">
                {{vehicleCount}}
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="inputColumns">Počet sloupců</label>
              <div class="col-6">
                <input [max]="vehicleCount" [min]="1" class="form-control col-10" id="inputColumns" name="columns" [(ngModel)]="columns" type="number" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="inputRows">Počet řádků</label>
              <div class="col-6">
                <input [max]="vehicleCount" [min]="1" class="form-control" id="inputRows" name="rows" [(ngModel)]="rows" type="number" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-8" for="aeaterShow">AETR</label>
              <div class="col-4">
                <input type="checkbox" name="aetr_show" class="" id="aeaterShow" value="1" [(ngModel)]="aetr_show" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-8" for="ganttShow">Truckagenda</label>
              <div class="col-4">
                <input type="checkbox" name="gantt_show" class="" id="ganttShow" value="1" [(ngModel)]="gantt_show" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-8" for="lastCargoGraphicShow">Ikonka poslední nakl/vykl</label>
              <div class="col-4">
                <input type="checkbox" name="last_cargo_graphic_show" class="" id="lastCargoGraphicShow" value="1" [(ngModel)]="last_cargo_graphic_show" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-8" for="lastCargoTextShow">Info o poslední nakl/vykl</label>
              <div class="col-4">
                <input type="checkbox" name="last_cargo_text_show" class="" id="lastCargoTextShow" value="1" [(ngModel)]="last_cargo_text_show" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-8" for="inputShowMap">Zobrazit mapu</label>
              <div class="col-4">
                <input type="checkbox" name="show_map" class="" id="inputShowMap" value="1" [(ngModel)]="show_map" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-8" for="inputShowNewMessages">Zobrazovat nové zprávy</label>
              <div class="col-4">
                <input type="checkbox" name="show_new_messages" class="" id="inputShowNewMessages" value="1" [(ngModel)]="show_new_messages" />
              </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group row">
              <label class="col-6" for="inputTrailer">Návěs</label>
              <div class="col-6">
                <input type="checkbox" name="trailer_show" class="" id="inputTrailer" value="1" [(ngModel)]="trailer_show" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="inputCoDriver">Druhý řidič</label>
              <div class="col-6">
                <input type="checkbox" name="co_driver_show" class="" id="inputCoDriver" value="1" [(ngModel)]="co_driver_show" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="inputColumns">Šířka sloupců</label>
              <div class="col-6">
                {{vehicleContainerWidth}}
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="mapHeight">Výška mapy</label>
              <div class="col-6">
                <input type="number" class="form-control" [(ngModel)]="map_height" name="map_height" id="mapHeight" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="inputNote">Poznámka</label>
              <div class="col-6">
                <input type="checkbox" name="note_show" class="" id="inputNote" value="1" [(ngModel)]="note_show" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="inputTruncateNote">Zkrátit poznámku</label>
              <div class="col-6">
                <input class="form-control col-10" id="inputTruncateNote" name="truncate_note" [(ngModel)]="truncate_note" type="number" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-6" for="inputTruncateCity">Zkrátit město</label>
              <div class="col-6">
                <input class="form-control" id="inputTruncateCity" name="truncate_city" [(ngModel)]="truncate_city" type="number" />
              </div>
              </div>
              <div class="form-group row">
              <label class="col-12 text-center">Velikost textu</label>
              <div class="col-12">
                <div class="row text-center">
                <div class="col-4 text-center">
                  <input id="font50" type="radio" name="font_size" [(ngModel)]="font_size" value="60%" /><br/>
                  <label for="font50">60%</label>
                </div>
                <div class="col-4 text-center">
                  <input id="font75" type="radio" name="font_size" [(ngModel)]="font_size" value="80%" /><br/>
                  <label for="font75">80%</label>
                </div>
                <div class="col-4 text-center">
                  <input id="font100" type="radio" name="font_size" [(ngModel)]="font_size" value="100%" /><br/>
                  <label for="font100">100%</label>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      <input type="submit" class="btn btn-success pointer button" value="Otevřít"/>
      </div>
    </form>
    </div>
  
  </div>
</div>
<!-- <div class="modal-backdrop fade in" (click)="closeDialog()"></div> -->