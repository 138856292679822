import { Pipe, PipeTransform } from '@angular/core';
import { Numbers } from "../tools/Numbers";

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any): string {
    if (!value) return '0';
    
    // 1 000.000
    return Numbers.toOurPriceFormat(value);
  }

}
