import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { BehaviorSubject, Observable } from "rxjs";

import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { ExchangeRates } from "../model/exchange-rates.object";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class ExchangeRatesService{

  private _loadingExchangeRates: boolean = false;
  get loadingExchangeRates(): boolean {
    return this._loadingExchangeRates;
  }

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService,
    private _datePipe: DatePipe
  ) {
  }

  loadExchangeRates(country: string, currency: string, validAt: string = null): Observable<ExchangeRates> {
    let exchangeRates: BehaviorSubject<ExchangeRates> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingExchangeRates = true;

      let today: Date = new Date();
      let todayFormatted: string = this._datePipe.transform(today, 'yyyy-MM-dd');
      if (!validAt || validAt > todayFormatted) {
        // use current date
        validAt = todayFormatted;
      }

      let url: string = ServiceConfiguration.exchangeRates.api + '/?country=' + country + '&currency=' + currency + '&valid_at=' + validAt; 

      this._http.get(url).subscribe(
        (response: Array<any>) => {
          let rate: ExchangeRates = this.buildExchangeRates(response[0]);
          exchangeRates.next(rate);
          this._loadingExchangeRates = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingExchangeRates = false;
        }
      );
    }
    
    return exchangeRates.asObservable();
  }

  // method for creating a single obligation object
  private buildExchangeRates(o: any): ExchangeRates {
    let exchangeRates: ExchangeRates = new ExchangeRates();

    for (let key in o) {
      exchangeRates[key] = o[key];
    }

    return exchangeRates;
  }
}
