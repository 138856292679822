import { ChangeDetectorRef, Component, Input } from "@angular/core";

import { MessageService } from "../../service/message.service";
import { NotificationService } from "../../service/notification-service";
import { FilesService } from "../../service/files.service";
import { Vehicle } from "../../model/vehicle.object";

@Component({
  selector: 'message-write',
  templateUrl: './r-message-write.component.html',
  styleUrls: ['./r-message-write.component.css']
})
export class RMessageWriteComponent {
  constructor(
    private _messageService: MessageService, 
    private _notificationService: NotificationService, 
    private _filesService: FilesService,
    private _cdr: ChangeDetectorRef
  ) {
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
    if (this.vehicle) {
      this._message = this._messageService.getUnsent(vehicle.car_key);
    }
  }

  private _messagesPatterns: Array<string> = [
    $localize`Prosím o zaslání dokumentů k zakázce č. <ORDER_NUMBER_STANDARD>.`,
    $localize`Dokument k zakázce č. <ORDER_NUMBER_STANDARD> je špatné kvality. Pošlete prosím znovu.`
  ];
  public get messagesPatterns(): Array<string> {
    return this._messagesPatterns;
  }
  @Input()
  public set messagesPatterns(value: Array<string>) {
    this._messagesPatterns = value;
  }

  private _attachment: File;
  get attachment(): File {
    return this._attachment;
  }
  set attachment(value: File) {
    this._attachment = value;
  }

  private _message: string;
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
    if (this._vehicle) {
      this._messageService.addUnsent(this.vehicle.car_key, this._message);
    }
  }

  // stuff for sending message with attached file
  private _uploading: boolean = false;
  get uploading(): boolean {
    return this._uploading;
  }

  private _attachment_source: string;
  get attachment_source(): string {
    if (!this._attachment_source && this._attachment) {
      this._attachment_source = URL.createObjectURL(this._attachment);
    }
    return this._attachment_source;
  }

  setPattern(pattern: string) {
    console.log(pattern);
    this._message = pattern;
    this._cdr.detectChanges();
  }

  attachmentChange(event: any) {
    if (event.currentTarget.files && event.currentTarget.files.length) {
      this._attachment = event.currentTarget.files[0];
    } 
    else {
      this._attachment = null;
    }
  }

  attachmentCancel(): void {
    this._attachment = null;
    this._attachment_source = null;
  }


  // method for sending text message
  sendAttachment() {
    if (this._attachment) {
      this._uploading = true;
      this._filesService.sendFileToVehicle(this._vehicle.car_key, this._attachment).subscribe(
        response => {
          this._attachment = null;
          this._notificationService.alert($localize`Soubor odeslán`, 'success', 3000);
        },
        error => {
          this._uploading = false;
          this._notificationService.alert($localize`Odeslání zprávy nebylo úspěšné`, 'error', 3000);
        },
        () => {
          this._uploading = false;
        }
      );
      this._attachment = null;
    } 
    else {
      this._notificationService.alert($localize`Zvolte soubor k odeslání`, 'error', 3000);
    }
  }

  isAttachmentImage() {
    if (!this._attachment) {
      return false;
    }
    let match = this._attachment.type.match(/image\/*/);
    return match && match.length > 0
  }

  isAttachmentDoc() {
    if (!this._attachment) {
      return false;
    }
    let match = this._attachment.type.match(/application\/pdf/);
    return match && match.length > 0;
  }

  sendMessage(event?: any): void {
    console.log(this._message);
    if (!this._message.trim()) {
      this._notificationService.alert($localize`Nelze odeslat prázdnou zprávu.`, 'error', 3000);
      return;
    }
    this._messageService.sendMessageToVehicle(this._message, this.vehicle).subscribe(
      message => {
        if (event && event.currentTarget && event.currentTarget.reset) {
          event.currentTarget.reset();
        }
        this._attachment = null;
        this.message = '';
        this._notificationService.alert($localize`Zpráva byla úspěšně odeslána.`, 'success', 3000);
      },
      error => {
        console.log(error);
        this._notificationService.alert($localize`Odeslání zprávy se nezdařilo.`, 'error', 3000);
      }
    );
  }
}
