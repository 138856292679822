import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { DatePipe } from "@angular/common";

import { Message } from "../../model/message.object";
import { MessageService } from "src/app/service/message.service";
import { ObligationService } from "src/app/service/obligation.service";
import { Subscription } from "rxjs";
import { Obligation } from "src/app/model/obligation.object";
import { ObligationTools } from "src/app/tools/ObligationTools";
import { Order } from "src/app/model/order.object";
import { OrderService } from "src/app/service/order.service";
import { OrderTools } from "src/app/tools/OrderTools";
import { Itinerary } from "src/app/model/itinerary.object";
import { UserConfigurationService } from "src/app/service/user-configuration.service";
import { MessagesConfigurationInterface } from "src/app/interface/messages-configuration.interface";
import { NotificationService } from "src/app/service/notification-service";

@Component({
  selector: 'messages-day-grouped-list',
  templateUrl: './r-messages-day-grouped-list.component.html',
  styleUrls: ['./r-messages-day-grouped-list.component.css'],
  providers: [
    ObligationService,
    OrderService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RMessageDayGroupedListComponent {

  private _subscribed: Array<Subscription> = [];
  private _days: Array<string> = [];

  private _messagesConfig: MessagesConfigurationInterface;

  private _messages_grouped: Array<any> = [];
  get messages_grouped(): Array<any> {
    return this._messages_grouped;
  }

  private _reply: boolean = false;
  get reply(): boolean {
    return this._reply
  }
  @Input()
  set reply(reply: boolean) {
    this._reply = reply;
    // custom detection
    this.detectChanges();
  }
  
  private _dateFrom: Date = null;
  @Input()
  set dateFrom(value: Date) {
    this._dateFrom = value;
    // just init day group with empty messages array
    let day_formatted: string = this._datePipe.transform(this._dateFrom, 'dd.MM.yyyy');
    if (!this._days.includes(day_formatted)) {
      this._days.push(day_formatted);
      
      let groupToPush: any = {
        day: day_formatted,
        dayCompare: this._datePipe.transform(this._dateFrom, 'yyyy-MM-dd'),
        messages: []
      };
      this._messages_grouped.push(groupToPush);
    }
    // custom detection
    this.detectChanges();
  }

  private _dateTo: Date = null;
  @Input()
  set dateTo(value: Date) {
    this._dateTo = value;
    // custom detection
    this.detectChanges();
  }

  private _messages: Array<Message> = [];
  @Input()
  set messages(messages: Array<Message>) {
    // associate messages with obligations
    if (messages) {
      // user settings filter
      if (this._messagesConfig) {
        if (this._messagesConfig.order_messages_show && !this._messagesConfig.vehicle_messages_show) {
          // show only order messages
          messages = messages.filter(m => m.order_key);
        }
        else if (!this._messagesConfig.order_messages_show && this._messagesConfig.vehicle_messages_show) {      
          // show all except of order messages
          messages = messages.filter(m => !m.order_key);
        }
      }

      this.associateObligationsOrders(messages);
    }

    if (this._dateFrom && this._dateTo) {
      let day_from_formatted: string = this._datePipe.transform(this._dateFrom, 'dd.MM.yyyy');

      // add messages
      if (messages.length) {
        messages.forEach(
          message => {
            let message_day = this._datePipe.transform(message.time, 'dd.MM.yyyy');
            if (message_day == day_from_formatted) {
              if (this._days.indexOf(day_from_formatted) > -1) {
                let day_group = this._messages_grouped[this._days.indexOf(day_from_formatted)];
                // possible entry of duplicate messages
                if (!day_group.messages.find(m => m.msg_key == message.msg_key)) {
                  // day_group.messages.push(message);
                  // push does not invoke @Input to messages-list -> using setter with concat
                  day_group.messages = day_group.messages.concat([message]);
                }
                day_group.messages.sort(
                  (message, messageB) => {
                    if (message.time > messageB.time) { return -1; }
                    if (message.time < messageB.time) { return 1; }
                    return 0;
                  }
                );
              } 
            }
          }
        );
      }
    }
    else {
      // OBSOLETE approach - all reinit - worse user experience
      // using in messages-new component
      this._messages_grouped = [];
      this._days = [];

      if (messages.length) {
        messages.forEach(
          message => {
            let date = message.time;
            let day = this._datePipe.transform(date, 'dd.MM.yyyy');
            let groupToPush: any;
            if (this._days.indexOf(day) > -1) {
              groupToPush = this._messages_grouped[this._days.indexOf(day)];
            } 
            else {
              groupToPush = {
                day: day,
                dayCompare: this._datePipe.transform(date, 'yyyy-MM-dd'),
                messages: []
              };
              this._messages_grouped.push(groupToPush);
              this._days.push(day);
            }
            groupToPush.messages.push(message);
          }
        );
      }
    }

    // always sort messages
    this._messages_grouped.sort(
      (day_group, day_group_B) => {
        if (day_group.dayCompare > day_group_B.dayCompare) { return -1; }
        else if (day_group.dayCompare < day_group_B.dayCompare) { return 1; }
        // a must be equal to b
        return 0;
      }
    );

    // custom detection
    this.detectChanges();
  }

  constructor(
    private _messageService: MessageService,
    private _obligationsService: ObligationService,
    private _ordersService: OrderService,
    private _configurationServ: UserConfigurationService,
    private _notificationServ: NotificationService,
    private _datePipe: DatePipe,
    private _cdr: ChangeDetectorRef
  ) {
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 20000
    );
    
    // load user configuration
    this._messagesConfig = this._configurationServ.configuration.defaultMessagesConfiguration;
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  public detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }
  
  get loading(): boolean {
    return this._messageService.loadingMessagesHistory;
  }

  
  /****************************************************************/
  /* Obligations and Orders of messages */
  /****************************************************************/
  private _obligation_keys: Array<number> = [];
  private _obligations: Array<Obligation> = [];

  private _order_keys: Array<number> = [];
  private _orders: Array<Order> = [];

  private associateObligationsOrders(messages: Array<Message>): void {
    // loop messages and find obligation_keys
    messages.forEach(
      m => {
        if (m.obligation_key) {
          if (!this._obligation_keys.includes(m.obligation_key)) {
            this._obligation_keys.push(m.obligation_key);
          }
        }
        if (m.order_key) {
          // hotfix - invoke new loading of order
          if (m.msg && m.msg.includes('upravena/doplněna')) {
            this._orders = this._orders.filter(o => o.order_key != m.order_key);
          }

          if (!this._order_keys.includes(m.order_key)) {
            this._order_keys.push(m.order_key);
          }
        }
      }
    );

    // loop obligation_keys and load not loaded obligation
    this._obligation_keys.forEach(
      key => {
        if (!this._obligations.find(o => o.obligation_key == key)) {
          this._subscribed.push(
            this._obligationsService.getObligationToComponent(key).subscribe(
              response => {
                if (response && response.obligation_key) {
                  let obligation: Obligation = ObligationTools.buildObligation(response);
                  this._obligations.push(obligation);
                  // add obligations object 
                  this.associateObligationsOrdersMessages(messages);
                }
              },
              error => {
                console.log(error);
              }
            )
          );
        }
      }
    );

    // loop order_keys and load not loaded order
    this._order_keys.forEach(
      key => {
        if (!this._orders.find(o => o.order_key == key)) {
          this._subscribed.push(
            this._ordersService.getOrderToComponent(key).subscribe(
              response => {
                if (response && response.order_key) {
                  let order: Order = OrderTools.buildOrder(response);
                  this._orders.push(order);
                  // add obligations object 
                  this.associateObligationsOrdersMessages(messages);
                }
              },
              error => {
                console.log(error);
              }
            )
          );
        }
      }
    );

    // add obligations object
    this.associateObligationsOrdersMessages(messages);
  }

  private associateObligationsOrdersMessages(messages: Array<Message>): void {
    messages.forEach(
      m => {
        if (m.obligation_key) {
          m.obligation = this._obligations.find(o => o.obligation_key == m.obligation_key);
          // associate itinerary
          if (m.itinerary_key && m.obligation) {
            m.itinerary = m.obligation.itinerary.find(it => it.itinerary_key == m.itinerary_key);
          }
        }
        if (m.order_key) {
          m.order = this._orders.find(o => o.order_key == m.order_key);
        }
      }
    );
    
    // custom detection
    this.detectChanges();
  }

  
  /************************************************************/
  /* Wienerberger */
  /************************************************************/
  public orderToWienerberger: Order = null;
  public orderNumberFormattedToWienerberger: string = null;
  handleOrderToWienerberger(event: Order): void {
    this.orderToWienerberger = event;
    if (this.orderToWienerberger && this.orderToWienerberger.obligations && this.orderToWienerberger.obligations.length) {
      let tmp_order_number: string = this.orderToWienerberger.obligations[0].order_number;
      // exact 10 string length
      if (tmp_order_number.length > 10) {
        tmp_order_number = tmp_order_number.substring(0, 10);
      }

      if (tmp_order_number.includes('/')) {
        // use the string before backslash symbol
        tmp_order_number = tmp_order_number.split('/')[0];
      }
      // formatted to 10 digit string - prefixed with zeros
      tmp_order_number = '0'.repeat(10 - tmp_order_number.length) + tmp_order_number;
      this.orderNumberFormattedToWienerberger = tmp_order_number;
    }
    this.detectChanges();
  }

  public urlWienerberger: string = null;
  handleUrlWienerberger(event: string): void {
    this.urlWienerberger = event;
    this.detectChanges();
  }

  public spzToWienerberger: string = null;
  handleSpzToWienerberger(event: string): void {
    this.spzToWienerberger = event;
    this.detectChanges();
  }

  public driverToWienerberger: string = null;
  handleDriverToWienerberger(event: string): void {
    this.driverToWienerberger = event;
    this.detectChanges();
  }

  public phoneToWienerberger: string = null;
  handlePhoneToWienerberger(event: string): void {
    this.phoneToWienerberger = event;
    this.detectChanges();
  }

  public loadingDateToWienerberger: Date = null;
  handleLoadingDateToWienerberger(event: Date): void {
    this.loadingDateToWienerberger = event;
    this.detectChanges();
  }
  
  copyToClipboard(text: string): void {
    if (text) {
      navigator.clipboard.writeText(text);
      this._notificationServ.alert($localize`Text byl zkopírován`, 'success', 3000);
    }
  }
}