<div style="width:100%; height:100%" id="googleMap{{key}}">
</div>

<div class="mapAdditionalFunctions pointer">
  <img src="assets/icons-new/traffic-info.svg" alt="icon" height="18px"
  class="border border-secondary rounded-sm shadow" (click)="toggleTrafficLayer()"
  ngbTooltip="Dopravní info" placement="right auto" container="body" i18n-ngbTooltip/>

  <img src="assets/icons-new/question1.svg" alt="icon" height="18px"
  class="border border-secondary rounded-sm shadow"
  [ngbPopover]="helpPopover" placement="right auto" container="body"/>
  <ng-template #helpPopover>
    <div i18n>Pro vložení nové položky můžete do mapy kliknout levým tlačítkem.</div>
    <div i18n>Nakládky/vykládky je možné přemístit pomocí přetažení myší.</div>
    <div i18n>Přetažením trasy je možné vkládat tranzitní body.</div>
  </ng-template>
</div>

<!-- rebuildTimeout ||  -->
<div *ngIf="!buildMapOnOpen" class="spinner-wrapper pt-5" 
style="position:absolute; margin:0 auto; left:0; right:0; top:0; bottom:0">
  <div class="spinner-border text-primary" style="width:5rem; height:5rem;" role="status">
    <span class="sr-only"></span>
  </div>
</div>