export class Delegation {
  private _action: string;
  public get action(): string {
    return this._action;
  }
  public set action(value: string) {
    this._action = value;
  }
  
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _created: string;
  public get created(): string {
    return this._created;
  }
  public set created(value: string) {
    this._created = value;
  }
  
  private _info: any;
  public get info(): any {
    return this._info;
  }
  public set info(value: any) {
    this._info = value;
  }
  
  private _last_used: string;
  public get last_used(): string {
    return this._last_used;
  }
  public set last_used(value: string) {
    this._last_used = value;
  }
  
  private _token: string;
  public get token(): string {
    return this._token;
  }
  public set token(value: string) {
    this._token = value;
  }
  
  private _username: string;
  public get username(): string {
    return this._username;
  }
  public set username(value: string) {
    this._username = value;
  }

  private _valid_from: string;
  public get valid_from(): string {
    return this._valid_from;
  }
  public set valid_from(value: string) {
    this._valid_from = value;
  }
  
  private _valid_times: string;
  public get valid_times(): string {
    return this._valid_times;
  }
  public set valid_times(value: string) {
    this._valid_times = value;
  }
  
  private _valid_until: string;
  public get valid_until(): string {
    return this._valid_until;
  }
  public set valid_until(value: string) {
    this._valid_until = value;
  }
}
