import { Component, Input } from "@angular/core";

import { Vehicle } from "../../model/vehicle.object";

@Component({
  selector: "vehicle-info",
  templateUrl: './r-vehicle-info.component.html',
  styleUrls: ['./r-vehicle-info.component.css']
})
export class RVehicleInfoComponent {
  private _vehicle: Vehicle;
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
  }

  get source(): string {
    return '/eso/manager/car_detail.php?car_key=' + this._vehicle.car_key;
  }

}