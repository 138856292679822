import {animate, state, style, transition} from "@angular/animations";

export const slideSide: Array<any> = [
  state('active', style({
    width: '*'
  })),
  state('inactive', style({
    width: '0px'
  })),
  transition('inactive <=> active', animate('100ms ease-in'))
];
