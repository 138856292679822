import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";

import { Message } from "../../model/message.object";
import { Order } from "src/app/model/order.object";
import { NotificationService } from "src/app/service/notification-service";

declare var $: any;

@Component({
  selector: 'messages-list',
  templateUrl: './r-messages-list.component.html',
  styleUrls: ['./r-messages-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RMessagesListComponent {

  private _messages: Array<Message> = [];
  get messages(): Array<Message> {
    return this._messages;
  }
  @Input()
  set messages(messages: Array<Message>) {
    if (messages && messages.length) {
      this._messages = messages;
      // this._messages.sort((a, b) => a.time > b.time ? -1 : 1);
      this._messages.sort(
        (m1, m2) => {
          if (m1.time > m2.time) { return -1; }
          else if (m1.time < m2.time) { return 1; }
          return 0;
        }
      );

      // set little timeout for downloading thumbnails
      let thumbnail_timeout: number = 0;
      this._messages.forEach(
        m => {
          if (m.attachment) {
            if (m.attachment) {
              m.attachment.timeoutThumbnail = thumbnail_timeout;
              // 2 seconds increment
              thumbnail_timeout += 2000;
            }
          }
        }
      );
    }
    // custom detection
    this.detectChanges();
  }


  private _reply: boolean = false;
  get reply(): boolean {
    return this._reply;
  }
  @Input()
  set reply(reply: boolean) {
    this._reply = reply;
    // custom detection
    this.detectChanges();
  }
  
  @Output() orderToWienerbergerEvent: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() urlWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() spzToWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() driverToWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() phoneToWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() loadingDateToWienerbergerEvent: EventEmitter<Date> = new EventEmitter<Date>();


  constructor(
    private _cdr: ChangeDetectorRef
  ) {
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 20000
    );
  }
  
  public detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }


  /************************************************************/
  /* Wienerberger */
  /************************************************************/
  public orderToWienerberger: Order = null;
  handleOrderToWienerberger(event: Order): void {
    this.orderToWienerberger = event;
    this.orderToWienerbergerEvent.emit(this.orderToWienerberger);
    this.detectChanges();
  }

  public urlWienerberger: string = null;
  handleUrlWienerberger(event: string): void {
    this.urlWienerberger = event;
    this.urlWienerbergerEvent.emit(this.urlWienerberger);
    this.detectChanges();
  }

  public spzToWienerberger: string = null;
  handleSpzToWienerberger(event: string): void {
    this.spzToWienerberger = event;
    this.spzToWienerbergerEvent.emit(this.spzToWienerberger);
    this.detectChanges();
  }

  public driverToWienerberger: string = null;
  handleDriverToWienerberger(event: string): void {
    this.driverToWienerberger = event;
    this.driverToWienerbergerEvent.emit(this.driverToWienerberger);
    this.detectChanges();
  }

  public phoneToWienerberger: string = null;
  handlePhoneToWienerberger(event: string): void {
    this.phoneToWienerberger = event;
    this.phoneToWienerbergerEvent.emit(this.phoneToWienerberger);
    this.detectChanges();
  }

  public loadingDateToWienerberger: Date = null;
  handleLoadingDateToWienerberger(event: Date): void {
    this.loadingDateToWienerberger = event;
    this.loadingDateToWienerbergerEvent.emit(this.loadingDateToWienerberger);
    this.detectChanges();
  }

}