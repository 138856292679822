import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Attachment } from 'src/app/model/attachment.object';
import { Email } from 'src/app/model/email.object';
import { Invoice } from 'src/app/model/invoice.object';
import { Obligation } from 'src/app/model/obligation.object';
import { FilesService } from 'src/app/service/files.service';
import { InvoiceService } from 'src/app/service/invoice.service';
import { NotificationService } from 'src/app/service/notification-service';
import { ObligationService } from 'src/app/service/obligation.service';

@Component({
  selector: 'app-ta3-invoice-email-logs',
  templateUrl: './ta3-invoice-email-logs.component.html',
  styleUrls: ['./ta3-invoice-email-logs.component.css']
})
export class Ta3InvoiceEmailLogsComponent implements OnInit, OnDestroy {
  
  private _subscribed: Array<Subscription> = [];

  private _invoice: Invoice = new Invoice();
  @Input()
  public set invoice(value: Invoice) {
    this._invoice = value;
  }
  public get invoice(): Invoice {
    return this._invoice;
  }
  
  private _obligation: Obligation = new Obligation();
  @Input()
  public set obligation(value: Obligation) {
    this._obligation = value;
    if (this._obligation && this._obligation.invoices.length) {
      // lets support only one invoice, not array
      this._invoice = this._obligation.invoices[0];
      // also init attachments (in company_obligation they are not initialized)
      this.initAttachments();
    }
  }

  // attachments of invoice/obligation
  @Input() attachments: Array<Attachment> = [];
  // flag - currently printing invoice pdf
  @Input() printing: boolean = false;
  // flag - currently opening invoice pdf
  @Input() openingPrintedPDF: boolean = false;


  constructor(
    private _notificationServ: NotificationService,
    private _invoiceServ: InvoiceService,
    private _obligationServ: ObligationService,
    private _filesServ: FilesService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
  }


  /*************************************************/
  /* Emails part */
  /*************************************************/
  // flag when email is currently being sent
  public get sendingEmail(): boolean {
    return this._invoiceServ.sendingEmail;
  }

  // getter for only sent email records
  get emailsOnly(): Array<Email> {
    if (!this._invoice.email_headers) return [];
    return this._invoice.email_headers.filter(e => e.subject != 'TM internal: Invoice printed');
  }

  // getter for only printing pdf records
  get printingsOnly(): Array<Email> {
    if (!this._invoice.email_headers) return [];
    return this._invoice.email_headers.filter(e => e.subject == 'TM internal: Invoice printed');
  }

  // output event binding method
  eventAfterEmailSent(event) {
    if (event) {
      // init if any email has not been sent yet
      if (!this._invoice.email_msg_ids) this._invoice.email_msg_ids = [];

      this._invoice.email_msg_ids.push(event);
      
      this._subscribed.push(
        this._invoiceServ.getEmailHeader(event).subscribe(
          (header: Email) => {
            if (header) {
              // save email_id
              header.email_msg_id = event;
              // reinit because of possible overpushing
              header.filesObjects = [];

              // connect attachments with emails
              this.attachments.forEach(
                attachment => {
                  // has attachment been attached?
                  if (header.files && header.files.length) {
                    let findName = header.files.find(f => f.includes(attachment.name));
                    if (findName) {
                      header.filesObjects.push(attachment);
                    }
                  }
                }
              );
              this._invoice.email_headers.push(header);
            }
          }
        )
      );
    }
  }

  // method for loading detail html page about email sent
  showEmailDetail(email_msg_id: string): void {
    this._invoiceServ.getEmailShow(email_msg_id).subscribe(
      response => {
        if (response) {
          // let parser = new DOMParser();
          // let doc = parser.parseFromString(response, "text/html");
          // using this solution now: https://stackoverflow.com/a/10573430
          let newWindow = window.open();
          newWindow.document.write(response);
        }
      }
    );
  }

  // method for resending email
  resendEmail(header: Email): void {
    let email: any = {
      from: header.from,
      to: header.to,
      invoice_key: this._invoice.invoice_key,
      cc: header.cc,
      subject: header.subject,
      body: header.body,
      files: this.filesUrlNormalized(header)
      // files: (header.files && header.files.length) ? 
      // header.files.filter(f => f.startsWith('/files/company')) : []
    };
    console.log(email);

    this._invoiceServ.sendEmail(email).subscribe(
      response => {
        if (response) {
          this.eventAfterEmailSent(response);
        }
      }
    );
  }

  // getter for urls of all attached files
  filesUrlNormalized(header: Email): Array<string> {
    let arr: Array<string> = [];
    if (!header.filesObjects || !header.filesObjects.length) return [];

    header.filesObjects.forEach(
      f => {
        if (f.url_normalized) {
          arr.push(f.url_normalized);
        }
      }
    );

    return arr;
  }


  /************************************************************/
  /* File attachment part */
  /************************************************************/
  // flag for signalization that attachment is downloading
  public loadingAttachment: boolean = false;

  // method for initializing headers (used for obligation.invoice)
  initAttachments(): void {
    // init attachments from obligation(s)
    let attachments: Array<Attachment> = [];
    if (this._invoice.obligations) {
      this._invoice.obligations.forEach(
        o => {
          if (o.attachments) attachments = attachments.concat(o.attachments);
          if (o.files) attachments = attachments.concat(o.files);
        }
      );
    }

    // download thumbnails
    attachments.forEach(
      attachment => {
        this.downloadThumbnail(attachment);
      }
    );

    // init attached headers
    if (this._invoice.email_headers && this._invoice.email_headers.length) {
      this._invoice.email_headers.forEach(
        (header) => {
          // reinit because of possible overpushing
          header.filesObjects = [];

          // connect attachments with emails
          attachments.forEach(
            attachment => {
              // has attachment been attached?
              if (header.files && header.files.length) {
                // header files used to be array of string ['xxx1', 'xxx2', 'xxx3']
                // now it is array of objects - { index: 0, name: 'xxx' }
                let findName = header.files.find(
                  f => {
                    f.name ? f.name.includes(attachment.name) : f.includes(attachment.name)
                  }
                );
                if (findName) {
                  header.filesObjects.push(attachment);
                }
              }
            }
          );
        }
      );
    }
    // store in attribute 
    this.attachments = attachments;
  }

  downloadThumbnail(attachment: Attachment): void {
    if (attachment.url && attachment.url.includes('files/')) {
      let thumbUrl: string = '';
      if (attachment.url.includes('/files/company/')) thumbUrl = attachment.url.replace('company/', 'company/thumb/');
      else thumbUrl = attachment.url + '/thumb';
      
      this._obligationServ.getAttachment(thumbUrl).subscribe(
        response => {
          let newBlob: any = new Blob([response], { type: response.type });

          if (response.type !== '' && (response.type.match(/image|pdf|text/))) {
            attachment.thumbnail = {
              content: URL.createObjectURL(newBlob),
              type: response.type,
              blob: newBlob   // keep blob for base 64
            };
            // create base64 via file service
            this._filesServ.createBase64ForAttachment(attachment);
          }
        },
        error => {
          // set flag after error loading
          console.log(error);
        }
      );
    }
  }

  // method for customizing width of popover div
  widthPopoverOfFiles(header: Email): string {
    let len: number = header.filesObjects ? header.filesObjects.length : 0;
    if (len < 2) return '140px';
    else if (len < 3) return '280px';
    else if (len < 4) return '420px';
    else return '550px';
  }

  // method for opening attachment in new tab
  openAttachmentNewTab(attachment: Attachment): void {
    this._obligationServ.openAttachmentNewTab(attachment);
  }


  /************************************************************/
  /* Methods for printing of invoice */
  /************************************************************/
  // method for downloading and opening printed PDF
  openPrintedPDF(email_id: string): void {
    if (!email_id) return;

    this.openingPrintedPDF = true;

    this._invoiceServ.getPrintedPDF(email_id).subscribe(
      response => {
        if (response) {
          let newBlob: any = new Blob([response], { type: (response.type ? response.type : 'application/pdf') });
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && (navigator as any).msSaveOrOpenBlob) {
            (navigator as any).msSaveOrOpenBlob(newBlob);
            return;
          }
      
          // For other browsers: 
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
      
          // var link = document.createElement('a');
          // link.href = data;
          // link.download = attachment.name;
          // // this is necessary as link.click() does not work on the latest firefox
          // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      
          // open new tab (does not work when ad block is activated)
          let w = window.open(data, '_blank');
          if (w) {
            setTimeout(() => w.document.title = this._invoice.invoiceNumberFormatted, 500);
          }
      
          // setTimeout(function () {
          //     // For Firefox it is necessary to delay revoking the ObjectURL
          //     window.URL.revokeObjectURL(data);
          //     link.remove();
          // }, 100);
          
          // set flag after success loading
          this.openingPrintedPDF = false;
        }
      },
      error => {
        console.log(error);
        // set flag after error loading
        this.openingPrintedPDF = false;
        this._notificationServ.alert(
          $localize`Chyba při stahování PDF faktury` + ' - ' + this._invoice.invoiceNumberFormatted, 
          'error', 3500
        );
      }
    );
  }
}
