import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Angular2Wamp } from "../angular2-wamp/angular2-wamp";
import { WebsocketResponse } from "../interface/websocket-response.interface";

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  
  static RELATIONS: any = {
    car: 'car',
    message: 'message',
    tracking: 'tracking',
    service: 'service',
    aetr: 'aetr',
    fueling: 'fuel',
    health: 'health',
    dest_route: 'dest_route',
    reach_event: 'reach_event',
    obligation: 'obligation',
    obligation_itinerary: 'obligation_itinerary',
    obligation_msg: 'obligation_msg',
    order: 'order',
    external_service_import: 'external-service-import',
    external_service_import_car: 'external-service-import-car'
  };

  static OPERATIONS: any = {
    insert: 'insert',
    update: 'update',
    delete: 'delete'
  };

  private openSubscriber;
  private closeSubscriber;
  private _lastConnection: any;

  constructor(
    private _wampService: Angular2Wamp
  ) {
  }

  private _companyMessage: EventEmitter<WebsocketResponse> = new EventEmitter();
  get companyMessage(): Observable<WebsocketResponse> {
    return this._companyMessage.asObservable();
  }

  get connected(): boolean {
    return this._wampService.websocket_connected;
  }

  connect(token, company_key) {
    if (this.openSubscriber) {
      this.openSubscriber.unsubscribe();
    }
    console.warn('WS', 'Opening connection to ' + this._wampService.url, (new Date()).toLocaleString());
    this._lastConnection = {token: token, company_key: company_key};
    this.openSubscriber = this._wampService.connect().subscribe(
      success => {
        this._wampService.call('auth', [token, company_key]).subscribe(
          success => {
            console.warn('WS', 'SUCCESS CONNECTION', (new Date()).toLocaleString());
            this._wampService.subscribe('company.' + company_key).subscribe(
              (result) => {
                // console.log(result);
                this.handleCompanyMessage(result.data);
              }
            )
          },
          error => {
            console.warn('WS', 'CONNECTION ERROR:', error, (new Date()).toLocaleString());
            if (error && error.uri == 'auth') {
              // open warning modal
              (<any>$('#authModal')).modal('show');
            }
          }
        );
      },
      error => {
        console.warn('WS', 'CONNECTION ERROR:', error, (new Date()).toLocaleString());
      }
    );
    this.closeSubscriber = this._wampService.onClose.subscribe(
      close => {
        this.cleanUp();
        if ([Angular2Wamp.CONNECTION_LOST, Angular2Wamp.CONNECTION_UNREACHABLE].indexOf(close[0]) > -1) {
          window.setTimeout(
            () => {
              this.reconnect();
            },
            500
          );
        }
        console.warn('WS', 'CLOSED OK', close, (new Date()).toLocaleString());
      },
      error => {
        console.warn('WS', 'CLOSING ERROR', error, (new Date()).toLocaleString());
        this.cleanUp();
      }
    );
  }

  close() {
    this._wampService.closeConnection();
  }

  subscribe(on) {
    return this._wampService.subscribe(on);
  }

  reconnect() {
    if (this._lastConnection && !this.openSubscriber) {
      this.connect(this._lastConnection.token, this._lastConnection.company_key);
    }
  }

  private cleanUp() {
    if (this.openSubscriber) {
      this.openSubscriber.unsubscribe();
      this.openSubscriber = null;
    }
    if (this.closeSubscriber) {
      this.closeSubscriber.unsubscribe();
      this.closeSubscriber = null;
    }
  }

  private handleCompanyMessage(data) {
    // console.log(data);
    this._companyMessage.emit(data);
  }

  /* ************************* */
  /* TODO completely rework
  
  // import { connections } from "../connect";
  // import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
  // import { catchError, tap, switchAll, retryWhen, delayWhen } from 'rxjs/operators';
  // import { EMPTY, Subject } from 'rxjs';
  // import { Injectable } from '@angular/core';
  // import { environment } from '../../environments/environment';
  // export const WS_ENDPOINT = environment.wsEndpoint;

  private _url: string = connections.websocket;
  public get url(): string {
    return this._url;
  }

  // Reimplemented logic of angular2-wamp
  private socket$: WebSocketSubject<any>;
  private messagesSubject$ = new Subject();
  public messages$ = this.messagesSubject$.pipe(
    switchAll(), 
    catchError(e => { throw e })
  );

  public connect(token, company_key, cfg: { reconnect: boolean } = { reconnect: false }): void {
    // if (this.openSubscriber) {
    //     this.openSubscriber.unsubscribe();
    // }

    console.warn('WS', 'Opening connection to ' + this._url, (new Date()).toLocaleString());
    this._lastConnection = {token: token, company_key: company_key};

    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      const messages = this.socket$.pipe(
        cfg.reconnect ? this.reconnect : o => o,
        tap({ error: error => console.log(error) }), 
        catchError(_ => EMPTY)
      );
      this.messagesSubject$.next(messages);
    }
  }
    
  private getNewWebSocket(): WebSocketSubject<any> {
    return webSocket(
      {
        url: this._url,
        closeObserver: {
          next: () => {
            console.log('[WebSocketService]: connection closed');
            console.warn('WS', 'CLOSED', (new Date()).toLocaleString());
            this.socket$ = undefined;
            this.connect(this._lastConnection.token, this._lastConnection.company_key, { reconnect: true });
          }
        },
      }
    );
  }

  sendMessage(msg: any) {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(msg);
    }
  }

  reconnect(observable: Observable<any> = null): Observable<any> {
    if (!observable) observable = this.messagesSubject$;

    console.log('Reconnect');

    return observable.pipe(
      retryWhen( 
        errors => errors.pipe(
          tap( val => console.log('[WebSocketService]: Trying to reconnect', val )), 
          delayWhen( _ => timer(500) )
        )
      )
    ); 
  }

  close() {
    if (this.socket$) {
      this.socket$.complete();
    }
  } */
  /* ************************* */
}

