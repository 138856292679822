export class Settings {
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _updated_time: string;
  public get updated_time(): string {
    return this._updated_time;
  }
  public set updated_time(value: string) {
    this._updated_time = value;
  }

  private _updated_time_series: string;
  public get updated_time_series(): string {
    return this._updated_time_series;
  }
  public set updated_time_series(value: string) {
    this._updated_time_series = value;
  }

  // settings/series only - https://app2.truckmanager.eu/nettedev/api/v1/settings/series
  private _series: any = {};
  public get series(): any {
    return this._series;
  }
  public set series(value: any) {
    this._series = value;
  }

  public get apiSeriesObject(): any {
    return {
      series: this.series
    };
  }

  // settings/invoicing - template https://app2.truckmanager.eu/nettedev/api/v1/settings/invoicing
  private _items: any = {};
  public get items(): any {
    return this._items;
  }
  public set items(value: any) {
    this._items = value;
  }

  private _accounts: Array<any> = [];
  public get accounts(): Array<any> {
    return this._accounts;
  }
  public set accounts(value: Array<any>) {
    this._accounts = value;
  }

  private _currency_to_series: any = {};
  public get currency_to_series(): any {
    return this._currency_to_series;
  }
  public set currency_to_series(value: any) {
    this._currency_to_series = value;
  }

  private _variables: any = {};
  public get variables(): any {
    return this._variables;
  }
  public set variables(value: any) {
    this._variables = value;
  }

  private _first_day_of_month: boolean;
  public get first_day_of_month(): boolean {
    return this._first_day_of_month;
  }
  public set first_day_of_month(value: boolean) {
    this._first_day_of_month = value;
  }
  
  private _image_logo_url: string;
  public get image_logo_url(): string {
    return this._image_logo_url;
  }
  public set image_logo_url(value: string) {
    this._image_logo_url = value;
  }

  private _image_signature_url: string;
  public get image_signature_url(): string {
    return this._image_signature_url;
  }
  public set image_signature_url(value: string) {
    this._image_signature_url = value;
  }

  private _invoice_numbering_start: number;
  public get invoice_numbering_start(): number {
    return this._invoice_numbering_start;
  }
  public set invoice_numbering_start(value: number) {
    this._invoice_numbering_start = value;
  }

  private _invoice_numbering_year: number;
  public get invoice_numbering_year(): number {
    return this._invoice_numbering_year;
  }
  public set invoice_numbering_year(value: number) {
    this._invoice_numbering_year = value;
  }
  
  private _invoice_received_numbering_start: number;
  public get invoice_received_numbering_start(): number {
    return this._invoice_received_numbering_start;
  }
  public set invoice_received_numbering_start(value: number) {
    this._invoice_received_numbering_start = value;
  }

  private _invoice_received_numbering_year: number;
  public get invoice_received_numbering_year(): number {
    return this._invoice_received_numbering_year;
  }
  public set invoice_received_numbering_year(value: number) {
    this._invoice_received_numbering_year = value;
  }
  
  private _invoice_cash_numbering_start: number;
  public get invoice_cash_numbering_start(): number {
    return this._invoice_cash_numbering_start;
  }
  public set invoice_cash_numbering_start(value: number) {
    this._invoice_cash_numbering_start = value;
  }

  private _invoice_cash_numbering_year: number;
  public get invoice_cash_numbering_year(): number {
    return this._invoice_cash_numbering_year;
  }
  public set invoice_cash_numbering_year(value: number) {
    this._invoice_cash_numbering_year = value;
  }
  
  private _invoice_others_numbering_start: number;
  public get invoice_others_numbering_start(): number {
    return this._invoice_others_numbering_start;
  }
  public set invoice_others_numbering_start(value: number) {
    this._invoice_others_numbering_start = value;
  }

  private _invoice_others_numbering_year: number;
  public get invoice_others_numbering_year(): number {
    return this._invoice_others_numbering_year;
  }
  public set invoice_others_numbering_year(value: number) {
    this._invoice_others_numbering_year = value;
  }
  
  private _invoice_number_format: string;
  public get invoice_number_format(): string {
    return this._invoice_number_format;
  }
  public set invoice_number_format(value: string) {
    this._invoice_number_format = value;
  }

  private _invoice_allow_gaps_in_numbering: boolean;
  public get invoice_allow_gaps_in_numbering(): boolean {
    return this._invoice_allow_gaps_in_numbering;
  }
  public set invoice_allow_gaps_in_numbering(value: boolean) {
    this._invoice_allow_gaps_in_numbering = value;
  }

  private _exchange_rates: any = {};
  public get exchange_rates(): any {
    return this._exchange_rates;
  }
  public set exchange_rates(value: any) {
    this._exchange_rates = value;
  }

  private _order_and_obligation_numbers_equal: boolean;
  public get order_and_obligation_numbers_equal(): boolean {
    return this._order_and_obligation_numbers_equal;
  }
  public set order_and_obligation_numbers_equal(value: boolean) {
    this._order_and_obligation_numbers_equal = value;
  }


  public get apiInvoicingObject(): any {
    return {
      items: this.items,
      accounts: this.accounts,
      currency_to_series: this.currency_to_series,
      variables: this.variables,
      first_day_of_month: this.first_day_of_month,
      image_logo_url: this.image_logo_url,
      image_signature_url: this.image_signature_url,
      invoice_numbering_year: this.invoice_numbering_year,
      invoice_numbering_start: this.invoice_numbering_start,
      invoice_received_numbering_year: this.invoice_received_numbering_year,
      invoice_received_numbering_start: this.invoice_received_numbering_start,
      invoice_cash_numbering_year: this.invoice_cash_numbering_year,
      invoice_cash_numbering_start: this.invoice_cash_numbering_start,
      invoice_others_numbering_year: this.invoice_others_numbering_year,
      invoice_others_numbering_start: this.invoice_others_numbering_start,
      invoice_number_format: this.invoice_number_format,
      invoice_allow_gaps_in_numbering: this.invoice_allow_gaps_in_numbering,
      exchange_rates: this.exchange_rates,
      order_and_obligation_numbers_equal: this.order_and_obligation_numbers_equal
    };
  }


  // settings/docs - template https://app2.truckmanager.eu/nettedev/api/v1/settings/docs
  private _texts: any = {};
  public get texts(): any {
    return this._texts;
  }
  public set texts(value: any) {
    this._texts = value;
  }

  private _presets: any = {};
  public get presets(): any {
    return this._presets;
  }
  public set presets(value: any) {
    this._presets = value;
  }

  private _bookkeeping_export: string;
  public get bookkeeping_export(): string {
    return this._bookkeeping_export;
  }
  public set bookkeeping_export(value: string) {
    this._bookkeeping_export = value;
  }

  private _trade_register_where: string;
  public get trade_register_where(): string {
    return this._trade_register_where;
  }
  public set trade_register_where(value: string) {
    this._trade_register_where = value;
  }

  private _trade_register_file: string;
  public get trade_register_file(): string {
    return this._trade_register_file;
  }
  public set trade_register_file(value: string) {
    this._trade_register_file = value;
  }

  private _trade_register_date: string;
  public get trade_register_date(): string {
    return this._trade_register_date;
  }
  public set trade_register_date(value: string) {
    this._trade_register_date = value;
  }

  public get apiDocObject(): any {
    return {
      texts: this.texts,
      presets: this.presets
    };
  }
}
