import {HaversinePositionArgInterface} from "./HaversinePositionArgInterface";
import {HaversineOptionsArgInterface} from "./HaversineOptionsArgInterface";
import {GoogleLatLng} from "../model/google-lat-lng.object";

export class Haversine {

  static toRad(num) {
    return num * Math.PI / 180;
  };

  /**
   * Determines the great-circle distance between two points on a sphere
   * @param {HaversinePositionArgInterface} start
   * @param {HaversinePositionArgInterface} end
   * @param {HaversineOptionsArgInterface} options
   * @returns {number}
   */
  static haversine(start: HaversinePositionArgInterface, end: HaversinePositionArgInterface, options?: HaversineOptionsArgInterface): number {
    let km = 6371;
    let mile = 3960;
    options = options || {};

    // let R = options.unit === 'km' ? km : mile;
    let R: number = km;

    let dLat = Haversine.toRad(end.latitude - start.latitude);
    let dLon = Haversine.toRad(end.longitude - start.longitude);
    let lat1 = Haversine.toRad(start.latitude);
    let lat2 = Haversine.toRad(end.latitude);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c
  }

  /**
   * Invoke to get coordinates between two location
   * @param lat1
   * @param lng1
   * @param lat2
   * @param lng2
   * @param interval_meters
   * @return {Array|*}
   */
  static getCoordinates(lat1: number, lng1: number, lat2: number, lng2: number, interval_meters: number): Array<GoogleLatLng> {
    let azimuth, coords;

    // point interval in meters
    if (!interval_meters) {
      interval_meters = 20.0
    }

    azimuth = this.calculateBearing(lat1, lng1, lat2, lng2);
    coords = this._buildCoordinates(interval_meters, azimuth, lat1, lng1, lat2, lng2);

    return coords;
  }

  /**
   * Calculates the distance between two lat, long coordinate pairs
   * @param lat1
   * @param lng1
   * @param lat2
   * @param lng2
   *
   * @return integer
   */
  private static getPathLength(lat1, lng1, lat2, lng2): number {
    let lat1rads, lat2rads, deltaLat, deltaLng,
      a, c, dist_metre, R;

    // Avoid to return NAN, if finding distance between same lat long.
    if (lat1 == lat2 && lng1 == lng2) {
      return 0;
    }

    //Earth Radius (in metre)
    R = 6371000;

    lat1rads = this.degreesToRadians(lat1);
    lat2rads = this.degreesToRadians(lat2);
    deltaLat = this.degreesToRadians((lat2 - lat1));
    deltaLng = this.degreesToRadians((lng2 - lng1));

    a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1rads) * Math.cos(lat2rads) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    dist_metre = R * c;

    if (isNaN(dist_metre)) {
      return 0;
    }

    return dist_metre
  };

  private static degreesToRadians(degree) {
    return degree * Math.PI / 180;
  }

  private static radiansToDegrees = function (radians) {
    return radians * 180 / Math.PI;
  };

  /**
   * returns the lat and long of destination point
   * given the start lat, long, aziuth, and distance.
   *
   * @param lat
   * @param lng
   * @param azimuth
   * @param distance_metre
   * @return {*[]}
   */
  private static _getDestinationLatLong(lat: number, lng: number, azimuth: number, distance_metre: number): GoogleLatLng {
    let lat2, lng2, R, brng, d_km, lat1, lng1;

    R = 6378.1; //Radius of the Earth in km

    //Bearing is degrees converted to radians.
    brng = this.degreesToRadians(azimuth);
    d_km = distance_metre / 1000;
    lat1 = this.degreesToRadians(lat);
    lng1 = this.degreesToRadians(lng);

    lat2 = Math.asin(Math.sin(lat1) * Math.cos(d_km / R) +
      Math.cos(lat1) * Math.sin(d_km / R) * Math.cos(brng));
    lng2 = lng1 +
      Math.atan2(
        Math.sin(brng) * Math.sin(d_km / R) * Math.cos(lat1),
        Math.cos(d_km / R) - Math.sin(lat1) * Math.sin(lat2));

    //convert back to degrees
    lat2 = this.radiansToDegrees(lat2);
    lng2 = this.radiansToDegrees(lng2);

    return new GoogleLatLng(parseFloat(lat2.toFixed(6)), parseFloat(lng2.toFixed(6)));
  }

  /**
   * calculates the azimuth in degrees from start point to end point
   *
   * @param lat1
   * @param lng1
   * @param lat2
   * @param lng2
   * @return {*}
   */
  private static calculateBearing(lat1: number, lng1: number, lat2: number, lng2: number) {
    let startLat, startLong, endLat, endLong, dLong, dPhi, bearing;

    startLat = this.degreesToRadians(lat1);
    startLong = this.degreesToRadians(lng1);
    endLat = this.degreesToRadians(lat2);
    endLong = this.degreesToRadians(lng2);

    dLong = endLong - startLong;
    dPhi = Math.log(Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0));

    if (Math.abs(dLong) > Math.PI) {
      if (dLong > 0) {
        dLong = -(2.0 * Math.PI - dLong)
      } else {
        dLong = (2.0 * Math.PI + dLong)
      }
    }

    bearing = (this.radiansToDegrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;

    return bearing;
  }

  /**
   * Invoke to returns every coordinate pair in-between two coordinate pairs
   * given the desired interval
   *
   * @param interval
   * @param azimuth
   * @param lat1
   * @param lng1
   * @param lat2
   * @param lng2
   *
   * @return {Array}
   */
  private static _buildCoordinates(interval: number, azimuth: number, lat1: number, lng1: number, lat2: number, lng2: number) {
    let d, dist: number, counter, _coord;

    d = this.getPathLength(lat1, lng1, lat2, lng2);

    dist = parseInt((d / interval).toString());

    let coords: Array<GoogleLatLng> = [];
    coords.push(new GoogleLatLng(lat1, lng1));

    counter = interval;

    for (let i = 0; i < dist; i++) {
      _coord = this._getDestinationLatLong(lat1, lng1, azimuth, counter);
      counter = counter + interval;
      coords.push(_coord);
    }

    coords.push(new GoogleLatLng(lat2, lng2));

    return coords;

  }
}