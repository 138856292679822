import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class TemplateJsonService {

  constructor(
    private _http: HttpClient,
    private _authService: AuthenticationService
  ) {
  }

  getInvoiceTemplateDictionary(): Observable<any> {
    return this._http.get(ServiceConfiguration.invoices.templateDictionary);
  }
}
