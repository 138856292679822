import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from './notification-service';
import { IS_DEMO, ServiceConfiguration } from "../config";
import { Settings } from "../model/settings.object";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private _loadingSettingsSeries: boolean = false;
  public get loadingSettingsSeries(): boolean {
    return this._loadingSettingsSeries;
  }

  private _loadingSettingsInvoicing: boolean = false;
  public get loadingSettingsInvoicing(): boolean {
    return this._loadingSettingsInvoicing;
  }

  private _loadingSettingsDocs: boolean = false;
  public get loadingSettingsDocs(): boolean {
    return this._loadingSettingsDocs;
  }

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService,
  ) {
  }

  getSettingsSeries(): Observable<Settings> {
    let settingsSeries: BehaviorSubject<Settings> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingSettingsSeries = true;
      this._http.get(ServiceConfiguration.settings.apiSeries).subscribe(
        response => {
          let settings: Settings = this.buildSettings(response);
          settingsSeries.next(settings);
          this._loadingSettingsSeries = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingSettingsSeries = false;
        }
      );
    }
    return settingsSeries.asObservable();
  }

  getSettingsInvoicing(): Observable<Settings> {
    let settingsInvoicing: BehaviorSubject<Settings> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingSettingsInvoicing = true;
      this._http.get(ServiceConfiguration.settings.apiInvoicing).subscribe(
        response => {
          let settings: Settings = this.buildSettings(response);
          settingsInvoicing.next(settings);
          this._loadingSettingsInvoicing = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingSettingsInvoicing = false;
        }
      );
    }
    return settingsInvoicing.asObservable();
  }

  getSettingsDocs(): Observable<Settings> {
    let settingsDocs: BehaviorSubject<Settings> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingSettingsDocs = true;
      this._http.get(ServiceConfiguration.settings.apiDocs).subscribe(
        response => {
          let settings: Settings = this.buildSettings(response);
          settingsDocs.next(settings);
          this._loadingSettingsDocs = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingSettingsDocs = false;
        }
      );
    }
    return settingsDocs.asObservable();
  }

  public updateSettingsSeries(setings: Settings): Observable<Settings> {
    // let updSettings: BehaviorSubject<Settings> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // initialize data
      let data = setings.apiSeriesObject;         
      return this._http.put(ServiceConfiguration.settings.apiSeries, data);
    }
  }

  public updateSetingsInvoicing(setings: Settings): Observable<Settings> {
    let updSettings: BehaviorSubject<Settings> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // initialize data
      let data = setings.apiInvoicingObject;         
      this._http.put(ServiceConfiguration.settings.apiInvoicing, data)
        .subscribe(
          response => {
            let settings: Settings = this.buildSettings(response);
            updSettings.next(settings);
          },
          error => {
            // handle error
            console.log(error);
            this._notificationService.alert(
              $localize`Nastavení neproběhlo.`, 'error', 3000
            );
          },
          () => {
            // finally
            this._notificationService.alert(
              $localize`Nastavení bylo provedeno.`, 'success', 3000
            );
          }
        );
      }
    return updSettings.asObservable();
  }

  // method for updating trailer properties
  public updateSetingsDoc(setings: Settings): Observable<Settings> {
    let updSettings: BehaviorSubject<Settings> = new BehaviorSubject(null);
    
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // initialize data
      let data = setings.apiDocObject;            
      this._http.put(ServiceConfiguration.settings.apiDocs , data)
        .subscribe(
          response => {
            let settings: Settings = this.buildSettings(response);
            updSettings.next(settings);
          },
          error => {
            // handle error
            console.log(error);
            this._notificationService.alert(
              $localize`Nastavení neproběhlo.`, 'error', 3000
            );
          },
          () => {
            // finally
            this._notificationService.alert(
              $localize`Nastavení bylo provedeno.`, 'success', 3000
            );
          }
        );
    }

    return updSettings.asObservable();
  }

  getDefaultDocs(): Observable<any> {
    return this._http.get(ServiceConfiguration.settings.apiDocsDefault);
  }

  getDefaultInvoicing():  Observable<any>{
    return this._http.get(ServiceConfiguration.settings.apiInvoicingDefault);
  }

  
  // method for creating a single Settings object
  public buildSettings(o: any): Settings {
    if (!o) return null;

    let settings: Settings = new Settings();
    for (let key in o) {
      settings[key] = o[key];
    }
    return settings;
  }
}

