import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

import { IS_DEMO, ServiceConfiguration } from "../config";
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from "./http-client.service";
import { NotificationService } from './notification-service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) { 
  }


  // method for creating/sending sms
  createSMS(message: string, phone: string, captcha: string): Observable<any> {
    let sendSMS: BehaviorSubject<any> = new BehaviorSubject(null);

    if (!IS_DEMO && this._authService.isAuthenticated()) {
      // initialize data
      let data = {
        msg: message,
        phone: phone,
        captcha: captcha
      };

      console.log(data);
      let url: string = ServiceConfiguration.sms.api;
      this._http.post(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`SMS byla úspěšně odeslána řidiči.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          console.log(response);
          // observable next
          sendSMS.next(response);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při odesílání SMS řidiči.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return sendSMS.asObservable();
  }
}
