<ng-container *ngIf="vehicle.aetrHistoryLoading">
  <div class="py-5 text-center">
    <div class="spinner-border text-primary" role="status" style="width: 2.5rem; height: 2.5rem;">
      <span class="sr-only"></span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dataSets.length > 0 && !vehicle.aetrHistoryLoading">
  <div style="display: block; position: relative;">
    <canvas baseChart
      height="300px"
      width="420px"
      [datasets]="dataSets"
      [labels]="displayRange"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [type]="barChartType">
      <!-- [chartType]="barChartType"> -->
    </canvas>
  </div>
</ng-container>
