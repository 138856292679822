import { DatePipe } from "@angular/common";
import { ErrorHandler, Injectable, isDevMode } from "@angular/core";
import { Subscription } from "rxjs";
import { Company } from "../model/company.object";
import { CompanyService } from "./company.service";
import { EmailingService } from "./emailing.service";

declare var ga: any;

@Injectable({
  providedIn: 'root',
})
export class TruckManagerErrorHandler implements ErrorHandler {
  
  private _subscribed: Array<Subscription> = [];
  private _company: Company = new Company();

  constructor(
    private _emailingServ: EmailingService,
    private _companyServ: CompanyService,
    private _datePipe: DatePipe
  ) {
    this._subscribed.push(
      this._companyServ.getCompanyFullObservable().subscribe(
        company => {
          if (company) {
            this._company = company;
          }
        }
      )
    );
  }

  // max errors logs count (no spams for the same error produced many times at the same time)
  private _MAX_ERRORS_LOG: number = 3;
  private _errorCounter: number = 0;

  handleError(error: any): void {
    console.error(error);

    if (!isDevMode()) {
      // alert('Nastala neočekávaná událost - kontaktuje podporu.' + plusMessage);
      console.error('Nastala neočekávaná událost - kontaktuje podporu.');

      // restrict email logging 
      // for total error cases would be hundrends of unnecessary emails
      this._errorCounter += 1;
      if (this._company && this._company.company_key && this._errorCounter < this._MAX_ERRORS_LOG) {
        let error_report: string = '';
        error_report += this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm:ss') + '\n';
        if (this._company) {
          error_report += this._company.company + '(company_key: ' + this._company.company_key + ')\n';
        }
        error_report += window.location.href + '\n\n';
        // error.stack includes the full text/message of error
        error_report += 'Error.stack:\n' + (error.stack ? error.stack : error) + '\n\n\n';
        
        // error.message
        error_report += 'Error.message:\n' + (error.message ? error.message : error) + '\n\n\n';
  
        // error.stringify
        error_report += 'Error.stringify:\n' + (error ? JSON.stringify(error) : error);

        let emailObj: any = {
          from: 'info@truckmanager.eu',
          to: ['ambroz.vita@gmail.com', 'jan.zimmermann80@gmail.com'],
          reply_to: 'info@truckmanager.eu',
          subject: 'Angular report - neočekávaná chyba u uživatele na app',
          body: error_report,
          content_type: 'text/plain'
        };
  
        this._subscribed.push(
          this._emailingServ.sendEmail(emailObj, false).subscribe()
        );
      }
    }

    if (isDevMode()) {
      throw error;
    } 
    else if (typeof ga !== 'undefined' && ga instanceof Function) {
      let plusMessage = '';
      ga('send', 'exception', {
        'exDescription': error.message,
        'exFatal': true
      });
      plusMessage += 'Error was reported';
    }
  }
}