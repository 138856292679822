<div class="messages-list container-fluid">
  <div *ngIf="!messages.length" class="text-center">
    <ng-container i18n>Žádné zprávy k zobrazení</ng-container>
  </div>
  <div *ngFor="let message of messages">
    <message-row 
      [reply]="reply" [message]="message"
      (orderToWienerbergerEvent)="handleOrderToWienerberger($event)"
      (urlWienerbergerEvent)="handleUrlWienerberger($event)"
      (spzToWienerbergerEvent)="handleSpzToWienerberger($event)"
      (driverToWienerbergerEvent)="handleDriverToWienerberger($event)"
      (phoneToWienerbergerEvent)="handlePhoneToWienerberger($event)"
      (loadingDateToWienerbergerEvent)="handleLoadingDateToWienerberger($event)">
    </message-row>
  </div>
</div>