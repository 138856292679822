import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { StorageKeys } from 'src/app/config';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { TrackingEvent } from 'src/app/model/tracking-event.object';
import { StorageService } from 'src/app/service/storage.service';
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';

declare var google: any;

@Component({
  selector: 'div.googleMapObligation',
  templateUrl: './google-map-obligation.component.html',
  styleUrls: ['./google-map-obligation.component.css']
})
export class GoogleMapObligationComponent implements OnInit, OnDestroy {

  // usage in components - ta1-company-obligation, ta3-gen-for-invoicing, r-diary-mobile
  
  private _subscriptions: Array<Subscription> = [];

  private _map: any;
  private _initialized: boolean = false;
  private _bounds: any;
  private _infoWindows: Array<any> = [];
  private _polyLines: Array<any> = [];
  private _directionOrigin: any = null;
  private _directionDest: any = null;
  private _directionWaypoints: Array<any> = [];
  private _directionRendered: any = null;
  private _directionsService = new google.maps.DirectionsService();
  
  // 
  private _previousDirectionWaypoints: Array<any> = [];

  private _DEFAULT_ZOOM: number = 7;

  private _key: number;
  get key(): number {
    return this._key;
  }

  private _trafficLayerShow: boolean = true;
  private _trafficLayer: any = null;
  get trafficLayer(): any {
    return this._trafficLayer;
  }
  
  // inputs
  private _properties = {};
  @Input()
  set properties(properties: any) {
    this._properties = properties;
    if (this._properties && this._properties['zoom']) {
      this._DEFAULT_ZOOM = this._properties['zoom'];
    }
  }

  @Input() rebuildMap: Observable<boolean> = new Observable();
  @Input() clearMap: Observable<boolean> = new Observable();

  private _buildMapOnOpen: boolean = false;
  public get buildMapOnOpen(): boolean {
    return this._buildMapOnOpen;
  }
  @Input()
  public set buildMapOnOpen(value: boolean) {
    this._buildMapOnOpen = value;
    if (this._buildMapOnOpen) {
      this.buildData();
      this._initialized = true;
    }
  }
  
  // fix condition for using in diary-mobile
  private _buildOnMarkersInit: boolean = false;
  @Input()
  set buildOnMarkersInit(value: boolean) {
    this._buildOnMarkersInit = value;
  }

  // markers could be provided from component
  private _markers: Array<GoogleMapMarker> = [];
  @Input()
  set markers(value: Array<GoogleMapMarker>) {
    this.clearMarkers();
    this._markers = value;
    // remove null marker placeholders (company-obligation)
    if (this._markers) {
      this._markers = this._markers.filter(m => m);
    }

    if (this._buildOnMarkersInit) {
      this.rebuildData(false);
    }
  }

  private _drawDirections: boolean = true;
  @Input()
  set drawDirections(value: boolean) {
    this._drawDirections = value;
    console.log(this._drawDirections);
  }

  private _directionsDraggable: boolean = false;
  @Input()
  set directionsDraggable(value: boolean) {
    this._directionsDraggable = value;
  }
  
  private _obligationTrackingEvents: Array<TrackingEvent> = [];
  @Input()
  public set obligationTrackingEvents(value: Array<TrackingEvent>) {
    this._obligationTrackingEvents = value;
  }
  
  private _showTransit: boolean = true;
  @Input()
  public set showTransit(value: boolean) {
    this._showTransit = value;
  }

  // outputs
  private _clickEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  get clicked(): Observable<any> {
    return this._clickEmitter.asObservable();
  }

  private _directionsChange: EventEmitter<any> = new EventEmitter();
  @Output()
  get directionsChange(): Observable<any> {
    return this._directionsChange.asObservable();
  }


  constructor(
    private _elRef: ElementRef,
    private _storageService: StorageService,
    private _externalApiRequestServ: ExternalApiRequestService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
    // get traffic layer cookie
    let traffic: string = this._storageService.getItem(StorageKeys.map.traffic_layer, true);
    this._trafficLayerShow = (traffic == 'false') ? false : true;
  }

  ngOnInit(): void {
    // added event listener - parent could sent order to rebuild map
    this._subscriptions.push(
      this.rebuildMap.subscribe(
        (rebuild: boolean) => {
          if (rebuild) {
            this.rebuildData(false);
          }
        }
      ),
      this.clearMap.subscribe(
        (clear: boolean) => {
          if (clear) {
            this.clearData(true);
          }
        }
      )
    );

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-obligation';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }
  
  ngOnDestroy() {
    this.clearMarkers();
    this.clearData(true);
    
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._subscriptions = [];
  }

  clearData(dropMap: boolean) {
    // info windows
    this._infoWindows.forEach(
      infoWindow => {
        infoWindow.close();
        infoWindow.setMap(null);
      }
    );
    this._infoWindows = [];
    // polylines
    this._polyLines.forEach(
      polyLine => {
        polyLine.setMap(null);
      }
    );
    this._polyLines = [];
    // directions
    this._directionOrigin = null;
    this._directionDest = null;
    this._directionWaypoints = [];
    this.clearDirectionsRenderers();
    this._bounds = null;
    // drop map
    if (dropMap) {
      this._map = null;
    }
  }

  clearMarkers(): void {
    // markers
    this._markers.forEach(
      marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
          marker.infoWindow = null;
        }
        marker.clearEvents();
        marker.map = null;
      }
    );
    this._markers = [];
  }

  
  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private _rebuildTimeout: number = null;
  public get rebuildTimeout(): number {
    return this._rebuildTimeout;
  }

  private _building: boolean = false;
  
  private rebuildData(dropMap: boolean) {
    if (!this._initialized) {
      return;
    }
    if (!this._building && this._rebuildTimeout === null) {
      this._rebuildTimeout = window.setTimeout(
        () => {
          this.clearData(dropMap);
          this.buildData();
          this._rebuildTimeout = null;
        },
        1000
      )
    }
  }
  
  private buildData() {
    this._building = true;

    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));
      
      this._map.addListener('click', (data) => {
        this._clickEmitter.emit(data);
      });
    }

    // obligation real tracking data
    if (this._obligationTrackingEvents && this._obligationTrackingEvents.length) {
      this.createColorPolylinesFromTracking(this._obligationTrackingEvents);
    }

    if (this._markers.length) {
      this._bounds = new google.maps.LatLngBounds();
      this._markers.forEach(
        (marker: GoogleMapMarker, index) => {
          // setup marker and bounds
          marker = this.setMarker(marker);
          // add waypoints for direction
          if (marker.position) {
            this._bounds.extend(marker.position);
            if (index == 0) {
              this._directionOrigin = { location: marker.position };
            }
            else if (index == this._markers.length - 1) {
              this._directionDest = { location: marker.position };
            }
            else {
              // push it waypoints array
              this._directionWaypoints.push(
                { location: marker.position }
              );
            }
          }
        }
      );
    }

    if (this._drawDirections && this._directionOrigin && this._directionDest) {
      let directionOptions: any;
      directionOptions = JSON.parse(JSON.stringify(GoogleMapTools.RendererOptions));
      // directions dragging for creating transit points
      if (this._directionsDraggable) {
        directionOptions.draggable = true;
        directionOptions.preserveViewport = true;
        directionOptions.suppressMarkers = true;
      }

      this._directionRendered = new google.maps.DirectionsRenderer(directionOptions);
      this._directionRendered.setMap(this._map);
      this._directionRendered.addListener('directions_changed', () => {
        let directionChange: any = {
          index: this.getDraggedDirectionIndex(),
          rendered: this._directionRendered,
          // allRendered: this._directionRendered
        };
        // console.log(directionChange);
        this._directionsChange.emit(directionChange);

        // save temporary waypoints (in case of dragging)
        this._previousDirectionWaypoints = this._directionRendered.getDirections().geocoded_waypoints;
      });

      // render request
      let request = {
        origin: this._directionOrigin.location,
        destination: this._directionDest.location,
        waypoints: this._directionWaypoints,
        travelMode: google.maps.DirectionsTravelMode.DRIVING
      };
      this.callRequestOnDirectionsRender(this._directionRendered, request);
    }

    if (this._map) {
      if (this._bounds && this._markers.length > 1) {
        this._map.fitBounds(this._bounds);
      } 
      else {
        if (this._markers.length) {
          this._map.setCenter(this._markers[0].position);
        }
        this._map.setZoom(this._DEFAULT_ZOOM);
      }

      // possibly draw traffic layer
      if (this._trafficLayerShow && !this._trafficLayer) {
        this.showTrafficLayer();
      }
    }

    this._building = false;
  }

  // computing index of waypoint, that has been created using dragging direction
  getDraggedDirectionIndex(): number {
    if (this._directionRendered && this._directionRendered.getDirections() && this._previousDirectionWaypoints) {
      let geo_waypoints = this._directionRendered.getDirections().geocoded_waypoints;
      // same count waypoints -> no directions dragging
      if (geo_waypoints.length == this._markers.length) {
        return null;
      }

      // find first different position
      let indexDifferent: number = null;
      for (let i = 0; i < geo_waypoints.length; i++) {
        if (geo_waypoints[i]) {
          if (geo_waypoints[i].place_id != this._previousDirectionWaypoints[i].place_id) {
            indexDifferent = i;
            break;
          }
        }
      }
      return indexDifferent;
    }

    return null;
  }


  /*************************************************************/
  // Markers methods
  /*************************************************************/
  // private initMarkersFromItinerary(): void {
  //   if (this._itinerary) {
  //     // init markers
  //     this._markers = [];
  //     this._itinerary.forEach(
  //       it => {
  //         if (it.marker) {
  //           this._markers.push(it.marker);
  //         }
  //       }
  //     );
  //   }
  // }

  private setMarker(marker: GoogleMapMarker): GoogleMapMarker {
    marker.map = this._map;
    // set info window
    if (marker.infoWindowContent && !marker.infoWindow) {
      if (marker.autoInfoWindowOpen) {
        let infowindow: any = new google.maps.InfoWindow({});
        this._infoWindows.push(infowindow);
        marker.infoWindow = infowindow;
        infowindow.setContent(marker.infoWindowContent);
        infowindow.open(this._map, marker.getGoogleMarker());
      }
      else {
        marker.addListener('click', () => {
          // toggle info window
          if (marker.infoWindow) {
            // close
            marker.infoWindow.close();
            marker.infoWindow = null;
          }
          else {
            // open
            let infowindow: any = new google.maps.InfoWindow({});
            this._infoWindows.push(infowindow);
            marker.infoWindow = infowindow;
            infowindow.setContent(marker.infoWindowContent);
            infowindow.open(this._map, marker.getGoogleMarker());
          }
        });
      }
    } 
    return marker;
  }


  /*************************************************************/
  // Waypoints/directions methods
  /*************************************************************/
  private _directionsRenderCalls: number = 0;

  private callRequestOnDirectionsRender(directionsRendered, request: any) {
    let isset = false;
    this._directionsRenderCalls++;
    window.setTimeout(
      () => {
        // api request call
        let api_request_log: ExternalApiRequest = new ExternalApiRequest();
        api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
        api_request_log.type = 'directions';
        api_request_log.descr = 'google-map-obligation';
        api_request_log.price = 0.005;
        this._externalApiRequestServ.createRequestLog(api_request_log);

        // google request
        this._directionsService.route(
          request,
          (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (isset === false) {
                directionsRendered.setDirections(response);
                isset = true;
              }
            }
            this._directionsRenderCalls--;
          }
        );
      },
      1001 * this._directionsRenderCalls
    );
  }
  
  private clearDirectionsRenderers() {
    if (this._directionRendered) {
      this._directionRendered.setMap(null);
      this._directionRendered = null;
    }
  }

  
  /*************************************************************/
  // Traffic methods
  /*************************************************************/
  showTrafficLayer() {
    this._trafficLayer = new google.maps.TrafficLayer();
    this._trafficLayer.setMap(this._map);
  }

  toggleTrafficLayer() {
    if (!this._trafficLayer) {
      this._trafficLayer = new google.maps.TrafficLayer();
    }

    if (this._trafficLayer.getMap() == null){
      // traffic layer is disabled.. enable it
      this._trafficLayer.setMap(this._map);
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'true', true);
    }
    else {
      // traffic layer is enabled.. disable it
      this._trafficLayer.setMap(null);        
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'false', true);     
    }
  }

  
  /*************************************************************/
  // Obligation events
  /*************************************************************/
  // method that creates polylines with given color
  createColorPolylinesFromTracking(trackingDataArr: Array<TrackingEvent>): void {
    // creating polylines to our map
    trackingDataArr.forEach(
      (trackingData, index) => {
        if (trackingDataArr[index + 1]) {
          // user could disable black transit route
          if (trackingData.cargo_status != 'E' || this._showTransit) {  
            // dark -> transit / red -> route
            let color: string = trackingData.cargo_status == 'E' ? '#444444' : '#ff0000';

            this._polyLines.push(
              new google.maps.Polyline({
                path: [
                  trackingData.geoPos.googleLatLang.googleLtLn,
                  trackingDataArr[index + 1].geoPos.googleLatLang.googleLtLn,
                ],
                geodesic: true,
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 2,
                zIndex: 1000,
                map: this._map
              })
            );
          }
        }
      }
    );
  }
}
