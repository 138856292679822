import { Injectable } from "@angular/core";
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';

declare var google: any;

@Injectable()
export class GoogleMapsApiService {
  private geoCoder: any = null; // new google.maps.Geocoder();
  private static directionsRenderCalls: number = 0;

  constructor(
  ) {
    this.geoCoder = new google.maps.Geocoder();
  }

  static getLocationClosesMatch(result: Array<any>) {
    let closestMatch: string = null;
    result.forEach(
      resultObject => {
        if (resultObject.types.indexOf('locality') > -1) {
          closestMatch = resultObject.formatted_address;
        }
      }
    );
    if (!closestMatch && result.length > 0) {
      closestMatch = result[0].formatted_address;
    }
    return closestMatch;
  }

  geocode(address: string, processCallback: Function) {
    this.geoCoder.geocode({address: address}, processCallback);
  }

  static callRequestOnDirectionsRender(directionsRendered, request: any) {
    let isset = false;
    this.directionsRenderCalls++;
    window.setTimeout(
      () => {
        (new google.maps.DirectionsService()).route(
          request,
          (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (isset === false) {
                directionsRendered.setDirections(response);
                isset = true;
              }
            }
            this.directionsRenderCalls--;
          });
      },
      501 * this.directionsRenderCalls // 1001 * this.directionsRenderCalls
    );
  }
}