import { GoogleLatLng } from "./google-lat-lng.object";

export class HomeStandKey {
  // private _; //from string "(49.5198916666667,15.9074611111111)"
  // get() {
  //   return this._;
  // }
  // set(value) {
  //   this._ = value;
  // }

  constructor() {
  }

  private _home_stand_key: string;
  get home_stand_key(): string {
    return this._home_stand_key;
  }
  set home_stand_key(value: string) {
    this._home_stand_key = value;
  }

  private _city_key: string;
  get city_key(): string {
    return this._city_key;
  }
  set city_key(value: string) {
    this._city_key = value;
  }

  private _alias: string;
  public get alias(): string {
    return this._alias;
  }
  public set alias(value: string) {
    this._alias = value;
  }

  private _country: string;
  get country(): string {
    return this._country;
  }
  set country(value: string) {
    this._country = value;
  }

  private _geo_pos: string;
  get geo_pos(): string {
    return this._geo_pos;
  }
  set geo_pos(value: string) {
    this._geo_pos = value;
    if (this._geo_pos) {
      this._geoPosition = GoogleLatLng.createFromPosString(this._geo_pos);
    }
  }

  private _geoPosition: GoogleLatLng;
  public get geoPosition(): GoogleLatLng {
    return this._geoPosition;
  }
  public set geoPosition(value: GoogleLatLng) {
    this._geoPosition = value;
  }

  private _name: string;
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  private _position: string;
  get position(): string {
    return this._position;
  }
  set position(value: string) {
    this._position = value;
  }

  private _size: string;
  get size(): string {
    return this._size;
  }
  set size(value: string) {
    this._size = value;
  }

  private _utm_pos: string;
  get utm_pos(): string {
    return this._utm_pos;
  }
  set utm_pos(value: string) {
    this._utm_pos = value;
  }

  private _utm_zone: string;
  get utm_zone(): string {
    return this._utm_zone;
  }
  set utm_zone(value: string) {
    this._utm_zone = value;
  }

  private _zip: string;
  get zip(): string {
    return this._zip;
  }
  set zip(value: string) {
    this._zip = value;
  }

  get apiObject(): any {
    return {
      home_stand_key: this.home_stand_key,
      alias: this.alias
    };
  }
}