export class ExchangeRates {
  private _rec_key: number;
  public get rec_key(): number {
    return this._rec_key;
  }
  public set rec_key(value: number) {
    this._rec_key = value;
  }

  private _country: string;
  public get country(): string {
    return this._country;
  }
  public set country(value: string) {
    this._country = value;
  }
  
  private _currency: string;
  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
  }

  private _rate: number;
  public get rate(): number {
    return this._rate;
  }
  public set rate(value: number) {
    this._rate = value;
  }

  private _valid_from: string;
  public get valid_from(): string {
    return this._valid_from;
  }
  public set valid_from(value: string) {
    this._valid_from = value;
  }
}