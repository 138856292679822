import { Agenda } from "./agenda.object";
import { Vehicle } from "./vehicle.object";
import { Itinerary } from "./itinerary.object";
import { BrowserTools } from "../tools/BrowserTools";
import { ItineraryType } from "../config";

export class DispatcherBoardAgendaObject {

  constructor(
    private _agenda: Agenda,
    private _vehicle: Vehicle
  ) {
    if (this._agenda && this._agenda.itinerary) {
      this._agenda.itinerary.forEach(
        it => {
          if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
            if (this._agenda.piece_shipment || this._agenda.transshipment) {
              // warehouse collection - add only loading
              if (this._agenda.car_key == this._vehicle.car_key && it.type == ItineraryType.LOADING) {
                this._agenda_itinerary.push(it);
                this._shipmentFirstPart = true;
              }
              // warehouse delivery - add only unloading
              if (this._agenda.car2_key == this._vehicle.car_key && it.type == ItineraryType.UNLOADING) {
                this._agenda_itinerary.push(it);
                this._shipmentSecondPart = true;
              }
            }
            else {
              // no piece shipment -> just push itinerary
              this._agenda_itinerary.push(it);
            }
          }
          
          // add warehouse, transshipment and moving itinerary as well
          if (it.type == ItineraryType.WAREHOUSE || 
            it.type == ItineraryType.WAREHOUSE_AUTO ||
            it.type == ItineraryType.TRANSSHIPMENT || 
            it.type == ItineraryType.MOVING
          ) {
            this._agenda_itinerary.push(it);
          }
        }
      );
    }
  }

  // defines all displayed itinerary (e.g. warehouse logic collection / delivery)
  private _agenda_itinerary: Array<Itinerary> = [];
  public get agenda_itinerary(): Array<Itinerary> {
    return this._agenda_itinerary;
  }
  public set agenda_itinerary(value: Array<Itinerary>) {
    this._agenda_itinerary = value;
  }

  get agenda(): Agenda {
    return this._agenda;
  }

  // meaning start of agenda
  get day(): Date {
    return this._agenda.day;
  }

  get end(): Date {
    return this._agenda.end;
  }

  get end_with_loading_limit(): number {
    return this._agenda.last_itinerary_time_with_limit;
  }

  get agenda_finished(): boolean {
    if (this._agenda.itinerary && this._agenda.itinerary.length) {
      // check if all itinerary has been finished
      let unfinished = this._agenda.itinerary.find(
        i => !i.isDone && (i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING)
      );
      if (!unfinished) {
        return true;
      }
    }
    return false;
  }

  get agenda_started(): boolean {
    if (this._agenda.itinerary && this._agenda.itinerary.length) {
      // check if all itinerary has been finished
      let started = this._agenda.itinerary.find(i => i.hasStarted);
      if (started) {
        return true;
      }
    }
    return false;
  }

  get status(): string {
    if (!this._agenda) return '';
    return this._agenda.status;
  }

  get order_comp_book(): string {
    if (!this._agenda || !this._agenda.order_comp_book) return '';
    return this._agenda.order_comp_book.company;
  }

  get order_number(): string {
    return this._agenda.order_number;
  }

  get express_delivery(): boolean {
    return this._agenda.express_delivery;
  }

  get backgroundColorClass(): string {
    return this._agenda.backgroundColorClass;
  }

  get first_itinerary(): Itinerary {
    return this._agenda.first_itinerary;
  }

  get first_loading(): Itinerary {
    return this._agenda.first_loading;
  }

  get last_itinerary(): Itinerary {
    return this._agenda.last_itinerary;
  }

  get start_location_shortcode(): string {
    let shortcode = '';
    if (this._agenda.itinerary && this._agenda.itinerary.length) {
      let first: Itinerary = this._agenda.itinerary.find(
        i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
      );
      if (first) {
        return first.address_excerpt;
      }
    }
    return shortcode;
  }

  get finish_location_shortcode(): string {
    let shortcode = '';
    if (this._agenda.itinerary && this._agenda.itinerary.length) {
      return this._agenda.itinerary[this._agenda.itinerary.length - 1].address_excerpt;
    }
    return shortcode;
  }

  get length_and_driven(): string {
    let info = '';
    // let length = parseFloat(this._agenda.route_length);
    let length = this._agenda.route_length;
    let driven = (this._agenda.tracked_distance ? this._agenda.tracked_distance : 0);
    if (driven > length) {
      info = '+';
    }
    info += Math.round((driven - length)) + '/' + length;
    return info;
  }

  getDrivenDistancePercentage(): number {
    // let length = parseFloat(this._agenda.route_length);
    let length = this._agenda.route_length;
    let driven = (this._agenda.tracked_distance ? this._agenda.tracked_distance : 0);
    return (driven / length) * 100;
  }

  getDrivenDistanceCssClass(): string {
    if (!this._agenda.tracked_distance) {
      return '';
    }
    let length = this._agenda.route_length; // parseInt(this._agenda.route_length)
    let col = this.getColumn(this._agenda.tracked_distance ? (this._agenda.tracked_distance / length) : 0);
    if (col < 11) {
      return '';
    }
    return (BrowserTools.isMobileBrowser() ? '' : 'progress-bar-striped progress-bar-animated ') + 'col-' + col;
  }

  private getColumn(percent, pull?: boolean): number {
    let col = (pull ? 0 : 1);
    let colPercent = (number: number) => {
      return (number / 12) * 100
    };
    switch (true) {
      case colPercent(1) <= percent && colPercent(2) > percent:
        col = 1;
        break;
      case colPercent(2) <= percent && colPercent(3) > percent:
        col = 2;
        break;
      case colPercent(3) <= percent && colPercent(4) > percent:
        col = 3;
        break;
      case colPercent(4) <= percent && colPercent(5) > percent:
        col = 4;
        break;
      case colPercent(5) <= percent && colPercent(6) > percent:
        col = 5;
        break;
      case colPercent(6) <= percent && colPercent(7) > percent:
        col = 6;
        break;
      case colPercent(7) <= percent && colPercent(8) > percent:
        col = 7;
        break;
      case colPercent(8) <= percent && colPercent(9) > percent:
        col = 8;
        break;
      case colPercent(9) <= percent && colPercent(10) > percent:
        col = 9;
        break;
      case colPercent(10) <= percent && colPercent(11) > percent:
        col = 10;
        break;
      case colPercent(11) <= percent && colPercent(12) > percent:
        col = 11;
        break;
      case colPercent(12) <= percent:
        col = 12;
        break
    }
    return col;
  }

  /* flag if agenda segment could be moved by mouse/touch events - via cdkDrop */
  private _enableMove: boolean = false;
  public get enableMove(): boolean {
    return this._enableMove;
  }
  public set enableMove(value: boolean) {
    this._enableMove = value;
  }

  /* Styling properties */
  private _infoOffset: number = 0;
  public get infoOffset(): number {
    return this._infoOffset;
  }
  public set infoOffset(value: number) {
    this._infoOffset = value;
  }
  
  private _infoWidth: number = 0;
  public get infoWidth(): number {
    return this._infoWidth;
  }
  public set infoWidth(value: number) {
    this._infoWidth = value;
  }
  
  // flag used in conflicting setting up
  private _isFirstConflictRow: boolean = false;
  public get isFirstConflictRow(): boolean {
    return this._isFirstConflictRow;
  }
  public set isFirstConflictRow(value: boolean) {
    this._isFirstConflictRow = value;
  }

  // row in which is agenda shown (default is 0 row, when no conflicts)
  private _conflictRow: number = 0;
  public get conflictRow(): number {
    return this._conflictRow;
  }
  public set conflictRow(value: number) {
    this._conflictRow = value;
  }

  private _conflictOffsetTop: number = 55;
  public get conflictOffsetTop(): number {
    if (this._conflictRow != 0) {
      return 55 + this._conflictRow * 50;
    }
    return this._conflictOffsetTop;
  }

  private _conflictRowSetUp: boolean = false;
  public get conflictRowSetUp(): boolean {
    return this._conflictRowSetUp;
  }
  public set conflictRowSetUp(value: boolean) {
    this._conflictRowSetUp = value;
  }

  // warehouse / transshipment
  private _shipmentFirstPart: boolean = false;
  public get shipmentFirstPart(): boolean {
    return this._shipmentFirstPart;
  }
  public set shipmentFirstPart(value: boolean) {
    this._shipmentFirstPart = value;
  }
  
  private _shipmentSecondPart: boolean = false;
  public get shipmentSecondPart(): boolean {
    return this._shipmentSecondPart;
  }
  public set shipmentSecondPart(value: boolean) {
    this._shipmentSecondPart = value;
  }
}
