import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { DatePipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { RecaptchaModule } from 'angular-google-recaptcha';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DragDropModule } from '@angular/cdk/drag-drop';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { MatNativeDateModule } from '@angular/material/core';
// import { ResizableModule } from 'angular-resizable-element';
// import { QRCodeModule } from 'angularx-qrcode';
// import { ChartsModule } from "ng2-charts";
// imported as provider
// import { GoogleTagManagerModule } from 'angular-google-tag-manager';
// import { Ng5SliderModule } from 'ng5-slider';
// import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { appRoutes } from "./app-routing.module";
import { SharedModule } from './shared/shared.module';

import { Angular2Wamp } from "./angular2-wamp/angular2-wamp";

import { HttpClientService } from "./service/http-client.service";
import { MessageService } from "./service/message.service";
import { StorageService } from "./service/storage.service";
import { AuthenticationService } from "./service/authentication.service";
import { WebConnectivityService } from "./service/web.connectivity.service";
import { TruckManagerLayoutService } from "./service/truck-manager-layout.service";
import { UserConfigurationService } from "./service/user-configuration.service";
import { NotificationService } from "./service/notification-service";
import { CompanyService } from "./service/company.service";
import { GoogleMapsApiService } from "./service/google-maps-api.service";
import { FilesService } from "./service/files.service";
import { DestinationRoutesService } from "./service/destination-routes.service";
import { VehicleLeftService } from "./service/vehicle-left.service";
import { TrailerService } from "./service/trailer.service";
import { MakeService } from "./service/make.service";
import { ModelService } from "./service/model.service";
import { DriverCardService } from "./service/driver-card.service";
import { HwInfoService } from "./service/hw-info.service";
import { ReachEventService } from "./service/reach-event.service";
import { TrackingEventService } from "./service/tracking-event.service";
import { ServiceEventService } from "./service/service-event.service";
import { FuelcardEventService } from "./service/fuelcard-event.service";
import { CitiesService } from "./service/cities.service";
import { ObligationService } from "./service/obligation.service";
import { SettingsService } from "./service/settings.service";
import { AddressBookService } from "./service/address-book.service";
import { ExchangeRatesService } from "./service/exchange-rates.service";
import { InvoiceService } from "./service/invoice.service";
import { ServiceTaskService } from "./service/service-task.service";
import { ImsiService } from "./service/imsi.service";
import { TemplateJsonService } from "./service/template-json.service";
import { TruckManagerErrorHandler } from "./service/truckmanager-error.handler";

import { RAgendaDetailComponent } from './components/r-agenda-detail/r-agenda-detail.component';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { DemoStartupComponent } from './components/demo-startup/demo-startup.component';
import { RDispatcherBoardComponent } from './components/r-dispatcher-board/r-dispatcher-board.component';
import { RGanntDriveDiagramComponent } from './components/r-gannt-drive-diagram/r-gannt-drive-diagram.component';
import { LeftTmComponent } from './components/left-tm/left-tm.component';
import { RItineraryDiaryComponent } from './components/r-itinerary-diary/r-itinerary-diary.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { MenuLeftComponent } from './components/menu-left/menu-left.component';
import { RUserConfigurationListComponent } from './components/r-user-configuration-list/r-user-configuration-list.component';
import { RVehicleAllInOneMapComponent } from './components/r-vehicle-all-in-one-map/r-vehicle-all-in-one-map.component';
import { RVehicleDiagnosticsComponent } from './components/r-vehicle-diagnostics/r-vehicle-diagnostics.component';
import { RVehicleDispositionsConfigurationComponent } from './components/r-vehicle-dispositions-configuration/r-vehicle-dispositions-configuration.component';
import { RVehicleInfoComponent } from './components/r-vehicle-info/r-vehicle-info.component';
import { RVehicleInterruptionComponent } from './components/r-vehicle-interruption/r-vehicle-interruption.component';
import { RVehicleListComponent } from './components/r-vehicle-list/r-vehicle-list.component';
import { RVehicleManualTrackingConfigurationComponent } from './components/r-vehicle-manual-tracking-configuration/r-vehicle-manual-tracking-configuration.component';
import { RVehicleStatusGraphComponent } from './components/r-vehicle-status-graph/r-vehicle-status-graph.component';
import { RVehicleManualTrackingItineraryComponent } from './components/r-vehicle-manual-tracking-itinerary/r-vehicle-manual-tracking-itinerary.component';
import { RLastCargoDiaryComponent } from './components/r-last-cargo-diary/r-last-cargo-diary.component';
import { RDiaryMobileComponent } from './components/r-diary-mobile/r-diary-mobile.component';
import { RDiaryMobileMapComponent } from './components/r-diary-mobile-map/r-diary-mobile-map.component';
import { WarehouseService } from './service/warehouse.service';
import { RVehicleGridDialogComponent } from './components/r-vehicle-grid-dialog/r-vehicle-grid-dialog.component';
import { RVehicleGridComponent } from './components/r-vehicle-grid/r-vehicle-grid.component';
import { RItObligationDiaryComponent } from './components/r-it-obligation-diary/r-it-obligation-diary.component';
import { RVehicleListLightComponent } from './components/r-vehicle-list-light/r-vehicle-list-light.component';
import { RVehicleListFullComponent } from './components/r-vehicle-list-full/r-vehicle-list-full.component';
import { DrivingExclusionsService } from './service/driving-exclusions.service';
import { VehicleNewService } from './service/vehicle-new.service';
import { VehicleFilterComponent } from './components/vehicle-filter/vehicle-filter.component';
import { RLocaleSettingsComponent } from './components/r-locale-settings/r-locale-settings.component';
import { TimocomComponent } from './components/timocom/timocom.component';
import { GoogleMapTimocomComponent } from './components/google-map-timocom/google-map-timocom.component';
// import { FileRenamingComponent } from './components/file-renaming/file-renaming.component';

@NgModule({
  declarations: [
    AppComponent,
    RAgendaDetailComponent,
    AutoLoginComponent,
    DemoStartupComponent,
    RDispatcherBoardComponent,
    RGanntDriveDiagramComponent,
    // LeftContactComponent,
    // LeftProfileComponent,
    // LeftSubscriptionComponent,
    LeftTmComponent,
    RItineraryDiaryComponent,
    LoginDialogComponent,
    MenuLeftComponent,
    RUserConfigurationListComponent,
    RVehicleAllInOneMapComponent,
    RVehicleDiagnosticsComponent,
    RVehicleDispositionsConfigurationComponent,
    RVehicleInfoComponent,
    RVehicleInterruptionComponent,
    RVehicleListComponent,
    RVehicleManualTrackingConfigurationComponent,
    RVehicleStatusGraphComponent,
    RVehicleManualTrackingItineraryComponent,
    RLastCargoDiaryComponent,
    RDiaryMobileComponent,
    RDiaryMobileMapComponent,
    RVehicleGridDialogComponent,
    RVehicleGridComponent,
    RItObligationDiaryComponent,
    RVehicleListLightComponent,
    RVehicleListFullComponent,
    VehicleFilterComponent,
    RLocaleSettingsComponent,
    TimocomComponent,
    GoogleMapTimocomComponent,
    // FileRenamingComponent
    // DateFormatPipe,
    // SafeResourcePipe,
    // SafeHtmlPipe,
    // SafeStylePipe,
    // ConfigurationPipe,
    // PricePipe,
    // KmPipe,
    // CarTypePipe,
    // LeftAddressBookItemComponent,
    // RAetrRowComponent,
    // RBroadcastMessageDialogComponent,
    // RMessageAttachmentComponent,
    // RMessageRowComponent,
    // RMessageWriteComponent,
    // RMessageDayGroupedListComponent,
    // RMessagesHistoryComponent,
    // RMessagesListComponent,
    // RMessagesNewDialogComponent,
    // RMessagesNewComponent,
    // RMessagesReadComponent,
    // RMultiMediaAttachmentComponent,
    // Ta1CompanyObligationComponent,
    // Ta1CompanyOrderComponent,
    // Ta1ObligationListComponent,
    // Ta1ObligationSalesComponent,
    // Ta1OrderListComponent,
    // Ta2CompanyImportComponent,
    // Ta2CompanyNewComponent,
    // Ta2SearchComponent,
    // Ta3InvoiceConditionComponent,
    // Ta3InvoiceExportComponent,
    // Ta3InvoiceInConditionComponent,
    // Ta3InvoiceListComponent,
    // Ta4CodeOrderParamComponent,
    // Ta4CodeRemindersParamComponent,
    // Ta4CodeOftenUsedPosComponent,
    // Ta4CodeOverheadsComponent,
    // Ta4CodeSeriesObligationComponent,
    // Ta4DeliveryLinesComponent,
    // Ta4UserEditComponent,
    // TmCarbookComponent,
    // TmMycarsCarsComponent,
    // TmMycarsDriversComponent,
    // TmMycarsGpsComponent,
    // TmMycarsTrailersComponent,
    // TmMycarsComponent,
    // TmStatisticsComponent,
    // RVehicleActivityComponent,
    // RVehicleManualTrackingResultComponent,
    // RVehicleMessagesListComponent,
    // TmScheduleComponent,
    // TmScheduleInvoiceExportComponent,
    // Ta3InvoiceEmailingComponent,
    // ContentEditableDirective,
    // Ta3PaymentsComponent,
    // Ta3RemindersComponent,
    // Ta3GenForInvoicingComponent,
    // Ta1OrderEmailingComponent,
    // Ta1ObligationStatsComponent,
    // Ta3InvoiceStatsComponent,
    // Ta3PaletteComponent,
    // Ta1ObligationEmailingComponent,
    // Ta1ObligationEmailLogsComponent,
    // Ta3InvoiceEmailLogsComponent,
    // RDispatcherBoardRowComponent,
    // Ta3InvoiceBookComponent,
    // Ta3SaldoComponent,
    // Ta1WarehousePlanComponent,
    // Ta1WarehouseControlComponent,
    // Ta1WarehouseVehicle,
    // TmServicesDetailComponent,
    // Ta1EmissionCalcComponent,
    // TmServicesModalsComponent,
    // TmMycarsSimComponent,
    // GoogleMapCarComponent,
    // GoogleMapCarsAllComponent,
    // GoogleMapObligationComponent,
    // GoogleMapPolygonsComponent,
    // GoogleMapFavouritesComponent,
    // GoogleMapManualTrackingComponent,
    // GoogleMapExpressComponent,
    // Ta1ExpressListComponent,
    // Ta1ExpressPlanComponent,
    // Ta1ExpressEvaluationComponent,
    // Ta1ObligationOtherActionsComponent,
    // PdfAnalysisComponent,
    // Ta1ObligationOfferComponent,
    // PdfAnalysisAttributeRowComponent,
    // Ta3ImportFuelingComponent,
    // TmServicesActualComponent,
    // Ta1ObligationAgreementComponent,
    // Ta3InvoiceExportNew,
    // TmStatisticsChartComponent,
    // NoPermissionComponent,
    // Ta1FuelingStatsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' }),
    SharedModule
    // RecaptchaModule.forRoot({siteKey: '6Lcvt8UUAAAAAHFAYec37VLmmHeSUi6TWtSBbLC5'}),
    // FormsModule,
    // ReactiveFormsModule,
    // DragDropModule,
    // MatDatepickerModule,
    // MatNativeDateModule,
    // ChartsModule,
    // QRCodeModule,
    // NgxSliderModule,
    // ResizableModule,
  ],
  providers: [
    DatePipe,
    Angular2Wamp,
    TruckManagerLayoutService,
    HttpClientService,
    MessageService,
    StorageService,
    AuthenticationService,
    WebConnectivityService,
    UserConfigurationService,
    NotificationService,
    CompanyService,
    GoogleMapsApiService,
    FilesService,
    DestinationRoutesService,
    VehicleLeftService,
    TrailerService,
    MakeService,
    ModelService,
    DriverCardService,
    HwInfoService,
    ReachEventService,
    TrackingEventService,
    ServiceEventService,
    FuelcardEventService,
    CitiesService,
    ObligationService,
    SettingsService,
    AddressBookService,
    ExchangeRatesService,
    InvoiceService,
    WarehouseService,
    ServiceTaskService,
    ImsiService,
    TemplateJsonService,
    DrivingExclusionsService,
    VehicleNewService,
    {
      provide: ErrorHandler,
      useClass: TruckManagerErrorHandler
    },
    {
      provide: LocationStrategy, 
      useClass: HashLocationStrategy
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'UA-116483982-1'
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
