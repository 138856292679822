import { Pipe, PipeTransform } from '@angular/core';
import { Numbers } from "../tools/Numbers";

@Pipe({
  name: 'km'
})
export class KmPipe implements PipeTransform {

  transform(value: any): string {
    if (!value) return '0';
    
    // 181 000
    return Numbers.toOurKilometersFormat(value);
  }

}
