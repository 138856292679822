import { Itinerary } from "./itinerary.object";
import { GeoPosition } from "./geo-position.object";
import { Vehicle } from "./vehicle.object";
import { Attachment } from "./attachment.object";
import { Email } from "./email.object";
import { ObligationTools } from "../tools/ObligationTools";
import { ItineraryType, ObligationStatus } from "../config";
import { DateTools } from "../tools/DateTools";
import { WarehouseEvent } from "./warehouse-event.object";
import { ExpressRoute } from "./express-route.object";

export class Agenda {

  private _obligation_key: number;
  get obligation_key(): number {
    return this._obligation_key;
  }
  set obligation_key(value: number) {
    this._obligation_key = value;
  }

  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }
  
  private _car2_key: number;
  public get car2_key(): number {
    return this._car2_key;
  }
  public set car2_key(value: number) {
    this._car2_key = value;
  }
  
  private _created_time: string;
  get created_time(): string {
    return this._created_time;
  }
  set created_time(value: string) {
    this._created_time = value;
    if (value) {
      this.created_time_date = DateTools.apiIsoToDate(value);
    }
  }

  private _created_time_date: Date;
  get created_time_date(): Date {
    return this._created_time_date;
  }
  set created_time_date(value: Date) {
    this._created_time_date = value;
  }

  private _edoc_url: string;
  public get edoc_url(): string {
    return this._edoc_url;
  }
  public set edoc_url(value: string) {
    this._edoc_url = value;
  }

  private _express_delivery: boolean;
  public get express_delivery(): boolean {
    return this._express_delivery;
  }
  public set express_delivery(value: boolean) {
    this._express_delivery = value;
  }

  private _express_route_key: number;
  public get express_route_key(): number {
    return this._express_route_key;
  }
  public set express_route_key(value: number) {
    this._express_route_key = value;
  }

  // custom
  private _express_route: ExpressRoute = null;
  public get express_route(): ExpressRoute {
    return this._express_route;
  }
  public set express_route(value: ExpressRoute) {
    this._express_route = value;
  }

  private _itinerary: Array<Itinerary> = [];
  get itinerary(): Array<Itinerary> {
    return this._itinerary;
  }
  set itinerary(value: Array<Itinerary>) {
    if (value) {
      value.forEach(
        it => {
          it.agenda = this;
        }
      )
    }
    this._itinerary = value;
  }

  private _number: number;
  get number(): number {
    return this._number;
  }
  set number(value: number) {
    this._number = value;
  }

  private _order_number: string;
  get order_number(): string {
    return this._order_number;
  }
  set order_number(value: string) {
    this._order_number = value;
  }

  private _order_number_standard: string;
  get order_number_standard(): string {
    return this._order_number_standard;
  }
  set order_number_standard(value: string) {
    this._order_number_standard = value;
  }
  private _payer_city: string;
  get payer_city(): string {
    return this._payer_city;
  }
  set payer_city(value: string) {
    this._payer_city = value;
  }

  private _payer_company: string;
  get payer_company(): string {
    return this._payer_company;
  }
  set payer_company(value: string) {
    this._payer_company = value;
  }

  private _payer_contact: string;
  get payer_contact(): string {
    return this._payer_contact;
  }
  set payer_contact(value: string) {
    this._payer_contact = value;
  }

  private _payer_email: string;
  get payer_email(): string {
    return this._payer_email;
  }
  set payer_email(value: string) {
    this._payer_email = value;
  }

  private _payer_phone: string;
  get payer_phone(): string {
    return this._payer_phone;
  }
  set payer_phone(value: string) {
    this._payer_phone = value;
  }

  private _route_length: number;
  get route_length(): number {
    return this._route_length;
  }
  set route_length(value: number) {
    this._route_length = value;
  }

  private _series: number;
  get series(): number {
    return this._series;
  }
  set series(value: number) {
    this._series = value;
  }

  private _status: string;
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
    this._backgroundColorClass = ObligationTools.setBackground(this._status);
    this._backgroundColorRowClass = ObligationTools.setBackgroundTableRow(this._status);

    switch (this._status) {
      case ObligationStatus.K_VYRIZENI:
        this._statusToString = $localize`K vyřízení`; break;
      case ObligationStatus.POPTAVKA:
        this._statusToString = $localize`Poptávka`; break;
      case ObligationStatus.ZAHAJENA:
        this._statusToString = $localize`Zahájená`; break;
      case ObligationStatus.SKLAD_SVOZ:
        this._statusToString = $localize`Sklad - Svoz`; break;
      case ObligationStatus.SKLAD:
        this._statusToString = $localize`Sklad`; break;
      case ObligationStatus.SKLAD_K_ROZVOZU:
        this._statusToString = $localize`Sklad - K rozvozu`; break;
      case ObligationStatus.SKLAD_ROZVOZ:
        this._statusToString = $localize`Sklad - rozvoz`; break;
      case ObligationStatus.K_FAKTURACI:
        this._statusToString = $localize`K fakturaci`; break;
      case ObligationStatus.FAKTUROVANA:
        this._statusToString = $localize`Fakturovaná`; break;
      case ObligationStatus.DOKONCENA:
        this._statusToString = $localize`Dokončená`; break;
      case ObligationStatus.MIMO_PROVOZ:
        this._statusToString = $localize`Mimo provoz`; break;
      case ObligationStatus.STORNOVANA:
        this._statusToString = $localize`Stornovaná`; break;
      default:
        this._statusToString = ''; break;
    }
  }

  private _statusToString: string;
  public get statusToString(): string {
    return this._statusToString;
  }
  public set statusToString(value: string) {
    this._statusToString = value;
  }

  // custom property for color in list of obligations according to status
  private _backgroundColorClass: string = '';
  get backgroundColorClass(): string {
    return this._backgroundColorClass;
  }
  set backgroundColorClass(value: string) {
    this._backgroundColorClass = value;
  }

  private _backgroundColorRowClass: string = '';
  get backgroundColorRowClass(): string {
    return this._backgroundColorRowClass;
  }
  set backgroundColorRowClass(value: string) {
    this._backgroundColorRowClass = value;
  }

  private _subnumber: number;
  get subnumber(): number {
    return this._subnumber;
  }
  set subnumber(value: number) {
    this._subnumber = value;
  }

  private _year: number;
  get year(): number {
    return this._year;
  }
  set year(value: number) {
    this._year = value;
  }

  private _tracked_positions: Array<GeoPosition> = [];
  get tracked_positions(): Array<GeoPosition> {
    return this._tracked_positions;
  }
  set tracked_positions(value: Array<GeoPosition>) {
    this._tracked_positions = value;
  }

  private _tracked_distance: number;
  get tracked_distance(): number {
    return this._tracked_distance;
  }
  set tracked_distance(value: number) {
    if (!isNaN(value)) {
      this._tracked_distance = value;
    }
  }

  private _tracked_span: number;
  get tracked_span(): number {
    return this._tracked_span;
  }
  set tracked_span(value: number) {
    this._tracked_span = value;
  }

  private _updated_by: string;
  get updated_by(): string {
    return this._updated_by;
  }
  set updated_by(value: string) {
    this._updated_by = value;
  }
  
  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  set vehicle(value: Vehicle) {
    this._vehicle = value;
  }

  private _order_comp_book: any;
  get order_comp_book(): any {
    return this._order_comp_book;
  }
  set order_comp_book(value: any) {
    this._order_comp_book = value;
  }

  private _order_comp_book_key: number;
  get order_comp_book_key(): number {
    return this._order_comp_book_key;
  }
  set order_comp_book_key(value: number) {
    this._order_comp_book_key = value;
  }

  get clean_payer_phone(): string {
    if (!this._payer_phone) {
      return '';
    }
    return this._payer_phone.replace(/\s|,|-/g, '');
  }

  addTrackedPosition(geoPosition: GeoPosition) {
    this._tracked_positions.push(geoPosition);
  }

  get day(): Date {
    let day: Date = null;
    if (this.itinerary && this.itinerary.length) {
      // if (this.status == ObligationStatus.SKLAD_K_ROZVOZU || this.status == ObligationStatus.SKLAD_ROZVOZ) {
      if (this._piece_shipment && this._car2_key) {
        // warehouse delivery part -> start with warehouse
        if (this._vehicle && this._vehicle.car_key == this._car2_key) {
          // first is warehouse
          let first: Itinerary = this.itinerary.find(
            i => i.type == ItineraryType.WAREHOUSE || i.type == ItineraryType.WAREHOUSE_AUTO
          );
          if (first) {
            return first.arrival_time;
          }
        }
      }
      // }

      // default
      if (this.first_itinerary) {
        day = this.first_itinerary.arrival_time;
      }
    }
    return day;
  }

  get end(): Date {
    let end: Date = null;
    if (this.itinerary && this.itinerary.length) {
      // added also warehouse (collection) logic
      if (this._piece_shipment && this._car_key) {
        // warehouse collection part -> start with warehouse
        if (this._vehicle && this._vehicle.car_key == this._car_key) {
          // last is warehouse
          let last: Itinerary = this.itinerary.find(
            i => i.type == ItineraryType.WAREHOUSE || i.type == ItineraryType.WAREHOUSE_AUTO
          );
          if (last) {
            return last.arrival_time;
          }
        }
      }

      // default
      if (this.last_itinerary) {
        end = this.last_itinerary.arrival_time;
      }
    }
    return end;
  }

  get first_itinerary(): Itinerary {
    let it: Itinerary = null;
    if (this._itinerary && this._itinerary.length) {
      // added also warehouse (delivering) logic
      // if (this.status == ObligationStatus.SKLAD_K_ROZVOZU || this.status == ObligationStatus.SKLAD_ROZVOZ) {
        
      if (this._piece_shipment && this._car2_key) {
        // warehouse delivery part -> start with warehouse
        if (this._vehicle && this._vehicle.car_key == this._car2_key) {
          // first is warehouse
          it = this._itinerary.find(
            i => i.type == ItineraryType.WAREHOUSE || i.type == ItineraryType.WAREHOUSE_AUTO
          );
          if (it) {
            return it;
          }
        }
      }

      // default is first itinerary
      it = this._itinerary[0];
    }
    return it;
  }

  get first_loading(): Itinerary {
    let it: Itinerary = null;
    if (this.itinerary && this.itinerary.length) {
      if (this._piece_shipment && this._car2_key) {
        // warehouse delivery part -> start with warehouse
        if (this._vehicle && this._vehicle.car_key == this._car2_key) {
          // first is warehouse
          it = this.itinerary.find(
            i => i.type == ItineraryType.WAREHOUSE || i.type == ItineraryType.WAREHOUSE_AUTO
          );
          if (it) {
            return it;
          }
        }
      }

      // default is first loading
      it = this._itinerary.find(i => i.type == ItineraryType.LOADING);
    }
    return it;
  }

  get last_itinerary(): Itinerary {
    let it: Itinerary = null;
    if (this.itinerary && this.itinerary.length) {
      // warehouse collection part -> start with warehouse
      if (this._vehicle && this._vehicle.car_key == this._car_key) {
        // last is warehouse
        let warehouse = this.itinerary.find(
          i => i.type == ItineraryType.WAREHOUSE || i.type == ItineraryType.WAREHOUSE_AUTO
        );
        if (warehouse) {
          return warehouse;
        }
      }

      // default is last_itinerary
      it = this._itinerary[this._itinerary.length - 1];
    }
    return it;
  }

  // in miliseconds, like date.getTime()
  get last_itinerary_time_with_limit(): number {
    if (this.last_itinerary) {
      // handle loading_time_limit with null value
      if (!this.last_itinerary.loading_time_limit && this.last_itinerary.loading_time_limit != 0) {
        // update to default 50 min loading time limit
        this.last_itinerary.loading_time_limit = 50;
      }
      return this.last_itinerary.arrival_time.getTime() + this.last_itinerary.loading_time_limit_miliseconds;
    }
    return 0;
  }

  // array of files from car for this agenda (same as in obligation)
  private _files: Array<Attachment> = [];
  public get files(): Array<Attachment> {
    return this._files;
  }
  public set files(value: Array<Attachment>) {
    this._files = value;
  }

  private _email_headers: Array<Email> = [];
  public get email_headers(): Array<Email> {
    return this._email_headers;
  }
  public set email_headers(value: Array<Email>) {
    this._email_headers = value;
  }

  
  // custom loading flag
  private _loadingFiles: boolean = false;
  public get loadingFiles(): boolean {
    return this._loadingFiles;
  }
  public set loadingFiles(value: boolean) {
    this._loadingFiles = value;
  }

  private _piece_shipment: boolean = null;
  public get piece_shipment(): boolean {
    return this._piece_shipment;
  }
  public set piece_shipment(value: boolean) {
    this._piece_shipment = value;
  }

  private _transshipment: boolean = null;
  public get transshipment(): boolean {
    return this._transshipment;
  }
  public set transshipment(value: boolean) {
    this._transshipment = value;
  }
  
  // events are equivalent for warehouse and transshipment
  private _warehouse_event_unloading: WarehouseEvent = null;
  public get warehouse_event_unloading(): WarehouseEvent {
    return this._warehouse_event_unloading;
  }
  public set warehouse_event_unloading(value: WarehouseEvent) {
    this._warehouse_event_unloading = value;
  }
  
  private _warehouse_event_loading: WarehouseEvent = null;
  public get warehouse_event_loading(): WarehouseEvent {
    return this._warehouse_event_loading;
  }
  public set warehouse_event_loading(value: WarehouseEvent) {
    this._warehouse_event_loading = value;
  }
}
