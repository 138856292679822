<div class="col-12 dashboard-head align-middle">
  <ng-container i18n>Jazyk</ng-container>
</div>

<div class="px-1 py-2">
  <div>
    Nainstalujte si rozšíření Překladař Google a využijte je pro přeložení naší aplikace do vašeho jazyka:
    <a target="_blank" 
    href="https://chrome.google.com/webstore/detail/google-translate/aapbdbdomjkkjkaonfhkkikfgjllcleb/related">Přidat zde</a>
  </div>
  <div class="mt-3">
    Install the Google Translate extension and use it to translate our application to your language:
    <a target="_blank"
    href="https://chrome.google.com/webstore/detail/google-translate/aapbdbdomjkkjkaonfhkkikfgjllcleb/related">Add here</a>
  </div>
</div>