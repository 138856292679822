import { Vehicle } from "../model/vehicle.object";
import { Agenda } from "../model/agenda.object";
import { GeoPosition } from "../model/geo-position.object";
import { HomeStandKey } from "../model/home-stand-key.object";
import { Itinerary } from "../model/itinerary.object";
import { Make } from "../model/make.object";
import { Model } from "../model/model.object";
import { Trailer } from "../model/trailer.object";
import { CargoActivity } from "../model/cargo-activity.object";
import { Driver } from "../model/driver.object";
import { Company } from "../model/company.object";
import { ServiceEventObject } from "../model/service-event.object";
import { SimCard } from "../model/simcard.object";

// Tools for building vehicle objects and common stuff
export class VehicleTools {
  static buildVehiclesFromData(data: Array<any>): any {
    let vehicles: Array<Vehicle> = [];
    data.forEach(
      (v, idx) => {
        let vehicle = this.buildVehicle(v);
        vehicle.index = idx;
        vehicles.push(vehicle);
      }
    );
    return vehicles;
  }

  // method for creating a single vehicle from api object
  static buildVehicle(v: any): Vehicle {
    let vehicle: Vehicle = new Vehicle();

    // nested json objects
    if (v.home_stand) {
      let home_stand_key = new HomeStandKey();
      for (let key in v.home_stand) {
        home_stand_key[key] = v.home_stand[key];
      }
      v.home_stand = home_stand_key;
      if (v.home_stand.country && v.home_stand.zip && v.home_stand.name) {
        v.home_stand_name = v.home_stand.country + ' ' + v.home_stand.zip.substring(0, 2) + ' ' + v.home_stand.name;
      } else {
        v.home_stand_name = '';
      }
    }
    if (v.model) {
      let model = new Model();
      for (let key in v.model) {
        model[key] = v.model[key];
      }
      v.model = model;
      v.model_id = model.model_id;

      if (v.model.make) {
        let make = new Make();
        for (let key in v.model.make) {
          make[key] = v.model.make[key];
        }
        v.model.make = make;
        v.make_id = make.make_id;
      }
    }
    if (v.trailer) {
      let trailer = new Trailer();
      for (let key in v.trailer) {
        trailer[key] = v.trailer[key];
      }
      v.trailer = trailer;
      v.trailer_key = trailer.trailer_key;
    }
    if (v.last_position) {
      let last_position = new GeoPosition(new Date());
      for (let key in v.last_position) {
        last_position[key] = v.last_position[key];
      }
      v.last_position = last_position;
    }
    if (v.agenda) {
      let vehicle_agenda = [];
      for (let key in v.agenda) {
        var agenda_data = v.agenda[key];
        if (!agenda_data) {
          continue;
        }

        let agenda: Agenda = this.buildAgenda(agenda_data);
        vehicle_agenda.push(agenda);
      }
      v.agenda = [];
      v.agenda = vehicle_agenda;
    }
    if (v.last_cargo_activity) {
      let last_cargo_activity = new CargoActivity();
      for (let key in v.last_cargo_activity) {
        last_cargo_activity[key] = v.last_cargo_activity[key];
      }
      let last_cargo_activity_position = new GeoPosition(new Date());
      for (let key in v.last_cargo_activity) {
        last_cargo_activity_position[key] = v.last_cargo_activity[key];
      }
      last_cargo_activity.city_gps = last_cargo_activity_position;
      v.last_cargo_activity = last_cargo_activity;
    }
    if (v.last_position) {
      let last_position = new GeoPosition(new Date());
      for (let key in v.last_position) {
        last_position[key] = v.last_position[key];
      }
      v.last_position = last_position;
    }


    for (let key in v) {
      vehicle[key] = v[key];
    }

    // clear array of setter changes for possible update
    vehicle.update = [];
    // set last cargo
    this.setLastCargo(vehicle);

    return vehicle;
  }
  
  
  // build agenda object itself
  static buildAgenda(data: any): Agenda {
    if (!data) return null;

    let agenda = new Agenda();
    if (data.itinerary) {
      let agenda_itinerary = [];
      data.itinerary.forEach(
        itinerary_data => {
          let itinerary = new Itinerary();
          let itinerary_gps_location = new GeoPosition(new Date());
          for (let key in itinerary_data) {
            itinerary[key] = itinerary_data[key];
            itinerary_gps_location[key] = itinerary_data[key];
          }
          itinerary.gps_coord = itinerary_gps_location;

          if (itinerary.service_events) {
            let array: Array<ServiceEventObject> = [];
            itinerary.service_events.forEach(
              item => {
                let sEvent: ServiceEventObject = new ServiceEventObject();
                for (let key in item) {
                  sEvent[key] = item[key];
                }
                array.push(sEvent);
              }
            );
            itinerary.service_events = array;
          }

          agenda_itinerary.push(itinerary);
        }
      );
      data.itinerary = agenda_itinerary;
    }

    for (let key in data) {
      agenda[key] = data[key];
    }

    if (data.order_number_standard) {
      agenda.order_number = data.order_number_standard;
    }
    agenda.tracked_positions = [];
    if (data.tracked_positions instanceof Array) {
      data.tracked_positions.forEach(
        trackedPosition => {
          let trackedPositionGeo = new GeoPosition(new Date());
          for (let key in trackedPosition) {
            trackedPositionGeo[key] = trackedPosition[key];
          }
          agenda.addTrackedPosition(trackedPositionGeo);
        }
      );
    }

    return agenda;
  }

  // method for building agenda in vehicle object
  static buildAgendaInVehicle(obj: any): any {
    if (obj.agenda) {
      let vehicle_agenda = [];
      for (let key in obj.agenda) {
        var agenda_data = obj.agenda[key];
        if (!agenda_data) {
          continue;
        }

        let agenda: Agenda = this.buildAgenda(agenda_data);
        vehicle_agenda.push(agenda);
      }
      obj.agenda = [];
      obj.agenda = vehicle_agenda;
    }

    return obj;
  }

  
  /*******************************************************/
  /* Last cargo */
  /*******************************************************/
  static setLastCargo(vehicle: Vehicle) {
    if (typeof vehicle.last_cargo_activity !== 'undefined' && null !== vehicle.last_cargo_activity) {
      if (vehicle.last_cargo_activity.type) {
        switch (vehicle.last_cargo_activity.type) {
          case Vehicle.LOADING_START:
            vehicle.cargo_activity_type = "loading";
            vehicle.cargo_activity_status = "play_green";
            break;
          case Vehicle.LOADING_END:
            vehicle.cargo_activity_type = "loading";
            vehicle.cargo_activity_status = "check_green";
            break;
          case Vehicle.UNLOADING_START:
            vehicle.cargo_activity_type = "unloading";
            vehicle.cargo_activity_status = "play_orange";
            break;
          case Vehicle.UNLOADING_END:
            vehicle.cargo_activity_type = "unloading";
            vehicle.cargo_activity_status = "check_orange";
            break;
          default:
            break;
        }
      }
    }
  }


  /*******************************************************/
  /* Drivers */
  /*******************************************************/
  static associateVehicleDrivers(v: Vehicle, company: Company): Vehicle {
    if (v && company && company.driversArray.length) {
      if (v.driver_key) {
        let driver: Driver = company.driversArray.find(d => d.driver_key == v.driver_key);
        if (driver) {
          v.driver = driver;
          v.driver_name = driver.name;
          // v.driver_phone = driver.phone;
        }
      }
      if (v.driver2_key) {
        let driver2: Driver = company.driversArray.find(d => d.driver_key == v.driver2_key);
        if (driver2) {
          v.driver2 = driver2;
          v.driver2_name = driver2.name;
        }
      }
    }
    // associate simcard from simcards array
    if (v && company && company.simcardsArray.length) {
      if (v.sim_imsi) {
        let sim: SimCard = company.simcardsArray.find(s => s.imsi == v.sim_imsi);
        if (sim) {
          v.driver_phone = sim.number;
        }
      }
    }
    return v;
  }

  static associateVehiclesDrivers(vehicles: Array<Vehicle>, company: Company): Array<Vehicle> {
    if (vehicles.length && company) {
      vehicles.forEach(
        v => {
          v.driver_name = '';
          v.driver_phone = '';
          if (company.driversArray.length) {
            if (v.driver_key) {
              let driver: Driver = company.driversArray.find(d => d.driver_key == v.driver_key);
              if (driver) {
                v.driver = driver;
                v.driver_name = driver.name;
                // v.driver_phone = driver.phone;
              }
            }
            if (v.driver2_key) {
              let driver2: Driver = company.driversArray.find(d => d.driver_key == v.driver2_key);
              if (driver2) {
                v.driver2 = driver2;
                v.driver2_name = driver2.name;
              }
            }
          }
          // associate simcard from simcards array
          if (company.simcardsArray.length) {
            if (v.sim_imsi) {
              let sim: SimCard = company.simcardsArray.find(s => s.imsi == v.sim_imsi);
              if (sim) {
                v.driver_phone = sim.number;
              }
            }
          }
        }
      );
    }
    
    return vehicles;
  }
}