import { DateTools } from '../tools/DateTools';

export class WoodSystemLog {
  private _log_id: number;
  public get log_id(): number {
    return this._log_id;
  }
  public set log_id(value: number) {
    this._log_id = value;
  }

  private _car_key: number;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _confirmed_time: string;
  public get confirmed_time(): string {
    return this._confirmed_time;
  }
  public set confirmed_time(value: string) {
    this._confirmed_time = value;
    if (value) {
      this._confirmed_time_date = DateTools.apiIsoToDate(value);
    }
  }

  private _confirmed_time_date: Date;
  public get confirmed_time_date(): Date {
    return this._confirmed_time_date;
  }
  public set confirmed_time_date(value: Date) {
    this._confirmed_time_date = value;
  }
  
  private _created_time: string;
  public get created_time(): string {
    return this._created_time;
  }
  public set created_time(value: string) {
    this._created_time = value;
  }
  
  private _err_msg: string;
  public get err_msg(): string {
    return this._err_msg;
  }
  public set err_msg(value: string) {
    this._err_msg = value;
  }
  
  private _file_name: string;
  public get file_name(): string {
    return this._file_name;
  }
  public set file_name(value: string) {
    this._file_name = value;
  }
  
  private _intent: string;
  public get intent(): string {
    return this._intent;
  }
  public set intent(value: string) {
    this._intent = value;
  }

  // private _received_time: string;
  // public get received_time(): string {
  //     return this._received_time;
  // }
  // public set received_time(value: string) {
  //     this._received_time = value;
  //     if (value) {
  //         this.created_time_input = DateTools.isoForDatetimeInput(DateTools.apiIsoToDate(value));
  //     }
  // }
  
  private _sent_manually: boolean;
  public get sent_manually(): boolean {
    return this._sent_manually;
  }
  public set sent_manually(value: boolean) {
    this._sent_manually = value;
  }
  
  private _sent_time: string;
  public get sent_time(): string {
    return this._sent_time;
  }
  public set sent_time(value: string) {
    this._sent_time = value;
    if (value) {
      this._sent_time_date = DateTools.apiIsoToDate(value);
    }
  }
  
  private _sent_time_date: Date;
  public get sent_time_date(): Date {
    return this._sent_time_date;
  }
  public set sent_time_date(value: Date) {
    this._sent_time_date = value;
  }

  private _sent_to: string;
  public get sent_to(): string {
    return this._sent_to;
  }
  public set sent_to(value: string) {
    this._sent_to = value;
  }
}
