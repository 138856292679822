import {animate, state, style, transition} from "@angular/animations";

export const slideUp: Array<any> = [
  state('active', style({
    height: '*',
    overflow: 'initial',
    opacity: 1
  })),
  state('inactive', style({
    height: '0px',
    overflow: 'hidden',
    opacity: 0
  })),
  transition('inactive <=> active', animate('100ms ease-in'))
];
