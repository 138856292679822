import { Component, EventEmitter, Output, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { MessageService } from "../../service/message.service";
import { NotificationService } from "../../service/notification-service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { Message } from "../../model/message.object";
import { Vehicle } from "src/app/model/vehicle.object";

declare var $: any;

@Component({
  selector: 'div.broadcast-message-dialog',
  templateUrl: './r-broadcast-message-dialog.component.html',
  styleUrls: ['./r-broadcast-message-dialog.component.css']
})
export class RBroadcastMessageDialogComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];

  private _message: string;
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  public broadcastVisible: boolean = false;

  private _vehicles: Array<Vehicle> = [];
  public get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }


  private _close: EventEmitter<any> = new EventEmitter();
  @Output()
  get close(): Observable<any> {
    return this._close.asObservable();
  }


  constructor(
    private _messageService: MessageService, 
    private _notifications: NotificationService, 
    private _vehicleNewService: VehicleNewService
  ) {
  }

  ngOnInit() {
    // modal default show
    (<any>$('#broadcastModal')).modal('show');

    this._subscribed.push(
      this._vehicleNewService.getAllVehiclesCache().subscribe(
        vehicles => {
          if (vehicles.length && vehicles != this._vehicles) {
            this._vehicles = vehicles;
          }
          else {
            this._vehicles = [];
          }
          console.log(vehicles);
        }
      ),
      this._vehicleNewService.getFilteredVehiclesCache().subscribe(
        vehicles => {
          if (vehicles.length) {
            // using little timeout
            window.setTimeout(
              () => {
                this._vehicles.forEach(
                  v => {
                    // mark all filtered vehicles
                    if (vehicles.find(v2 => v2.car_key == v.car_key)) {
                      v.broadcastMarked = true;
                    }
                    else {
                      v.broadcastMarked = false;
                    }
                  }
                );
              }, 500
            );
          }
          else {
            this._vehicles.forEach(
              v => {
                v.broadcastMarked = false;
              }
            );
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      s => s.unsubscribe()
    );
    this._subscribed = [];
  }
  
  get loadingVehicles(): boolean {
    return this._vehicleNewService.loadingVehicles;
  }

  sendMessages(): void {
    if (this._vehicles.length) {
      let done: number = 0;
      let marked_vehicles: Array<Vehicle> = this._vehicles.filter(v => v.broadcastMarked);

      // TODO mark only filtered vehicles

      marked_vehicles.forEach(
        v => {
          this._messageService.sendMessageToVehicle(this._message, v).subscribe(
            (message: Message) => {
              if (message) {
                done++;
                if (done === marked_vehicles.length) {
                  let alert: string = $localize`Hromadná zpráva úspěšně odeslána.`;
                  this._notifications.alert(alert, 'success', 4000);
                }
                this.closeDialog();
              }
            },
            error => {
              console.log(error);
              let alert: string = $localize`Chyba hromadné zprávy, vozidlo ` + v.number_plate;
              this._notifications.alert(alert, 'error', 4000);
            }
          )
        }
      )
    }
  }

  closeDialog() {
    (<any>$('#broadcastModal')).modal('hide');
    this._close.emit(true);
  }
}
