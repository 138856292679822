<ng-container *ngIf="loadingMessagesHistory">
  <div class="py-5 text-center">
    <div class="spinner-border text-primary" role="status" style="width: 2.5rem; height: 2.5rem;">
      <span class="sr-only"></span>
    </div>
  </div>
</ng-container>

<messages-day-grouped-list *ngIf="!loadingMessagesHistory" [messages]="vehicle_messages">
</messages-day-grouped-list>
