import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { NotificationService } from "./notification-service";
import { HttpClientService } from "./http-client.service";
import { WebsocketService } from "./websocket.service";
import { StorageService } from "./storage.service";
import { User } from "../model/user.object";
import { Token } from "../model/token.object";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  static GRANT = 'grant_type';

  static GRANT_TYPE_PASSWORD = 'password';
  static GRANT_TYPE_INVALIDATE = 'invalidate_token';
  static GRANT_TYPE_REFRESH = 'refresh_token';
  static GRANT_TYPE_CODE = 'authorization_code';

  static STORAGE_KEY = '_tmUser';

  private _authenticationApi: string = ServiceConfiguration.authentication.api;
  private _authenticated: boolean = false;
  private _currentTime: Date = new Date();
  /** timeoutId **/
  private _automaticAuthenticationRefresh: number;
  private _automaticAuthenticationRefreshErrors: number;

  constructor(
    private _httpService: HttpClientService,
    private _storage: StorageService,
    private _wsService: WebsocketService,
    private _notificationService: NotificationService
  ) {
    if (!IS_DEMO) {
      this.setUserFromStorage();
    }
    this.connectToWs();
    this._httpService.handleUnauthorized().subscribe(
      () => {
        this.cleanUp();
      }
    );
  }

  private _user: User = null;
  get user(): User {
    return this._user;
  }

  private _authenticationResult: BehaviorSubject<User> = new BehaviorSubject(this._user);
  get authenticationResult(): Observable<User> {
    return this._authenticationResult.asObservable();
  }

  isAuthenticated(): boolean {
    return this._authenticated;
  }

  authenticate(login: string, password: string): Observable<User> {
    if (IS_DEMO) {
      throw new Error('Not allowed on demo');
    }
    if (this._user) {
      throw new Error('Already authenticated');
    }
    this._wsService.close();
    let data = {
      username: login,
      password: password
    };
    data[AuthenticationService.GRANT] = AuthenticationService.GRANT_TYPE_PASSWORD;
    let observable: Subject<User> = new Subject();
    this.getAuthenticationRequest(data).subscribe(
      response => {
        if (response) {
          let json = response;
          this.setUser(json);
          observable.next(this._user);
          this.saveUserToStorage();
          this._wsService.connect(this._user.token.token, this._user.company_key);
          // Use the timeout from server, but shorten in by 5mins
          this.automaticTokenRefresh(json.expires_in * 1000 - 300000);
        }
      },
      error => {
        this.connectToWs();
        if (error.status === 400 || error.status === 401) {
          this._notificationService.alert(
            $localize`Kombinace zadaného emailu a hesla neexistuje.`, 'error', 4000
          );
        } 
        else {
          this._notificationService.alert(
            $localize`Neočekávaná chyba na straně serveru.`, 'error', 4000
          );
        }
      }
    );
    return observable.asObservable();
  }

  logout(token?: string) {
    if (!token && this._user) {
      token = this._user.token.token;
    }
    if (token) {
      let body = {};
      body[AuthenticationService.GRANT] = AuthenticationService.GRANT_TYPE_INVALIDATE;
      body[AuthenticationService.GRANT_TYPE_INVALIDATE] = token;

      this.getAuthenticationRequest(body).subscribe(
        response => {
          this.cleanUp();
          this._wsService.close();
          this.connectToWs();
          this._httpService.default_headers = [];
        },
        error => {
          //handle error
        }
      );
    }

  }

  refreshToken() {
    if (this._user) {
      let body = {};
      body[AuthenticationService.GRANT] = AuthenticationService.GRANT_TYPE_REFRESH;
      body[AuthenticationService.GRANT_TYPE_REFRESH] = this._user.token.token;
      this._httpService.post(this._authenticationApi, body).subscribe(
        response => {
          this._automaticAuthenticationRefreshErrors = 0;
          if (response.expires_in) {
            this.user.token.extendExpire(response.expires_in * 1000);
            this.saveUserToStorage();
            this.automaticTokenRefresh(response.expires_in * 1000 - 300000); // Use the timeout from server, but shorten in by 5mins
          } else {
            this.cleanUp();
            this._wsService.close();
            this.connectToWs();
          }
        },
        error => {
          // On error, repeat it in one minute
          if (this._automaticAuthenticationRefreshErrors++ > 5) {
            this.automaticTokenRefresh(300000);
          } else {
            this.automaticTokenRefresh(60000);
          }
        }
      );
    }
  }

  authenticateByCode(code: string) {
    var self = this;
    let subscription = this.authenticationResult.subscribe(
      function (user) {
        if (user === null) {
          let data = {};
          data[AuthenticationService.GRANT] = AuthenticationService.GRANT_TYPE_CODE;
          data['code'] = code;
          self.getAuthenticationRequest(data).subscribe(
            response => {
              let json = response;
              self.setUser(json);
              self.saveUserToStorage();
              window.location.href = '/';
            },
            error => {
              //window.location.href = '/';
              alert('Remote login was unsuccessful: ' + error);
            }
          );
          this.unsubscribe();
        }
      }
    );
    this.logout();
  }

  private connectToWs() {
    if (IS_DEMO) {
      this._wsService.connect(
        ServiceConfiguration.websocket.demo_token, ServiceConfiguration.websocket.demo_company
      );
    } 
    else if (this.isAuthenticated()) {
      this._wsService.connect(this._user.token.token, this._user.company_key);
    }
  }

  private getAuthenticationRequest(body: any) {
    this._httpService.excludeDefaultRequestOptions();
    let options = null;
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + ServiceConfiguration.authentication.token);
    options = {
      headers: headers,
      withCredentials: true
    };
    let observable = this._httpService.post(this._authenticationApi, body, options);
    this._httpService.includeDefaultRequestOptions();
    return observable;
  }

  private setUser(data: any): void {
    this._currentTime = new Date();
    let expires: Date;
    if (!data.expires) {
      expires = new Date(this._currentTime.getTime() + data.expires_in * 1000);
    } else {
      expires = data.expires;
    }
    let userToken = new Token(data.access_token, expires, data.token_type);
    this._user = new User(
      data.user.username,
      userToken,
      data.user.company_key,
      data.user.company_name,
      data.user.display_key,
      data.user.person,
      new Date(data.user.admittance)
    );
    this._authenticated = true;
    this._httpService.default_headers = [
      {
        name: ServiceConfiguration.authentication.authorizationHeader,
        value: ServiceConfiguration.authentication.authorizationHeaderValueFormat.replace(/%TOKEN%/g, this._user.token.token)
      },
      {
        name: ServiceConfiguration.authentication.requestTimeHeader,
        value: () => {
          let d = new Date();
          return d.getTime() / 1000 - d.getTimezoneOffset() * 60
        }
      }
    ];
    this._authenticationResult.next(this._user);
  }


  private _storageChecked: BehaviorSubject<boolean> = new BehaviorSubject(null); 
  public get storageChecked(): Observable<boolean> {
    return this._storageChecked.asObservable();
  }

  private setUserFromStorage() {
    let user: any = this._storage.getItem(AuthenticationService.STORAGE_KEY);
    if (user) {
      // reference itself in listener function
      let self = this;
      window.addEventListener('storage', function(event) {
        if (event.key == 'getSessionStorage') {
          // Some tab asked for the sessionStorage -> send it via local storage
          self._storage.setItem('sessionStorage', JSON.stringify(sessionStorage), true);
          self._storage.removeItem('sessionStorage', true);
        } 
      });
      this.setUserFromToken(user);
      this._storageChecked.next(true);
    }
    else {
      // multitab authentication solution inspired by:
      // https://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/
      // https://blog.guya.net/security/browser_session/sessionStorage.html
      
      // Ask other tabs for session storage
      // set item to LOCAL storage 
      this._storage.setItem('getSessionStorage', Date.now().toString(), true);
  
      // reference itself in listener function
      let self = this;
      window.addEventListener('storage', function(event) {
        if (event.key == 'getSessionStorage') {
          // Some tab asked for the sessionStorage -> send it via local storage
          self._storage.setItem('sessionStorage', JSON.stringify(sessionStorage), true);
          self._storage.removeItem('sessionStorage', true);
        } 
        else if (event.key == 'sessionStorage' && !self._storage.storageLength()) {
          // sessionStorage is empty -> fill it
          var data = JSON.parse(event.newValue);
          for (let key in data) {
            // get item from
            sessionStorage.setItem(key, data[key]);
          }
          
          // authenticate in new tab - this part is most important
          let userFromOtherTab: any = self._storage.getItem(AuthenticationService.STORAGE_KEY);
          if (userFromOtherTab) {
            self.setUserFromToken(userFromOtherTab);
            self.connectToWs();
            self._storageChecked.next(true);
          }
        }
      });

      // check it anyways..
      const TIMEOUT_CHECKING_STORAGE: number = 500;
      window.setTimeout(
        () => { self._storageChecked.next(true); }, TIMEOUT_CHECKING_STORAGE
      );
    }
  }

  private setUserFromToken(user: any): void {
    if (user) {
      user = JSON.parse(user);
      user.token = JSON.parse(user.token);
      user.admittance = JSON.parse(user.admittance);

      let data = {
        access_token: user.token.token,
        expires: new Date(user.token.expire),
        token_type: user.token.type,
        user: {
          username: user.username,
          company_key: user.company_key,
          company_name: user.company_name,
          display_key: user.display_key,
          person: user.person,
          admittance: new Date(user.admittance)
        }
      };

      if (data.expires.getTime() > Date.now()) {
        this.setUser(data);
        // Refresh earlier by 1 min
        this.automaticTokenRefresh(data.expires.getTime() - Date.now() - 60000); 
      } 
      else {
        this.logout(data.access_token);
      }
    }
  }

  private cleanUp() {
    if (this._automaticAuthenticationRefresh) {
      window.clearTimeout(this._automaticAuthenticationRefresh);
    }
    this._automaticAuthenticationRefreshErrors = 0;
    this._user = null;
    this._authenticated = false;
    this._storage.removeItem(AuthenticationService.STORAGE_KEY);
    this._httpService.default_headers = [];
    this._authenticationResult.next(this._user);
  }

  private automaticTokenRefresh(inMiliseconds: number) {
    let curTimeout = (inMiliseconds < 1000) ? 1000 : inMiliseconds;
    if (inMiliseconds < 900000) {
      inMiliseconds = 900000;
    }
    console.warn((new Date()).toLocaleString(), 'Automatic refresh in ' + curTimeout + 'ms');
    if (this._automaticAuthenticationRefresh) {
      window.clearTimeout(this._automaticAuthenticationRefresh);
    }
    this._automaticAuthenticationRefresh = window.setTimeout(
      () => {
        this.refreshToken();
        this.automaticTokenRefresh(inMiliseconds);
      },
      curTimeout
    );
  }

  private saveUserToStorage() {
    if (this._user) {
      this._storage.setItem(AuthenticationService.STORAGE_KEY, this._user.toString());
    } else {
      this._storage.removeItem(AuthenticationService.STORAGE_KEY);
    }
  }
}
