import { Component, Input, OnInit } from '@angular/core';
import { ManualTrackingTargetInterface } from 'src/app/interface/manual-tracking-target.interface';
import { Attachment } from 'src/app/model/attachment.object';
import { Driver } from 'src/app/model/driver.object';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { Vehicle } from 'src/app/model/vehicle.object';
import { DestinationRoutesService } from 'src/app/service/destination-routes.service';
import { FilesService } from 'src/app/service/files.service';
import {Observable, Subject, Subscription} from "rxjs";
import { CompanyService } from 'src/app/service/company.service';
import { StorageService } from 'src/app/service/storage.service';
import { NotificationService } from 'src/app/service/notification-service';
import { ObligationService } from 'src/app/service/obligation.service';
import { ItineraryType } from 'src/app/config';


@Component({
  selector: 'app-r-diary-mobile',
  templateUrl: './r-diary-mobile.component.html',
  styleUrls: ['./r-diary-mobile.component.css']
})
export class RDiaryMobileComponent implements OnInit {
  private _subscribed: Array<Subscription> = [];

  public itenerary :Itinerary = null;

  private _data: any = null;
  private _driversActive: any;
  private _driversInactive: any;
  public selectedDriver: any;
  public get data(): any {
    return this._data;
  }

  @Input()
  public set data(value: any) {
    this._data = value;
  }

  private _vehicle: Vehicle = null;
  public get vehicle(): Vehicle {
    return this._vehicle;
  }

  @Input()
  public set vehicle(value: Vehicle) {
    this._vehicle = value;
    if(this.vehicle){
      if(this.data){
        for (var d of this.data){
          if(d.car_key == this.vehicle.car_key){
            this.setDefalut();
            this.selectedData = d;
            this.itineraries = this.selectedData.itineraries;
            break;
          }
        }
      }
    }
  }

  private _driver: Driver = null;
  public get driver():Driver{
    return this._driver;
  }
  @Input()
  public set driver(value:Driver){
    this.driver = value;
  }
  
  public selectedData:any = null;

  private _itineraries: any = null;
  public get itineraries(): any {
    return this._itineraries;
  }
  public set itineraries(value: any) {
    this._itineraries = value;
  }

  
  private _markers: Array<GoogleMapMarker> = [];
  public get markers(): Array<GoogleMapMarker> {
    return this._markers;
  }


  private _rebuildMapSubject: Subject<boolean> = new Subject<boolean>();
  public get rebuildMapSubject(): Observable<boolean> {
    return this._rebuildMapSubject.asObservable();
  }

  public get loading():boolean{
    if (this.vehicle && this.selectedData && this.itineraries) {
      return false;
    }
    return true;
  }

  public skenButton:boolean = false;

  public cmrButton:boolean = false;

  public loadButton:boolean = false;

  public loadOrderNumber = null;

  public loadWeight = null;

  public selectedItinerary:Itinerary = null;

  public allGpsButton:boolean = false;
  
  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }

  constructor(
    private _filesServ: FilesService,
    private _companyServ: CompanyService,
    private _obligationServ: ObligationService
  ) {}


  ngOnInit(): void {
    this.setDefalut();
    this.loadData();
  }

  setDefalut():void{
    this.skenButton = false;
    this.cmrButton = false;
    this.loadButton = false;

  }

  loadData():void{
    this._companyServ.getDriversObservable().subscribe(
      drivers => {
        if(this._driversActive !== drivers && drivers.length) {
          this._driversActive = drivers.filter(d => d.active == true);
          this._driversActive.sort((a, b) => (a.name > b.name) ? 1 : -1);
          
          this._driversInactive = drivers.filter(d => d.active == false);
          this._driversInactive.sort((a, b) => (a.name > b.name) ? 1 : -1);
        }
        else if(this._driversActive !== drivers) {
          this._driversActive = [];
          this._driversInactive = [];
        }
      }
    )
      

  }

  


  public gpsButton:boolean = false;

  gpsClick(it:any,show:boolean){
    this.selectedItinerary = it;
    this.gpsButton = show;
  }

  allGpsClick(){
    this.allGpsButton = true;
    // reinit right map markers
    this._markers = [];
    // init each itinerary item
    this._itineraries.forEach(
      it => {
        // init markers on right map
        if (it.gps_coord) {
          let marker: GoogleMapMarker = this.createmarkers(it.gps_coord.googleLatLang, it);
          marker.setData('address', it.address);
          this._markers.push(marker);
        }
      }
    );
    this.rebuildmarkers();
  }

  

  skenClick(time:number){
    this.skenButton = true;
    let self = this
    
    window.setTimeout(() => {
      self.skenButton = false;
    }, time)
    
  }

  cmrClick(time:number){
    this.cmrButton = true;
    let self = this 
    window.setTimeout(() => {
      self.cmrButton = false;
    }, time)
    
  }

  loadClick(itenerary:any){
    this.loadOrderNumber = itenerary.agenda.order_number_standard;
    this.loadWeight = itenerary.agenda.loadWeight;
    this.loadButton = true;
    this.itenerary = itenerary;

  }

  driverclick(driverId : number){
    this._driversActive.forEach(element => {
      if(element.driver_key == driverId){
        this.selectedDriver = element;
        this.downloadDriverDocuments(this.selectedDriver);
      }  
          
    });
    
  }

  loadUnloadExit(setTarget:boolean){
    
    this.loadButton =false;
  }

  
  restartGif(imgElement){ 
  let element = document.getElementById("sken");
  if (element) {

  }
}

  // method for rebuilding markers after any change
  private rebuildmarkers() {
    this._markers = this._markers.slice();
    this._markers.forEach(
      (marker, index) => {
        if (marker) {
          // we now use icons of cargo_status
          marker.icon = this._itineraries[index].typeIcon();
        }
      }
    );
    this._rebuildMapSubject.next(true);
  }

  // method for creating new marker on google map in right part
  private createmarkers(location: any, it: Itinerary = null): GoogleMapMarker {
    let marker = new GoogleMapMarker();
    marker.position = location;
    marker.draggable = false;
    marker.setData('type', 2);
    marker.setData('id', Math.random().toString(36).substring(7));
    return marker;
  }

  /////////////////////////////////////////////////////////////////////////

  getItenerary(key:number):any{
    return this._itineraries[key];
  }

  getType(typ:string):string{
    if (typ == ItineraryType.LOADING) {
      return $localize`Nakládka`;
    }
    if (typ == "U") {
      return $localize`Vykládka`;
    }
  }


  gatDateTimeInPrintableFormat(dateTime: string):string{
    dateTime = dateTime.split("+")[0];
    // date prepare
    let date = dateTime.split("T")[0];
    let day = date.split("-")[2];
    let month = date.split("-")[1];
    let year = date.split('-')[0];

    // time prepare
    let time = dateTime.split("T")[1];
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1]; 
    let sec = time.split(":")[2]; 
    return day + "." + month+ "." + year + " " + hours+":"+minutes ;
  }

  getPrintableDateTime(dateTime:any):string{
    let hours = null;
    let minutes = null;
    if(dateTime.getUTCHours()<10){
      hours = "0" + dateTime.getUTCHours();
    }else{
      hours = dateTime.getUTCHours();
    }

    if(dateTime.getUTCMinutes()<10){
      minutes = "0" + dateTime.getUTCMinutes();
    }else{
      minutes = dateTime.getUTCMinutes();
    }



    return dateTime.getDay() + "." + dateTime.getMonth() + "." + dateTime.getFullYear() + " " + hours + ":" + minutes;
  }

  getTimeInPrintableFormat(time:string):string{
    var t =time.split(":"); 
    return t[0]+":"+t[1];
  }

  /****************************************************/
  /* Destination route creating */
  /****************************************************/
  private _temporaryTrackingTarget: ManualTrackingTargetInterface;
  private _temporaryTrackingWaypoints: Array<any> = [];
  private _currentVehicle: Vehicle;

  // method for setting of destination route to be created
  


 // handler for event from r-vehicle-manual-tracking-itinerary component
  hideMapEvent(ev: boolean) {
    if (ev == true) {
      this.gpsButton = false;
    }
  }


  /************************************************************/
  /* Driver documents part */
  /************************************************************/
  //downloadDriversDocuments(): void {
  //  this._driversActive.forEach(
  //    d => {
  //      this.downloadDriverDocuments(d);
  //    }
  //  );
 // }

  downloadDriverDocuments(d: Driver): void {
    this._subscribed.push(
      this._filesServ.downloadDriverDocuments(d.driver_key).subscribe(
        response => {        
          if (response.length) {
            d.driver_documents = response;
            d.driver_documents.sort((a, b) => (a.modified < b.modified) ? 1 : -1);
            // downloading thumbnails
            this.downloadThumbnails(d);
          }
        }
      )
    );
  }

  // just flag of downloading
  public get downloadingFile(): boolean {
    return this._obligationServ.downloadingAttachment;
  }
  
  // method for downloading selected driver document
  downloadAttachment(document: Attachment): void {
    this._obligationServ.openAttachmentNewTab(document);
  }
  
  // method for downloading thumbnails of all documents
  downloadThumbnails(d: Driver): void {
    // download all thumbnails of files
    if (d.driver_documents.length) {
      d.driver_documents.forEach(
        f => {
          this.downloadThumbnail(f);
        }
      );
    }
  }

  // method for downloading thumbnail of given document
  downloadThumbnail(attachment: Attachment): void {
    if (attachment.url && attachment.url.includes('files/')) {
      let thumbUrl: string = '';
      if (attachment.url.includes('/files/company/')) thumbUrl = attachment.url.replace('company/', 'company/thumb/');
      else thumbUrl = attachment.url + '/thumb';
      
      // set flag for loading thumbnail
      attachment.loadingThumbnail = true;

      this._filesServ.getDocument(thumbUrl).subscribe(
        response => {
          let newBlob: any = new Blob([response], { type: response.type });
  
          if (response.type !== '' && (response.type.match(/image|pdf|text/)) && response.size) {
            attachment.thumbnail = {
              content: URL.createObjectURL(newBlob),
              type: response.type
            };
          }
          // set flag for loading thumbnail
          attachment.loadingThumbnail = false;
        },
        error => {
          // set flag after error loading
          console.log(error);
          // set flag for loading thumbnail
          attachment.loadingThumbnail = false;
        }
      );
    }
  }

}
