import {LastService, LastSupportInfo, LastUploadLog} from "../model/hw-info-data.object";
import { Vehicle } from "./vehicle.object";

// Lze dodat i dalsi atributy viz dokumentace
export class HwInfo {
  private _imei: number;
  get imei(): number {
    return this._imei;
  }
  set imei(value: number) {
    this._imei = value;
  }

  private _model: string;
  get model(): string {
    return this._model;
  }
  set model(value: string) {
    this._model = value;
  }

  private _first_use: Date;
  get first_use(): Date {
    return this._first_use;
  }
  set first_use(value: Date) {
    this._first_use = value;
  }

  private _last_use: Date;
  get last_use(): Date {
    return this._last_use;
  }
  set last_use(value: Date) {
    this._last_use = value;
  }

  private _tm_ver: string;
  get tm_ver(): string {
    return this._tm_ver;
  }
  set tm_ver(value: string) {
    this._tm_ver = value;
  }

  private _model_count: number;
  get model_count(): number {
    return this._model_count;
  }
  set model_count(value: number) {
    this._model_count = value;
  }

  private _all_count: string;
  get all_count(): string {
    return this._all_count;
  }
  set all_count(value: string) {
    this._all_count = value;
  }

  private _memory_info: string;
  get memory_info(): string {
    return this._memory_info;
  }
  set memory_info(value: string) {
    this._memory_info = value;
  }

  private _operated_days: number;
  get operated_days(): number {
    return this._operated_days;
  }
  set operated_days(value: number) {
    this._operated_days = value;
  }

  private _last_support_info: LastSupportInfo;
  get last_support_info(): LastSupportInfo {
    return this._last_support_info;
  }
  set last_support_info(value: LastSupportInfo) {
    this._last_support_info = value;
  }

  private _last_upload_log: LastUploadLog;
  get last_upload_log(): LastUploadLog {
    return this._last_upload_log;
  }
  set last_upload_log(value: LastUploadLog) {
    this._last_upload_log = value;
  }

  private _last_service: LastService;
  get last_service(): LastService {
    return this._last_service;
  }
  set last_service(value: LastService) {
    this._last_service = value;
  }

  // custom ref
  private _vehicle: Vehicle = null;
  public get vehicle(): Vehicle {
    return this._vehicle;
  }
  public set vehicle(value: Vehicle) {
    this._vehicle = value;
  }
}