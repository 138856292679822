// import {SimCardDataInterface} from "../interface/sim_card-data.interface";

import { Vehicle } from "./vehicle.object";

export class SimCard {

  constructor() {
  }

  // e.g. "230024100666066"
  private _imsi: string;
  public get imsi(): string {
    return this._imsi;
  }
  public set imsi(value: string) {
    this._imsi = value;
  }

  // e.g. "+420725326585"
  private _number: string;
  public get number(): string {
    return this._number;
  }
  public set number(value: string) {
    this._number = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _abroad1_nets: any;
  public get abroad1_nets(): any {
    return this._abroad1_nets;
  }
  public set abroad1_nets(value: any) {
    this._abroad1_nets = value;
  }
  
  private _abroad2_nets: any;
  public get abroad2_nets(): any {
    return this._abroad2_nets;
  }
  public set abroad2_nets(value: any) {
    this._abroad2_nets = value;
  }

  private _our_sim: boolean;
  public get our_sim(): boolean {
    return this._our_sim;
  }
  public set our_sim(value: boolean) {
    this._our_sim = value;
  }
  
  private _price: number;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
  }
  
  private _proto_home: string;
  public get proto_home(): string {
    return this._proto_home;
  }
  public set proto_home(value: string) {
    this._proto_home = value;
  }
  
  private _proto_abroad1: string;
  public get proto_abroad1(): string {
    return this._proto_abroad1;
  }
  public set proto_abroad1(value: string) {
    this._proto_abroad1 = value;
  }
  
  private _proto_abroad2: string;
  public get proto_abroad2(): string {
    return this._proto_abroad2;
  }
  public set proto_abroad2(value: string) {
    this._proto_abroad2 = value;
  }

  private _tariff: string;
  public get tariff(): string {
    return this._tariff;
  }
  public set tariff(value: string) {
    this._tariff = value;
  }

  private _tariff_name: string;
  public get tariff_name(): string {
    return this._tariff_name;
  }
  public set tariff_name(value: string) {
    this._tariff_name = value;
  }

  private _upload_home: string;
  public get upload_home(): string {
    return this._upload_home;
  }
  public set upload_home(value: string) {
    this._upload_home = value;
  }

  private _upload_abroad1: string;
  public get upload_abroad1(): string {
    return this._upload_abroad1;
  }
  public set upload_abroad1(value: string) {
    this._upload_abroad1 = value;
  }

  private _upload_abroad2: string;
  public get upload_abroad2(): string {
    return this._upload_abroad2;
  }
  public set upload_abroad2(value: string) {
    this._upload_abroad2 = value;
  }

  private _version: number;
  public get version(): number {
    return this._version;
  }
  public set version(value: number) {
    this._version = value;
  }

  private _serial_number: any;
  public get serial_number(): any {
    return this._serial_number;
  }
  public set serial_number(value: any) {
    this._serial_number = value;
  }

  // custom pairing with vehicles
  private _vehicle: Vehicle;
  public get vehicle(): Vehicle {
    return this._vehicle;
  }
  public set vehicle(value: Vehicle) {
    this._vehicle = value;
  }

  get apiObject(): any {
    return {
      number: this.number
    }
  }
}