import { CompanyContact } from "../model/company-contact.object";
import { Company } from "../model/company.object";
import { DriverCard } from "../model/driver-card.object";
import { Driver } from "../model/driver.object";
import { Person } from "../model/person.object";
import { SimCard } from "../model/simcard.object";
import { UserAccount } from "../model/user-account.object";

// Tools for building company associated objects
export class CompanyTools {

  /**********************************************/
  /* Company */
  /**********************************************/
  static buildCompany(data: any): Company {
    let company: Company = new Company();
    for (let key in data) {
      company[key] = data[key];
    }
    return company;
  }


  /**********************************************/
  /* Contacts */
  /**********************************************/
  // method for creating contacts array
  static buildContactsFromData(data: any): Array<CompanyContact> {
    let contacts: Array<CompanyContact> = [];
    for (let key in data) {
      let contact: CompanyContact = this.buildContact(data[key]);
      contacts.push(contact);
    }
    return contacts;
  }

  // method for creating a single contact
  static buildContact(o: any): CompanyContact {
    let contact: CompanyContact = new CompanyContact();
    for (let key in o) {
      contact[key] = o[key];
    }
    return contact;
  }


  /**********************************************/
  /* Drivers */
  /**********************************************/
  // method for creating drivers array
  static buildDriversFromData(data: any): Array<Driver> {
    let drivers: Array<Driver> = [];
    for (let key in data) {
      let driver: Driver = this.buildDriver(data[key]);
      drivers.push(driver);
    }
    return drivers;
  }

  // method for creating a single driver object
  static buildDriver(o: any): Driver {
    let driver: Driver = new Driver();

    if (o.tacho_card) {
      let tacho_card = new DriverCard();
      for (let key in o.tacho_card) {
        tacho_card[key] = o.tacho_card[key];
      }
      o.tacho_card = tacho_card;
    }

    for (let key in o) {
      driver[key] = o[key];
    }
    driver.update = [];
    return driver;
  }
  

  /**********************************************/
  /* Persons */
  /**********************************************/
  // method for creating persons array
  static buildPersonsFromData(data: any): Array<Person> {
    let persons: Array<Person> = [];
    for (let key in data) {
      let person: Person = this.buildPerson(data[key]);
      persons.push(person);
    }
    return persons;
  }

  // method for creating a single driver object
  static buildPerson(o: any): Person {
    let person: Person = new Person();
    for (let key in o) {
      person[key] = o[key];
    }
    return person;
  }


  /**********************************************/
  /* Simcards */
  /**********************************************/
  // method for creating simcards array
  static buildSimcardsFromData(data: any): Array<SimCard> {
    let sims: Array<SimCard> = [];
    for (let key in data) {
      let sim: SimCard = this.buildSimcard(data[key]);
      sims.push(sim);
    }
    return sims;
  }

  // method for creating a single simcard object
  static buildSimcard(o: any): SimCard {
    let sim: SimCard = new SimCard();
    for (let key in o) {
      sim[key] = o[key];
    }
    return sim;
  }


  /**********************************************/
  /* User accounts */
  /**********************************************/
  // method for creating user accounts array
  static buildUserAccountsFromData(data: any): Array<UserAccount> {
    let users: Array<UserAccount> = [];
    for (let key in data) {
      let user: UserAccount = this.buildUserAccount(data[key]);
      users.push(user);
    }
    return users;
  }

  // method for creating a single user account object
  static buildUserAccount(o: any): UserAccount {
    let user: UserAccount = new UserAccount();
    for (let key in o) {
      user[key] = o[key];
    }
    // permissions attributes initialization
    user.setPermissionsArray();
    return user;
  }
}