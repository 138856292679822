<ng-container *ngIf="interruptionCount > 0">
  <img src="assets/icon/icon-car-state/interruption.svg" style=" width:16px!important; height: 16px!important;" />
  <ng-template #dataLoaded>
    {{interruptionCount}}x ({{interruptionDistance}})
  </ng-template>
  <ng-container *ngIf="loading;else dataLoaded">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </ng-container>
</ng-container>
