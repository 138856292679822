<div class="col-12 dashboard-head itinerary-dairy" style="font-size: 26px;">
  <img class="rightMenuImg" src="assets/icons-new/diare_2.svg" alt="calendar"/>
  <span *ngIf="!notConnected">{{formattedLastVehicleLoad}}</span>
  <b *ngIf="notConnected" style="cursor:default; font-size:36px; line-height:0px; color:red" 
  ngbTooltip="Chyba spojení" container="body" placement="bottom auto">
    {{'{!}'}}
  </b>&nbsp;
  <ng-container i18n>Diář</ng-container>
  <a [style.visibility]="!isYesterdayVisible ? 'visible' : 'hidden'" 
  (click)="moveToPast()" class="pointer">
    <img src="assets/icons-new/navigate_left.svg" alt="navigate_left" height="18px"/>
  </a>
  {{current_selected_text}}
  <a [style.visibility]="!isTomorrowVisible ? 'visible' : 'hidden'" 
  (click)="moveToFuture()" class="pointer">
    <img src="assets/icons-new/navigate_right.svg" alt="navigate_right" height="18px"/>
  </a>
</div>

<div *ngIf="reloadVehicles" class="dashboard-body text-center py-3">
  <h6 i18n>Itinerář byl upraven - dojde k přenačtení...</h6>
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<!-- Toto info mrknutí zruším..
v diáři by se jim to dělalo, když např fakturantka vstoupí do zakazky -->
<!-- <div *ngIf="reloadAgenda" class="dashboard-body text-center pt-3 pb-1">
  <h6>Itinerář byl upraven - dojde k aktualizaci...</h6>
  <div class="spinner-wrapper pt-4 pb-2">
    <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div> -->

<div *ngIf="!itinerary || loadingVehicles" class="dashboard-body text-center py-3">
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<div *ngIf="!validAccess" class="dashboard-body">
  <div class="container text-center py-3">
    <h5>
      <ng-container i18n>Váš přístup vypršel. Máte-li zájem o další využívání, předplaťte si přístup</ng-container>
      <a href="javascript:void(0)" (click)="openSubsNewTab()" i18n>zde</a>.
    </h5>
  </div>
</div>

<div *ngIf="!loadingVehicles && !selectedDayItinerary.length" class="dashboard-body">
  <div class="container text-center py-3">
    <h5 i18n>Žádné nakládky/vykládky.</h5>
  </div>
</div>

<div *ngIf="itinerary && !loadingVehicles && !reloadVehicles && validAccess" class="dashboard-body">
  <div class="clearfix">
    <table class="col-12 table-bordered">
      <tbody>
        <!-- sorting switch option -->
        <tr *ngIf="selectedDayItinerary?.length">
          <td colspan="2">
            <div class="w-100 d-flex">
              <div class="w-50 text-center">
                <button [class]="'btn btn-' + (sortByHour ? '' : 'outline-') + 'primary button-sort'" 
                (click)="sortByHour=true">
                  <ng-container i18n>Řadit dle času</ng-container>
                </button>
              </div>
              <div class="w-50 text-center">
                <button [class]="'btn btn-' + (sortByHour ? 'outline-' : '') + 'primary button-sort'" 
                (click)="sortByHour=false">
                  <ng-container i18n>Řadit dle SPZ</ng-container>
                </button>
              </div>
            </div>
          </td>
        </tr>

        <!-- sorted itinerary list -->
        <ng-template ngFor let-lookup [ngForOf]="selectedDayItinerary">
          <tr *ngIf="!sortByHour">
            <td colspan="2" class="bg-secondary text-light">
              <strong class="mx-2">{{lookup.first.number_plate}}</strong>
              <span>{{lookup.first.vehicle.driver_name}}</span>
              <img class="mr-1 float-right pointer" 
              src="assets/icons-new/calendar_25_white.svg" 
              height="25px" data-toggle="modal" data-target="#mobileModal" 
              (click)="selectedVehicle = lookup.first.vehicle">
            </td>
          </tr>

          <tr>
            <td class="text-center" 
            [style.background]="isCurrentHour(lookup.hour) ? '#ff7f2a' : ''"
            [attr.rowspan]="lookup.itineraries.length + (lookup.itineraries.length === 1 ? 0 : 1)">
              {{lookup.hourHuman}}
            </td>

            <!-- only one itinerary for particular hour/vehicle -->
            <td *ngIf="lookup.itineraries.length === 1 && lookup.first" 
            class="w-100 px-1 {{lookup.first.css_class}}">
              <ng-container *ngTemplateOutlet="itineraryTemplate, context: {it: lookup.first}">
              </ng-container>
            </td>
          </tr>

          <!-- more itinerary for particular hour/vehicle -->
          <ng-template [ngIf]="lookup.itineraries.length > 1">
            <tr *ngFor="let it of lookup.itineraries" [class]="it.css_class">
              <td class="w-100 px-1">
                <ng-container *ngTemplateOutlet="itineraryTemplate, context: {it: it}">
                </ng-container>
              </td>
            </tr>
          </ng-template>
        </ng-template>
      </tbody>
    </table>

    <!-- sorting just because of tooltips scrolling problems in last row of diary table -->
    <div *ngIf="selectedDayItinerary?.length" style="height: 10px;"></div>
  </div>
</div>


<!-- template for each itinerary -->
<ng-template #itineraryTemplate let-it="it">
  <div class="d-inline-flex">
    <div class="d-flex">
      <!-- padding-top-5  -->
      <div class="pointer my-auto" (click)="openCurrentPositionDetail(it)">
        <ng-container *ngIf="it.type=='L' || it.type=='U'">
          <img [src]="it.typeIcon()" class="pointer" width="30" height="30"/>
        </ng-container>
        <ng-container *ngIf="it.type=='W' || it.type=='V'">
          <img *ngIf="it.warehouse_in_itinerary" src="assets/icons-new/collection.svg" 
          class="pointer" width="30" height="30"/>
          <img *ngIf="it.warehouse_out_itinerary" src="assets/icons-new/delivery.svg" 
          class="pointer" width="30" height="30"/>
        </ng-container>
        <ng-container *ngIf="it.type=='T'">
          <img *ngIf="it.warehouse_in_itinerary" src="assets/icons-new/transship-unload.svg" 
          class="pointer" width="30" height="30"/>
          <img *ngIf="it.warehouse_out_itinerary" src="assets/icons-new/transship-load.svg" 
          class="pointer" width="30" height="30"/>
        </ng-container>
      </div>

      <!-- padding-top-5  -->
      <div class="pointer my-auto" (click)="openMessages(it)">
        <div>
          <img *ngIf="!it.hasStarted && !it.isDone" width="30" height="30" 
          src="assets/icons-new/navigate_cross_red_transition_1.svg"
          ngbTooltip="Nezahájena" data-toggle="modal" data-target="#agendaServiceModal"
          (click)="itineraryToEvent = it" i18n-ngbTooltip/>
          <img *ngIf="it.hasStarted && !it.isDone" width="30" height="30" 
          src="assets/icons-new/check-orange-transition.svg"
          ngbTooltip="Probíhá" data-toggle="modal" data-target="#agendaServiceModal"
          (click)="itineraryToEvent = it" i18n-ngbTooltip/>
          <img *ngIf="it.isDone" width="30" height="30" 
          src="assets/icons-new/check-green-transition.svg"
          ngbTooltip="Dokončena" i18n-ngbTooltip/>
        </div>
        <div *ngIf="it.isDone && it.agenda?.edoc_url && it.agenda?.order_comp_book.edoc_required_itinerary">
          <div class="d-flex justify-content-center">
            <div>
              <a [href]="it.agenda.edoc_url" target="_blank">
                <img width="20" height="20" src="assets/icons-new/link.svg"
                [ngbTooltip]="'Link e-dokumentace: ' + it.agenda.edoc_url" 
                placement="left auto" container="body"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-auto" style="min-width:50px; width:50px;">
      <div *ngIf="it.hasStarted" ngbTooltip="Čas zahájení" i18n-ngbTooltip>
        <span *ngIf="!it.startTime">--:--</span>
        <span *ngIf="it.startTime">
          {{it.startTime | date:'HH:mm'}}
        </span>
      </div>
      <div *ngIf="it.isDone" ngbTooltip="Čas dokončení" i18n-ngbTooltip>
        <strong *ngIf="it.endTime">
          {{it.endTime | date:'HH:mm'}}
        </strong>
      </div>
      <div *ngIf="it.isDone" ngbTooltip="Doba trvání" i18n-ngbTooltip>
        {{it.durationFormatted}}
      </div>
      <!-- viscofan feature  -->
      <div *ngIf="!it.hasStarted && it.agenda.order_comp_book_key == 26646" 
      class="padding-top-5 pointer my-auto">
        <span class="pointer ml-1" class="pointer ml-1" 
        data-toggle="modal" data-target="#viscofanModal" 
        (click)="agendaToViscofan = it.agenda"
        ngbTooltip="Viscofan incident" container="body" placement="left auto" i18n-ngbTooltip>
          <img src="assets/icons-new/message.svg" width="28px" height="28px"/>
        </span>
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-between diary-truncate">
        <div class="pointer diary-truncate text-truncate" (click)="openItineraryAgendaDetail(it)">
          <strong>{{it.number_plate}}</strong>,
          <span>{{it.vehicle.driver_name}}</span>,
          <strong>{{it.order_comp_book_name}}</strong>
        </div>
        <div (click)="openMessages(it)">
          <img src="assets/icons-new/message.svg" class="pointer" height="18px"
          ngbTooltip="Zpráva řidiči" container="body" placement="left auto" i18n-ngbTooltip/>
        </div>
      </div>
      <div class="pointer diary-truncate text-truncate" (click)="openItineraryAgendaDetail(it)">
        {{it.address}}
      </div>
      <div *ngIf="it.isDone" class="d-flex justify-content-between diary-truncate">
        <div class="pointer text-truncate" (click)="openItineraryAgendaDetail(it)">
          {{it.weightServiceEvent}},
          {{it.lengthMetersServiceEvent}},
          {{it.containersOutServiceEvent}}/{{it.containersInServiceEvent}}
          {{it.ware_type ? it.ware_type : 'PAL'}}
          {{it.note ? ',' + it.note : ''}}
        </div>

        <div *ngIf="it.type == ItineraryType.UNLOADING" class="text-nowrap">
          <div *ngIf="it.agenda?.loadingFiles" class="spinner-grow text-primary" 
          style="width:16px; height:16px;" role="status">
            <span class="sr-only"></span>
          </div>

          <ng-container *ngIf="!it.agenda.loadingFiles">
            <span *ngIf="!it.agenda.files.length" class="badge badge-dark pointer" 
            ngbTooltip="Žádné přiložené soubory" container="body" placement="auto" i18n-ngbTooltip>
              0
            </span>
            
            <span *ngIf="it.agenda.files.length" 
              class="badge badge-dark pointer"
              [ngbPopover]="filesPopover" [ngbTooltip]="filesTooltip"
              [autoClose]="'outside'" placement="left auto" container="body"
              (click)="downloadThumbnails(it.agenda);">
              {{it.agenda.files.length}}
            </span>

            <ng-template #filesTooltip>
              <ng-container i18n>Počet přiložených souborů</ng-container>: {{it.agenda.files.length}}
              <br>ZA{{it.agenda.order_number}}
            </ng-template>
  
            <ng-template #filesPopover>
              <div *ngIf="it.agenda.files.length">
                <h6 class="normal-font-size">
                  <ng-container i18n>Přiložené soubory</ng-container> - ZA{{it.agenda.order_number}}
                </h6>
                <h6 *ngIf="downloadingFile" class="text-primary normal-font-size text-truncate d-flex">
                  <div class="mr-2" i18n>Otevírám zvolený soubor...</div>
                  <div class="spinner-wrapper">
                    <div class="spinner-border text-primary" style="width:1rem; height:1rem;" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </h6>
  
                <div class="row p-1" [style.width]="widthPopoverOfFiles(it.agenda)">
                  <div *ngFor="let file of it.agenda.files" class="p-0 px-2 mb-2 position-relative" style="width:130px">
                    <!-- loading thumbnail -->
                    <div *ngIf="file.loadingThumbnail" class="w-100 text-center">
                      <div class="spinner-wrapper">
                        <div class="spinner-border text-primary" style="width:1rem; height:1rem;" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    </div>
                    <!-- thumbnail loaded -->
                    <div *ngIf="!file.loadingThumbnail">
                      <a (click)="downloadAttachment(file)" href="javascript:void(0)">
                        <div *ngIf="file.thumbnail" class="thumbnail border rounded shadow" [ngbTooltip]="file.name">
                          <img class="thumbnail" [src]="file.thumbnail.content | safeResource" alt="Náhled"/>
                        </div>
                        <div *ngIf="!file.thumbnail" class="thumbnail border rounded shadow" [ngbTooltip]="file.name">
                          <img class="thumbnail" src="assets/icons-new/folder_document.svg" alt="Náhled"/>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <span *ngIf="it.agenda.files.length && !it.agenda.email_headers.length" 
            class="pointer ml-1" (click)="agendaToSend = it.agenda"
            ngbTooltip="Odeslat dokumenty" container="body" placement="left auto" i18n-ngbTooltip>
              <img src="assets/icons-new/mail.svg" height="18px"/>
            </span>
  
            <span *ngIf="it.agenda.files.length && it.agenda.email_headers.length" 
            (click)="agendaToSend = it.agenda"
            class="pointer ml-1" container="body" placement="left auto"
            [ngbPopover]="emailsInfoPopover" ngbTooltip="Dokumenty odeslány" i18n-ngbTooltip>
              <img src="assets/icons-new/mail-green.svg" height="18px"/>
            </span>
  
            <ng-template #emailsInfoPopover>
              <div *ngIf="it.agenda.email_headers?.length">
                <div *ngFor="let header of it.agenda.email_headers; let i = index">
                  <a href="javascript:void(0)" (click)="showEmailDetail(header.email_msg_id)">
                    {{i+1}}) 
                    <ng-container i18n>Email odeslán</ng-container> - 
                    {{header.sent_date_object ? (header.sent_date_object | date: 'dd.MM.yyyy HH:mm') : ''}}
                  </a>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  
  <!-- possible destination route row -->
  <div *ngIf="it.agenda.vehicle.temporaryManualTrackingTarget" class="col-12 no-padding">
    <div *ngIf="isTargetForItinerary(it)">
      <vehicle-manual-tracking-result [vehicle]="it.agenda.vehicle">
      </vehicle-manual-tracking-result>
    </div>
  </div>

  <!-- details -->
  <div class="w-100">
    <!-- agenda detail -->
    <div class="agenda-detail" 
      *ngIf="itineraryAgendaDetail === it && it.agenda" 
      [agenda]="it.agenda">
    </div>

    <!-- map -->
    <div *ngIf="currentPositionDetailItinerary === it && it.agenda && it.agenda.vehicle">
      <r-vehicle-manual-tracking-itinerary 
        [itinerary]="it"
        [vehicle]="it.agenda.vehicle"
        (hideMap)="hideMapEvent($event)">
      </r-vehicle-manual-tracking-itinerary>
    </div>

    <!-- messages -->
    <div *ngIf="itineraryMessages === it && it.agenda" class="p-relative" style="height: 400px;">
      <div style="height: 300px; overflow-y: auto;">
        <vehicle-messages-list [vehicle]="it.agenda.vehicle">
        </vehicle-messages-list>
      </div>
      <div style="height: 100px">                     
        <message-write 
          [vehicle]="it.agenda.vehicle" 
          [messagesPatterns]="itineraryMessagePatterns">
        </message-write>
      </div>
    </div>
  </div>
</ng-template>

<!-- modal for r-diary-mobile -->
<div class="modal fade" role="dialog" tabindex="-1"  id="mobileModal">
  <div class="modal-dialog modal-dialog"  role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
          <h4 class="modal-title" i18n>Náhled na mobilu</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div *ngIf="itinerary && itinerary.byDay && selectedDayItinerary && selectedVehicle">
          <app-r-diary-mobile
            [data]="selectedDayItinerary"
            [vehicle]="selectedVehicle">
          </app-r-diary-mobile>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal for sending attachments via email -->
<div class="modal fade" role="dialog" tabindex="-1" id="sendAttachmentsModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <div *ngIf="agendaToSend">
          <h4 class="modal-title">
            <ng-container i18n>Odeslat doklady</ng-container> - {{agendaToSend.order_number}}
          </h4>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="agendaToSend">
          <app-ta1-obligation-emailing #myEmailingChild
          [agenda]="agendaToSend"
          (emailHasBeenSent)="eventAfterEmailSent($event)"
          [attachments]="attachmentsToSend">
          </app-ta1-obligation-emailing>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal for creating service event of loading/unloading via api -->
<div class="modal fade" role="dialog" tabindex="-1" id="agendaServiceModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <div class="modal-title">
          <h4>
            <ng-container i18n>Dokončení</ng-container>
            {{itineraryToEvent?.type == ItineraryType.LOADING ? 'nakládky' : 'vykládky'}}
            <ng-container i18n>pomocí</ng-container> TAgendy
          </h4>
          <h6 *ngIf="itineraryToEvent" class="text-center my-1">
            {{itineraryToEvent.address}}
          </h6>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body bg-secondary-dark itinerary-complete">
        <div *ngIf="newServiceEvent && itineraryToEvent" 
        class="container-fluid bg-secondary-dark py-3 h-100" style="min-height: 600px;">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-1">
                <div class="form-group row">
                  <label class="col-6 text-white" i18n>Zakázka</label>
                  <div class="col-6">
                    <input id="order_number_input" 
                    class="form-control text-white bg-secondary-dark" 
                    type="text" placeholder="Číslo zakázky" disabled
                    [ngModel]="newServiceEvent.order_number" i18n-placeholder/>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-1 pt-3 border-top">
                <div class="form-group row">
                  <label class="col-6 text-white">
                    <ng-container i18n>Hmotnost</ng-container> (t)
                  </label>
                  <div class="col-6">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="number" placeholder="0" min="0" max="100" [(ngModel)]="newServiceEvent.weight">
                  </div>
                </div>
              </div>
              <div class="col-12 mb-1">
                <div class="form-group row">
                  <label class="col-6 text-white" i18n>Datum a čas</label>
                  <div class="col-6">
                    <input id="time_input" class="form-control text-white bg-secondary-dark" 
                    type="datetime-local" [(ngModel)]="newServiceEvent.time_custom"/>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-1">
                <div class="form-group row">
                  <label class="col-6 text-white">
                    <ng-container i18n>Ložná délka</ng-container> (m)
                  </label>
                  <div class="col-6">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="number" placeholder="0" min="0" max="100" [(ngModel)]="newServiceEvent.length"/>
                  </div>
                </div>
              </div>
              
              <h5 class="col-12 text-white text-center pt-2 border-top" i18n>Obaly / palety</h5>

              <div class="col-12 mb-1">
                <div class="form-group row">
                  <label class="col-6 text-white" i18n>Odevzdal prázdné</label>
                  <div class="col-6">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="number" placeholder="0" [(ngModel)]="newServiceEvent.containers_out"/>
                  </div>
                </div>
              </div>
              <div class="col-12 pb-3">
                <div class="form-group row">
                  <label class="col-6 text-white" i18n>Naložil s nákl.</label>
                  <div class="col-6">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="number" placeholder="0" [(ngModel)]="newServiceEvent.containers_in"/>
                  </div>
                </div>
              </div>

              <div class="col-12 pt-2 border-top">
                <div class="form-group row">
                  <h5 class="col-12 text-white text-center" i18n>Poznámka</h5>
                  <div class="col-12">
                    <textarea class="form-control text-white bg-secondary-dark" 
                    placeholder="Text poznámky až 100 znaků..." style="height: 80px"
                    [(ngModel)]="newServiceEvent.descr" i18n-placeholder></textarea>
                  </div>
                </div>
              </div>
            </div>
          
            <div class="row my-4">
              <div class="col-12 mb-3">
                <button class="btn btn-primary w-100" type="button" (click)="createNewServiceEvent()">
                  <ng-container i18n>Potvrdit dokončení</ng-container>
                </button>
              </div>
              <div class="col-6 mb-2">
                <button class="btn btn-warning w-100" type="button" disabled>
                  <ng-container i18n>Naviguj</ng-container>
                </button>
              </div>
              <div class="col-6">
                <button class="btn btn-secondary w-100" type="button" disabled>
                  <ng-container i18n>Dokumenty</ng-container>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal for sending report to VISCOFAN -->
<div class="modal fade" role="dialog" tabindex="-1" id="viscofanModal">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title" i18n>Viscofan incident</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">x</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label i18n>Typ události</label>
          <select class="form-control" [(ngModel)]="viscofanReportType">
            <option [ngValue]="null" i18n>Zvolte</option>
            <option *ngFor="let type of VISCOFAN_REPORT_TYPES" [value]="type">
              {{type}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label i18n>Zpráva</label>
          <textarea class="form-control" placeholder="Zadejte vaši zprávu o události"
          [(ngModel)]="viscofanMessage" i18n-placeholder></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" type="button" [disabled]="!viscofanReportType"
        (click)="sendReportToViscofan()">
          <ng-container i18n>Odeslat</ng-container>
        </button>
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>


<!-- modal for creating service event of loading/unloading via api -->
<!-- <div class="modal fade" role="dialog" tabindex="-1" id="agendaServiceModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title">Ruční vytvoření nakl/vykl pomocí TruckAgendy</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body bg-secondary-dark">
        <div *ngIf="newServiceEvent" class="container rounded bg-secondary-dark">
          <h4 class="text-white my-2">
            {{newServiceEvent.type == ItineraryType.LOADING ? 'Nakládka' : 'Vykládka'}}
          </h4>
          
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white">Zakázka</label>
                <input class="form-control text-white bg-secondary-dark" type="text"
                [ngModel]="newServiceEvent.order_number" disabled/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white">Vozidlo</label>
                <input class="form-control text-white bg-secondary-dark" type="text"
                [ngModel]="newServiceEvent.car_key" disabled/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="text-white">Datum a čas</label>
                <input class="form-control text-white bg-secondary-dark" type="datetime-local"
                [(ngModel)]="newServiceEvent.time_custom"/>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white">Hmotnost</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.weight"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white">Ložná délka</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.length"/>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white">Odevzdal prázdné (obaly/palety)</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.containers_out"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="text-white">Naložil s nákladem (obaly/palety)</label>
                <input class="form-control text-white bg-secondary-dark" type="number"
                [(ngModel)]="newServiceEvent.containers_in"/>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="text-white">Poznámka</label>
                <textarea class="form-control text-white bg-secondary-dark" 
                placeholder="Poznámka až 100 znaků" [(ngModel)]="newServiceEvent.descr">
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-secondary-dark">
        <button class="btn btn-light" type="button" data-dismiss="modal">Zavřít</button>
        <button class="btn btn-success" type="button" data-dismiss="modal"
        (click)="createNewServiceEvent()">Vytvořit</button>
      </div>
    </div>
  </div>
</div> -->
