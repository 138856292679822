import { GeoPosition } from "./geo-position.object";
import { Vehicle } from "./vehicle.object";
import { DateTools } from "../tools/DateTools";
import { VehicleTA } from "./vehicle-ta.object";
import { VehicleTM } from "./vehicle-tm.object";

export class CargoActivity {

  private _arrival_time: Date;
  get arrival_time(): Date {
    return this._arrival_time;
  }
  set arrival_time(value) {
    if (value) {
      this._arrival_time = DateTools.isoFix(value.toString());
    }
  }

  private _arrival_time_max: Date;
  get arrival_time_max(): Date {
    return this._arrival_time_max;
  }
  set arrival_time_max(value: Date) {
    if (value) {
      this._arrival_time_max = DateTools.isoFix(value.toString());
    }
  }

  private _city_country: string;
  get city_country(): string {
    return this._city_country;
  }
  set city_country(value: string) {
    this._city_country = value;
  }

  private _city_gps: GeoPosition;
  get city_gps(): GeoPosition {
    return this._city_gps;
  }
  set city_gps(value: GeoPosition) {
    this._city_gps = value;
  }

  private _code: string;
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }

  private _descr: string;
  get descr(): string {
    return this._descr;
  }
  set descr(value: string) {
    this._descr = value;
  }

  private _event_took: number;
  get event_took(): number {
    return this._event_took;
  }
  set event_took(value: number) {
    if (!value) {
      this._event_took = null;
    }
    this._event_took = value;
  }

  private _length: number;
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
  }

  private _order_company: string;
  get order_company(): string {
    return this._order_company;
  }
  set order_company(value: string) {
    this._order_company = value;
  }

  private _order_customer: string;
  get order_customer(): string {
    return this._order_customer;
  }
  set order_customer(value: string) {
    this._order_customer = value;
  }

  private _order_number: string;
  get order_number(): string {
    return this._order_number;
  }
  set order_number(value: string) {
    this._order_number = value;
  }

  private _service_key: number;
  get service_key(): number {
    return this._service_key;
  }
  set service_key(value: number) {
    this._service_key = value;
  }

  private _time: Date;
  get time(): Date {
    return this._time;
  }
  set time(value) {
    if (value) {
      this._time = DateTools.isoFix(value.toString());
    }
  }

  private _time_start: Date;
  get time_start(): Date {
    return this._time_start;
  }
  set time_start(value) {
    if (!value) {
      this._time_start = null;
    } else {
      this._time_start = DateTools.isoFix(value.toString());
    }
  }

  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }

  private _weight: number;
  get weight(): number {
    return this._weight;
  }
  set weight(value: number) {
    this._weight = value;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  set vehicle(value: Vehicle) {
    this._vehicle = value;
  }

  get time_shortA(): string {
    return this.time.toDateString();
    //return CargoActivity.convertDateFormat(this.time.toDateString(), true)[0];
  }
  
  get time_shortB(): string {
    return this.time.toDateString();
    //return CargoActivity.convertDateFormat(this.time.toDateString(), true)[1];
  }

  private _event_took_parse_string: string = '--:--';
  public get event_took_parse_string(): string {
    return this._event_took_parse_string;
  }

  set_event_took_parse(): void {
    if (this.event_took) {
      this._event_took_parse_string = GeoPosition.parseMilisecondsToHHMMString(this._event_took * 1000);
    } 
    else if (!this.event_took &&
      this._vehicle &&
      this._vehicle.last_position &&
      this._vehicle.last_cargo_activity &&
      (this._vehicle.last_position.truck_status === Vehicle.STOP || this._vehicle.last_position.truck_status === Vehicle.SLEEP) &&
      (this._vehicle.last_cargo_activity.type === Vehicle.UNLOADING_START || this._vehicle.last_cargo_activity.type === Vehicle.LOADING_START)) {
      // this._event_took_parse_string = GeoPosition.parseMilisecondsToHHMMString(Date.now() - this._vehicle.last_position.time.getTime());
      this._event_took_parse_string = GeoPosition.parseMilisecondsToHHMMString(Date.now() - this._vehicle.last_cargo_activity.time.getTime());
    } 
    else {
      this._event_took_parse_string = '--:--';
    }
  }

  // get event_took_parse(): string {
  //   if (this.event_took) {
  //     return GeoPosition.parseMilisecondsToHHMMString(this._event_took * 1000);
  //   } else if (!this.event_took &&
  //     this._vehicle &&
  //     this._vehicle.last_position &&
  //     this._vehicle.last_cargo_activity &&
  //     this._vehicle.last_position.truck_status === Vehicle.STOP &&
  //     (
  //       this._vehicle.last_cargo_activity.type === Vehicle.UNLOADING_START ||
  //       this._vehicle.last_cargo_activity.type === Vehicle.LOADING_START
  //     )) {
  //     return GeoPosition.parseMilisecondsToHHMMString(Date.now() - this._vehicle.last_position.time.getTime());
  //   } else {
  //     return '--:--';
  //   }
  // }

  get delayed_arrival_diff(): number {
    if (this.time_start && this.arrival_time) {
      return this.time_start.getTime() - this.arrival_time.getTime();
    } else {
      return 0;
    }
  }

  get delayed_arrival(): string {
    if (this.time_start && this.arrival_time) {
      let diff = this.delayed_arrival_diff;
      if (diff < 0) {
        return GeoPosition.parseMilisecondsToHHMMString(diff);
      } else {
        return '+' + GeoPosition.parseMilisecondsToHHMMString(diff);
      }
    } else if (this.arrival_time &&
      !this.time_start &&
      this._vehicle &&
      this._vehicle.last_position &&
      this._vehicle.last_cargo_activity &&
      this._vehicle.last_position.truck_status === Vehicle.STOP && // pridat SLEEP podminku i sem?
      (
        this._vehicle.last_cargo_activity.type === Vehicle.UNLOADING_START ||
        this._vehicle.last_cargo_activity.type === Vehicle.LOADING_START
      )) {
      return GeoPosition.parseMilisecondsToHHMMString(Date.now() - this.arrival_time.getTime());
    } else {
      return '--:--';
    }
  }

  get city_country_short(): string {
    return this._city_country.substring(0, 2);
  }

  get city_zip_short(): string {
    return this._city_gps.city_zip_short;
  }

  static convertDateFormat(dateStr: string, dateTimeSepare?: Boolean): Array<String> | String {
    var dF = "?";
    var dA = [];
    if (dateStr) {
      var dateTimeArr = dateStr.split("T");
      var date = dateTimeArr[0];
      var dateParts = date.split("-");
      var time = dateTimeArr[1];
      var timeParts = time.split(":");

      dA.push(dateParts[2] + "." + dateParts[1]);
      dA.push(timeParts[0] + ":" + timeParts[1]);

    }
    if (true === dateTimeSepare) {
      return dA;
    } else {
      return dA.join(" ");
    }
  }
}