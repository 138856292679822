import {GeoPosition} from "./geo-position.object";
import {HaversinePositionArgInterface} from "../tools/HaversinePositionArgInterface";

declare var google: any;

export class GoogleLatLng {

  constructor(
    private _lat: number, 
    private _lng: number
  ) {
  }

  get lat(): number {
    return this._lat;
  }

  get lng(): number {
    return this._lng;
  }

  get createString(): string {
    return '(' + this._lat + ',' + this._lng + ')';
  }

  get googleLtLn(): any {
    if (isNaN(this.lat) || isNaN(this.lng)) {
      console.error('UNDEFINEd', this.lat, this.lng);
    }
    return new google.maps.LatLng(this.lat, this.lng);
  }

  static createFromPosString(pos: string): GoogleLatLng {
    let data = GeoPosition.parseToLocationArray(pos);
    return new GoogleLatLng(
      data[0],
      data[1]
    );
  }

  toArray(): Array<number> {
    return [
      this.lat,
      this.lng
    ];
  }

  toString(): string {
    return this.lat + ',' + this.lng;
  }

  toHaversine(): HaversinePositionArgInterface {
    return {
      latitude: this.lat,
      longitude: this.lng
    };
  }
}
