import { CompanyContact } from "./company-contact.object";
import { Driver } from "./driver.object";
import { Person } from "./person.object";
import { SimCard } from "./simcard.object";
import { UserAccount } from "./user-account.object";

export class Company {
  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  private _account: string;
  public get account(): string {
    return this._account;
  }
  public set account(value: string) {
    this._account = value;
  }
  
  private _bank: string;
  public get bank(): string {
    return this._bank;
  }
  public set bank(value: string) {
    this._bank = value;
  }
  
  private _company: string;
  public get company(): string {
    return this._company;
  }
  public set company(value: string) {
    this._company = value;
  }
  
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _cin: string;
  public get cin(): string {
    return this._cin;
  }
  public set cin(value: string) {
    this._cin = value;
  }

  private _city: string;
  public get city(): string {
    return this._city;
  }
  public set city(value: string) {
    this._city = value;
  }

  private _country: string;
  public get country(): string {
    return this._country;
  }
  public set country(value: string) {
    this._country = value;
  }
  
  private _geo_pos: string;
  public get geo_pos(): string {
    return this._geo_pos;
  }
  public set geo_pos(value: string) {
    this._geo_pos = value;
  }

  private _last_modif: string;
  public get last_modif(): string {
    return this._last_modif;
  }
  public set last_modif(value: string) {
    this._last_modif = value;
  }

  private _near_city_key: string;
  public get near_city_key(): string {
    return this._near_city_key;
  }
  public set near_city_key(value: string) {
    this._near_city_key = value;
  }
  
  private _near_city: any;
  public get near_city(): any {
    return this._near_city;
  }
  public set near_city(value: any) {
    this._near_city = value;
  }
  
  private _region: string;
  public get region(): string {
    return this._region;
  }
  public set region(value: string) {
    this._region = value;
  }
  
  private _send_emails_from_their_domain: boolean = false;
  public get send_emails_from_their_domain(): boolean {
    return this._send_emails_from_their_domain;
  }
  public set send_emails_from_their_domain(value: boolean) {
    this._send_emails_from_their_domain = value;
  }

  private _street: string;
  public get street(): string {
    return this._street;
  }
  public set street(value: string) {
    this._street = value;
  }

  private _tariff: string;
  public get tariff(): string {
    return this._tariff;
  }
  public set tariff(value: string) {
    this._tariff = value;
  }
  
  private _tin: string;
  public get tin(): string {
    return this._tin;
  }
  public set tin(value: string) {
    this._tin = value;
  }

  private _zip: string;
  public get zip(): string {
    return this._zip;
  }
  public set zip(value: string) {
    this._zip = value;
  }

  // private _near_city: any;
  // NOT TYPED objects!
  private _invoice_address: any = {
    company: "",
    street: "",
    city: "",
    zip: "",
    country: ""
  };
  public get invoice_address(): any {
    if (!this._invoice_address || !Object.keys(this._invoice_address).length) {
      return {
        company: "",
        street: "",
        city: "",
        zip: "",
        country: ""
      };
    }
    return this._invoice_address;
  }
  public set invoice_address(value: any) {
    this._invoice_address = value;
  }

  private _contacts: any = {};
  public get contacts(): any {
    return this._contacts;
  }
  public set contacts(value: any) {
    this._contacts = value;
  }

  private _contactsArray: Array<CompanyContact> = [];
  public get contactsArray(): Array<CompanyContact> {
    return this._contactsArray;
  }
  public set contactsArray(value: Array<CompanyContact>) {
    this._contactsArray = value;
  }

  private _persons: any = {};
  public get persons(): any {
    return this._persons;
  }
  public set persons(value: any) {
    this._persons = value;
  }

  private _personsArray: Array<Person> = [];
  public get personsArray(): Array<Person> {
    return this._personsArray;
  }
  public set personsArray(value: Array<Person>) {
    this._personsArray = value;
  }

  private _drivers: any = {};
  public get drivers(): any {
    return this._drivers;
  }
  public set drivers(value: any) {
    this._drivers = value;
  }
  
  private _driversArray: Array<Driver> = [];
  public get driversArray(): Array<Driver> {
    return this._driversArray;
  }
  public set driversArray(value: Array<Driver>) {
    this._driversArray = value;
  }

  private _sim_cards: any = {};
  public get sim_cards(): any {
    return this._sim_cards;
  }
  public set sim_cards(value: any) {
    this._sim_cards = value;
  }
  
  private _simcardsArray: Array<SimCard> = [];
  public get simcardsArray(): Array<SimCard> {
    return this._simcardsArray;
  }
  public set simcardsArray(value: Array<SimCard>) {
    this._simcardsArray = value;
  }

  private _user_accounts: any = {};
  public get user_accounts(): any {
    return this._user_accounts;
  }
  public set user_accounts(value: any) {
    this._user_accounts = value;
  }
  
  private _userAccountsArray: Array<UserAccount> = [];
  public get userAccountsArray(): Array<UserAccount> {
    return this._userAccountsArray;
  }
  public set userAccountsArray(value: Array<UserAccount>) {
    this._userAccountsArray = value;
  }

  // return contacts as array of objects
  public get contactsAsArray(): Array<any> {
    return Object.values(this._contacts);
  }

  // return persons as array of objects
  public get personsAsArray(): Array<any> {
    return Object.values(this._persons);
  }
  
  // return persons as array of objects
  public get usersAsArray(): Array<any> {
    return Object.values(this._user_accounts);
  }

  
  // these might be missing in current api version
  // private _install_number: number;    
  // public get install_number(): number {
  //   return this._install_number;
  // }
  // public set install_number(value: number) {
  //   this._install_number = value;
  // }

  // private _provider: string;
  // public get provider(): string {
  //   return this._provider;
  // }
  // public set provider(value: string) {
  //   this._provider = value;
  // }

  // private _key_provider: string;
  // public get key_provider(): string {
  //   return this._key_provider;
  // }
  // public set key_provider(value: string) {
  //   this._key_provider = value;
  // }
  
  // private _parent_key: string;
  // public get parent_key(): string {
  //   return this._parent_key;
  // }
  // public set parent_key(value: string) {
  //   this._parent_key = value;
  // }
  
  // private _branch: string;
  // public get branch(): string {
  //   return this._branch;
  // }
  // public set branch(value: string) {
  //   this._branch = value;
  // }
}
