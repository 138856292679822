import {ArrayCompare} from "./ArrayCompare";

export class ObjectCompare {

  public static haveSameContent(data1: Object, data2: Object): boolean {
    for (let key in data1) {
      let same: boolean = false;
      if (typeof data2[key] === 'undefined' || typeof data1[key] !== typeof data2[key]) {
        return false;
      }
      if (data1[key] instanceof Object) {
        same = ObjectCompare.haveSameContent(data1[key], data2[key]);
      } else if (data1[key] instanceof Array) {
        same = ArrayCompare.haveSameContent(data1[key], data2[key]);
      } else {
        same = data1[key] === data2[key];
      }
      if (!same) {
        return false;
      }
    }
    return true;
  }

}