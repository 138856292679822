<!-- TODO Vymyslet jinak to otevirani modalu, 
jelikoz se tam tlucou ID a zustava tam jen ten prvni otevreny -->

<ng-container *ngIf="loadingAttachment">
  <div class="spinner-wrapper pt-3 pb-2">
    <div class="spinner-border text-warning" style="width:1.6rem; height:1.6rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="text-nowrap"><ng-container i18n>Stahování</ng-container>...</div>
</ng-container>

<ng-container *ngIf="attachment.isInvoiceAttachment">
  <img class="pointer" src="assets/icons-new/folder_document.svg" height="40px"
  (click)="downloadAttachment(); openModal();"
  [ngbTooltip]="attachment.fileName" container="body" placement="left auto"/>
</ng-container>

<ng-container *ngIf="attachment?.isImage() && attachment.thumbnail && !loadingAttachment">
  <div #attachmentDiv>
    <img class="pointer shadow rounded" [src]="attachment.thumbnail.content | safeResource" 
    [alt]="attachment.fileName" (click)="getSource(); openModal()" [style.width]="attachmentWidth"
    [ngbTooltip]="attachment.fileName" container="body" placement="left auto"/>
  </div>
</ng-container>

<ng-container *ngIf="attachment?.isDocument() && !loadingAttachment && !isRoutingPoint">
  <a style="display:block" class="text-center pointer" 
  [ngbTooltip]="attachment.fileName" container="body" placement="left auto" 
  (click)="openModal()" href="javascript:void(0)">
    <img src="assets/icons-new/document_orientation_portrait.svg" 
    alt="document_orientation_portrait" height="20px"/>
  </a>
</ng-container>

<ng-container *ngIf="(attachment?.isAudio() || attachment?.isVideo()) && !loadingAttachment">
  <a class="pointer" (click)="openModal()" href="javascript:void(0)">
    <img src="assets/icons-new/play-circle.svg" alt="play-circle" height="20px"/>
  </a>
</ng-container>

<ng-container *ngIf="attachment?.isUnsupported() && source && !loadingAttachment">
  <a style="display:block" class="text-center" [href]="source|safeResource" 
  placement="bottom" [ngbTooltip]="attachment.fileName" container="body" placement="bottom auto">
    <img src="assets/icons-new/download.svg" alt="download" height="20px"/>
  </a>
</ng-container>


<div class="modal fade" [id]="'attachmentModal' + msg_key" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <span *ngIf="attachment" class="mr-4">{{attachment.fileName}}</span>
          <ng-container *ngIf="attachment?.isImage() && !attachment.isInvoiceAttachment">
            <img src="assets/icons-new/undo.svg" alt="turnLeft" height="28px" 
            (click)="turnLeft()" class="pointer" 
            ngbTooltip="Otočit o 90° doleva" i18n-ngbTooltip placement="bottom auto" container="body"/>
            
            <img src="assets/icons-new/redo.svg" alt="turnRight" height="28px" 
            (click)="turnRight()" class="pointer" 
            ngbTooltip="Otočit o 90° doprava" i18n-ngbTooltip placement="bottom auto" container="body"/>
            
            <a *ngIf="attachment?.isImage()" target="_blank" [href]="source|safeResource" class="ml-3">
              <img src="assets/icons-new/download.svg" alt="download" height="28px"
              ngbTooltip="Kliknutím na toto tlačítko lze obrázek otevřít do nové karty"
              i18n-ngbTooltip/>
            </a>
          </ng-container>
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="loadingAttachment">
          <div class="spinner-wrapper pt-3 pb-2">
            <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
          <div class="text-nowrap"><ng-container i18n>Stahování</ng-container>...</div>
        </ng-container>

        <ng-container *ngIf="attachment.isInvoiceAttachment && currentPdf">
          <!-- prostor pro zobrazeni prilohy -->
          <div *ngIf="currentPdf">
            <div style="height:65vh;">
              <object class="full fill" 
                [data]="currentPdf.content | safeResource" 
                [type]="currentPdf.type">
              </object>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="attachment?.isImage() && source && !attachment.isInvoiceAttachment">
          <a target="_blank" [href]="source|safeResource">
            <img [style.transform]="imageRotation|safeStyle" style="max-height: calc(100vh - 210px);" 
            [src]="source|safeResource" [alt]="attachment.fileName" />
          </a>
        </ng-container>
        <video class="col-12" *ngIf="attachment?.isVideo() && source" width="320" height="240" controls>
          <source [src]="source|safeResource" 
          [type]="attachment.attachmentType + (attachment.attachmentType === 'video/3gpp' ? '; codecs=\'mp4v.20.8, samr\'' : '')">
          <ng-container i18n>Prohlížeč nepodporuje přehrání videa</ng-container>
        </video>
        <audio class="col-12" *ngIf="attachment?.isAudio() && source" controls>
          <source [src]="source|safeResource" [type]="attachment.attachmentType">
          <ng-container i18n>Prohlížeč nepodporuje přehrání audia</ng-container>
        </audio>
        <object *ngIf="attachment?.isDocument() && source" class="full fill document" 
        [data]="source|safeResource" type="application/pdf">
        </object>
      </div>
    </div>
  </div>
</div>