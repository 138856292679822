<!-- Hlavni menu -->
<nav id="menuLeft" class="navbar navbar-expand-lg navbar-dark bg-dark py-1">
  <!-- navbar toggler icon pro male a stredni displeje -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContentMain" 
  aria-controls="navbarSupportedContentMain" aria-expanded="false" (click)="closeDropdowns()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContentMain">
    <ul id="hlavniMenu-tabs" role="tablist" class="navbar-nav top-level-menu">
      <!-- logo -->
      <li class="nav-item">
        <a class="nav-link py-1 icon-menu-parent" target="_blank" href="https://www.truckmanager.eu/">
          <img id="leftLogoTm" src="assets/images/logoTm.png" />
        </a>
      </li>

      <!-- truck manager -->
      <li class="nav-item dropdown {{isManagerActive ? 'active' : 'not-active'}}"
      (mouseover)="setManagerHover(true)" (mouseleave)="setManagerHover(false)">
        <a class="nav-link py-1 dropdown-toggle icon-menu-parent pointer" (click)="toggleManager()">
          <div class="icon-menu">
            <img [src]="managerSrc" height="28px">
          </div>
          <span class="nav-font-size">T<span style="color:#de8d00">M</span>anager</span>
        </a>
        <ul class="dropdown-menu second-level-menu bg-dark" [class.second-level-menu-show]="managerHover">
          <a *ngIf="user?.user_account?.historyAllow"
          class="dropdown-item" [class.active]="isStatisticsActive" (click)="routeAndClose('tm/statistics')">
            <img [src]="statisticsSrc" class="mr-2" width="28px">
            <span i18n>Historie pohybu</span>
          </a>
          <a *ngIf="user?.user_account?.statementsAllow"
          class="dropdown-item" [class.active]="isCarbookActive" (click)="routeAndClose('tm/carbook')">
            <img [src]="carbookSrc" class="mr-2" width="28px">                        
            <span i18n>Výkazy a sestavy</span>
          </a>
          <a *ngIf="user?.user_account?.servicesAllow"
          class="dropdown-item" [class.active]="isServicesActive" (click)="routeAndClose('tm/services')">
            <img [src]="servicesSrc" class="mr-2" width="28px">
            <span i18n>Servis/údržba vozidel</span>
          </a>
          <a *ngIf="user?.user_account?.carsAllow"
          class="dropdown-item" [class.active]="isMycarsActive" (click)="routeAndClose('tm/mycars')">
            <img [src]="mycarsSrc" class="mr-2" width="28px">
            <span i18n>Moje Auta - nastavení</span>
          </a>
        </ul>
      </li>
  
      <!-- truck agenda -->
      <li class="nav-item dropdown {{isAgendaActive ? 'active' : 'not-active'}}" 
      (mouseover)="setAgendaHover(true)" (mouseleave)="setAgendaHover(false)">
        <a #agenda class="nav-link py-1 icon-menu-parent dropdown-toggle pointer" (click)="toggleAgenda(); scroll(agenda)">
          <div class="icon-menu">
            <img [src]="agendaSrc" height="28px">
          </div>
          <span class="nav-font-size">T<span style="color:#de8d00">A</span>genda</span>
        </a>
        <ul class="dropdown-menu bg-dark second-level-menu" [class.second-level-menu-show]="agendaHover">
          <!-- zakazky -->
          <li class="dropdown-submenu" (mouseover)="setAgenda1Hover(true)" (mouseleave)="setAgenda1Hover(false)">
            <a #agenda1 class="dropdown-item dropdown-toggle pointer {{isZakazkyActive ? 'active' : 'not-active'}}" 
            (click)="toggleAgenda1(); scroll(agenda1)">
              <span i18n>Zakázky</span>
            </a>
            <ul class="dropdown-menu bg-dark third-level-menu" [class.third-level-menu-show]="agenda1Hover">
              <li *ngIf="user?.user_account?.obligationRead">
                <a class="dropdown-item" [class.active]="isObligationListActive" 
                (click)="routeAndClose('ta1-obligation/obligation_list')">
                  <img [src]="obligation_listSrc" class="mr-2" width="28px"/> 
                  <span i18n>Zakázky</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.obligationRead">
                <a class="dropdown-item" [class.active]="isCompanyObligationActive" 
                (click)="routeCloseInitNew('ta1-obligation/company_obligation')">
                  <img [src]="company_obligationSrc" class="mr-2" width="28px"/>
                  <span i18n>Nová zakázka</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.obligationRead">
                <a class="dropdown-item" [class.active]="isExpressListActive" 
                (click)="routeCloseInitNew('ta1-obligation/express_list')">
                  <img [src]="express_listSrc" class="mr-2" width="28px"/>
                  <span i18n>Dokládky (LTL)</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.obligationRead">
                <a class="dropdown-item" [class.active]="isWarehousePlanActive" 
                (click)="routeAndClose('ta1-other/warehouse-plan')">
                  <img [src]="warehousePlanSrc" class="mr-2" width="28px"/>
                  <span i18n>Plán rozvozu</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.obligationRead">
                <a class="dropdown-item" [class.active]="isWarehouseControlActive" 
                (click)="routeAndClose('ta1-other/warehouse-control')">
                  <img [src]="warehouseControlSrc" class="mr-2" width="28px"/>
                  <span i18n>Sklad</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.orderRead">
                <a class="dropdown-item" [class.active]="isOrderListActive" 
                (click)="routeAndClose('ta1-order/order_list')">
                  <img [src]="order_listSrc" class="mr-2" width="28px"/>
                  <span i18n>Objednávky</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.statisticsSalesProfit">
                <a class="dropdown-item" [class.active]="isObligationSalesActive" 
                (click)="routeAndClose('ta1-obligation/obligation_sales')">
                  <img [src]="obligation_salesSrc" class="mr-2" width="28px"/>
                  <span i18n>Tržby a zisk</span>    
                </a>
              </li>
              <li>
                <a class="dropdown-item" [class.active]="isFuelingStatsActive" 
                (click)="routeAndClose('ta1-other/fueling_stats')">
                  <img [src]="fuelingStatsSrc" class="mr-2" width="28px"/>
                  <span i18n>Vyhodnocení PHM</span>    
                </a>
              </li>
              <li *ngIf="user?.user_account?.statisticsOverviewRankings">
                <a class="dropdown-item" (click)="routeAndClose()" 
                target="_blank" [href]="staticContentResource + dataOverview">
                  <img src="assets/icons-new/data_table-gray.svg" class="mr-2" width="28px"/>
                  <span i18n>Přehledy a žebříčky</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.obligationRead">
                <a class="dropdown-item" (click)="routeAndClose()" 
                target="_blank" [routerLink]="{outlets: {left:'dispatcher-board'}}">
                  <img src="assets/icons-new/table-gray.svg" class="mr-2" width="28px"/>
                  <span i18n>Plachta dispečera</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" (click)="routeAndClose(); routeRightMenu('itinerary-diary')" 
                [routerLink]="{outlets: {right:'itinerary-dairy'}}">
                  <img src="assets/icons-new/diare-gray.svg" class="mr-2" width="28px"/>
                  <span i18n>Diář na(vy)kládek</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" (click)="routeAndClose()" 
                target="_blank" [href]="staticContentResource + calc">
                  <img src="assets/icons-new/calculator-gray.svg" class="mr-2" width="28px"/>
                  <span i18n>Kalkulace trasy</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [class.active]="isEmissionCalcActive"
                (click)="routeAndClose('ta1-other/emission-calc')">
                  <img [src]="calculatorSrc" class="mr-2" width="28px"/>
                  <span i18n>Emisní kalkulačka</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- adresar -->
          <li class="dropdown-submenu" (mouseover)="setAgenda2Hover(true)" (mouseleave)="setAgenda2Hover(false)">
            <a #agenda2 class="dropdown-item dropdown-toggle pointer {{isAdresarActive ? 'active' : 'not-active'}}"
            (click)="toggleAgenda2(); scroll(agenda2)">
              <span i18n>Adresář</span>
            </a>
            <ul class="dropdown-menu bg-dark third-level-menu" [class.third-level-menu-show]="agenda2Hover">
              <li>
                <a class="dropdown-item" [class.active]="isSearchActive" 
                (click)="routeAndClose('ta2/search')">
                  <img [src]="searchSrc" class="mr-2" width="28px">
                  <span i18n>Adresář</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [class.active]="isCompanyNewActive" 
                (click)="routeAndClose('ta2/company_new')">
                  <div class="d-inline-block mr-2" style="width:28px; min-height:1px"></div>
                  <span i18n>Nová firma</span>
                </a>
              </li>
              <!-- Hide import on production - users imported crazy things -->
              <li *ngIf="!isProduction">
                <a class="dropdown-item" [class.active]="isCompanyImportActive" 
                (click)="routeAndClose('ta2/company_import')">
                  <div class="d-inline-block mr-2" style="width:28px; min-height:1px"></div>  
                  <span i18n>Import</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- faktury -->
          <li class="dropdown-submenu" (mouseover)="setAgenda3Hover(true)" (mouseleave)="setAgenda3Hover(false)">
            <a #agenda3 class="dropdown-item dropdown-toggle pointer {{isFakturyActive ? 'active' : 'not-active'}}"
            (click)="toggleAgenda3(); scroll(agenda3)">
              <span i18n>Faktury</span>
            </a>
            <ul class="dropdown-menu bg-dark third-level-menu" [class.third-level-menu-show]="agenda3Hover">
              <li *ngIf="user?.user_account?.invoiceRead">
                <a class="dropdown-item" [class.active]="isInvoiceListActive" (click)="routeAndClose('ta3/invoice_list')">
                  <img [src]="invoice_listSrc" class="mr-2" width="28px">
                  <span i18n>Faktury</span>
                </a>
              </li>
              <li>
                <!-- <a class="dropdown-item" (click)="routeAndClose()" target="_blank" [href]="staticContentResource + _genOrder">
                  <img src="assets/icons-new/server_document-gray.svg" class="mr-2" width="28px">
                  <span>Podklady pro fakturaci</span>
                </a> -->
                <a class="dropdown-item" [class.active]="isGenForInvoicingActive" (click)="routeAndClose('ta3/gen_for_invoicing')">
                  <img [src]="gen_for_invoicingSrc" class="mr-2" width="28px" alt="gen_for_invoicing">
                  <span i18n>Podklady pro fakturaci</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [class.active]="isPaymentBankActive" (click)="routeAndClose('ta3/payment_bank')">
                  <img [src]="payment_bankSrc" class="mr-2" width="28px" alt="bank_building">
                  <span i18n>Zpracování výpisů z banky</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.remindersAllow">
                <a class="dropdown-item" [class.active]="isRemindersActive" (click)="routeAndClose('ta3/reminders')">
                  <img [src]="remindersSrc" class="mr-2" width="28px" alt="reminders">
                  <span i18n>Upomínky neuhrazených faktur</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.invoiceRead">
                <a class="dropdown-item" [class.active]="isInvoiceConditionActive" (click)="routeAndClose('ta3/invoice_condition')">
                  <div class="d-inline-block mr-2" style="width:28px; min-height:1px"></div>    
                  <span i18n>Vystav fakturu</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.invoiceRead">
                <a class="dropdown-item" [class.active]="isInvoiceInConditionActive" (click)="routeAndClose('ta3/invoice_in_condition')">
                  <img [src]="invoice_inSrc" class="mr-2" width="28px" alt="reminders">
                  <span i18n>Přijaté doklady</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [class.active]="isPaletteActive" (click)="routeAndClose('ta3/palette')">
                  <img [src]="invoice_inSrc" class="mr-2" width="28px" alt="reminders">
                  <span i18n>Paletové hospodářství</span>
                </a>
              </li>

              <!-- import dropdown -->
              <li class="dropdown-submenu" (mouseover)="setImportHover(true)" (mouseleave)="setImportHover(false)">
                <a #import class="dropdown-item dropdown-toggle pointer {{isFuelingImportActive ? 'active' : 'not-active'}}" 
                (click)="toggleImport(); scroll(import)">
                  <div class="d-inline-block mr-2" style="width:28px; min-height:1px"></div>
                  <span i18n>Import</span>
                </a>
                <ul class="dropdown-menu bg-dark third-level-menu" [class.third-level-menu-show]="importHover">
                  <li>
                    <a class="dropdown-item" [class.active]="isFuelingImportActive" (click)="routeAndClose('ta3/fueling')">
                      <span i18n>Import tankování</span>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- export dropdown -->
              <!-- <li class="dropdown-submenu" (mouseover)="setExportHover(true)" (mouseleave)="setExportHover(false)">
                <a #export class="dropdown-item dropdown-toggle pointer {{isExportActive ? 'active' : 'not-active'}}" 
                (click)="toggleExport(); scroll(export)">
                  <div class="d-inline-block mr-2" style="width:28px; min-height:1px"></div>
                  <span>Export</span>
                </a>
                <ul class="dropdown-menu bg-dark third-level-menu" [class.third-level-menu-show]="exportHover">
                  <li>
                    <a class="dropdown-item" [class.active]="isExportActive" (click)="routeAndClose('invoice_export')">
                      <span>Export do Money</span>
                    </a>
                  </li>
                </ul>
              </li> -->
            </ul>
          </li>
          <!-- nastaveni -->
          <li class="dropdown-submenu" (mouseover)="setAgenda4Hover(true)" (mouseleave)="setAgenda4Hover(false)">
            <a #agenda4 class="dropdown-item dropdown-toggle pointer {{isNastaveniActive ? 'active' : 'not-active'}}" 
            (click)="toggleAgenda4(); scroll(agenda4)">
              <span i18n>Nastavení</span>
            </a>
            <ul class="dropdown-menu bg-dark third-level-menu" [class.third-level-menu-show]="agenda4Hover">
              <li *ngIf="user?.user_account?.settingsUserAllow">
                <a class="dropdown-item" [class.active]="isCodeSeriesObligation" 
                (click)="routeAndClose('ta4/code_series_obligation')">
                  <span i18n>Číselník - řada/středisko</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.otherCostsAllow">
                <a class="dropdown-item" [class.active]="isCodeOverheads" 
                (click)="routeAndClose('ta4/code_overheads')">
                  <span i18n>Ostatní náklady</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.settingsUserAllow">
                <a class="dropdown-item" [class.active]="isCodeInvoiceParam" 
                (click)="routeAndClose('ta4/code_invoice_param')">
                  <span i18n>Nastavení faktury</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.settingsUserAllow">
                <a class="dropdown-item" [class.active]="isCodeOrderParam" 
                (click)="routeAndClose('ta4/code_order_param')">
                  <span i18n>Nastavení objednávky</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.settingsUserAllow">
                <a class="dropdown-item" [class.active]="isCodeRemindersParam" 
                (click)="routeAndClose('ta4/code_reminders_param')">
                  <span i18n>Nastavení upomínek</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [class.active]="isCodeOftenUsedPos" 
                (click)="routeAndClose('ta4/code_often_used_pos')">
                  <span i18n>Oblíbené adresy / Sklady</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" [class.active]="isDeliveryLines" 
                (click)="routeAndClose('ta4/delivery_lines')">
                  <span i18n>Rozvozové linky</span>
                </a>
              </li>
              <li *ngIf="user?.user_account?.companySuperuser">
                <a class="dropdown-item" [class.active]="isUserEdit" 
                (click)="routeAndClose('ta4/user_edit')">
                  <span i18n>Uživatel systému</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
  
      <!-- kontakt -->
      <li class="nav-item">
        <a id="kontakt" class="nav-link py-1 icon-menu-parent" [class.active]="isContactActive"
        [routerLink]="[{outlets: {left:'general/contact'}}]" [queryParams]="{ reloadPossible: true }">
          <div class="icon-menu">
            <img [src]="contactSrc" height="28px">
          </div>
          <span class="nav-font-size" i18n>Kontakt</span>
        </a>
      </li>

      <!-- podpora -->
      <li class="nav-item dropdown not-active"
      (mouseover)="setSupportHover(true)" (mouseleave)="setSupportHover(false)">
        <a #support class="nav-link py-1 icon-menu-parent dropdown-toggle pointer" (click)="toggleSupport(); scroll(support)">
          <div class="icon-menu">
            <img [src]="supportSrc" height="28px">
          </div>
          <span class="nav-font-size" i18n>Podpora</span>
        </a>
        <ul class="dropdown-menu bg-dark second-level-menu" [class.second-level-menu-show]="supportHover">
          <!-- <a class="dropdown-item" (click)="routeAndClose()" 
          target="_blank" href="https://get.teamviewer.com/67d5jzz">
            <span i18n>Vzdálená plocha - TeamViewer</span>
          </a> -->
          <!-- <a class="dropdown-item" target="_blank" href="https://anydesk.com/en/downloads/thank-you?dv=win_exe">
            <span i18n>Vzdálená podpora - Anydesk stáhnout</span>
          </a>
          <a class="dropdown-item" href="javscript:void(0)" (click)="openAnydesk()">
            <span i18n>Vzdálená podpora - Anydesk spustit</span>
          </a> -->
          <a class="dropdown-item" target="_blank" href="https://remotedesktop.google.com/support">
            <span i18n>Vzdálená podpora - Google</span>
          </a>
          <a class="dropdown-item" data-toggle="modal" data-target="#scannerModal" 
          (click)="getDelegations(); routeAndClose()">
            <span i18n>TM Scanner</span>
          </a>
          <a class="dropdown-item" (click)="routeAndClose()" 
          target="_blank" href="https://www.truckmanager.eu/manual.html">
            <span i18n>Manuály</span>
          </a>
        </ul>
      </li>

      <!-- udaje -->
      <li *ngIf="user?.user_account?.companySuperuser" class="nav-item dropdown {{(isConfigActive) ? 'active' : 'not-active'}}"
      (mouseover)="setMydataHover(true)" (mouseleave)="setMydataHover(false)">
        <a #mydata class="nav-link py-1 icon-menu-parent dropdown-toggle pointer" (click)="toggleMydata(); scroll(mydata)">
          <div class="icon-menu">
            <img [src]="configSrc" height="28px">
          </div>
          <span class="nav-font-size" i18n>Údaje</span>
        </a>
        <ul #mydata class="dropdown-menu bg-dark second-level-menu" [class.second-level-menu-show]="mydataHover">
          <a class="dropdown-item" [class.active]="isProfileActive" (click)="routeAndClose('general/profile')">
            <span i18n>Naše údaje</span>
          </a>
          <a class="dropdown-item" [class.active]="isSubscriptionActive" (click)="routeAndClose('general/subscription')">
            <span i18n>Předplatné</span>
          </a>
        </ul>
      </li>
    </ul>
  </div>

  <div id="companyInfo" class="mx-auto">
    <span class="navbar-text mx-auto py-0 text-center nav-font-size-small text-nowrap">
      <div>{{companyId}}</div>
      <div>{{company.substring(0,13)}}</div>
      <div>Do {{admittanceDate}}</div>
    </span>
  </div>

  <!-- <div id="fastChoiceMobile" class="mr-auto">
    <div class="d-flex pl-2">
      <a placement="bottom" ngbTooltip="Zakázky" 
      class="nav-link px-2 py-1" [class.active]="isObligationListActive"
      [routerLink]="[{outlets: {left:'obligation_list'}}]" [queryParams]="{ reloadPossible: true }">
        <img [src]="obligation_listSrc">
      </a>
      <a placement="bottom" ngbTooltip="Nová zakázka"
      class="nav-link px-2 py-1" [class.active]="isCompanyObligationActive"
      [routerLink]="[{outlets: {left:'ta1-obligation/company_obligation'}}]" 
      [queryParams]="{ reloadPossible: true, initNew: true }">
        <img [src]="company_obligationSrc">
      </a>
      <a placement="bottom" ngbTooltip="Faktury" 
      class="nav-link px-2 py-1" [class.active]="isInvoiceListActive" 
      [routerLink]="[{outlets: {left:'invoice_list'}}]" [queryParams]="{ reloadPossible: true }">
        <img [src]="invoice_listSrc">
      </a>
      <a placement="bottom" ngbTooltip="Sklad"
      class="nav-link px-2 py-1" [class.active]="isWarehouseControlActive"
      [routerLink]="[{outlets: {left:'warehouse-control'}}]" [queryParams]="{ reloadPossible: true }">
        <img [src]="warehouseControlSrc">
      </a>
    </div>
  </div> -->
</nav>

<!-- mobilni pruh rychle volby -->
<div id="fastChoiceMobile" class="mr-auto">
  <div class="d-flex pl-2">
    <a *ngIf="user?.user_account?.obligationRead" placement="bottom" ngbTooltip="Zakázky" 
    class="nav-link px-2 py-1" [class.active]="isObligationListActive" i18n-ngbTooltip
    [routerLink]="[{outlets: {left:'ta1-obligation/obligation_list'}}]" [queryParams]="{ reloadPossible: true }">
      <img [src]="obligation_listSrc">
    </a>
    <a *ngIf="user?.user_account?.obligationRead" placement="bottom" ngbTooltip="Nová zakázka"
    class="nav-link px-2 py-1" [class.active]="isCompanyObligationActive" i18n-ngbTooltip
    [routerLink]="[{outlets: {left:'ta1-obligation/company_obligation'}}]" 
    [queryParams]="{ reloadPossible: true, initNew: true }">
      <img [src]="company_obligationSrc">
    </a>
    <a *ngIf="user?.user_account?.invoiceRead" placement="bottom" ngbTooltip="Faktury" 
    class="nav-link px-2 py-1" [class.active]="isInvoiceListActive" i18n-ngbTooltip 
    [routerLink]="[{outlets: {left:'ta3/invoice_list'}}]" [queryParams]="{ reloadPossible: true }">
      <img [src]="invoice_listSrc">
    </a>
    <a *ngIf="user?.user_account?.obligationRead" placement="bottom" ngbTooltip="Sklad"
    class="nav-link px-2 py-1" [class.active]="isWarehouseControlActive" i18n-ngbTooltip
    [routerLink]="[{outlets: {left:'ta1-other/warehouse-control'}}]" [queryParams]="{ reloadPossible: true }">
      <img [src]="warehouseControlSrc">
    </a>
    <a *ngIf="user?.user_account?.obligationRead" placement="bottom" ngbTooltip="Plachta dispečera"
     class="nav-link px-2 py-1" i18n-ngbTooltip 
    target="_blank" [routerLink]="{outlets: {left:'dispatcher-board'}}">
      <img src="assets/icons-new/table-gray.svg">
    </a>
  </div>
</div>

<!-- pruh rychle volby -->
<nav id="fastChoice" class="navbar navbar-expand-lg navbar-light bg-lightdark border border-dark shadow-sm">
  <div class="navbar-collapse">
    <ul class="navbar-nav">
      <li class="nav-item py-0">
        <a *ngIf="user?.user_account?.historyAllow" placement="bottom" ngbTooltip="Historie" 
        class="nav-link px-2 py-1" [class.active]="isStatisticsActive" i18n-ngbTooltip
        [routerLink]="[{outlets: {left:'tm/statistics'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="statisticsSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.statementsAllow" class="nav-item py-0">
        <a placement="bottom" ngbTooltip="Výkazy a sestavy" i18n-ngbTooltip
        class="nav-link px-2 py-1" [class.active]="isCarbookActive"
        [routerLink]="[{outlets: {left:'tm/carbook'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="carbookSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.servicesAllow" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Servis/údržba vozidel" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isServicesActive"
        [routerLink]="[{outlets: {left:'tm/services'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="servicesSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.carsAllow" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Moje auta - nastavení" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isMycarsActive"
        [routerLink]="[{outlets: {left:'tm/mycars'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="mycarsSrcFast">
        </a>
      </li>

      <div class="mx-1" style="width: 5px; background-color: #373737;"></div>
      
      <li *ngIf="user?.user_account?.obligationRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Zakázky" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isObligationListActive"
        [routerLink]="[{outlets: {left:'ta1-obligation/obligation_list'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="obligation_listSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.obligationRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Nová zakázka" i18n-ngbTooltip
        class="nav-link px-2 py-1" [class.active]="isCompanyObligationActive"
        [routerLink]="[{outlets: {left:'ta1-obligation/company_obligation'}}]" 
        [queryParams]="{ reloadPossible: true, initNew: true }">
          <img [src]="company_obligationSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.obligationRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Dokládky (LTL)" i18n-ngbTooltip
        class="nav-link px-2 py-1" [class.active]="isExpressListActive"
        [routerLink]="[{outlets: {left:'ta1-obligation/express_list'}}]" 
        [queryParams]="{ reloadPossible: true, initNew: true }">
          <img [src]="express_listSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.obligationRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Plán rozvozu" i18n-ngbTooltip
        class="nav-link px-2 py-1" [class.active]="isWarehousePlanActive"
        [routerLink]="[{outlets: {left:'ta1-other/warehouse-plan'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="warehousePlanSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.obligationRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Sklad" i18n-ngbTooltip
        class="nav-link px-2 py-1" [class.active]="isWarehouseControlActive"
        [routerLink]="[{outlets: {left:'ta1-other/warehouse-control'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="warehouseControlSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.orderRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Objednávky" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isOrderListActive" 
        [routerLink]="[{outlets: {left:'ta1-order/order_list'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="order_listSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.invoiceRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Faktury" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isInvoiceListActive" 
        [routerLink]="[{outlets: {left:'ta3/invoice_list'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="invoice_listSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.invoiceRead" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Přijaté doklady" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isInvoiceInConditionActive" 
        [routerLink]="[{outlets: {left:'ta3/invoice_in_condition'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="invoice_inSrcFast">
        </a>
      </li>
      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Podklady pro fakturaci" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isGenForInvoicingActive"
        [routerLink]="[{outlets: {left:'ta3/gen_for_invoicing'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="gen_for_invoicingSrcFast" alt="gen_for_invoicing"/>
        </a>
      </li>
      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Zpracování výpisů z banky" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isPaymentBankActive"
        [routerLink]="[{outlets: {left:'ta3/payment_bank'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="payment_bankSrcFast" alt="payment_bank"/>
        </a>
      </li>
      <li *ngIf="user?.user_account?.remindersAllow" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Upomínky neuhrazených faktur" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isRemindersActive"
        [routerLink]="[{outlets: {left:'ta3/reminders'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="remindersSrcFast" alt="reminders"/>
        </a>
      </li>
      <li *ngIf="user?.user_account?.statisticsSalesProfit" lass="nav-item p-0">
        <a placement="bottom" ngbTooltip="Tržby a zisk" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isObligationSalesActive"
        [routerLink]="[{outlets: {left:'ta1-obligation/obligation_sales'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="obligation_salesSrcFast">
        </a>
      </li>
      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Vyhodnocení spotřeby PHM" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isFuelingStatsActive" 
        [routerLink]="[{outlets: {left:'ta1-other/fueling_stats'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="fuelingStatsSrcFast">
        </a>
      </li>
      <li *ngIf="user?.user_account?.statisticsSalesProfit" class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Přehledy a žebříčky" i18n-ngbTooltip 
        class="nav-link px-2 py-1" target="_blank" [href]="staticContentResource + dataOverview">
          <img src="assets/icons-new/data_table.svg">
        </a>
      </li>
      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Adresář" i18n-ngbTooltip 
        class="nav-link px-2 py-1" [class.active]="isSearchActive" 
        [routerLink]="[{outlets: {left:'ta2/search'}}]" [queryParams]="{ reloadPossible: true }">
          <img [src]="searchSrcFast">
        </a>
      </li>
      
      <div class="mx-1" style="width: 5px; background-color: #373737;"></div>

      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Plachta dispečera" class="nav-link px-2 py-1" 
        target="_blank" [routerLink]="{outlets: {left:'dispatcher-board'}}" i18n-ngbTooltip>
          <img src="assets/icons-new/table.svg">
        </a>
      </li>
      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Diář na(vy)kládek" class="nav-link px-2 py-1" 
        [routerLink]="{outlets: {right:'itinerary-dairy'}}" i18n-ngbTooltip>
          <img src="assets/icons-new/diare.svg">
        </a>
      </li>
      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Auta - dlaždice" class="nav-link px-2 py-1"
        target="_blank" [routerLink]="{outlets: {left:'vehicle-grid'}}" i18n-ngbTooltip>
          <img src="assets/icons-new/windows.svg">
        </a>
      </li>
      <li class="nav-item p-0">
        <a placement="bottom" ngbTooltip="Kalkulace trasy" class="nav-link px-2 py-1" 
        target="_blank" [href]="staticContentResource + calc" i18n-ngbTooltip>
          <img src="assets/icons-new/calculator.svg">
        </a>
      </li>
    </ul>
  </div>
</nav>


<!-- modal for opening TM scanner installer and token -->
<div class="modal fade" role="dialog" tabindex="-1" id="scannerModal">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title" i18n>TM Scanner</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <h6 class="col-sm-12" i18n>Nainstalujte si aplikaci TM Scanner</h6>
          
          <div class="col-sm-12">
            <div i18n>Aplikace skenuje a odesílá přepravní dokumenty na server TruckManager</div>
            <a class="btn btn-primary my-3" href="https://www.truckmanager.eu/tmscanner/publish.htm" 
            target="_blank" i18n>Instalovat</a>
          </div>

          <div *ngIf="loadingToken" class="spinner-wrapper text-center py-2">
            <div class="spinner-border text-primary" style="width:1.5rem; height:1.5rem;" role="status">
              <span class="sr-only"></span>
            </div>
          </div>

          <h6 *ngIf="!loadingToken" class="col-sm-12" i18n>Váš přihlašovací token je: </h6>
          <div *ngIf="!loadingToken" class="col-sm-12 text-primary mb-3">
            <span #tokenSpan>{{token}}</span> 
            <button *ngIf="token" class="btn btn-sm btn-outline-primary ml-3" (click)="copyToken()">
              <img src="assets/icons-new/copy.svg" class="mr-1" alt="copy" height="18px"/>
              <ng-container i18n>Kopírovat do schránky</ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- modal alert for admittance -->
<div class="modal fade" role="dialog" tabindex="-1" id="admittanceModal">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-danger shadow">
        <h4 class="modal-title" i18n>Konec předplatného</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row py-2">
          <h5 class="col-12">
            <ng-container i18n>Konec vašeho předplatného</ng-container> {{admittanceDate}}
          </h5>
          <h6 class="col-12" i18n>Některé funkce systému TruckAgenda 
            pro vás již nemusí být dostupné.
          </h6>

          <div class="offset-md-3 col-md-6 mt-2">
            <a class="btn btn-primary w-100" (click)="routeAndClose('general/subscription')" 
            data-dismiss="modal" i18n>
              Předplatné
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- OBSOLETE
<li>
  <a class="dropdown-item" [class.active]="isRouteActive('left:code_invoice_item')" 
  (click)="routeAndClose('code_invoice_item')">
    <span>Číselník - položky faktury</span>
  </a>
</li> -->
<!-- OBSOLETE
  <li>
  <a class="dropdown-item" [class.active]="isRouteActive('left:driver_edit')" 
  (click)="routeAndClose('driver_edit')">
    <span>Editace parametrů řidičů</span>
  </a>
</li> -->
<!-- mozna v budoucnu 
  <li>
  <a class="dropdown-item" [class.active]="isRouteActive('left:code_book_note')" 
  (click)="routeAndClose('code_book_note')">
    <span>Číselník - poznámky</span>
  </a>
</li> -->
<!-- OBSOLETE 
  <li>
  <a class="dropdown-item" [class.active]="isRouteActive('left:code_stock')" 
  (click)="routeAndClose('code_stock')">
    <span>Sklady pro sběrnou službu</span>
  </a>
</li> -->
<!-- OBSOLETE 
  <li class="dropdown-submenu" (mouseover)="setServisDBHover(true)" (mouseleave)="setServisDBHover(false)">
  <a #servisDB class="dropdown-item dropdown-toggle pointer" (click)="toggleServisDB(); scroll(servisDB)">
    <span>Servis DB</span>
  </a>
  <ul class="dropdown-menu bg-dark third-level-menu" [class.third-level-menu-show]="servisDBHover">
    <li>
      <a class="dropdown-item" [href]="staticContentResource + _delDefInvoice" 
      onclick="if (confirm('Smazat?')) { return true; } else { return false; }">Smaž def invoice</a>
    </li>
    <li>
      <a class="dropdown-item" [href]="staticContentResource + _delDefInvoiceAuto" 
      onclick="if (confirm('Smazat?')) { return true; } else { return false; }">Smaž def invoice autotext</a>
    </li>
    <li>
      <a class="dropdown-item" [href]="staticContentResource + _delDefInvoiceItem"  
      onclick="if (confirm('Smazat?')) { return true; } else { return false; }">Smaž def invoice item</a>
    </li>
    <li>
      <a class="dropdown-item" [href]="staticContentResource + _delDefStredisko"  
      onclick="if (confirm('Smazat?')) { return true; } else { return false; }">Smaž def stredisko</a>
    </li>
  </ul>
</li> -->
