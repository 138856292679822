import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../service/authentication.service";
import { MessageService } from "../../service/message.service";
import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";

@Component({
    selector: 'div#demo-startup',
    templateUrl: './demo-startup.component.html',
    styleUrls: ['./demo-startup.component.css']
    // templateUrl: '/app/templates/demo-startup.component.html?v=' + Date.now()
})
export class DemoStartupComponent {

    private _messageDialogBeforeInit: boolean;

    constructor(
        private _layout: TruckManagerLayoutService,
        private _authentication: AuthenticationService,
        private _router: Router
    ) {
        _layout.showLoginDialog();
        _layout.toggleDashboard();
        this._authentication.authenticationResult.subscribe(
            (user) => {
                if (user) {
                    this._router.navigateByUrl('/');
                }
            }
        )
    }

}
