import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {

  private _defaultHeaders: Array<any> = [];

  private _unauthorized: EventEmitter<boolean> = new EventEmitter();
  private _addDefaultRequestOptions: boolean = true;

  constructor(
    private _http: HttpClient
  ) {
  }

  private _map: boolean = true;
  set map(value: boolean) {
    this._map = value;
  }

  set default_headers(header: Array<any>) {
    this._defaultHeaders = header;
  }

  public excludeDefaultRequestOptions() {
    this._addDefaultRequestOptions = false;
  }

  public includeDefaultRequestOptions() {
    this._addDefaultRequestOptions = true;
  }

  public get(url: string, options?: {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    options = this.getDefaultRequestOptionsArgs(options);
    let obs = this._http.get(
      url,
      options
    );
    if (this._map) {
      obs = obs.pipe(map(
        this.mapResponse
      ));
    } 
    else {
      this._map = true;
    }
    return obs;
  }

  public post(url: string, body: any, options?: {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    options = this.getDefaultRequestOptionsArgs(options);
    let obs = this._http.post(
      url,
      body,
      options
    );
    if (this._map) {
      obs = obs.pipe(map(
        this.mapResponse
      ));
    } else {
      this._map = true;
    }
    return obs;
  }

  public delete(url: string, options?: {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    let obs = this._http.delete(url, this.getDefaultRequestOptionsArgs(options));
    if (this._map) {
      obs = obs.pipe(map(
        this.mapResponse
      ));
    } else {
      this._map = true;
    }
    return obs;
  }

  public put(url: string, body: any, options?: {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    options = this.getDefaultRequestOptionsArgs(options);
    let obs = this._http.put(url, body, options);
    if (this._map) {
      obs = obs.pipe(map(
        this.mapResponse
      ));
    } else {
      this._map = true;
    }
    return obs;
  }

  handleUnauthorized(): Observable<boolean> {
    return this._unauthorized.asObservable();
  }

  // fix - response here was not always of type Response!
  private mapResponse(response: any) {
    if (response) {
      if (response.status === 401) {
        this._unauthorized.emit(true);
      }
      // hotfix for obligations(obligation contains property status) 
      // else if (!response.obligation_key && (response.status < 200 || response.status > 299)) {
      //   this.notificationService.alert('Http response error', 'error');
      // }
      try {
        return response.json();
      } 
      catch (e) {
        // console.log(e);
      }
    }
    return response;
  }

  private getDefaultRequestOptionsArgs(options?: {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  } {
    if (!options) {
      options = {
        headers: new HttpHeaders(),
        observe: "body",
        params: new HttpParams(),
        reportProgress: false,
        responseType: "json",
        withCredentials: false,
      }
    }
    if (!options.headers) {
      options.headers = new HttpHeaders();
      options.headers = options.headers.set('Content-type', 'application/json');
      // options.headers = options.headers.set('Access-Control-Allow-Origin', '*');
      // options.headers = options.headers.set('Access-Control-Allow-Credentials', 'true');
      // options.headers = options.headers.set('Access-Control-Allow-Methods', 'POST, GET, PUT, OPTIONS, DELETE');
      // options.headers = options.headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Authorization, Accept, Api-key');
    }


    if (!this._addDefaultRequestOptions) {
      return options;
    }


    if (this._defaultHeaders.length) {
      this._defaultHeaders.forEach(
        header => {
          let value;
          if (header.value instanceof Function) {
            value = header.value();
          } 
          else {
            value = header.value;
          }
          
          if (header.name === ServiceConfiguration.authentication.authorizationHeader) {
            options.withCredentials = true;
          }
          // options.headers = options.headers.append(header.name, value);
          // https://stackoverflow.com/questions/56767158/angular-8-typeerror-found-non-callable-iterator
          // All Header should be in string while using angular 8+
          options.headers = options.headers.set(header.name, value.toString());
        }
      )
    }
    return options;
  }
}
