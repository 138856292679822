export class TimocomFreightSearchObject {
    private _startLocatiLatitude: string;
    public get startLocatiLatitude(): string {
        return this._startLocatiLatitude;
    }
    public set startLocatiLatitude(value: string) {
        this._startLocatiLatitude = value;
    }
    private _startLocationLongitude: string;
    public get startLocationLongitude(): string {
        return this._startLocationLongitude;
    }
    public set startLocationLongitude(value: string) {
        this._startLocationLongitude = value;
    }
    private _destinationLocatiLatitude: string;
    public get destinationLocatiLatitude(): string {
        return this._destinationLocatiLatitude;
    }
    public set destinationLocatiLatitude(value: string) {
        this._destinationLocatiLatitude = value;
    }
    private _destinationLocationLongitude: string;
    public get destinationLocationLongitude(): string {
        return this._destinationLocationLongitude;
    }
    public set destinationLocationLongitude(value: string) {
        this._destinationLocationLongitude = value;
    }
    private _startLocationCity: string;
    public get startLocationCity(): string {
        return this._startLocationCity;
    }
    public set startLocationCity(value: string) {
        this._startLocationCity = value;
    }
    private _destinationLocatiCity: string;
    public get destinationLocatiCity(): string {
        return this._destinationLocatiCity;
    }
    public set destinationLocatiCity(value: string) {
        this._destinationLocatiCity = value;
    }
    private _startLocationSize_km: number;
    public get startLocationSize_km(): number {
        return this._startLocationSize_km;
    }
    public set startLocationSize_km(value: number) {
        this._startLocationSize_km = value;
    }
    private _destinationLocationSize_km: number;
    public get destinationLocationSize_km(): number {
        return this._destinationLocationSize_km;
    }
    public set destinationLocationSize_km(value: number) {
        this._destinationLocationSize_km = value;
    }
    private _loadingDateStart: string;
    public get loadingDateStart(): string {
        return this._loadingDateStart;
    }
    public set loadingDateStart(value: string) {
        this._loadingDateStart = value;
    }
    private _loadingDateEnd: string; 
    public get loadingDateEnd(): string {
        return this._loadingDateEnd;
    }
    public set loadingDateEnd(value: string) {
        this._loadingDateEnd = value;
    }

    private _startLocationCountry: string;
    public get startLocationCountry(): string {
        return this._startLocationCountry;
    }
    public set startLocationCountry(value: string) {
        this._startLocationCountry = value;
    }
    private _startLocationPostalCode: string;
    public get startLocationPostalCode(): string {
        return this._startLocationPostalCode;
    }
    public set startLocationPostalCode(value: string) {
        this._startLocationPostalCode = value;
    }
    private _destinationLocationCountry: string;
    public get destinationLocationCountry(): string {
        return this._destinationLocationCountry;
    }
    public set destinationLocationCountry(value: string) {
        this._destinationLocationCountry = value;
    }
    private _destinationLocationPostalCode: string;
    public get destinationLocationPostalCode(): string {
        return this._destinationLocationPostalCode;
    }
    public set destinationLocationPostalCode(value: string) {
        this._destinationLocationPostalCode = value;
    }

    private _exclusiveLeftLowerBoundDateTime: string = "2023-06-16T08:15:22Z";
    public get exclusiveLeftLowerBoundDateTime(): string {
        return this._exclusiveLeftLowerBoundDateTime;
    }
    public set exclusiveLeftLowerBoundDateTime(value: string) {
        this._exclusiveLeftLowerBoundDateTime = value;
    }
    private _inclusiveRightUpperBoundDateTime: string = "2023-06-16T10:20:22Z";
    public get inclusiveRightUpperBoundDateTime(): string {
        return this._inclusiveRightUpperBoundDateTime;
    }
    public set inclusiveRightUpperBoundDateTime(value: string) {
        this._inclusiveRightUpperBoundDateTime = value;
    }

    private _vehicleBody: string;
    public get vehicleBody(): string {
        return this._vehicleBody;
    }
    public set vehicleBody(value: string) {
        this._vehicleBody = value;
    }

    private _vehicleType: string;
    public get vehicleType(): string {
        return this._vehicleType;
    }
    public set vehicleType(value: string) {
        this._vehicleType = value;
    }

    get apiObject(): any {
        let obj: any = {
            // sortings: [
            //   {
            //     ascending: true,
            //     field: "creationDateTime"
            //   }
            // ],
            //exclusiveLeftLowerBoundDateTime: this.exclusiveLeftLowerBoundDateTime,
            inclusiveRightUpperBoundDateTime: this.inclusiveRightUpperBoundDateTime,
            startLocation: {
              objectType: "areaSearch",
              area: {
                address: {
                  objectType: "address",
                  country:this.startLocationCountry,
                  geoCoordinate: {
                    latitude: parseFloat(this.startLocatiLatitude),
                    longitude: parseFloat(this.startLocationLongitude)
                  },
                  postalCode: this.startLocationPostalCode
                },
                size_km: this.startLocationSize_km
              }
            },
            destinationLocation: {
              objectType: "areaSearch",
              area: {
                address: {
                  objectType: "address",
                  country: this.destinationLocationCountry,
                  geoCoordinate: {
                    latitude: parseFloat(this.destinationLocatiLatitude),
                    longitude: parseFloat(this.destinationLocationLongitude)
                  },
                  postalCode: this.destinationLocationPostalCode
                },
                size_km: this._destinationLocationSize_km
              }
            },
            // loadingDate: {
            //   objectType: "dateInterval",
            //   start: this.loadingDateStart,
            //   end: this.loadingDateEnd
            // },
            firstResult: 0,
            maxResults: 30,
            vehicleProperties:{
                body: [this.vehicleBody],
                type: [this.vehicleType]
            }
            }
          return obj;
    }
}