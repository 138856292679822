import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { Model } from "../model/model.object";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class ModelService {

  constructor(
    private _http: HttpClientService,
    private _authServ: AuthenticationService
  ) {
  }

  private _loadingVehicleModels: boolean = false;
  get loadingVehicleModels(): boolean {
    return this._loadingVehicleModels;
  }

  private _loadingTrailerModels: boolean = false;
  get loadingTrailerModels(): boolean {
    return this._loadingTrailerModels;
  }

  getVehicleModels(): Observable<Array<Model>> {
    let result: BehaviorSubject<Array<Model>> = new BehaviorSubject([]);
    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      this._loadingVehicleModels = true;
      this._http.get(ServiceConfiguration.vehicle.apiModels).subscribe(
        response => {
          if (response) {
            let models = this.buildModelsFromData(response);
            result.next(models);
            this._loadingVehicleModels = false;
          }
        },
        error => {
          //handle error
          console.log(error);
          this._loadingVehicleModels = false;
        }
      );
    }
    return result.asObservable();
  }

  getTrailerModels(): Observable<Array<Model>> {
    let result: BehaviorSubject<Array<Model>> = new BehaviorSubject([]);
    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      this._loadingTrailerModels = true;
      this._http.get(ServiceConfiguration.trailers.apiModels).subscribe(
        response => {
          if (response) {
            let models = this.buildModelsFromData(response);
            result.next(models);
            this._loadingTrailerModels = false;
          }
        },
        error => {
          //handle error
          console.log(error);
          this._loadingTrailerModels = false;
        }
      );
    }
    return result.asObservable();
  }

  // method for creating model objects from api objects
  private buildModelsFromData(data: Array<any>): any {
    let models: Array<Model> = [];
    data.forEach(
      m => {
        let model: Model = this.buildModel(m);
        models.push(model);
      }
    );
    return models;
  }
  
  // method for creating a single model object
  private buildModel(o: any): Model {
    let model: Model = new Model();
    for (let key in o) {
      model[key] = o[key];
    }
    return model;
  }
}
