<!-- Modal for creating new service task -->
<div class="modal fade" role="dialog" tabindex="-1" id="createModal">
  <form [formGroup]="newTaskForm" (ngSubmit)="submitNewTaskForm()">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-warning shadow">
          <h5 class="modal-title">
            <ng-container i18n>Nový servisní úkon</ng-container>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <div *ngIf="newTask" class="smallMarginFormGroup">
            <div class="row mb-4">
              <!-- vehicles or trailers loaded -->
              <div *ngIf="vehicles.length || trailers.length" class="col-lg-6">
                <div *ngIf="isCarNewTask" class="form-group mb-1">
                  <label><ng-container i18n>Vozidlo</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="car_key">
                    <option *ngFor="let v of vehicles" [ngValue]="v.car_key" [class.text-danger]="v.inactive">
                      {{v.number_plate}} {{v.driver_name}}
                    </option>
                  </select>
                </div>
                <div *ngIf="isTrailerNewTask" class="form-group mb-1">
                  <label><ng-container i18n>Návěs/přívěs</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="trailer_key">
                    <option *ngFor="let t of trailers" [ngValue]="t.trailer_key">
                      {{t.number_plate}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- vehicle or trailer selected -->
              <div *ngIf="(vehicle || trailer) && (!vehicles.length && !trailers.length)" class="col-lg-6">
                <div *ngIf="isCarNewTask" class="form-group mb-1">
                  <label><ng-container i18n>Vozidlo</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="car_key">
                    <option *ngIf="vehicle" [ngValue]="vehicle.car_key">
                      {{vehicle.number_plate}} {{vehicle.driver_name}}
                    </option>
                  </select>
                </div>
                <div *ngIf="isTrailerNewTask" class="form-group mb-1">
                  <label><ng-container i18n>Návěs/přívěs</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="trailer_key">
                    <option *ngIf="trailer" [ngValue]="trailer.trailer_key">
                      {{trailer.number_plate}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label i18n>Typ plánu</label>
                  <select class="form-control form-control-sm" formControlName="type">
                    <option value="A" i18n>Plánovaný</option>
                    <option value="B" i18n>Neplánovaný</option>
                    <option value="C" i18n>Nadstandard</option>
                    <option value="D" i18n>Poplatky</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label>Úkon *</label>
                  <select class="form-control form-control-sm" formControlName="task"
                  (change)="newTaskChange()"
                  [ngClass]="{'is-invalid': newTaskFormSubmitted && f_new.task?.errors}">
                    <option [ngValue]="null" i18n>Vyberte</option>
                    <!-- <option value="A" i18n>Mýto</option> -->
                    <option value="B" i18n>Baterie (pořízení)</option>
                    <option value="C" i18n>Parkování</option>
                    <option value="L" i18n>Certifikát "L"</option>
                    <option value="G" i18n>Garanční prohlídka</option>
                    <option value="M" i18n>Mytí</option>
                    <option value="N" i18n>Nákup/pořízení vozidla</option>
                    <option value="D" i18n>Nákup náhradních dílů</option>
                    <option value="Y" i18n>Nadstandard</option>
                    <option value="O" i18n>Olej (výměna oleje a filtrů)</option>
                    <option value="H" i18n>Oprava (havárie)</option>
                    <option value="I" i18n>Pojištění</option>
                    <option value="P" i18n>Pneu 1 - hnací náprava</option>
                    <option value="Q" i18n>Pneu 2 - vlečená náprava</option>
                    <option value="R" i18n>Pneu 3</option>
                    <option value="S" i18n>Termínovaná prohlídka</option>
                    <option value="T" i18n>STK + Emise</option>
                    <option value="V" i18n>Kalibrace tacho</option>
                    <option value="W" i18n>Záloha tacho</option>
                    <option value="Z" i18n>Záruka do</option>
                    <option value="X" i18n>Ostatní</option>
                  </select>
                  <div *ngIf="newTaskFormSubmitted && f_new.task?.errors" class="text-danger">
                    * <ng-container i18n>povinné pole</ng-container>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label><ng-container i18n>Název úkonu</ng-container> *</label>
                  <input class="form-control form-control-sm" formControlName="code" type="text"
                  [ngClass]="{'is-invalid': newTaskFormSubmitted && f_new.code?.errors}"/>
                  <div *ngIf="newTaskFormSubmitted && f_new.code?.errors" class="text-danger">
                    * <ng-container i18n>povinné pole (max 40 znaků)</ng-container>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-1">
                  <label i18n>Poznámka</label>
                  <input class="form-control form-control-sm" formControlName="note" type="text"
                  [ngClass]="{'is-invalid': newTaskFormSubmitted && f_new.note?.errors}"/>
                  <div *ngIf="newTaskFormSubmitted && f_new.note?.errors" class="text-danger">
                    * <ng-container i18n>max 250 znaků</ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Cena</label>
                  <div class="input-group">
                    <input class="form-control form-control-sm" formControlName="price" type="number"/>
                    <div class="input-group-append">
                      <select class="form-control form-control-sm" formControlName="currency">
                        <option [ngValue]="null">-</option>
                        <option value="CZK">CZK</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option value="CHF">CHF</option>
                        <option value="DKK">DKK</option>
                        <option value="PLN">PLN</option>
                        <option value="NOK">NOK</option>
                        <option value="HUF">HUF</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Amortizace/Interval servisu</label>
                  <div class="input-group input-group-sm">
                    <input class="form-control form-control-sm" 
                    formControlName="amortization_interval" type="number"/>
                    <div class="input-group-append">
                      <select class="form-control form-control-sm" formControlName="amortization_unit">
                        <option [ngValue]="null">-</option>
                        <option value="D" i18n>dny</option>
                        <option value="K" i18n>km</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Odepsáno</label>
                  <input class="form-control form-control-sm" type="text"
                  [value]="newTask.current_depriciated_formatted" disabled/>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Pojistné plnění</label>
                  <div class="input-group input-group-sm">
                    <input class="form-control form-control-sm" 
                    formControlName="insurance_benefits" type="number"/>
                    <div class="input-group-append">
                      <span class="input-group-text">{{f_new.currency.value}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Plánované datum úkonu</label>
                  <input class="form-control form-control-sm" 
                  formControlName="due_date" type="date"/>
                </div>
              </div>
              <div *ngIf="datePlanned(f_new.task.value) || bothPlanned(f_new.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>1. <ng-container i18n>upozornění (počet dní před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder1" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="datePlanned(f_new.task.value) || bothPlanned(f_new.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>2. <ng-container i18n>upozornění (počet dní před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder2" type="number" min="0"/>
                </div>
              </div>
              <div class="col-lg-3" 
              [class.offset-lg-6]="!datePlanned(f_new.task.value) && !bothPlanned(f_new.task.value)">
                <div class="form-group mb-1">
                  <label i18n>Datum dokončení úkonu</label>
                  <input class="form-control form-control-sm" 
                  formControlName="completed_date" type="date"
                  (change)="autoCompleteKm()"/>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Plánovaný počet km (při úkonu)</label>
                  <input class="form-control form-control-sm" 
                  formControlName="due_km" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="kmPlanned(f_new.task.value) || bothPlanned(f_new.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>1. <ng-container i18n>upozornění (počet km před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder1" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="kmPlanned(f_new.task.value) || bothPlanned(f_new.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>2. <ng-container i18n>upozornění (počet km před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder2" type="number" min="0"/>
                </div>
              </div>
              <div class="col-lg-3"
              [class.offset-lg-6]="!kmPlanned(f_new.task.value) && !bothPlanned(f_new.task.value)">
                <div class="form-group mb-1">
                  <label i18n>Stav tachometru při dokončení úkonu</label>
                  <input class="form-control form-control-sm" 
                  formControlName="completed_km" type="number" min="0"
                  (focus)="autoCompleteKm()"/>
                </div>
              </div>
            </div>
            
            <!-- suggestions from events -->
            <div *ngIf="fuelEvents?.length || trackingEvents?.length" class="row mt-5">
              <h6 class="col-12" i18n>Návrhy stavu tachografu</h6>
              <div *ngIf="fuelEvents?.length" class="col-12">
                <div class="d-flex text-primary eventRow" 
                (click)="newCompletedKm(fuelEvents[0].clock_up)">
                  <div class="w-25" i18n>Nejbližší tankování</div>
                  <div class="w-25">{{fuelEvents[0].timeDate | date: 'dd.MM.yyyy HH:mm'}}</div>
                  <div class="w-50">{{fuelEvents[0].clock_up | km}}km</div>
                </div>
              </div>
              <div *ngIf="trackingEvents?.length" class="col-12">
                <div class="d-flex text-primary eventRow" 
                (click)="newCompletedKm(trackingEvents[0].odometer)">
                  <div class="w-25" i18n>Událost z vozidla</div>
                  <div class="w-25">{{trackingEvents[0].timeDate | date: 'dd.MM.yyyy HH:mm'}}</div>
                  <div class="w-50">{{trackingEvents[0].odometer | km}}km</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-success mr-1" type="submit">
            <ng-container i18n>Vytvořit</ng-container>
          </button>
          <button class="btn btn-light" type="button" data-dismiss="modal">
            <ng-container i18n>Zavřít</ng-container>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal for editing service task -->
<div class="modal fade" role="dialog" tabindex="-1" id="editModal">
  <form [formGroup]="editTaskForm" (ngSubmit)="submitEditTaskForm()">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-warning shadow">
          <h5 class="modal-title">
            <ng-container i18n>Detail servisního úkonu</ng-container>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <div *ngIf="editTask" class="smallMarginFormGroup">
            <div class="row mb-4">
              <!-- vehicles or trailers loaded -->
              <div *ngIf="vehicles.length || trailers.length" class="col-lg-6">
                <div *ngIf="isCarEditTask" class="form-group mb-1">
                  <label><ng-container i18n>Vozidlo</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="car_key">
                    <option *ngFor="let v of vehicles" [ngValue]="v.car_key" [class.text-danger]="v.inactive">
                      {{v.number_plate}} {{v.driver_name}}
                    </option>
                  </select>
                </div>
                <div *ngIf="isTrailerEditTask" class="form-group mb-1">
                  <label><ng-container i18n>Návěs/přívěs</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="trailer_key">
                    <option *ngFor="let t of trailers" [ngValue]="t.trailer_key">
                      {{t.number_plate}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- vehicle or trailer selected -->
              <div *ngIf="(vehicle || trailer) && (!vehicles.length && !trailers.length)" class="col-lg-6">
                <div *ngIf="isCarEditTask" class="form-group mb-1">
                  <label><ng-container i18n>Vozidlo</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="car_key">
                    <option *ngIf="vehicle" [ngValue]="vehicle.car_key">
                      {{vehicle.number_plate}} {{vehicle.driver_name}}
                    </option>
                  </select>
                </div>
                <div *ngIf="isTrailerEditTask" class="form-group mb-1">
                  <label><ng-container i18n>Návěs/přívěs</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="trailer_key">
                    <option *ngIf="trailer" [ngValue]="trailer.trailer_key">
                      {{trailer.number_plate}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label i18n>Typ plánu</label>
                  <select class="form-control form-control-sm" formControlName="type">
                    <option value="A" i18n>Plánovaný</option>
                    <option value="B" i18n>Neplánovaný</option>
                    <option value="C" i18n>Nadstandard</option>
                    <option value="D" i18n>Poplatky</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label>Úkon *</label>
                  <select class="form-control form-control-sm" formControlName="task"
                  (change)="editTaskChange()"
                  [ngClass]="{'is-invalid': editTaskFormSubmitted && f_edit.task?.errors}">
                    <option [ngValue]="null" i18n>Vyberte</option>
                    <!-- <option value="A" i18n>Mýto</option> -->
                    <option value="B" i18n>Baterie (pořízení)</option>
                    <option value="C" i18n>Parkování</option>
                    <option value="L" i18n>Certifikát "L"</option>
                    <option value="G" i18n>Garanční prohlídka</option>
                    <option value="M" i18n>Mytí</option>
                    <option value="N" i18n>Nákup/pořízení vozidla</option>
                    <option value="D" i18n>Nákup náhradních dílů</option>
                    <option value="Y" i18n>Nadstandard</option>
                    <option value="O" i18n>Olej (výměna oleje a filtrů)</option>
                    <option value="H" i18n>Oprava (havárie)</option>
                    <option value="I" i18n>Pojištění</option>
                    <option value="P" i18n>Pneu 1 - hnací náprava</option>
                    <option value="Q" i18n>Pneu 2 - vlečená náprava</option>
                    <option value="R" i18n>Pneu 3</option>
                    <option value="S" i18n>Termínovaná prohlídka</option>
                    <option value="T" i18n>STK + Emise</option>
                    <option value="V" i18n>Kalibrace tacho</option>
                    <option value="W" i18n>Záloha tacho</option>
                    <option value="Z" i18n>Záruka do</option>
                    <option value="X" i18n>Ostatní</option>
                  </select>
                  <div *ngIf="editTaskFormSubmitted && f_edit.task?.errors" class="text-danger">
                    * <ng-container i18n>povinné pole</ng-container>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label><ng-container i18n>Název úkonu</ng-container> *</label>
                  <input class="form-control form-control-sm" formControlName="code" type="text"
                  [ngClass]="{'is-invalid': editTaskFormSubmitted && f_edit.code?.errors}"/>
                  <div *ngIf="editTaskFormSubmitted && f_edit.code?.errors" class="text-danger">
                    * <ng-container i18n>povinné pole (max 40 znaků)</ng-container>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-1">
                  <label i18n>Poznámka</label>
                  <input class="form-control form-control-sm" formControlName="note" type="text"
                  [ngClass]="{'is-invalid': editTaskFormSubmitted && f_edit.note?.errors}"/>
                  <div *ngIf="editTaskFormSubmitted && f_edit.note?.errors" class="text-danger">
                    * <ng-container i18n>max 250 znaků</ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Cena</label>
                  <div class="input-group">
                    <input class="form-control form-control-sm" formControlName="price" type="number"/>
                    <div class="input-group-append">
                      <select class="form-control form-control-sm" formControlName="currency">
                        <option [ngValue]="null">-</option>
                        <option value="CZK">CZK</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option value="CHF">CHF</option>
                        <option value="DKK">DKK</option>
                        <option value="PLN">PLN</option>
                        <option value="NOK">NOK</option>
                        <option value="HUF">HUF</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Amortizace/Interval servisu</label>
                  <div class="input-group input-group-sm">
                    <input class="form-control form-control-sm" 
                    formControlName="amortization_interval" type="number"/>
                    <div class="input-group-append">
                      <select class="form-control form-control-sm" formControlName="amortization_unit">
                        <option [ngValue]="null">-</option>
                        <option value="D" i18n>dny</option>
                        <option value="K">km</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Odepsáno</label>
                  <input class="form-control form-control-sm" type="text"
                  [value]="editTask.current_depriciated_formatted" disabled/>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Pojistné plnění</label>
                  <div class="input-group input-group-sm">
                    <input class="form-control form-control-sm" 
                    formControlName="insurance_benefits" type="number"/>
                    <div class="input-group-append">
                      <span class="input-group-text">{{f_edit.currency.value}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Plánované datum úkonu</label>
                  <input class="form-control form-control-sm" 
                  formControlName="due_date" type="date"/>
                </div>
              </div>
              <div *ngIf="datePlanned(f_edit.task.value) || bothPlanned(f_edit.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>1. <ng-container i18n>upozornění (počet dní před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder1" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="datePlanned(f_edit.task.value) || bothPlanned(f_edit.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>2. <ng-container i18n>upozornění (počet dní před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder2" type="number" min="0"/>
                </div>
              </div>
              <div class="col-lg-3" 
              [class.offset-lg-6]="!datePlanned(f_edit.task.value) && !bothPlanned(f_edit.task.value)">
                <div class="form-group mb-1">
                  <label i18n>Datum dokončení úkonu</label>
                  <input class="form-control form-control-sm" 
                  formControlName="completed_date" type="date"
                  (change)="autoCompleteKm()"/>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group mb-1">
                  <label i18n>Plánovaný počet km (při úkonu)</label>
                  <input class="form-control form-control-sm" 
                  formControlName="due_km" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="kmPlanned(f_edit.task.value) || bothPlanned(f_edit.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>1. <ng-container i18n>upozornění (počet km před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder1" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="kmPlanned(f_edit.task.value) || bothPlanned(f_edit.task.value)" class="col-lg-3">
                <div class="form-group mb-1">
                  <label>2. <ng-container i18n>upozornění (počet km před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder2" type="number" min="0"/>
                </div>
              </div>
              <div class="col-lg-3"
              [class.offset-lg-6]="!kmPlanned(f_edit.task.value) && !bothPlanned(f_edit.task.value)">
                <div class="form-group mb-1">
                  <label i18n>Stav tachometru při dokončení úkonu</label>
                  <input class="form-control form-control-sm" 
                  formControlName="completed_km" type="number" min="0"
                  (focus)="autoCompleteKm()"/>
                </div>
              </div>
            </div>

            <!-- suggestions from events -->
            <div *ngIf="fuelEvents?.length || trackingEvents?.length" class="row mt-5">
              <h6 class="col-12" i18n>Návrhy stavu tachografu</h6>
              <div *ngIf="fuelEvents?.length" class="col-12">
                <div class="d-flex text-primary eventRow" 
                (click)="editCompletedKm(fuelEvents[0].clock_up)">
                  <div class="w-25" i18n>Nejbližší tankování</div>
                  <div class="w-25">{{fuelEvents[0].timeDate | date: 'dd.MM.yyyy HH:mm'}}</div>
                  <div class="w-50">{{fuelEvents[0].clock_up | km}}km</div>
                </div>
              </div>
              <div *ngIf="trackingEvents?.length" class="col-12">
                <div class="d-flex text-primary eventRow" 
                (click)="editCompletedKm(trackingEvents[0].odometer)">
                  <div class="w-25" i18n>Událost z vozidla</div>
                  <div class="w-25">{{trackingEvents[0].timeDate | date: 'dd.MM.yyyy HH:mm'}}</div>
                  <div class="w-50">{{trackingEvents[0].odometer | km}}km</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-success mr-1" type="submit" i18n>Uložit</button>
          <button class="btn btn-light" type="button" data-dismiss="modal">
            <ng-container i18n>Zavřít</ng-container>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal for planning future service task via amortization_interval -->
<div class="modal fade" role="dialog" tabindex="-1" id="planModal">
  <form [formGroup]="planTaskForm" (ngSubmit)="submitPlanTaskForm()">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success shadow">
          <h5 class="modal-title">
            <ng-container i18n>Automatické plánování následujícího úkonu</ng-container>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <div *ngIf="planTask" class="smallMarginFormGroup">
            <div class="row mb-4">
              <!-- vehicles or trailers loaded -->
              <div *ngIf="vehicles.length || trailers.length" class="col-lg-6">
                <div *ngIf="isCarPlanTask" class="form-group mb-1">
                  <label><ng-container i18n>Vozidlo</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="car_key">
                    <option *ngFor="let v of vehicles" [ngValue]="v.car_key" [class.text-danger]="v.inactive">
                      {{v.number_plate}} {{v.driver_name}}
                    </option>
                  </select>
                </div>
                <div *ngIf="isTrailerPlanTask" class="form-group mb-1">
                  <label><ng-container i18n>Návěs/přívěs</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="trailer_key">
                    <option *ngFor="let t of trailers" [ngValue]="t.trailer_key">
                      {{t.number_plate}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- vehicle or trailer selected -->
              <div *ngIf="vehicle || trailer" class="col-lg-6">
                <div *ngIf="isCarPlanTask" class="form-group mb-1">
                  <label><ng-container i18n>Vozidlo</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="car_key">
                    <option *ngIf="vehicle" [ngValue]="vehicle.car_key">
                      {{vehicle.number_plate}} {{vehicle.driver_name}}
                    </option>
                  </select>
                </div>
                <div *ngIf="isTrailerPlanTask" class="form-group mb-1">
                  <label><ng-container i18n>Návěs/přívěs</ng-container> *</label>
                  <select class="form-control form-control-sm" formControlName="trailer_key">
                    <option *ngIf="trailer" [ngValue]="trailer.trailer_key">
                      {{trailer.number_plate}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label i18n>Typ plánu</label>
                  <select class="form-control form-control-sm" formControlName="type">
                    <option value="A" i18n>Plánovaný</option>
                    <option value="B" i18n>Neplánovaný</option>
                    <option value="C" i18n>Nadstandard</option>
                    <option value="D" i18n>Poplatky</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label><ng-container i18n>Úkon</ng-container> *</label>

                  <select class="form-control form-control-sm" formControlName="task"
                  [ngClass]="{'is-invalid': newTaskFormSubmitted && f_plan.task?.errors}">
                    <option [ngValue]="null" i18n>Vyberte</option>
                    <!-- <option value="A" i18n>Mýto</option> -->
                    <option value="B" i18n>Baterie (pořízení)</option>
                    <option value="C" i18n>Parkování</option>
                    <option value="L" i18n>Certifikát "L"</option>
                    <option value="G" i18n>Garanční prohlídka</option>
                    <option value="M" i18n>Mytí</option>
                    <option value="N" i18n>Nákup/pořízení vozidla</option>
                    <option value="D" i18n>Nákup náhradních dílů</option>
                    <option value="Y" i18n>Nadstandard</option>
                    <option value="O" i18n>Olej (výměna oleje a filtrů)</option>
                    <option value="H" i18n>Oprava (havárie)</option>
                    <option value="I" i18n>Pojištění</option>
                    <option value="P" i18n>Pneu 1 - hnací náprava</option>
                    <option value="Q" i18n>Pneu 2 - vlečená náprava</option>
                    <option value="R" i18n>Pneu 3</option>
                    <option value="S" i18n>Termínovaná prohlídka</option>
                    <option value="T" i18n>STK + Emise</option>
                    <option value="V" i18n>Kalibrace tacho</option>
                    <option value="W" i18n>Záloha tacho</option>
                    <option value="Z" i18n>Záruka do</option>
                    <option value="X" i18n>Ostatní</option>
                  </select>
                  <div *ngIf="newTaskFormSubmitted && f_plan.task?.errors" class="text-danger">
                    * <ng-container i18n>povinné pole</ng-container>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-1">
                  <label><ng-container i18n>Název úkonu</ng-container> *</label>
                  <input class="form-control form-control-sm" formControlName="code" type="text"
                  [ngClass]="{'is-invalid': newTaskFormSubmitted && f_plan.code?.errors}"/>
                  <div *ngIf="newTaskFormSubmitted && f_plan.code?.errors" class="text-danger">
                    * <ng-container i18n>povinné pole (max 40 znaků)</ng-container>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-1">
                  <label i18n>Poznámka</label>
                  <input class="form-control form-control-sm" formControlName="note" type="text"/>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-lg-4">
                <div class="form-group mb-1">
                  <label i18n>Cena</label>
                  <div class="input-group">
                    <input class="form-control form-control-sm" formControlName="price" type="number"/>
                    <div class="input-group-append">
                      <select class="form-control form-control-sm" formControlName="currency">
                        <option [ngValue]="null">-</option>
                        <option value="CZK">CZK</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option value="CHF">CHF</option>
                        <option value="DKK">DKK</option>
                        <option value="PLN">PLN</option>
                        <option value="NOK">NOK</option>
                        <option value="HUF">HUF</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group mb-1">
                  <label i18n>Amortizace/Interval servisu</label>
                  <div class="input-group input-group-sm">
                    <input class="form-control form-control-sm" 
                    formControlName="amortization_interval" type="number"/>
                    <div class="input-group-append">
                      <select class="form-control form-control-sm" formControlName="amortization_unit">
                        <option [ngValue]="null">-</option>
                        <option value="D" i18n>dny</option>
                        <option value="K">km</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group mb-1">
                  <label i18n>Pojistné plnění</label>
                  <div class="input-group input-group-sm">
                    <input class="form-control form-control-sm" 
                    formControlName="insurance_benefits" type="number"/>
                    <div class="input-group-append">
                      <span class="input-group-text">{{f_plan.currency.value}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="form-group mb-1">
                  <label i18n>Plánované datum úkonu</label>
                  <input class="form-control form-control-sm" 
                  formControlName="due_date" type="date"/>
                </div>
              </div>
              <div *ngIf="datePlanned(f_plan.task.value)" class="col-lg-4">
                <div class="form-group mb-1">
                  <label>1. <ng-container i18n>upozornění (počet dní před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder1" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="datePlanned(f_plan.task.value)" class="col-lg-4">
                <div class="form-group mb-1">
                  <label>2. <ng-container i18n>upozornění (počet dní před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder2" type="number" min="0"/>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group mb-1">
                  <label i18n>Plánovaný počet km (při úkonu)</label>
                  <input class="form-control form-control-sm" 
                  formControlName="due_km" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="kmPlanned(f_plan.task.value)" class="col-lg-4">
                <div class="form-group mb-1">
                  <label>1. <ng-container i18n>upozornění (počet km před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder1" type="number" min="0"/>
                </div>
              </div>
              <div *ngIf="kmPlanned(f_plan.task.value)" class="col-lg-4">
                <div class="form-group mb-1">
                  <label>2. <ng-container i18n>upozornění (počet km před)</ng-container></label>
                  <input class="form-control form-control-sm" 
                  formControlName="reminder2" type="number" min="0"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-success mr-1" type="submit">
            <ng-container i18n>Vytvořit</ng-container>
          </button>
          <button class="btn btn-light" type="button" data-dismiss="modal">
            <ng-container i18n>Neplánovat a zavřít</ng-container>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>