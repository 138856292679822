import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClientService} from "./http-client.service";
import {AuthenticationService} from "./authentication.service";
import {TruckManagerLayoutService} from "../service/truck-manager-layout.service";
import {HwInfo} from "../model/hw-info.object";
import {LastService, LastSupportInfo, LastUploadLog} from "../model/hw-info-data.object";
import {IS_DEMO, ServiceConfiguration} from "../config";

@Injectable({
  providedIn: 'root',
})
export class HwInfoService {

  private _apiUrl: string = ServiceConfiguration.gpsUnits.api;
  private _hwInfo: BehaviorSubject<Array<HwInfo>> = new BehaviorSubject([]);
  private _hwInfoCache: Array<HwInfo> = [];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _layout: TruckManagerLayoutService
  ) {
  }

  private _loadingHwInfo: boolean = false;

  get loadingHwInfo(): boolean {
    return this._loadingHwInfo;
  }

  public clearCache(): void {
    this._hwInfoCache = [];
  }

  getHwInfo(): Observable<Array<HwInfo>> {
    if (!this._hwInfoCache.length && !this._loadingHwInfo) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadHwInfo();
      }
    }
    return this._hwInfo.asObservable();
  }

  loadHwInfo() {
    this._loadingHwInfo = true;
    this._http.get(this._apiUrl)
      .subscribe(
        response => {
          if (response) {
            this._hwInfoCache = this.buildHwInfoFromData(response);
            // push data
            this._hwInfo.next(this._hwInfoCache);
          }
        },
        error => {
          //handle error
          console.log(error);
          this._loadingHwInfo = false;
        },
        () => {
          // finally
          this._loadingHwInfo = false;
        }
      );
  }

  // method for sending email with order
  // public sendEmail(data: any): Observable<any> {
  //   if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {

  //     // let dataToSend: any = {};
  //     // dataToSend["myData"] = JSON.stringify(data);
  //     // // let stringified: string = JSON.stringify(dataToSend);
  //     // console.log(dataToSend);
  //     return this._http.post(this._layout.staticContentResource + 'other/mailsender.php', data);
  //   }
  // }

  // method for creating hwInfo objects from api objects
  private buildHwInfoFromData(data: Array<any>): any {
    let hwInfo: Array<HwInfo> = [];
    data.forEach(
      hw => {
        let info: HwInfo = new HwInfo();

        if (hw.last_service) {
          let last_service = new LastService();
          for (let key in hw.last_service) {
            last_service[key] = hw.last_service[key];
          }
          hw.last_service = last_service;
        }
        if (hw.last_support_info) {
          let last_support_info = new LastSupportInfo();
          for (let key in hw.last_support_info) {
            last_support_info[key] = hw.last_support_info[key];
          }
          hw.last_support_info = last_support_info;
        }
        if (hw.last_upload_log) {
          let last_upload_log = new LastUploadLog();
          for (let key in hw.last_upload_log) {
            last_upload_log[key] = hw.last_upload_log[key];
          }
          hw.last_upload_log = last_upload_log;
        }

        for (let key in hw) {
          info[key] = hw[key];
        }
        hwInfo.push(info);
      }
    );
    return hwInfo;
  }
}
