import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IS_PRODUCTION, TransApi, TRANS_API_KEY, TRANS_CLIENT_ID, TRANS_CLIENT_SECRET } from '../config';
import { TransFreightOffer } from '../model/databanks-freight-offer.objects';
import { VehicleOffer, TransVehicleOffer } from '../model/databanks-vehicle-offer.object';
import { AuthenticationService } from './authentication.service';
import { NotificationService } from './notification-service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DatabanksService {

  static TRANS_USER_STORAGE: string = '_transUser';

  private _TRANS_CODE: string = null;
  private _TRANS_USER: any = null;

  private _transUserAuthenticated: boolean = false;
  get transUserAuthenticated(): boolean {
    return this._transUserAuthenticated;
  }
  // get transUserAuthenticated(): boolean {
  //   return (
  //     this._TRANS_USER && this._authService.user && this._TRANS_USER.admittance > Date.now()
  //   );
  //   // this._TRANS_USER.company_key == this._authService.user.company_key &&
  // }

  constructor(
    private _http: HttpClient,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService,
    private _storage: StorageService,
    private _activatedRoute: ActivatedRoute
  ) {
    // TRANS eu response with code and state
    this._activatedRoute.queryParams.subscribe(
      params => {
        if (params['code'] && params['state']) {
          // send token request
          this._TRANS_CODE = params['code'];
          this.getAccessToken();
        }
      }
    );

    this._authService.authenticationResult.subscribe(
      user => {
        if (user) {
          this.setUserFromStorage();
        }
      }
    );
    
    // using setter logic (problems with getter)
    window.setInterval(
      () => {
        if (this._TRANS_USER && this._authService.user && this._TRANS_USER.admittance > Date.now()) {
          this._transUserAuthenticated = true;
        }
        else {
          this._transUserAuthenticated = false;
        }
      }, 5000
    );
  }

  private setUserFromStorage() {
    let trans_user: any = this._storage.getItem(DatabanksService.TRANS_USER_STORAGE);
    if (trans_user && this._authService.isAuthenticated) {
      // set user
      this._TRANS_USER = JSON.parse(trans_user);
      if (this._TRANS_USER.company_key == this._authService.user.company_key) {
        this.setUserFromToken(this._TRANS_USER);
      }
    }
  }

  private setUserFromToken(trans_user: any): void {
    if (trans_user && trans_user.admittance > Date.now()) {
      // refresh earlier by 1 min
      this.automaticTokenRefresh(trans_user.admittance - Date.now() - 60000);

      if (this._TRANS_USER && this._authService.user && this._TRANS_USER.admittance > Date.now()) {
        this._transUserAuthenticated = true;
      }
      else {
        this._transUserAuthenticated = false;
      }
    }
  }
  
  private saveUserToStorage() {
    if (this._TRANS_USER) {
      this._storage.setItem(DatabanksService.TRANS_USER_STORAGE, JSON.stringify(this._TRANS_USER));
    } 
    // else {
    //   this._storage.removeItem(DatabanksService.TRANS_USER_STORAGE);
    // }
  }


  private _automaticTransAuthenticationRefresh: any;
  private automaticTokenRefresh(inMiliseconds: number) {
    let curTimeout = (inMiliseconds < 1000) ? 1000 : inMiliseconds;
    if (inMiliseconds < 900000) {
      inMiliseconds = 900000;
    }
    console.warn((new Date()).toLocaleString(), 'Automatic refresh in ' + curTimeout + 'ms');
    if (this._automaticTransAuthenticationRefresh) {
      window.clearTimeout(this._automaticTransAuthenticationRefresh);
    }
    this._automaticTransAuthenticationRefresh = window.setTimeout(
      () => {
        this.refreshToken();
        this.automaticTokenRefresh(inMiliseconds);
      },
      curTimeout
    );
  }
  

  // Opening url with query string according to format
  // https://www.trans.eu/api/general-information/authorization/authorize-using-jwt/
  public authenticate(): void {
    let redirect_uri: string = 'https://app2.truckmanager.eu/';
    if (IS_PRODUCTION) {
      redirect_uri = 'https://app.truckmanager.eu/';
    }

    // open auth page with formatted query string
    let url: string = TransApi.auth;
    url += '?response_type=code';
    url += '&client_id=' + TRANS_CLIENT_ID;
    url += '&state=' + this.generateRandomNumber(8);
    url += '&redirect_uri=' + redirect_uri;
    window.open(url, '_self');
  }
  
  // POST https://api.platform.trans.eu/ext/auth-api/accounts/token
  // https://www.trans.eu/api/general-information/authorization/authorize-using-jwt/
  public getAccessToken(): void {
    let url: string = TransApi.auth_token;
    
    let redirect_uri: string = 'https://app2.truckmanager.eu/';
    if (IS_PRODUCTION) {
      redirect_uri = 'https://app.truckmanager.eu/';
    }
    
    // x-www-form-urlencoded params - https://stackoverflow.com/questions/39863317/
    let params = new HttpParams();
    params = params.set('grant_type', 'authorization_code');
    params = params.set('code', this._TRANS_CODE);
    params = params.set('redirect_uri', redirect_uri);
    params = params.set('client_id', TRANS_CLIENT_ID);
    params = params.set('client_secret', TRANS_CLIENT_SECRET);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Api-key': TRANS_API_KEY,
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Credentials': 'true',
        // 'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Api-key'
      })
    };

    this._http.post(url, params, httpOptions).subscribe(
      response => {
        if (response) {
          // e.g.
          // { 
          //   "access_token": "59d9aa9b15cd59a61fc52014792efb6caa82373b", 
          //   "expires_in": 21600, 
          //   "token_type": "Bearer",
          //   "scope": "offers.loads.manage", 
          //   "refresh_token": "d52d1d998d6533a3be8e7f26f904be513287938b"
          // }
          console.log(response);
          this._TRANS_USER = response;

          // TODO move this tokening to auth service..
          // TODO databanks service is not parent, now its added only in obligation-list
          // add company and time 
          this._TRANS_USER.company_key = this._authService.user.company_key;
          // admittance for token refreshing
          let admittance: number = new Date().getTime();
          admittance += (this._TRANS_USER.expires_in * 1000);
          this._TRANS_USER.admittance = admittance;

          if (this._TRANS_USER && this._authService.user && this._TRANS_USER.admittance > Date.now()) {
            this._transUserAuthenticated = true;
          }

          this.saveUserToStorage();
          // refresh earlier by 1 min
          this.automaticTokenRefresh(this._TRANS_USER.admittance - Date.now() - 60000); 
        }
      },
      error => {
        let alertError: string = 'Chyba autentizace do TRANS.eu';
        this._notificationService.alert(alertError, 'error', 4000);
        // handle error
        console.log(error);
      },
    );
  }
  
  private refreshToken(): void {
    let url: string = TransApi.auth_token;

    // x-www-form-urlencoded params - https://stackoverflow.com/questions/39863317/
    let params = new HttpParams();
    params = params.set('grant_type', 'refresh_token');
    params = params.set('refresh_token', this._TRANS_USER.refresh_token);
    params = params.set('client_id', TRANS_CLIENT_ID);
    params = params.set('client_secret', TRANS_CLIENT_SECRET);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Api-key': TRANS_API_KEY
      })
    };

    // https://www.trans.eu/api/general-information/authorization/refreshing-access-token/
    this._http.post(url, params, httpOptions).subscribe(
      response => {
        if (response) {
          // e.g.
          // { 
          //   "access_token": "59d9aa9b15cd59a61fc52014792efb6caa82373b", 
          //   "expires_in": 3600, 
          //   "token_type": "Bearer",
          //   "refresh_token": "d52d1d998d6533a3be8e7f26f904be513287938b"
          // }
          this._TRANS_USER = response;

          // add company and time 
          this._TRANS_USER.company_key = this._authService.user.company_key;
          // admittance for token refreshing
          let admittance: number = new Date().getTime();
          admittance += (this._TRANS_USER.expires_in * 1000);
          this._TRANS_USER.admittance = admittance;
          
          if (this._TRANS_USER && this._authService.user && this._TRANS_USER.admittance > Date.now()) {
            this._transUserAuthenticated = true;
          }

          this.saveUserToStorage();
          // refresh earlier by 1 min
          this.automaticTokenRefresh(this._TRANS_USER.admittance - Date.now() - 60000); 
        }
      },
      error => {
        let alertError: string = $localize`Chyba autentizace do TRANS.eu`;
        this._notificationService.alert(alertError, 'error', 4000);
        // handle error
        console.log(error);
      },
    );
  }



  /********************************************************/
  /* TRANS eu api methods */
  /********************************************************/
  // POST /ext/vehicles-api/v1/vehicles
  createVehicleOffer(o: TransVehicleOffer): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated() && this._TRANS_USER) {
      let url: string = TransApi.vehicle;
      let data = o.apiObject;
      let httpOptions = this.getTransHeaders();

      this._http.post(url, data, httpOptions).subscribe(
        response => {
          console.log(response);
          result.next(response);
          
          let alertSuccess: string = 'Nabídka volného vozidla pro TRANS.eu byla úspěšně vytvořena';
          this._notificationService.alert(alertSuccess, 'success', 4000);
        },
        error => {
          // handle error
          console.log(error);
          let alertError: string = 'Chyba vytváření nabídky volného vozidla pro TRANS.eu.';
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // POST /ext/freights-api/v1/freight-exchange
  createFreightOffer(o: TransFreightOffer): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated() && this._TRANS_USER) {
      let url: string = TransApi.freight;
      let data = o.apiObject;
      let httpOptions = this.getTransHeaders();

      this._http.post(url, data, httpOptions).subscribe(
        response => {
          console.log(response);
          result.next(response);
          
          let alertSuccess: string = $localize`Nabídka nákladu pro TRANS.eu byla úspěšně vytvořena`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
        },
        error => {
          // handle error
          console.log(error);
          let alertError: string = $localize`Chyba vytváření nabídky nákladu pro TRANS.eu.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }


  // TRANS headers
  // Content-type: application/json
  // Accept: application/json
  // Authorization: Bearer {access_token}
  // Api-key: {unique_app_api_key}
  private getTransHeaders(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Api-key': TRANS_API_KEY,
        'Authorization': 'Bearer ' + this._TRANS_USER.access_token
      })
    };
  }

  private generateRandomNumber(len: number): string {
    const characters: string = '0123456789';
    let result: string = '';
    const charactersLength: number = characters.length;
    for (let i = 0; i < len; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}


