import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { Driver } from "../model/driver.object";
import { SimCard } from "../model/simcard.object";
import { Person } from "../model/person.object";
import { User } from "../model/user.object";
import { UserAccount } from "../model/user-account.object";
import { Company } from "../model/company.object";
import { DriverCard } from "../model/driver-card.object";
import { IS_DEMO, ServiceConfiguration } from "../config";
import { CompanyContact } from "../model/company-contact.object";
import { CompanyTools } from "../tools/CompanyTools";

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  private _drivers: Array<Driver> = [];
  private _driversSubject: BehaviorSubject<Array<Driver>> = new BehaviorSubject(this._drivers);
  private _driverByKeyCache: any = {};
  private _contacts: Array<CompanyContact> = [];
  private _contactsSubject: BehaviorSubject<Array<CompanyContact>> = new BehaviorSubject(this._contacts);
  private _simCards: Array<SimCard> = [];
  private _simCardsSubject: BehaviorSubject<Array<SimCard>> = new BehaviorSubject(this._simCards);
  private _persons: Array<Person> = [];
  private _personsSubject: BehaviorSubject<Array<Person>> = new BehaviorSubject(this._persons);
  private _userAccounts: Array<UserAccount> = [];
  private _userAccountsSubject: BehaviorSubject<Array<UserAccount>> = new BehaviorSubject(this._userAccounts);

  private _companyFull: Company = null;
  private _companyFullSubject: BehaviorSubject<Company> = new BehaviorSubject(this._companyFull);

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
    this._authService.authenticationResult.subscribe(
      (user: User | null) => {
        this.loadCompanyDetails();
      }
    );
  }

  private _company_key: number = null;
  get company_key(): number {
    return this._company_key;
  }

  private _company_id: string = "";
  get company_id(): string {
    return this._company_id;
  }

  private _company: string = "";
  get company(): string {
    return this._company;
  }

  private _country: string = "";
  get country(): string {
    return this._country;
  }

  private _loadingCompany: boolean = false;
  get loadingCompany(): boolean {
    return this._loadingCompany;
  }

  public cleanUp(): void {
    this._driverByKeyCache = {};
    this._drivers = [];
    this._contacts = [];
    this._simCards = [];
    this._persons = [];
    this._userAccounts = [];
    this._companyFull = null;
    this._driversSubject.next(this._drivers);
    this._simCardsSubject.next(this._simCards);
    this._personsSubject.next(this._persons);
    this._userAccountsSubject.next(this._userAccounts);
    this._companyFullSubject.next(this._companyFull);
  }

  getDriversObservable(): Observable<Array<Driver>> {
    if (this._drivers.length === 0 && !this._loadingCompany) {
      this.loadCompanyDetails();
    }
    return this._driversSubject.asObservable();
  }

  getContactsObservable(): Observable<Array<CompanyContact>> {
    if (this._contacts.length === 0 && !this._loadingCompany) {
      this.loadCompanyDetails();
    }
    return this._contactsSubject.asObservable();
  }

  getSimCardsObservable(): Observable<Array<SimCard>> {
    if (this._simCards.length === 0 && !this._loadingCompany) {
      this.loadCompanyDetails();
    }
    return this._simCardsSubject.asObservable();
  }

  getPersonsObservable(): Observable<Array<Person>> {
    if (this._persons.length === 0 && !this._loadingCompany) {
      this.loadCompanyDetails();
    }
    return this._personsSubject.asObservable();
  }

  getUserAccountsObservable(): Observable<Array<UserAccount>> {
    if (this._userAccounts.length === 0 && !this._loadingCompany) {
      this.loadCompanyDetails();
    }
    return this._userAccountsSubject.asObservable();
  }

  getCompanyFullObservable(): Observable<Company> {
    if (!this._companyFull && !this._loadingCompany) {
      this.loadCompanyDetails();
    }
    return this._companyFullSubject.asObservable();
  }

  getDriverById(driverKey: number): Driver | null {
    let found: Driver = null;
    if (this._driverByKeyCache[driverKey]) {
      return this._driverByKeyCache[driverKey];
    }
    if (this._drivers.length) {
      this._drivers.forEach(
        driver => {
          if (driver.driver_key === driverKey) {
            found = driver;
            this._driverByKeyCache[driverKey] = driver;
            return false;
          }
        }
      )
    }
    return found;
  }


  private pushDrivers(drivers: Array<Driver>) {
    this._drivers = drivers;
    this._driversSubject.next(this._drivers);
  }
  
  private pushContacts(contacts: Array<CompanyContact>) {
    this._contacts = contacts;
    this._contactsSubject.next(this._contacts);
  }

  private pushSimCards(simcards: Array<SimCard>) {
    this._simCards = simcards;
    this._simCardsSubject.next(this._simCards);
  }

  private pushPersons(persons: Array<Person>) {
    this._persons = persons;
    this._personsSubject.next(this._persons);
  }

  private pushUserAccounts(users: Array<UserAccount>) {
    this._userAccounts = users;
    this._userAccountsSubject.next(this._userAccounts);
  }

  private pushCompany(company: Company) {
    this._companyFull = company;
    this._companyFullSubject.next(this._companyFull);
  }

  private loadCompanyDetails(): void {
    this.cleanUp();
    this._loadingCompany = true;

    this._http.get(ServiceConfiguration.company.api).subscribe(
      response => {
        if (response.company_key) {
          this._company_key = response.company_key;
        }
        // id (eg. CZ000001)
        if (response.id) {
          this._company_id = response.id;
        }
        // company name (eg. Euro Sped Online)
        if (response.company) {
          this._company = response.company;
        }
        // company country
        if (response.country) {
          this._country = response.country;
        }
        // solve contacts
        if (response.contacts) {
          let contacts: Array<CompanyContact> = CompanyTools.buildContactsFromData(response.contacts);
          response.contactsArray = contacts;
          this.pushContacts(contacts);
        }
        // solve drivers
        if (response.drivers) {
          let drivers: Array<Driver> = CompanyTools.buildDriversFromData(response.drivers);
          response.driversArray = drivers;
          this.pushDrivers(drivers);
        }
        // solve sim cards
        if (response.sim_cards) {
          let simcards: Array<SimCard> = CompanyTools.buildSimcardsFromData(response.sim_cards);
          response.simcardsArray = simcards;
          this.pushSimCards(simcards);
        }
        // solve persons
        if (response.persons) {
          let persons: Array<Person> = CompanyTools.buildPersonsFromData(response.persons);
          response.personsArray = persons;
          this.pushPersons(persons);
        }
        // solve user accounts
        if (response.user_accounts) {
          let user_accounts: Array<UserAccount> = [];
          user_accounts = CompanyTools.buildUserAccountsFromData(response.user_accounts);
          response.userAccountsArray = user_accounts;
          this.pushUserAccounts(user_accounts);
        }

        // solve whole company object
        if (response) {
          let companyObject: Company = CompanyTools.buildCompany(response);
          this.pushCompany(companyObject);
        }
        this._loadingCompany = false;
      },
      error => {
        console.log(error);
      }
    );
  }


  /***********************************************/
  /* Driver creating/updating */
  /***********************************************/
  // POST /company/driver/
  createDriver(driver: Driver): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let data = {
        name: driver.name,
        active: true
      };

      // set loading flag
      this._loadingCompany = true;

      this._http.post(ServiceConfiguration.company.apiDriver + '/', data).subscribe(
        success => {
          if (success) {
            // save new driver
            let driver = CompanyTools.buildDriver(success);
            this._drivers.push(driver);
            this._driversSubject.next(this._drivers);
            this._loadingCompany = false;

            // success alert
            let alert = $localize`Řidič %Name byl úspěšně vytvořen.`;
            alert = alert.replace('%Name', driver.name);
            this._notificationService.alert(alert, 'success', 3000);
          }
        },
        error => {
          this._loadingCompany = false;
          console.log(error);
          // error alert
          let alert = $localize`Chyba při vytváření řidiče %Name.`;
          alert = alert.replace('%Name', driver.name);
          this._notificationService.alert(alert, 'error', 3000);
        }
      );
    }
    return this._driversSubject.asObservable();
  }
  
  // PUT /company/driver/<driver_key>
  public updateDriver(driver: Driver): Observable<any> {
    let updVehicle: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let data = driver.apiObject;

      if (Object.keys(data).length) {
        this._http.put(ServiceConfiguration.company.apiDriver + '/' + driver.driver_key, data).subscribe(
          response => {
            // remove all updated values
            driver.update = [];
            let alert = $localize`Řidič %Name byl úspěšně upraven.`;
            alert = alert.replace('%Name', driver.name);
            this._notificationService.alert(alert, 'success', 3000);
          },
          error => {
            console.log(error);
            let alert = $localize`Chyba úpravy řidiče %Name.`;
            alert = alert.replace('%Name', driver.name);
            this._notificationService.alert(alert, 'error', 3000);
          }
        );
      }
    }

    return updVehicle.asObservable();
  }


  /***********************************************/
  /* Contact creating/updating */
  /***********************************************/
  // POST /company/contact/
  createContact(contact: CompanyContact): Observable<any> {
    let result: BehaviorSubject<CompanyContact> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.post(ServiceConfiguration.company.apiContact + '/', contact.apiObject).subscribe(
        response => {
          if (response) {
            let c = CompanyTools.buildContact(response);
            result.next(c);
            // success alert
            let alert = $localize`Kontakt %VALUE% byl úspěšně vytvořen.`;
            alert = alert.replace('%VALUE%', contact.value);
            this._notificationService.alert(alert, 'success', 3000);
          }
        },
        error => {
          console.log(error);
          // error alert
          let alert = $localize`Chyba při vytváření kontaktu %VALUE%.`;
          alert = alert.replace('%VALUE%', contact.value);
          this._notificationService.alert(alert, 'error', 3000);
        }
      );
    }
    return result.asObservable();
  }
  
  // PUT /company/contact/<contact_key>
  public updateContact(contact: CompanyContact): Observable<any> {
    let result: BehaviorSubject<CompanyContact> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.company.apiContact + '/' + contact.contact_key;
      this._http.put(url, contact.apiObject).subscribe(
        response => {
          let c = CompanyTools.buildContact(response);
          result.next(c);
          // success alert
          let alert = $localize`Kontakt %VALUE% byl úspěšně upraven.`;
          alert = alert.replace('%VALUE%', contact.value);
          this._notificationService.alert(alert, 'success', 3000);
        },
        error => {
          console.log(error);
          let alert = $localize`Chyba úpravy kontaktu %VALUE%.`;
          alert = alert.replace('%VALUE%', contact.value);
          this._notificationService.alert(alert, 'error', 3000);
        }
      );
    }

    return result.asObservable();
  }


  
  /***********************************************/
  /* User accounts - new version creating/updating */
  /***********************************************/
  private _loadingUserAccounts: boolean = false;
  get loadingUserAccounts(): boolean {
    return this._loadingUserAccounts;
  }

  getUserAccounts(): Observable<Array<UserAccount>> {
    let result: BehaviorSubject<Array<UserAccount>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.user_accounts.api;

      this._loadingUserAccounts = true;
      this._http.get(url).subscribe(
        response => {
          // console.log(response);
          let user_accounts = CompanyTools.buildUserAccountsFromData(response);
          result.next(user_accounts);
          this._loadingUserAccounts = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingUserAccounts = false;
        }
      );
    }

    return result.asObservable();
  }

  // POST /company/account/
  createUserAccount(user_account: UserAccount): Observable<UserAccount> {
    let result: BehaviorSubject<UserAccount> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.post(ServiceConfiguration.user_accounts.api, user_account.apiObject).subscribe(
        response => {
          if (response) {
            let user = CompanyTools.buildUserAccount(response);
            result.next(user);
            // success alert
            let alert = $localize`Uživatelský účet ` + user_account.username;
            alert += $localize` byl úspěšně vytvořen.`;
            this._notificationService.alert(alert, 'success', 4000);
          }
        },
        error => {
          console.log(error);
          // error alert
          let alert = $localize`Chyba vytváření uživatele ` + user_account.username;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }
  
  // PUT /company/account/<username>
  updateUserAccount(user_account: UserAccount): Observable<any> {
    let result: BehaviorSubject<UserAccount> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.user_accounts.api_username;
      url = url.replace('<USERNAME>', user_account.username);
      this._http.put(url, user_account.apiObject).subscribe(
        response => {
          let user = CompanyTools.buildUserAccount(response);
          result.next(user);
          // success alert
          let alert = $localize`Uživatelský účet ` + user_account.username;
          alert += $localize` byl úspěšně upraven.`;
          this._notificationService.alert(alert, 'success', 4000);
        },
        error => {
          console.log(error);
          // error alert
          let alert = $localize`Chyba úpravy uživatele ` + user_account.username;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  
  // DELETE /company/account/<username>
  deleteUserAccount(user_account: UserAccount): Observable<boolean> {
    let result: BehaviorSubject<boolean> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.user_accounts.api_username;
      url = url.replace('<USERNAME>', user_account.username);
      this._http.delete(url).subscribe(
        response => {
          result.next(true);
          // success alert
          let alert = $localize`Uživatelský účet ` + user_account.username;
          alert += $localize` byl úspěšně odstraněn.`;
          this._notificationService.alert(alert, 'success', 4000);
        },
        error => {
          console.log(error);
          // error alert
          let alert = $localize`Chyba odstranění uživatele ` + user_account.username;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }
}