import { connections } from "./connect";

// TODO local test DEMO
// let containsDemo = window.location.href.match(/localhost:/);
let containsDemo = window.location.href.match(/demo/);
export const IS_DEMO = containsDemo !== null && containsDemo.length > 0;
let containsApp = window.location.href.match(/app.truckmanager.eu/);
export const IS_PRODUCTION = containsApp !== null && containsApp.length > 0;

export const HIGHLIGHT_DRIVER_NAMES: string = 'drivers';
export const HIGHLIGHT_LICENCE_PLATES: string = 'plates';

export const MAP_ONLY_CURRENT_AGENDA: string = 'current';
export const MAP_ALL_AGENDA: string = 'all';

// superuser is just "trdlo"
export const SUPERUSER_COMPANY_KEYS: Array<number> = [ 4438 ];


export const RECAPTCHA_SITE_KEY: string = '6Lcvt8UUAAAAAHFAYec37VLmmHeSUi6TWtSBbLC5';

export const OPENAI_API_KEY: string = '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';

// specific users / customers
export const WIENERBERGER_TIN: string = 'CZ00015253';

let api = connections.apiUrl;
// let api = connections.apiLocahost;

// https://stackoverflow.com/a/46181
export const EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const ItineraryType = {
  LOADING           : 'L',
  UNLOADING         : 'U',
  WAREHOUSE         : 'W',
  WAREHOUSE_AUTO    : 'V',  // warehouse without truckagenda interaction
  TRANSSHIPMENT     : 'T',
  TRANSIT           : 'X',
  MOVING            : 'M',
  FUELING           : 'F',
  OLD_LOADING       : 'A',
  OLD_UNLOADING     : 'B',
  OLD_TRANSIT       : 'C',
  OLD_MOVING        : 'D',
  OLD_FUELING       : 'E'
};

export const ServiceType = {
  LOADING_START     : 'l',
  LOADING_END       : 'L',
  UNLOADING_START   : 'u',
  UNLOADING_END     : 'U',
  FUELING           : 'F',
  TOLL              : 'T',
  CHARGING          : '+',
  NOT_CHARGING      : '-',
  POWER_OFF         : 'v',
  POWER_ON          : '^',
  UNKNOWN           : 'R'
}

export const InvoiceType = {
  ISSUED          : 'I',
  RECEIVED        : 'R',
  CASH            : 'C',
  OTHER           : 'O',
  CREDIT_NOTE     : 'N'
}

export const TrackingStatus = {
  SLEEP_START       : '*',
  SLEEP_END         : '#',
  INTERRUPTION      : 'X',
  STOP_START        : '_',
  STOP_END          : '=',
  MOVE              : 'M',
  FERRY             : 'F'
}

// same status for obligation / express route
export const ObligationStatus = {
  K_VYRIZENI        : 'E',
  POPTAVKA          : 'P',
  ZAHAJENA          : 'A',
  SKLAD_SVOZ        : 'G',
  SKLAD             : 'H',
  SKLAD_K_ROZVOZU   : 'L',
  SKLAD_ROZVOZ      : 'J',
  K_FAKTURACI       : 'B',
  FAKTUROVANA       : 'C',
  DOKONCENA         : 'F',
  MIMO_PROVOZ       : 'K',
  STORNOVANA        : 'D'
}

// same status for order
export const OrderStatus = {
  EVIDOVANA        : 'A',
  NALOZENA         : 'L',
  VYLOZENA         : 'U',
  DOKONCENA        : 'B',
  STORNOVANA       : 'C'
}


export const CarTypeName = {
  Q   : $localize`Autobus`,
  A   : $localize`Autojeřáb`,
  V   : $localize`Dodávka`,
  R   : $localize`Minibus`,
  P   : $localize`Osobní auto`,
  Y   : $localize`PickUp`,
  W   : $localize`Tahač`,
  T   : $localize`Cisterna`,
  C   : $localize`Cívky / Mulda`,
  F   : $localize`Frigo`,
  I   : $localize`Izoterm`,
  J   : $localize`Jumbo`,
  K   : $localize`Kontejner`,
  Z   : $localize`Lowdeck`,
  H   : $localize`Podvalník`,
  U   : $localize`Odtah vozidel`,
  N   : $localize`Plachta`,
  O   : $localize`Plato`,
  '1' : $localize`Posuvná podlaha`,
  E   : $localize`Přeprava aut`,
  '2' : $localize`Přeprava dřeva`,
  G   : $localize`Přeprava zvířat`,
  S   : $localize`Silo`,
  D   : $localize`Sklápěcí`,
  B   : $localize`Skříň`,
  X   : $localize`Speciál`,
  M   : $localize`Stěhovací`,
  L   : $localize`Tautliner`
}

export const AetrActivityName = {
  'S' : $localize`Odpočinek`,
  'P' : $localize`Výkon`,
  'D' : $localize`Jízda`
}

export const UserPermission = {
  COMPANY_SUPERUSER               : 'COMPANY_SUPERUSER',
  COMPANY_DETAILS_MANAGE          : 'COMPANY_DETAILS_MANAGE',
  OBLIGATION_READ                 : 'OBLIGATION_READ',
  OBLIGATION_MANAGE               : 'OBLIGATION_MANAGE',
  ORDER_READ                      : 'ORDER_READ',
  ORDER_MANAGE                    : 'ORDER_MANAGE',
  INVOICE_READ                    : 'INVOICE_READ',
  INVOICE_MANAGE                  : 'INVOICE_MANAGE',
  STATISTICS_SALES_PROFIT         : 'STATISTICS_SALES_PROFIT',
  STATISTICS_OVERVIEW_RANKINGS    : 'STATISTICS_OVERVIEW_RANKINGS',
  STATISTICS_INVOICES_OVERVIEW    : 'STATISTICS_INVOICES_OVERVIEW',
  STATISTICS_OBLIGATIONS_OVERVIEW : 'STATISTICS_OBLIGATIONS_OVERVIEW',
  EXPORT_OBLIGATIONS              : 'EXPORT_OBLIGATIONS',
  EXPORT_INVOICES                 : 'EXPORT_INVOICES',
  EXPORT_ORDERS                   : 'EXPORT_ORDERS',
  EXPORT_ADRESS_BOOK              : 'EXPORT_ADRESS_BOOK',
  HISTORY_ALLOW                   : 'HISTORY_ALLOW',
  STATEMENTS_ALLOW                : 'STATEMENTS_ALLOW',
  STATEMENTS_EPD_ALLOW            : 'STATEMENTS_EPD_ALLOW',
  STATEMENTS_CN_ALLOW             : 'STATEMENTS_CN_ALLOW',
  STATEMENTS_KJ_ALLOW             : 'STATEMENTS_KJ_ALLOW',
  STATEMENTS_HVV_ALLOW            : 'STATEMENTS_HVV_ALLOW',
  STATEMENTS_EXPORT_INVOICES_ALLOW : 'STATEMENTS_EXPORT_INVOICES_ALLOW',
  SERVICES_ALLOW                  : 'SERVICES_ALLOW',
  CARS_ALLOW                      : 'CARS_ALLOW',
  REMINDERS_ALLOW                 : 'REMINDERS_ALLOW',
  SETTINGS_TEXT_ALLOW             : 'SETTINGS_TEXT_ALLOW',
  SETTINGS_USER_ALLOW             : 'SETTINGS_USER_ALLOW',
  OTHER_COSTS_USERS               : 'OTHER_COSTS_USERS'
}

export const ServiceConfiguration = {
  global : {
    lengthUnitKm        : 'km',
    lengthUnitMile      : 'm'
  },
  authentication : {
    api                             : api + '/auth/token',
    authorizationHeader             : 'Authorization',
    authorizationHeaderValueFormat  : 'Bearer %TOKEN%',
    requestTimeHeader               : 'RequestTime',
    token                           : 'bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0'
  },
  translation : {
    api                 : 'data/locale-%LOCALE%.json',
    locale              : window.navigator.language,
    localeFallback      : 'cs',
    locales             : ['en','cs']
  },
  websocket : {
    demo_token          : "usr_anonymous_______|00000000-0000-0000-0000-000000000000",
    demo_company        : 4439
  },
  webConnectivity : {
    pingInterval        : 30000,
    pingUrl             : connections.pingUrl,
    intervalReconnected : 60000
  },
  layout : {
    unAuthenticatedStaticContent    : connections.iframeSrcUnathenticated,
    authenticatedStaticContent      : connections.iframeScrAuthenticated,
    demoStaticContent               : connections.iframeSrcDemo,
    vehicleDetailHeight             : '320px',
    vehicleDetailHeightMobile       : '250px',
    dashboardMenuWidth              : '50px',
    staticContentName               : '',
    dashboardWidth                  : '600px'
  },
  notification : {
    notificationBadge       : 'assets/images/logoTmMin.png',
    notificationIcon        : 'assets/images/logoTm.png'
  },
  userConfiguration : {
    defaultVehicleListConfiguration : {
      dashboard: 'light', // 'full'
      last_cargo_text_show: true,
      aetr_show: true,
      gantt_show: true,
      messages_show: true,
      sound_alert: true,
      vehicle_filters: {},
      vehicle_filter: [],
      vehicle_person_filters: {},
      vehicle_person_filter: [],
      vehicle_user_filters: {},
      vehicle_user_filter: [],
      vehicle_list_highlight: HIGHLIGHT_DRIVER_NAMES,
      vehicle_map_agenda: MAP_ONLY_CURRENT_AGENDA // MAP_ALL_AGENDA
      // all_maps_show: false,
      // last_cargo_graphical_show: true,
      // vehicle_details_show: true,
    },
    defaultMessagesConfiguration: {
      new_messages_dialog_show: false,
      new_messages_sound_notification: true,
      order_messages_show: true,
      vehicle_messages_show: true
    }
  },
  vehicle : {
    api                         : api + '/cars',
    apiLight                    : api + '/cars/?mode=light',
    apiInactive                 : api + '/cars/?inactive=true',
    apiInactiveLight            : api + '/cars/?inactive=true&mode=light',
    apiCarKey                   : api + '/cars/%CAR_KEY%',
    apiCarKeyLight              : api + '/cars/%CAR_KEY%/?mode=light',
    servicePlan                 : api + '/cars/servicing-plan/',
    servicePlanKey              : api + '/cars/servicing-plan/<TASK_KEY>',
    servicePlanInvoices         : api + '/cars/servicing-plan/<TASK_KEY>/invoices/',
    apiMakes                    : api + '/makes/?type=C',
    apiModels                   : api + '/models/?type=C',
    apiAetrCars                 : api + '/aetrs',
    apiAetrCar                  : api + '/aetrs/%CAR_KEY%',
    apiDriveByAetr              : api + '/aetrs/%CAR_KEY%/drive',
    apiAetrInfoCar              : api + '/aetrs/%CAR_KEY%/info',
    apiServices                 : api + '/aetrs/%CAR_KEY%/service',
    apiAetrCarLatest            : api + '/aetrs/%CAR_KEY%/last',
    apiHealth                   : api + '/cars/%CAR_KEY%/health',
    apiAgenda                   : api + '/cars/%CAR_KEY%/agenda',
    apiAgendaServiceEvent       : api + '/cars/%CAR_KEY%/tm-agenda/%ITINERARY_KEY%',
    apiTmAgenda                 : api + '/cars/%CAR_KEY%/tm-agenda',
    apiHomeStand                : api + '/cars/<CAR_KEY>/alt-home/',
    dispatcher_board_days       : [-3,4],
    dispatcher_board_day_width  : '576px' // 24px is one hour 
  },
  trailers : {
    api                 : api + '/trailers',
    apiInactive         : api + '/trailers/?inactive=true',
    apiMakes            : api + '/makes/?type=T',
    apiModels           : api + '/models/?type=T',
    servicePlanKey      : api + '/trailers/servicing-plan/<TASK_KEY>',
    servicePlanInvoices : api + '/trailers/servicing-plan/<TASK_KEY>/invoices/',
  },
  reachEvent : {
    api                 : api + '/reach-events/' 
  },
  trackingEvent : {
    api                 : api + '/events/tracking/' 
  },
  serviceEvent : {
    api                 : api + '/events/service/' 
  },
  warehouseEvent : {
    api                 : api + '/events/warehouse/' 
  },
  fuelcardEvent : {
    api                 : api + '/events/fuelcard/' 
  },
  cities : {
    api                 : api + '/city/' 
  },
  tachograph : {
    api                 : api + '/tachograph/card',
    apiBackups          : api + '/tachograph/card/files/zip',
    apiBackupsLatest    : api + '/tachograph/card/files/zip/?type=latest'
  },
  gpsUnits : {
    api                 : api + '/hwinfo',
  },
  routes: {
    api                 : api + '/dest-route'
  },
  message : {
    apiMessageDashboard         : api + '/events/message/dashboard',
    apiMessageDashboardUnread   : api + '/events/message/dashboard/?unread=true',
    apiMessage                  : api + '/events/message',
    apiEditMessage              : api + '/events/message/%ID%',
    soundNotificationFileUrlIE  : 'data/sound/new-message.mp3',
    soundNotificationFileUrl    : 'data/sound/new-message.ogg'
  },
  files : {
    filesApiVehicle             : api + '/files/%CAR_KEY%/%FILE_NAME%/%WAY%',
    filesApiVehicleUpload       : api + '/files/%CAR_KEY%/%FILE_NAME%',
    filesPdfConvertor           : api + '/files/topdf',
    filesPdfToHtml              : api + '/files/pdftohtml',
    companyFiles                : api + '/files/company/',
    filesSearch                 : api + '/files/search/',
    filesCarSearch              : api + '/files/search/%CAR_KEY%/',
  },
  company: {
    api             : api + '/company',
    apiDriver       : api + '/company/driver',
    apiPerson       : api + '/company/person',
    apiContact      : api + '/company/contact'
  },
  settings: {
    apiSeries           : api + '/settings/series',
    apiInvoicing        : api + '/settings/invoicing',
    apiInvoicingDefault : api + '/settings/invoicing/default',
    apiDocs             : api + '/settings/docs',
    apiDocsDefault      : api + '/settings/docs/default'
  },
  addressBook: {
    api                 : api + '/address-book/',
    apiKey              : api + '/address-book/<BOOK_KEY>',
    apiPersons          : api + '/address-book/<BOOK_KEY>/persons/',
    apiPersonsKey       : api + '/address-book/<BOOK_KEY>/persons/<PERSON_KEY>',
    apiCarDests         : api + '/address-book/<BOOK_KEY>/car-dests/',
    apiCarDestsKey      : api + '/address-book/<BOOK_KEY>/car-dests/<DEST_KEY>',
    apiAttachment       : api + '/address-book/<BOOK_KEY>/attachment/',
    apiAttachmentKey    : api + '/address-book/<BOOK_KEY>/attachment/<ATTACHMENT_KEY>',
    apiPricelist        : api + '/address-book/<BOOK_KEY>/pricelist/',
    apiPricelistKey     : api + '/address-book/<BOOK_KEY>/pricelist/<PRICELIST_KEY>',
    apiFavorite         : api + '/address-book/favorite/',
    apiFavoriteKey      : api + '/address-book/favorite/<OUL_KEY>',
    apiFavoriteInside   : api + '/address-book/favorite/<OUL_KEY>/inside?lat=<LAT>&lng=<LNG>'
  },
  exchangeRates: {
    api                 : api + '/exchange-rates'
  },
  obligations: {
    api                             : api + '/obligations/',
    apiObligMsg                     : api + '/obligations/<OBLIGATION_KEY>/msg/',
    apiObligAttachment              : api + '/obligations/<OBLIGATION_KEY>/attachment/',
    apiObligAttachmentId            : api + '/obligations/<OBLIGATION_KEY>/attachment/%ID%',
    apiObligEmail                   : api + '/obligations/<OBLIGATION_KEY>/email',
    apiObligEmails                  : api + '/obligations/<OBLIGATION_KEY>/emails',
    apiObligPreview                 : api + '/obligations/<OBLIGATION_KEY>/preview?lang=<LANG>',
    apiObligAgreementPreview        : api + '/obligations/<OBLIGATION_KEY>/agreement/preview?lang=<LANG>',
    apiObligAgreementEmail          : api + '/obligations/<OBLIGATION_KEY>/agreement/email?lang=<LANG>',
    apiObligRenameFile              : api + '/obligations/<OBLIGATION_KEY>/rename-file/<NAME>?name=<NEWNAME>',
    apiExternalServiceEventToken    : api + '/obligations/<OBLIGATION_KEY>/complete-token',
    apiExternalObligationCreate     : api + '/obligations/new-obligations-token?book=<BOOK_KEY>',
    apiExternalObligationDelete     : api + '/address-book/<BOOK_KEY>/token',
    apiViscofanImport               : api + '/obligations/<OBLIGATION_KEY>/viscofan/',
    apiOrderItineraryComplete       : api + '/obligations/complete-itinerary?token=<TOKEN>&oblig_key=<OBLIGATION_KEY>&itin_key=<ITINERARY_KEY>',
    apiObligItinerary               : api + '/obligations/<OBLIGATION_KEY>/itinerary/',
    apiItineraryLatest              : api + '/obligations/itinerary/?latest=<TYPE>',
    apiCosts                        : api + '/obligations/costs/',
    apiCostsKey                     : api + '/obligations/<OBLIGATION_KEY>/costs/',
  },
  express: {
    api                     : api + '/express-route/',
    apiExpressKey           : api + '/express-route/%EXPRESS_KEY%',
    apiObligations          : api + '/express-route/%EXPRESS_KEY%/obligations'
  },
  invoices: {
    api                     : api + '/invoices/',
    apiInvoiceKey           : api + '/invoices/%INVOICE_KEY%',
    apiInvoiceObligation    : api + '/invoices/%INVOICE_KEY%/obligations/',
    apiInvoiceObligationDel : api + '/invoices/%INVOICE_KEY%/obligations/%OBLIGATION_KEY%',
    apiInvoiceItems         : api + '/invoices/%INVOICE_KEY%/items/',
    apiInvoiceItemsKey      : api + '/invoices/%INVOICE_KEY%/items/%ITEM_KEY%',
    apiBulkReminders        : api + '/invoices/reminders/send',
    apiAttachment           : api + '/invoices/%INVOICE_KEY%/attachment/',
    apiReminders            : api + '/invoices/%INVOICE_KEY%/reminders/',
    apiRemindersDelete      : api + '/invoices/%INVOICE_KEY%/reminders/%NUMBER%',
    apiInvoicePrint         : api + '/invoices/%INVOICE_KEY%/print',
    apiInvoicePreview       : api + '/invoices/%INVOICE_KEY%/preview?lang=%LANG%',
    apiInvoiceEmail         : api + '/invoices/%INVOICE_KEY%/email',
    apiInvoiceEmails        : api + '/invoices/%INVOICE_KEY%/emails',
    apiInvoicePrintOpen     : api + '/email/%EMAIL_ID%/attachment-by-number/1',
    templateDictionary      : 'data/invoices.json'
  },
  orders: {
    api                     : api + '/orders/',
    apiOrderKey             : api + '/orders/%ORDER_KEY%',
    apiOrderObligation      : api + '/orders/%ORDER_KEY%/obligations/',
    apiOrderObligationDel   : api + '/orders/%ORDER_KEY%/obligations/%OBLIGATION_KEY%',
    apiOrderInvoice         : api + '/orders/%ORDER_KEY%/invoices/',
    apiOrderInvoiceDel      : api + '/orders/%ORDER_KEY%/invoices/%INVOICE_KEY%',
    apiOrderPrint           : api + '/orders/%ORDER_KEY%/print',
    apiOrderPreview         : api + '/orders/%ORDER_KEY%/preview?lang=%LANG%',
    apiOrderEmail           : api + '/orders/%ORDER_KEY%/email',
    apiOrderEmails          : api + '/orders/%ORDER_KEY%/emails',
    apiOrderPrintOpen       : api + '/email/%EMAIL_ID%/attachment-by-number/1',
    apiExternalToken        : api + '/orders/%ORDER_KEY%/accept-token'
  },
  emailing: {
    send            : api + '/email/send',
    sendFile        : api + '/email/send-file',
    attachments     : api + '/email/%MESSAGE_ID/attachment',
    attachment      : api + '/email/%MESSAGE_ID/attachment/%FILE_NAME',
    zip             : api + '/email/%MESSAGE_ID/zip',
    show            : api + '/email/%MESSAGE_ID/show',
    print           : api + '/email/%MESSAGE_ID/print',
    header          : api + '/email/%MESSAGE_ID/header',
  },
  reportSchedule: {
    api             : api + '/report-schedule'
  },
  delegations: {
    api             : api + '/delegations/',
    apiPost         : api + '/delegations/?action=%ACTION%'
  },
  external: {
    apiWoodSystem       : api + '/wood-system/log/?car_key=%CAR_KEY%&intent=%INTENT%',
    apiImport           : api + '/external/import/',
    apiImportCar        : api + '/external/import/%TYPE%/car',
    apiImportAvailable  : api + '/external/import/available',
    apiExportAvailable  : api + '/external/export/available',
    apiExport           : api + '/external/export/',
    apiOrder            : api + '/external/orders/?token=<TOKEN>&order=<ORDER>',
    apiOrderPdf         : api + '/external/orders/pdf?token=<TOKEN>&order=<ORDER>'
  },
  internal: {
    apiRequestsLogs     : api + '/internal/logs/external-api-request/'
  },
  sms: {
    api             : api + '/sms/'
  },
  imsi: {
    api             : api + '/sim/',
    apiImsiKey      : api + '/sim/%IMSI%'
  },
  drivingExclusions: {
    api             : api + '/driving-exclusions/public-holidays/?country=<COUNTRY>',
  },
  doc_parser: {
    api                 : api + '/doc-parser/obligation/',
    apiGetTin           : api + '/doc-parser/obligation/?tin=%TIN%',
    apiPutTin           : api + '/doc-parser/obligation/%TIN%/%ITIN_COUNT%',
    apiPostItin         : api + '/doc-parser/obligation/%TIN%/%ITIN_COUNT%/itinerary/',
    apiPutItin          : api + '/doc-parser/obligation/%TIN%/%ITIN_COUNT%/itinerary/%POS%',
    apiFuelCard         : api + '/doc-parser/fuel-card/'
  },
  marketing: {
    apiCampaign         : api + '/marketing/campaign/',
    apiSubscriber       : api + '/marketing/subscriber/',
    apiSent             : api + '/marketing/sent/'
  },
  user_accounts : {
    api                 : api + '/company/account/',
    api_username        : api + '/company/account/<USERNAME>',
    api_password        : api + '/company/account/<USERNAME>/password'
  },
  manualTrackingMessagePointFormat: "*%INDEX%* %TYPE% %ADDRESS%",
  manualTrackingMessagePointsGlue: ",",
  manualTrackingMessageGMapsLinkFormat: "https://www.google.com/maps/dir/%WAYPOINTS%/?sensor=true",
  manualTrackingMessageGMapsLinkWaypointGlue: "/",
  manualTrackingMessageRouteDistanceFormat: "Trasa:%DISTANCE%km"
};

export const StorageKeys = {
  dashboard : {
    visible             : 'dashboard_visible'
  },
  map : {
    traffic_layer       : 'map_traffic_layer',
    // vehicle_keys        : 'map_marked_vehicles', // '_vehicleListMarkedVehiclesFilter'
  },
  obligation : {
    time_estimation     : 'obligation_time_estimation',
    addr_confirmation   : 'obligation_addr_confirmation',
    message_number      : 'obligation_message_number',
    message_gps         : 'obligation_message_gps',
    message_date        : 'obligation_message_date',
    message_work_day    : 'obligation_message_work_day',
    message_google_maps : 'obligation_message_google_maps',
    message_it_complete : 'obligation_message_it_complete_link',
    series              : 'obligation_series',
    loading_limit       : 'obligation_loading_limit',
    unloading_limit     : 'obligation_unloading_limit',
    loading_day_begin   : 'obligation_loading_day_begin',
    loading_day_end     : 'obligation_loading_day_end',
    unloading_day_begin : 'obligation_unloading_day_begin',
    unloading_day_end   : 'obligation_unloading_day_end',
    tariff              : 'obligation_tariff',
    obligations_pattern_show      : 'obligations_pattern_show',
    obligations_favourite_show    : 'obligations_favourite_show',
    obligations_modes_show        : 'obligations_modes_show',
    comp_contact_show             : 'comp_contact_show',
    comp_phone_show               : 'comp_phone_show',
    comp_email_show               : 'comp_email_show',
    order_number_show             : 'order_number_show',
    code_show                     : 'code_show',
    custom_order_number_show      : 'custom_order_number_show',
    delivery_note_show            : 'delivery_note_show',
    edoc_show                     : 'edoc_show',
    car_autocomplete_show         : 'car_autocomplete_show',
    it_loading_time_limit_show    : 'it_loading_time_limit_show',
    it_loading_time_real_show     : 'it_loading_time_real_show',
    it_work_day_show              : 'it_work_day_show',
    it_work_day_basic_show        : 'it_work_day_basic_show',
    it_weight_show                : 'it_weight_show',
    it_length_show                : 'it_length_show',
    it_ware_pcs_show              : 'it_ware_pcs_show',
    it_ware_type_show             : 'it_ware_type_show',
    it_additional_activities_show : 'it_additional_activities_show',
    it_code_show                  : 'it_code_show',
    it_destination_show           : 'it_destination_show',
    it_note_show                  : 'it_note_show',
    it_notify_completed_show      : 'it_notify_completed_show',
    it_notify_arriving_show       : 'it_notify_arriving_show',
    obligation_costs_show         : 'obligation_costs_show',
    obligation_costs_toggle       : 'obligation_costs_toggle',
    subnumber_show                : 'subnumber_show',
    calculated_price_show         : 'calculated_price_show',
    note_price_show               : 'note_price_show',
    weight_show                   : 'weight_show',
    length_show                   : 'length_show',
    note_show                     : 'note_show',
    route_length_show             : 'route_length_show',
    route_length_real_show        : 'route_length_real_show',
    transit_length_real_show      : 'transit_length_real_show',
    warehouse_setup               : 'warehouse_setup',
    warehouse_delivery_setup      : 'warehouse_delivery_setup',
    transshipment_setup           : 'transhipment_setup',
    express_setup                 : 'express_setup',
  },
  obligation_list : {
    cookies             : 'obligation_list_cookies',
    filter              : 'obligation_list_filter',
    sort                : 'obligation_list_sort',
    page                : 'obligation_list_page',
    page_size           : 'obligation_list_page_list',
    txt_button          : 'txt_button',
    export_button       : 'export_button',
    real_data_button    : 'real_data_button',
    ltl_button          : 'ltl_button',
    order_button        : 'order_button',
    invoice_button      : 'invoice_button',
    overview_button     : 'overview_button',
    col1                : 'order_number_standard_col',
    col2                : 'order_number_col',
    col2b               : 'custom_order_number_col',
    col2c               : 'delivery_note_col',
    col3mutlirow        : 'destination_multirow_col',
    col3truncate        : 'destination_truncate',
    col3                : 'destination_col',
    col3b               : 'destination_mpz_city_col',
    col3c               : 'destination_mpz_zip_city_col',
    col3d               : 'destination_city_col',
    col3e               : 'destination_company_col',
    col_mode            : 'col_mode',
    col_route           : 'route_length_col',
    col_weight          : 'weight_col',
    col_length          : 'length_col',
    col4                : 'status_col',
    col5                : 'created_col',
    col6                : 'updated_col',
    col7                : 'comb_book_col',
    col8                : 'invoice_col',
    col9                : 'invoice_price_col',
    col_cost            : 'obligation_cost_col',
    col_cost_total      : 'obligation_cost_total_col',
    col10               : 'order_company_col',
    col11               : 'order_col',
    col12               : 'order_price_col',
    col13               : 'invoice_received_col',
    col14               : 'invoice_received_price_col',
    col15               : 'number_plate_col',
    col16               : 'files_col',
    col16b              : 'attachments_verification_col',
    off_col0            : 'additional_activities_col',
    off_col1            : 'loading_date_col',
    off_col2            : 'unloading_date_col',
    off_col3            : 'period_days_col',
    off_col4            : 'tariff_col',
    off_col5            : 'obligation_note_col',
    vehicle_selects     : 'all_vehicle_selects',
    vehicle_autocomplete: 'vehicle_autocomplete',
  },
  order : {
    person_key          : 'order_person_key'
  },
  order_list : {
    cookies             : 'order_list_cookies',
    filter              : 'order_list_filter',
    sort                : 'order_list_sort',
    page                : 'order_list_page',
    page_size           : 'order_list_page_list',
    col1                : 'info_col_ord',
    col2                : 'series_col_ord',
    col3                : 'order_number_col_ord',
    col4                : 'obligation_number_col_ord',
    col5                : 'status_col_ord',
    col6                : 'created_col_ord',
    col7                : 'sent_col_ord',
    col8                : 'updated_col_ord',
    col9                : 'company_col_ord',
    col10               : 'spz_col_ord',
    col11               : 'order_price_col',
    col12               : 'note_price_col_ord',
    col12b              : 'note_col_ord',
    col13               : 'obligation_price_ord',
    col14               : 'invoice_col_ord',
    col15               : 'invoice_price_col_ord'
  },
  invoice : {
    item_order_number         : 'invoice_item_order_number',
    item_code                 : 'invoice_item_code',
    item_delivery_note        : 'invoice_item_delivery_note',
    item_obligation_number    : 'invoice_item_obligation_number',
    item_number_plate         : 'invoice_item_number_plate',
    item_info                 : 'invoice_item_info',
    item_route_plan_length    : 'invoice_item_route_plan_length',
    item_route_real_length    : 'invoice_item_route_real_length',
    item_route_total_length   : 'invoice_item_route_total_length',
    item_route_airline_length : 'invoice_item_route_airline_length',
    item_it_address           : 'invoice_item_it_address', 
    item_it_date              : 'invoice_item_it_date',
    item_it_time              : 'invoice_item_it_time',
    item_it_times             : 'invoice_item_it_times',
    item_it_note              : 'invoice_item_it_note',
    item_intro                : 'invoice_item_intro',
    item_it_mark              : 'invoice_item_it_mark'
  },
  invoice_list : {
    cookies                 : 'invoice_list_cookies',
    filter                  : 'invoice_list_filter',
    sort                    : 'invoice_list_sort',
    page                    : 'invoice_list_page',
    page_size               : 'invoice_list_page_list'
  },
  express_list : {
    cookies                 : 'express_list_cookies',
    filter                  : 'express_list_filter',
    sort                    : 'express_list_sort',
    page                    : 'express_list_page',
    page_size               : 'express_list_page_size',
    col1                    : 'route_col_express',
    col2                    : 'date_col_express',
    col3                    : 'status_col_express',
    col4                    : 'updated_col_express',
    col5                    : 'obligation_col_express',
    col6                    : 'destination_col_express',
    col7                    : 'comp_book_col_express',
    col8                    : 'cargo_col_express',
    col9                    : 'price_col_express',
    col9b                   : 'price_cost_total_col_express',
    col10                   : 'sum_col_express',
    col10b                  : 'sum_col_total_express',
    col11                   : 'lenght_plan_col_express',
    col12                   : 'lenght_real_col_express',
    col13                   : 'car_col_express'
  },
  express: {
    time_estimation         : 'express_time_estimation',
    home_moving             : 'express_home_moving'
  },
  services : {
    cookies                 : 'services_cookies',
    col_year                : 'services_col_year',
    col_age                 : 'services_col_age',
    col_nakup               : 'services_col_nakup',
    col_tacho               : 'services_col_tacho',
    col_najeto              : 'services_col_najeto',
    col_zaruka              : 'services_col_zaruka',
    col_garancka            : 'services_col_garancka',
    col_terminovana         : 'services_col_terminovana',
    col_technicka           : 'services_col_technicka',
    col_oil                 : 'services_col_oil',
    col_zaloha              : 'services_col_zaloha',
    col_kalibrace           : 'services_col_kalibrace',
    col_certifikat          : 'services_col_certifikat',
    col_pneu1               : 'services_col_pneu1',
    col_pneu2               : 'services_col_pneu2',
    col_pneu3               : 'services_col_pneu3',
    col_battery             : 'services_col_battery',
    col_parking             : 'services_col_parking',
    col_docs                : 'services_col_docs'
  },
  warehouse : {
    selected_warehouse_key  : 'selected_warehouse_key',
    switch_collection       : 'switch_collection',
    switch_warehouse        : 'switch_warehouse',
    switch_delivery         : 'switch_delivery',
    number_plate            : 'warehouse_number_plate',
    driver                  : 'warehouse_driver',
    driver_phone            : 'warehouse_driver_phone',
    current_pos             : 'warehouse_current_pos',
    order_number            : 'warehouse_order_number',
    ware_info               : 'warehouse_ware_info',
    comp_book               : 'warehouse_comp_book',
    note                    : 'warehouse_note',
    arrival_time            : 'warehouse_arrival_time',
    work_day                : 'warehouse_work_day',
    eta                     : 'warehouse_eta',
    aetr                    : 'warehouse_aetr',
    line                    : 'warehouse_line'
  },
  email : {
    copy                    : 'email_copy',         // invoice
    copy_address            : 'email_copy_address', // invoice
    copy_order              : 'email_copy_order',
    copy_order_address      : 'email_copy_order_address'
  },
  dispatcher_board : {
    finalToHomeStart        : 'finalToHomeStart',
    finalToHomeEnd          : 'finalToHomeEnd',
    startToFinalStart       : 'startToFinalStart',
    startToFinalEnd         : 'startToFinalEnd'
  }
};


export const TRANS_CLIENT_ID: string = 'd0fcae74-dabb-4d4a-937e-11e18807608e';
export const TRANS_CLIENT_SECRET: string = '3YHwzwkruej2W2Ew8pTT';
export const TRANS_API_KEY: string = '5d7a5d98b726a400012bbb8a8b31e01758404b299c3bd8d8edef5c40';
export const TRANS_AUTH_URL: string = 'https://auth.platform.trans.eu';
export const TRANS_API_URL: string = 'https://api.platform.trans.eu';
export const TIMOCOM_API_URL:string = 'https://api.timocom.com/freight-exchange/3'

export const TransApi = {
  auth:               TRANS_AUTH_URL + '/oauth2/auth',
  auth_token:         TRANS_API_URL + '/ext/auth-api/accounts/token',
  vehicle:            TRANS_API_URL + '/ext/vehicles-api/v1/vehicles',
  freight:            TRANS_API_URL + '/ext/freights-api/v1/freight-exchange'    
}
export const TimocomApi = {
  freight_offer :     TIMOCOM_API_URL + '/freight-offers/search',
  proxy :              api + '/proxy/post'
}


export const defaultLengthUnit: string = ServiceConfiguration.global.lengthUnitKm;

export const lengthUnits: Array<string> = [
  ServiceConfiguration.global.lengthUnitKm,
  ServiceConfiguration.global.lengthUnitMile
];