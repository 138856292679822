import {Injectable} from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {secured} from "../connect";

declare var Notification: any;
Injectable({
  providedIn: 'root',
});

@Injectable()
export class NotificationService {

  private _registration;

  constructor() {
    if (secured) {
      try {
        if (navigator.serviceWorker && navigator.serviceWorker.register instanceof Function) {
          navigator.serviceWorker.register('sw.js');
          Notification.requestPermission((result) => {
            if (result === 'granted') {
              navigator.serviceWorker.ready.then((registration) => {
                this._registration = registration;
              });
            }
          });
        }

      } catch (error) {
        console.warn(error);
      }
    } else {
      console.warn('Cannot use notifications on unsecured connection');
    }
  }

  notify(title, options?: any) {
    if (this._registration) {
      this._registration.showNotification(title, options)
    }
  }


  /* alerts solved as observables array */
  private _alerts = [];

  private _alertsSubject: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  get getAlerts(): Observable<Array<any>> {
    return this._alertsSubject.asObservable();
  }

  alert(message: string, type: string, duration?: number) {
    this._alerts.push({
      message: message,
      type: type === 'error' ? 'danger' : type,
    });
    this._alerts = this._alerts.slice();
    this._alertsSubject.next(this._alerts);

    window.setTimeout(
      () => {
        this._alerts.splice(0, 1);
        this._alerts = this._alerts.slice(0, this._alerts.length);
        this._alertsSubject.next(this._alerts);
      },
      duration || 3000
    );
  }

  // OBSOLETE version - just sharing array..
  // get alerts(): Array<any> {
  //   return this._alerts;
  // }

  // alert(message: string, type: string, duration?: number) {
  //   this._alerts.push({
  //     message: message,
  //     type: type === 'error' ? 'danger' : type,
  //   });
  //   this._alerts = this._alerts.slice();
  //   console.log(this._alerts.length);

  //   window.setTimeout(
  //     () => {
  //       console.log(this._alerts.length);
  //       this._alerts.splice(0, 1);
  //       console.log(this._alerts.length);
  //       this._alerts = this._alerts.slice(0, this._alerts.length);
  //       console.log(this._alerts.length);
  //     },
  //     duration || 1000
  //   );
  // }
}
