import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MAP_ALL_AGENDA, ObligationStatus, StorageKeys } from "../../config";
import { StorageService } from 'src/app/service/storage.service';
// import { VehicleService } from 'src/app/service/vehicle.service';
import { VehicleNewService } from 'src/app/service/vehicle-new.service';
import { UserConfigurationService } from 'src/app/service/user-configuration.service';
import { ExpressRouteService } from 'src/app/service/express-route.service';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { Vehicle } from "../../model/vehicle.object";
import { Agenda } from 'src/app/model/agenda.object';
import { ExpressRoute } from 'src/app/model/express-route.object';
import { Colors } from 'src/app/tools/Colors';
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';
import { TruckManagerLayoutService } from 'src/app/service/truck-manager-layout.service';
import { TrackingEventService } from 'src/app/service/tracking-event.service';
import { DateTools } from 'src/app/tools/DateTools';

declare var google: any;

@Component({
  selector: 'div.googleMapCarsAll',
  templateUrl: './google-map-cars-all.component.html',
  styleUrls: ['./google-map-cars-all.component.css']
})
export class GoogleMapCarsAllComponent implements OnInit, OnDestroy {

  // usage in components - r-vehicle-all-in-one-map
  
  private _subscriptions: Array<Subscription> = [];
  
  private _map: any;
  private _markers: Array<GoogleMapMarker> = [];
  private _currentPositionMarkers: any = {};
  private _initialized: boolean = false;
  private _bounds: any;
  private _directionsService = new google.maps.DirectionsService();
  private _infoWindows: Array<any> = [];

  private _key: number;
  get key(): number {
    return this._key;
  }
  
  private _trafficLayerShow: boolean = true;
  private _trafficLayer: any = null;
  get trafficLayer(): any {
    return this._trafficLayer;
  }

  private _DEFAULT_ZOOM: number = 7;
  
  // inputs
  private _properties = {};
  @Input()
  set properties(properties: any) {
    this._properties = properties;
    if (this._properties && this._properties['zoom']) {
      this._DEFAULT_ZOOM = this._properties['zoom'];
    }
  }

  private _vehicles: Array<Vehicle> = [];
  @Input()
  set vehicles(vehicles: Array<Vehicle>) {
    this._vehicles = vehicles;
    if (this._vehicles && this._vehicles.length) {
      this._vehicles.forEach(
        v => {
          this.loadExpressRoute(v);
        }
      );
    }
  }
  get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }

  @Input() rebuildMap: Observable<boolean> = new Observable();


  constructor(
    private _elRef: ElementRef,
    private _vehicleNewService: VehicleNewService,
    private _storageService: StorageService,
    private _expressRouteServ: ExpressRouteService,
    private _userConfig: UserConfigurationService,
    private _externalApiRequestServ: ExternalApiRequestService,
    private _trackingEventServ: TrackingEventService,
    private _layout: TruckManagerLayoutService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
    // get traffic layer cookie
    let traffic: string = this._storageService.getItem(StorageKeys.map.traffic_layer, true);
    this._trafficLayerShow = (traffic == 'false') ? false : true;
  }

  ngOnInit() {
    // added event listener - parent could sent order to rebuild map
    this._subscriptions.push(
      this.rebuildMap.subscribe(
        (rebuild: boolean) => {
          if (rebuild) {
            this.rebuildData(false);
          }
        }
      ),
      this._userConfig.configChanged.subscribe(
        (changeSet: any) => {
          if (changeSet && changeSet.vehicle_map_agenda) {
            this.rebuildData(false);
          }
        }
      )
    );

    // set vehicle tracking websocket listeners
    if (this._vehicles.length) {
      this._vehicles.forEach(
        (vehicle: Vehicle) => {
          this._subscriptions.push(
            vehicle.trackingDataChange.subscribe(
              () => {
                // update current position marker + red tracking path 
                this.buildDataVehicle(vehicle);
              }
            ),
            vehicle.destRouteChange.subscribe(
              () => {
                // dest route could be finished / removed / added -> rebuild
                this.rebuildData(false);
                // this.buildDataVehicle(vehicle);
              }
            )
          );
        }
      )
    }
    this.buildData();
    this._initialized = true;

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-cars-all';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }

  ngOnDestroy() {
    this.clearData(true);
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._subscriptions = [];
  }

  private clearData(dropMap: boolean) {
    // directions
    this._vehicles.forEach(
      v => {
        // agenda direction renderers
        this.clearDirectionsRenderers(v);
        // manual tracking renderers
        if (v.manualTrackingDirectionRender) {
          v.manualTrackingDirectionRender.setMap(null);
          v.manualTrackingDirectionRendered = false;
        }
        // agenda markers
        v.allMapAgendaMarkers.forEach(
          marker => {
            marker.map = null;
          }
        );
        v.allMapAgendaMarkers = [];
      }
    );
    // current position markers
    for (let car_key in this._currentPositionMarkers) {
      if (this._currentPositionMarkers[car_key]) {
        if (this._currentPositionMarkers[car_key].infoWindow) {
          this._currentPositionMarkers[car_key].infoWindow.close();
          this._currentPositionMarkers[car_key].infoWindow.setMap(null);
          this._currentPositionMarkers[car_key].infoWindow = null;
        }
        if (this._currentPositionMarkers[car_key].getData('path')) {
          this._currentPositionMarkers[car_key].getData('path').setMap(null);
        }
        this._currentPositionMarkers[car_key].setData('path', null);
        this._currentPositionMarkers[car_key].setData('display', 'no');
        this._currentPositionMarkers[car_key].clearEvents();
        this._currentPositionMarkers[car_key].map = null;
      }
    }
    this._currentPositionMarkers = {};
    // temporary markers
    this._markers.forEach(
      marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
          marker.infoWindow = null;
        }
        if (marker.getData('path')) {
          marker.getData('path').setMap(null);
        }
        marker.setData('path', null);
        marker.setData('display', 'no');
        marker.clearEvents();
        marker.map = null;
      }
    );
    // info windows
    this._infoWindows.forEach(
      infoWindow => {
        infoWindow.close();
        infoWindow.setMap(null);
      }
    );
    this._infoWindows = [];

    this._markers = [];
    this._bounds = null;
    // this.clearHeatMap();
    if (dropMap) {
      this._map = null;
    }
  }


  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private _rebuildTimeout: number = null;
  private _building: boolean = false;
  
  private rebuildData(dropMap: boolean) {
    if (!this._initialized) {
      return;
    }
    if (!this._building && this._rebuildTimeout === null) {
      this.clearData(dropMap);

      this._rebuildTimeout = window.setTimeout(
        () => {
          this.buildData();
          this._rebuildTimeout = null;
        },
        1000
      )
    }
  }

  private buildData() {
    this._building = true;

    // possibly create new map
    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));
    }

    // init info windows array
    this._infoWindows = [];
    // this._infoWindows = [
    //   new google.maps.InfoWindow({})
    // ];

    // init markers
    this._markers = [];
    this._vehicles.forEach(
      vehicle => {
        // init markers + agenda directions
        this.buildDataVehicle(vehicle);

        // in rebuilding data (data has been cleared) 
        // -> simulate click on marker (that has been previously clicked)
        if (vehicle.showPathsAndAgenda && this._currentPositionMarkers[vehicle.car_key]) {
          this._currentPositionMarkers[vehicle.car_key].setData('display', 'no');
          this.doClickOnMarker(this._currentPositionMarkers[vehicle.car_key], vehicle);
        }
      }
    );
    // init bounds
    if (this._markers.length) {
      this._bounds = new google.maps.LatLngBounds();
      this._markers.forEach(
        marker => {
          this._bounds.extend(marker.position);
        }
      );
    }

    if (this._map) {
      if (this._bounds && this._markers.length > 1) {
        this._map.fitBounds(this._bounds);
      } 
      else {
        if (this._markers.length) {
          this._map.setCenter(this._markers[0].position);
        }
        this._map.setZoom(this._DEFAULT_ZOOM);
      }

      // possibly draw traffic layer
      if (this._trafficLayerShow && !this._trafficLayer) {
        this.showTrafficLayer();
      }
    }

    this._building = false;
  }

  private buildDataVehicle(vehicle: Vehicle) {
    // invalid last_position!
    if (!vehicle.getCurrentPositionMarker()) return;

    console.log('buildDataVehicle');
    console.log(vehicle.number_plate);

    // handle marker for current position of vehicle
    if (this._currentPositionMarkers[vehicle.car_key]) {
      // if polylines showed - hide and reload
      if (vehicle.showPathsAndAgenda) {
        // hide polylines
        if (this._currentPositionMarkers[vehicle.car_key].getData('path')) {
          let polylines = this._currentPositionMarkers[vehicle.car_key].getData('path');
          if (polylines.length) {
            polylines.forEach(
              polyline => {
                polyline.setMap(null);
              }
            );
            polylines = null;
          }
          this._currentPositionMarkers[vehicle.car_key].setData('path', null);
        }
        this._currentPositionMarkers[vehicle.car_key].setData('display', 'no');
      }

      // remove previous position marker
      this._currentPositionMarkers[vehicle.car_key].map = null;
    }
    this._currentPositionMarkers[vehicle.car_key] = vehicle.getCurrentPositionMarker();

    this.setVehicleMarker(this._currentPositionMarkers[vehicle.car_key], vehicle);
    this._markers.push(this._currentPositionMarkers[vehicle.car_key]);
    
    // always render dest routes directions
    if (!vehicle.manualTrackingDirectionRendered) {
      this.buildManualTracking(vehicle);
    }

    // there could be previous agenda markers
    if (vehicle.allMapAgendaMarkers.length) {
      vehicle.allMapAgendaMarkers.forEach(
        m => {
          m.map = this._map;
        }
      );
      this._markers = this._markers.concat(vehicle.allMapAgendaMarkers);
    }
    // there could be previously clicked on this marker
    if (vehicle.showPathsAndAgenda) {
      // this.displayMarkerPaths(vehicle, this._currentPositionMarkers[vehicle.car_key]);
      this.loadVehicleAgendaTrackingEvents(vehicle, this._currentPositionMarkers[vehicle.car_key]);
    }

    // reinit bounds
    if (this._markers.length) {
      this._bounds = new google.maps.LatLngBounds();
      this._markers.forEach(
        marker => {
          this._bounds.extend(marker.position);
        }
      );
      
      if (this._map) {
        if (this._bounds && this._markers.length > 1) {
          this._map.fitBounds(this._bounds);
        } 
      }
    }
  }


  /*************************************************************/
  // Markers methods
  /*************************************************************/
  private setVehicleMarker(marker: GoogleMapMarker, vehicle: Vehicle): GoogleMapMarker {
    marker.map = this._map;

    // color and background part is used in r-vehicle-all-in-one-map
    if (vehicle) {
      // init color content
      let background: string = Colors.getColor(vehicle.index);
      let color: string = Colors.computeColorForBackgroundColor(background);
      // margin-left:10px;
      let htmlContent: string = '<div style="font-weight:bold; padding:2px; border-radius:7px; background:' + background + '; color:' + color + '">';
      htmlContent += vehicle.number_plate + '</div>';

      marker.infoWindowContent = htmlContent;
      marker.autoInfoWindowOpen = true;
    }
    if (marker.infoWindowContent && !marker.infoWindow) {
      let infowindow: any;
      infowindow = new google.maps.InfoWindow({});
      this._infoWindows.push(infowindow);
      // behavior on marker clicked
      marker.addListener('click', () => {
        infowindow.setContent(marker.infoWindowContent);
        infowindow.open(this._map, marker.getGoogleMarker());
        this.doClickOnMarker(marker, vehicle);
      });
      // save info window to marker and open
      marker.infoWindow = infowindow;
      if (marker.autoInfoWindowOpen) {
        infowindow.setContent(marker.infoWindowContent);
        infowindow.open(this._map, marker.getGoogleMarker());
      }
    } 
    return marker;
  }

  private doClickOnMarker(marker: GoogleMapMarker, vehicle: Vehicle) {
    if (vehicle) {
      if (vehicle.showPathsAndAgenda) {
        // hide polylines and directions
        if (marker.getData('path')) {
          // hide polylines
          if (marker.getData('path')) {
            let polylines = marker.getData('path');
            if (polylines.length) {
              polylines.forEach(
                polyline => {
                  polyline.setMap(null);
                }
              );
            }
            marker.setData('path', null);
          }
        }
        marker.setData('display', 'no');

        // hide agenda markers
        vehicle.allMapAgendaMarkers.forEach(
          m => {
            m.map = null;
          }
        );
        vehicle.allMapAgendaMarkers = [];
        // hide agenda directions
        this.clearDirectionsRenderers(vehicle);

        // disable flag for displaying paths and agenda
        vehicle.showPathsAndAgenda = false;
      } 
      else {
        // show polylines ~ marker paths
        
        /* // AETR today path
        vehicle.trackingDataLazyload = null;
        vehicle.trackingDataLazyload = this._vehicleNewService.createVehicleTrackingEventsLazyload(vehicle);
        // invoke lazy loading
        let tmp = vehicle.trackingData;
        this.displayMarkerPaths(vehicle, marker);
        */

        // all loaded obligations tracking events
        this.loadVehicleAgendaTrackingEvents(vehicle, marker);

        // user config for display agenda in maps
        let config = this._userConfig.configuration;
        let vehicle_map_agenda = config.defaultVehicleListConfiguration.vehicle_map_agenda;

        // standard agenda handling
        // init directions and itinerary markers from vehicle agenda
        vehicle.agenda.forEach(
          agenda => {
            if (!agenda.express_delivery) {
              if (vehicle.last_position && vehicle.last_position.order_number) {
                if (vehicle.last_position.order_number.includes(agenda.order_number)) {
                  // current
                  this.addMarkersAndDirections(vehicle, agenda, true);
                }
                else if (vehicle_map_agenda === MAP_ALL_AGENDA) {
                  // past or future
                  this.addMarkersAndDirections(vehicle, agenda, false);
                }
              }
            }
          }
        );

        // express routes agenda handling
        vehicle.expressRoutes.forEach(
          e => {
            let dir: CustomDirection = new CustomDirection();
            // dir.agenda = agenda;
            let dirError: boolean = false;
            dir.directionsColor = '#0075f2'; // default blue
            dir.directionsOpacity = 0.4;
            dir.directionsWeight = 10;

            e.itineraryOrdered.forEach(
              (itinerary, idx) => {
                // ignorujeme prejezd
                // if (itinerary.type !== 'M') {
                let marker = new GoogleMapMarker();
                marker.position = itinerary.gps_coord.googleLatLang;
                // smaller 24px itinerary icons
                marker.icon = itinerary.typeIconSm();
                marker.zIndex = 200;
                marker.title = itinerary.address;
                marker.itinerary_key = itinerary.itinerary_key;
                marker.number = idx + 1;
                if (itinerary.completed) {
                  marker.icon = itinerary.typeIconGrayscaleSm();
                }
                else {
                  marker.icon = itinerary.typeIconSm();
                }
                marker.map = this._map;
                
                vehicle.allMapAgendaMarkers.push(marker);

                if (itinerary.gps_coord && itinerary.gps_coord.googleLatLang) {
                  dir.directionsArray.push(
                    { location: itinerary.gps_coord.googleLatLang.googleLtLn }
                  );
                }
                else {
                  // at least 1 invalid/null gps coord -> no direction rendered
                  dirError = true;
                }
              }
            );

            // save direction
            if (dirError) dir.directionsArray = [];
            vehicle.allMapAgendaDirections.push(dir);
          }
        );


        // redraw agenda directions
        this.renderDirections(vehicle);

        // enable flag for displaying paths and agenda
        vehicle.showPathsAndAgenda = true;
      }
    }
  }

  loadVehicleAgendaTrackingEvents(vehicle: Vehicle, marker: GoogleMapMarker): void {
    vehicle.agendaTrackingEvents = [];

    // resize tf interval (without that some events are missing)
    let today: Date = new Date();
    let sevenDaysLess: Date = new Date(today.getTime() - 7*24*60*60*1000);
    let sevenDaysMore: Date = new Date(today.getTime() + 7*24*60*60*1000);
    let filterObj: any = {
      tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
      tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
      // on: this._input_order_number
    };

    let current_response_cnt: number = 0;
    let total_response_cnt: number = 0;

    // tmp array for drawed express routes
    let drawed_express_routes: Array<ExpressRoute> = [];

    vehicle.agenda.forEach(
      agenda => {
        // standard agenda
        if (vehicle.last_position && vehicle.last_position.order_number) {
          if (vehicle.last_position.order_number.includes(agenda.order_number)) {
            if (!agenda.express_delivery) {
              filterObj['on'] = agenda.order_number;

              // inc total needed responses
              total_response_cnt += 1;

              // current
              this._subscriptions.push(
                this._trackingEventServ.getTrackingEventsSeparately(filterObj).subscribe(
                  (response: Array<any>) => {
                    if (response) {
                      let tracking_events = TrackingEventService.buildTrackingEventsFromData(response);
                      vehicle.agendaTrackingEvents = vehicle.agendaTrackingEvents.concat(tracking_events);
                      vehicle.agendaTrackingEvents.sort((a, b) => (a.time > b.time) ? 1 : -1);

                      // inc current coming response
                      current_response_cnt += 1;
                      if (current_response_cnt == total_response_cnt) {
                        this.displayMarkerPaths(vehicle, marker);
                      }
                    }
                  },
                  error => {
                    console.log(error);
                  }
                )
              );
            }
            else {
              // express agenda
              if (vehicle.expressRoutes) {
                let express_route = vehicle.expressRoutes.find(
                  e => e.obligations.find(o => o.obligation_key == agenda.obligation_key)
                );
                if (express_route && !drawed_express_routes.find(e => e.express_key == express_route.express_key)) {
                  drawed_express_routes.push(express_route);
                  if (express_route.obligations) {
                    express_route.obligations.forEach(
                      o => {
                        filterObj['on'] = o.order_number_standard;

                        // inc total needed responses
                        total_response_cnt += 1;

                        // current
                        this._subscriptions.push(
                          this._trackingEventServ.getTrackingEventsSeparately(filterObj).subscribe(
                            (response: Array<any>) => {
                              if (response) {
                                let tracking_events = TrackingEventService.buildTrackingEventsFromData(response);
                                console.log(tracking_events);
                                vehicle.agendaTrackingEvents = vehicle.agendaTrackingEvents.concat(tracking_events);
                                vehicle.agendaTrackingEvents.sort((a, b) => (a.time > b.time) ? 1 : -1);

                                // inc current coming response
                                current_response_cnt += 1;
                                if (current_response_cnt == total_response_cnt) {
                                  this.displayMarkerPaths(vehicle, marker);
                                }
                              }
                            },
                            error => {
                              console.log(error);
                            }
                          )
                        );
                      }
                    )
                  }
                }
              }
            }
          }
        }
      }
    );
  }

  // creating red polylines from current tracking data
  private displayMarkerPaths(vehicle: Vehicle, marker: GoogleMapMarker) {
    marker.setData('display', 'yes');
    if (vehicle.agendaTrackingEvents) {
      if (marker.getData('path')) {
        marker.getData('path').setMap(null);
      }
      // show polylines - black - empty / red - loaded
      let polylines: Array<any> = [];
      vehicle.agendaTrackingEvents.forEach(
        (e, idx) => {
          if (vehicle.agendaTrackingEvents[idx - 1]) {
            polylines.push(
              new google.maps.Polyline({
                path: [
                  vehicle.agendaTrackingEvents[idx - 1].geoPos.googleLatLang.googleLtLn,
                  e.geoPos.googleLatLang.googleLtLn
                ],
                geodesic: true,
                strokeColor: e.cargo_status == 'E' ? 'black' : 'red', 
                strokeOpacity: 1.0,
                strokeWeight: 2,
                zIndex: 1000,
                map: this._map
              })
            );
          }
        }
      );
      marker.setData('path', polylines);
    }
  }
  
  private addMarkersAndDirections(vehicle: Vehicle, agenda: Agenda, currentAgenda: boolean): void {
    // add direction for agenda
    let dir: CustomDirection = new CustomDirection();
    dir.vehicle = vehicle;
    dir.agenda = agenda;
    let dirError: boolean = false;
    // dir.directionsColor = '#0000ff'; // default blue
    dir.directionsColor = '#0075f2'; // default blue
    dir.directionsOpacity = 0.4;
    dir.directionsWeight = 10;

    // specification of not current agendas
    if (!currentAgenda) {
      // smaller/lighter weight
      dir.directionsWeight = 6;
      switch (agenda.status) {
        case ObligationStatus.ZAHAJENA:
          // zahajena, kterou nejede (zrejme budouci planovana)
          dir.directionsColor = '#4f4f4f'; // gray
          dir.directionsOpacity = 0.3;
          break;
        case ObligationStatus.SKLAD_SVOZ:
          // svoz
          dir.directionsColor = '#91d8ce'; // cyan1
          dir.directionsOpacity = 0.7;
          break;
        case ObligationStatus.SKLAD:
          // sklad
          dir.directionsColor = '#48bfae'; // cyan2
          dir.directionsOpacity = 0.7;
          break;
        case ObligationStatus.SKLAD_K_ROZVOZU:
          // sklad - k rozvozu
          dir.directionsColor = '#48bfae'; // cyan2
          dir.directionsOpacity = 0.7;
          break;
        case ObligationStatus.SKLAD_ROZVOZ:
          // rozvoz
          dir.directionsColor = '#1ab19a'; // cyan3
          dir.directionsOpacity = 0.7;
          break;
        case ObligationStatus.K_FAKTURACI:
          // k fakturaci
          dir.directionsColor = '#ff626f'; // light red
          dir.directionsOpacity = 0.4;
          break;
        case ObligationStatus.FAKTUROVANA:
          // fakturovana
          dir.directionsColor = '#b04bb3'; // purple
          dir.directionsOpacity = 0.4;
          break;
        case ObligationStatus.DOKONCENA:
          // dokoncena
          dir.directionsColor = '#00ff00'; // green
          dir.directionsOpacity = 0.4;
          break;
        default:
          break;
      }
    }

    // add markers of agenda itinerary
    agenda.itinerary.forEach(
      itinerary => {
        // OBSOLETE? pridavat znovu pouze ty markery, ktere nejsou jeste vykresleny 
        // if (!this._markers.find(m => m.itinerary_key == itinerary.itinerary_key)) {
        // ignorujeme prejezd
        if (itinerary.type !== 'M') {
          let marker = new GoogleMapMarker();
          marker.position = itinerary.gps_coord.googleLatLang;
          // smaller 24px itinerary icons
          // marker.icon = itinerary.typeIconSm();
          marker.icon = itinerary.typeIconGrayscaleSm();
          marker.zIndex = 200;
          marker.title = itinerary.address;
          marker.itinerary_key = itinerary.itinerary_key;
          marker.map = this._map;
          
          vehicle.allMapAgendaMarkers.push(marker);
        }

        if (itinerary.gps_coord && itinerary.gps_coord.googleLatLang) {
          dir.directionsArray.push(
            { location: itinerary.gps_coord.googleLatLang.googleLtLn }
          );
        }
        else {
          // at least 1 invalid/null gps coord -> no direction rendered
          dirError = true;
        }
      }
    );

    // save direction
    if (dirError) dir.directionsArray = [];
    vehicle.allMapAgendaDirections.push(dir);
    this._markers = this._markers.concat(vehicle.allMapAgendaMarkers);
  }


  /*************************************************************/
  // Express routes loading
  /*************************************************************/
  loadExpressRoute(vehicle: Vehicle): void {
    if (vehicle.agenda && vehicle.agenda.length) {
      // all current express routes
      let express_route_keys: Array<number> = [];
      vehicle.agenda.forEach(
        agenda => {
          if (agenda.express_route_key) {
            if (!express_route_keys.includes(agenda.express_route_key)) {
              express_route_keys.push(agenda.express_route_key);
            }
          }
        }
      );
      
      // load express routes and draw directions
      express_route_keys.forEach(
        express_key => {
          this._subscriptions.push(
            this._expressRouteServ.getExpressRoute(express_key).subscribe(
              express_route => {
                if (express_route) {
                  // console.log(express_route);
                  // load obligations of selected express route
                  this._subscriptions.push(
                    this._expressRouteServ.getExpressObligations(express_route).subscribe(
                      obligations => {
                        if (obligations) {
                          // console.log(obligations);
                          express_route.obligations = obligations;
                          // init sorted itinerary array
                          this.initItineraryExpress(express_route);
                          // save express route for directions creating
                          vehicle.expressRoutes.push(express_route);
                        }
                      }
                    )
                  );
                } 
              }
            )
          );
        }
      );
    }
  }

  initItineraryExpress(express_route: ExpressRoute): void {
    // keep only itinerary with some gps coord
    express_route.itineraryOrdered = express_route.itineraryOrdered.filter(i => i.gps_coord);
    // sort according to arrival time
    express_route.itineraryOrdered.sort(
      (a, b) => (a.arrival_time > b.arrival_time) ? 1 : -1
    );
  }


  /*************************************************************/
  // Waypoints/directions methods
  /*************************************************************/
  private _directionsRenderCalls: number = 0;

  private callRequestOnDirectionsRender(directionsRendered, request: any) {
    let isset = false;
    this._directionsRenderCalls++;
    window.setTimeout(
      () => {
        // api request call
        let api_request_log: ExternalApiRequest = new ExternalApiRequest();
        api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
        api_request_log.type = 'directions';
        api_request_log.descr = 'google-map-cars-all';
        api_request_log.price = 0.005;
        this._externalApiRequestServ.createRequestLog(api_request_log);

        // google request
        this._directionsService.route(
          request,
          (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (isset === false) {
                directionsRendered.setDirections(response);
                isset = true;
              }
            }
            this._directionsRenderCalls--;
          });
      },
      500 * this._directionsRenderCalls // 1001 * this._directionsRenderCalls
    );
  }

  private renderDirections(vehicle: Vehicle): void {
    if (vehicle.allMapAgendaDirections.length) {
      vehicle.allMapAgendaDirections.forEach(
        (d: CustomDirection) => {
          if (d.directionsArray.length) {
            // deep copy
            let options: any = JSON.parse(JSON.stringify(GoogleMapTools.RendererOptions));
            if (options.polylineOptions) {
              if (d.directionsColor) {
                options.polylineOptions.strokeColor = d.directionsColor;
              }
              if (d.directionsOpacity) {
                options.polylineOptions.strokeOpacity = d.directionsOpacity;
              }
              if (d.directionsWeight) {
                options.polylineOptions.strokeWeight = d.directionsWeight;
              }
            }

            let directionsRenderer = new google.maps.DirectionsRenderer(options);
            directionsRenderer.setMap(this._map);
            let request = {
              origin: d.directionsArray[0].location,
              destination: d.directionsArray[d.directionsArray.length - 1].location,
              waypoints: d.directionsArray,
              travelMode: google.maps.DirectionsTravelMode.DRIVING
            };
            this.callRequestOnDirectionsRender(directionsRenderer, request);
            // this._directionsRendered.push(directionsRenderer);
            vehicle.allMapAgendaDirectionsRenderers.push(directionsRenderer);
          }
        }
      );
    }
  }

  private clearDirectionsRenderers(vehicle: Vehicle) {
    vehicle.allMapAgendaDirectionsRenderers.forEach(
      renderer => {
        renderer.setMap(null);
      }
    );
    vehicle.allMapAgendaDirectionsRenderers = [];
    vehicle.allMapAgendaDirections = [];
  }

  
  /*************************************************************/
  // Manual tracking methods
  /*************************************************************/
  private buildManualTracking(vehicle: Vehicle) {
    // check if there is valid manual tracking ~ dest route
    if (vehicle && vehicle.temporaryManualTracking) {
      if (vehicle.temporaryManualTracking.length === 2 && vehicle.manualTrackingDirectionRender) {
        // set flag that this vehicle manual tracking has been rendered
        vehicle.manualTrackingDirectionRendered = true;
        vehicle.manualTrackingDirectionRender.setMap(this._map);
        vehicle.temporaryManualTracking[1].map = this._map;
        this._markers.push(vehicle.temporaryManualTracking[1]);
      }
    }
  }


  /*************************************************************/
  // Traffic methods
  /*************************************************************/
  showTrafficLayer() {
    this._trafficLayer = new google.maps.TrafficLayer();
    this._trafficLayer.setMap(this._map);
  }

  toggleTrafficLayer() {
    if (!this._trafficLayer) {
      this._trafficLayer = new google.maps.TrafficLayer();
    }

    if (this._trafficLayer.getMap() == null) {
      // traffic layer is disabled.. enable it
      this._trafficLayer.setMap(this._map);
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'true', true);
    }
    else {
      // traffic layer is enabled.. disable it
      this._trafficLayer.setMap(null);        
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'false', true);     
    }
  }  
}


export class CustomDirection {
  public vehicle: Vehicle = null;
  public agenda: Agenda = null;
  public directionsColor: string = null;
  public directionsOpacity: number = null;
  public directionsWeight: number = null;
  public directionsArray: Array<any> = [];
}
