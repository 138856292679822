<div *ngIf="attachment && obligation" class="row">
  <div class="col-lg-4">
    <div class="form-group w-100">
      <label class="mb-1" i18n>Dodací list</label>
      <div class="input-group">
        <input class="form-control" 
        [(ngModel)]="obligation.delivery_note" (ngModelChange)="deliveryNoteChange()"
        placeholder="Číslo dodacího listu" i18n-placeholder/>
        <div class="input-group-append">
          <button class="btn btn-success" type="button" (click)="updateObligation()">Uložit</button>
        </div>
      </div>
    </div>
    <div class="form-group w-100">
      <label class="mb-1" i18n>Jméno souboru</label>
      <div class="input-group">
        <input class="form-control" [(ngModel)]="newName"
        placeholder="Zadejte jméno souboru" i18n-placeholder/>
        <div class="input-group-append">
          <span class="input-group-text">
            {{extension}}
          </span>
          <button class="btn btn-success" type="button"
          (click)="updateAttachmentName()" i18n>Uložit</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-8 text-center">
    <!-- prostor pro zobrazeni prilohy -->
    <div *ngIf="loadingAttachment" class="spinner-wrapper py-4">
      <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div *ngIf="currentPdf">
      <div *ngIf="!isTypeImage" class="text-center" style="width:100%; height: 60vh;">
        <object class="full fill" [data]="currentPdf.content|safeResource" [type]="currentPdf.type">
        </object>
      </div>
      <div *ngIf="isTypeImage" class="text-center" style="width:100%; height:60vh;">
        <img [src]="currentPdf.content|safeResource" [alt]="currentPdf.name" style="width:100%"/>
      </div>
    </div>
  </div>
</div>
