import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";
import { MessageService } from "../../service/message.service";
import { Message } from "../../model/message.object";
import { DateTools } from "../../tools/DateTools";

@Component({
  selector: 'messages-read',
  templateUrl: './r-messages-read.component.html',
  styleUrls: ['./r-messages-read.component.css']
})
export class RMessagesReadComponent implements OnInit, OnDestroy {

  private _subscribers: Array<Subscription> = [];

  private _messages: Array<Message> = [];
  get messages(): Array<Message> {
    return this._messages;
  }

  private _loading: boolean;
  get loading(): boolean {
    return this._loading;
  }

  private _broadcastVisible: boolean = false;
  get broadcastVisible(): boolean {
    return this._broadcastVisible;
  }

  constructor(
    private _messageService: MessageService, 
    private _layout: TruckManagerLayoutService
  ) {
  }

  ngOnInit() {
    // this._layout.isDashboardFullMode = true;
    this.loadMessages(false);
  }

  ngOnDestroy() {
    // this._layout.isDashboardFullMode = false;
    this._subscribers.forEach(
      subscriber => subscriber.unsubscribe()
    )
  }

  get lastLoadTime(): string {
    if (!this._messageService.lastLoadDate) {
      return '-';
    }
    return DateTools.formatLocaleString(this._messageService.lastLoadDate, '%hours:%minutes');
  }

  reloadMessages() {
    this.loadMessages(true);
  }

  showBroadcast() {
    this._broadcastVisible = true;
  }

  hideBroadcast() {
    this._broadcastVisible = false;
  }

  private loadMessages(reload?: boolean) {
    this._loading = true;
    this._messageService.getMessages(reload).subscribe(
      messages => {
        this._messages = messages;
        console.log(this._messages);
        this._loading = false;
      }
    )
  }
}
