<!-- Persons filtering - lower priority -->
<div class="d-flex justify-content-between bg-secondary text-white rounded py-1 px-1">
  <h6 class="m-0 pl-1"><ng-container i18n>Filtr dle "Pořídil zakázku"</ng-container>&nbsp;
    <a class="badge badge-pill badge-warning" href="javascript:void(0)" tabindex="-1"
    ngbPopover="Zobrazení vozidel podle uživatele, který naplánoval dnešní zakázku" 
    container="body" placement="top auto">?</a>
  </h6>
  <div>
    <div class="mr-1 d-inline-block" i18n>Označit vše</div>
    <div class="custom-control custom-control-inline custom-switch mx-0">
      <input class="custom-control-input" type="checkbox" id="markAllUsers"
      [(ngModel)]="markAllUsers" (ngModelChange)="resetVehicleUsersFilter()"/> 
      <label class="custom-control-label" for="markAllUsers"></label>
    </div>
  </div>
</div> 

<table class="table table-striped table-hover mb-4">
  <tbody>
    <tr *ngFor="let user of userAccounts">
      <td class="p-1">
        <b>{{user.username}}
          <span *ngIf="!getPersonNameByUsername(user.username)">(Global)</span>
          <span *ngIf="getPersonNameByUsername(user.username)">
            ({{getPersonNameByUsername(user.username)}})
          </span>
        </b>
      </td>
      <td class="p-1 text-right">
        <div class="custom-control custom-switch">
          <input class="custom-control-input" type="checkbox" 
          [id]="'markUser-' + user.username"
          [(ngModel)]="user.marked" (ngModelChange)="changeUserFilter()"/> 
          <label class="custom-control-label" [for]="'markUser-' + user.username"></label>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<!-- Persons filtering - lower priority -->
<div class="d-flex justify-content-between bg-secondary text-white rounded py-1 px-1">
  <h6 class="m-0 pl-1" i18n>Filtr dle dispečera</h6>
  <div>
    <div class="mr-1 d-inline-block" i18n>Označit vše</div>
    <div class="custom-control custom-control-inline custom-switch mx-0">
      <input class="custom-control-input" type="checkbox" id="markAllPersons"
      [(ngModel)]="markAllPersons" (ngModelChange)="resetVehiclePersonFilter()"/> 
      <label class="custom-control-label" for="markAllPersons"></label>
    </div>
  </div>
</div>

<table class="table table-striped table-hover mb-4">
  <tbody>
    <tr *ngFor="let person of persons">
      <td class="p-1">
        <b>{{person.name}}</b>
      </td>
      <td class="p-1 text-right">
        <div class="custom-control custom-switch">
          <input class="custom-control-input" type="checkbox" 
          [id]="'markPerson' + person.person_key"
          [(ngModel)]="person.marked" (ngModelChange)="changePersonFilter()"/> 
          <label class="custom-control-label" [for]="'markPerson' + person.person_key"></label>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<!-- Vehicles filtering - particular priority -->
<div class="d-flex justify-content-between bg-secondary text-white rounded py-1 px-1">
  <h6 class="m-0 pl-1" i18n>Filtr dle SPZ/řidiče</h6>
  <div>
    <div class="mr-1 d-inline-block" i18n>Označit vše</div>
    <div class="custom-control custom-control-inline custom-switch mx-0">
      <input class="custom-control-input" type="checkbox" id="markAllVehicles"
      [(ngModel)]="markAllVehicles" (ngModelChange)="resetVehicleFilter()"/> 
      <label class="custom-control-label" for="markAllVehicles"></label>
    </div>
  </div>
</div>

<table class="table table-striped table-hover">
  <tbody>
    <tr *ngFor="let v of vehicles">
      <td class="p-1">
        <b>{{v.number_plate}}</b>, {{v.driver_name}}
      </td>
      <td class="p-1 text-right">
        <div class="custom-control custom-switch">
          <input class="custom-control-input" type="checkbox" [id]="'mark' + v.car_key"
          [(ngModel)]="v.marked" (ngModelChange)="changeVehicleFilter()"/> 
          <label class="custom-control-label" [for]="'mark' + v.car_key"></label>
        </div>
      </td>
    </tr>
  </tbody>
</table>