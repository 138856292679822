import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { AuthenticationService } from "../../service/authentication.service";
import { NotificationService } from 'src/app/service/notification-service';
import { DatabanksService } from 'src/app/service/databanks.service';

@Component({
  selector: '<component-router>',
  template: '<h1>Auto logging</h1>'
})
export class AutoLoginComponent implements OnInit, OnDestroy {

  // property for router reloading subscribtion
  private _navigationSubscription: any;

  constructor(
    private _databanksServ: DatabanksService,
    private _authServ: AuthenticationService,
    private _notifyServ: NotificationService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    // subscribe to the router events. Store the subscription so we can unsubscribe later.
    this._navigationSubscription = this._route.queryParams.subscribe(
      params => {
        // defaults to null if no query param provided.
        let code: string = params['code'] || null;
        let state: string = params['state'] || null;

        // code && state are params for TRANS.eu authentication
        if (code && state) {
          // code and state - handled by constructor in databanksService
        }
        else if (code) {
          this._authServ.authenticateByCode(code);
        }
        else {
          this._notifyServ.alert('Přihlášení nebylo úspěšné - chybí auth_code.', 'error', 3500);
        }
      }
    );
    // console.log(window.location.href);
    // console.log(window.location.href.replace(/^.*\?code=/, ''));
    // let code = decodeURIComponent(window.location.href.replace(/^.*\?code=/, ''));
    // console.log(code);
  }

  ngOnDestroy() {
    if (this._navigationSubscription) {
      this._navigationSubscription.unsubscribe();
    }
  }
  
}
