import { GoogleLatLng } from "./google-lat-lng.object";
import { DateTools } from "../tools/DateTools";
import { Vehicle } from "./vehicle.object";

export class GeoPosition {

  constructor(
    private _curDate?: Date
  ) {
    if (!_curDate) {
      this._curDate = new Date();
    }
  }

  get currentDate(): Date {
    return this._curDate;
  }
  set currentDate(currentDate: Date) {
    this._curDate = currentDate;
  }

  private _cargo_status: string;
  get cargo_status(): string {
    return this._cargo_status;
  }
  set cargo_status(value: string) {
    this._cargo_status = value;
  }

  private _cargo_weight: number = 0;
  get cargo_weight(): number {
    return this._cargo_weight;
  }
  set cargo_weight(value: number) {
    this._cargo_weight = value;
  }

  private _city_country: string;
  get city_country(): string {
    return this._city_country;
  }
  set city_country(value: string) {
    this._city_country = value;
  }

  private _city_name: string;
  get city_name(): string {
    return this._city_name;
  }
  set city_name(value: string) {
    this._city_name = value;
  }

  private _city_size: string;
  get city_size(): string {
    return this._city_size;
  }
  set city_size(value: string) {
    this._city_size = value;
  }

  private _city_zip: string;
  get city_zip(): string {
    return this._city_zip;
  }
  set city_zip(value: string) {
    this._city_zip = value;
  }

  private _distance: number;
  get distance(): number {
    return this._distance;
  }
  set distance(value: number) {
    this._distance = value;
  }

  private _driving_time: string;
  get driving_time(): string {
    return this._driving_time;
  }
  set driving_time(value: string) {
    this._driving_time = value;
  }

  // tachometr, tachograf
  private _odometer: number = null;
  public get odometer(): number {
    return this._odometer;
  }
  public set odometer(value: number) {
    this._odometer = value;
  }

  // order numbers in array order_number in api /cars last_position
  private _order_number: Array<string> = [];
  get order_number(): Array<string> {
    return this._order_number;
  }
  set order_number(value: Array<string>) {
    if (typeof(value) == 'string') {
      // uniform always array of strings
      this._order_number = [value];
    }
    else {
      // array
      this._order_number = value;
    }

    // possibly truncate for displaying less numbers
    if (this._order_number) {
      if (this._order_number.length && this._order_number.length > 10) {
        this._order_number_truncated = this._order_number.slice(0, 9);
      }
      else {
        this._order_number_truncated = this._order_number.slice();
      }
    }
  }

  // custom
  private _order_number_truncated: Array<string> = [];
  public get order_number_truncated(): Array<string> {
    return this._order_number_truncated;
  }
  public set order_number_truncated(value: Array<string>) {
    this._order_number_truncated = value;
  }

  // order numbers in array websocket relation tracking
  public set order_numbers(value: Array<string>) {
    // order_number -> set to order_number array
    // this setter, because in tracking websocket this array has been renamed to order_numbers
    this._order_number = value;
  }

  // private _agendas: Array<Agenda> = [];
  // get agendas(): Array<Agenda> {
  //   return this._agendas;
  // }
  // set agendas(value: Array<Agenda>) {
  //   this._agendas = value;
  // }

  // custom - should be key values - "order_number" : "agenda"
  private _agendas: any = {};
  get agendas(): any {
    return this._agendas;
  }
  set agendas(value: any) {
    this._agendas = value;
  }

  // private _itin: Array<Itinerary>;
  // get itin(): Array<Itinerary> {
  //   return this._itin;
  // }
  // set itin(value: Array<Itinerary>) {
  //   this._itin = value;
  // }

  private _order_customer: string;
  get order_customer(): string {
    return this._order_customer;
  }
  set order_customer(value: string) {
    this._order_customer = value;
  }

  private _pos_key: number;
  get pos_key(): number {
    return this._pos_key;
  }
  set pos_key(value: number) {
    this._pos_key = value;
  }

  private _speed: number;
  get speed(): number {
    return this._speed;
  }
  set speed(value: number) {
    this._speed = value;
  }

  private _time: Date;
  get time(): Date {
    return this._time;
  }
  set time(value) {
    if (value) {
      this._time = DateTools.isoFix(value.toString());
    }
  }
  
  get formatted_time(): string {
    return DateTools.formatLocaleString(this.time);
  }

  private _truck_status: string;
  get truck_status(): string {
    return this._truck_status;
  }
  set truck_status(value: string) {
    this._truck_status = value;
  }

  //gps_properties ... a lot of them unify maybe ?
  private _city_gps: string;
  get city_gps(): string {
    return this._city_gps;
  }
  set city_gps(value: string) {
    this._city_gps = value;
  }

  private _pos_gps: string;
  get pos_gps(): string {
    return this._pos_gps;
  }
  set pos_gps(value: string) {
    this._pos_gps = value;
    this._googleLatLang = null;
  }

  private _gps_coord: string;
  get gps_coord(): string {
    return this._gps_coord;
  }
  set gps_coord(value: string) {
    this._gps_coord = value;
    this._googleLatLang = null;
  }

  private _gps: string;
  get gps(): string {
    return this._gps;
  }
  set gps(value: string) {
    this._gps = value;
    this._googleLatLang = null;
  }

  get city_zip_short(): string {
    if (!this.city_zip) {
      return '';
    }
    return this.city_zip.substring(0, 2);
  }

  private _googleLatLang: GoogleLatLng;
  get googleLatLang(): GoogleLatLng {
    let gps;
    if (!this._googleLatLang) {
      if (this._gps_coord) {
        gps = this._gps_coord;
      } else if (this._gps) {
        gps = this._gps;
      } else if (this._pos_gps) {
        gps = this.pos_gps;
      } else {
        console.error('Missing argument for gps coordinations', this);
        return null;
        // throw new Error('Missing argument for gps coordinations');
      }
      let gps_array: Array<string> = GeoPosition.parseToLocationArray(gps);


      this._googleLatLang = new GoogleLatLng(parseFloat(gps_array[0]), parseFloat(gps_array[1]));
    }
    let returning = this._googleLatLang;
    if (!returning) {
      console.error('NO GOOGLELATLNG object', returning, this);
    }
    return returning;
  }

  get positionString(): string {
    let gps = '';
    if (this._gps_coord) {
      gps = this._gps_coord;
    } else if (this._gps) {
      gps = this._gps;
    } else if (this._pos_gps) {
      gps = this.pos_gps;
    } else {
      throw new Error('Missing argument for gps coordinations');
    }
    if (gps[0] !== '(') {
      gps = '(' + gps;
    }
    if (gps[gps.length - 1] !== ')') {
      gps += ')';
    }
    return gps;
  }

  static parseMilisecondsToHHMMString(milisecond: number): string {
    return DateTools.getHumanReadHoursAndMinutes(milisecond);
  }

  static parseToLocationArray(gps: any | string) {
    if (typeof gps === 'string') {
      return gps.replace(/\(|\)/g, '').split(',');
    } else {
      return [
        gps.x,
        gps.y
      ]
    }
  }

  isInOrders(order_number: string): boolean {
    return this._order_number.indexOf(order_number) > -1;
  }

  get getDateDiff(): number {
    return this._curDate.getTime() - this.time.getTime();
  }

  // fix for expressionChangedAfter.. error
  // private _dateDiffString: string = '';
  // public get dateDiffString(): string {
  //     return this._dateDiffString;
  // }
  
  // setDateDiffString(): void {
  //     this._dateDiffString = GeoPosition.parseMilisecondsToHHMMString(this.getDateDiff());
  // }

  get getDateDiffString(): string {
    return GeoPosition.parseMilisecondsToHHMMString(this.getDateDiff);
  }

  dateDiffColor() {
    switch (this.truck_status) {
      case Vehicle.INTERRUPTION:
        return '#FF0000';
      case Vehicle.MOVING_FULL:
        if (this.getDateDiff <= 900000) {
          return '#000000';
        } else {
          return '#FF0000';
        }
      case Vehicle.SLEEP:
        return '#000000';
      case Vehicle.FERRY:
        return '#000000';
      case Vehicle.STOP:
        if (this.getDateDiff <= 3600000) {
          return '#000000';
        } else {
          return '#FF0000';
        }
      default:
        return '';
    }
  }
}