import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Attachment } from 'src/app/model/attachment.object';
import { Obligation } from 'src/app/model/obligation.object';
import { FilesService } from 'src/app/service/files.service';
import { ObligationService } from 'src/app/service/obligation.service';

@Component({
  selector: 'app-file-renaming',
  templateUrl: './file-renaming.component.html',
  styleUrls: ['./file-renaming.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ObligationService,
    FilesService
  ]
})
export class FileRenamingComponent implements OnInit {

  private _subscribed: Array<Subscription> = [];

  private _attachment: Attachment = new Attachment();
  @Input()
  public set attachment(value: Attachment) {
    this._attachment = value;
    if (this._attachment) {
      // reinit current pdf and download attachment
      this._currentPdf = null;
      this.downloadAttachment();
      // set name of file for editation
      this.newName = this.attachment.name;
      // truncate long file names
      if (this.attachment.name && this.attachment.name.lastIndexOf('.') > 0) {
        this.newName = this.attachment.name.substring(0, this.attachment.name.lastIndexOf('.'));
        this.extension = this.attachment.name.substring(this.attachment.name.lastIndexOf('.'));
      }
    }
    this.detectChanges();
  }
  public get attachment(): Attachment {
    return this._attachment;
  }
  
  private _obligation: Obligation = new Obligation();
  @Input()
  public set obligation(value: Obligation) {
    this._obligation = value;
    this.detectChanges();
  }
  public get obligation(): Obligation {
    return this._obligation;
  }

  constructor(
    private _obligationServ: ObligationService,
    private _filesServ: FilesService,
    private _cdr: ChangeDetectorRef
  ) {
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 5000
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  public detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }

  /******************************************/
  /* NgModel change of delivery_note */
  /******************************************/
  deliveryNoteChange(): void {
    // reactive change
    if (this.obligation && this.obligation.delivery_note) {
      this.newName = this.obligation.delivery_note;
      this.detectChanges();
    }
  }


  /******************************************/
  /* Downloading attachment */
  /******************************************/
  private _currentPdf: any = null;
  public get currentPdf(): any {
    return this._currentPdf;
  }
  
  private _loadingAttachment: boolean = false;
  public get loadingAttachment(): boolean {
    return this._loadingAttachment;
  }

  get isTypeImage(): boolean {
    if (this._currentPdf && this._currentPdf.type && this._currentPdf.type.match(/image/)) {
      return true;
    }
    return false;
  }

  // method for updating attributes of specified obligation
  downloadAttachment(): void {
    if (this._attachment && this._attachment.url) {
      this._loadingAttachment = true;
      this.detectChanges();

      this._obligationServ.getAttachment(this._attachment.url).subscribe(
        response => {
          let newBlob: any = new Blob(
            [response], { type: (response.type ? response.type : 'application/pdf') }
          );
          
          // set flag after successful loading
          this._loadingAttachment = false;
  
          if (response.type !== '' && (response.type.match(/image|pdf|text\//))) {
            // save data to attachment fileContent property
            this._attachment.fileContent = {
              content: URL.createObjectURL(newBlob),
              type: 'application/pdf'
            };
            // set currentPdf property to display attachment
            this._currentPdf = this._attachment.fileContent;
            this.detectChanges();
          } 
          else {
            newBlob.lastModifiedDate = new Date();
            newBlob.name = this._attachment.name;
            this.detectChanges();
  
            this._filesServ.convertToPdfDefault(newBlob).subscribe(
              (pdfResponse: HttpResponse<Blob>) => {
                // save data to attachment fileContent property
                this._attachment.fileContent = {
                  content: URL.createObjectURL(pdfResponse.body),
                  type: 'application/pdf'
                };
                // set currentPdf property to display attachment
                this._currentPdf = this._attachment.fileContent;
                this.detectChanges();
              }
            );
          }
        },
        error => {
          console.log(error);
          // set flag after error loading
          this._loadingAttachment = false;
          this.detectChanges();
        }
      );
    }
  }

  /******************************************/
  /* Updating obligation */
  /******************************************/
  updateObligation(): void {
    this._subscribed.push(
      this._obligationServ.updateObligation(this._obligation).subscribe()
    );
  }

  /******************************************/
  /* Renaming obligation attachment */
  /******************************************/
  public newName: string = null; 
  public extension: string = null; 

  updateAttachmentName(): void {
    if (this.obligation && this.attachment) {
      let current_name: string = this.attachment.name;
      let new_name: string = this.newName + this.extension;

      // api call
      this._obligationServ.renameFile(
        this.obligation.obligation_key, current_name, new_name
      );
      
      // data refresh
      window.setTimeout(
        () => {
          this.attachment.name = new_name;
          this.detectChanges();
        }, 1000
      )
    }
  }
}
