<div style="width:100%; height:100%" id="googleMap{{key}}">
</div>

<div *ngIf="vehicle" class="mapAdditionalFunctions pointer">
  <img src="assets/icons-new/search-location-solid.svg" 
  class="border border-secondary rounded-sm shadow"
  placement="right auto" container="body" ngbTooltip="Zameřit vozidlo" i18n-ngbTooltip
  (click)="centerToCurrentVehiclePosition()"/>

  <ng-container *ngIf="vehicle.eco_speed">
    <img src="assets/icons-new/dashboard.svg" alt="icon" height="18px"
    class="border border-secondary rounded-sm shadow" 
    placement="right auto" container="body" ngbTooltip="Jízda dle DriveManager" i18n-ngbTooltip
    (click)="toggleSpeedView()"/>
  </ng-container>

  <img src="assets/icons-new/traffic-info.svg" alt="icon" height="18px"
  class="border border-secondary rounded-sm shadow"
  ngbTooltip="Dopravní info" placement="right auto" container="body" i18n-ngbTooltip
  (click)="toggleTrafficLayer()"/>
</div>