<div style="width:100%; height:100%" id="googleMap{{key}}">
</div>

<div class="mapAdditionalFunctions pointer">
  <img src="assets/icons-new/traffic-info.svg" alt="icon" height="18px"
  class="border border-secondary rounded-sm shadow" (click)="toggleTrafficLayer()"
  ngbTooltip="Dopravní info" placement="right auto" container="body"/>
  
  <img src="assets/icons-new/question1.svg" alt="icon" height="18px"
  class="border border-secondary rounded-sm shadow"
  [ngbPopover]="helpPopover" placement="right auto" container="body" i18n-ngbTooltip/>
  <ng-template #helpPopover>
    <div i18n>Mapa umožňuje provést přesun bodů pomocí kliknutí myší.</div>
    <div i18n>Nakládky/vykládky budou při kliknutí označeny oranžovou barvou.</div>
    <div i18n>Nejdříve prvním kliknutím zvolte bod, a následně druhým kliknutím vyberte bod,</div>
    <div i18n>který si přejete přesunout přímo za zvolený bod.</div>
  </ng-template>
</div>

<div *ngIf="showHelp" class="bg-light border rounded shadow px-2 py-1" 
style="position: absolute; left: 100px; bottom: 30px;">
  <div i18n>Mapa umožňuje provést přesun bodů pomocí kliknutí myší.</div>
  <div i18n>Nakládky/vykládky budou při kliknutí označeny oranžovou barvou.</div>
  <div i18n>Nejdříve prvním kliknutím zvolte bod, a následně druhým kliknutím vyberte bod,</div>
  <div i18n>který si přejete přesunout přímo za zvolený bod.</div>
</div>

<!-- || !buildMapOnOpen -->
<div *ngIf="firstBuilding || rebuilding" class="spinner-wrapper pt-5" 
style="position:absolute; margin:0 auto; left:0; right:0; top:0; bottom:0">
  <div class="spinner-border text-primary" style="width:5rem; height:5rem;" role="status">
    <span class="sr-only"></span>
  </div>
</div>