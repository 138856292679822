import { GoogleLatLng } from "./google-lat-lng.object";
import { GoogleMapMarker } from "./google-map-marker.object";
import { Obligation } from "./obligation.object";
import { DateTools } from "../tools/DateTools";
import { CityDataInerfacte } from "../interface/city-data.inerfacte";
import { Haversine } from "../tools/Haversine";
import { defaultLengthUnit, ItineraryType, ServiceType } from "../config";

export class ServiceEventObject {

  public readonly isServiceEvent: boolean = true;

  static TYPE_REFUELING = ServiceType.FUELING;
  static TYPE_LOADING_START = ServiceType.LOADING_START;
  static TYPE_LOADING_END = ServiceType.LOADING_END;
  static TYPE_UNLOADING_START = ServiceType.UNLOADING_START;
  static TYPE_UNLOADING_END = ServiceType.UNLOADING_END;

  static TYPE_PREJEZD = ItineraryType.MOVING;
  static TYPE_TRANSIT = ItineraryType.TRANSIT;

  constructor() {
  }

  private _service_key: number;
  public get service_key(): number {
    return this._service_key;
  }
  public set service_key(value: number) {
    this._service_key = value;
  }

  private _car_key: number;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }
  
  // custom
  private _number_plate: string;
  public get number_plate(): string {
    return this._number_plate;
  }
  public set number_plate(value: string) {
    this._number_plate = value;
  }

  private _driver_key: number;
  public get driver_key(): number {
    return this._driver_key;
  }
  public set driver_key(value: number) {
    this._driver_key = value;
  }

  // custom
  private _driver_name: string = null;
  public get driver_name(): string {
    return this._driver_name;
  }
  public set driver_name(value: string) {
    this._driver_name = value;
  }
  
  private _driver2_key: number;
  public get driver2_key(): number {
    return this._driver2_key;
  }
  public set driver2_key(value: number) {
    this._driver2_key = value;
  }

  private _time: string;
  public get time(): string {
    return this._time;
  }
  public set time(value: string) {
    this._time = value;
    if (this._time) {
      this._timeDate = DateTools.isoFix(this._time);
      this._time_custom = DateTools.isoForDatetimeInput(this._timeDate);
    }
  }

  private _timeDate: Date;
  public get timeDate(): Date {
    return this._timeDate;
  }
  public set timeDate(value: Date) {
    this._timeDate = value;
  }
  
  get timeFormatted(): string {
    return DateTools.formatLocaleString(DateTools.isoFix(this._time));
  }

  // using in input datetime-local
  private _time_custom: string = null;
  public get time_custom(): string {
    return this._time_custom;
  }
  public set time_custom(value: string) {
    this._time_custom = value;
  }

  private _pos_gps: string;
  public get pos_gps(): string {
    return this._pos_gps;
  }
  public set pos_gps(value: string) {
    this._pos_gps = value;
    if (this._pos_gps) {
      this._latLng = GoogleLatLng.createFromPosString(this._pos_gps);

      if (this._latLng) {
        let lat = this._latLng.lat;
        let lng = this._latLng.lng;
        lat = Math.round(lat * 1000) / 1000;
        lat = Math.round(lng * 1000) / 1000;
        // format coord as number to 3 decimals
        this._latLngForGrouping = lat.toString() + ',' + lng.toString();
      }
    }
  }

  private _gps: string;
  public get gps(): string {
    return this._gps;
  }
  public set gps(value: string) {
    this._gps = value;
  }

  private _latLng: GoogleLatLng;
  get latLng(): GoogleLatLng {
    return this._latLng;
  }

  // using for fueling stats to remove duplicit petrol station
  private _latLngForGrouping: string; 
  public get latLngForGrouping(): string {
    return this._latLngForGrouping;
  }
  public set latLngForGrouping(value: string) {
    this._latLngForGrouping = value;
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
    if (this._type && GoogleMapMarker.icons['serviceEvent_' + this._type]) {
      this._type_icon = GoogleMapMarker.icons['serviceEvent_' + this._type].url;

      if (this._type == ServiceType.TOLL) {
        // override for TOLL
        this._type_icon = 'assets/icons-new/toll.svg';
      }
    }
  }

  private _type_icon: string = null;
  public get type_icon(): string {
    return this._type_icon;
  }

  private _code: string;
  public get code(): string {
    return this._code;
  }
  public set code(value: string) {
    this._code = value;
  }

  private _descr: string;
  public get descr(): string {
    return this._descr;
  }
  public set descr(value: string) {
    this._descr = value;
  }

  private _order_number: string;
  public get order_number(): string {
    return this._order_number;
  }
  public set order_number(value: string) {
    this._order_number = value;
  }

  private _distance: number;
  public get distance(): number {
    return this._distance;
  }
  public set distance(value: number) {
    this._distance = value;
  }
  
  get distanceFormatted(): string {
    if (!this._distance) {
      return 'N/A';
    }
    return (Math.round(this._distance * 100) / 100).toString();
  }
  
  get approximateDistance(): number {
    return Haversine.haversine(
      this.cityLatLng.toHaversine(),
      GoogleLatLng.createFromPosString(this._pos_gps).toHaversine()
    )
  }

  get approximateDistanceFormatted(): string {
    return (Math.round(this.approximateDistance * 10) / 10) + ' ' + defaultLengthUnit;
  }

  // or number ?
  private _liter: number;
  public get liter(): number {
    return this._liter;
  }
  public set liter(value: number) {
    this._liter = value;
  }

  private _price: number;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
    this._price_exchanged = value;
  }
  
  private _price_exchanged: number;
  public get price_exchanged(): number {
    return this._price_exchanged;
  }
  public set price_exchanged(value: number) {
    this._price_exchanged = value;
  }
  
  private _currency: string;
  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
  }

  private _clock_up: number;
  public get clock_up(): number {
    return this._clock_up;
  }
  public set clock_up(value: number) {
    this._clock_up = value;
  }

  private _weight: number;
  public get weight(): number {
    return this._weight;
  }
  public set weight(value: number) {
    this._weight = value;
  }

  private _length: number;
  public get length(): number {
    return this._length;
  }
  public set length(value: number) {
    this._length = value;
  }

  private _fuel_gauge: number;
  public get fuel_gauge(): number {
    return this._fuel_gauge;
  }
  public set fuel_gauge(value: number) {
    this._fuel_gauge = value;
  }

  private _containers_in: number;
  public get containers_in(): number {
    return this._containers_in;
  }
  public set containers_in(value: number) {
    this._containers_in = value;
  }

  private _containers_out: number;
  public get containers_out(): number {
    return this._containers_out;
  }
  public set containers_out(value: number) {
    this._containers_out = value;
  }

  // custom
  private _city_autocompleter: any;
  public get city_autocompleter(): any {
    return this._city_autocompleter;
  }
  public set city_autocompleter(value: any) {
    this._city_autocompleter = value;
  }

  private _city_key: string;
  public get city_key(): string {
    return this._city_key;
  }
  public set city_key(value: string) {
    this._city_key = value;
  }

  private _city: CityDataInerfacte;
  public get city(): CityDataInerfacte {
    return this._city;
  }
  public set city(value: CityDataInerfacte) {
    this._city = value;
    if (this._city && this._city.zip) {
      if (this._city.zip && this._city.zip.length > 2) {
        this._city_zip_short = this._city.zip.substring(0, 2);
      }
    }
  }

  // custom
  private _city_zip_short: string;
  public get city_zip_short(): string {
    return this._city_zip_short;
  }

  get cityLatLng(): GoogleLatLng {
    return GoogleLatLng.createFromPosString(this._city.geo_pos);
  }

  // TOREMOVE
  get cityShort(): string {
    return this.city.country + ' ' + this.city.zip.substring(0, 2);
  }

  private _full_tank_refueling: boolean = false;
  public get full_tank_refueling(): boolean {
    return this._full_tank_refueling;
  }
  public set full_tank_refueling(value: boolean) {
    this._full_tank_refueling = value;
  }

  private _payment_type: string;
  public get payment_type(): string {
    return this._payment_type;
  }
  public set payment_type(value: string) {
    this._payment_type = value;
  }

  private _tank_type: string;
  public get tank_type(): string {
    return this._tank_type;
  }
  public set tank_type(value: string) {
    this._tank_type = value;
  }

  get TYPE_REFUELING(): string {
    return ServiceEventObject.TYPE_REFUELING;
  }

  get TYPE_LOADING_START(): string {
    return ServiceEventObject.TYPE_LOADING_START;
  }

  get TYPE_LOADING_END(): string {
    return ServiceEventObject.TYPE_LOADING_END;
  }

  get TYPE_UNLOADING_START(): string {
    return ServiceEventObject.TYPE_UNLOADING_START;
  }

  get TYPE_UNLOADING_END(): string {
    return ServiceEventObject.TYPE_UNLOADING_END;
  }

  toMarker(): GoogleMapMarker {
    let marker: GoogleMapMarker = new GoogleMapMarker();
    // marker.icon = GoogleMapMarker.icons['serviceEvent_' + this._data.type];
    marker.icon = GoogleMapMarker.icons['sm_' + this._type];
    marker.position = this.latLng;
    return marker;
  }

  // temp itinerary_key
  private _itinerary_key: number;
  public get itinerary_key(): number {
    return this._itinerary_key;
  }
  public set itinerary_key(value: number) {
    this._itinerary_key = value;
  }

  // temp address
  private _address: string;
  public get address(): string {
    return this._address;
  }
  public set address(value: string) {
    this._address = value;
  }

  // Atributes for ta3-palete.component
  private _vehicleSpz: string;
  public get vehicleSpz(): string {
    return this._vehicleSpz;
  }
  public set vehicleSpz(v: string) {
    this._vehicleSpz = v;
  }
  
  private _trailerSpz: string;
  public get trailerSp(): string {
    return this._trailerSpz;
  }
  public set trailerSp(v: string) {
    this._trailerSpz  = v;
  }
  
  private _driver1Name: string;
  public get driver1Name(): string {
    return this._driver1Name;
  }
  public set driver1Name(v: string) {
    this._driver1Name = v;
  }
  
  private _driver2Name: string;
  public get driver2Name(): string {
    return this._driver2Name;
  }
  public set driver2Name(v: string) {
    this._driver2Name = v;
  }
  
  private _obligation: Obligation;
  public get obligation(): Obligation {
    return this._obligation;
  }
  public set obligation(v: Obligation) {
    this._obligation = v;
  }

  private _marked: boolean = false;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }

  // fueling stats temp property
  private _fuelingAtTheSameTime: boolean = false;
  public get fuelingAtTheSameTime(): boolean {
    return this._fuelingAtTheSameTime;
  }
  public set fuelingAtTheSameTime(value: boolean) {
    this._fuelingAtTheSameTime = value;
  }

  private _origin:string;
  public get origin():string{
    return this._origin;
  }
  public set origin(value:string){
    this._origin = value;
  }

  get postApiObject(): any {
    return {
      car_key: this.car_key,
      city_key: this.city_key,
      driver_key: this.driver_key,
      order_number: this.order_number,
      type: this.type,
      pos_gps: this.pos_gps,
      time: DateTools.toIsoWithoutMilisec(new Date(this._time_custom)),
      weight: this.weight,
      length: this.length,
      descr: this.descr,
      containers_in: this.containers_in,
      containers_out: this.containers_out,
      liter: this.liter,
      price: this.price,
      currency: this.currency,
      tank_type: this.tank_type,
      clock_up: this.clock_up,
      full_tank_refueling: this.full_tank_refueling,
      payment_type: this.payment_type,
      origin:this.origin
    };
  }

  get putApiObject(): any {
    return {
      type: this.type,
      time: DateTools.toIsoWithoutMilisec(new Date(this._time_custom)),
      weight: this.weight,
      length: this.length,
      descr: this.descr,
      containers_in: this.containers_in,
      containers_out: this.containers_out,
      clock_up: this.clock_up,
      price: this.price,
      currency: this.currency,
      liter: this.liter,
      tank_type: this.tank_type,
      full_tank_refueling: this.full_tank_refueling,
      payment_type: this.payment_type,
      city_key: this.city_key,
      pos_gps: this.pos_gps
    };
  }
}