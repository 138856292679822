import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable } from "rxjs";
import { IS_DEMO } from "../../config";
import { AuthenticationService } from "../../service/authentication.service";
import { User } from "../../model/user.object";

declare var $: any;

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})

export class LoginDialogComponent {
  constructor(
    private _authenticationService: AuthenticationService
  ) {
  }

  @Input()
  set open(open: boolean) {
    this._visible = open;
    window.setTimeout(
      () => {
        if (this._visible) {
          (<any>$('#loginModal')).modal('show');
        }
      }, 250
    );

  }

  private _closed: EventEmitter<any> = new EventEmitter();
  @Output()
  get closed(): Observable<any> {
    return this._closed.asObservable();
  }

  private _password: string;
  get password(): string {
    return this._password;
  }
  set password(value: string) {
    this._password = value;
  }

  private _login: string;
  get login(): string {
    return this._login;
  }
  set login(value: string) {
    this._login = value;
  }

  private _visible: boolean = false;
  get visible(): boolean {
    return this._visible;
  }
  get user(): User {
    return this._authenticationService.user;
  }

  private _loginError: string;
  get loginError(): string {
    return this._loginError;
  }

  signIn(event: any) {
    this._loginError = null;
    this._authenticationService.authenticate(this.login, this.password).subscribe(
      result => {
        if (this._authenticationService.isAuthenticated()) {
          this.close();
          // reload app
          window.location.reload();
        }
      }
    );
    this._login = '';
    this._password = '';
  }

  logout() {
    this._authenticationService.logout();
  }

  close(): void {
    this._visible = false;
    this._closed.emit(true);
    (<any>$('#loginModal')).modal('hide');
  }

  refresh() {
    if (this._authenticationService.isAuthenticated()) {
      this._authenticationService.refreshToken();
    }
  }

  closeButtonAvailable(): boolean {
    return IS_DEMO || this.user !== null;
  }
}
