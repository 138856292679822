import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { MessageService } from "../../service/message.service";
import { Message } from "../../model/message.object";
import { DateTools } from "../../tools/DateTools";

@Component({
  selector: 'messages-new',
  templateUrl: './r-messages-new.component.html',
  styleUrls: ['./r-messages-new.component.css']
})
export class RMessagesNewComponent implements OnDestroy {

  private _subscribers: Array<Subscription> = [];

  private _newMessages: Array<Message> = [];
  public get newMessages(): Array<Message> {
    return this._newMessages;
  }

  private _loading: boolean = false;
  get loading(): boolean {
    return this._loading;
  }

  private _broadcastVisible: boolean = false;
  get broadcastVisible(): boolean {
    return this._broadcastVisible;
  }

  constructor(
    private _messageService: MessageService,
    private _router: Router
  ) {
    // disable message dialog when opened this message component
    _messageService.newMessagesDialogDisabled = true;
  }

  ngOnInit() {
    this._loading = true;
    this._subscribers.push(
      this._messageService.getNewMessages(true).subscribe(
        messages => {
          this._newMessages = messages;
          this._loading = false;
        },
        error => {
          console.log(error);
          this._loading = false;
        }
      )
    );
  }

  ngOnDestroy() {
    this._messageService.newMessagesDialogDisabled = false;
    this._subscribers.forEach(
      subscriber => {
        subscriber.unsubscribe();
      }
    )
  }

  get lastLoadTime(): string {
    return DateTools.formatLocaleString(this._messageService.lastLoadDate, '%hours:%minutes')
  }


  readAllNew() {
    let currentRequest: number = 0;
    let totalRequests: number = this._newMessages.length;

    this._newMessages.slice().forEach(
      message => {
        this._messageService.readMessage(message).subscribe(
          response => {
            // after read all messages -> routing to cars dashboard
            currentRequest += 1;
            if (currentRequest == totalRequests) {
              // keep previous route query params
              let route: any = this._router.parseUrl(this._router.url);
              // add param of new message (components could not be reloaded)
              route.queryParams['rightMenu'] = true;
              // open dashbord with non read messages
              let url = this._router.serializeUrl(
                this._router.createUrlTree(
                  [{outlets: {right: 'cars'}}], {queryParams: route.queryParams} 
                )
              );
              this._router.navigateByUrl(url);
            }
          },
          error => {
            console.log(error);
          }
        )
      }
    )
  }

  showBroadcast() {
    this._broadcastVisible = true;
  }

  hideBroadcast() {
    this._broadcastVisible = false;
  }

  refreshNewMessages() {
    // this._newMessages = [];
    this._loading = true;
    this._messageService.getNewMessages(true).subscribe();
  }
}
