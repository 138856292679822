import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { AsyncSubject, BehaviorSubject, Observable, Subscription } from "rxjs";
import { map } from 'rxjs/operators';

import { HttpClientService } from "./http-client.service";
import { TruckManagerLayoutService } from "./truck-manager-layout.service";
import { UserConfigurationService } from "./user-configuration.service";
import { WebConnectivityService } from "./web.connectivity.service";
import { AuthenticationService } from "./authentication.service";
import { WebsocketService } from "./websocket.service";
import { MessageAttachmentObject } from "../model/message-attachment.object";
import { Message } from "../model/message.object";
import { Vehicle } from "../model/vehicle.object";
import { VehicleListConfigurationInterface } from "../interface/vehicle-list-configuration.interface";
import { UserConfigurationInterface } from "../interface/user-configuration.interface";
import { WebsocketResponse } from "../interface/websocket-response.interface";
import { DateTools } from "../tools/DateTools";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  private _subscribers: Array<Subscription> = [];
  
  // private apiUrl: string = ServiceConfiguration.message.apiMessageDashboard;
  // private apiUrl: string = ServiceConfiguration.message.apiMessageDashboardUnread;
  // private editApiUrl: string = ServiceConfiguration.message.apiEditMessage;

  private _loadingMessages: boolean = false;
  private _messages: BehaviorSubject<Array<Message>> = new BehaviorSubject([]);
  private _newMessages: BehaviorSubject<Array<Message>> = new BehaviorSubject([]);
  private _loadedHistoryMessagesByDay: any = {};
  private _historyMessagesByDay: BehaviorSubject<any> = new BehaviorSubject({});
  private _unsentMessages = {};//car key indexed messages that are written in components to not lose content in case of component reload
  
  // user configuration
  private _userConfig: UserConfigurationInterface;
  private _vehiclesConfig: VehicleListConfigurationInterface;

  constructor(
    private _http: HttpClientService,
    private _websocketService: WebsocketService,
    private _authService: AuthenticationService,
    private _connService: WebConnectivityService,
    private _layoutService: TruckManagerLayoutService,
    private _configurationService: UserConfigurationService,
    private _datePipe: DatePipe
  ) {
    // load user configuration  
    this._userConfig = JSON.parse(JSON.stringify(this._configurationService.configuration));
    this._vehiclesConfig = this._configurationService.configuration.defaultVehicleListConfiguration;

    this._subscribers.push(
      this._websocketService.companyMessage.subscribe(
        companyMessage => {
          this.handleWebsocketCompanyMessage(companyMessage);
        }
      ),
      this._authService.authenticationResult.subscribe(
        user => {
          if (IS_DEMO || user) {
            this.cleanCache();
            this.getMessages(true);
          } else {
            this.cleanCache();
          }
        }
      ),
      this._connService.reconnectedAfterInterval.subscribe(
        (anyValue) => {
          this.cleanCache();
          this.getMessages(true);
        }
      ),
      this._configurationService.configChanged.subscribe(
        (changeSet: any) => {
          if (changeSet.vehicle_filters || changeSet === true) {
            this._layoutService.newMessagesCount = this.loadedNewMessages.length;
            this._newMessages.next(this.loadedNewMessages);
            this._messages.next(this.loadedMessages);
          }
        }
      )
    );

    
    window.setInterval(
      () => {
        this._lastLoadDate = new Date();
      },
      60000
    );
  }

  /* Getters and initializing methods */
  private _lastLoadDate: Date = new Date();
  get lastLoadDate(): Date {
    return this._lastLoadDate;
  }

  private _newMessagesDialogDisabled: boolean = false;
  get newMessagesDialogDisabled(): boolean {
    return this._newMessagesDialogDisabled;
  }
  set newMessagesDialogDisabled(value: boolean) {
    this._newMessagesDialogDisabled = value;
  }

  private _loadedMessages: Array<Message> = [];
  private get loadedMessages(): Array<Message> {
    return this._loadedMessages.filter(this.filterByVehicleFilter, this);
  }
  private set loadedMessages(value: Array<Message>) {
    this._loadedMessages = value;
  }

  private _loadedNewMessages: Array<Message> = [];
  private get loadedNewMessages(): Array<Message> {
    let array = this._loadedNewMessages.filter(this.filterByVehicleFilter, this);
    this._layoutService.newMessagesCount = array.length;
    return array;
  }
  private set loadedNewMessages(value: Array<Message>) {
    this._loadedNewMessages = value;
  }

  private _loadingMessagesHistory: boolean = false;
  public get loadingMessagesHistory(): boolean {
    return this._loadingMessagesHistory;
  }

  public cleanCache() {
    this.loadedMessages = [];
    this.loadedNewMessages = [];
    this._loadedHistoryMessagesByDay = {};
    this._messages.next(this.loadedMessages);
    this._newMessages.next(this.loadedNewMessages);
    this._historyMessagesByDay.next(this._loadedHistoryMessagesByDay);
  }

  private removeMessageFromMemory(message: Message) {
    let index = this.loadedMessages.indexOf(message);
    if (index > -1) {
      this.loadedMessages.splice(index, 1);
      this.loadedMessages = this.loadedMessages.slice();
      this._messages.next(this.loadedMessages.slice());
    }
    index = this.loadedNewMessages.indexOf(message);
    if (index > -1) {
      this.loadedNewMessages.splice(index, 1);
      this._newMessages.next(this.loadedNewMessages.slice());
    }
  }


  /* methods for getting message(s) */
  getMessagesRequest(): Observable<any> {
    return this._http.get(ServiceConfiguration.message.apiMessageDashboardUnread);
  }

  getMessages(reload?: boolean): Observable<Array<Message>> {
    if (!this._loadingMessages && (!this.loadedMessages.length || reload) && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._loadingMessages = true;
      this.getMessagesRequest().subscribe(
        (response) => {
          if (response) {
            let messages = response;
            let messageArray: Array<Message> = [];

            if (messages instanceof Array) {
              messages.forEach(
                message_data => {
                  let message: Message = this.createMessage(message_data);
                  messageArray.push(message);
                }
              )
            }
            this.pushMessages(messageArray);
            this._lastLoadDate = new Date();

            this._loadingMessages = false;
          }
        },
        error => {
          console.log(error);
        }
      );
    }
    return this._messages.asObservable();
  }

  getNewMessages(reload?: boolean): Observable<Array<Message>> {
    this._loadedNewMessages = [];
    this.getMessages(reload);
    this._newMessages.next(this.loadedNewMessages);
    return this._newMessages.asObservable();
  }

  getMessagesHistory(dayOffset: number, days: number, refDate?: Date): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingMessagesHistory = true;

      let from = new Date();
      if (refDate) from = new Date(refDate.getTime());
      from.setDate(from.getDate() + dayOffset);
      from.setHours(0, 0, 0, 0);
      let to = new Date();

      if (refDate) to = new Date(refDate.getTime());
      to.setDate(to.getDate() + (dayOffset + days));
      to.setHours(23, 59, 59, 999);

      let allInitialized = true;
      let tempFrom = new Date();
      tempFrom.setTime(from.getTime());

      if (tempFrom.toDateString() === to.toDateString()) {
        allInitialized = false;
        let dayString = this.getLoadedHistoryDayKey(tempFrom);
        if (!this._loadedHistoryMessagesByDay[dayString]) {
          this._loadedHistoryMessagesByDay[dayString] = [];
        }
      }

      while (tempFrom.toDateString() !== to.toDateString()) {
        tempFrom.setDate(tempFrom.getDate() + 1);
        let dayString = this.getLoadedHistoryDayKey(tempFrom);
        if (!this._loadedHistoryMessagesByDay[dayString]) {
          this._loadedHistoryMessagesByDay[dayString] = [];
          allInitialized = false;
        }
      }

      if (!allInitialized) {
        this._http.get(
          ServiceConfiguration.message.apiMessage
          + '?tf=' + DateTools.toApiFormat(from)
          + '&tt=' + DateTools.toCustomIsoApiFormat(to)
        ).subscribe(
          (messages: Array<any>) => {
            messages.forEach(
              message => {
                let messageObj = this.createMessage(message);
                if (this.filterByVehicleFilter(messageObj)) {
                  this.pushMessageToLoadedHistory(messageObj);
                }
              }
            );
            this._historyMessagesByDay.next(this._loadedHistoryMessagesByDay);
            this._loadingMessagesHistory = false;
          },
          error => {
            console.log(error);
            this._loadingMessagesHistory = false;
          }
        );
      } 
      else {
        this._historyMessagesByDay.next(this._loadedHistoryMessagesByDay);
        // lets simulate loading for 500ms
        window.setTimeout(() => this._loadingMessagesHistory = false, 500);
      }
    }
    
    return this._historyMessagesByDay.asObservable();
  }

  private getMessageByKey(key: number, array?: string): Message {
    let findInArray: Array<Message> = this.loadedMessages;
    switch (array) {
      case 'new':
        findInArray = this.loadedNewMessages;
        break;
    }
    let message: Message;
    findInArray.forEach(
      fromMessages => {
        if (key === fromMessages.msg_key) {
          message = fromMessages;
        }
      }
    );
    return message;
  }

  private getMessageApiUrl(message: Message): string {
    return ServiceConfiguration.message.apiEditMessage.replace(/%ID%/g, message.msg_key.toString());
  }

  private getLoadedHistoryDayKey(date: Date) {
    return this._datePipe.transform(date, 'dd.MM.yyyy');
    // return DateTools.formatLocaleString(date, '%day.%month.%year');
  }

  
  /* method for sending message */
  sendMessageToVehicle(message: string, vehicle: Vehicle): Observable<Message> {
    let newMessage: Message = this.createMessage(
      {
        type: Message.TYPE_DISPATCHER,
        msg: message,
        car_key: vehicle.car_key,
        direction_to_car: true,
        person_key: this._authService.user.display_key
      }
    );
    return this.createMessageRequest(newMessage);
  }

  /* method for sending message */
  sendMessageToVehicle2(message: string, car_key: number): Observable<Message> {
    let newMessage: Message = this.createMessage(
      {
        type: Message.TYPE_DISPATCHER,
        msg: message,
        car_key: car_key,
        direction_to_car: true,
        person_key: this._authService.user.display_key
      }
    );
    return this.createMessageRequest(newMessage);
  }

  sendSystemMessageToVehicle(message: string, vehicle: Vehicle): Observable<Message> {
    let newMessage: Message = this.createMessage(
      {
        type: Message.TYPE_DISPATCHER,
        msg: message,
        car_key: vehicle.car_key,
        direction_to_car: false,
        person_key: this._authService.user.display_key,
        system_msg: true
      }
    );
    return this.createMessageRequest(newMessage);
  }


  /* method for deleting message */
  deleteMessage(message: Message): Observable<any> {
    let messageDelete = new AsyncSubject();
    let index: number;
    if ((index = this.loadedMessages.indexOf(message)) > -1) {
      //ajaxrequest here
      this._http.delete(this.getMessageApiUrl(message)).subscribe(
        success => {
          this.removeMessageFromMemory(message);
          messageDelete.next(true);
        },
        error => {
          //well not rly removing but for try
          this.removeMessageFromMemory(message);
          messageDelete.next(false);
        }
      );
    }
    return messageDelete.asObservable();
  }

  
  /* method for reading/unreading message logic */
  messageRead(message: Message) {
    message.unread = false;
  }

  public readMessage(message: Message): Observable<any> {
    message.unread = false;
    return this.editMessageRequest(message, ['unread']).pipe(map(
      () => {
        this.removeMessageFromUnread(message);
      }
    ));
  }

  private removeMessageFromUnread(message: Message): boolean {
    if (this._loadedNewMessages.indexOf(message) > -1 && message.unread === false) {
      this._loadedNewMessages.splice(this._loadedNewMessages.indexOf(message), 1);
      this._layoutService.newMessagesCount = this.loadedNewMessages.length;
      this._newMessages.next(this.loadedNewMessages);
      return true;
    } else {
      return false;
    }
  }

  addUnsent(carKey: number, message: string) {
    this._unsentMessages[carKey] = message;
  }

  getUnsent(carKey: number): string {
    return this._unsentMessages[carKey] ? this._unsentMessages[carKey].toString() : '';
  }


  /* PUT request template */
  private editMessageRequest(message: Message, update: Array<string>): Observable<any> {
    let data = {};
    update.forEach(
      key => {
        data[key] = message[key];
      }
    );
    data['msg_key'] = message.msg_key;

    return this._http.put(this.getMessageApiUrl(message), data).pipe(
      map(
        response => {
          this.removeMessageFromUnread(message);
        },
        error => {
          if (!this._authService.isAuthenticated()) {
            this.removeMessageFromUnread(message);
          }
        }
      ));
  }

  /* POST request template */
  private createMessageRequest(message: Message): Observable<Message> {
    let data: any = {};
    data = {
      car_key: message.car_key,
      direction_to_car: message.direction_to_car,
      msg: message.msg,
      reply: message.reply,
      type: message.type
    };
    // TODO pridat objekt pro api do tridy Message
    return this._http.post(
      ServiceConfiguration.message.apiMessage,
      data
    ).pipe(map(
      json => {
        for (let key in json) {
          message[key] = json[key]
        }
        this.pushMessageToNew(message);
        return message;
      }
    ))
  }


  /* Websocket handler */
  private handleWebsocketCompanyMessage(companyMessage: WebsocketResponse) {
    if (companyMessage.relation === WebsocketService.RELATIONS.message) {
      let message: Message = this.getMessageByKey(companyMessage.record.msg_key);

      switch (companyMessage.operation) {
        case WebsocketService.OPERATIONS.insert:
        case WebsocketService.OPERATIONS.update:
          if (message) {
            this.updateMessageFromCompanyMessage(message, companyMessage);
          } 
          else {
            this.createMessageFromCompanyMessage(companyMessage);
          }
          break;
        case WebsocketService.OPERATIONS.delete:
          if (message) {
            this.removeMessageFromMemory(message);
          }
          break;
        default:
          console.warn('unsupported operation');
          break;
      }

      this._lastLoadDate = new Date();
    }
    else if (companyMessage.relation === WebsocketService.RELATIONS.obligation_msg) {
      let message: Message = this.getMessageByKey(companyMessage.record.msg_key);
      switch (companyMessage.operation) {
        case WebsocketService.OPERATIONS.insert:
        case WebsocketService.OPERATIONS.update:
          if (message) {
            // update itinerary_key and obligation_key
            for (let key in companyMessage.record) {
              message[key] = companyMessage.record[key];
            }
            this._newMessages.next(this.loadedNewMessages.slice());
            // this.updateMessageFromCompanyMessage(message, companyMessage);
          } 
          // else {
          //   this.createMessageFromCompanyMessage(companyMessage);
          // }
          break;
        case WebsocketService.OPERATIONS.delete:
          break;
        default:
          console.warn('unsupported operation');
          break;
      }
    }
  }

  
  /* Methods for logic of creating and pushing messages to arrays and subjects */
  private createMessage(data: any): Message {
    let message = new Message();
    for (let key in data) {
      message[key] = data[key];
    }
    if (data.attachment) {
      message.attachment = new MessageAttachmentObject(
        ServiceConfiguration.files.filesApiVehicle, 
        data.attachment, 
        message.car_key, 
        data.attachment_type, 
        null, 
        data.attachment_url
      );
    }
    return message;
  }

  private createMessageFromCompanyMessage(companyMessage: any) {
    let message: Message = this.getMessageByKey(companyMessage.record.message_key) ? this.getMessageByKey(companyMessage.record.message_key) : this.createMessage(companyMessage.record);
    this.pushMessage(message);
  }

  private updateMessageFromCompanyMessage(message: Message, companyMessage: WebsocketResponse) {
    let inNew: boolean = false;
    if (this.loadedNewMessages.indexOf(message) > -1 && !this.getMessageByKey(message.msg_key, 'new')) {
      inNew = true;
    }
    for (let key in companyMessage.record) {
      message[key] = companyMessage.record[key];
    }
    if (message.unread === false && inNew) {
      this.removeMessageFromUnread(message);
    } 
    else if (message.unread && !inNew) {
      this.pushMessageToNew(message);
    }
    this._messages.next(this.loadedMessages.slice());
  }


  private pushMessages(messages: Array<Message>) {
    messages.forEach(
      message => this.pushMessage(this.filterByVehicleFilter(message))
    );
  }

  private pushMessage(message: Message) {
    this.pushMessageToNormal(message);
    if (message && message.type !== Message.TYPE_DRIVERS) {
      this.pushMessageToNew(message);
    }
  }

  private pushMessageToNormal(message: Message) {
    if (!message || !message.msg_key) return;

    if (this.loadedMessages.indexOf(message) === -1 && !this.getMessageByKey(message.msg_key)) {
      this._loadedMessages.push(message);
      this._messages.next(this.loadedMessages.slice());
    }
  }

  private pushMessageToNew(message: Message) {
    if (message.unread && ((message.unread && message.read_by_tm && message.direction_to_car === true) || message.direction_to_car === false) && this.loadedNewMessages.indexOf(message) === -1) {
      this._loadedNewMessages.push(message);
      this._newMessages.next(this.loadedNewMessages.slice());
      // fix for updating history messages
      if (this.filterByVehicleFilter(message)) {
        this.pushMessageToLoadedHistory(message);
      }
      this._historyMessagesByDay.next(this._loadedHistoryMessagesByDay);
    }
    else if (message.unread && message.direction_to_car === true && this.loadedNewMessages.indexOf(message) === -1) {
      if (this.filterByVehicleFilter(message)) {
        this.pushMessageToLoadedHistory(message);
      }
      this._historyMessagesByDay.next(this._loadedHistoryMessagesByDay);
    }
    this._layoutService.newMessagesCount = this.loadedNewMessages.length;
  }

  private pushMessageToLoadedHistory(message: Message) {
    let day = this.getLoadedHistoryDayKey(message.time);
    if (!this._loadedHistoryMessagesByDay[day]) {
      this._loadedHistoryMessagesByDay[day] = [];
    }

    let alreadyExist = this._loadedHistoryMessagesByDay[day].find(m => m.msg_key == message.msg_key);
    if (!alreadyExist) {
      this._loadedHistoryMessagesByDay[day].push(message);
    }
  }

  private filterByVehicleFilter(message: Message): Message {
    // let config = this._vehiclesConfig.vehicle_filter;
    let config = this._configurationService.configuration.defaultVehicleListConfiguration.vehicle_filter;
    // if ((config && config.length === 0) || (config.length && config.indexOf(message.car_key) > -1)) {
    if (config && (config.length == 0 || (config.length && config.indexOf(message.car_key) > -1))) {
      return message;
    }
    return null;
  }
}
