import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ServiceConfiguration } from '../config';
import { IS_DEMO } from '../connect';
import { GeoPosition } from '../model/geo-position.object';
import { TrackingEvent } from '../model/tracking-event.object';
import { TrackingEventService } from './tracking-event.service';
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { FuelcardEvent } from '../model/fuelcard-event.object';

@Injectable({
  providedIn: 'root'
})
export class FuelcardEventService {
 

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {}

  // GET '/events/fuelcard/' 
  getFuelcardEventsSeparately(filterObj: any): Observable<Array<TrackingEvent>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.fuelcardEvent.api;
      // add url query
      if (filterObj) {
        let filterKeys: Array<string> = Object.keys(filterObj);
        filterKeys.forEach(
          (key, index) => {
            if (index == 0 && filterObj[key]) {
              url += '?' + key + '=' + filterObj[key];
            }
            else if (filterObj[key]) {
              url += '&' + key + '=' + filterObj[key];
            }
          }
        );
      }

      return this._http.get(url);
    }
  }

  // POST '/events/fuelcard/' 
  createEvent(fuelImport: FuelcardEvent): Observable<FuelcardEvent> {
    let result: BehaviorSubject<FuelcardEvent> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.fuelcardEvent.api;

      this._http.post(url, fuelImport.apiObject).subscribe(
        response => {
          if (response) {
            // alert
            let alert: string = $localize`Událost byla úspěšně vytvořena.`;
            this._notificationService.alert(alert, 'success', 4000);
            
            // observable next
            result.next(FuelcardEventService.buildRespose(response));
          }
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při vytváření události.`;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // method for creating tracking events array
  static buildFuelingcardEventsFromData(data: Array<any>): any {
    let events: Array<FuelcardEvent> = [];
    if (data) {
      data.forEach(
        te => {
          let tEvent = FuelcardEventService.buildRespose(te);
          events.push(tEvent);
        }
      );
    }
    return events;
  }

  // method for creating a single tracking event
  static buildRespose(te: any): FuelcardEvent {
    let tEvent: FuelcardEvent = new FuelcardEvent();
    for (let key in te) {
      tEvent[key] = te[key];
    }
    
    //tEvent.geoPos = new GeoPosition();
    //tEvent.geoPos.pos_gps = tEvent.pos_gps;
    
    return tEvent;
  }
}
