export class Make {
  private _make_id: number;
  get make_id(): number {
    return this._make_id;
  }
  set make_id(value: number) {
    this._make_id = value;
  }

  private _name: string;
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
}
