import { Attachment } from './attachment.object';

export class Email {
  private _email_msg_id: string;
  public get email_msg_id(): string {
    return this._email_msg_id;
  }
  public set email_msg_id(value: string) {
    this._email_msg_id = value;
  }

  private _body: string;
  public get body(): string {
    return this._body;
  }
  public set body(value: string) {
    this._body = value;
  }

  private _cc: Array<string> = [];
  public get cc(): Array<string> {
    return this._cc;
  }
  public set cc(value: Array<string>) {
    this._cc = value;
  }

  // header files used to be array of string ['xxx1', 'xxx2', 'xxx3']
  // now it is array of objects - { index: 0, name: 'xxx' }
  private _files: Array<any> = [];
  public get files(): Array<any> {
    return this._files;
  }
  public set files(value: Array<any>) {
    this._files = value;
  }

  private _filesObjects: Array<Attachment> = [];
  public get filesObjects(): Array<Attachment> {
    return this._filesObjects;
  }
  public set filesObjects(value: Array<Attachment>) {
    this._filesObjects = value;
  }

  private _from: string;
  public get from(): string {
    return this._from;
  }
  public set from(value: string) {
    this._from = value;
  }

  private _invoice_key: number;
  public get invoice_key(): number {
    return this._invoice_key;
  }
  public set invoice_key(value: number) {
    this._invoice_key = value;
  }

  private _sent_date: string;
  public get sent_date(): string {
    return this._sent_date;
  }
  public set sent_date(value: string) {
    this._sent_date = value;
    if (this._sent_date) {
      this._sent_date_object = new Date(this._sent_date);
    }
  }

  private _sent_date_object: Date;
  public get sent_date_object(): Date {
    return this._sent_date_object;
  }
  public set sent_date_object(value: Date) {
    this._sent_date_object = value;
  }
  
  private _subject: string;
  public get subject(): string {
    return this._subject;
  }
  public set subject(value: string) {
    this._subject = value;
  }
  
  private _to: string;
  public get to(): string {
    return this._to;
  }
  public set to(value: string) {
    this._to = value;
  }
}