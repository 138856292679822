import { DateTools } from "../tools/DateTools";
import { Model } from "../model/model.object";
import { ServiceTask } from "./service-task.object";

export class Trailer {
  private _accident_insurance: number;
  get accident_insurance(): number {
    return this._accident_insurance;
  }
  set accident_insurance(value: number) {
    this._accident_insurance = value;
    this._update.push("accident_insurance");
  }

  private _adr: string;
  get adr(): string {
    return this._adr;
  }
  set adr(value: string) {
    this._adr = value;
    this._update.push("adr");
  }

  private _amortization: number;
  get amortization(): number {
    return this._amortization;
  }
  set amortization(value: number) {
    this._amortization = value;
    this._update.push("amortization");
  }

  private _axles: number;
  get axles(): number {
    return this._axles;
  }
  set axles(value: number) {
    this._axles = value;
    this._update.push("axles");
  }

  private _capacity: number;
  get capacity(): number {
    return this._capacity;
  }
  set capacity(value: number) {
    this._capacity = value;
    this._update.push("capacity");
  }

  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
    this._update.push("car_key");
  }

  private _cargo_insurance: number;
  get cargo_insurance(): number {
    return this._cargo_insurance;
  }
  set cargo_insurance(value: number) {
    this._cargo_insurance = value;
    this._update.push("cargo_insurance");
  }

  private _company_key: number;
  get company_key(): number {
    return this._company_key;
  }
  set company_key(value: number) {
    this._company_key = value;
  }

  private _description: string;
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
    this._update.push("description");
  }

  private _height: number;
  get height(): number {
    return this._height;
  }
  set height(value: number) {
    this._height = value;
    this._update.push("height");
  }

  private _inactive: Boolean;
  get inactive(): Boolean {
    return this._inactive;
  }
  set inactive(value: Boolean) {
    this._inactive = value;
    this._update.push("inactive");
  }

  private _last_modif: Date;
  get last_modif(): Date {
    return this._last_modif;
  }
  set last_modif(value) {
    if (value) {
      this._last_modif = DateTools.isoFix(value.toString());
    }
  }

  private _leasing: number;
  get leasing(): number {
    return this._leasing;
  }
  set leasing(value: number) {
    this._leasing = value;
    this._update.push("leasing");
  }

  private _length: number;
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
    this._update.push("length");
  }

  private _liability_insurance: number;
  get liability_insurance(): number {
    return this._liability_insurance;
  }
  set liability_insurance(value: number) {
    this._liability_insurance = value;
    this._update.push("liability_insurance");
  }

  private _make: string;
  get make(): string {
    return this._make;
  }
  set make(value: string) {
    this._make = value;
    // make is obsolete and we update just model (viz doc)
    this._update.push("model_id");
  }

  private _model: Model;
  get model(): Model {
    return this._model;
  }
  set model(value: Model) {
    this._model = value;
  }

  private _model_id: number;
  get model_id(): number {
    return this._model_id;
  }
  set model_id(value: number) {
    this._model_id = value;
    this._update.push("model_id");
  }

  private _number_plate: string;
  get number_plate(): string {
    return this._number_plate;
  }
  set number_plate(value: string) {
    this._number_plate = value;
    this._update.push("number_plate");
  }

  private _partner_company_key: string;
  get partner_company_key(): string {
    return this._partner_company_key;
  }
  set partner_company_key(value: string) {
    this._partner_company_key = value;
  }

  private _production_year: Date;
  get production_year(): Date {
    return this._production_year;
  }
  set production_year(value: Date) {
    this._production_year = new Date(value);
    if (this._production_year) {
      this._production_year_number = this._production_year.getFullYear();

      // formatted age of vehicle in year
      let date: Date = new Date();
      let currentYear: number = date.getFullYear();
      let diff = currentYear - this._production_year_number;
      this._age = '~' + diff;
      if (diff <= 1) {
        this._age_year_formatted = $localize`rok`;
      }
      else if (diff > 1 && diff < 5) {
        this._age_year_formatted = $localize`roky`;
      }
      else {
        this._age_year_formatted = 'let';
      }
    }
  }

  private _production_year_number: number;
  get production_year_number(): number {
    return this._production_year_number;
  }
  set production_year_number(value: number) {
    this._production_year_number = value;
    if (value) {
      // set on June 30 of selected year
      this._production_year = new Date(value.toString() + '-06-30');
    }
    this._update.push("production_year");
  }

  private _age: string;
  public get age(): string {
    return this._age;
  }
  public set age(value: string) {
    this._age = value;
  }
  
  private _age_year_formatted: string;
  public get age_year_formatted(): string {
    return this._age_year_formatted;
  }
  public set age_year_formatted(value: string) {
    this._age_year_formatted = value;
  }


  private _road_tax: number;
  get road_tax(): number {
    return this._road_tax;
  }
  set road_tax(value: number) {
    this._road_tax = value;
    this._update.push("road_tax");
  }

  private _tonnage: number;
  get tonnage(): number {
    return this._tonnage;
  }
  set tonnage(value: number) {
    this._tonnage = value;
    this._update.push("tonnage");
  }

  private _trailer_key: number;
  get trailer_key(): number {
    return this._trailer_key;
  }
  set trailer_key(value: number) {
    this._trailer_key = value;
  }

  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    this._update.push("type");
  }

  private _vin: string;
  get vin(): string {
    return this._vin;
  }
  set vin(value: string) {
    this._vin = value;
    this._update.push("vin");
  }

  private _width: number;
  get width(): number {
    return this._width;
  }
  set width(value: number) {
    this._width = value;
    this._update.push("width");
  }

  private _car_key_duplicit: boolean;
  get car_key_duplicit(): boolean {
    return this._car_key_duplicit;
  }
  set car_key_duplicit(value: boolean) {
    this._car_key_duplicit = value;
  }

  private _update: Array<string> = [];
  get update(): Array<string> {
    return this._update;
  }
  set update(value: Array<string>) {
    this._update = value;
  }
  

  /******************************************/
  /* Service tasks/plans */
  /******************************************/
  private _tasksPlannedPrice: number = 0;
  public get tasksPlannedPrice(): number {
    return this._tasksPlannedPrice;
  }
  public set tasksPlannedPrice(value: number) {
    this._tasksPlannedPrice = value;
  }
  
  private _tasksNotPlannedPrice: number = 0;
  public get tasksNotPlannedPrice(): number {
    return this._tasksNotPlannedPrice;
  }
  public set tasksNotPlannedPrice(value: number) {
    this._tasksNotPlannedPrice = value;
  }

  private _loadingTasks: boolean = false;
  public get loadingTasks(): boolean {
    return this._loadingTasks;
  }
  public set loadingTasks(value: boolean) {
    this._loadingTasks = value;
  }

  private _tasks: Array<ServiceTask> = [];
  public get tasks(): Array<ServiceTask> {
    return this._tasks;
  }
  public set tasks(value: Array<ServiceTask>) {
    this._tasks = value;
    if (this._tasks) {
      this._tasksPlannedPrice = 0;
      this._tasksNotPlannedPrice = 0;
      // TODO other than our currency with exchange rate might occur
      // this._tasks.forEach(
      //   t => {
      //     if (t.price) {
      //       // planned
      //       if (t.type == 'A' && !t.isNakupTask) {
      //         this._tasksPlannedPrice += t.price;
      //       }
      //       else if (!t.isNakupTask) {
      //         this._tasksNotPlannedPrice += t.price;
      //       }
      //     }
      //   }
      // );
    }
  }

  private _garancniTasks: Array<ServiceTask> = [];
  public get garancniTasks(): Array<ServiceTask> {
    return this._garancniTasks;
  }
  public set garancniTasks(value: Array<ServiceTask>) {
    this._garancniTasks = value;
    if (this._garancniTasks.length) {
      // using setter - following planned task (without date)
      this.garancniTask = this._garancniTasks.find(t => !t.completed_date);
      if (!this._garancniTask) {
        // last made task
        this.garancniTask = this._garancniTasks[this._garancniTasks.length - 1];
      }
    }
  }
  
  private _nakupTasks: Array<ServiceTask> = [];
  public get nakupTasks(): Array<ServiceTask> {
    return this._nakupTasks;
  }
  public set nakupTasks(value: Array<ServiceTask>) {
    this._nakupTasks = value;
    if (this._nakupTasks.length) {
      // using setter
      this.nakupTask = this._nakupTasks[0];
    }
  }
  
  private _pneu1Tasks: Array<ServiceTask> = [];
  public get pneu1Tasks(): Array<ServiceTask> {
    return this._pneu1Tasks;
  }
  public set pneu1Tasks(value: Array<ServiceTask>) {
    this._pneu1Tasks = value;
    if (this._pneu1Tasks.length) {
      // using setter - last made task
      this.pneu1Task = this._pneu1Tasks[this._pneu1Tasks.length - 1];
    }
  }

  private _pneu2Tasks: Array<ServiceTask> = [];
  public get pneu2Tasks(): Array<ServiceTask> {
    return this._pneu2Tasks;
  }
  public set pneu2Tasks(value: Array<ServiceTask>) {
    this._pneu2Tasks = value;
    if (this._pneu2Tasks.length) {
      // using setter - last made task
      this.pneu2Task = this._pneu2Tasks[this._pneu2Tasks.length - 1];
    }
  }
  
  private _pneu3Tasks: Array<ServiceTask> = [];
  public get pneu3Tasks(): Array<ServiceTask> {
    return this._pneu3Tasks;
  }
  public set pneu3Tasks(value: Array<ServiceTask>) {
    this._pneu3Tasks = value;
    if (this._pneu3Tasks.length) {
      // using setter - last made task
      this.pneu3Task = this._pneu3Tasks[this._pneu3Tasks.length - 1];
    }
  }
  
  private _terminovanaTasks: Array<ServiceTask> = [];
  public get terminovanaTasks(): Array<ServiceTask> {
    return this._terminovanaTasks;
  }
  public set terminovanaTasks(value: Array<ServiceTask>) {
    this._terminovanaTasks = value;
    if (this._terminovanaTasks.length) {
      // using setter - following planned task (without date)
      this.terminovanaTask = this._terminovanaTasks.find(t => !t.completed_date);
      if (!this._terminovanaTask) {
        // last made task
        this.terminovanaTask = this._terminovanaTasks[this._terminovanaTasks.length - 1];
      }
    }
  }

  private _technickaTasks: Array<ServiceTask> = [];
  public get technickaTasks(): Array<ServiceTask> {
    return this._technickaTasks;
  }
  public set technickaTasks(value: Array<ServiceTask>) {
    this._technickaTasks = value;
    if (this._technickaTasks.length) {
      // using setter - following planned task (without date)
      this.technickaTask = this._technickaTasks.find(t => !t.completed_date);
      if (!this._technickaTask) {
        // last made task
        this.technickaTask = this._technickaTasks[this._technickaTasks.length - 1];
      }
    }
  }

  private _zarukaTasks: Array<ServiceTask> = [];
  public get zarukaTasks(): Array<ServiceTask> {
    return this._zarukaTasks;
  }
  public set zarukaTasks(value: Array<ServiceTask>) {
    this._zarukaTasks = value;
    if (this._zarukaTasks.length) {
      // using setter
      this.zarukaTask = this._zarukaTasks[0];
    }
  }
  

  /****************************************************/
  // main tasks
  private _nakupTask: ServiceTask = null;
  public get nakupTask(): ServiceTask {
    return this._nakupTask;
  }
  public set nakupTask(value: ServiceTask) {
    this._nakupTask = value;
  }

  // main zaruka task
  private _zarukaTask: ServiceTask = null;
  public get zarukaTask(): ServiceTask {
    return this._zarukaTask;
  }
  public set zarukaTask(value: ServiceTask) {
    this._zarukaTask = value;
  }

  // main garancni task
  private _garancniTask: ServiceTask = null;
  public get garancniTask(): ServiceTask {
    return this._garancniTask;
  }
  public set garancniTask(value: ServiceTask) {
    this._garancniTask = value;
    if (this._garancniTask && this._garancniTask.dueRemainingDays) {
      this._garancniDays = this._garancniTask.dueRemainingDays;
    }
    else if (this._garancniTask && this._garancniTask.completedBeforeDays) {
      this._garancniDays = this._garancniTask.completedBeforeDays;
    }
  }

  private _garancniDays: number = null;
  public get garancniDays(): number {
    return this._garancniDays;
  }
  
  // main terminovana task (planned)
  private _terminovanaTask: ServiceTask = null;
  public get terminovanaTask(): ServiceTask {
    return this._terminovanaTask;
  }
  public set terminovanaTask(value: ServiceTask) {
    this._terminovanaTask = value;
    if (this._terminovanaTask && this._terminovanaTask.dueRemainingDays) {
      this._terminovanaDays = this._terminovanaTask.dueRemainingDays;
    }
    else if (this._terminovanaTask && this._terminovanaTask.completedBeforeDays) {
      this._terminovanaDays = this._terminovanaTask.completedBeforeDays;
    }
  }

  private _terminovanaDays: number = null;
  public get terminovanaDays(): number {
    return this._terminovanaDays;
  }

  // main technicka task (planned)
  private _technickaTask: ServiceTask = null;
  public get technickaTask(): ServiceTask {
    return this._technickaTask;
  }
  public set technickaTask(value: ServiceTask) {
    this._technickaTask = value;
    if (this._technickaTask && this._technickaTask.dueRemainingDays) {
      this._technickaDays = this._technickaTask.dueRemainingDays;
    }
    else if (this._technickaTask && this._technickaTask.completedBeforeDays) {
      this._technickaDays = this._technickaTask.completedBeforeDays;
    }
  }

  private _technickaDays: number = null;
  public get technickaDays(): number {
    return this._technickaDays;
  }
  
  // last pneu1 task (last made)
  private _pneu1Task: ServiceTask = null;
  public get pneu1Task(): ServiceTask {
    return this._pneu1Task;
  }
  public set pneu1Task(value: ServiceTask) {
    this._pneu1Task = value;
    if (this._pneu1Task && this._pneu1Task.completedBeforeKm) {
      this._pneu1Km = this._pneu1Task.completedBeforeKm;
    }
  }

  private _pneu1Km: number = null;
  public get pneu1Km(): number {
    return this._pneu1Km;
  }
  
  // last pneu2 task (last made)
  private _pneu2Task: ServiceTask = null;
  public get pneu2Task(): ServiceTask {
    return this._pneu2Task;
  }
  public set pneu2Task(value: ServiceTask) {
    this._pneu2Task = value;
    if (this._pneu2Task && this._pneu2Task.completedBeforeKm) {
      this._pneu2Km = this._pneu2Task.completedBeforeKm;
    }
  }

  private _pneu2Km: number = null;
  public get pneu2Km(): number {
    return this._pneu2Km;
  }
  
  // last pneu2 task (last made)
  private _pneu3Task: ServiceTask = null;
  public get pneu3Task(): ServiceTask {
    return this._pneu3Task;
  }
  public set pneu3Task(value: ServiceTask) {
    this._pneu3Task = value;
    if (this._pneu3Task && this._pneu3Task.completedBeforeKm) {
      this._pneu3Km = this._pneu3Task.completedBeforeKm;
    }
  }

  private _pneu3Km: number = null;
  public get pneu3Km(): number {
    return this._pneu3Km;
  }
}