export class MessageAttachmentObject {

  public static WAY_TO_TM = 'to';
  public static WAY_FROM_TM = 'from';
  private static KEYWORD_FILENAME = '%FILE_NAME%';
  private static KEYWORD_CARKEY = '%CAR_KEY%';
  private static KEYWORD_WAY = '%WAY%';
  private static DEFAULT_WAY = MessageAttachmentObject.WAY_FROM_TM;

  constructor(
    private _urlString: string,
    private _fileName: string,
    private _carKey: number,
    private _attachmentType: string,
    private _way?: string,
    private _directUrl?: string
  ) {
    if (_way === undefined) {
      this._way = MessageAttachmentObject.DEFAULT_WAY;
    }
    if (_directUrl.includes('invoice-')) {
      this._isInvoiceAttachment = true;
    }
  }

  get urlString(): string {
    return this._urlString;
  }

  get directUrl(): string {
    return this._directUrl;
  }

  get fileName(): string {
    return this._fileName;
  }

  get carKey(): number {
    return this._carKey;
  }

  private _isInvoiceAttachment: boolean = false;
  public get isInvoiceAttachment(): boolean {
    return this._isInvoiceAttachment;
  }
  public set isInvoiceAttachment(value: boolean) {
    this._isInvoiceAttachment = value;
  }

  get attachmentType(): string {
    return this._attachmentType;
  }
  set attachmentType(value: string) {
    this._attachmentType = value;
  }

  private _thumbnail: any;
  public get thumbnail(): any {
    return this._thumbnail;
  }
  public set thumbnail(value: any) {
    this._thumbnail = value;
  }

  // custom attribute for window timeout loading
  private _timeoutThumbnail: number = 0;
  public get timeoutThumbnail(): number {
    return this._timeoutThumbnail;
  }
  public set timeoutThumbnail(value: number) {
    this._timeoutThumbnail = value;
  }
  

  static doReplaceUrlPlaceholders(url: string, fileName: string, carKey: string, way: string) {
    return url.replace(MessageAttachmentObject.KEYWORD_FILENAME, fileName)
      .replace(MessageAttachmentObject.KEYWORD_CARKEY, carKey)
      .replace(MessageAttachmentObject.KEYWORD_WAY, way);
  }

  getFormattedUrlString(): string {
    return MessageAttachmentObject.doReplaceUrlPlaceholders(
      this._urlString,
      encodeURI(this._fileName),
      this._carKey.toString(),
      MessageAttachmentObject.WAY_FROM_TM
    )
  }

  isImage(): boolean {
    return this.isTypeOf(/^image\//);
  }

  isDocument(): boolean {
    return this.isTypeOf(/^application\//);
  }

  isAudio(): boolean {
    return this.isTypeOf(/^audio\/(mpeg|ogg|wav|x-wav)/);
  }

  isVideo(): boolean {
    return this.isTypeOf(/^video\/(mp4|webm|ogg)/);
  }

  isUnsupported(): boolean {
    return !this.isAudio() && !this.isVideo() && !this.isDocument() && !this.isImage();
  }

  private isTypeOf(regex) {
    return this.attachmentType && this.attachmentType.match(regex) !== null;
  }
}