import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { IS_DEMO, ServiceConfiguration } from "../config";
import { Vehicle } from "../model/vehicle.object";
import { HomeStandKey } from "../model/home-stand-key.object";
import { VehicleTools } from "../tools/VehicleTools";
import { VehicleHealthObject } from "../model/vehicle-health.object";

@Injectable({
  providedIn: 'root',
})
export class VehicleLeftService {

  private _vehicles: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject([]);
  private _vehiclesCache: Array<Vehicle> = [];
  private _allVehicles: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject([]);
  private _allVehiclesCache: Array<Vehicle> = [];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  private _loadingVehicles: boolean = false;
  get loadingVehicles(): boolean {
    return this._loadingVehicles;
  }

  private _loadingAllVehicles: boolean = false;
  get loadingAllVehicles(): boolean {
    return this._loadingAllVehicles;
  }

  public clearVehiclesCache(): void {
    this._vehiclesCache = [];
    this._vehicles.next([]);
  }

  public clearAllVehiclesCache(): void {
    this._allVehiclesCache = [];
    this._allVehicles.next([]);
  }

  getVehicles(): Observable<Array<Vehicle>> {
    if (!this._vehiclesCache.length && !this._loadingVehicles) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadVehicles();
      }
    }
    return this._vehicles.asObservable();
  }

  getVehiclesLight(): Observable<Array<Vehicle>> {
    if (!this._vehiclesCache.length && !this._loadingVehicles) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadVehiclesLight();
      }
    }
    return this._vehicles.asObservable();
  }

  getAllVehicles(): Observable<Array<Vehicle>> {
    if (!this._allVehiclesCache.length && !this._loadingAllVehicles) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadAllVehicles();
      }
    }
    return this._allVehicles.asObservable();
  }

  getAllVehiclesLight(): Observable<Array<Vehicle>> {
    if (!this._allVehiclesCache.length && !this._loadingAllVehicles) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadAllVehiclesLight();
      }
    }
    return this._allVehicles.asObservable();
  }

  loadVehicles() {
    this._loadingVehicles = true;
    this._http.get(ServiceConfiguration.vehicle.api).subscribe(
      response => {
        if (response) {
          this._vehiclesCache = VehicleTools.buildVehiclesFromData(response);
          // push vehicles
          this._vehicles.next(this._vehiclesCache);
          this._loadingVehicles = false;
        }
      },
      error => {
        //handle error
        console.log(error);
        this._loadingVehicles = false;
      }
    );
  }

  loadVehiclesLight() {
    this._loadingVehicles = true;
    this._http.get(ServiceConfiguration.vehicle.apiLight).subscribe(
      response => {
        if (response) {
          this._vehiclesCache = VehicleTools.buildVehiclesFromData(response);
          // push vehicles
          this._vehicles.next(this._vehiclesCache);
          this._loadingVehicles = false;
        }
      },
      error => {
        //handle error
        console.log(error);
        this._loadingVehicles = false;
      }
    );
  }


  loadAllVehicles() {
    this._loadingAllVehicles = true;
    this._http.get(ServiceConfiguration.vehicle.apiInactive).subscribe(
      response => {
        if (response) {
          this._allVehiclesCache = VehicleTools.buildVehiclesFromData(response);
          // push vehicles
          this._allVehicles.next(this._allVehiclesCache);
          this._loadingAllVehicles = false;
        }
      },
      error => {
        //handle error
        console.log(error);
        this._loadingAllVehicles = false;
      }
    );
  }

  loadAllVehiclesLight() {
    this._loadingAllVehicles = true;
    this._http.get(ServiceConfiguration.vehicle.apiInactiveLight).subscribe(
      response => {
        if (response) {
          this._allVehiclesCache = VehicleTools.buildVehiclesFromData(response);
          // push vehicles
          this._allVehicles.next(this._allVehiclesCache);
          this._loadingAllVehicles = false;
        }
      },
      error => {
        //handle error
        console.log(error);
        this._loadingAllVehicles = false;
      }
    );
  }

  // GET /cars/<car_key>
  getCar(car_key: number): Observable<Vehicle> {
    let result: BehaviorSubject<Vehicle> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.vehicle.apiCarKey;
      url = url.replace('%CAR_KEY%', car_key.toString());

      this._http.get(url).subscribe(
        response => {
          // response is array and car is first item (to fix?)
          if (response && response.length) {
            let car = VehicleTools.buildVehicle(response[0]);
            result.next(car);
          }
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // GET /cars/<car_key>/?mode=light
  getCarLight(car_key: number): Observable<Vehicle> {
    let result: BehaviorSubject<Vehicle> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.vehicle.apiCarKeyLight;
      url = url.replace('%CAR_KEY%', car_key.toString());

      this._http.get(url).subscribe(
        response => {
          let car = VehicleTools.buildVehicle(response);
          result.next(car);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  
  // method for updating vehicle properties
  public updateVehicle(vehicle: Vehicle, update: Array<string>): Observable<any> {
    let updVehicle: BehaviorSubject<any> = new BehaviorSubject(null);

    if (!IS_DEMO && this._authService.isAuthenticated()) {
      // prepare object for updating 
      let data = {};
      update.forEach(
        key => {
          if (key !== "trailer_key") {
            if (key == "model_id") {
              data["model"] = vehicle.model_id;
            } else {
              data[key] = vehicle[key];
            }
          }
        }
      );

      if (Object.keys(data).length) {
        this._http.put(ServiceConfiguration.vehicle.api + '/' + vehicle.car_key, data).subscribe(
          response => {
            // remove all updated values
            vehicle.update = vehicle.update.filter(val => !update.includes(val));
            updVehicle.next(response);

            // success alert
            let alert: string = $localize`Vozidlo %SPZ bylo úspěšně upraveno.`;
            alert = alert.replace('%SPZ', vehicle.number_plate);
            this._notificationService.alert(alert, 'success', 3000);
          },
          error => {
            console.log(error);
            // error alert
            let alert: string = $localize`Chyba úpravy vozidla %SPZ`;
            alert = alert.replace('%SPZ', vehicle.number_plate);
            this._notificationService.alert(alert, 'error', 3000);
          }
        );
      }
    }

    return updVehicle.asObservable();
  }

  // method for creating new vehicle
  createVehicle(vehicle: Vehicle): Observable<any> {
    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = {
        number_plate: vehicle.number_plate,
        home_stand_key: vehicle.home_stand_key,
        color: vehicle.color,
        model: vehicle.model_id,
        production_year: vehicle.production_year,
        type: vehicle.type,
        tonnage: vehicle.tonnage,
        inactive: false,
        // some defaults
        length: 0,
        car_length: 0,
        width: 0,
        car_width: 0,
        height: 0,
        car_height: 0,
        capacity: 0,
        eco_speed: 85,
        eco_braking_coef: 0.3,
        eco_acceleration_coef: 0.3
      };

      // set loading flag
      this._loadingAllVehicles = true;

      this._http.post(ServiceConfiguration.vehicle.api + '/', data).subscribe(
        success => {
          if (success && success.car_key) {
            let vehicle = VehicleTools.buildVehicle(success);
            this._allVehiclesCache.push(vehicle);
            this._allVehicles.next(this._allVehiclesCache);
            this._loadingAllVehicles = false;

            // success alert
            let alert: string = $localize`Vozidlo %SPZ bylo úspěšně vytvořeno.`;
            alert = alert.replace('%SPZ', vehicle.number_plate);
            this._notificationService.alert(alert, 'success', 3000);
          } 
        },
        error => {
          this._loadingAllVehicles = false;
          console.log(error);
          // error alert
          let alert: string = $localize`Chyba při vytváření vozidla %SPZ.`;
          alert = alert.replace('%SPZ', vehicle.number_plate);
          this._notificationService.alert(alert, 'error', 3000);
        }
      );
    }

    return this._allVehicles.asObservable();
  }


  /*****************************************/
  /* Home stand part */
  /*****************************************/
  // GET /cars/<CAR_KEY>/alt-home>/
  getHomeStands(car_key: number): Observable<Array<HomeStandKey>> {
    let result: BehaviorSubject<Array<HomeStandKey>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.apiHomeStand;
      url = url.replace('<CAR_KEY>', car_key.toString());

      this._http.get(url).subscribe(
        response => {
          let tasks = this.buildHomeStandsFromData(response);
          result.next(tasks);
        },
        error => {
          //handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // POST /cars/<CAR_KEY>/alt-home/<CITY_KEY>
  createHomeStand(car_key: number, home_stand: HomeStandKey): Observable<HomeStandKey> {
    let result: BehaviorSubject<HomeStandKey> = new BehaviorSubject(null);
    if (this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.vehicle.apiHomeStand;
      url = url.replace('<CAR_KEY>', car_key.toString());

      this._http.post(url, home_stand.apiObject).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Domovské parkoviště bylo úspěšně přidáno.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          result.next(this.buildHomeStand(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba připřidání domovského parkoviště.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // PUT /cars/<CAR_KEY>/alt-home/<ID>
  updateHomeStand(car_key: number, home_stand: HomeStandKey): Observable<HomeStandKey> {
    let result: BehaviorSubject<HomeStandKey> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.apiHomeStand + home_stand.home_stand_key;
      url = url.replace('<CAR_KEY>', car_key.toString());

      this._http.put(url, home_stand.apiObject).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Domovské parkoviště bylo úspěšně upraveno.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          result.next(this.buildHomeStand(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě domovského parkoviště - kontaktujte podporu.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }
  
  // DELETE /cars/<CAR_KEY>/alt-home/<ID>
  deleteHomeStand(car_key: number, home_stand: HomeStandKey): Observable<boolean> {
    let result: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.apiHomeStand + home_stand.home_stand_key;
      url = url.replace('<CAR_KEY>', car_key.toString());

      this._http.delete(url).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Domovské parkoviště bylo úspěšně odstraněno.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          result.next(true);
        },
        error => {
          //handle error
          console.error(error);
          // alert
          let alertError: string = $localize`Chyba při odstranění domovského parkoviště - kontaktujte podporu.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }

  
  // creating home stand object array
  private buildHomeStandsFromData(data: Array<any>): any {
    let homes: Array<HomeStandKey> = [];
    data.forEach(
      o => {
        let home: HomeStandKey = this.buildHomeStand(o);
        homes.push(home);
      }
    );
    return homes;
  }

  // method for creating a home stand object
  private buildHomeStand(o: any): HomeStandKey {
    let home: HomeStandKey = new HomeStandKey();
    for (let key in o) {
      home[key] = o[key];
    }
    return home;
  }

  
  /******************************************************/
  /* Vehicle health */
  /******************************************************/
  public getVehicleHealth(vehicle: Vehicle): Observable<VehicleHealthObject> {
    let result: BehaviorSubject<VehicleHealthObject> = new BehaviorSubject(null);
    let url: string = ServiceConfiguration.vehicle.apiHealth;
    url = url.replace(/%CAR_KEY%/, vehicle.car_key.toString());

    this._http.get(url).subscribe(
      response => {
        // console.log(response);
        result.next(this.buildVehicleHealth(response));
      },
      error => {
        console.log(error);
      }
    );

    return result.asObservable();
  }

  private buildVehicleHealth(o: any): VehicleHealthObject {
    if (o) {
      let health: VehicleHealthObject = new VehicleHealthObject();
      for (let key in o) {
        health[key] = o[key];
      }
      return health;
    }
    return null;
  }
}
