export class ReachEvent {
  private _reach_key: number;
  get reach_key(): number {
    return this._reach_key;
  }
  set reach_key(value: number) {
    this._reach_key = value;
  }

  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }

  private _time: Date;
  get time(): Date {
    return this._time;
  }
  set time(value: Date) {
    this._time = value;
  }

  private _valid_until: Date;
  get valid_until(): Date {
    return this._valid_until;
  }
  set valid_until(value: Date) {
    this._valid_until = value;
  }

  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }

  private _pos_gps: string;
  get pos_gps(): string {
    return this._pos_gps;
  }
  set pos_gps(value: string) {
    this._pos_gps = value;
  }

  private _radius: number;
  get radius(): number {
    return this._radius;
  }
  set radius(value: number) {
    this._radius = value;
  }

  private _car_is_inside: boolean;
  get car_is_inside(): boolean {
    return this._car_is_inside;
  }
  set car_is_inside(value: boolean) {
    this._car_is_inside = value;
  }

  private _description: string;
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }

  private _country: string;
  get country(): string {
    return this._country;
  }
  set country(value: string) {
    this._country = value;
  }

  private _time_last_check: Date;
  get time_last_check(): Date {
    return this._time_last_check;
  }
  set time_last_check(value: Date) {
    this._time_last_check = value;
  }
}
