<!-- default resource -->
<iframe *ngIf="!isAuthenticated" name="unAuthenticated" [src]="contentResource | safeResource"></iframe>

<div *ngIf="isAuthenticated" class="col-12 map-component-header">
  <div class="map-component-header-text text-truncate">
    <!-- map icon -->
    <img height="50px" class="mr-3 align-top" 
    src="assets/icons-new/all-in-one-map.svg" alt="map"/>

    <!-- time / connection error -->
    <span *ngIf="!notConnected">{{lastVehicleLoad | date: 'HH:mm'}}</span>
    <b *ngIf="notConnected" style="cursor:default; font-size:36px; line-height:0px; color:red" 
    ngbTooltip="Chyba spojení" container="body" placement="bottom auto" i18n-ngbTooltip>
      {{'{!}'}}
    </b>
    <span class="ml-2 mr-3" i18n>Mapa</span>

    <!-- truck icon -->
    <div class="position-relative d-inline-block ml-3"
    ngbTooltip="Vybrat vozidla k zobrazení" placement="bottom auto" container="body"
    data-toggle="modal" data-target="#vehicleFilterModal" i18n-ngbTooltip>
      <img height="50px" class="align-top pointer" 
      src="assets/icons-new/small_truck.svg" alt="truck"/>
      <span class="badge badge-light pointer" 
      style="font-size:11px; position:absolute; bottom:5px; right:0px;">
        {{vehicles.length}}
      </span>
    </div>
    <span class="ml-2" style="font-size: 20px;" i18n>Vybrat vozidla</span>

    <!-- Show spinner -->
    <div *ngIf="loadingVehicles" class="d-inline-block spinner-wrapper ml-3">
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAuthenticated && !validAccess">
  <div class="container text-center py-4">
    <h4>
      <ng-container i18n>Váš přístup vypršel. Máte-li zájem o další využívání, předplaťte si přístup</ng-container>
      <a href="javascript:void(0)" (click)="openSubsNewTab()" i18n>zde</a>.
    </h4>
  </div>
</div>

<div *ngIf="isAuthenticated && tariffOnlyAgenda">
  <div class="container text-center py-4">
    <h4 i18n>Váš tarif neumožňuje zobrazení vozidel</h4>
  </div>
</div>

<ng-container *ngIf="isAuthenticated && validAccess && !tariffOnlyAgenda && !refreshLimitReached">
  <div *ngIf="!loadingVehicles && !vehicles.length">
    <div class="container text-center py-4">
      <h5 i18n>Nebyla zvolena žádná vozidla k zobrazení. Vozidla k zobrazení je možné zvolit v horní liště.</h5>
    </div>
  </div>

  <div *ngIf="vehicles.length > 0" class="googleMapCarsAll fill" 
    [properties]="{scrollwheel: true}"
    [vehicles]="vehicles" 
    [rebuildMap]="rebuildMapSubject">
  </div>
</ng-container>

<ng-container *ngIf="refreshLimitReached">
  <div class="w-100 text-center pt-3">
    <h6 i18n>Pozor, počet načtení/obnovení stránky "Mapa - všechna auta" je omezen limitem 25/denně.</h6>
    <h6 i18n>Stránku není nutné obnovovat, poloha všech vozidel je v reálném čase neustále aktualizována.</h6>
  
    <h6 class="mt-3 text-danger" i18n>"Mapa - všechna auta" byla dnes načtena {{refreshCount}}krát.</h6>
    <h6 class="text-danger" i18n>Denní limit zobrazení "Mapa - všechna auta" vyčerpán.</h6>
    <h6 class="text-danger" i18n>V případě dotazů volejte prosím podporu +420,737 288 090.</h6>
  </div>
</ng-container>

<!-- SPINNER must be down here -->
<!-- <ng-container *ngIf="isAuthenticated && loadingVehicles" class="col-12 text-center">
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-success" style="width:5rem; height:5rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</ng-container> -->


<!-- info absolute div -->
<div *ngIf="showIntro" class="map-intro border border-primary rounded shadow-lg p-2">
  <h5 class="m-0" i18n>Klikněte na ikonku auta pro zobrazení trasy.</h5>
</div>


<!-- Modal for marking vehicles to be shown -->
<div class="modal fade" role="dialog" tabindex="-1" id="vehicleFilterModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title">
          <ng-container i18n>Výběr vozidel dle SPZ či dispečera</ng-container>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <app-vehicle-filter></app-vehicle-filter>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal for user reloading warning/information -->
<div class="modal fade" role="dialog" tabindex="-1" id="reloadingWarningModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title">
          <ng-container i18n>Upozornění - obnovení stránky</ng-container>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h6 i18n>Pozor, počet načtení/obnovení stránky "Mapa - všechna auta" je omezen limitem 20/denně.</h6>
        <h6 i18n>Stránku není nutné obnovovat, poloha všech vozidel je v reálném čase neustále aktualizována.</h6>

        <h6 class="mt-3" i18n>"Mapa - všechna auta" byla dnes načtena {{refreshCount}}krát.</h6>
        <ng-container *ngIf="refreshLimitReached" class="text-danger">
          <h6 i18n>Denní limit zobrazení "Mapa - všechna auta" vyčerpán.</h6>
          <h6 i18n>V případě dotazů volejte prosím podporu +420,737 288 090.</h6>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>
