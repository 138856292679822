import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { SimCard } from '../model/simcard.object';
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root'
})
export class ImsiService {

  private _loading: boolean = false;

  constructor(
    private _http: HttpClientService,
    private _authServ: AuthenticationService,
    private _notificationServ: NotificationService
  ) {
  }

  
  // POST /imsi/
  createSimcard(sim: SimCard): Observable<SimCard> {
    let created: BehaviorSubject<SimCard> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.imsi.api;

      this._http.post(url, sim.apiObject).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`SIM karta byla úspěšně přidána.`;
          this._notificationServ.alert(alertSuccess, 'success', 4000);
          
          // observable next
          created.next(this.buildSimCard(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření SIM karty - kontaktujte podporu.`;
          this._notificationServ.alert(alertError, 'error', 4000);
        }
      );
    }

    return created.asObservable();
  }

  // DELETE /sim/<IMSI>
  deleteSimcard(sim: SimCard): Observable<boolean> {
    let res: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.imsi.apiImsiKey;
      url = url.replace('%IMSI%', sim.imsi);

      this._http.delete(url).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`SIM karta byla úspěšně odstraněna.`;
          this._notificationServ.alert(alertSuccess, 'success', 4000);
          res.next(true);
        },
        error => {
          // handle error
          console.error(error);
          // alert
          let alertError: string = `Chyba při odstranění SIM karty - kontaktujte podporu.`;
          this._notificationServ.alert(alertError, 'error', 4000);
        }
      );
    }
    return res.asObservable();
  }

  // method for creating a single task object
  private buildSimCard(o: any): SimCard {
    let task: SimCard = new SimCard();
    for (let key in o) {
      task[key] = o[key];
    }
    return task;
  }
}