<!-- <div *ngIf="composition.length && !loading" class="clearfix" >-->
<div *ngIf="composition.length" class="clearfix" >
  <h4 class="text-center" [ngSwitch]="isConnDown">
    <span *ngSwitchCase="false">{{formattedLastLoadDate}}</span>
    <span *ngSwitchCase="true">
      <img src="assets/icons-new/exclamation-solid.svg" alt="exclamation-solid" height="12px"/>
      <ng-container i18n>Chyba spojení</ng-container>
    </span>
    <a style="font-size:15px" class="pointer" (click)="toggleFullScreen()">
      Fullscreen
      <span style="font-size:10px">(F11)</span> 
      <img src="assets/icons-new/arrow_cross.svg" alt="arrow_cross" height="12px"/>
    </a>
  </h4>

  <div class="clearfix" *ngFor="let row of composition">
    <div *ngFor="let vehicle of row" class="text-center container-fluid float-left"
    [style.border]="'2px solid'" [style.width]="compositionWidth" [style.padding]="'0px'">
      <ng-template #tankVolume>
        <div class="pointer progress {{vehicle.fuelPercentage < 10 ? 'bg-warning' : ''}}" 
        placement="bottom" [ngbTooltip]="tankVolumeTooltip">
          <!-- bootstrap tooltip template -->
          <ng-template #tankVolumeTooltip>
            <div>
              <ng-container *ngIf="vehicle.lastRefueledBefore(21)">
                <ng-container i18n>Tankování do plné nádrže proběhlo již před vice jak 14 dny. Stav nádrže není zobrazen - mohl by být výrazně zkreslen.</ng-container>
              </ng-container>
              <ng-container *ngIf="!vehicle.lastRefueledBefore(14)">
                <ng-container *ngIf="vehicle.consumption_avg">
                  <ng-container i18n>Stav nádrže</ng-container> {{vehicle.fuelPercentage+'%'}}<br/>
                    <ng-container i18n>Dojezd</ng-container> {{vehicle.driveRange}}<br/>
                    <ng-container i18n>Do plné</ng-container> {{vehicle.lastRefuelingInfo}}<br/>
                    <ng-container i18n>Průměrná spotřeba</ng-container> {{vehicle.consumptionInfo}}<br/>
                </ng-container>
                <ng-container *ngIf="!vehicle.consumption_avg">
                  <ng-container i18n>Posledni tankovaní do plné</ng-container> {{vehicle.lastRefuelingInfo}}<br/>
                  <ng-container i18n>Zaznamenali jsme nesrovnalosti v evidenci tankování. Prověřte řidičem zapsané údaje.</ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>
          <ng-container *ngIf="vehicle.tank_volume > 0">
            <div [style.width]="vehicle.fuelPercentage + '%'"
              class="progress-bar progress-bar-animated bg-{{vehicle.tank_volume_color}} progress-bar-striped active"
              role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="24"></div>
          </ng-container>
        </div>
      </ng-template>

      <div class="row no-gutters icons-{{font_size_number}} fonts-{{font_size_number}}" [style.fontSize]="font_size">
        <div class="{{last_cargo_graphic_show || last_cargo_text_show ? (last_cargo_graphic_show && last_cargo_text_show ? 'col-8' : 'col-8') : 'col-10'}}">
          <div class="row no-gutters">
            <div class="col-4 openToolTip">
              <div class="pull-0 pull-sm-12">
                <ng-template [ngTemplateOutlet]="tankVolume"></ng-template>
                <div class="progress">
                  <div [style.width]="vehicle.tonnage_percetange+'%'" 
                  class="progress-bar bg-success progress-bar-striped active" 
                  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="24" ></div>
                  </div>
                <div placement="bottom" [ngbTooltip]="last_position_tooltip">
                <div class="vehicle-status">
                  <div style="width:30px;height: 30px; margin: auto;">
                    <img class="vehStatusIcon" [src]="vehicle.truck_status_name"/>
                  </div>
                  <img class="roadExceptionsIcon" *ngIf="vehicle.roadExceptions()" 
                  src="assets/icon/road-exceptions.png"/>
                </div>
                <!-- bootstrap tooltip template -->
                <ng-template #last_position_tooltip>
                  <div>
                  <div *ngIf="vehicle.roadExceptions()" class="text-danger">
                    Road exceptions info comes here!
                  </div>
                  <div *ngIf="vehicle.last_position">
                    {{vehicle.last_position.order_number}}&nbsp;&nbsp;
                    {{vehicle.last_position.cargo_weight}}t&nbsp;&nbsp;
                    {{vehicle.last_position.formatted_time}}<br/>
                    {{vehicle.order_customer}}<br/>
                    <div *ngFor="let itin of vehicle.last_position.itin">
                      <img class="icon" [src]="icons[itin.type_marker].url"/> 
                      <span>{{itin.address}}</span>
                    </div>
                  </div>
                  </div>
                </ng-template>
                </div>
                <div class="vehicle-health">
                <i placement="bottom" [ngbTooltip]="health_tooltip"></i>
                <!-- bootstrap tooltip template -->
                <ng-template #health_tooltip>
                  <div>
                    {{vehicle.health_icon_title}}
                    <div *ngIf="vehicle.health">
                      {{vehicle.health.formattedDate}}
                    </div>
                  </div>
                </ng-template>
                </div>
                <div *ngIf="vehicle.last_position" style="padding-left: 4px;" 
                [style.color]="vehicle.last_position.dateDiffColor()">
                  {{vehicle.last_position.getDateDiffString}}
                </div>
                <div *ngIf="!vehicle.last_position">
                  <img src="assets/icons-new/question.svg" alt="question" height="50px"/>
                </div>
              </div>
            </div>
            <div class="col-8 text-left">
              <div style="line-height: 16px; margin-top: 2px;">
                <div class="clearfix">
                  <span [class]="('vehicle_list_highlight'|configuration:'vehicle') === 'plates' ? 'bold' : ''">
                    {{vehicle.number_plate}}
                    <span *ngIf="vehicle.number_plate_trailer && trailer_show">
                      + {{vehicle.number_plate_trailer}}
                    </span>
                  </span>
                </div>
                <div class="clearfix">
                  <span [class]="('vehicle_list_highlight'|configuration:'vehicle') === 'drivers' ? 'bold' : ''">
                    {{vehicle.driver_name}}
                    <span *ngIf="vehicle.driver2_key && co_driver_show">
                      + {{vehicle.driver2_name}}
                    </span>
                  </span>
                </div>
                <!-- TOREPAIR zrejme dat spravny styl teto oriznute poznamce -->
                <!-- <div *ngIf="note_show">{{vehicle.description | slice:0:truncate_note}}</div> -->
                <div *ngIf="vehicle.last_position">
                <strong placement="bottom" [ngbTooltip]="vehicle.last_position.formatted_time">
                  {{vehicle.last_position.city_country}} {{vehicle.last_position.city_zip_short}} 
                </strong>
                <!-- TOREPAIR zrejme dat spravny styl teto oriznute poznamce -->
                <!-- {{vehicle.last_position.city_name | slice:0:truncate_city}} -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="(last_cargo_graphic_show || last_cargo_text_show) && vehicle.last_cargo_activity" 
        class="col-{{last_cargo_graphic_show && last_cargo_text_show ? 4 : 2}}">
          <div class="row text-left no-gutters">
            <div class="col-12 no-padding">
              <strong>
                {{vehicle.last_cargo_activity.city_country_short}}&nbsp;
                {{vehicle.last_cargo_activity.city_zip_short}}
              </strong>
              <span *ngIf="vehicle.order_customer">
                ({{ vehicle.order_customer | slice:0:7 }}
                {{vehicle.order_customer.length>7?'...':''}})
              </span>
            </div>
            <div class="col-12 no-padding">
            <div style="height: 30px;width: 30px;margin: auto" class="d-inline-block" *ngIf="last_cargo_graphic_show">
              <img src="assets/icon/icon-car-state/{{vehicle.cargo_activity_type}}.svg" 
              class="cargoStatusIcon align-middle" />
            </div>
            <div class="d-inline-block align-middle" *ngIf="last_cargo_text_show">
              <div>
              {{ vehicle.last_cargo_activity.time.getDate() < 10 ? '0': '' }}
              {{vehicle.last_cargo_activity.time.getDate()}}.
              {{ vehicle.last_cargo_activity.time.getMonth()+1 < 10 ? '0' : '' }}
              {{vehicle.last_cargo_activity.time.getMonth()+1}}
      
              {{vehicle.last_cargo_activity.time.getHours()<10?'0':''}}
              {{ vehicle.last_cargo_activity.time.getHours() }}:
              {{ vehicle.last_cargo_activity.time.getMinutes()<10?'0':'' }}
              {{ vehicle.last_cargo_activity.time.getMinutes() }}
              </div>
              <div>
                {{ vehicle.last_cargo_activity.event_took_parse_string }}
                <span [style.color]="vehicle.last_cargo_activity.delayed_arrival_diff > (1000*60*10) ? '#FF0000' : ''">
                  ({{ vehicle.last_cargo_activity.delayed_arrival }})
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left row no-gutters">
          <vehicle-interruption-component [vehicle]="vehicle" class="col-12"></vehicle-interruption-component>
        </div>
        </div>
        <div *ngIf="aetr_show" class="col-12 col-sm-12 col-md-12 ">
          <div class="aetr aetr-collection container-fluid" [vehicle]="vehicle">
          </div>
        </div>
        <div *ngIf="vehicle.temporaryManualTracking.length"  class="col-12 col-sm-12 col-md-12">
          <vehicle-manual-tracking-result [vehicle]="vehicle"></vehicle-manual-tracking-result>
        </div>
        <div *ngIf="gantt_show" class="col-12 col-sm-12 col-md-12 ta-head">
          <gantt-drive-diagram [currentDate]="lastLoadDate" [vehicle]="vehicle"></gantt-drive-diagram>
        </div>
        <div *ngIf="show_map" 
        id="map_canvas_grid_{{vehicle.car_key}}"
        class="google-map-container googleMapCar col-sm-12"
        [style.height]="mapHeight"
        [vehicle]="vehicle">
        </div>
          <!-- [changeSubscribe]="changeDetect" -->
        </div>
      </div>
    </div>
  </div>
  
<!-- TODO loading is missing here ... style="font-size: 50px; -->
<!-- <div *ngIf="loading" class="spinner-border" role="status">
<span class="sr-only"></span>
</div> -->