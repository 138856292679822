<div class="d-flex" style="padding-top: 1px; padding-bottom: 1px;">
  <div style="width: 87%;">
    <div class="progress full gantt-part position-relative" style="border: 1px solid grey;margin: 0px;">
      <div class="left-positioned-text">
        <img width="14px" src="assets/images/DistanceToTarget.svg" 
        ngbTooltip="Vzdálenost do cíle" placement="top auto" container="body" i18n-ngbTooltip/>
        <b class="mx-1" style="font-size: 14px; line-height: 13px;">{{distanceRemaining()}}</b>

        <img width="14px" src="assets/images/TimeToTarget.svg"
        ngbTooltip="Čas do cíle" placement="top auto" container="body" i18n-ngbTooltip/>
        <b class="mx-1" style="font-size: 14px; line-height: 13px;">{{drivingTimeRemainingFormatted}}</b>

        <img width="14px" src="assets/images/TimeInTarget.svg"
        ngbTooltip="Čas v cíli" placement="top auto" container="body" i18n-ngbTooltip/>
        <b class="mx-1" style="font-size: 14px; line-height: 13px;">~{{etaFormatted}}</b>

        <img width="14px" src="assets/images/Target.svg"
        ngbTooltip="Zvolený cíl" placement="top" container="body" i18n-ngbTooltip/>
        <ng-container *ngIf="vehicle.temporaryManualTrackingTarget && vehicle.temporaryManualTrackingTarget.address">
          <ng-container *ngIf="vehicle.temporaryManualTrackingTarget.address.length">
            <b style="font-size: 14px; line-height: 13px;" container="body" placement="left auto" 
            [ngbTooltip]="vehicle.temporaryManualTrackingTarget.address">
              {{vehicle.temporaryManualTrackingTarget.address}}
            </b>
          </ng-container>
          <!-- <ng-container *ngIf="vehicle.temporaryManualTrackingTarget.address.length < 16">
            <b style="font-size: 14px; line-height: 13px;">
              {{vehicle.temporaryManualTrackingTarget.address}}
            </b>
          </ng-container> -->
          <!-- {{shortDescription(vehicle.temporaryManualTrackingTarget.address)}}... -->
        </ng-container>
      </div>

      <div class="progress-bar progress-bar-striped progress-bar-animated progress-bg" 
      [style.height]="'100%'" [style.width]="percentageLeft()+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
      </div>
    </div>
  </div>
  
  <div class="text-right" style="width: 7%; line-height: 13px;">
    <img src="assets/icons-new/dashboard-regular.svg" alt="navigate_cross_red" height="13px" class="pointer"
    ngbTooltip="Zobrazit výkon" [ngbPopover]="popoverVykonJizda" placement="left auto" container="body"
    (click)="getAetr()" i18n-ngbTooltip/>

    <ng-template #popoverVykonJizda>
      <div *ngIf="aetr_collection?.currentSegment">
        <div *ngIf="aetr_collection.currentSegment.type == 'S'">
          <b><ng-container i18n>Spánek</ng-container>:</b> {{workloadCurrent}}h
        </div>
        <div *ngIf="aetr_collection.currentSegment.type == 'D'">
          <b><ng-container i18n>Výkon</ng-container>:</b> {{workloadCurrent}}h,
          <b><ng-container i18n>Jízda</ng-container>:</b> {{duration(aetr_collection.currentSegment)}}h,
          <b>{{doneDriving(aetr_collection.currentSegment)}}</b>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="text-right" style="width: 6%; line-height: 13px;">
    <img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="14px" 
    class="pointer" (click)="resetManualTemporaryTracking()" 
    ngbTooltip="Zrušit dojezdový cíl" placement="left auto" container="body" i18n-ngbTooltip/>
  </div>
</div>