import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { MessageService } from "../../service/message.service";
import { NotificationService } from "src/app/service/notification-service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { Message } from "../../model/message.object";
import { Vehicle } from "../../model/vehicle.object";
import { Obligation } from "src/app/model/obligation.object";
import { Order } from "src/app/model/order.object";

declare var $: any;

@Component({
  selector: 'message-row',
  templateUrl: './r-message-row.component.html',
  styleUrls: ['./r-message-row.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RMessageRowComponent implements OnDestroy {

  static FUNC_VEHICLE_TRACKING = 'tracking';
  static FUNC_TEXT = 'text';
  static FUNC_BREAKER = 'breaker';
  private _subscribed: Array<Subscription> = [];
  private _manualTrackingVehicleOpened: boolean = false;
  
  private _messageSegments: Array<any> = [];
  get messageSegments(): Array<any> {
    return this._messageSegments;
  }

  private _reply: boolean = false;
  get reply(): boolean {
    return this._reply;
  }
  @Input()
  set reply(reply: boolean) {
    this._reply = reply;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }

  private _visible: boolean = false;
  get visible(): boolean {
    return this._visible;
  }

  private _ignoreAttachment: boolean = false;
  get ignoreAttachment(): boolean {
    return this._ignoreAttachment;
  }

  private _deleting: boolean = false;
  get deleting(): boolean {
    return this._deleting;
  }

  private _coordinates: string = '';
  get coordinates(): string {
    return this._coordinates;
  }

  private _message: Message;
  get message(): Message {
    return this._message;
  }
  @Input()
  set message(message: Message) {
    this._message = message;
    // console.log(this._message);
    let locationSegments = message.msg.match(/\(\d+\.?\d*,\d+\.?\d*\)/g);
    if (message.msg_raw) {
      let haveFuckedUpOrderAttachment = message.msg_raw.replace(message.msg, '').match(/^<#ord:.+>$/);
      this._ignoreAttachment = haveFuckedUpOrderAttachment && haveFuckedUpOrderAttachment.length > 0;
    }
    if (locationSegments && locationSegments.length) {
      let messageStrings: Array<string> = [message.msg];
      locationSegments.forEach(
        segment => {
          let toPushSplitMessages = [];
          messageStrings.forEach(
            message => {
              message.split(segment).forEach(
                splitMessage => toPushSplitMessages.push(splitMessage)
              )
            }
          );
          messageStrings = toPushSplitMessages;
        }
      );
      messageStrings.forEach(
        (message, index) => {
          this._messageSegments.push({
            type: RMessageRowComponent.FUNC_TEXT,
            value: message,
          });
          if (locationSegments[index]) {
            this._messageSegments.push({
              type: RMessageRowComponent.FUNC_VEHICLE_TRACKING,
              value: locationSegments[index]
            });
          }
        }
      )
    } else {
      this._messageSegments.push({
        type: RMessageRowComponent.FUNC_TEXT,
        value: message.msg
      });
    }

    let finalSegments = [];

    this._messageSegments.forEach(
      (segment, index) => {
        if (segment.type === RMessageRowComponent.FUNC_TEXT) {
          let lineBrokenSegments = segment.value.split(/\n/);
          lineBrokenSegments.forEach(
            (text, index) => {
              finalSegments.push({
                type: RMessageRowComponent.FUNC_TEXT,
                value: text
              });
              if (index !== lineBrokenSegments.length - 1) {
                finalSegments.push({
                  type: RMessageRowComponent.FUNC_BREAKER,
                  value: null
                });
              }
            }
          );
        } else {
          finalSegments.push(segment);
        }
      }
    );

    this._messageSegments = finalSegments;

    // this._vehicleService.vehicles.forEach(
    //   vehicle => {
    //     if (vehicle.car_key === message.car_key) {
    //       this._vehicle = vehicle;
    //     }
    //   }
    // );
    // if (!this._vehicle) {
    //   // this._subscription = this._vehicleService.getVehicles(false, false).subscribe(
    //   //   vehicles => {
    //   //     vehicles.forEach(
    //   //       vehicle => {
    //   //         if (vehicle.car_key === message.car_key) {
    //   //           this._vehicle = vehicle;
    //   //         }
    //   //       }
    //   //     );
    //   //   }
    //   // )
    // }

    this._subscribed.push(
      this._vehicleService.getAllVehiclesCache().subscribe(
        (vehicles: Array<Vehicle>) => {
          if (vehicles && vehicles.length) {
            let vehicle: Vehicle = vehicles.find(v => v.car_key == message.car_key);
            if (vehicle && vehicle.car_key === message.car_key) {
              this._vehicle = vehicle;
              this._message.vehicle = vehicle;
              // custom detection
              this.detectChanges();
            }
          }
        }
      )
    );
    
    // custom detection
    this.detectChanges();

    // FIX to detect loaded order/obligation/itinerary from parent component
    if (this._message || this._message.obligation_key || this._message.itinerary_key || this._message.order_key) {
      window.setTimeout(
        () => {
          this.detectChanges();
        }, 3000
      );
    }
  }

  
  @Output() orderToWienerbergerEvent: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() urlWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() spzToWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() driverToWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() phoneToWienerbergerEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() loadingDateToWienerbergerEvent: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(
    private _messageService: MessageService, 
    private _vehicleService: VehicleNewService, 
    private _notificationServ: NotificationService,
    private _router: Router,
    private _cdr: ChangeDetectorRef
  ) {
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 10000
    );
  }

  ngOnDestroy() {      
    this._subscribed.forEach(
      s => s.unsubscribe()
    )
    this._subscribed = [];
  }

  
  public detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }
  
  get replyPossible(): boolean {
    return this._reply && !(!this._vehicle);
  }

  get manualTrackingConfigurationOpened(): boolean {
    return this._manualTrackingVehicleOpened;
  }

  get displayAttachment(): boolean {
    return this.message.attachment && !this.ignoreAttachment;
  }

  toggleReply() {
    this._visible = !this._visible;
    // custom detection
    this.detectChanges();
  }

  showReply() {
    this._visible = true;
    // custom detection
    this.detectChanges();
  }

  hideReply() {
    this._visible = false;
    // custom detection
    this.detectChanges();
  }

  openManualTrackingConfiguration(coordinates: string) {
    this._router.navigate([
      {outlets: {right: ['manual-tracking-full', this.vehicle.car_key, coordinates.replace(/\(|\)/g, '')]}}
    ]);
  }

  
  /*****************************************************/
  /* Reading message */
  /*****************************************************/
  readMessage(): void {
    this._subscribed.push(
      this._messageService.readMessage(this._message).subscribe()
    );
    // custom detection
    this.detectChanges();
  }


  /*****************************************************/
  /* Deleting message */
  /*****************************************************/
  deleteMessage(): void {
    this._deleting = true;
    this._subscribed.push(
      this._messageService.deleteMessage(this._message).subscribe(
        response => {
          this._deleting = false;
          // custom detection
          this.detectChanges();
        },
        error => {
          this._deleting = false;
          // custom detection
          this.detectChanges();
          console.log(error);
        }
      )
    );
    // custom detection
    this.detectChanges();
  }

  
  /************************************************************/
  /* Wienerberger */
  /************************************************************/
  // public obligationToWienerberger: Obligation = null;
  public orderToWienerberger: Order = null;
  public urlWienerberger: string = null;

  public spzToWienerberger: string = null;
  public driverToWienerberger: string = null;
  public phoneToWienerberger: string = null;
  public loadingDateToWienerberger: Date = null;

  // setObligationToWienerberger(o: Obligation): void {
  //   this.orderToWienerberger = null;
  //   this.obligationToWienerberger = o;
  //   if (o) {
  //     if (o.orders && o.orders.length) {
  //       this.spzToWienerberger = o.orders[0].spz;
  //       this.driverToWienerberger = o.orders[0].driver_name;
  //       this.phoneToWienerberger = o.orders[0].driver_phone;
  //     }
  //     else if (o.car) {
  //       this.spzToWienerberger = o.car.number_plate;
  //       this.driverToWienerberger = o.car.driver_name;
  //       this.phoneToWienerberger = o.car.driver_phone;
  //     }

  //     if (o.itinerary && o.itinerary.length) {
  //       this.loadingDateToWienerberger = o.itinerary[0].arrival_time;
  //     }
  //   }
  //   this.detectChanges();
  // }

  setOrderToWienerberger(o: Order): void {
    // reinit properties
    this.orderToWienerberger = o;
    this.spzToWienerberger = null;
    this.driverToWienerberger = null;
    this.phoneToWienerberger = null;
    this.loadingDateToWienerberger = null;

    if (o) {
      this.spzToWienerberger = o.spz;
      this.driverToWienerberger = o.driver_name;
      this.phoneToWienerberger = o.driver_phone;

      if (o.obligations && o.obligations[0] && o.obligations[0].itinerary && o.obligations[0].itinerary[0]) {
        this.loadingDateToWienerberger = o.obligations[0].itinerary[0].arrival_time;
      }

      this.orderToWienerbergerEvent.emit(this.orderToWienerberger);
      this.spzToWienerbergerEvent.emit(this.spzToWienerberger);
      this.driverToWienerbergerEvent.emit(this.driverToWienerberger);
      this.phoneToWienerbergerEvent.emit(this.phoneToWienerberger);
      this.loadingDateToWienerbergerEvent.emit(this.loadingDateToWienerberger);
    }

    console.log(this.orderToWienerberger);
    // possibly hide new messages modal
    (<any>$('#messagesModal')).modal('hide');
    this.detectChanges();
  }

  // setWcpWienerberger(): void {
  //   if (this.orderToWienerberger) {
  //     this.urlWienerberger = 'https://www.wcp-doprava.cz/doprava/wzi.php?czak=';
  //     if (this.orderToWienerberger.obligations && this.orderToWienerberger.obligations[0]) {
  //       this.urlWienerberger += this.orderToWienerberger.obligations[0].order_number;
  //     }
  //   }
  //   this.urlWienerbergerEvent.emit(this.urlWienerberger);
  //   // possibly hide new messages modal
  //   (<any>$('#messagesModal')).modal('hide');
  //   this.detectChanges();
  // }
  
  // setTondachWienerberger(): void {
  //   if (this.orderToWienerberger) {
  //     this.urlWienerberger = 'https://www.tondach-doprava.cz/doprava/wzi.php?czak=';
  //     if (this.orderToWienerberger.obligations && this.orderToWienerberger.obligations[0]) {
  //       this.urlWienerberger += this.orderToWienerberger.obligations[0].order_number;
  //     }
  //   }
  //   console.log(this.urlWienerberger);
  //   console.log(this.orderToWienerberger);
  //   this.urlWienerbergerEvent.emit(this.urlWienerberger);
  //   this.detectChanges();
  //   // possibly hide new messages modal
  //   (<any>$('#messagesModal')).modal('hide');
  // }

  
  /**************************************/
  /* Routing stuff */
  /**************************************/
  openObligationNewTab(obligation_key: number): void {
    let url: any = null;
    let queryParams: any = {
      obligation_key: obligation_key,
      reloadPossible: true
    };
    url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {left: 'ta1-obligation/company_obligation', right: 'cars'}}], 
        {queryParams: queryParams}
      )
    );
    window.open('#' + url, '_blank');
  }

  openOrderNewTab(order_key: number): void {
    let url: any = null;
    let queryParams: any = {
      order_key: order_key,
      reloadPossible: true
    };
    url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {left: 'ta1-order/company_order', right: 'cars'}}], 
        {queryParams: queryParams}
      )
    );
    window.open('#' + url, '_blank');
  }
}
