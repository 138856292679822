import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// import { VehicleService } from 'src/app/service/vehicle.service';
import { VehicleNewService } from 'src/app/service/vehicle-new.service';
import { ObligationService } from 'src/app/service/obligation.service';
import { TmAgendaService } from 'src/app/service/tm-agenda.service';
import { Attachment } from 'src/app/model/attachment.object';
import { Agenda } from 'src/app/model/agenda.object';
import { Obligation } from 'src/app/model/obligation.object';
import { Vehicle } from 'src/app/model/vehicle.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { ObligationTools } from 'src/app/tools/ObligationTools';
import { Haversine } from 'src/app/tools/Haversine';
import { ServiceEventObject } from 'src/app/model/service-event.object';
import { GeoPosition } from 'src/app/model/geo-position.object';
import { ItineraryDairyDayCollection } from 'src/app/model/itinerary-dairy-day-collection.object';
import { ItineraryType } from 'src/app/config';
import { Ta1ObligationEmailingComponent } from '../ta1-obligation-emailing/ta1-obligation-emailing.component';
import { Email } from 'src/app/model/email.object';

declare var $: any;

@Component({
  selector: 'r-last-cargo-diary',
  templateUrl: './r-last-cargo-diary.component.html',
  styleUrls: ['./r-last-cargo-diary.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TmAgendaService,
    ObligationService
  ]
})
export class RLastCargoDiaryComponent implements OnInit {

  private _subscribed: Array<Subscription> = [];

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
    if (this._vehicle) {
      // solve today itinerary from agenda
      this.initTodayAgendaItinerary();
      // solve not planned itinerary (from vehicle aetr service events)
      this.initTodayEventsItinerary();

      // there could be also some itinerary from driver diary -> add them
      this.initTmAgendaItinerary();

      // custom detection signal
      this.detectChanges();
    }
  }

  // planned service events
  // attribute for saving service events from itinerary.service_events array
  private _serviceEventsInAgenda: Array<any> = [];

  private _itinerary: Array<Itinerary>;
  get itinerary(): Array<Itinerary> {
    return this._itinerary;
  }

  // not planned service events
  // service events not in agenda
  private _serviceEventsOutOfAgenda: Array<ServiceEventObject> = [];
  public get serviceEventsOutOfAgenda(): Array<ServiceEventObject> {
    return this._serviceEventsOutOfAgenda;
  }

  // itinerary created from service events not in agenda
  private _itineraryOutOfAgenda: Array<Itinerary> = [];
  public get itineraryOutOfAgenda(): Array<Itinerary> {
    return this._itineraryOutOfAgenda;
  }
  
  constructor(
    private _vehicleServ: VehicleNewService,
    private _obligationServ: ObligationService,
    private _tmAgendaServ: TmAgendaService,
    private _router: Router,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }
  
  ngOnDestroy() {
    this._subscribed.forEach(
      subscription => subscription.unsubscribe()
    )
  }
  
  detectChanges(): void {
    // detect changes 100 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }

  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }

  get loading(): boolean {
    return this._tmAgendaServ.loading;
  }
    

  // method that manages creating planned itinerary from vehicle agenda
  initTodayAgendaItinerary(): void {
    // solve today itinerary from agenda
    let new_itineraries: Array<Itinerary> = [];
    if (this._vehicle.agenda && this._vehicle.agenda.length) {
      this._vehicle.agenda.forEach(
        agenda => {
          if (agenda.itinerary && agenda.itinerary.length) {
            agenda.itinerary.forEach(
              itinerary => {
                itinerary.car_key = this._vehicle.car_key;
                itinerary.number_plate = this._vehicle.number_plate;
                new_itineraries.push(itinerary);
              }
            );
          }
        }
      );
    }

    // using same technique as in diary
    let sortedItinerary = new ItineraryDairyDayCollection(new_itineraries);
    this._itinerary = [];
    // saving obligation keys
    let obligation_keys: Array<number> = [];

    if (sortedItinerary.byDayVehicle.today.length && sortedItinerary.byDayVehicle.today[0].itineraries) {
      this._itinerary = sortedItinerary.byDayVehicle.today[0].itineraries;
      this._itinerary.forEach(
        it => {
          // save obligation_key
          if (!obligation_keys.includes(it.agenda.obligation_key)) {
            obligation_keys.push(it.agenda.obligation_key);
          }

          // save service event connected with agenda
          if (it.service_events) {
            it.service_events.forEach(
              ev => {
                this._serviceEventsInAgenda.push(ev);
              }
            );
          }
        }
      );
    } 

    // load files and save them to agendas
    this.loadFiles(obligation_keys);

    // custom detection signal
    this.detectChanges();
  }

  // loading possibly old not finished itinerary from tm-agenda
  initTmAgendaItinerary(): void {
    let obligation_keys: Array<number> = [];

    this._subscribed.push(
      this._tmAgendaServ.getTmAgendaItinerary(this._vehicle).subscribe(
        response => {
          if (response && response.length) {
            // console.log(response);
            let tmpItinerary = response;
            // keep only loading and unloadings
            tmpItinerary = tmpItinerary.filter(
              it => it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING
            );
            // removing already added itinerary from vehicle agenda (todays)
            tmpItinerary = tmpItinerary.filter(
              i => !this._itinerary.find(i2 => i2.itinerary_key == i.itinerary_key)
            );
            tmpItinerary.forEach(
              it => {
                it.vehicle = this._vehicle;
                it.car_key = this._vehicle.car_key;
                it.number_plate = this._vehicle.number_plate;

                // save obligation_key
                if (!obligation_keys.includes(it.obligation_key)) {
                  obligation_keys.push(it.obligation_key);
                }
              }
            );
            
            console.log(response);

            // add left tm-agenda itinerary to itinerary array
            this._itinerary = this._itinerary.concat(tmpItinerary);
            this._itinerary.sort((a, b) => (a.arrival_time > b.arrival_time) ? 1 : -1);
            this.loadObligations(obligation_keys);
          }
          // custom detection signal
          this.detectChanges();
        }
      )
    );
  }
  
  // method that manages creating not planned itinerary according to service events
  initTodayEventsItinerary(): void {
    // aetr/<car_key>/service
    if (!this._vehicle.latestServiceEventsLazyLoad) {
      this._vehicle.latestServiceEventsLazyLoad = this._vehicleServ.createLatestServiceEventsLazyload(this._vehicle);
    }

    this._subscribed.push(
      this._vehicle.latestServiceEvents(true).subscribe(
        events => {
          if (events && events.length) {
            // save all (obsolete)
            let loadUnloadEvents = events.filter(
              (event) => {
                if ([Vehicle.LOADING_END, Vehicle.UNLOADING_END, Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(event.type) > -1) {
                  return event;
                }
              }
            );

            this._serviceEventsOutOfAgenda = [];
            // find events out of agenda (not planned)
            loadUnloadEvents.forEach(
              ev => {
                let found = this._serviceEventsInAgenda.find(
                  e => e.time == ev.time && e.type == ev.type
                );
                if (!found) {
                  this.serviceEventsOutOfAgenda.push(ev);
                }
              }
            );

            this._itineraryOutOfAgenda = [];
            // create itinerary from not planned events
            let ends = this.serviceEventsOutOfAgenda.filter(e => e.type == Vehicle.LOADING_END || e.type == Vehicle.UNLOADING_END);
            ends.forEach(
              ev => {
                let it: Itinerary = new Itinerary();
                it.type = ev.type;
                it.gps_coord = new GeoPosition();
                it.gps_coord.pos_gps = ev.pos_gps; 
                it.vehicle = this._vehicle;
                if (ev.city) {
                  it.address = ev.city.name + ' (' + ev.city.country + ' ' + ev.city.zip + ')';
                } 
                it.note = ev.order_number;  // give order_number (that driver writes) to note
                it.service_events = [];
                it.service_events.push(ev);
                this._itineraryOutOfAgenda.push(it);
              }
            );

            let starts = this.serviceEventsOutOfAgenda.filter(e => e.type == Vehicle.LOADING_START || e.type == Vehicle.UNLOADING_START);
            starts.forEach(
              ev => {
                let found: boolean = false;

                // find if event could be paired with any itinerary (that have been previously created)
                for (let i = 0; i < this._itineraryOutOfAgenda.length; i++) {
                  let it = this._itineraryOutOfAgenda[i];

                  // skip if already got some start event
                  if (it.service_events && it.service_events.length > 1) continue;

                  let dist = Haversine.haversine(
                    { latitude: it.gps_coord.googleLatLang.lat, longitude: it.gps_coord.googleLatLang.lng },
                    { latitude: ev.latLng.lat, longitude: ev.latLng.lng }
                  );
                  // distance closer than 1 km
                  if (dist < 1 && it.type == ev.type.toUpperCase()) {
                    found = true;
                    it.service_events.push(ev);
                    break;
                  }
                }

                // some itinerary events just could start and not have finished
                if (!found) {
                  let it: Itinerary = new Itinerary();
                  it.type = ev.type.toUpperCase();
                  it.gps_coord = new GeoPosition();
                  it.gps_coord.pos_gps = ev.pos_gps; 
                  it.vehicle = this._vehicle;
                  if (ev.city) {
                    it.address = ev.city.name + ' (' + ev.city.country + ' ' + ev.city.zip + ')';
                  }
                  it.note = ev.order_number;  // give order_number (that driver writes) to note
                  it.service_events = [];
                  it.service_events.push(ev);
                  this._itineraryOutOfAgenda.push(it);
                }
              }
            );
          }
          // custom detection signal
          this.detectChanges();
        }
      )
    )
  }

  
  // possible details of itinerary
  private _currentPositionDetailItinerary: Itinerary;
  get currentPositionDetailItinerary(): Itinerary {
    return this._currentPositionDetailItinerary;
  }

  private _itineraryAgendaDetail: Itinerary;
  get itineraryAgendaDetail(): Itinerary {
    return this._itineraryAgendaDetail;
  }
  
  openCurrentPositionDetail(itinerary: Itinerary) {
    // close possible previous
    this._itineraryAgendaDetail = null;
    // close
    if (this.currentPositionDetailItinerary === itinerary) {
      this._currentPositionDetailItinerary.manualTrackingMode = false;
      this._currentPositionDetailItinerary = null;
    } 
    // open
    else {
      if (this._currentPositionDetailItinerary)
        this._currentPositionDetailItinerary.manualTrackingMode = false;
      this._currentPositionDetailItinerary = itinerary;
    }
    // custom detection signal
    this.detectChanges();
  }

  openItineraryAgendaDetail(itinerary: Itinerary) {
    // close possible previous
    this._currentPositionDetailItinerary = null;
    // close
    if (this.itineraryAgendaDetail === itinerary) {
      this._itineraryAgendaDetail = null;
    } 
    // open
    else {
      this._itineraryAgendaDetail = itinerary;
    }
    // custom detection signal
    this.detectChanges();
  }
  
  // handler for event from r-vehicle-manual-tracking-itinerary component
  hideMapEvent(ev: boolean) {
    if (ev == true) {
      this._currentPositionDetailItinerary = null;
    }
    // custom detection signal
    this.detectChanges();
  }


  /***********************************************************/
  /******* Obligation/Files loading management methods *******/
  /***********************************************************/
  // loading files array of obligations (TODO now needed to load full obligations)
  private loadFiles(obligation_keys: Array<number>): void {
    obligation_keys.forEach(
      key => {
        this._subscribed.push(
          this._obligationServ.getObligationToComponent(key).subscribe(
            o => {
              if (o) {
                let obligation: Obligation = ObligationTools.buildObligation(o);
                let filesArr = new Array<Attachment>();

                // build files array
                if (obligation.files.length) {
                  obligation.files.forEach(
                    f => {
                      let file = new Attachment();
                      for (let key in f) {
                        file[key] = f[key];
                      }
                      filesArr.push(file);
                    }
                  );

                  // remove demo files (starting with prefix out-qqq)
                  filesArr = filesArr.filter(f => f.name && !f.name.includes('out-qqq'));

                  // save files to agenda 
                  let agenda: Agenda = this._vehicle.agenda.find(a => a => a.obligation_key == key);
                  if (agenda) {
                    agenda.files = filesArr;
                  }

                  // save files to itinerary agenda (the privous reference sometimes does not work)
                  this._itinerary.forEach(
                    it => {
                      if (it.agenda && it.agenda.obligation_key == obligation.obligation_key) {
                        it.obligation = obligation;
                        it.agenda.files = filesArr;
                      }
                    }
                  );
                }
                // custom detection signal
                this.detectChanges();
              }
            },
            error => {
              console.log(error);
            }
          )
        );
      }
    );
  }

  // method for loading full obligations objects (wtih files as well)
  private loadObligations(obligation_keys: Array<number>): void {
    obligation_keys.forEach(
      key => {
        this._subscribed.push(
          this._obligationServ.getObligationToComponent(key).subscribe(
            o => {
              if (o) {
                let obligation: Obligation = ObligationTools.buildObligation(o);
                let filesArr = new Array<Attachment>();
                // build files array
                if (obligation.files.length) {
                  obligation.files.forEach(
                    f => {
                      let file = new Attachment();
                      for (let key in f) {
                        file[key] = f[key];
                      }
                      filesArr.push(file);
                    }
                  );

                  // remove demo files (starting with prefix out-qqq)
                  filesArr = filesArr.filter(f => f.name && !f.name.includes('out-qqq'));
                }

                // save ref for agenda and obligation to itinerary
                let agenda: Agenda = ObligationTools.obligationToAgenda(obligation);
                let tmpItinerary = this._itinerary.filter(
                  it => it.obligation_key == obligation.obligation_key
                );
                tmpItinerary.forEach(
                  it => {
                    it.obligation = obligation;
                    it.agenda = agenda;
                    it.agenda.files = filesArr;
                  }
                );
                // custom detection signal
                this.detectChanges();
              }
            },
            error => {
              console.log(error);
            }
          )
        );
      }
    );
  }

  // method for customizing width of popover div
  widthPopoverOfFiles(agenda: Agenda): string {
    let len: number = agenda.files.length;
    if (len < 2) return '140px';
    else if (len < 3) return '280px';
    else if (len < 4) return '420px';
    else return '550px';
  }

  // download all thumbnails of files
  downloadThumbnails(agenda: Agenda): void {
    if (agenda.files) {
      agenda.files.forEach(
        f => {
          this._obligationServ.downloadThumbnail(f);
        }
      );
    }
  }

  // flag for detecting if file/attachment is currently downloading
  public get downloadingFile(): boolean {
    return this._obligationServ.downloadingAttachment;
  }

  // method for updating attributes of specified obligation
  downloadAttachment(attachment: Attachment): void {
    this._obligationServ.openAttachmentNewTab(attachment);
  }

  
  /************************************************************/
  /* Methods for creating service event without TM */
  /************************************************************/
  public newServiceEvent: ServiceEventObject = new ServiceEventObject();

  private _itineraryToEvent: Itinerary = null;
  public get itineraryToEvent(): Itinerary {
    return this._itineraryToEvent;
  }
  public set itineraryToEvent(value: Itinerary) {
    this._itineraryToEvent = value;
    console.log(this._itineraryToEvent);

    if (this._itineraryToEvent) {
      this.newServiceEvent = new ServiceEventObject();
      if (this._itineraryToEvent.agenda) {
        console.log(this._itineraryToEvent.agenda);
        this.newServiceEvent.order_number = this._itineraryToEvent.agenda.order_number_standard;
        this.newServiceEvent.car_key = this._itineraryToEvent.agenda.car_key;
      }
      this.newServiceEvent.itinerary_key = this._itineraryToEvent.itinerary_key; 
      this.newServiceEvent.time_custom = this._itineraryToEvent.arrival_time_custom;
      this.newServiceEvent.type = this._itineraryToEvent.type;
      this.newServiceEvent.weight = this._itineraryToEvent.weight ? this._itineraryToEvent.weight : 0;
      this.newServiceEvent.length = this._itineraryToEvent.length ? this._itineraryToEvent.length : 0;
      this.newServiceEvent.containers_in = 0;
      this.newServiceEvent.containers_out = 0;
      this.newServiceEvent.descr = '';

      console.log(this.newServiceEvent);
    }
    // custom detection signal
    this.detectChanges();
  }

  createNewServiceEvent(): void {
    this._tmAgendaServ.createServiceEvent(this.newServiceEvent).subscribe(
      ev => {
        if (ev) {
          // reload 
          const MAGIC_TIMEOUT: number = 2000; // 2 seconds
          window.setTimeout(
            () => {
              // reinit
              // solve today itinerary from agenda
              this.initTodayAgendaItinerary();
              // solve not planned itinerary (from vehicle aetr service events)
              this.initTodayEventsItinerary();

              // there could be also some itinerary from driver diary -> add them
              this.initTmAgendaItinerary();
              // custom detection signal
              this.detectChanges();
            }, MAGIC_TIMEOUT
          );
        }
      }
    );
  }

  // modal-backdrop has been staying in dispatcher board component
  hotfixRemoveBackdropModal(): void {
    window.setTimeout(
      () => {
        console.log('remove backdrop');
        $('.modal-backdrop').remove();
      }, 250
    );
  }

  
  /************************************************************/
  /* Methods for sending email of obligation */
  /************************************************************/
  @ViewChild('myEmailingChild', {static: false}) private _myEmailingChild: Ta1ObligationEmailingComponent;

  private _agendaToSend: Agenda = null;
  public get agendaToSend(): Agenda {
    return this._agendaToSend;
  }
  public set agendaToSend(value: Agenda) {
    this._agendaToSend = value;
    if (this._agendaToSend) {
      if (this._agendaToSend.edoc_url && this._agendaToSend.order_comp_book.edoc_required_documents) {
        window.open(this._agendaToSend.edoc_url, '_blank');
      }
      else {
        (<any>$('#sendAttachmentsModal')).modal('show');
      }
    }

    // init also attachments for emailing
    this.initAttachments();
    // reinit child component
    if (this._myEmailingChild) {
      this._myEmailingChild.reinitComponent();
    }

    this.detectChanges();
  }

  private _attachmentsToSend: Array<Attachment> = [];
  public get attachmentsToSend(): Array<Attachment> {
    return this._attachmentsToSend;
  }

  /* Thumbnails part */
  initAttachments(): void {
    this._attachmentsToSend = [];
    if (this._agendaToSend.files) {
      this._attachmentsToSend = this._agendaToSend.files;
    }

    this._attachmentsToSend.forEach(
      att => {
        this._obligationServ.downloadThumbnail(att);
      }
    );

    this.detectChanges();
  }

  // method for loading detail html page about email sent
  showEmailDetail(email_msg_id: string): void {
    this._obligationServ.getEmailShow(email_msg_id).subscribe(
      response => {
        if (response) {
          // using this solution now: https://stackoverflow.com/a/10573430
          let newWindow = window.open();
          newWindow.document.write(response);
          this.detectChanges();
        }
      }
    );
  }

  // output event binding method
  eventAfterEmailSent(event) {
    if (event) {
      // init if any email has not been sent yet
      if (!this._agendaToSend.email_headers) this._agendaToSend.email_headers = [];
      
      this._subscribed.push(
        this._obligationServ.getEmailHeader(event).subscribe(
          (header: Email) => {
            if (header) {
              header.email_msg_id = event;
              this._agendaToSend.email_headers.push(header);
              this.detectChanges();
            }
          }
        )
      );
    }
  }


  /************************************************************/
  /* Methods for sending report to VISCOFAN */
  /************************************************************/
  public agendaToViscofan: Agenda = null;
  public viscofanMessage: string = '';
  public viscofanReportType: string = null;

  public readonly VISCOFAN_REPORT_TYPES: Array<string> = [
    'VehicleFault', 'Accident', 'TrafficComplication', 'DelayedLoading', 'HumanError'
  ];

  sendReportToViscofan(): void {
    let data: any = {
      action: 'incident',
      type: this.viscofanReportType,
      msg: this.viscofanMessage
    };

    this._subscribed.push(
      this._obligationServ.reportObligationToViscofan(
        this.agendaToViscofan.obligation_key, data
      ).subscribe(
        response => {
          console.log(response);
        }
      )
    );
  }


  
  /**************************************/
  /* Routing stuff */
  /**************************************/
  openObligationNewTab(obligation_key: number): void {
    let url: any = null;
    let queryParams: any = {
      obligation_key: obligation_key,
      reloadPossible: true
    };
    url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {left: 'ta1-obligation/company_obligation', right: 'cars'}}], 
        {queryParams: queryParams}
      )
    );
    window.open('#' + url, '_blank');
  }
}
