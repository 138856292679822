import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { HomeStandKey } from "../model/home-stand-key.object";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class CitiesService {

  private _apiUrl: string = ServiceConfiguration.cities.api;
  private _cities: BehaviorSubject<Array<HomeStandKey>> = new BehaviorSubject([]);
  private _citiesCache: Array<HomeStandKey> = [];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService
  ) {
  }
  
  private clearCitiesCache(): void {
    this._citiesCache = [];
  }

  private _loadingCities: boolean = false;
  get loadingCities(): boolean {
    return this._loadingCities;
  }

  // method for getting possible cities for autocompleter (by name or zip)
  getCities(searchText: string) {
    this.clearCitiesCache();

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // to lower
      searchText = searchText.toLowerCase();
      // ignore 1 or less symbol
      if (searchText.length < 2) {
        // empty array
        this._cities.next(this._citiesCache);
        return this._cities.asObservable();
      }

      let url: string = this._apiUrl;
      // only digits -> zip code
      if (/^\d+$/.test(searchText)) {
        url += '?zip=' + searchText;
      }
      // CZ and zip code
      else if ((searchText.startsWith("cz-") || searchText.startsWith("cz ")) && searchText.length > 3) {
        url += '?country=CZ';
        url += '&zip=' + searchText.substring(3);
      }
      // SK and zip code
      else if ((searchText.startsWith("sk-") || searchText.startsWith("sk ")) && searchText.length > 3) {
        url += '?country=SK';
        url += '&zip=' + searchText.substring(3);
      } else {
        url += '?name=' + searchText;
      }

      this._loadingCities = true;
      this._http.get(url).subscribe(
          response => {
            this._citiesCache = this.buildCitiesFromData(response);
            // slice number of cities to max constant value 6
            if (this._citiesCache.length > 6) {
              this._citiesCache = this._citiesCache.slice(0, 6);
            }
            this._cities.next(this._citiesCache);
          },
          error => {
            // handle error
            console.log(error);
            this._loadingCities = false;
          },
          () => {
            this._loadingCities = false;
          }
        );
    }

    return this._cities.asObservable();
  }

  
  // method for getting possible cities for autocompleter (by name or zip)
  getCitiesNoObservable(filterObj: any) {
    let result: BehaviorSubject<Array<HomeStandKey>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // to lower
      // searchText = searchText.toLowerCase();
      // ignore 1 or less symbol
      // if (searchText.length < 2) {
      //   // empty array
      //   result.next([]);
      //   return result.asObservable();
      // }

      console.log(filterObj);

      let url: string = this._apiUrl;
      if (filterObj) {
        if (filterObj.country) {
          url += '?country=' + filterObj.country.toUpperCase();
        }
        if (filterObj.zip) {
          url += filterObj.country ? '&' : '?';
          url += 'zip=' + filterObj.zip;
        }
        if (filterObj.name) {
          url += (filterObj.country || filterObj.zip) ? '&' : '?';
          url += 'name=' + filterObj.name.toLowerCase();
        }
      }

      // only digits -> zip code
      // if (/^\d+$/.test(searchText)) {
      //   url += '?zip=' + searchText;
      // }
      // // CZ and zip code
      // else if ((searchText.startsWith("cz-") || searchText.startsWith("cz ")) && searchText.length > 3) {
      //   url += '?country=CZ';
      //   url += '&zip=' + searchText.substring(3);
      // }
      // // SK and zip code
      // else if ((searchText.startsWith("sk-") || searchText.startsWith("sk ")) && searchText.length > 3) {
      //   url += '?country=SK';
      //   url += '&zip=' + searchText.substring(3);
      // } else {
      //   url += '?name=' + searchText;
      // }

      this._loadingCities = true;
      this._http.get(url).subscribe(
        response => {
          result.next(this.buildCitiesFromData(response));
          this._loadingCities = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingCities = false;
        }
      );
    }

    return result.asObservable();
  }

  // method for creating cities array
  private buildCitiesFromData(data: Array<any>): any {
    let cities: Array<HomeStandKey> = [];
    data.forEach(
      c => {
        let city = this.buildCity(c);
        cities.push(city);
      }
    );
    return cities;
  }

  // method for creating a single city/homestand object
  private buildCity(c: any): HomeStandKey {
    let city: HomeStandKey = new HomeStandKey();
    for (let key in c) {
      city[key] = c[key];
    }
    return city;
  }
}
