import { GoogleLatLng } from "./google-lat-lng.object";
import { VehicleTA } from "./vehicle-ta.object";
import { VehicleTM } from "./vehicle-tm.object";
import { Vehicle } from "./vehicle.object";

declare var google: any;

export class GoogleMapMarker {

  private _googleMarker: any;
  private events: Array<any> = [];

  private static _icons = {
    l: {
      url: "assets/icon/icon-car-state/loading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    L: {
      url: "assets/icon/icon-car-state/loading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    u: {
      url: "assets/icon/icon-car-state/unloading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    U: {
      url: "assets/icon/icon-car-state/unloading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    X: {
      url: "assets/icon/icon-car-state/interruption.svg",  // interruption vs. transit!
      // scaledSize: new google.maps.Size(25, 25)
    },
    M_left: {
      url: "assets/icon/icon-car-state/load.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    M: {
      url: "assets/icon/icon-car-state/load.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    m: {
      url: "assets/icon/icon-car-state/unload.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    m_left: {
      url: "assets/icon/icon-car-state/unload.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    M_right: {
      url: "assets/icon/icon-car-state/load_right.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    m_right: {
      url: "assets/icon/icon-car-state/unload_right.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    '*': { //start
      url: "assets/icon/icon-car-state/sleep.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    '#': { //end
      url: "assets/icon/icon-car-state/sleep.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    F: {
      url: "assets/icon/icon-car-state/ferry.svg", // ferry vs. fuel!
      // scaledSize: new google.maps.Size(25, 25)
    },
    _: {
      url: "assets/icon/icon-car-state/stop.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    '=': {
      url: "assets/icon/icon-car-state/stop.svg",
      // scaledSize: new google.maps.Size(25, 25)
    },
    A: {
      url: "assets/icon/icon-car-state/loading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    B: {
      url: "assets/icon/icon-car-state/unloading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    C: {
      url: "assets/icon/icon-car-state/start.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    D: {
      url: "assets/icon/icon-car-state/start.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    E: {
      url: "assets/icon/icon-car-state/unloading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    W: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    V: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    T: {
      url: "assets/icon/icon-car-state/forklift.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_F: {
      url: "assets/icon/icon-car-state/fuel-new.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_X: {
      url: "assets/icon/icon-car-state/transit.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_M: {
      url: "assets/icon/icon-car-state/unload.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_L: {
      url: "assets/icon/icon-car-state/loading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_U: {
      url: "assets/icon/icon-car-state/unloading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_l: {
      url: "assets/icon/icon-car-state/loading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_u: {
      url: "assets/icon/icon-car-state/unloading.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_W: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_V: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    serviceEvent_T: {
      url: "assets/icon/icon-car-state/forklift.svg", // using service event for itinerary.type = TRANSSHIPMENT
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_L: {
      url: "assets/icon/icon-car-state/loading-grayscale.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_U: {
      url: "assets/icon/icon-car-state/unloading-grayscale.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_F: {
      url: "assets/icon/icon-car-state/fuel-grayscale.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_W: {
      url: "assets/icon/icon-car-state/warehouse-grayscale.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_V: {
      url: "assets/icon/icon-car-state/warehouse-grayscale.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_X: {
      url: "assets/icon/icon-car-state/transit-grayscale.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_T: {
      url: "assets/icon/icon-car-state/forklift-grayscale.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    sm_F: {
      url: "assets/icon/icon-car-state/fuel-new-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    sm_L: {
      url: "assets/icon/icon-car-state/loading-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    sm_U: {
      url: "assets/icon/icon-car-state/unloading-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    sm_X: {
      url: "assets/icon/icon-car-state/transit-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    sm_W: {
      url: "assets/icon/icon-car-state/warehouse1-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    sm_V: {
      url: "assets/icon/icon-car-state/warehouse1-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    sm_T: {
      url: "assets/icon/icon-car-state/forklift-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    grayscale_sm_L: {
      url: "assets/icon/icon-car-state/loading-grayscale-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    grayscale_sm_U: {
      url: "assets/icon/icon-car-state/unloading-grayscale-sm.svg",
      // scaledSize: new google.maps.Size(24, 24)
    },
    grayscale_sm_F: {
      url: "assets/icon/icon-car-state/fuel-grayscale-sm.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_sm_X: {
      url: "assets/icon/icon-car-state/transit-grayscale-sm.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_sm_W: {
      url: "assets/icon/icon-car-state/warehouse-grayscale-sm.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_sm_V: {
      url: "assets/icon/icon-car-state/warehouse-grayscale-sm.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
    grayscale_sm_T: {
      url: "assets/icon/icon-car-state/forklift-grayscale-sm.svg",
      // scaledSize: new google.maps.Size(32, 32)
    },
  };

  static get icons(): any {
    return this._icons
  }

  private _anchorPoint: any;
  get anchorPoint(): any {
    return this.getGoogleMarker().getAnchorPoint();
  }
  set anchorPoint(value: any) {
    this.getGoogleMarker().setAnchorPoint(value)
  }

  // The offset from the marker's position to the tip of an InfoWindow that has been opened with the marker as anchor.
  private _animation: any;
  get animation(): any {
    return this.getGoogleMarker().getAnimation();
  }
  set animation(value: any) {
    this.getGoogleMarker().setAnimation(value)
  }

  //Which animation to play when marker is added to a map.
  private _attribution: any;
  get attribution(): any {
    return this.getGoogleMarker().getAttribution();
  }
  set attribution(value: any) {
    this.getGoogleMarker().setAttribution(value)
  }

  // Contains all the information needed to identify your application as the source of a save. In this context, 'place' means a business, point of interest or geographic location. attribution must be specified with a place in order to enable a save.
  private _clickable: boolean;
  get clickable(): boolean {
    return this.getGoogleMarker().getClickable();
  }
  set clickable(value: boolean) {
    this.getGoogleMarker().setClickable(value)
  }

  // If true, the marker receives mouse and touch events. Default value is true.
  private _crossOnDrag: boolean;
  get crossOnDrag(): boolean {
    return this.getGoogleMarker().getCrossOnDrag();
  }
  set crossOnDrag(value: boolean) {
    this.getGoogleMarker().setCrossOnDrag(value)
  }

  // If false, disables cross that appears beneath the marker when dragging. This option is true by default.
  private _cursor: string;
  get cursor(): string {
    return this.getGoogleMarker().getCursor();
  }
  set cursor(value: string) {
    this.getGoogleMarker().setCursor(value)
  }

  // Mouse cursor to show on hover
  private _draggable: boolean;
  get draggable(): boolean {
    return this.getGoogleMarker().getDraggable();
  }
  set draggable(value: boolean) {
    this.getGoogleMarker().setDraggable(value)
  }

  // If true, the marker can be dragged. Default value is false.
  private _icon: string;//|Icon|Symbol;
  get icon(): string {
    return this.getGoogleMarker().getIcon();
  }
  set icon(value: string) {
    this.getGoogleMarker().setIcon(value)
  }

  // Icon for the foreground. If a string is provided, it is treated as though it were an Icon with the string as url.
  private _label: string;//|MarkerLabel;
  get label(): string {
    return this.getGoogleMarker().getLabel();
  }
  set label(value: string) {
    this.getGoogleMarker().setLabel(value)
  }

  // Adds a label to the marker. The label can either be a string, or a MarkerLabel object. Only the first character of the string will be displayed.
  private _map: any;//Map|StreetViewPanorama;
  get map(): any {
    return this.getGoogleMarker().getMap();
  }
  set map(value: any) {
    this.getGoogleMarker().setMap(value);
  }

  // Map on which to display Marker.
  private _opacity: number;
  get opacity(): number {
    return this.getGoogleMarker().getOpacity();
  }
  set opacity(value: number) {
    this.getGoogleMarker().setOpacity(value)
  }

  // The marker's opacity between 0.0 and 1.0.
  private _optimized: boolean;
  get optimized(): boolean {
    return this.getGoogleMarker().getOptimized();
  }
  set optimized(value: boolean) {
    this.getGoogleMarker().setOptimized(value)
  }

  // Optimization renders many markers as a single static element. Optimized rendering is enabled by default. Disable optimized rendering for animated GIFs or PNGs, or when each marker must be rendered as a separate DOM element (advanced usage only).
  private _place: any;//:MarkerPlace;
  get place(): any {
    return this.getGoogleMarker().getPlace();
  }
  set place(value: any) {
    this.getGoogleMarker().setPlace(value)
  }

  //Place information, used to identify and describe the place associated with this Marker. In this context, 'place' means a business, point of interest or geographic location. To allow a user to save this place, open an info window anchored on this marker. The info window will contain information about the place and an option for the user to save it. Only one of position or place can be specified.
  private _position: GoogleLatLng//:LatLng;
  get position(): any {
    return this.getGoogleMarker().getPosition();
  }
  set position(value: any) {
    let lat, lng: number;
    if (!value) return;

    if (value instanceof GoogleLatLng) {
      lat = value.lat;
      lng = value.lng;
    } 
    else {
      if (value.lat instanceof Function) {
        lat = value.lat();
      } 
      else {
        lat = value.lat;
      }

      if (value.lng instanceof Function) {
        lng = value.lng();
      } 
      else {
        lng = value.lng;
      }
    }
    this.getGoogleMarker().setPosition(new google.maps.LatLng(lat, lng));
  }

  // Marker position. Required.
  private _shape: any//:MarkerShape;
  get shape(): any {
    return this.getGoogleMarker().getShape();
  }
  set shape(value: any) {
    this.getGoogleMarker().setShape(value)
  }

  // Image map region definition used for drag/click.
  private _title: string;
  get title(): string {
    return this.getGoogleMarker().getTitle();
  }
  set title(value: string) {
    this.getGoogleMarker().setTitle(value)
  }

  // Rollover text
  private _visible: boolean;
  get visible(): boolean {
    return this.getGoogleMarker().getVisible();
  }
  set visible(value: boolean) {
    this.getGoogleMarker().setVisible(value)
  }

  // If true, the marker is visible
  private _zIndex: number;
  get zIndex(): number {
    return this.getGoogleMarker().getZIndex();
  }
  set zIndex(value: number) {
    this.getGoogleMarker().setZIndex(value)
  }

  // All markers are displayed on the map in order of their zIndex, with higher values displaying in front of markers with lower values. By default, markers are displayed according to their vertical position on screen, with lower markers appearing in front of markers further up the screen.
  private _iwText: string;
  get iwText() {
    return this._map.getIwText();
  }
  set iwText(value: string) {
    this._iwText = value;
  }

  //This will be rendered as infowindow for this marker on map
  private _infoWindowContent;
  get infoWindowContent() {
    return this._infoWindowContent;
  }
  set infoWindowContent(value) {
    this._infoWindowContent = value;
  }

  private _infoWindow;
  get infoWindow() {
    return this._infoWindow;
  }
  set infoWindow(value) {
    this._infoWindow = value;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  set vehicle(value: Vehicle) {
    this._vehicle = value;
  }
  
  // flag if itinerary marker has been already drawn
  private _itinerary_key: number;
  public get itinerary_key(): number {
    return this._itinerary_key;
  }
  public set itinerary_key(value: number) {
    this._itinerary_key = value;
  }

  private _data: any = {};
  get data(): any {
    return this._data;
  }

  private _autoInfoWindowOpen: boolean = false;
  get autoInfoWindowOpen(): boolean {
    return this._autoInfoWindowOpen;
  }
  set autoInfoWindowOpen(value: boolean) {
    this._autoInfoWindowOpen = value;
  }

  setData(key: string, value: any) {
    this._data[key] = value;
  }

  getData(key: string): any {
    return this._data[key];
  }

  public getGoogleMarker(): any {
    if (!this._googleMarker) {
      this._googleMarker = new google.maps.Marker();
    }
    return this._googleMarker;
  }

  addListener(any, any2) {
    this.events.push(this.getGoogleMarker().addListener(any, any2));
  }

  clearEvents(): void {
    this.events.forEach(
      event => {
        if (event) event.remove()
      }
    );
  }

  // flag for marker, if it has been clicked
  private _isActive: boolean = false;
  public get isActive(): boolean {
    return this._isActive;
  }
  public set isActive(value: boolean) {
    this._isActive = value;
  }
  
  // flag for marker, that is part of current obligation itinerary
  private _isCurrentObligation: boolean = false;
  public get isCurrentObligation(): boolean {
    return this._isCurrentObligation;
  }
  public set isCurrentObligation(value: boolean) {
    this._isCurrentObligation = value;
  }

  // e.g. number of itinerary (could be displayed in info window)
  private _number: number = null;
  public get number(): number {
    return this._number;
  }
  public set number(value: number) {
    this._number = value;
  }
  // hiperling for timocom 
  private _hipperling: string;
  public get hipperling(): string {
    return this._hipperling;
  }
  public set hipperling(value: string) {
    this._hipperling = value;
  }

  private _tonage: string;
  public get tonage(): string {
    return this._tonage;
  }
  public set tonage(value: string) {
    this._tonage = value;
  }
}

