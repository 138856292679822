<div class="col-12 nav-tabs">
  <ul class="nav">
    <li>
      <a (click)="activate('vehicles')" [@activeMenu]="menuActive('vehicles')" 
      class="rightMenuImg" id="dashboard-carList" ></a>
    </li>
    <li>
      <a (click)="activate('messages')" [@activeMenu]="menuActive('messages')" 
      class="rightMenuImg" id="dashboard-msgNew" ></a>
    </li>
  </ul>
</div>
<br/>

<!-- vehicles tab -->
<div [@activeTab]="menuActive('vehicles')" class="container-fluid">
  <div class="row mb-4">
    <label class="col-6">Dashboard <ng-container i18n>vozidel</ng-container></label>

    <div class="col-6">
      <select class="form-control form-control-sm" [(ngModel)]="dashboard_version">
        <option value="light">Lite</option>
        <option value="full">Full</option>
      </select>
    </div>
  </div>
  
  <!-- <div class="row mb-3">
    <div class="col-6">
      <label class="clearfix">
        Filtr dle SPZ/řidiče
        <img src="assets/icons-new/question.svg" alt="question" height="18px" 
        [ngbTooltip]="filterTooltip" placement="right auto" container="body"/>
        <ng-template #filterTooltip>
          <div style="white-space: nowrap">
            kliknutí = Výběr auta<br/>
            kliknutí + ctrl / = Výběr více aut<br/>
            kliknutí + shift + ↑ ↓ = Výběr více aut<br/>
            Nezvoleno nic = Všechna auta
          </div>
        </ng-template>
      </label>
      <div *ngIf="vehicle_filter?.length">
        <a class="pull-right btn btn-danger text-white" (click)="resetVehicleFilter()">
          Reset filtru
        </a>
      </div>
    </div>
    <div *ngIf="vehicles?.length" class="col-6">
      <select multiple type="checkbox" name="vehicle_vehicle_filter"
      class="form-control" id="vehicleFilter" [(ngModel)]="vehicle_filter">
        <option *ngFor="let v of vehicles" [value]="v.car_key">
          {{v.number_plate}} - {{v.driver_name}}
        </option>
      </select>
    </div>
  </div> -->

  <!-- <div class="row mb-3">
    <div class="col-6">
      <label class="clearfix">
        Filtr dle dispečera
        <img src="assets/icons-new/question.svg" alt="question" height="18px" 
        [ngbTooltip]="filterTooltip" placement="right auto" container="body"/>
        <ng-template #filterTooltip>
          <div style="white-space: nowrap">
            kliknutí = Výběr dispečera<br/>
            kliknutí + ctrl / = Výběr více dispečerů<br/>
            kliknutí + shift + ↑ ↓ = Výběr více dispečerů<br/>
            Nezvoleno nic = Všichni dispečeři
          </div>
        </ng-template>
      </label>
      <div *ngIf="vehicle_person_filter?.length">
        <a class="pull-right btn btn-danger text-white" (click)="resetVehiclePersonFilter()">
          Reset filtru
        </a>
      </div>
    </div>
    <div *ngIf="persons?.length" class="col-6">
      <select multiple type="checkbox" class="form-control" id="personFilter" 
      name="vehicle_person_filter" [(ngModel)]="vehicle_person_filter">
        <option *ngFor="let p of persons" [value]="p.person_key">
          {{p.name}}
        </option>
      </select>
    </div>

    <div class="col-sm-12">
      <em>* Více vozidel / dispečerů označíte pomocí ctrl + kliknutí</em>
    </div>
  </div> -->

  <div class="form-group row">
    <label class="col-4 col-sm-5" for="aeaterShow">AETR</label>
    <div class="col-2 col-sm-1 text-center">
      <input type="checkbox" name="aetr_show" id="aeaterShow" value="1" [(ngModel)]="aetr_show" />
    </div>

    <label class="col-4 col-sm-5" for="ganttShow">TruckAgenda</label>
    <div class="col-2 col-sm-1 text-center">
      <input type="checkbox" name="gantt_show" id="ganttShow" value="1" [(ngModel)]="gantt_show" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-4 col-sm-5" for="lastCargoTextShow">
      <ng-container i18n>Info o poslední nakl/vykl</ng-container>
    </label>
    <div class="col-2 col-sm-1 text-center">
      <input type="checkbox" name="last_cargo_text_show" id="lastCargoTextShow" 
      value="1" [(ngModel)]="last_cargo_text_show" />
    </div>

    <label class="col-4 col-sm-5" for="messagesShow">
      TM messenger
    </label>
    <div class="col-2 col-sm-1 text-center">
      <input type="checkbox" name="messages_show" id="messagesShow" 
      value="1" [(ngModel)]="messages_show" />
    </div>
  </div>
  
  <div class="form-group row">
    <label class="col-sm-5" i18n>U vozidel tučně označit</label>
    <div class="col-sm-7">
      <div>
        <input type="radio" name="vehicle_list_highlight" id="radio_names" 
        [(ngModel)]="vehicle_list_highlight" [value]="vehicleListHighlightOptions[0]"/>
        <label class="m-0 ml-2" for="radio_names" i18n>jména řidičů</label>
      </div>
      <div>
        <input type="radio" name="vehicle_list_highlight" id="radio_plates"
        [(ngModel)]="vehicle_list_highlight" [value]="vehicleListHighlightOptions[1]"/>
        <label class="m-0 ml-2" for="radio_plates" i18n>SPZ vozidel</label>
      </div>
    </div>
  </div>
  
  <div class="form-group row">
    <label class="col-sm-5" i18n>Zobrazení trasy na mapě</label>
    <div class="col-sm-7">
      <div>
        <input type="radio" name="vehicle_agenda_map" id="map_only_current"
        [(ngModel)]="vehicle_map_agenda" [value]="vehicleMapAgendaOptions[0]"/>
        <label class="m-0 ml-2" for="map_only_current">
          <ng-container i18n>pouze aktuální naložené zakázky</ng-container>
        </label>
      </div>
      <div>
        <input type="radio" name="vehicle_agenda_map" id="map_all_current" 
        [(ngModel)]="vehicle_map_agenda" [value]="vehicleMapAgendaOptions[1]"/>
        <label class="m-0 ml-2" for="map_all_current">
          <ng-container i18n>všechny zakázky (+-2 dny)</ng-container>
        </label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-4 col-sm-5" for="sound_alert" i18n>Zvukové upozornění</label>
    <div class="col-2 col-sm-1 text-center">
      <input type="checkbox" name="sound_alert" id="sound_alert" 
      value="1" [(ngModel)]="sound_alert" />
    </div>
  </div>
</div>

<!-- messages tab -->
<div [@activeTab]="menuActive('messages')" class="container-fluid">
  <div class="form-group row">
    <label class="col-8" for="messagesDialogShow">
      <ng-container i18n>Nové zprávy na celou obrazovku</ng-container>
    </label>
    <div class="col-2 text-center">
      <input type="checkbox" name="messages_messages_dialog_show" 
      id="messagesDialogShow" value="1" [(ngModel)]="messages_messages_dialog_show"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-8" for="soundNotification">
      <ng-container i18n>Upozornění na novou zprávu</ng-container>
    </label>
    <div class="col-2 text-center">
      <input type="checkbox" name="message_new_message_sound_notification" 
      id="soundNotification" value="1" [(ngModel)]="message_new_message_sound_notification"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-8" for="orderMessagesShow">
      <ng-container i18n>Zobrazení zpráv spedice</ng-container>
    </label>
    <div class="col-2 text-center">
      <input type="checkbox" name="orderMessagesShow" 
      id="orderMessagesShow" value="1" [(ngModel)]="order_messages_show"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-8" for="vehicleMessagesShow">
      <ng-container i18n>Zobrazení zpráv vozidel</ng-container>
    </label>
    <div class="col-2 text-center">
      <input type="checkbox" name="vehicleMessagesShow" 
      id="vehicleMessagesShow" value="1" [(ngModel)]="vehicle_messages_show"/>
    </div>
  </div>
</div>