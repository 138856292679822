import {EventEmitter, Injectable} from "@angular/core";
import {config} from "./angular2-wamp.config";
import {Observable} from "rxjs";

declare var ab: any;

@Injectable()
export class Angular2Wamp {

    static CONNECTION_CLOSED: number = 0;
    static CONNECTION_LOST: number = 1;
    static CONNECTION_LOST_SCHEDULED_RECONNECT: number = 6;
    static CONNECTION_RETRIES_EXCEEDED: number = 2;
    static CONNECTION_UNREACHABLE: number = 3;
    static CONNECTION_UNREACHABLE_SCHEDULED_RECONNECT: number = 5;
    static CONNECTION_UNSUPPORTED: number = 4;

    private _connection: any;

    constructor() {
    }

    private _onOpen: EventEmitter<any> = new EventEmitter();

    get onOpen(): EventEmitter<any> {
        return this._onOpen;
    }

    private _onClose: EventEmitter<any> = new EventEmitter();

    get onClose(): EventEmitter<any> {
        return this._onClose;
    }

    get websocket_connected(): boolean {
        return !this._connection ? false : this._connection._websocket_connected;
    }

    get url(): string {
        return config.url;
    }

    publish(topic, args) {
        let callEventEmitter = new EventEmitter();
        this._connection.publish(topic, args)
            .then(
                (result) => {
                    callEventEmitter.emit(result)
                },
                (error) => {
                    callEventEmitter.error(error);
                }
            );
        return callEventEmitter.asObservable();
    }

    call(procedure, args) {
        let callEventEmitter = new EventEmitter();
        this._connection
            .call(procedure, args)
            .then(
                (result) => {
                    callEventEmitter.emit(result)
                },
                (error) => {
                    callEventEmitter.error(error);
                }
            );
        return callEventEmitter.asObservable();
    }

    connect(): Observable<any> {
        this._connection = new ab.Session(
            config.url,
            (...args) => {
                this._onOpen.emit(args);
            },
            (...args) => {
                this._onClose.emit(args);
            },
            config.options
        );
        return this._onOpen.asObservable();
    }

    close(): Observable<any> {
        this._connection.close();
        return this._onClose.asObservable();
    }

    subscribe(on): Observable<any> {
        let subscribe = new EventEmitter();
        this._connection.subscribe(
            on,
            (on, data) => {
                // here we can log all websockets
                // console.log(data);
                subscribe.emit({
                    on: on,
                    data: data
                });
            }
        );
        return subscribe.asObservable();
    }

    closeConnection() {
        if (this._connection) {
            this._connection.close();
        }
    }
}
