import { InvoiceReminder } from "./invoice-reminder.object";
import { AddressBookItem } from "./address-book-item.object";
import { InvoiceItem } from "./invoice-item.object";
import { Obligation } from "./obligation.object";
import { Person } from "../model/person.object";
import { Attachment } from "./attachment.object";
import { Order } from "./order.object";
import { Email } from "./email.object";
import { InvoiceType } from "../config";

export class Invoice {
  private _invoice_key: number;
  get invoice_key(): number {
    return this._invoice_key;
  }
  set invoice_key(value: number) {
    this._invoice_key = value;
  }

  private _account: string;
  public get account(): string {
    return this._account;
  }
  public set account(value: string) {
    this._account = value;
  }

  private _attachment: Attachment;
  public get attachment(): Attachment {
    return this._attachment;
  }
  public set attachment(value: Attachment) {
    this._attachment = value;
  }

  private _attachment_link: string;
  public get attachment_link(): string {
    return this._attachment_link;
  }
  public set attachment_link(value: string) {
    this._attachment_link = value;
  }

  private _bank: string;
  public get bank(): string {
    return this._bank;
  }
  public set bank(value: string) {
    this._bank = value;
  }
  
  /*private _cancelation: string;
  public get cancelation(): string {
    return this._cancelation;
  }
  public set cancelation(value: string) {
    this._cancelation = value;
  }*/

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _comp_book: AddressBookItem;
  public get comp_book(): AddressBookItem {
    return this._comp_book;
  }
  public set comp_book(value: AddressBookItem) {
    this._comp_book = value;
  }

  private _comp_book_key: number;
  public get comp_book_key(): number {
    return this._comp_book_key;
  }
  public set comp_book_key(value: number) {
    this._comp_book_key = value;
  }
  
  // custom property - could be loaded via https://adisspr.mfcr.cz/adistc/adis/idpr_pub/dpr_info/ws_spdph.faces
  private _comp_book_trustiness: number;
  public get comp_book_trustiness(): number {
    return this._comp_book_trustiness;
  }
  public set comp_book_trustiness(value: number) {
    this._comp_book_trustiness = value;
  }
  
  // custom property
  private _comp_book_maturity: number;
  public get comp_book_maturity(): number {
    return this._comp_book_maturity;
  }
  public set comp_book_maturity(value: number) {
    this._comp_book_maturity = value;
  }

  // custom property
  private _comp_book_contacts: Array<Person> = [];
  public get comp_book_contacts(): Array<Person> {
    return this._comp_book_contacts;
  }
  public set comp_book_contacts(value: Array<Person>) {
    this._comp_book_contacts = value;
  }
  
  private _comp_name: string;
  public get comp_name(): string {
    return this._comp_name;
  }
  public set comp_name(value: string) {
    this._comp_name = value;
  }
  
  private _comp_city: string;
  public get comp_city(): string {
    return this._comp_city;
  }
  public set comp_city(value: string) {
    this._comp_city = value;
  }

  private _comp_country: string;
  public get comp_country(): string {
    return this._comp_country;
  }
  public set comp_country(value: string) {
    this._comp_country = value;
  }
  
  private _comp_street: string;
  public get comp_street(): string {
    return this._comp_street;
  }
  public set comp_street(value: string) {
    this._comp_street = value;
  }

  private _comp_zip: string;
  public get comp_zip(): string {
    return this._comp_zip;
  }
  public set comp_zip(value: string) {
    this._comp_zip = value;
  }
  
  private _constant_symbol: string;
  public get constant_symbol(): string {
    return this._constant_symbol;
  }
  public set constant_symbol(value: string) {
    this._constant_symbol = value;
  }

  private _contact_email: string;
  public get contact_email(): string {
    return this._contact_email;
  }
  public set contact_email(value: string) {
    this._contact_email = value;
  }

  private _contact_name: string;
  public get contact_name(): string {
    return this._contact_name;
  }
  public set contact_name(value: string) {
    this._contact_name = value;
  }

  private _contact_phone: string;
  public get contact_phone(): string {
    return this._contact_phone;
  }
  public set contact_phone(value: string) {
    this._contact_phone = value;
  }

  private _credit_note_key: number;
  public get credit_note_key(): number {
    return this._credit_note_key;
  }
  public set credit_note_key(value: number) {
    this._credit_note_key = value;
  }

  // custom property for credit note invoice
  private _credit_note_invoice: any = null;
  public get credit_note_invoice(): any {
    return this._credit_note_invoice;
  }
  public set credit_note_invoice(value: any) {
    this._credit_note_invoice = value;
  }

  // custom property for invoice to be paired with repaired invoice
  private _credit_note_invoice_from: any = null;
  public get credit_note_invoice_from(): any {
    return this._credit_note_invoice_from;
  }
  public set credit_note_invoice_from(value: any) {
    this._credit_note_invoice_from = value;
  }

  private _currency: string;
  get currency(): string {
    return this._currency;
  }
  set currency(value: string) {
    this._currency = value;
  }

  private _description: string;
  public get description(): string {
    return this._description;
  }
  public set description(value: string) {
    this._description = value;
  }

  private _emailed: string;
  public get emailed(): string {
    return this._emailed;
  }
  public set emailed(value: string) {
    this._emailed = value;
  }

  private _email_msg_ids: Array<string> = [];
  public get email_msg_ids(): Array<string> {
    return this._email_msg_ids;
  }
  public set email_msg_ids(value: Array<string>) {
    this._email_msg_ids = value;
  }
  
  // OBSOLETE
  // array that should be sorted exactly as array _email_msg_ids
  // private _email_msg_headers: Array<Email> = [];
  // public get email_msg_headers(): Array<Email> {
  //     return this._email_msg_headers;
  // }
  // public set email_msg_headers(value: Array<Email>) {
  //     this._email_msg_headers = value;
  // }

  private _email_headers: Array<Email> = [];
  public get email_headers(): Array<Email> {
    return this._email_headers;
  }
  public set email_headers(value: Array<Email>) {
    this._email_headers = value;
  }

  private _exchange_rate: number;
  public get exchange_rate(): number {
    return this._exchange_rate;
  }
  public set exchange_rate(value: number) {
    this._exchange_rate = value;
  }
  
  // custom
  private _exchange_rate_tmp: number;
  public get exchange_rate_tmp(): number {
    return this._exchange_rate_tmp;
  }
  public set exchange_rate_tmp(value: number) {
    this._exchange_rate_tmp = value;
  }
  
  private _factoring: boolean;
  public get factoring(): boolean {
    return this._factoring;
  }
  public set factoring(value: boolean) {
    this._factoring = value;
  }

  private _fullfilment: string;
  public get fullfilment(): string {
    return this._fullfilment;
  }
  public set fullfilment(value: string) {
    this._fullfilment = value;
    if (this._fullfilment) {
      this._fullfilmentDate = new Date(this._fullfilment);
      if (this._order_invoice_maturity && this._fullfilmentDate) {
        this._order_invoice_maturity_diff = false;
        // fullfilment + order.invoice_maturity
        let tmpDate: Date = new Date(
          this._fullfilmentDate.getTime() + this._order_invoice_maturity*24*60*60*1000
        );
        
        if (this._maturityDate) {
          if (this._maturityDate.getFullYear() != tmpDate.getFullYear() 
            || this._maturityDate.getMonth() != tmpDate.getMonth() 
            || this._maturityDate.getDate() != tmpDate.getDate() 
          ) {
            // diff flag
            this._order_invoice_maturity_diff = true;
          }
        }
      }
    }
  }

  private _fullfilmentDate: Date;
  public get fullfilmentDate(): Date {
    return this._fullfilmentDate;
  }

  private _iban: string;
  public get iban(): string {
    return this._iban;
  }
  public set iban(value: string) {
    this._iban = value;
  }
  
  private _issued: string;
  public get issued(): string {
    return this._issued;
  }
  public set issued(value: string) {
    this._issued = value;
    if (this._issued) {
      this._issuedDate = new Date(this._issued);
    }
  }

  private _issuedDate: Date;
  public get issuedDate(): Date {
    return this._issuedDate;
  }
  
  /*private _isdoced: string;
  public get isdoced(): string {
    return this._isdoced;
  }
  public set isdoced(value: string) {
    this._isdoced = value;
  }*/
  
  private _isdoc_uuid: string;
  public get isdoc_uuid(): string {
    return this._isdoc_uuid;
  }
  public set isdoc_uuid(value: string) {
    this._isdoc_uuid = value;
  }

  private _items: Array<InvoiceItem> = [];
  public get items(): Array<InvoiceItem> {
    return this._items;
  }
  public set items(value: Array<InvoiceItem>) {
    this._items = value;
  }

  private _mail_city: string;
  public get mail_city(): string {
    return this._mail_city;
  }
  public set mail_city(value: string) {
    this._mail_city = value;
  }

  private _mail_country: string;
  public get mail_country(): string {
    return this._mail_country;
  }
  public set mail_country(value: string) {
    this._mail_country = value;
  }

  private _mail_name: string;
  public get mail_name(): string {
    return this._mail_name;
  }
  public set mail_name(value: string) {
    this._mail_name = value;
  }
  
  private _mail_street: string;
  public get mail_street(): string {
    return this._mail_street;
  }
  public set mail_street(value: string) {
    this._mail_street = value;
  }
  
  private _mail_zip: string;
  public get mail_zip(): string {
    return this._mail_zip;
  }
  public set mail_zip(value: string) {
    this._mail_zip = value;
  }

  private _maturity: string;
  get maturity(): string {
    return this._maturity;
  }
  set maturity(value: string) {
    this._maturity = value;
    if (this._maturity) {
      this._maturityDate = new Date(this._maturity);
      // this._maturityDate10 = new Date(this._maturity);
      // this._maturityDate10.setDate(this._maturityDate.getDate() + 10);

      // compute remaining days
      let currentDate = new Date().getTime();
      let maturity = this.maturityDate.getTime();
      const diffTime = maturity - currentDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this._maturityDaysRemaining = diffDays;

      // order_invoice maturity difference
      if (this._order_invoice_maturity && this._fullfilmentDate) {
        this._order_invoice_maturity_diff = false;
        // fullfilment + order.invoice_maturity
        let tmpDate: Date = new Date(
          this._fullfilmentDate.getTime() + this._order_invoice_maturity*24*60*60*1000
        );
        
        if (this._maturityDate) {
          if (this._maturityDate.getFullYear() != tmpDate.getFullYear() 
            || this._maturityDate.getMonth() != tmpDate.getMonth() 
            || this._maturityDate.getDate() != tmpDate.getDate() 
          ) {
            // diff flag
            this._order_invoice_maturity_diff = true;
          }
        }
      }
    }
  }
  
  private _maturityDate: Date;
  public get maturityDate(): Date {
    return this._maturityDate;
  }

  private _maturityDaysRemaining: number;
  public get maturityDaysRemaining(): number {
    return this._maturityDaysRemaining;
  }

  // custom constant, because 10 days after maturity is the color breakpoint (used to be)
  // private _maturityDate10: Date;
  // public get maturityDate10(): Date {
  //   return this._maturityDate10;
  // }
  
  private _number: number;
  public get number(): number {
    return this._number;
  }
  public set number(value: number) {
    this._number = value;
  }

  private _obligations: Array<Obligation> = [];
  public get obligations(): Array<Obligation> {
    return this._obligations;
  }
  public set obligations(value: Array<Obligation>) {
    this._obligations = value;
  }

  private _orders: Array<Order> = [];
  get orders(): Array<Order> {
    return this._orders;
  }
  set orders(value: Array<Order>) {
    this._orders = value;

    if (this._orders && this._orders.length) {
      this._order_invoice_maturity = this._orders[0].invoice_maturity;
      if (this._order_invoice_maturity && this._fullfilmentDate) {
        this._order_invoice_maturity_diff = false;
        // fullfilment + order.invoice_maturity
        let tmpDate: Date = new Date(
          this._fullfilmentDate.getTime() + this._order_invoice_maturity*24*60*60*1000
        );
        
        if (this._maturityDate) {
          if (this._maturityDate.getFullYear() != tmpDate.getFullYear() 
            || this._maturityDate.getMonth() != tmpDate.getMonth() 
            || this._maturityDate.getDate() != tmpDate.getDate() 
          ) {
            // diff flag
            this._order_invoice_maturity_diff = true;
          }
        }
      }
    }
  }

  // custom
  private _order_invoice_maturity: number = null;
  public get order_invoice_maturity(): number {
    return this._order_invoice_maturity;
  }
  public set order_invoice_maturity(value: number) {
    this._order_invoice_maturity = value;
  }

  // custom
  private _order_invoice_maturity_diff: boolean = false;
  public get order_invoice_maturity_diff(): boolean {
    return this._order_invoice_maturity_diff;
  }
  public set order_invoice_maturity_diff(value: boolean) {
    this._order_invoice_maturity_diff = value;
  }


  private _payment: string;
  public get payment(): string {
    return this._payment;
  }
  public set payment(value: string) {
    this._payment = value;
    if (this._payment) {
      this._paymentDate = new Date(this._payment);
      
      if (this.maturityDate) {
        let payment = this.paymentDate.getTime();
        let maturity = this.maturityDate.getTime();
        const diffTime = payment - maturity;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this._paymentAfterMaturity = diffDays;
      }
    }
  }

  private _paymentDate: Date;
  public get paymentDate(): Date {
    return this._paymentDate;
  }
  
  private _paymentAfterMaturity: number;
  public get paymentAfterMaturity(): number {
    return this._paymentAfterMaturity;
  }

  private _payment_method: string;
  public get payment_method(): string {
    return this._payment_method;
  }
  public set payment_method(value: string) {
    this._payment_method = value;
  }

  private _price: number = 0;
  get price(): number {
    return this._price;
  }
  set price(value: number) {
    this._price = value;
  }

  private _price_total: number = 0;
  public get price_total(): number {
    return this._price_total;
  }
  public set price_total(value: number) {
    this._price_total = value;
  }
  
  private _price_paid_incl_vat: number = 0;
  public get price_paid_incl_vat(): number {
    return this._price_paid_incl_vat;
  }
  public set price_paid_incl_vat(value: number) {
    this._price_paid_incl_vat = value;
  }

  // custom property - total count of discounts in invoice items
  private _price_discount: number = 0;
  public get price_discount(): number {
    return this._price_discount;
  }
  public set price_discount(value: number) {
    this._price_discount = value;
  }
  
  private _printed: boolean;
  public get printed(): boolean {
    return this._printed;
  }
  public set printed(value: boolean) {
    this._printed = value;
  }

  /*private _proforma_number: string;
  public get proforma_number(): string {
    return this._proforma_number;
  }
  public set proforma_number(value: string) {
    this._proforma_number = value;
  }*/

  private _received_invoice_no: string;
  public get received_invoice_no(): string {
    return this._received_invoice_no;
  }
  public set received_invoice_no(value: string) {
    this._received_invoice_no = value;
  }

  private _reminders: Array<InvoiceReminder> = [];
  public get reminders(): Array<InvoiceReminder> {
    return this._reminders;
  }
  public set reminders(value: Array<InvoiceReminder>) {
    this._reminders = value;
  }
  
  // custom property for signalizing that this invoice should not been remindered now
  private _reminderTooEarly: boolean = false;
  public get reminderTooEarly(): boolean {
    return this._reminderTooEarly;
  }
  public set reminderTooEarly(value: boolean) {
    this._reminderTooEarly = value;
  }

  // custom property for confirmation of sending reminder (i.e. reminder does not fir to numbers in settings/docs - payment_reminder_mail_*)
  private _reminderConfirmation: boolean = false;
  public get reminderConfirmation(): boolean {
    return this._reminderConfirmation;
  }
  public set reminderConfirmation(value: boolean) {
    this._reminderConfirmation = value;
  }

  // custom property for subject of reminder email
  private _paymentReminderMailSubject: string;
  public get paymentReminderMailSubject(): string {
    return this._paymentReminderMailSubject;
  }
  public set paymentReminderMailSubject(value: string) {
    this._paymentReminderMailSubject = value;
  }
  
  // custom property for text of reminder email
  private _paymentReminderMailText: string;
  public get paymentReminderMailText(): string {
    return this._paymentReminderMailText;
  }
  public set paymentReminderMailText(value: string) {
    this._paymentReminderMailText = value;
  }

  private _series: number;
  public get series(): number {
    return this._series;
  }
  public set series(value: number) {
    this._series = value;
  }

  private _specific_symbol: string;
  public get specific_symbol(): string {
    return this._specific_symbol;
  }
  public set specific_symbol(value: string) {
    this._specific_symbol = value;
  }
  
  private _swift: string;
  public get swift(): string {
    return this._swift;
  }
  public set swift(value: string) {
    this._swift = value;
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }
  
  private _updated_by: string;
  public get updated_by(): string {
    return this._updated_by;
  }
  public set updated_by(value: string) {
    this._updated_by = value;
  }
  
  private _updated_time: string;
  public get updated_time(): string {
    return this._updated_time;
  }
  public set updated_time(value: string) {
    this._updated_time = value;
  }

  private _variable_symbol: string;
  get variable_symbol(): string {
    return this._variable_symbol;
  }
  set variable_symbol(value: string) {
    this._variable_symbol = value;
    if (this._variable_symbol && this._number) {
      if (!this._variable_symbol.endsWith(this._number.toString())) {
        this._variable_symbol_number_diff = true;
      }
    }
  }

  // variable symbol does not end with invoice number
  private _variable_symbol_number_diff: boolean = false;
  public get variable_symbol_number_diff(): boolean {
    return this._variable_symbol_number_diff;
  }
  
  private _vat1: number = 0;
  public get vat1(): number {
    return this._vat1;
  }
  public set vat1(value: number) {
    this._vat1 = value;
  }
  
  private _vat1_rate: number = 0;
  public get vat1_rate(): number {
    return this._vat1_rate;
  }
  public set vat1_rate(value: number) {
    this._vat1_rate = value;
  }
  
  private _vat2: number = 0;
  public get vat2(): number {
    return this._vat2;
  }
  public set vat2(value: number) {
    this._vat2 = value;
  }

  private _vat2_rate: number = 0;
  public get vat2_rate(): number {
    return this._vat2_rate;
  }
  public set vat2_rate(value: number) {
    this._vat2_rate = value;
  }
  
  private _vat_home: number = 0;
  public get vat_home(): number {
    return this._vat_home;
  }
  public set vat_home(value: number) {
    this._vat_home = value;
  }

  // custom property for color in list of invoices
  private _backgroundColorClass: string = '';
  get backgroundColorClass(): string {
    return this._backgroundColorClass;
  }
  set backgroundColorClass(value: string) {
    this._backgroundColorClass = value;
  }
  
  private _backgroundColorRowClass: string = '';
  get backgroundColorRowClass(): string {
    return this._backgroundColorRowClass;
  }
  set backgroundColorRowClass(value: string) {
    this._backgroundColorRowClass = value;
  }

  // custom property for total vat displayed in invoice detail
  private _totalVat: number = 0;
  public get totalVat(): number {
    return this._totalVat;
  }
  public set totalVat(value: number) {
    this._totalVat = value;
  }

  private _year: number;
  public get year(): number {
    return this._year;
  }
  public set year(value: number) {
    this._year = value;
  }

  private _web_origin: string;
  public get web_origin(): string {
    return this._web_origin;
  }
  public set web_origin(value: string) {
    this._web_origin = value;
  }

  // flag for checkboxes to provide action for multiple invoices
  private _marked: boolean = false;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }

  public get invoiceNumberFormatted(): string {
    let type: string = '';
    if (this._type == InvoiceType.ISSUED) type = 'FA';
    else if (this._type == InvoiceType.RECEIVED) type = 'PF';
    else if (this._type == InvoiceType.CREDIT_NOTE) type = 'DO';

    if (this._variable_symbol) {
      return type + this._variable_symbol;
    }
    if (this._series && this._year && this._number) {
      return type + this._series.toString() + this._year.toString() + this._number.toString();
    }
    return '';
  }

  public get invoiceNumber(): string {
    if (this._variable_symbol) {
      return this._variable_symbol;
    }
    else if (this._series && this._year && this._number) {
      return this._series.toString() + this._year.toString() + this._number.toString();
    }
    return null;
  }

  private _previewPDF: any;
  public get previewPDF(): any {
    return this._previewPDF;
  }
  public set previewPDF(value: any) {
    this._previewPDF = value;
  }

  public get apiObject(): any {
    return {
      account: this.account,
      bank: this.bank,
      comp_book_key: this.comp_book_key,
      comp_city: this.comp_city,
      comp_country: this.comp_country,
      comp_name: this.comp_name,
      comp_street: this.comp_street,
      comp_zip: this._comp_zip,
      constant_symbol: this.constant_symbol,
      contact_email: this.contact_email,
      contact_name: this.contact_name,
      contact_phone: this.contact_phone,
      credit_note_key: this._credit_note_key,
      currency: this.currency,
      description: this.description,
      exchange_rate: this.exchange_rate,
      factoring: this.factoring,
      fullfilment: this.fullfilment ? this.fullfilment : null,
      iban: this.iban,
      issued: this.issued ? this.issued : null,
      mail_city: this._mail_city,
      mail_country: this._mail_country,
      mail_name: this.mail_name,
      mail_street: this.mail_street,
      mail_zip: this.mail_zip,
      maturity: this.maturity,
      // number: this._number,
      payment: this.payment ? this.payment : null,
      payment_method: this.payment_method,
      price: this.price,
      price_total: this.price_total,
      price_paid_incl_vat: this.price_paid_incl_vat,
      printed: this.printed,
      received_invoice_no: this.received_invoice_no ? this.received_invoice_no : this.variable_symbol,
      series: this.series,
      specific_symbol: this.specific_symbol,
      swift: this.swift,
      type: this.type,
      variable_symbol: this.variable_symbol,
      vat1: this.vat1,
      vat1_rate: this.vat1_rate,
      vat2: this.vat2,
      vat2_rate: this.vat2_rate,
      vat_home: this.vat_home,
      year: this.year
    };
  }

  // creates copy of this invoice object
  get invoiceCopy(): Invoice {
    let invoice: Invoice = new Invoice();
    invoice.invoice_key = this.invoice_key;
    invoice.account = this.account;
    invoice.bank = this.bank;
    invoice.comp_book_key = this.comp_book_key;
    invoice.comp_city = this.comp_city;
    invoice.comp_country = this.comp_country;
    invoice.comp_name = this.comp_name;
    invoice.comp_street = this.comp_street;
    invoice.comp_zip = this._comp_zip;
    invoice.constant_symbol = this.constant_symbol;
    invoice.contact_email = this.contact_email;
    invoice.contact_name = this.contact_name;
    invoice.contact_phone = this.contact_phone;
    invoice.credit_note_key = this._credit_note_key;
    invoice.currency = this.currency;
    invoice.description = this.description;
    invoice.exchange_rate = this.exchange_rate;
    invoice.factoring = this.factoring;
    invoice.fullfilment = this.fullfilment ? this.fullfilment : null;
    invoice.iban = this.iban;
    invoice.issued = this.issued ? this.issued : null;
    invoice.mail_city = this._mail_city;
    invoice.mail_country = this._mail_country;
    invoice.mail_name = this.mail_name;
    invoice.mail_street = this.mail_street;
    invoice.mail_zip = this.mail_zip;
    invoice.maturity = this.maturity;
    // number: this._number,
    invoice.payment = this.payment ? this.payment : null;
    invoice.payment_method = this.payment_method;
    invoice.price = this.price;
    invoice.price_total = this.price_total;
    invoice.price_paid_incl_vat = this.price_paid_incl_vat;
    invoice.printed = this.printed;
    invoice.received_invoice_no = this.received_invoice_no ? this.received_invoice_no : this.variable_symbol;
    invoice.series = this.series;
    invoice.specific_symbol = this.specific_symbol;
    invoice.swift = this.swift;
    invoice.type = this.type;
    invoice.variable_symbol = this.variable_symbol;
    invoice.vat1 = this.vat1;
    invoice.vat1_rate = this.vat1_rate;
    invoice.vat2 = this.vat2;
    invoice.vat2_rate = this.vat2_rate;
    invoice.vat_home = this.vat_home;
    invoice.year = this.year;
    return invoice;
  }
}
