
export class AddressBookItemAttachment {
  private _attach_key: number;
  public get attach_key(): number {
    return this._attach_key;
  }
  public set attach_key(value: number) {
    this._attach_key = value;
  }
  
  private _book_key: number;
  public get book_key(): number {
    return this._book_key;
  }
  public set book_key(value: number) {
    this._book_key = value;
  }
  
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _currency: string = null;
  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
  }
  
  private _description: string = null;
  public get description(): string {
    return this._description;
  }
  public set description(value: string) {
    this._description = value;
  }
  
  private _price: number = null;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
  }
  
  private _type: string = null;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  public readonly TYPE_NAMES: any = {
    'I': $localize`Pojištění`,
    'C': $localize`Smlouva`
  };
  
  private _validity: string = null;
  public get validity(): string {
    return this._validity;
  }
  public set validity(value: string) {
    this._validity = value;
  }

  private _file: any;
  public get file(): any {
    return this._file;
  }
  public set file(value: any) {
    this._file = value;
  }

  get apiObject(): any {
    return {
      currency: this._currency,
      description: this._description,
      price: this._price,
      type: this._type,
      validity: this._validity
    }
  }
}