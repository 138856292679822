<div class="container-fluid">
  <div class="row table-bordered">
    <div class="col-2 table-bordered">SPZ:</div>
    <div class="col-2 table-bordered">1P3-52-48</div>
    <div class="col-1 table-bordered"></div>
    <div class="col-3 table-bordered"></div>
    <div class="col-4 table-bordered"></div>

    <div class="col-2 table-bordered">První řidič:</div>
    <div class="col-2 table-bordered"> Novotny</div>
    <div class="col-1 table-bordered">&nbsp;</div>
    <div class="col-3 table-bordered"><a href="tel:420777874569">420777874569</a></div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Druhý řidič:</div>
    <div class="col-2 table-bordered">Prokop</div>
    <div class="col-1 table-bordered">&nbsp;</div>
    <div class="col-3 table-bordered"><a href="tel:420777547146">420777547146</a></div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">&nbsp;</div>
    <div class="col-2 table-bordered">&nbsp;</div>
    <div class="col-1 table-bordered">&nbsp;</div>
    <div class="col-3 table-bordered">&nbsp;</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Poslední diagnostika:</div>
    <div class="col-2 table-bordered">21.10.2016&nbsp; 8:58:23</div>
    <div class="col-1 table-bordered"> </div>
    <div class="col-3 table-bordered"> </div>
    <div class="col-4 table-bordered no-padding text-center"><a href="#" class="btn btn-primary" (click)="newDiagnosticRequest()">Nová diagnostika</a></div>

    <div class="col-2 table-bordered">Doručení dotazu:</div>
    <div class="col-2 table-bordered">21.10.2016&nbsp; 8:58:37</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">zařízení žije&nbsp; dotaz byl do zařízení doručen</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered">Doručená odpověď:</div>
    <div class="col-2 table-bordered">21.10.2016&nbsp; 8:59:49</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">sw TM běží OK</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered">Datum a čas zařízení:</div>
    <div class="col-2 table-bordered">21.10.2016&nbsp; 8:59:41</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">čas v zařízení je správně</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered"> Model zařízení:</div>
    <div class="col-2 table-bordered"> <a href="https://www.google.cz/webhp?sourceid=chrome-instant&amp;ion=1&amp;espv=2&amp;ie=UTF-8#q=Huawei+Y210+-+100" target="_blank">Huawei Y210 - 100</a></div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">Tento model vykazuje 98% spolehlivost u 425 uživatelů</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered">Verze OS Android:</div>
    <div class="col-2 table-bordered"> 2.3</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">Verze OS je starší a doporučujeme zvážit obnovu tohoto HW</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered"> Volná interní paměť:</div>
    <div class="col-2 table-bordered"> 93 MB</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">interní paměti je dostatek</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered"> Volná externí paměť:</div>
    <div class="col-2 table-bordered"> 0 MB</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered">externí paměti je nedostatek</div>
    <div class="col-4 table-bordered"> externí paměť je buď zcela zaplněna nebo chybí SD karta</div>

    <div class="col-2 table-bordered"> Verze TruckManager:</div>
    <div class="col-2 table-bordered"> A36</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered"> verze sw TruckManager je neaktuální,</div>
    <div class="col-4 table-bordered"> nainstalujte verzi A38</div>

    <div class="col-2 table-bordered"> Použitá SIM karta:</div>
    <div class="col-2 table-bordered"> 420777568941</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">SIM karta poskytovatele TM&nbsp; operátor Vodafone</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered"> APN - přístupový bod k internetu:</div>
    <div class="col-2 table-bordered"> internet</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered">nesprávně nastavený přístupový bod - má být cma.eso-tm</div>
    <div class="col-4 table-bordered no-padding text-center"><a href="#" class="btn btn-primary" (click)="setApnRequest()">Nastavit APN</a></div>

    <div class="col-2 table-bordered"> Používaná síť operátora:</div>
    <div class="col-2 table-bordered"> 26201</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">TeleFlex&nbsp; - (DE)<br>operátor poskytuje data</div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered"> Kvalita signálu:</div>
    <div class="col-2 table-bordered"> 23 %</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered">V dané oblasti je signál slabý</div>
    <div class="col-4 table-bordered"> čpatný signál - přejeďte jinam nebo zvolte ručně jiného operátora (nedoporučujeme)</div>

    <div class="col-2 table-bordered"> IP adresa:</div>
    <div class="col-2 table-bordered"> --</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered">IP adresa nedostupná</div>
    <div class="col-4 table-bordered">SIM karta nemá přístup k datové siťi (problém s APN nebo operátorem)</div>

    <div class="col-2 table-bordered"> Doba běhu zařízení po restartu:</div>
    <div class="col-2 table-bordered"> 157 hod</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered">zařízení běží bez restartu příliš dlouho</div>
    <div class="col-4 table-bordered"> proveďte restart zařízení - doporučeno každých 72hod</div>

    <div class="col-2 table-bordered"> Doba běhu aplikace TruckManager:</div>
    <div class="col-2 table-bordered"> 2 hod</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered">doba běhu TruckManager se neshoduje s během zařízení</div>
    <div class="col-4 table-bordered">	buďto řidič aplikaci TM ručně ukončuje nebo je jedná o problém s daty nebo kompatibilitou - přejete-li si bude řidiče kontaktovat holtine</div>

    <div class="col-2 table-bordered"> Datové přenosy zapnuty:</div>
    <div class="col-2 table-bordered"> ANO</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered"> </div>
    <div class="col-4 table-bordered"></div>

    <div class="col-2 table-bordered"> Roaming zapnut:</div>
    <div class="col-2 table-bordered"> ANO</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered"> </div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered"> Režim sledování mimo napájení:</div>
    <div class="col-2 table-bordered"> NE</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered"> </div>
    <div class="col-4 table-bordered"> </div>

    <div class="col-2 table-bordered">Napájení:</div>
    <div class="col-2 table-bordered"> NE</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered"> napájení není připojeno</div>
    <div class="col-4 table-bordered"> připojte zařízení do napájení nebo zkontrolujte funkčnost nabíječka (min. výkon 2A)</div>

    <div class="col-2 table-bordered">Stav baterie:</div>
    <div class="col-2 table-bordered"> 9 %</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered"> stav baterie je nizký</div>
    <div class="col-4 table-bordered"> připojte neprodleně zařízení k napaájení</div>

    <div class="col-2 table-bordered">Teplota baterie:</div>
    <div class="col-2 table-bordered"> 67 °C</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/navigate_cross_red.svg" alt="navigate_cross_red" height="18px"/></div>
    <div class="col-3 table-bordered"> twplota baterie je zvýšená</div>
    <div class="col-4 table-bordered"> teplota múže být zvýšena slunečním svitem - zakryjte zařízení</div>

    <div class="col-2 table-bordered">GPS signál zapnut:</div>
    <div class="col-2 table-bordered"> ANO</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">&nbsp;</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Čas GPS signálu/fixu:</div>
    <div class="col-2 table-bordered"> 21.10.2016&nbsp; 8:59:49</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">&nbsp;</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Používaná korekce času GPS:</div>
    <div class="col-2 table-bordered"> 0 min</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">&nbsp;</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Souřadnice GPS:</div>
    <div class="col-2 table-bordered"> <a href="https://www.google.cz/maps/place/49.548+15.584" target="_blank">49.548, 15.584</a></div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">místo aktuální polohy (přesnost cca 150m)</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Počet používaných satelitů GPS:</div>
    <div class="col-2 table-bordered"> 8 satelitů</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">&nbsp;</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Nepřesnost GPS signálu:</div>
    <div class="col-2 table-bordered"> 29 m</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">&nbsp;</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-2 table-bordered">Aktuální rychlost:</div>
    <div class="col-2 table-bordered"> 59 km/h</div>
    <div class="col-1 table-bordered"><img src="assets/icons-new/check-solid.svg" alt="check-solid" height="18px"/></div>
    <div class="col-3 table-bordered">&nbsp;</div>
    <div class="col-4 table-bordered">&nbsp;</div>

    <div class="col-12 table-bordered">Pane Novotný: pro správnou funkci aplikace TM proveďte toto: restartujte zařízení, připojte je do napájení a chraňte před sluncem.
      <div class="text-center">
        <a href="#" class="btn btn-primary" (click)="enlightenDriver()">Odeslat SMS řidiči</a>
      </div>
    </div>

    <div class="col-12 table-bordered">Pokud jste na základě výše uvedených informací nevyřešili problém sami požádejte hotline o pomoc. Za neoprávněné nahlášení poruchy nebo problém, který jste mohli snadno sami vyřesit (např. zařízení není v napájení nebo řidič ještě nezkusil restart) <strong>může být účtován poplatek 1,-EUR</strong>
      <div class="text-center">
        <a href="#" class="btn btn-primary" (click)="reportProblem()">Nahlásit problém</a>
      </div>
    </div>
  </div>
</div>