import { DateTools } from '../tools/DateTools';

export class InvoiceReminder {
  private _invoice_key: number;
  get invoice_key(): number {
    return this._invoice_key;
  }
  set invoice_key(value: number) {
    this._invoice_key = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _number: number;
  public get number(): number {
    return this._number;
  }
  public set number(value: number) {
    this._number = value;
  }
  
  private _created: string;
  public get created(): string {
    return this._created;
  }
  public set created(value: string) {
    this._created = value;
    if (this._created) {
      this._createdDate = DateTools.apiIsoToDate(this._created);
    }
  }

  private _createdDate: Date;
  public get createdDate(): Date {
    return this._createdDate;
  }
  public set createdDate(value: Date) {
    this._createdDate = value;
  }
  
  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  private _addressee: string;
  public get addressee(): string {
    return this._addressee;
  }
  public set addressee(value: string) {
    this._addressee = value;
  }

  public get apiObject(): any {
    return {
      addressee: this._addressee,
      number: this._number,
      type: this._type,
    };
  }
}