import {AetrActivityDataInterface} from "../interface/aetr-activity-data.interface";
import {DateTools} from "../tools/DateTools";
import {AetrDayActivitiesInterface} from "../interface/aetr-day-activities.interface";

export class AetrHistoryCollectionObject {

  private _data: Array<AetrActivityDataInterface> = [];

  private _byDaysActivities: Array<AetrDayActivitiesInterface>;

  private _dateMap: any = {};

  constructor(activities: Array<AetrActivityDataInterface>) {
    activities.forEach(
      activity => {
        this.addActivity(activity);
      }
    );
    this.createData();
  }

  static dateToString(date: Date): string {
    return DateTools.formatLocaleString(date, '%day.%month.%year');
  }

  getActivityByDays(): Array<AetrDayActivitiesInterface> {
    return this._byDaysActivities;
  }

  getActivityByDate(date: Date): AetrDayActivitiesInterface {
    return this._dateMap[AetrHistoryCollectionObject.dateToString(date)];
  }

  getActivityByDateString(date: string): AetrDayActivitiesInterface {
    return this._dateMap[date];
  }

  private addActivity(data: AetrActivityDataInterface) {
    this._data.push(data);
  }

  private createData() {
    this._byDaysActivities = [];
    this._dateMap = {};
    let building: any = {};
    building[AetrHistoryCollectionObject.dateToString(new Date())] = [];
    for (let i = 1; i <= 20; i++) {
      let start = new Date();
      start.setDate(start.getDate() - i);
      building[AetrHistoryCollectionObject.dateToString(start)] = [];
    }

    this._data.forEach(
      activity => {
        let start = DateTools.isoFix(activity.start_date);
        let end = DateTools.isoFix(activity.end_date);

        // ignore 9hour Pauses (32400sec) - should be counted as Sleep 
        let diff: number = (end.getTime() - start.getTime()) / 1000;

        // set only 32000 - few minutes toleration....
        const nineHours: number = 32000;

        if (diff < nineHours) {
          if (building[AetrHistoryCollectionObject.dateToString(start)] && AetrHistoryCollectionObject.dateToString(start) === AetrHistoryCollectionObject.dateToString(end)) {
            building[AetrHistoryCollectionObject.dateToString(start)].push(activity);
          }
          else if (AetrHistoryCollectionObject.dateToString(start) !== AetrHistoryCollectionObject.dateToString(end)) {
            let fromDate = new Date(start.getTime());
            while (AetrHistoryCollectionObject.dateToString(fromDate) !== AetrHistoryCollectionObject.dateToString(end)) {
              let tomorrow = new Date(fromDate.getTime());
              tomorrow.setDate(fromDate.getDate() + 1);
              tomorrow.setHours(0, 0, 0, 0);
              if (!building[AetrHistoryCollectionObject.dateToString(fromDate)]) {
                building[AetrHistoryCollectionObject.dateToString(fromDate)] = [];
              }
  
              building[AetrHistoryCollectionObject.dateToString(fromDate)].push({
                "start_date": DateTools.toIsoWithoutMilisec(fromDate),//	 time when segments started
                "end_date": DateTools.toIsoWithoutMilisec(tomorrow),	// time when segments ended
                "type": activity.type, //					# segment type (S = sleep/rest, D = driving)
                "length": (tomorrow.getTime() - fromDate.getTime()) / 1000,//				# segment duration in seconds
                "length_dbg": "",//
                "distance": activity.length,				//	# distance travelled (in km)
                "first_driver": activity.first_driver,		//		# which driver was driving (true if the first driver)
                "drive_style": activity.drive_style,//
                "aetr_error": activity.aetr_error,//				# list of error messages (for details see above)
                "aetr_alt_error": activity.aetr_alt_error			//# list of error messages if drivers have swapped in driving in time
              });
              fromDate.setDate(fromDate.getDate() + 1);
            }
          }
        }
      }
    );

    let byDateTypePercentage: any = {};
    for (let key in building) {
      let dayActivity: Array<AetrActivityDataInterface> = building[key];
      if (!byDateTypePercentage[key]) {
        byDateTypePercentage[key] = {
          total: 0,
          total_without_sleep: 0
        };
      }

      dayActivity.forEach(
        (activity) => {
          if (!isNaN(activity.length)) {
            if (!byDateTypePercentage[key][activity.type]) {
              byDateTypePercentage[key][activity.type] = {
                time: 0,
              };
            }
            byDateTypePercentage[key][activity.type].time += activity.length;
            byDateTypePercentage[key].total += activity.length;
            byDateTypePercentage[key].total_without_sleep += activity.length;
          }
        }
      )

    }
    let percentages = {};
    let day: number = 24 * 60 * 60;
    for (let key in byDateTypePercentage) {
      let total = byDateTypePercentage[key].total_without_sleep;
      if (!percentages[key]) {
        percentages[key] = {};
      }
      for (let key2 in byDateTypePercentage[key]) {
        if (key2 === 'total') {
          continue;
        }

        percentages[key][key2] = (Math.round((byDateTypePercentage[key][key2].time / day) * 10000)) / 100;
      }
      percentages[key].activity_percentage = Math.round((total / day) * 10000) / 100;
    }

    for (let key in building) {
      building[key].sort(
        (a, b) => {
          return a > b ? 1 : a < b ? -1 : 0
        }
      );
      let percentage = [];
      if (percentages[key]) {
        ['S', 'P', 'D'].forEach(
          type => {
            if (percentages[key][type]) {
              let partPercentage = {
                type: type,
                length: percentages[key][type],
                time: byDateTypePercentage[key][type].time,
                timeModified: byDateTypePercentage[key][type].time
              };
              percentage.push(partPercentage);
              if (type === 'P') { //vykon is accumulated time of all day activity but only in time not % as total activity
                if (byDateTypePercentage[key]['D']) {
                  partPercentage.timeModified += byDateTypePercentage[key]['D'].time;
                }
              }
            }
          }
        );
      }
      let length = this._byDaysActivities.push({
        date: key,
        percentage: percentage,
        activity_total_percentage: percentages[key].activity_percentage,
        activity_time: percentages[key].total
      });
      this._dateMap[key] = this._byDaysActivities[length - 1];
    }
  }
}