export class Token {

  static TYPE_BEARER: string = 'bearer';

  constructor(
    private _token: string,
    private _expire: Date,
    private _type: string
  ) {
    if (!_token || _token.length === 0) {
      throw new Error('Empty token cannot be');
    }
    if ([Token.TYPE_BEARER].indexOf(_type) === -1) {
      throw new Error('Unsupported token type');
    }
  }


  get token(): string {
    return this._token;
  }

  get expire(): Date {
    return this._expire;
  }

  get type(): string {
    return this._type;
  }

  extendExpire(time: number) {
    if (time) {
      this._expire = new Date(Date.now() + time);
    }
  }

  toString() {
    return JSON.stringify({
      token: this._token,
      expire: this._expire,
      type: this._type
    });
  }
}
