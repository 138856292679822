import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  // only for session
  private _storage: Storage;
  // stays also when browser is closed
  private _localStorage: Storage;

  constructor() {
    this._storage = window.sessionStorage;
    this._localStorage = window.localStorage;
  }

  clear(asLocal?: boolean): void {
    this.getStorage(asLocal).clear();
  }

  getItem(key: string, asLocal?: boolean): any {
    return this.getStorage(asLocal).getItem(key);
  }

  key(index: number, asLocal?: boolean): string {
    return this.getStorage(asLocal).key(index);
  }

  removeItem(key: string, asLocal?: boolean): void {
    this.getStorage(asLocal).removeItem(key);
  }

  setItem(key: string, data: string, asLocal?: boolean): void {
    this.getStorage(asLocal).setItem(key, data);
  }

  storageLength(asLocal?: boolean): number {
    if (asLocal === true) {
      return this._localStorage.length;
    } 
    else {
      return this._storage.length;
    }
  }

  private getStorage(asLocal?: boolean): Storage {
    if (asLocal === true) {
      return this._localStorage;
    } else {
      return this._storage;
    }
  }
}
