import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";

import { TruckManagerLayoutService } from "src/app/service/truck-manager-layout.service";
import { UserConfigurationService } from "../../service/user-configuration.service";
import { NotificationService } from "../../service/notification-service";
import { ObligationService } from "src/app/service/obligation.service";
import { OrderService } from "src/app/service/order.service";
import { MessageService } from "../../service/message.service";
import { MessagesConfigurationInterface } from "src/app/interface/messages-configuration.interface";
import { Obligation } from "src/app/model/obligation.object";
import { Message } from "../../model/message.object";
import { Order } from "src/app/model/order.object";
import { IS_DEMO, ServiceConfiguration } from "../../config";
import { ObligationTools } from "src/app/tools/ObligationTools";
import { OrderTools } from "src/app/tools/OrderTools";

declare var $: any;

@Component({
  selector: 'messages-new-dialog',
  templateUrl: './r-messages-new-dialog.component.html',
  styleUrls: ['./r-messages-new-dialog.component.css'],
  providers: [
    ObligationService,
    OrderService
  ]
})
export class RMessagesNewDialogComponent implements OnInit, OnDestroy {

  private _markedMessages: Array<Message> = [];
  private _subscribed: Array<Subscription> = [];
  private _newMessagesNotificationInterval: number;
  private _messagesConfig: MessagesConfigurationInterface;

  private _visible: boolean = false;
  get visible(): boolean {
    return this._visible;
  }

  private _closed: EventEmitter<boolean> = new EventEmitter();
  @Output()
  get closed(): Observable<boolean> {
    return this._closed.asObservable();
  }

  private _opened: EventEmitter<boolean> = new EventEmitter();
  @Output()
  get opened(): Observable<boolean> {
    return this._opened.asObservable();
  }

  @Input()
  set open(open: boolean) {
    this.setVisible(open);
  }

  private _newMessages: Array<Message> = [];
  get newMessages(): Array<Message> {
    return this._newMessages;
  }

  // HOTFIX of obsolete message service
  private _initialized: boolean = false;
  private readonly INIT_TIMEOUT: number = 3000;

  constructor(
    private _messagesService: MessageService,
    private _obligationsService: ObligationService,
    private _ordersService: OrderService,
    private _changeDet: ChangeDetectorRef,
    private _notifyService: NotificationService,
    private _confService: UserConfigurationService,
    private _layout: TruckManagerLayoutService,
    private _router: Router
  ) {
    // load user configuration
    this._messagesConfig = this._confService.configuration.defaultMessagesConfiguration;
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subscriber => subscriber.unsubscribe()
    )
  }

  ngOnInit() {
    window.setTimeout(
      () => {
        this._initialized = true;
        this.handleNewMessagesRouting();
      }, this.INIT_TIMEOUT
    );

    this._subscribed.push(
      this._messagesService.getNewMessages(false).subscribe(
        newMessages => {
          this._newMessages = newMessages;
          this._newMessages.sort((a, b) => (a.time < b.time) ? 1 : -1);

          // user settings filter
          if (this._messagesConfig) {
            if (this._messagesConfig.order_messages_show && !this._messagesConfig.vehicle_messages_show) {
              // show only order messages
              this._newMessages = this._newMessages.filter(m => m.order_key);
            }
            else if (!this._messagesConfig.order_messages_show && this._messagesConfig.vehicle_messages_show) {      
              // show all except of order messages
              this._newMessages = this._newMessages.filter(m => !m.order_key);
            }
          }
          // associate obligations and orders
          this.associateObligationsOrders(this._newMessages);

          // set little timeout for downloading thumbnails
          let thumbnail_timeout: number = 0;
          this._newMessages.forEach(
            m => {
              if (m.attachment) {
                m.attachment.timeoutThumbnail = thumbnail_timeout;
                // 500ms increment
                thumbnail_timeout += 500;
              }
            }
          );
          
          // TODO rework message service - all messages pushed to observable one by one
          if (this._newMessages && this._newMessages.length) {
            if (!IS_DEMO && this._layout.isAuthenticated()) {
              this.handleNewMessagesRouting();
            }

            // playsound
            if (!this._newMessagesNotificationInterval) {
              this.soundNotification();
              this._newMessagesNotificationInterval = window.setInterval(
                () => {
                  if (this._newMessages && this._newMessages.length > 0) {
                    this.soundNotification();
                  } 
                  else {
                    this.clearSoundNotification();
                  }
                },
                60000
              )
            }
          } 
          else if (this._visible !== false) {
            this.setVisible(false);
            this.clearSoundNotification();
          }

          if (!this._newMessages || !this._newMessages.length) {
            this.clearSoundNotification();
          }
          this._changeDet.detectChanges();
        }
      )
    );
  }

  handleNewMessagesRouting(): void {
    // HOTFIX timeouting initialization to handle multiple messages on init
    if (this._initialized && this._newMessages && this._newMessages.length) {
      let show_messages = this._confService.configuration.defaultMessagesConfiguration.new_messages_sound_notification;
      let show_modal = this._confService.configuration.defaultMessagesConfiguration.new_messages_dialog_show;
      if (show_messages) {
        if (!show_modal) {
          // keep previous route query params
          let route: any = this._router.parseUrl(this._router.url);
          // add param of new message (components could not be reloaded)
          route.queryParams['rightMenu'] = true;

          // parse left outlet
          let left_outlet: string = null;
          let regex = /(?<=left:)(.*?)(\)|\/\/)/;
          let match = window.location.href.match(regex);
          if (match && match.length > 1) {
            left_outlet = match[1];
          }

          // open dashbord component with non read messages
          let url: string = null;
          if (left_outlet) {
            url = this._router.serializeUrl(
              this._router.createUrlTree(
                [{outlets: {left: left_outlet, right: 'messages-new'}}], {queryParams: route.queryParams} 
              )
            );
          }
          else {
            url = this._router.serializeUrl(
              this._router.createUrlTree(
                [{outlets: {right: 'messages-new'}}], {queryParams: route.queryParams} 
              )
            );
          }
          // console.log(url);
          
          this._router.navigateByUrl(url);
        }
        else if (!this._messagesService.newMessagesDialogDisabled && !this.visible) {
          // open modal dialog
          this.setVisible(true);
          this.pushVisible();
        }
      }
    }
  }

  close() {
    this.setVisible(false);
    this.pushVisible();
  }

  markMessage(event, message: Message): number {
    let value: number;
    if (this._markedMessages.indexOf(message) > -1) {
      this._markedMessages.splice(this._markedMessages.indexOf(message), 1);
      value = -1;
    } else {
      value = this._markedMessages.push(message)
    }
    return value;
  }

  isMessageMarked(message: Message): boolean {
    return (this._markedMessages.indexOf(message) > -1);
  }

  isAnyMessageMarked(): boolean {
    return this._markedMessages.length > 0;
  }

  readAll() {
    // if (this._newMessages && this._newMessages.length > 1) {
    //   let self = this;
    //   this._messagesService.getNewMessages().subscribe(
    //     function (newMessages) {
    //       if (newMessages && newMessages.length === 0) {
    //         console.log('Here');
    //         self._notifyService.alert($localize`Zprávy přečteny`, 'success');
    //         this.unsubscribe();
    //       }
    //     }
    //   );
    // }
    this._newMessages.slice().forEach(
      message => {
        this._messagesService.readMessage(message).subscribe(
          success => {
            if (this._newMessages && this._newMessages.length == 0) {
              this._notifyService.alert($localize`Zprávy přečteny`, 'success');
            }
          },
          error => {
            this._notifyService.alert($localize`Zprávy nebyly přečteny`, 'error');
          }
        );
      }
    );
  }

  readMarked() {
    if (this.isAnyMessageMarked()) {
      // if (this._markedMessages && this._markedMessages.length > 1) {
      //   let self = this;
      //   this._messagesService.getNewMessages().subscribe(
      //     function (newMessages) {
      //       if (newMessages && newMessages.length === 0) {
      //         self._notifyService.alert($localize`Zprávy přečteny`, 'success');
      //         this.unsubscribe();
      //       }
      //     }
      //   );
      // }
      this._markedMessages.forEach(
        message => {
          this._messagesService.readMessage(message).subscribe(
            response => {
              this.markMessage(null, message);
            },
            error => {
              this._notifyService.alert($localize`Zprávy nebyly přečteny`, 'error');
            }
          );
        }
      );
    }

  }

  private pushVisible() {
    this._closed.emit(this._visible);
  }

  private clearSoundNotification() {
    window.clearInterval(this._newMessagesNotificationInterval);
    this._newMessagesNotificationInterval = null;
  }

  private soundNotification(): boolean {
    // if (!this._confService.configuration.defaultMessagesConfiguration.new_messages_sound_notification) {
    //   return false;
    // }
    if (!this._confService.configuration.defaultVehicleListConfiguration.sound_alert) {
      return false;
    }

    let fileUrl = this.detectIEBrowser() ? 
      ServiceConfiguration.message.soundNotificationFileUrlIE : 
      ServiceConfiguration.message.soundNotificationFileUrl;

    if (typeof Audio !== 'undefined') {
      let audio = new Audio(fileUrl);
      let doNotify = () => {
        this._notifyService.notify(
          'Nepřečtené zprávy ( %d ) !!'.replace(/%d/g, this._newMessages.length.toString()), {
          sound: fileUrl,
          icon: ServiceConfiguration.notification.notificationIcon,
          badge: ServiceConfiguration.notification.notificationBadge
        });
      };
      try {
        audio.play();
        doNotify();
        return true;
      } catch (error) {
        doNotify();
        return false;
      }
    }
    return false;
  }

  private detectIEBrowser() {
    return navigator.appName.match(/(Internet Explorer|Netscape|Trident)/g);
  }

  private setVisible(visible: boolean) {
    // dont show new message in demo
    if (this.isDemoOnlyDashboard) return;

    if (this._confService.configuration.defaultMessagesConfiguration.new_messages_dialog_show) {
      this._visible = visible;
      if (this._visible) {
        (<any>$('#messagesModal')).modal('show');
      }
      else {
        (<any>$('#messagesModal')).modal('hide');
      }
    }
  }

  get isDemoOnlyDashboard(): boolean {
    return IS_DEMO && window.location.href.includes('onlyDashboard=true');
  }


  /****************************************************************/
  /* Obligations and Orders of messages */
  /****************************************************************/
  private _obligation_keys: Array<number> = [];
  private _obligation_keys_to_load: Array<number> = [];
  private _obligations: Array<Obligation> = [];

  private _order_keys: Array<number> = [];
  private _order_keys_to_load: Array<number> = [];
  private _orders: Array<Order> = [];
  
  private associateObligationsOrders(messages: Array<Message>): void {
    // loop messages and find obligation_keys
    messages.forEach(
      m => {
        if (m.obligation_key) {
          if (!this._obligation_keys.includes(m.obligation_key)) {
            this._obligation_keys.push(m.obligation_key);
          }
        }
        if (m.order_key) {
          // hotfix - invoke new loading of order
          if (m.msg && m.msg.includes('upravena/doplněna')) {
            this._order_keys_to_load = this._order_keys_to_load.filter(key => key != m.order_key);
            this._orders = this._orders.filter(o => o.order_key != m.order_key);
          }

          if (!this._order_keys.includes(m.order_key)) {
            this._order_keys.push(m.order_key);
          }
        }
      }
    );

    // loop obligation_keys and load not loaded obligation
    this._obligation_keys.forEach(
      key => {
        if (!this._obligation_keys_to_load.includes(key)) {
          this._obligation_keys_to_load.push(key);
          this._subscribed.push(
            this._obligationsService.getObligationToComponent(key).subscribe(
              response => {
                if (response && response.obligation_key) {
                  let obligation: Obligation = ObligationTools.buildObligation(response);
                  this._obligations.push(obligation);
                  // add obligations object 
                  this.associateObligationsOrdersMessages(messages);
                }
              },
              error => {
                console.log(error);
              }
            )
          );
        }
      }
    );

    // loop order_keys and load not loaded order
    this._order_keys.forEach(
      key => {
        if (!this._order_keys_to_load.includes(key)) {
          this._order_keys_to_load.push(key);
          this._subscribed.push(
            this._ordersService.getOrderToComponent(key).subscribe(
              response => {
                if (response && response.order_key) {
                  let order: Order = OrderTools.buildOrder(response);
                  this._orders.push(order);
                  // add obligations object 
                  this.associateObligationsOrdersMessages(messages);
                }
              },
              error => {
                console.log(error);
              }
            )
          );
        }
      }
    );

    // add obligations object
    this.associateObligationsOrdersMessages(messages);
  }

  private associateObligationsOrdersMessages(messages: Array<Message>): void {
    messages.forEach(
      m => {
        if (m.obligation_key) {
          m.obligation = this._obligations.find(o => o.obligation_key == m.obligation_key);
          // associate itinerary
          if (m.itinerary_key && m.obligation) {
            m.itinerary = m.obligation.itinerary.find(it => it.itinerary_key == m.itinerary_key);
          }
        }
        if (m.order_key) {
          m.order = this._orders.find(o => o.order_key == m.order_key);
        }
      }
    );
  }

  /************************************************************/
  /* Wienerberger */
  /************************************************************/
  public orderToWienerberger: Order = null;
  public orderNumberFormattedToWienerberger: string = null;
  handleOrderToWienerberger(event: Order): void {
    this.orderToWienerberger = event;
    if (this.orderToWienerberger && this.orderToWienerberger.obligations && this.orderToWienerberger.obligations.length) {
      let tmp_order_number: string = this.orderToWienerberger.obligations[0].order_number;
      // exact 10 string length
      if (tmp_order_number.length > 10) {
        tmp_order_number = tmp_order_number.substring(0, 10);
      }

      if (tmp_order_number.includes('/')) {
        // use the string before backslash symbol
        tmp_order_number = tmp_order_number.split('/')[0];
      }
      // formatted to 10 digit string - prefixed with zeros
      tmp_order_number = '0'.repeat(10 - tmp_order_number.length) + tmp_order_number;
      this.orderNumberFormattedToWienerberger = tmp_order_number;
    }
  }

  public urlWienerberger: string = null;
  handleUrlWienerberger(event: string): void {
    this.urlWienerberger = event;
  }

  public spzToWienerberger: string = null;
  handleSpzToWienerberger(event: string): void {
    this.spzToWienerberger = event;
  }

  public driverToWienerberger: string = null;
  handleDriverToWienerberger(event: string): void {
    this.driverToWienerberger = event;
  }

  public phoneToWienerberger: string = null;
  handlePhoneToWienerberger(event: string): void {
    this.phoneToWienerberger = event;
  }

  public loadingDateToWienerberger: Date = null;
  handleLoadingDateToWienerberger(event: Date): void {
    this.loadingDateToWienerberger = event;
  }
  
  copyToClipboard(text: string): void {
    if (text) {
      navigator.clipboard.writeText(text);
      this._notifyService.alert($localize`Text byl zkopírován`, 'success', 3000);
    }
  }
}
