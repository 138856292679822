
export class AddressBookItemPricelist {
  private _pricelist_key: number;
  public get pricelist_key(): number {
    return this._pricelist_key;
  }
  public set pricelist_key(value: number) {
    this._pricelist_key = value;
  }
  
  private _book_key: number;
  public get book_key(): number {
    return this._book_key;
  }
  public set book_key(value: number) {
    this._book_key = value;
  }
  
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _code: string = null;
  public get code(): string {
    return this._code;
  }
  public set code(value: string) {
    this._code = value;
  }

  private _currency: string = null;
  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
  }
  
  private _price: number = null;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
  }

  private _note: string = null;
  public get note(): string {
    return this._note;
  }
  public set note(value: string) {
    this._note = value;
  }

  private _tariff: string = null;
  public get tariff(): string {
    return this._tariff;
  }
  public set tariff(value: string) {
    this._tariff = value;
  }

  private _valid_until: string = null;
  public get valid_until(): string {
    return this._valid_until;
  }
  public set valid_until(value: string) {
    this._valid_until = value;
    if (this._valid_until) {
      this._valid_untilDate = new Date(this._valid_until);
    }
  }

  // custom
  private _valid_untilDate: Date = null;
  public get valid_untilDate(): Date {
    return this._valid_untilDate;
  }
  public set valid_untilDate(value: Date) {
    this._valid_untilDate = value;
  }

  private _incl_limit: number = null;
  public get incl_limit(): number {
    return this._incl_limit;
  }
  public set incl_limit(value: number) {
    this._incl_limit = value;
  }
  
  private _overlimit: number = null;
  public get overlimit(): number {
    return this._overlimit;
  }
  public set overlimit(value: number) {
    this._overlimit = value;
  }

  
  public CODES_KM: Array<string> = [
    '#trasa-plan',
    '#trasa-real',
    '#trasa+prejezd',
    '#trasa-vzduch'
  ];

  public CODES_TUNA: Array<string> = [
    '#tuna',
    '#tuna-nakl',
    '#tuna-vykl'
  ];
  
  public CODES_MINUTES: Array<string> = [
    '#limit-nakladka'
  ];

  get isCodeKm(): boolean {
    return this._code && this.CODES_KM.includes(this._code);
  }

  get isCodeTuna(): boolean {
    return this._code && this.CODES_TUNA.includes(this._code);
  }
  
  get isCodeMinutes(): boolean {
    return this._code && this.CODES_MINUTES.includes(this._code);
  }

  get apiObject(): any {
    return {
      code: this._code,
      currency: this._currency,
      price: this._price,
      note: this._note,
      tariff: this._tariff,
      valid_until: this._valid_until,
      incl_limit: this._incl_limit,
      overlimit: this._overlimit,
    }
  }
}