import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IS_DEMO, ServiceConfiguration } from '../config';
import { DrivingExclusion } from '../model/driving-exclusion.object';
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';

@Injectable({
  providedIn: 'root'
})
export class DrivingExclusionsService {

  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }

  constructor(
    private _http: HttpClientService,
    private _authServ: AuthenticationService,
    private _notificationServ: NotificationService
  ) { }

    
  // GET /driving-exclusions/public-holidays/?country=<COUNTRY>
  getDrivingExclusions(country: string): Observable<Array<DrivingExclusion>> {
    let res: BehaviorSubject<Array<DrivingExclusion>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.drivingExclusions.api;
      url = url.replace('<COUNTRY>', country);
      this._loading = true;

      this._http.get(url).subscribe(
        response => {
          let tasks = this.buildDrivingExclusionsFromData(response);
          res.next(tasks);
          this._loading = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loading = false;
        },
      );
    }

    return res.asObservable();
  }

  // method for creating tasks array
  private buildDrivingExclusionsFromData(data: Array<any>): any {
    let exclusions: Array<DrivingExclusion> = [];
    data.forEach(
      o => {
        let task: DrivingExclusion = this.buildDrivingExclusion(o);
        exclusions.push(task);
      }
    );
    return exclusions;
  }

  // method for creating a single task object
  private buildDrivingExclusion(o: any): DrivingExclusion {
    let e: DrivingExclusion = new DrivingExclusion();
    for (let key in o) {
      e[key] = o[key];
    }
    return e;
  }

}
