import { Component, Input, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { Subscription } from "rxjs";

import { NotificationService } from "src/app/service/notification-service";
import { DestinationRoutesService } from "../../service/destination-routes.service";
// import { VehicleService } from "src/app/service/vehicle.service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { UserConfigurationService } from "../../service/user-configuration.service";
import { AetrCollectionPartInterface } from "../../interface/aetr-collection-part.interface";
import { AetrDataCollectionObject } from "src/app/model/aetr-data-collection.object";
import { Vehicle } from "../../model/vehicle.object";
import { DateTools } from "../../tools/DateTools";
import { Haversine } from "../../tools/Haversine";
import { defaultLengthUnit } from "../../config";

@Component({
  selector: 'vehicle-manual-tracking-result',
  templateUrl: './r-vehicle-manual-tracking-result.component.html',
  styleUrls: ['./r-vehicle-manual-tracking-result.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RVehicleManualTrackingResultComponent implements OnInit, OnDestroy, AfterViewChecked {
  
  private _subscribed: Array<Subscription> = [];
  
  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
  }

  constructor(
    private _notificationService: NotificationService,
    private _destinationRoutes: DestinationRoutesService,
    private _vehicleServ: VehicleNewService,  
    private _cdr: ChangeDetectorRef
  ) {
    // custom change detection strategy
    this._cdr.detach();
    setInterval(
      () => {
        this.setDrivingTimeRemainingFormatted();
        this.setEtaFormatted();
        this.runWorkloadCurrent();
        this._cdr.detectChanges();
      }, 5000
    );
    
  }

  ngOnInit(): void {
    if (!this._vehicle) {
      throw new Error('Vehicle is required');
    }
    if (!this._vehicle.temporaryManualTracking) {
      throw new Error('Vehicle not being manually tracked');
    }
    
    
    this.setDrivingTimeRemainingFormatted();
    this.setEtaFormatted();
    this.runWorkloadCurrent();
    this.detectChanges();

    this._subscribed.push(
      this._vehicle.aetr_loaded.subscribe(
        aetr => {
          if (!aetr) {
            return this._vehicle.aetr;
          }
          this._aetr_collection = aetr;
          // custom change detection
          this.detectChanges();
          // console.log(this._aetr_collection);
        }
      )
    );
  }

  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // HOT FIX for ExpressionChangedAfterItHasBeenCheckedError
    // this.setDrivingTimeRemainingFormatted();
    // this.setEtaFormatted();
    // this.runWorkloadCurrent();
    // this._cdr.detectChanges();
  }

  
  ngOnDestroy(): void {
    this._subscribed.forEach(
      subscription => subscription.unsubscribe()
    );
  }
  
  detectChanges(): void {
    // detect changes 100 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }


  private get distance(): number {
    return Math.ceil(Haversine.haversine(
      {
        latitude: this._vehicle.last_position.googleLatLang.lat,
        longitude: this._vehicle.last_position.googleLatLang.lng
      },
      {
        latitude: this._vehicle.temporaryManualTracking[1].position.lat(),
        longitude: this._vehicle.temporaryManualTracking[1].position.lng(),
      },
      {
        unit: defaultLengthUnit
      }
    ) * 1.3);//adding straight line calculation error
  }

  distanceRemaining(): string {
    if (this._vehicle.temporaryManualTrackingTarget) {
      let numDistance = (Math.round(this._vehicle.temporaryManualTrackingTarget.distance / 100) / 10);
      return numDistance + defaultLengthUnit;
    }
    
    let distance = 'N/A';
    if (this._vehicle.last_position && this._vehicle.temporaryManualTracking && this._vehicle.temporaryManualTracking.length) {
      let distanceInNumber = this.distance;
      distance = '~' + distanceInNumber + defaultLengthUnit
    }
    return distance;
  }

  
  private _drivingTimeRemainingFormatted: string = '--:--';
  public get drivingTimeRemainingFormatted(): string {
    return this._drivingTimeRemainingFormatted;
  }
  setDrivingTimeRemainingFormatted(): void {
    let time = '--:--';
    if (!this._vehicle.temporaryManualTrackingTarget) {
      this._drivingTimeRemainingFormatted = time; 
      return;
    }
    
    let duration = this._vehicle.temporaryManualTrackingTarget.duration;
    let fullDuration: number = duration;
    if (fullDuration) {
      time = '~' + DateTools.getHumanReadHoursAndMinutes(fullDuration * 1000);
      // time = DateTools.getHumanReadHoursAndMinutes(fullDuration * 1000);
    }
    this._drivingTimeRemainingFormatted = time;
  }

  private _etaFormatted: string = 'N\A';
  public get etaFormatted(): string {
    return this._etaFormatted;
  }
  setEtaFormatted(): void {
    // let hour = new Date(new Date().getTime()+this.timeRemaining() + (this._vehicle.temporaryManualTrackingTarget.stops*1000));
    // if(!hour){
    //     return 'N\A';
    // }
    // return DateTools.formatLocaleString(hour, '%hours:%minutes');
    this._etaFormatted = '--:--';
    if (!this._vehicle.temporaryManualTrackingTarget) {
      return;
    }
    // FIX
    let duration = this._vehicle.temporaryManualTrackingTarget.duration;
    let fullDuration: number = duration;
    if (fullDuration > 0) {
      let hour = new Date((new Date).getTime() + fullDuration * 1000);
      if (!hour) {
        return;
      }
      this._etaFormatted = DateTools.formatLocaleString(hour, '%hours:%minutes');
      return;
    }
  }

  resetManualTemporaryTracking() {
    this._destinationRoutes.deleteRoute(this._vehicle).subscribe(
      success => {
        let alertSuccess: string = $localize`Dojezdový cíl byl odstraněn.`;
        this._notificationService.alert(alertSuccess, 'success', 3000);
        this._vehicle.resetManualTemporaryTracking();
      },
      error => {
        let alertError: string = $localize`Chyba při vytváření dojezdového cíle.`;
        this._notificationService.alert(alertError, 'error', 3000);
      }
    );
  }

  percentageLeft(): number {
    if (!this._vehicle.temporaryManualTrackingTarget) {
      return 100;
    }

    if (this._vehicle.temporaryManualTrackingTarget.distance > 200000) {
      return 100;
    } 
    else {
      return Math.round((this._vehicle.temporaryManualTrackingTarget.distance / 200000) * 100);
    }
  }

  // private timeRemaining(): number {
  //   if (this._vehicle.temporaryManualTrackingTarget) {
  //     //speed_coef is 0.6 => slower , 1.5 => faster
  //     let duration = this._vehicle.temporaryManualTrackingTarget.durationReal + this._vehicle.temporaryManualTrackingTarget.stops;
  //     let fullDuration: number = duration;

  //     let remaining = fullDuration * 1000;
  //     if (remaining < 0) {
  //       return 0;
  //     }
  //     return remaining;
  //   }
  //   if (this._vehicle.aetr) {
  //     let drivingSegment: AetrCollectionPartInterface;
  //     if (this._vehicle.aetr.isSegmentDrive(this._vehicle.aetr.currentSegment)) {
  //       drivingSegment = this._vehicle.aetr.currentSegment;
  //     }
  //     if (!drivingSegment && this._vehicle.aetr.isSegmentDrive(this._vehicle.aetr.pastSegment)) {
  //       drivingSegment = this._vehicle.aetr.pastSegment;
  //     }
  //     if (drivingSegment) {
  //       let speed: number = drivingSegment.distance * 1000 / drivingSegment.length; //ms/s
  //       let distanceInNumber = this.distance * 1000;//data in km transform to m
  //       return (distanceInNumber / speed) * 1000; // to ms
  //     }
  //     return 0;
  //   }
  // }


  /* Stuff same as in aetr-row component */
  private _aetr_collection: AetrDataCollectionObject;
  public get aetr_collection(): AetrDataCollectionObject {
    return this._aetr_collection;
  }

  private _workloadCurrent: string = '0:00';
  public get workloadCurrent(): string {
    return this._workloadCurrent;
  }
  
  // method for reloading from api again
  getAetr(): void {
    this._vehicle.aetr_lazyload = null;
    this._vehicle.aetr_lazyload = this._vehicleServ.createAetrLazyloader(this._vehicle);
  }

  runWorkloadCurrent(): void {
    if (this._aetr_collection && this._aetr_collection.pastSegment) {
      let segment: AetrCollectionPartInterface = this._aetr_collection.pastSegment;
      let now: Date = new Date();
      // div miliseconds with 1000 and 60 to minutes, and again 60 to hours
      let hours: number = (now.getTime() - segment.date_stop.getTime()) / (1000*60*60);
      let minutes: number = ((now.getTime() - segment.date_stop.getTime()) / (1000*60)) % 60;
      hours = Math.floor(hours);
      minutes = Math.floor(minutes);

      this._workloadCurrent = ((hours < 10 ? '0' : '') + hours.toString()) + ':' + ((minutes < 10 ? '0' : '') + minutes.toString());
    }
  }
  
  duration(segment: AetrCollectionPartInterface): string {
    return DateTools.getHumanReadHoursAndMinutes(segment.length * 1000);
  }
  
  doneDriving(segment: AetrCollectionPartInterface): string {
    return Math.round(segment.distance) + UserConfigurationService.global_length_unit;
  }
  
  distanceDriving(segment: AetrCollectionPartInterface): string {
    return Math.round(segment.distance) + ' ' + UserConfigurationService.global_length_unit;
  }
}
