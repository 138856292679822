import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";

import { VehicleNewService } from "../../service/vehicle-new.service";
import { Vehicle } from "../../model/vehicle.object";
import { GanttDriveDiagramEvent } from "../../model/gantt-drive-diagram-event.object";
import { GoogleMapMarker } from "../../model/google-map-marker.object";
import { Agenda } from "../../model/agenda.object";
import { DateTools } from "../../tools/DateTools";
import { ItineraryType } from "src/app/config";

@Component({
  selector: 'gantt-drive-diagram',
  templateUrl: './r-gannt-drive-diagram.component.html',
  styleUrls: ['./r-gannt-drive-diagram.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RGanntDriveDiagramComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];

  private _vehicle: Vehicle;
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
    // custom change detection
    this.detectChanges();
  }

  private _currentDate: Date;
  get currentDate(): Date {
    return this._currentDate;
  }
  @Input()
  set currentDate(value: Date) {
    this._currentDate = value;
  }

  private _yesterday: Date;
  get yesterday(): Date {
    return this._yesterday;
  }

  private _tomorrow: Date;
  get tomorrow(): Date {
    return this._tomorrow;
  }

  private _eventClicked: EventEmitter<GanttDriveDiagramEvent> = new EventEmitter();
  @Output()
  get eventClicked(): EventEmitter<GanttDriveDiagramEvent> {
    return this._eventClicked;
  }

  private _events: Array<GanttDriveDiagramEvent> = [];
  get events(): Array<GanttDriveDiagramEvent> {
    return this._events;
  }

  private _conflictingLevels: Array<Array<GanttDriveDiagramEvent>> = [];
  get conflictingLevels(): Array<Array<GanttDriveDiagramEvent>> {
    return this._conflictingLevels;
  }

  constructor(
    private _vehicleService: VehicleNewService, 
    private _cdr: ChangeDetectorRef
  ) {
    // custom change detection strategy
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 5000
    );
  }

  ngOnInit() {
    this._subscribed.push(
      this._vehicleService.vehicleDataChanged.subscribe(
        vehicle => {
          if (vehicle.car_key === this._vehicle.car_key) {
            this._events = [];
            this._conflictingLevels = [];
            this._vehicle.agenda = vehicle.agenda;
            this.createData();
            // custom detection strategy
            // console.log(this._vehicle.agenda);
            this.detectChanges();
          }
        }
      ),
      this._vehicleService.agendaChanged.subscribe(
        agenda => {
          if (agenda) {
            // console.log(agenda);
            let found = this._vehicle.agenda.find(a => a.obligation_key == agenda.obligation_key);
            if (found && agenda.car_key != this._vehicle.car_key) {
              // remove it from this vehicle (obligation has been moved to another vehicle)
              this._events = [];
              this._conflictingLevels = [];
              this._vehicle.agenda = this._vehicle.agenda.filter(
                a => a.obligation_key != agenda.obligation_key
              );
              this.createData();
              // custom detection strategy
              this.detectChanges();
            }
            // else if (found && agenda.car_key != this._vehicle.car_key) {
            //   // update agenda with current data
            //   // use timeout for sure updating obligation + all itinerary
            //   found = agenda;
            //   this._events = [];
            //   this._conflictingLevels = [];
            //   this.createData();
            //   this._detectRef.detectChanges();
            // }, 2000
            // }
          }
        }
      )
    ) 

    this.createData();
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subscriber => subscriber.unsubscribe()
    );
  }

  detectChanges(): void {
    // detect changes 100 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }

  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }
  
  get icons(): any {
    return GoogleMapMarker.icons;
  }

  get current_date_formatted(): string {
    return DateTools.formatLocaleString(this._currentDate);
  }

  ganttPartClicked(event: GanttDriveDiagramEvent) {
    this._eventClicked.emit(event);
  }

  private createData() {
    // yesterday before 24hours
    this._yesterday = DateTools.getYesterday(this._currentDate);
    // tomorrow after 24hours
    this._tomorrow = DateTools.getTomorrow(this._currentDate);
    let yesterday: number = this._yesterday.getTime();
    let tomorrow: number = this._tomorrow.getTime();

    let sortedAgenda: Array<Agenda> = this._vehicle.agenda || [];
    // sort agendas according to arrival_time
    // sortedAgenda.sort((a, b) => (a.day > b.day) ? 1 : -1);
    sortedAgenda.sort(
      (a, b) => {
        if (a.day > b.day) {
          return 1;
        }
        else if (a.day < b.day) {
          return -1;
        }
        else {
          // same - prefer first created agenda
          if (a.obligation_key > b.obligation_key) {
            return 1;
          }
          return -1;
        }
        return 1;
      }
    );

    sortedAgenda.forEach(
      agenda => {
        // not displaying status H Sklad
        if (agenda.status != 'H') {
          if (agenda.itinerary && agenda.itinerary.length) {
            // start time of agenda
            let startTime: Date = agenda.first_itinerary.arrival_time;
            if (startTime.getTime() < yesterday) {
              startTime = this.yesterday;
            } 

            // end time of agenda with loading time limit
            let endTimeAfterLoading: Date = new Date(agenda.last_itinerary_time_with_limit);
            if (endTimeAfterLoading > this._tomorrow) {
              endTimeAfterLoading = this._tomorrow;
            } 

            // skip current agenda if it is out of our 48 hod interval
            if (startTime >= this._tomorrow) {
              return true;
            }
            else if (endTimeAfterLoading <= this._yesterday) {
              return true;
            }
             
            let currentEventDriving = new GanttDriveDiagramEvent(
              agenda, 
              GanttDriveDiagramEvent.EVENT_DRIVING, 
              startTime, 
              endTimeAfterLoading
            );

            // handle yellow/green ratio distance 
            if (agenda.itinerary.length) {
              // currentEventDriving.distanceTotal = parseFloat(agenda.route_length);
              currentEventDriving.distanceTotal = agenda.route_length;
              if (agenda.tracked_distance) {
                currentEventDriving.distanceCompleted = agenda.tracked_distance;
              }
            }

            // handle conflicts
            if (this.checkConflict(currentEventDriving, this._events)) {
              // already some conflicts exist
              if (this._conflictingLevels.length) {
                let foundNonConflicting = false;
                for (let i = 0; i < this._conflictingLevels.length; i++) {
                  if (!this.checkConflict(currentEventDriving, this._conflictingLevels[i])) {
                    this._conflictingLevels[i].push(currentEventDriving);
                    foundNonConflicting = true;
                    break;
                  }
                }

                // no possible option - create new conflict level
                if (!foundNonConflicting) {
                  this.addConflictingLevel(currentEventDriving);
                }
              } 
              // no conflicts yet - create conflict level
              else {
                this.addConflictingLevel(currentEventDriving);
              }
            } 
            else {
              // no conflict -> add to first row :-)
              this._events.push(currentEventDriving);
            }
          }
        }
      }
    );

    // first row - computing white spaces between green agendas
    this._events = this.computeWhiteSpace(this._events, yesterday, tomorrow);
    // other rows - computing white spaces between green agendas
    this._conflictingLevels.forEach(
      (level, index) => {
        this._conflictingLevels[index] = this.computeWhiteSpace(level, yesterday, tomorrow);
      }
    );

    // custom change detection
    this.detectChanges();
  }

  
  /*****************************************************/
  /* Conflicts computing (agendas overlay) */
  /*****************************************************/
  private addConflictingLevel(event: GanttDriveDiagramEvent): void {
    let nextLevel = [];
    nextLevel.push(event);
    this._conflictingLevels.push(nextLevel);
  }

  private checkConflict(event: GanttDriveDiagramEvent, events: Array<GanttDriveDiagramEvent>): boolean {
    let conflict = false;
    events.forEach(
      ev2 => {
        if ((event.eventStartDate >= ev2.eventStartDate && event.eventStartDate < ev2.eventEndDate) 
        || (event.eventEndDate > ev2.eventStartDate && event.eventEndDate <= ev2.eventEndDate)) {
          conflict = true;
        }
      }
    );
    return conflict;
  }


  /*****************************************************/
  /* Empty placeholders computing (time windows) */
  /*****************************************************/
  private computeWhiteSpace(events: Array<GanttDriveDiagramEvent>, yesterday: number, tomorrow: number): Array<GanttDriveDiagramEvent> {
    let placeholderEvents = [];

    events.forEach(
      (event, index) => {
        let diffBetweenLastEvent;
        switch (index) {
          case 0: // placeholder at start
            let startDiff = event.eventStartDate.getTime() - yesterday;
            if (startDiff > 0) {
              placeholderEvents.push(
                new GanttDriveDiagramEvent(
                  event.agenda,
                  GanttDriveDiagramEvent.EVENT_PLACEHOLDER,
                  new Date(yesterday),
                  event.eventStartDate
                )
              );
            }
            placeholderEvents.push(event);
            if (events.length === 1) {
              let endDiff = tomorrow - event.eventEndDate.getTime();
              if (endDiff > 0) {
                placeholderEvents.push(
                  new GanttDriveDiagramEvent(
                    event.agenda,
                    GanttDriveDiagramEvent.EVENT_PLACEHOLDER,
                    event.eventEndDate,
                    new Date(tomorrow)
                  )
                );
              }
            }
            break;
          case events.length - 1: // placeholder at the end
            diffBetweenLastEvent = event.eventStartDate.getTime() - events[index - 1].eventEndDate.getTime();
            if (diffBetweenLastEvent > 0) {
              placeholderEvents.push(
                new GanttDriveDiagramEvent(
                  event.agenda,
                  GanttDriveDiagramEvent.EVENT_PLACEHOLDER,
                  events[index - 1].eventEndDate,
                  event.eventStartDate
                )
              );
            }
            placeholderEvents.push(event);
            let endDiff = tomorrow - event.eventEndDate.getTime();
            if (endDiff > 0) {
              placeholderEvents.push(
                new GanttDriveDiagramEvent(
                  event.agenda,
                  GanttDriveDiagramEvent.EVENT_PLACEHOLDER,
                  event.eventEndDate,
                  new Date(tomorrow)
                )
              );
            }
            break;
          default: // placeholders between
            diffBetweenLastEvent = event.eventStartDate.getTime() - events[index - 1].eventEndDate.getTime();
            if (diffBetweenLastEvent > 0) {
              placeholderEvents.push(
                new GanttDriveDiagramEvent(
                  event.agenda,
                  GanttDriveDiagramEvent.EVENT_PLACEHOLDER,
                  events[index - 1].eventEndDate,
                  event.eventStartDate
                )
              );
            }
            placeholderEvents.push(event);
            break;
        }
      }
    );
    return placeholderEvents;
  }
}

