import {Pipe, PipeTransform} from "@angular/core";
import {DateTools} from "../tools/DateTools";

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, ...args): any {
    if (!(value instanceof Date)) {
      value = new Date(value);
    }
    return DateTools.formatLocaleString(value, args[0]);
  }
}

