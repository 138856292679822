import { Injectable } from "@angular/core";
import { Observable } from "rxjs/index";

import { ServiceConfiguration } from "../config";
import { HttpClientService } from "./http-client.service";
import { GoogleMapsApiService } from "./google-maps-api.service";
import { DestinationRouteDataInterface } from "../interface/destination-route-data.interface";
import { Vehicle } from "../model/vehicle.object";
import { GeoPosition } from "../model/geo-position.object";
import { GoogleMapMarker } from "../model/google-map-marker.object";
import { DateTools } from "../tools/DateTools";
import { Colors } from "../tools/Colors";
import { GoogleMapTools } from "../tools/GoogleMapTools";

declare var google:any;

@Injectable()
export class DestinationRoutesService {

  constructor(
    private http: HttpClientService
  ) {
  }

  static setReachDestinationToVehicle(
    vehicle: Vehicle, 
    waypoints: Array<GeoPosition>, 
    duration: number, 
    durationReal: number, 
    destination: GeoPosition, 
    distance: number, 
    tolerance: number, 
    trackingStart: Date, 
    description: string, 
    stops: number,
    strokeColor: string = null
  ) {
    // https://stackoverflow.com/questions/47906696/waypoints-in-google-maps-invalidvalueerror-in-property-waypoints-at-index-0
    vehicle.temporaryManualTrackingWaypoints = waypoints.map(
      geoPosition => {
        return {location: geoPosition.googleLatLang.googleLtLn};
      }
    );

    if (vehicle.last_position && vehicle.last_position.googleLatLang && destination) {
      let startMarker: GoogleMapMarker = new GoogleMapMarker();
      startMarker.position = vehicle.last_position.googleLatLang.googleLtLn;
      startMarker.icon = GoogleMapMarker.icons['C'];
      let endMarker: GoogleMapMarker = new GoogleMapMarker();
      endMarker.position = destination.googleLatLang.googleLtLn;
      endMarker.icon = GoogleMapMarker.icons['C'];
      vehicle.temporaryManualTrackingStart = trackingStart;
      vehicle.temporaryManualTracking = [startMarker, endMarker];
      vehicle.temporaryManualTrackingTarget = {
        address: description,
        searchedAddress: description,
        duration: duration,
        durationReal: durationReal,
        distance: distance,
        targetReachedMessageSent: false,
        stops: stops,
        targetTolerance: tolerance,
        lat: destination.googleLatLang.lat,
        lng: destination.googleLatLang.lng
      };
  
      // deep copy
      let options: any = JSON.parse(JSON.stringify(GoogleMapTools.RendererOptions));
      options.polylineOptions.strokeColor = strokeColor ? strokeColor : Colors.getColor(vehicle.index);
      options.polylineOptions.strokeOpacity = 0.75;
      vehicle.manualTrackingDirectionRender = new google.maps.DirectionsRenderer(options);
  
      let request: any = {
        origin: vehicle.temporaryManualTracking[0].position,
        destination: vehicle.temporaryManualTracking[1].position,
        waypoints: vehicle.temporaryManualTrackingWaypoints,
        travelMode: google.maps.DirectionsTravelMode.DRIVING
      }
      GoogleMapsApiService.callRequestOnDirectionsRender(vehicle.manualTrackingDirectionRender, request);
    }
  }

  static setReachDestinationToVehicleFromApiData(
    vehicle: Vehicle, 
    data: DestinationRouteDataInterface, 
    strokeColor: string = null
  ) {
    let dest = new GeoPosition();
    dest.pos_gps = data.gps_dest;

    // set speed coef
    let coeficient: number = 1;
    if (!isNaN(vehicle.speed_coef)) {
      coeficient = vehicle.speed_coef;
    }

    DestinationRoutesService.setReachDestinationToVehicle(
      vehicle,
      data.transit_points.map(
        (pos: string) => {
          let geoPosition = new GeoPosition();
          geoPosition.pos_gps = pos;
          return geoPosition;
        }
      ),
      data.drive_time/coeficient - data.summary_drive_time,
      data.drive_time/coeficient,
      dest,
      (data.drive_dist - data.summary_drive_dist) * 1000, // FIX - km to m again
      data.reach_dist,
      DateTools.isoFix(data.time_created),
      data.description,
      data.summary_rest_time,
      strokeColor
    )
  }

  createNewRoute(
    vehicle: Vehicle, 
    position: GeoPosition, 
    transitPoints: Array<GeoPosition>, 
    reachDistance: number, 
    duration: number, 
    distance: number, 
    description: string
  ): Observable<any> {
    let data = {
      car_key: vehicle.car_key,
      gps_cur: vehicle.last_position.positionString,
      gps_dest: position.positionString,
      time_created: new Date().toISOString(),
      drive_time: Math.round(duration),
      drive_dist: distance / 1000, //in here km is required
      transit_points: transitPoints.map(
        (position: GeoPosition) => {
          return position.positionString;
        }
      ),
      reach_dist: reachDistance,
      description: description
    };

    return this.http.post(
      ServiceConfiguration.routes.api + '/' + vehicle.car_key,
      data
    )
  }

  getRoute(vehicle: Vehicle): Observable<any> {
    return this.http.get(
      ServiceConfiguration.routes.api + '/' + vehicle.car_key
    )
  }

  deleteRoute(vehicle: Vehicle): Observable<any> {
    return this.http.delete(
      ServiceConfiguration.routes.api + '/' + vehicle.car_key
    );
  }
}