<div class="col-sm-12 dashboard-head messages-read">
  <i class="rightMenuImg" id="dashboard-msgNew"></i>
  <span class="float-left">{{lastLoadTime}}</span>

  <i *ngIf="newMessages.length" id="dashboard-readAllNew" 
  class="rightMenuImg pointer" (click)="readAllNew()"
  placement="bottom auto" container="body" 
  ngbTooltip="Označit vše jako přečtené" i18n-ngbTooltip></i>

  <i id="dashboard-broadcast" class="rightMenuImg pointer" (click)="showBroadcast()"
  placement="bottom auto" container="body" 
  ngbTooltip="Hromadná zpráva všem řidičům" i18n-ngbTooltip></i>
  
  <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only"></span>
  </div>

  <i *ngIf="!loading" id="dashboard-refresh" 
  class="rightMenuImg pointer" (click)="refreshNewMessages()"
  placement="bottom" container="body" ngbTooltip="Obnovit" i18n-ngbTooltip></i>
</div>

<div id="dashboard-carList-div">
  <messages-day-grouped-list [reply]="true" [messages]="newMessages"></messages-day-grouped-list>
</div>

<ng-container *ngIf="broadcastVisible">
  <div class="broadcast-message-dialog" (close)="hideBroadcast()"></div>
</ng-container>