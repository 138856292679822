import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { IS_DEMO, ServiceConfiguration } from '../config';
import { ServiceEventObject } from '../model/service-event.object';
import { Vehicle } from '../model/vehicle.object';
import { Itinerary } from '../model/itinerary.object';
import { ObligationTools } from '../tools/ObligationTools';

@Injectable({
  providedIn: 'root'
})
export class TmAgendaService {

  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) { }

  // GET /cars/%CAR_KEY%/tm-agenda
  getTmAgenda(vehicle: Vehicle): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.apiTmAgenda;
      url = url.replace('%CAR_KEY%', vehicle.car_key.toString());

      this._loading = true;

      this._http.get(url).subscribe(
        response => {
          result.next(response);
          this._loading = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loading = false;
        },
      );
    }

    return result.asObservable();
  }

  
  // GET /cars/%CAR_KEY%/tm-agenda - itinerary array
  getTmAgendaItinerary(vehicle: Vehicle): Observable<Array<Itinerary>> {
    let result: BehaviorSubject<Array<Itinerary>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.apiTmAgenda;
      url = url.replace('%CAR_KEY%', vehicle.car_key.toString());

      this._loading = true;

      this._http.get(url).subscribe(
        response => {
          if (response && response.itinerary) {
            result.next(this.buildItineraryFromData(response.itinerary));
          }
          this._loading = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loading = false;
        },
      );
    }

    return result.asObservable();
  }


  // PUT /cars/%CAR_KEY%/tm-agenda/%ITINERARY_KEY%
  createServiceEvent(event: ServiceEventObject): Observable<boolean> {
    let created: BehaviorSubject<boolean> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.vehicle.apiAgendaServiceEvent;
      url = url.replace('%CAR_KEY%', event.car_key.toString());
      url = url.replace('%ITINERARY_KEY%', event.itinerary_key.toString());

      console.log(event.putApiObject);
      console.log(event.postApiObject);

      // this._http.put(url, event.putApiObject).subscribe(
      this._http.put(url, event.postApiObject).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Událost Nakládky/Vykládky byla úspěšně vytvořena.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          // console.log(response);

          // observable next
          // created.next(this.buildServiceEvent(response));
          created.next(true);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření události Nakládky/Vykládky.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return created.asObservable();
  }


  buildItineraryFromData(data: Array<any>): Array<Itinerary> {
    let itineraryArr: Array<Itinerary> = [];
    if (data && data.length) {
      data.forEach(
        it => {
          let itinerary = ObligationTools.buildItinerary(it);
          itineraryArr.push(itinerary);
        }
      );
    }
    return itineraryArr;
  }
}
