import { Component, OnDestroy, OnInit, HostBinding } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable, Subscription, Subject } from "rxjs";

import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";
import { WebConnectivityService } from "src/app/service/web.connectivity.service";
import { NotificationService } from "src/app/service/notification-service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { WebsocketService } from "src/app/service/websocket.service";
import { CompanyService } from "src/app/service/company.service";
import { StorageService } from "src/app/service/storage.service";
import { Vehicle } from "../../model/vehicle.object";
import { Company } from "src/app/model/company.object";
import { IS_DEMO } from "../../config";

// eclare let gtag: Function;
declare var $: any;

@Component({
  selector: 'div.vehicle-all-in-one-map',
  templateUrl: './r-vehicle-all-in-one-map.component.html',
  styleUrls: ['./r-vehicle-all-in-one-map.component.css'],
  providers: [
    VehicleNewService
  ]
})
export class RVehicleAllInOneMapComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'fill';

  private _subs: Array<Subscription> = [];

  private _company: Company;
  
  // all vehicles
  private _vehicles: Array<Vehicle> = [];
  get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }

  // connection stuff
  private _notConnected: boolean = false;
  get notConnected(): boolean {
    return this._notConnected;
  }

  // rebuild when vehicle filters have changed
  private _rebuildMapSubject: Subject<boolean> = new Subject<boolean>();
  public get rebuildMapSubject(): Observable<boolean> {
    return this._rebuildMapSubject.asObservable();
  }

  // flga for displaying intro in top right corner
  public showIntro: boolean = true;

  // flag for handling obligation websocket
  // public websocketReloading: boolean = false;

  // counter for refresh limitation
  private _refreshCount: number = 0;
  public get refreshCount(): number {
    return this._refreshCount;
  }

  private _refreshLimitReached: boolean = false;
  public get refreshLimitReached(): boolean {
    return this._refreshLimitReached;
  }

  constructor(
    private _layout: TruckManagerLayoutService,
    private _webConn: WebConnectivityService,
    private _notificationServ: NotificationService,
    private _companyServ: CompanyService,
    private _vehicleNewService: VehicleNewService,
    private _storageServ: StorageService,
    private _datePipe: DatePipe,
    private _title: Title,
    private _router: Router
  ) {
    // if (!window.location.href.includes('app2') && !window.location.href.includes('localhost')) {
    //   gtag('config', 'UA-116483982-1', { 'page_path': 'mapa_vsechna_auta' });
    // }

    // restrict web browser reloading of this component for particular user/browser storage
    let today: Date = new Date();
    let map_storage_name: string = 'all-in-one-map';
    map_storage_name += '-' + this._datePipe.transform(today, 'yyyy-MM-dd');

    const MAX_DAY_LIMIT: number = 25;

    let map_storage_today = this._storageServ.getItem(map_storage_name, true);
    if (map_storage_today) {
      this._refreshCount = parseInt(map_storage_today);
      if (this._refreshCount) {
        if (this._refreshCount >= MAX_DAY_LIMIT) {
          // limit reached -> disable map loading
          this._refreshLimitReached = true;
          // open limit warning modal
          window.setTimeout(
            () => {
              (<any>$('#reloadingWarningModal')).modal('show');
            }, 2000
          );
        }
        else {
          // increment limit in local storage
          this._storageServ.setItem(map_storage_name, (this._refreshCount + 1).toString(), true);
        }
      }
    }
    else {
      // first loading refresh
      this._refreshCount = 1;
      this._storageServ.setItem(map_storage_name, this._refreshCount.toString(), true);
    }
  }

  ngOnInit() {
    this.setTitle($localize`TM mapa`);
    // this._layout.isDashboardFullMode = true;

    // set flag for colour destination routes
    this._vehicleNewService.allInOneMap = true;

    window.setTimeout(
      () => {
        this._subs.push(
          this._vehicleNewService.getFilteredVehiclesCache().subscribe(
            (vehicles: Array<Vehicle>) => {
              if (vehicles && vehicles.length && vehicles != this._vehicles) {
                this._vehicles = vehicles;
                // send event to googleMap component
                this._rebuildMapSubject.next(true);

                // websocket obligation
                // if (this.websocketReloading) {
                //   this.websocketReloading = false;
                //   this._rebuildMapSubject.next(true);
                // }
              }
              else {
                this._vehicles = [];
              }
            }
          ),
          // check connection
          this._webConn.connectionDown.subscribe(
            notConnected => {
              this._notConnected = notConnected;
            }
          ),
          this._webConn.isConnectionUp().subscribe(
            _ => {
              this._notConnected = false;
            }
          ),
          this._companyServ.getCompanyFullObservable().subscribe(
            company => {
              if (company) {
                this._company = company;
              } 
            }
          ),
          /*
          this._websocketServ.companyMessage.subscribe(
            (companyMessage: WebsocketResponse) => {
              if (companyMessage.relation === WebsocketService.RELATIONS.obligation) {
                // 6 seconds must be enough to update also itinerary - we use here window timeout

                // info for user that component will be soon reloaded
                this._notificationServ.alert(
                  $localize`Došlo ke změně zakázek - proběhne aktualizace mapy...`, 'success', 6000
                );
                this.websocketReloading = true;

                window.setTimeout(
                  () => {
                    // reload vehicles - agenda with new itinerary will be up to date
                    // this._vehicleService.getVehicles(true, true).subscribe();
                    this._vehicleNewService.getFilteredVehiclesCache(true).subscribe();
                  },
                  6000
                );
              }
            }
          )
          */
        );
      },
      500
    );

    // open warning modal always
    // window.setTimeout(
    //   () => {
    //     (<any>$('#reloadingWarningModal')).modal('show');
    //   }, 2000
    // );

    // show intro div/header
    this.showIntro = true;
    window.setTimeout(
      () => { this.showIntro = false }, 5000
    )
  }

  ngOnDestroy() {
    // this._layout.isDashboardFullMode = false;
    this._subs.forEach(
      sub => sub.unsubscribe()
    );
    this._vehicles.forEach(
      vehicle => {
        vehicle.rebuildTrackedPositionBasedData();
      }
    );
  }

  private setTitle(newTitle: string) {
    this._title.setTitle(newTitle);
  }


  /********************************************/
  /****** Getters ******/
  /********************************************/
  // default url of current server
  get contentResource(): string {
    return this._layout.staticContentResource;
  }

  // method for checking if user is authenticated
  get isAuthenticated(): boolean {
    return (IS_DEMO || (!IS_DEMO && this._layout.isAuthenticated()));
  }
  
  private TODAY: Date = new Date();
  // get flag if user has valid access = true, expired access = false
  get validAccess(): boolean {
    if (IS_DEMO) return true;
    if (!this._layout.user || !this._layout.user.admittanceDateExtended) return false;
    return this._layout.user.admittanceDateExtended > this.TODAY;
  }
  
  get tariffOnlyAgenda(): boolean {
    return this._company && (this._company.tariff == '17' || this._company.tariff == '23');
  }
  
  get loadingVehicles(): boolean {
    return this._vehicleNewService.loadingVehicles;
  }

  // time of last loading of cars
  get lastVehicleLoad(): Date {
    return this._vehicleNewService.lastLoadDate;
    // if (!this._vehicleNewService.lastLoadDate) {
    //     return '-';
    // }
    // return DateTools.formatLocaleString(this._vehicleNewService.lastLoadDate, '%hours:%minutes');
  }


  /*************************************/
  /* Routing stuff */
  /*************************************/
  openSubsNewTab() {
    let url: any = null;
    let queryParams: any = {
      reloadPossible: true
    }
    url = this._router.serializeUrl(
      this._router.createUrlTree([{outlets: {left: 'general/subscription', right: 'cars'}}], {queryParams: queryParams})
    );
    window.open('#' + url, '_blank');
  }
}
