import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { DriverCard } from "../model/driver-card.object";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class DriverCardService {

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  private _loadingCards: boolean = false;
  get loadingCards(): boolean {
    return this._loadingCards;
  }

  // GET /tachograph/card
  getDriverCards(): Observable<Array<DriverCard>> {
    let result: BehaviorSubject<Array<DriverCard>> = new BehaviorSubject(null);
    this._loadingCards = true;

    this._http.get(ServiceConfiguration.tachograph.api).subscribe(
      response => {
        if (response) {
          let cards = this.buildCardsFromData(response);
          result.next(cards);
          this._loadingCards = false;
        }
      },
      error => {
        console.log(error);
        this._loadingCards = false;
      }
    );

    return result.asObservable();
  }
  
  // PUT /tachograph/card/<card_no>
  public updateCard(card: DriverCard): Observable<any> {
    let updCard: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // prepare object for updating 
      let data = {};
      data["driver_key"] = card.driver_key;

      if (Object.keys(data).length) {
        this._http.put(ServiceConfiguration.tachograph.api + '/' + card.card_no, data).subscribe(
          response => {
            console.log(response);
            let alert: string = $localize`Karta %No byla úspěšně upravena.`;
            alert = alert.replace('%No', card.card_no.toString());
            this._notificationService.alert(alert, 'success', 3000);
          },
          error => {
            console.log(error);
            let alert: string = $localize`Chyba úpravy karty %No.`;
            alert = alert.replace('%No', card.card_no.toString());
            this._notificationService.alert(alert, 'error', 3000);
          }
        );
      }
    }

    return updCard.asObservable();
  }


  // method for downloading zip file with driver card backups
  downloadFile() {
    const options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'If-Modified-Since': '0',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }),
      responseType: 'arraybuffer'
    };

    // GET /tachograph/card/files/zip/?type=latest
    return this._http.get(ServiceConfiguration.tachograph.apiBackupsLatest, options).pipe(
      map(response => response)
    );
  }

  // alternative method for downloading zip file with driver card backups using promise
  // public async downloadResource(): Promise<Blob> {
  //     const file = await this._http.get(this._apiAllBackups, {responseType: 'blob' as 'json'}).toPromise();
  //     return file;
  // }

  // method for creating driver cards from api objects
  private buildCardsFromData(data: Array<any>): any {
    let cards: Array<DriverCard> = [];
    data.forEach(
      c => {
        let card: DriverCard = new DriverCard();
        for (let key in c) {
          card[key] = c[key];
        }
        cards.push(card);
      }
    );
    return cards;
  }
}
