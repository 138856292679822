<div *ngIf="events.length">
  <div class="gantt-center-anchor">
    <img id="caret-down" src="assets/icons-new/caret-down.svg" alt="down" height="18px"/>
    <img id="caret-up" src="assets/icons-new/caret-up.svg" alt="up" height="18px"/>
  </div>

  <!-- first row -->
  <div class="clearfix">
    <div *ngFor="let event of events">
      <!-- yellow green agenda -->
      <!-- placement="left auto" container="body" [ngbTooltip]="tooltip" -->
      <div *ngIf="event.isDriving" [style.width.%]="event.totalTimePercentage"
      class="gantt-part position-relative pointer driving"
      (click)="ganttPartClicked(event)">
        <!-- km info - driven/total -->
        <div *ngIf="!event.agenda.express_delivery" class="centered-text">
          <b style="line-height: 16px; font-size: 14px;">{{event.event_info}}</b>
        </div>
        <!-- Express route -->
        <!-- top part -->
        <div *ngIf="event.agenda.express_route_key" style="height: 8px;"
        [class]="'progress position-relative ' + (event.agenda?.first_itinerary?.completed ? 'redBackground' : event.agenda.backgroundColorClass)">
          &nbsp;
        </div>
        <!-- bottom part  -->
        <div *ngIf="event.agenda.express_route_key" [class]="'radius ' + event.agenda.backgroundColorClass" style="height: 8px;">
          &nbsp;
        </div>

        <!-- Standard obligation -->
        <!-- top part - obligations status color -->
        <div *ngIf="!event.agenda.express_route_key" [class]="'radius progress position-relative ' + event.agenda.backgroundColorClass" style="height: 8px;">
          &nbsp;
        </div>
        <!-- bottom part progress -->
        <div *ngIf="!event.agenda.express_route_key" class="progress position-relative" style="height: 8px;">
          <div class="progress-bar clearfix greenBackground" [style.width.%]="event.completedPercentage">
          </div>
        </div>
        <!-- bootstrap tooltip template -->
        <!-- <ng-template #tooltip>
          <div>
            {{event.agenda.order_number}}&nbsp;&nbsp;({{event.event_info}}km)
          </div>
          <div>
            {{event.agenda_comp_book_formatted}}
          </div>
          <div *ngFor="let it of event.agenda.itinerary">
            <div class="d-flex align-items-center">
              <div style="width: 28px;">
                <img class="icon-lg" [src]="icons[it.type_marker].url"/>
              </div>
              <div>
                <div class="d-flex">
                  <div>
                    {{it.arrival_time_formatted}}
                  </div>
                  <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING" class="ml-2">
                    {{it.weight ? it.weight : 0}}t,
                    {{it.ware_pcs ? it.ware_pcs : 0}}{{it.ware_pcs && it.ware_type ? it.ware_type : 'ks'}}
                    (Lze {{it.work_day_begin_formatted}}-{{it.work_day_end_formatted}})
                  </div>
                </div>
                <div class="text-truncate" style="max-width: 500px;">
                  {{it.address}}
                </div>
              </div>
            </div>
          </div>
        </ng-template> -->
      </div>
      <!-- just white placeholder -->
      <div *ngIf="event.isPlaceholder" [style.width.%]="event.totalTimePercentage"
      class="progress gantt-part position-relative placeholder">
        &nbsp;
      </div>
    </div>
  </div>

  <!-- possibly other rows -->
  <div *ngIf="conflictingLevels.length">
    <div *ngFor="let events of conflictingLevels" class="clearfix border-top">
      <div *ngFor="let event of events">
        <!-- yellow green agenda -->
        <!-- placement="left auto" container="body" [ngbTooltip]="tooltip" -->
        <div *ngIf="event.isDriving" [style.width.%]="event.totalTimePercentage"
        class="gantt-part position-relative pointer driving"
        (click)="ganttPartClicked(event)">
          <!-- km info - driven/total -->
          <div *ngIf="!event.agenda.express_route_key" class="centered-text">
            <b style="line-height: 16px; font-size: 14px;">{{event.event_info}}</b>
          </div>

          <!-- Express route -->
          <!-- top part -->
          <div *ngIf="event.agenda.express_route_key" style="height: 8px;"
          [class]="'progress position-relative ' + (event.agenda?.first_itinerary?.completed ? 'redBackground' : event.agenda.backgroundColorClass)">
            &nbsp;
          </div>
          <!-- bottom part  -->
          <div *ngIf="event.agenda.express_route_key" [class]="'radius ' + event.agenda.backgroundColorClass" style="height: 8px;">
            &nbsp;
          </div>

          <!-- Standard obligation -->
          <!-- top part - obligations status color -->
          <div *ngIf="!event.agenda.express_route_key" [class]="'radius progress position-relative ' + event.agenda.backgroundColorClass" style="height: 8px;">
            &nbsp;
          </div>
          <!-- bottom part progress -->
          <div *ngIf="!event.agenda.express_route_key" class="progress position-relative" style="height: 8px;">
            <div class="progress-bar clearfix greenBackground" [style.width.%]="event.completedPercentage">
            </div>
          </div>

          <!-- bootstrap tooltip template -->
          <!-- <ng-template #tooltip>
            <div>
              {{event.agenda.order_number}}&nbsp;&nbsp;({{event.event_info}}km)
            </div>
            <div>
              {{event.agenda_comp_book_formatted}}
            </div>
            <div *ngFor="let it of event.agenda.itinerary">
              <div class="d-flex align-items-center">
                <div style="width: 28px;">
                  <img class="icon-lg" [src]="icons[it.type_marker].url"/>
                </div>
                <div>
                  <div class="d-flex">
                    <div>
                      {{it.arrival_time_formatted}}
                    </div>
                    <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING" class="ml-2">
                      {{it.weight ? it.weight : 0}}t,
                      {{it.ware_pcs ? it.ware_pcs : 0}}{{it.ware_pcs && it.ware_type ? it.ware_type : 'ks'}}
                      (Lze {{it.work_day_begin_formatted}}-{{it.work_day_end_formatted}})
                    </div>
                  </div>
                  <div class="text-truncate" style="max-width: 500px;">
                    {{it.address}}
                  </div>
                </div>
              </div>
            </div>
          </ng-template> -->
        </div>
        <!-- just white placeholder -->
        <div *ngIf="event.isPlaceholder" [style.width.%]="event.totalTimePercentage"
        class="gantt-part placeholder">
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</div>