import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Agenda } from 'src/app/model/agenda.object';
import { Company } from 'src/app/model/company.object';
import { TimocomFreightSearchObject } from 'src/app/model/databank-timocom.object';
import { DateTools } from "../../tools/DateTools";
import { TimocomService } from 'src/app/service/timocom.service';
import { ObligationTools } from 'src/app/tools/ObligationTools';
import { GoogleLatLng } from 'src/app/model/google-lat-lng.object';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { GeoPosition } from 'src/app/model/geo-position.object';

@Component({
  selector: 'app-timocom',
  templateUrl: './timocom.component.html',
  styleUrls: ['./timocom.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TimocomService
  ]
})

export class TimocomComponent implements OnInit {
  public response:any = null;

  private _subscribed: Array<any> = [];
  
  private _agenda: Agenda;
  @Input()
  public set agenda(value: Agenda) {
    this._agenda = value;
    this.showMap = false;
    this.loadTimocomData();
  }
  public get agenda(): Agenda {
    return this._agenda;
  }

  private _company: Company;
  public get company(): Company {
    return this._company;
  }
  @Input()
  public set company(value: Company) {
    this._company = value;
  }

  private _onTheWay: boolean = false;
  public get onTheWay(): boolean {
    return this._onTheWay;
  }
  @Input()
  public set onTheWay(value: boolean) {
    this._onTheWay = value;
    if (this._onTheWay) {
      this.showMapSwitchId = 'showMapSwitchOnTheWay';
    }
    //console.log(value);
    this.detectChanges();
  }

  private _backHome: boolean = false;
  public get backHome(): boolean {
    return this._backHome;
  }
  @Input()
  public set backHome(value: boolean) {
    this._backHome = value;
    if (this._backHome) {
      this.showMapSwitchId = 'showMapSwitchBackHome';
    }
    this.detectChanges();
  }
  
  private _reload: number = 0;
  @Input()
  public set reload(value: number) {
    this._reload = value;
    //console.log('RELOAD!');
    //console.log(this._reload);
    if (this._reload && this._agenda && this._company) {
      this.showMap = false;
      this.groupMarkers = [[]];
      this.loadTimocomData();
    }
  }

  private _serchrangeStartOnWay: number = 100;
  public get serchrangeStartOnWay(): number {
    return this._serchrangeStartOnWay;
  }
  @Input()
  public set serchrangeStartOnWay(value: number) {
    this._serchrangeStartOnWay = value;
    this.detectChanges();
  }

  private _serchrangeEndOnWay: number = 100;
  public get serchrangeEndOnWay(): number {
    return this._serchrangeEndOnWay;
  }
  @Input()
  public set serchrangeEndOnWay(value: number) {
    this._serchrangeEndOnWay = value;
    this.detectChanges();
  }

  private _serchrangeStartHome: number = 100;
  public get serchrangeStartHome(): number {
    return this._serchrangeStartHome;
  }
  @Input()
  public set serchrangeStartHome(value: number) {
    this._serchrangeStartHome = value;
    this.detectChanges();
  }

  private _serchrangeEndHome: number = 100;
  public get serchrangeEndHome(): number {
    return this._serchrangeEndHome;
  }
  @Input()
  public set serchrangeEndHome(value: number) {
    this._serchrangeEndHome = value;
    this.detectChanges();
  }


  constructor(
    private _timocomServ: TimocomService,
    private _cdr: ChangeDetectorRef
  ) { 
    // custom change detection strategy
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 5000
    );
  }

  ngOnInit(): void {
    if(this.agenda && this.company){
      this.showMap = false;
      this.groupMarkers = [];
      if(this._backHome){
        this.prepareSearchObjectFromLastToHome(this.serchrangeStartHome,this.serchrangeEndHome);
      }
      if(this.onTheWay){
        this.prepareSearchObjectOnTheWay(this.serchrangeStartOnWay,this.serchrangeEndOnWay);
      }
    }
  }

  detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 250
    );
  }

  loadTimocomData(): void { 
    // reinit
    this.response = null;
    this._groupMarkers = [];
    this.detectChanges();

    window.setTimeout(
      () => {
        if (this.agenda && this.company){
          let data : any;
          if (this._backHome){
             data = this.prepareSearchObjectFromLastToHome(this.serchrangeStartHome,this.serchrangeEndHome);
          }else{
            data = this.prepareSearchObjectOnTheWay(this.serchrangeStartOnWay,this.serchrangeEndOnWay);
          }
          this._subscribed.push(
            this._timocomServ.createFreightOfferProxy(data).subscribe(
              offers => {
                if (offers) {
                  this.response = offers;
                  //console.log(this.response);
                  if (this.response && this.response.payload && this.response.payload.length) {
                    this.response.payload.forEach(
                      item => {
                        //console.log(item);
                        if (item && item.loadingPlaces[0] && item.loadingPlaces[0].earliestLoadingDate) {
                          item.earliestLoadingDateObject = new Date(item.loadingPlaces[0].earliestLoadingDate);
                        }


                        if (item.loadingPlaces.length > 1) {
                          let mapMarker: GoogleMapMarker;

                          let lineCoord: Array<GoogleMapMarker> = [];
                          let gps_coord: GeoPosition = new GeoPosition();
                          let gps_coord2: GeoPosition = new GeoPosition();

                          if (item.loadingPlaces[0].address.geoCoordinate.latitude && item.loadingPlaces[0].address.geoCoordinate.longitude) {
                            gps_coord.gps_coord = item.loadingPlaces[0].address.geoCoordinate.latitude+","+ item.loadingPlaces[0].address.geoCoordinate.longitude;
                            mapMarker = this.createMarker(gps_coord.googleLatLang,item.loadingPlaces[0].loadingType,item.deeplink,item.weight_t);
                            lineCoord.push(mapMarker);
                          }                          
                          
                          if (item.loadingPlaces[item.loadingPlaces.length-1].address.geoCoordinate.latitude && item.loadingPlaces[item.loadingPlaces.length-1].address.geoCoordinate.longitude) {
                            gps_coord2.gps_coord = item.loadingPlaces[item.loadingPlaces.length-1].address.geoCoordinate.latitude+","+item.loadingPlaces[item.loadingPlaces.length-1].address.geoCoordinate.longitude;
                            mapMarker = this.createMarker(gps_coord2.googleLatLang, item.loadingPlaces[item.loadingPlaces.length-1].loadingType,item.deeplink,item.weight_t); 
                            lineCoord.push(mapMarker);
                          }

                          this.groupMarkers.push(lineCoord);
                        }
                      }
                    );

                  }
                  this.detectChanges();
                }
              }
            )
          );
          this.detectChanges();
        }
      }, 1000
    );
  }

    // method for creating new marker on google map in right part
    private createMapWay(startPoint: any,endPoint:any){


    }

  
  vehicleBodyTaToTimmocom(taString:string):string{
    switch(taString){
      case "B":return "BOX";
      //case "E":return "CAR_TRANSPORTER";
      case "H":return "CHASSIS";
      case "C":return "COIL_WELL";
      case "N":return "CURTAIN_SIDER";
      case "D":return "DUMP_TRAILER";
      //case "V":return "FLATBED";
      //case "R":return "INLOADER";
      case "J":return "JUMBO";
      case "E":return "MEGA";
      case "1":return "MOVING_FLOOR";
      case "O":return "PLATFORM";
      case "F":return "REFRIGERATOR";
      case "S":return "SILO";
      case "X":return "SPECIAL_TRUCK";
      case "T":return "TANK";
      case "L":return "TAUTLINER";
      case "I":return "THERMO";
    }
    return ""

  }

  vehicleTypeTaToTimmocom(taData:number):string{
    //console.log(taData);

    if(taData > 12 ){
      return "TRAILER";
    }
    if(taData <=12 && taData > 7.5){
      //console.log("do 12");
      return "VEHICLE_UP_TO_12_T";
    }
    if(taData <= 7.5 && taData > 3.5){
      return "VEHICLE_UP_TO_7_5_T";
      //console.log("do 7.5");
    }if(taData <= 3.5){
      //console.log("do 3.5");
      return "VEHICLE_UP_TO_3_5_T";
    }
  }

  prepareSearchObjectOnTheWay(searchDestinationStart,searchDestinationEnd){
    let data = new TimocomFreightSearchObject;
    let itineraryStart = this.agenda.itinerary[0];
    let itinerary = this.agenda.itinerary[this.agenda.itinerary.length - 1];
    if(itineraryStart.place_country){
      data.startLocationCountry = itineraryStart.place_country;
    }
    if(itineraryStart.gps_coord.gps_coord){
    data.startLocatiLatitude = itineraryStart.gps_coord.gps_coord.split(",")[0].replace("(","");
      data.startLocationLongitude = itineraryStart.gps_coord.gps_coord.split(",")[1].replace(")","");
    }
    if(itineraryStart.place_zip){
      data.startLocationPostalCode = itineraryStart.place_zip.toString();
    }   


    if(itinerary.place_country){
      data.destinationLocationCountry = itinerary.place_country;
    }
    if(itinerary.gps_coord.gps_coord){
      data.destinationLocatiLatitude = itinerary.gps_coord.gps_coord.split(",")[0].replace("(","");
    }
    if(itinerary.gps_coord.gps_coord){
      data.destinationLocationLongitude = itinerary.gps_coord.gps_coord.split(",")[1].replace(")","");
    }
    if(itinerary.place_zip){
      data.destinationLocationPostalCode = itinerary.place_zip.toString();
    }

    data.destinationLocationSize_km = searchDestinationStart;
    data.startLocationSize_km = searchDestinationEnd;
    data.vehicleBody = this.vehicleBodyTaToTimmocom(this.agenda.vehicle.type);
    if (!data.vehicleBody) data.vehicleBody = 'BOX';
    data.vehicleType = this.vehicleTypeTaToTimmocom(this.agenda.vehicle.car_tonnage);
    data.exclusiveLeftLowerBoundDateTime = itinerary.arrival_time_custom +":00Z";
    var dateTest = new Date(itinerary.arrival_time_custom.split("T")[0]);
    dateTest = this.addDays(dateTest,2);
    data.inclusiveRightUpperBoundDateTime = DateTools.toCustomIso(dateTest).split(".")[0]+"Z";  

    let gps_coord: GeoPosition = new GeoPosition();
    let gps_coord2: GeoPosition = new GeoPosition();
    let mapMarker:GoogleMapMarker;
    let mapMarker2:GoogleMapMarker;
    gps_coord.gps_coord = data.startLocatiLatitude +","+ data.startLocationLongitude
    mapMarker = this.createMarker(gps_coord.googleLatLang,"autoStart","","");

    gps_coord2.gps_coord = data.destinationLocatiLatitude+","+ data.destinationLocationLongitude;
    mapMarker2 = this.createMarker(gps_coord2.googleLatLang,"autoEnd","","");

    this.groupMarkers.push([mapMarker,mapMarker2]); 
    return data;
  }


  prepareSearchObjectFromLastToHome(searchDestinationStart,searchDestinationEnd){
    let data = new TimocomFreightSearchObject;
    let itinerary = this.agenda.itinerary[this.agenda.itinerary.length - 1];
    if(itinerary.place_country){
      data.startLocationCountry = itinerary.place_country;
    }
    if(itinerary.gps_coord.gps_coord){
      data.startLocatiLatitude = itinerary.gps_coord.gps_coord.split(",")[0].replace("(","");
      data.startLocationLongitude = itinerary.gps_coord.gps_coord.split(",")[1].replace(")","");
    }
    if(itinerary.place_zip){
      data.startLocationPostalCode = itinerary.place_zip.toString();
    }   
    if(this.agenda.vehicle.home_stand.country){
      data.destinationLocationCountry = this.agenda.vehicle.home_stand.country;
    }
    if(this.agenda.vehicle.home_stand.geoPosition.lat.toString()){
      data.destinationLocatiLatitude = this.agenda.vehicle.home_stand.geoPosition.lat.toString();
    }
    if(this.agenda.vehicle.home_stand.geoPosition.lng.toString()){
      data.destinationLocationLongitude = this.agenda.vehicle.home_stand.geoPosition.lng.toString();
    }
    if(this.agenda.vehicle.home_stand.zip){
      data.destinationLocationPostalCode = this.agenda.vehicle.home_stand.zip;
    }
    
    data.destinationLocationSize_km = searchDestinationEnd;
    data.startLocationSize_km = searchDestinationStart;
    data.vehicleBody = this.vehicleBodyTaToTimmocom(this.agenda.vehicle.type);
    if (!data.vehicleBody) data.vehicleBody = 'BOX';
    data.vehicleType = this.vehicleTypeTaToTimmocom(this.agenda.vehicle.car_tonnage);
    data.exclusiveLeftLowerBoundDateTime = itinerary.arrival_time_custom +":00Z";
    var dateTest = new Date(itinerary.arrival_time_custom.split("T")[0]);
    dateTest = this.addDays(dateTest,2);
    data.inclusiveRightUpperBoundDateTime = DateTools.toCustomIso(dateTest).split(".")[0]+"Z";  

    let gps_coord: GeoPosition = new GeoPosition();
    let gps_coord2: GeoPosition = new GeoPosition();
    let mapMarker:GoogleMapMarker;
    let mapMarker2:GoogleMapMarker;
    gps_coord.gps_coord = data.startLocatiLatitude +","+ data.startLocationLongitude
    mapMarker = this.createMarker(gps_coord.googleLatLang,"autoStart","","");
    gps_coord2.gps_coord = data.destinationLocatiLatitude+","+ data.destinationLocationLongitude;
    mapMarker2 = this.createMarker(gps_coord2.googleLatLang,"autoEnd","","");
    this.groupMarkers.push([mapMarker,mapMarker2]); 
    return data;
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  public computeTransit2(item: any): number {
    let itinerary = this.agenda.itinerary[this.agenda.itinerary.length - 1];
    if(item){
        if(itinerary.gps_coord.gps_coord && this.agenda.vehicle.home_stand.geoPosition){
          //let firtstTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[0].address.geoCoordinate.latitude,item.loadingPlaces[0].address.geoCoordinate.longitude),new GoogleLatLng(Number(itinerary.gps_coord.gps_coord.split(",")[0].replace("(","")),Number(itinerary.gps_coord.gps_coord.split(",")[1].replace(")",""))));
          let secondTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[item.loadingPlaces.length -1].address.geoCoordinate.latitude,item.loadingPlaces[item.loadingPlaces.length -1].address.geoCoordinate.longitude),
                                                                        new GoogleLatLng(this.agenda.vehicle.home_stand.geoPosition.lat, this.agenda.vehicle.home_stand.geoPosition.lng));
          return secondTransit;
      }else{
        return 0;
      }
    }else{
      return 0;
    }
  }

  public computeTransit(item: any): number {
    let itinerary = this.agenda.itinerary[this.agenda.itinerary.length - 1];
    if(item){
        if(itinerary.gps_coord.gps_coord && this.agenda.vehicle.home_stand.geoPosition){
          let firtstTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[0].address.geoCoordinate.latitude,item.loadingPlaces[0].address.geoCoordinate.longitude),
                                                                        new GoogleLatLng(Number(itinerary.gps_coord.gps_coord.split(",")[0].replace("(","")),Number(itinerary.gps_coord.gps_coord.split(",")[1].replace(")",""))));
          //let secondTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[1].address.geoCoordinate.latitude,item.loadingPlaces[1].address.geoCoordinate.longitude),new GoogleLatLng(this.agenda.vehicle.home_stand.geoPosition.lat, this.agenda.vehicle.home_stand.geoPosition.lng));
          return firtstTransit;
      }else{
        return 0;
      }
    }else{
      return 0;
    }
  }


  public computeTransitOnRoad2(item: any): number {
    let itinerary = this.agenda.itinerary[this.agenda.itinerary.length - 1];
    if(item){
        if(itinerary.gps_coord.gps_coord && this.agenda.vehicle.home_stand.geoPosition){
          //let firtstTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[0].address.geoCoordinate.latitude,item.loadingPlaces[0].address.geoCoordinate.longitude),new GoogleLatLng(Number(itinerary.gps_coord.gps_coord.split(",")[0].replace("(","")),Number(itinerary.gps_coord.gps_coord.split(",")[1].replace(")",""))));
          let secondTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[item.loadingPlaces.length-1].address.geoCoordinate.latitude,item.loadingPlaces[item.loadingPlaces.length-1].address.geoCoordinate.longitude),
                                                                        new GoogleLatLng(Number(itinerary.gps_coord.gps_coord.split(",")[0].replace("(","")),Number(itinerary.gps_coord.gps_coord.split(",")[1].replace(")",""))));                                                            
          return secondTransit;
      }else{
        return 0;
      }
    }else{
      return 0;
    }
  }

  public computeTransitOnRoad(item: any): number {
    let itinerary = this.agenda.itinerary[this.agenda.itinerary.length - 1];
    if(item){
        if(itinerary.gps_coord.gps_coord && this.agenda.vehicle.home_stand.geoPosition){
          let firtstTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[0].address.geoCoordinate.latitude,item.loadingPlaces[0].address.geoCoordinate.longitude),
                                                                        new GoogleLatLng(Number(itinerary.agenda.itinerary[0].gps_coord.gps_coord.split(",")[0].replace("(","")),Number(itinerary.agenda.itinerary[0].gps_coord.gps_coord.split(",")[1].replace(")",""))));
          
                                                    //let secondTransit = ObligationTools.computeDistanceEstimation(new GoogleLatLng(item.loadingPlaces[1].address.geoCoordinate.latitude,item.loadingPlaces[1].address.geoCoordinate.longitude),new GoogleLatLng(this.agenda.vehicle.home_stand.geoPosition.lat, this.agenda.vehicle.home_stand.geoPosition.lng));
          return firtstTransit;
      }else{
        return 0;
      }
    }else{
      return 0;
    }
  }

  public testResponse:any = {
    meta: {
      requestId: "47-11-request-id",
      responseTimestamp: "2022-09-13T13:22:09.3011033Z"
    },
    payload: [
      {
        closedFreightExchangeSetting: {
          closedFreightExchangeId: 288,
          publicationDateTime: "2019-08-24T14:15:22Z",
          publicationType: "INTERNAL_ONLY",
          remark: "External Remark",
          resetRetentionDuration: true,
          retentionDurationInMinutes: 5
        },
        contactPerson: {
          businessPhone: "+49 211 88 26 88 26",
          email: "schnittstellen@timocom.com",
          fax: "+49 211 88 26 88 26",
          firstName: "Fernández",
          languages: [
            "de"
          ],
          lastName: "Hernández",
          mobilePhone: "+49 211 88 26 88 26",
          title: "MR"
        },
        creationDateTime: "2019-08-24T14:15:22Z",
        customer: {
          companyAddress: {
            city: "Gerona",
            country: "ES",
            geoCoordinate: {
              longitude: 2.82493,
              latitude: 41.98311
            },
            geocoded: false,
            postalCode: "17001",
            streetOrPostbox: "PO 3454"
          },
          creationDateTime: "2019-08-24T14:15:22Z",
          fax: "+49 211 88 26 88 26",
          id: 288,
          name: "Hernández y Fernández S.A.",
          phone: "+49 211 88 26 88 26",
          postalAddress: {
            city: "Gerona",
            country: "ES",
            geoCoordinate: {
              longitude: 2.82493,
              latitude: 41.98311
            },
            geocoded: false,
            postalCode: "17001",
            streetOrPostbox: "PO 3454"
          },
          taxId: "TAX-47-87"
        },
        deeplink: "https://timocom.com/example/freight/hggaXk77z",
        id: "offer-471-publicId",
        internalRemark: "This remark is visible to users of your company, only",
        logisticsDocumentTypes: [
          "string"
        ],
        publicRemark: "This remark is visible to all",
        trackable: true,
        vehicleProperties: {
          body: [
            "MOVING_FLOOR"
          ],
          bodyProperty: [
            "FOLDING_SIDE_BOX"
          ],
          equipment: [
            "PORTABLE_FORKLIFT"
          ],
          loadSecuring: [
            "BOARD_WALL"
          ],
          swapBody: [
            "FORTY_FIVE_FEET_CONTAINER"
          ],
          type: [
            "VEHICLE_UP_TO_12_T"
          ]
        },
        start: {
          city: "Gerona",
          country: "ES",
          geoCoordinate: {
            longitude: 2.82493,
            latitude: 41.98311
          },
          geocoded: false,
          postalCode: "17001"
        },
        destination: {
          objectType: "string",
          area: {
            address: {
              city: "Gerona",
              country: "ES",
              geoCoordinate: {
                longitude: 2.82493,
                latitude: 41.98311
              },
              geocoded: false,
              postalCode: "17001"
            },
            size_km: 55
          },
          additionalProperties: null
        },
        loadingDate: "2019-08-24",
        trailerLength_m: 12.31,
        truckLength_m: 12.31,
        trailerWeight_t: 5.55,
        truckWeight_t: 5.55
      }
    ]
  }

  /************************************************************/
  // Google map stuff
  /************************************************************/
  public showMap: boolean = false;
  public showMapSwitchId: string = 'showMapSwitch';
  public mapMarkers: Array<GoogleMapMarker> = [];

  private _groupMarkers: Array<Array<GoogleMapMarker>> = [];
  public get groupMarkers(): Array<Array<GoogleMapMarker>> {
    return this._groupMarkers;
  }
  public set groupMarkers(value: Array<Array<GoogleMapMarker>>) {
    this._groupMarkers = value;
  }


  private _markers: Array<GoogleMapMarker> = [];
  public get markers(): Array<GoogleMapMarker> {
    return this._markers;
  }
  public set markers(value: Array<GoogleMapMarker>) {
    this._markers = value;
  }

  // method for creating marker on google map in confirmation modal
  private createMarker(location: any,stateOfCargo:string,hiperling:string,tonage:string): GoogleMapMarker {
    // this.createMarker(itinerary.gps_coord.googleLatLang);
    let marker = new GoogleMapMarker();
    marker.position = location;
    marker.draggable = false;
    marker.setData('type', 2);
    marker.setData('id', Math.random().toString(36).substring(7));
    if(stateOfCargo == "LOADING"){
      marker.icon = "assets/icon/icon-car-state/loading.svg"
    }
    
    if(stateOfCargo == "UNLOADING"){
      marker.icon = "assets/icon/icon-car-state/unloading.svg"
    }

    if(stateOfCargo == "autoStart"){
      marker.icon = "assets/icon/icon-car-state/load.svg"
    }

    if(stateOfCargo == "autoEnd"){
      marker.icon = "assets/icon/icon-car-state/unload.svg"
    }
    marker.hipperling = hiperling;
    marker.tonage = tonage;
    return marker;
  }


}