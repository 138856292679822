import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { Vehicle } from "../../model/vehicle.object";

@Component({
  selector: 'div.vehicle-diagnostics-component',
  templateUrl: './r-vehicle-diagnostics.component.html',
  styleUrls: ['./r-vehicle-diagnostics.component.css']
  // templateUrl: '/app/templates/vehicle-diagnostics.component.html?v=' + Date.now()
})
export class RVehicleDiagnosticsComponent implements OnInit, OnDestroy {

  constructor(
  ) {
  }

  private _vehicle: Vehicle;
  @Input()
  set vehicle(value: Vehicle) {
    this._vehicle = value;
  }

  ngOnInit() {
    if (!this._vehicle) {
      throw new Error('No vehicle provided');
    }
  }

  ngOnDestroy() {
  }

  newDiagnosticRequest() {
    alert('Requesting new diagnostics! - demonstration click only');
  }

  setApnRequest() {
    alert('Setting APN request! - demonstration click only');
  }

  enlightenDriver() {
    alert('Sending diagnostics info to driver - demonstration click only');
  }

  reportProblem() {
    alert('Reporting problem - demonstration click only');
  }

}
