import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { ReachEvent } from "../model/reach-event.object";
import { Vehicle } from "../model/vehicle.object";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class ReachEventService {

  private _apiUrl: string = ServiceConfiguration.reachEvent.api;
  private _reachEvents: BehaviorSubject<Array<ReachEvent>> = new BehaviorSubject([]);
  private _reachEventsCache: Array<ReachEvent> = [];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  private _loadingReachEvents: boolean = false;

  get loadingReachEvents(): boolean {
    return this._loadingReachEvents;
  }

  public clearCache(): void {
    this._reachEventsCache = [];
  }

  getReachEvents(): Observable<Array<ReachEvent>> {
    if (!this._reachEventsCache.length && !this._loadingReachEvents) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadReachEvents();
      }
    }
    return this._reachEvents.asObservable();
  }

  loadReachEvents() {
    this._loadingReachEvents = true;
    this._http.get(this._apiUrl)
      .subscribe(
        response => {
          if (response) {
            this._reachEventsCache = this.buildReachEventsFromData(response);
            // push reachEvents
            this._reachEvents.next(this._reachEventsCache);
          }
        },
        error => {
          //handle error
          console.log(error);
          this._loadingReachEvents = false;
        },
        () => {
          // finally
          this._loadingReachEvents = false;
        }
      );
  }

  // method for creating new reach event (returning reach_key when succeed)
  createReachEvent(type: string, vehicle: Vehicle): Observable<any> {
    let reachEvent: BehaviorSubject<number> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let data = {
        car_key: vehicle.car_key,
        type: type
      };
      this._http.post(this._apiUrl, data).subscribe(
        success => {
          if (success) {
            reachEvent.next(success.reach_key);
          }
          this._notificationService.alert(
            $localize`Hlášení vozidla %SPZ byly upraveny.`.replace('%SPZ', vehicle.number_plate),
            'success', 3000
          );
        },
        error => {
          // handle error
          console.log(error);
          this._notificationService.alert(
            $localize`Chyba úpravy systémových hlášení vozidla %SPZ.`.replace('%SPZ', vehicle.number_plate),
            'error', 3000
          );
        }
      );
    }
    return reachEvent.asObservable();
  }

  // method for deleting reach event (returning reach_key when succeed)
  deleteReachEvent(reach_key: number, type: string, vehicle: Vehicle): Observable<any> {
    let reachEvent: BehaviorSubject<number> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.delete(this._apiUrl + reach_key).subscribe(
        success => {
          if (success) {
            reachEvent.next(success.reach_key);
          }
          this._notificationService.alert(
            $localize`Hlášení vozidla %SPZ byly upraveny.`.replace('%SPZ', vehicle.number_plate),
            'success', 3000
          );
        },
        error => {
          // handle error
          console.log(error);
          this._notificationService.alert(
            $localize`Chyba úpravy systémových hlášení vozidla %SPZ.`.replace('%SPZ', vehicle.number_plate),
            'error', 3000
          );
        }
      );
    }
    return reachEvent;
  }

  // method for creating reach event objects from api objects
  private buildReachEventsFromData(data: Array<any>): any {
    let reachEvents: Array<ReachEvent> = [];
    data.forEach(
      re => {
        let reachEvent: ReachEvent = new ReachEvent();
        for (let key in re) {
          reachEvent[key] = re[key];
        }
        reachEvents.push(reachEvent);
      }
    );
    return reachEvents;
  }
}
