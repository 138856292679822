import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { Make } from "../model/make.object";
import { IS_DEMO, ServiceConfiguration } from "../config";

@Injectable({
  providedIn: 'root',
})
export class MakeService {

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService
  ) {
  }

  private _loadingVehicleMakes: boolean = false;
  get loadingVehicleMakes(): boolean {
    return this._loadingVehicleMakes;
  }

  private _loadingTrailerMakes: boolean = false;
  get loadingTrailerMakes(): boolean {
    return this._loadingTrailerMakes;
  }

  getVehicleMakes(): Observable<Array<Make>> {
    let result: BehaviorSubject<Array<Make>> = new BehaviorSubject([]);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingVehicleMakes = true;
      this._http.get(ServiceConfiguration.vehicle.apiMakes).subscribe(
        response => {
          let makes = this.buildMakesFromData(response);
          result.next(makes);
          this._loadingVehicleMakes = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loadingVehicleMakes = false;
        }
      );
    }
    return result.asObservable();
  }

  getTrailerMakes(): Observable<Array<Make>> {
    let result: BehaviorSubject<Array<Make>> = new BehaviorSubject([]);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingTrailerMakes = true;
      this._http.get(ServiceConfiguration.trailers.apiMakes).subscribe(
        response => {
          let makes = this.buildMakesFromData(response);
          result.next(makes);
          this._loadingTrailerMakes = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loadingTrailerMakes = false;
        }
      );
    }
    return result.asObservable();
  }

  // method for creating makes objects from api objects
  private buildMakesFromData(data: Array<any>): any {
    let makes: Array<Make> = [];
    data.forEach(
      m => {
        let make: Make = this.buildMake(m);
        makes.push(make);
      }
    );
    return makes;
  }

  // method for creating a single make object
  private buildMake(o: any): Make {
    let make: Make = new Make();
    for (let key in o) {
      make[key] = o[key];
    }
    return make;
  }
}
