import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { defaultLengthUnit, lengthUnits, ServiceConfiguration } from "../config";
import { StorageService } from "./storage.service";
import { AuthenticationService } from "./authentication.service";
import { UserConfigurationInterface } from "../interface/user-configuration.interface";
import { User } from "../model/user.object";
import { ArrayCompare } from "../tools/ArrayCompare";
import { ObjectCompare } from "../tools/ObjectCompare";
import { VehicleListConfigurationInterface } from "../interface/vehicle-list-configuration.interface";
import { MessagesConfigurationInterface } from "../interface/messages-configuration.interface";

@Injectable({
  providedIn: 'root',
})
export class UserConfigurationService {

  static CONFIGURATION_KEY = 'usrConf';
  private _isLocalySaved: boolean = true;

  private _configuration: UserConfigurationInterface;
  get configuration(): UserConfigurationInterface {
    return this._configuration;
  }

  private _configChanged: Subject<any> = new Subject();
  get configChanged(): Observable<boolean> {
    return this._configChanged.asObservable();
  }

  constructor(
    private _storage: StorageService, 
    private _authService: AuthenticationService
  ) {
    let storedConfig = this.loadStoredConfig();
    if (storedConfig) {
      // Object.assign can't manage nested objects
      let vehicleListConfiguration: VehicleListConfigurationInterface = Object.assign(
        ServiceConfiguration.userConfiguration.defaultVehicleListConfiguration,
        JSON.parse(JSON.stringify(storedConfig.defaultVehicleListConfiguration))
      );
      
      let messagesConfiguration: MessagesConfigurationInterface = Object.assign(
        ServiceConfiguration.userConfiguration.defaultMessagesConfiguration,
        JSON.parse(JSON.stringify(storedConfig.defaultMessagesConfiguration))
      );

      this._configuration = {
        defaultVehicleListConfiguration: vehicleListConfiguration,
        defaultMessagesConfiguration: messagesConfiguration
      }
    } 
    else {
      this._configuration = ServiceConfiguration.userConfiguration;
    }
    
    this._authService.authenticationResult.subscribe(
      (user: User) => {
        this.setVehicleFilter(user);
        this.setVehiclePersonFilter(user);
        this._configChanged.next(true);
      }
    );
  }

  private static _global_length_unit: string = defaultLengthUnit;
  static get global_length_unit(): string {
    return this._global_length_unit;
  }
  static set global_length_unit(unit: string) {
    if (lengthUnits.indexOf(unit) === -1) {
      throw new Error('Undefined length unit "' + unit + '"');
    }
    this._global_length_unit = unit;
  }

  saveUserConfiguration(configuration?: UserConfigurationInterface) {
    if (!configuration) {
      configuration = this._configuration;
    } 
    else {
      configuration = JSON.parse(JSON.stringify(configuration));
    }
    let changeSet = this.getConfigDiff(configuration);
    this._configuration = configuration;
    this.setVehicleFilter(this._authService.user);
    this.setVehiclePersonFilter(this._authService.user);
    this.saveConfig(configuration, UserConfigurationService.CONFIGURATION_KEY);
    this._configChanged.next(changeSet);
  }

  private setVehicleFilter(user?: User) {
    let key = user ? user.company_key : 0;
    if (!this._configuration.defaultVehicleListConfiguration.vehicle_filters) {
      this._configuration.defaultVehicleListConfiguration.vehicle_filters = {};
    }
    if (this._configuration.defaultVehicleListConfiguration.vehicle_filters[key]) {
      this._configuration.defaultVehicleListConfiguration.vehicle_filter = this._configuration.defaultVehicleListConfiguration.vehicle_filters[key];
    } 
    else {
      this._configuration.defaultVehicleListConfiguration.vehicle_filter = [];
    }
  }
  
  private setVehiclePersonFilter(user?: User) {
    let key = user ? user.company_key : 0;
    if (!this._configuration.defaultVehicleListConfiguration.vehicle_person_filters) {
      this._configuration.defaultVehicleListConfiguration.vehicle_person_filters = {};
    }
    if (this._configuration.defaultVehicleListConfiguration.vehicle_person_filters[key]) {
      this._configuration.defaultVehicleListConfiguration.vehicle_person_filter = this._configuration.defaultVehicleListConfiguration.vehicle_person_filters[key];
    } 
    else {
      this._configuration.defaultVehicleListConfiguration.vehicle_person_filter = [];
    }
  }

  private loadStoredConfig(): UserConfigurationInterface {
    return this.getConfig(UserConfigurationService.CONFIGURATION_KEY);
  }

  private getConfig(key: string): any {
    return JSON.parse(this._storage.getItem(key, this._isLocalySaved));
  }

  private saveConfig(config: any, key: string) {
    this._storage.setItem(key, JSON.stringify(config), this._isLocalySaved);
  }

  private getConfigDiff(configuration: UserConfigurationInterface): Object {
    let changeSet = {};
    for (let keyUpper in configuration) {
      for (let keyBottom in configuration[keyUpper]) {
        let value = configuration[keyUpper][keyBottom];
        let same =
          (value instanceof Array
            ? ArrayCompare.haveSameContent(this._configuration[keyUpper][keyBottom], value)
            : (value instanceof Object
                ? ObjectCompare.haveSameContent(value, this._configuration[keyUpper][keyBottom])
                : value === this._configuration[keyUpper][keyBottom]
            ));
        if (!same) {
          changeSet[keyBottom] = {
            old: this._configuration[keyUpper][keyBottom],
            new: value
          };
        }
      }
    }
    return changeSet;
  }
}
