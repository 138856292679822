import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IS_DEMO, ServiceConfiguration } from "../config";
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { ServiceTask } from '../model/service-task.object';
import { Vehicle } from '../model/vehicle.object';
import { Trailer } from '../model/trailer.object';
import { InvoiceTools } from '../tools/InvoiceTools';
import { Invoice } from '../model/invoice.object';

@Injectable({
  providedIn: 'root'
})
export class ServiceTaskService {

  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }

  constructor(
    private _http: HttpClientService,
    private _authServ: AuthenticationService,
    private _notificationServ: NotificationService
  ) {
  }
  
  // GET /cars/servicing-plan
  getServiceTasks(): Observable<Array<ServiceTask>> {
    let res: BehaviorSubject<Array<ServiceTask>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.servicePlan;
      this._loading = true;

      this._http.get(url).subscribe(
        response => {
          let tasks = this.buildServiceTasksFromData(response);
          res.next(tasks);
          this._loading = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loading = false;
        },
      );
    }

    return res.asObservable();
  }

  // GET /cars/servicing-plan
  getServiceTask(task_key: number): Observable<ServiceTask> {
    let res: BehaviorSubject<ServiceTask> = new BehaviorSubject(null);

    if (task_key && (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated()))) {
      let url: string = ServiceConfiguration.vehicle.servicePlanKey;
      url = url.replace('<TASK_KEY>', task_key.toString());

      this._http.get(url).subscribe(
        response => {
          if (response && response.length) {
            // response is array - the task is the first item
            let task = this.buildServiceTask(response[0]);
            res.next(task);
          }
        },
        error => {
          //handle error
          console.log(error);
        },
      );
    }

    return res.asObservable();
  }

  // GET /cars/servicing-plan/?car_key=<KEY>
  getCarServiceTasks(car: Vehicle): Observable<Array<ServiceTask>> {
    let res: BehaviorSubject<Array<ServiceTask>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.servicePlan;
      url += '?car_key=' + car.car_key;
      car.loadingTasks = true;

      this._http.get(url).subscribe(
        response => {
          let tasks = this.buildServiceTasksFromData(response);
          res.next(tasks);
          car.loadingTasks = false;
        },
        error => {
          //handle error
          console.log(error);
          car.loadingTasks = false;
        }
      );
    }

    return res.asObservable();
  }

    // GET /cars/servicing-plan/?car_key=<KEY>
    getCarServiceTasksByKey(carKey: string): Observable<Array<ServiceTask>> {
      let res: BehaviorSubject<Array<ServiceTask>> = new BehaviorSubject(null);

      if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
        let url: string = ServiceConfiguration.vehicle.servicePlan;
        url += '?car_key=' + carKey;
        this._http.get(url).subscribe(
          response => {
            let tasks = this.buildServiceTasksFromData(response);
            res.next(tasks);
          },
          error => {
            //handle error
            console.log(error);
          }
        );
      }
  
      return res.asObservable();
    }

  // GET /cars/servicing-plan/?trailer_key=<KEY>
  getTrailerServiceTasks(trailer: Trailer): Observable<Array<ServiceTask>> {
    let res: BehaviorSubject<Array<ServiceTask>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.servicePlan;
      url += '?trailer_key=' + trailer.trailer_key;
      trailer.loadingTasks = true;

      this._http.get(url).subscribe(
        response => {
          let tasks = this.buildServiceTasksFromData(response);
          res.next(tasks);
          trailer.loadingTasks = false;
        },
        error => {
          //handle error
          console.log(error);
          trailer.loadingTasks = false;
        }
      );
    }

    return res.asObservable();
  }

  // POST /cars/servicing-plan/
  createServiceTask(task: ServiceTask): Observable<ServiceTask> {
    let createdTask: BehaviorSubject<ServiceTask> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.servicePlan;
      this._http.post(url, task.apiObject).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Servisní úkon byl úspěšně vytvořen.`;
          this._notificationServ.alert(alertSuccess, 'success', 4000);
          
          // observable next
          createdTask.next(this.buildServiceTask(response));
          console.log(response);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření servisního úkonu.`;
          this._notificationServ.alert(alertError, 'error', 4000);
          console.log();
        }
      );
    }

    return createdTask.asObservable();
  }

  // PUT /cars/servicing-plan/
  updateServiceTask(task: ServiceTask): Observable<ServiceTask> {
    let updatedTask: BehaviorSubject<ServiceTask> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.servicePlan + task.task_key;

      this._http.put(url, task.apiObject).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Servisní úkon byl úspěšně upraven.`;
          this._notificationServ.alert(alertSuccess, 'success', 4000);
          
          // observable next
          updatedTask.next(this.buildServiceTask(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě servisního úkonu.`;
          this._notificationServ.alert(alertError, 'error', 4000);
        }
      );
    }

    return updatedTask.asObservable();
  }
  
  // DELETE /cars/servicing-plan/
  deleteServiceTask(task: ServiceTask): Observable<boolean> {
    let res: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.vehicle.servicePlan + task.task_key;

      this._http.delete(url).subscribe(
        response => {
          this._notificationServ.alert(
            $localize`Servisní úkon byl úspěšně odstraněn.`, 'success', 4000
          );
          res.next(true);
        },
        error => {
          //handle error
          console.error(error);
          this._notificationServ.alert(
            $localize`Chyba při odstranění servisního úkonu - kontaktujte podporu.`, 'error', 4000
          );
        }
      );
    }
    return res.asObservable();
  }

  // GET /cars/servicing-plan/<TASK_KEY>/invoices
  getCarServiceTasksInvoices(task_key: number): Observable<Array<Invoice>> {
    let res: BehaviorSubject<Array<Invoice>> = new BehaviorSubject([]);

    if (task_key && (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated()))) {
      let url: string = ServiceConfiguration.vehicle.servicePlanInvoices;
      url = url.replace('<TASK_KEY>', task_key.toString());

      this._http.get(url).subscribe(
        response => {
          let invoices = InvoiceTools.buildInvoicesFromData(response);
          res.next(invoices);
        },
        error => {
          //handle error
          console.log(error);
        }
      );
    }

    return res.asObservable();
  }
  
  // GET /trailers/servicing-plan/<TASK_KEY>/invoices
  // getTrailerServiceTasksInvoices(task_key: number): Observable<Array<Invoice>> {
  //   let res: BehaviorSubject<Array<Invoice>> = new BehaviorSubject([]);

  //   if (task_key && (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated()))) {
  //     let url: string = ServiceConfiguration.trailers.servicePlanInvoices;
  //     url = url.replace('<TASK_KEY>', task_key.toString());

  //     this._http.get(url).subscribe(
  //       response => {
  //         let invoices = InvoiceTools.buildInvoicesFromData(response);
  //         res.next(invoices);
  //       },
  //       error => {
  //         //handle error
  //         console.log(error);
  //       }
  //     );
  //   }

  //   return res.asObservable();
  // }


  // method for creating tasks array
  private buildServiceTasksFromData(data: Array<any>): any {
    let tasks: Array<ServiceTask> = [];
    data.forEach(
      o => {
        let task: ServiceTask = this.buildServiceTask(o);
        tasks.push(task);
      }
    );
    return tasks;
  }

  // method for creating a single task object
  private buildServiceTask(o: any): ServiceTask {
    let task: ServiceTask = new ServiceTask();
    for (let key in o) {
      task[key] = o[key];
    }
    // computing properties
    task.computeColorClass();
    task.computeDepriciated();
    return task;
  }
}