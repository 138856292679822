<div class="modal fade" id="messagesModal" tabindex="-1" role="dialog" aria-labelledby="messagesModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="messagesModalLabel">
          <ng-container i18n>Nové zprávy</ng-container>! {{newMessages.length}}
        </h4>
        <button (click)="close()" type="button" class="close">&times;</button>
      </div>
      <div class="modal-body messages-dialog">
        <div *ngIf="visible">
          <div class="row" *ngFor="let message of newMessages" >
            <div class="col-2 col-sm-1 col-md-1 col-lg-1 text-center">
              <input type="checkbox" (change)="markMessage($event,message)" />
            </div>
            <div class="col-10 col-sm-11 col-md-11 col-lg-11 {{message.message_row_background_color}}">
              <message-row [message]="message" [reply]="true"
                (orderToWienerbergerEvent)="handleOrderToWienerberger($event)"
                (urlWienerbergerEvent)="handleUrlWienerberger($event)"
                (spzToWienerbergerEvent)="handleSpzToWienerberger($event)"
                (driverToWienerbergerEvent)="handleDriverToWienerberger($event)"
                (phoneToWienerbergerEvent)="handlePhoneToWienerberger($event)"
                (loadingDateToWienerbergerEvent)="handleLoadingDateToWienerberger($event)">
              </message-row>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-left">
        <button class="pointer btn btn-primary" (click)="readMarked()" 
        [disabled]="!isAnyMessageMarked()">
          <ng-container i18n>Přečteno označené</ng-container>
        </button>
        <button class="pointer btn btn-danger" (click)="readAll()">
          <ng-container i18n>Přečteno vše</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal for displaying wienerberger specific modal -->
<div class="modal fade" role="dialog" tabindex="-1" id="wienerbergerModal">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <ng-container *ngIf="orderToWienerberger">
          <h4 *ngIf="orderToWienerberger?.obligations?.length" class="modal-title">
            {{orderToWienerberger.obligations[0].order_number}}
            <ng-container *ngIf="orderToWienerberger.obligations[0].itinerary?.length">
              <span *ngFor="let it of orderToWienerberger.obligations[0].itinerary">
                - {{it.place_city}}
              </span>
            </ng-container>
          </h4>
        </ng-container>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-1" i18n>SPZ</label>
              <div class="input-group">
                <input class="form-control form-control-sm" 
                [ngModel]="spzToWienerberger" readonly/>
                <div class="input-group-append">
                  <button class="btn btn-sm btn-warning" type="button" 
                  ngbTooltip="Zkopírovat do schránky" i18n-ngbTooltip
                  (click)="copyToClipboard(spzToWienerberger)">
                    <img src="assets/icons-new/copy.svg" height="16px"/> 
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-1" i18n>Řidič</label>
              <div class="input-group">
                <input class="form-control form-control-sm" 
                [ngModel]="driverToWienerberger" readonly/>
                <div class="input-group-append">
                  <button class="btn btn-sm btn-warning" type="button" 
                  ngbTooltip="Zkopírovat do schránky" i18n-ngbTooltip
                  (click)="copyToClipboard(driverToWienerberger)">
                    <img src="assets/icons-new/copy.svg" height="16px"/> 
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-1" i18n>Telefon</label>
              <div class="input-group">
                <input class="form-control form-control-sm" 
                [ngModel]="phoneToWienerberger" readonly/>
                <div class="input-group-append">
                  <button class="btn btn-sm btn-warning" type="button" 
                  ngbTooltip="Zkopírovat do schránky" i18n-ngbTooltip
                  (click)="copyToClipboard(phoneToWienerberger)">
                    <img src="assets/icons-new/copy.svg" height="16px"/> 
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-1" i18n>Datum a čas nakládky</label>
              <input class="form-control form-control-sm" 
              [ngModel]="loadingDateToWienerberger | date: 'dd.MM.yyyy HH:mm'" readonly/>
            </div>
          </div>
        </div>
        <div *ngIf="orderToWienerberger" class="row mt-4">
          <div class="row mt-4">
            <h6 class="col-12">
              <a [href]="'https://www.wcp-doprava.cz/doprava/wzi.php?czak=' + orderNumberFormattedToWienerberger" target="_blank">
                https://www.wcp-doprava.cz/doprava/wzi.php?czak={{orderNumberFormattedToWienerberger}}
              </a>
            </h6>
            <h6 class="col-12">
              <a [href]="'https://www.tondach-doprava.cz/doprava/wzi.php?czak=' + orderNumberFormattedToWienerberger" target="_blank">
                https://www.tondach-doprava.cz/doprava/wzi.php?czak={{orderNumberFormattedToWienerberger}}
              </a>
            </h6>
            <!-- <div *ngIf="urlWienerberger" class="col-12">
              <iframe name="wienerberger-iframe" style="height: 30rem !important"
                [src]="'https://www.seznam.cz' | safeResource">
              </iframe>
              <iframe name="wienerberger-iframe" style="height: 30rem !important"
                [src]="(urlWienerberger + obligationToWienerberger.order_number) | safeResource">
              </iframe>
            </div> -->
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-lg btn-outline-secondary" type="button" data-dismiss="modal">
          <ng-container i18n>Zavřít</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>