import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "./authentication.service";
import { StorageService } from "./storage.service";
import { User } from "../model/user.object";
import { Vehicle } from "../model/vehicle.object";
import { ServiceConfiguration, IS_DEMO } from "../config";
import { BrowserTools } from "../tools/BrowserTools";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class TruckManagerLayoutService {

  static CURRENT_LANGUAGE_KEY = 'lang';

  private _unAuthenticatedStaticContent = ServiceConfiguration.layout.unAuthenticatedStaticContent;
  private _authenticatedStaticContent = ServiceConfiguration.layout.authenticatedStaticContent;
  private _demoStaticContent = ServiceConfiguration.layout.demoStaticContent;

  private _maxDashboardWidth: string = ServiceConfiguration.layout.dashboardMenuWidth;
  private _minDashboardHeight: string = '0px';
  private _dashboard_visible: boolean = false;
  private _vehicleDetailHeight: string = BrowserTools.isMobileBrowser() ? ServiceConfiguration.layout.vehicleDetailHeightMobile : ServiceConfiguration.layout.vehicleDetailHeight;

  constructor(
    private _authentication: AuthenticationService,
    private _storage: StorageService,
    private _router: Router
  ) {
    // init dashboard visibility
    this._dashboard_visible = IS_DEMO ? true : false;

    // if (_storage.getItem(TruckManagerLayoutService.CURRENT_LANGUAGE_KEY)) {
    //   _translate.setLocale(_storage.getItem(TruckManagerLayoutService.CURRENT_LANGUAGE_KEY));
    // }
  }

  private _timeStamp: string = '';
  public get timeStamp(): string {
    return this._timeStamp;
  }
  public set timeStamp(value: string) {
    this._timeStamp = value;
  }

  private _login_modal_visible: boolean = false;
  get login_modal_visible(): boolean {
    return this._login_modal_visible;
  }

  private _newMessagesCount: number = 0;
  get newMessagesCount(): number {
    return this._newMessagesCount;
  }
  set newMessagesCount(value: number) {
    this._newMessagesCount = value;
  }

  private _staticContentName: string = ServiceConfiguration.layout.staticContentName;
  get staticContentName(): string {
    return this._staticContentName;
  }

  get isDashboardVisible(): boolean {
    return this._dashboard_visible;
  }

  private _dashboardMenuShown = false;
  get dashboardMenuShown(): boolean {
    return this._dashboardMenuShown;
  }

  private _dashboardMenuWidth: string = '0%';
  get dashboardMenuWidth(): string {
    return this._dashboardMenuWidth;
  }

  get user(): User {
    return this._authentication.user;
  }

  get staticContentResource(): string {
    if (this._authentication.isAuthenticated()) {
      return this._authenticatedStaticContent;
    }
    else if (this._demoStaticContent && IS_DEMO) {
      if (this.isFullscreenRoute) {
        return this._authenticatedStaticContent;
      }
      return this._demoStaticContent.replace(/%LOCALE%/g, 'cs');
    }

    return 'https://demo.truckmanager.eu/front/cs/demo.html';
  }

  // routes: all-in-one-map, dispatcher-board, messages-read
  get isFullscreenRoute(): boolean {
    return (
      this._router.url.includes('all-in-one-map') || 
      this._router.url.includes('dispatcher-board') || 
      this._router.url.includes('messages-read')
    );
  }

  private _isDashboardControlsVisible: boolean = true;
  get isDashboardControlsVisible(): boolean {
    return this._isDashboardControlsVisible;
  }
  set isDashboardControlsVisible(value: boolean) {
    this._isDashboardControlsVisible = value;
  }

  get storage(): StorageService {
    return this._storage;
  }

  toggleDashboard() {
    this._dashboard_visible = !this._dashboard_visible;
    this._dashboardMenuShown = this._dashboard_visible;
  }

  activateDashboard() {
    this._dashboard_visible = true;
  }

  hideDashboard() {
    this._dashboard_visible = false;
  }

  toggleDashboardMenu(notFromMenuItem?: boolean) {
    if (this._newMessagesCount > 0) {
      this._dashboardMenuWidth = this._maxDashboardWidth;
    } else {
      this._dashboardMenuWidth = (this._dashboardMenuWidth === this._maxDashboardWidth ? this._minDashboardHeight : this._maxDashboardWidth);
    }
  }

  closeDashboardMenu() {
    this._dashboardMenuShown = false;
  }

  isDashboardMenuVisible(): boolean {
    return this._dashboardMenuWidth === this._maxDashboardWidth;
  }

  changeDashboardMenuSection() {
    this.toggleDashboardMenu();
  }

  isAuthenticated(): boolean {
    return this._authentication.isAuthenticated();
  }

  showLoginDialog(): void {
    this._login_modal_visible = true;
  }

  loginDialogClosed(event: any = null): void {
    this._login_modal_visible = false;
  }

  getVehicleDetailHeight(vehicle?: Vehicle): string {
    return (!vehicle || vehicle.is_detail_visible ? this._vehicleDetailHeight : '0px');
  }

  // scrolling to bottom of content-left-parent div
  private _scrolledToBottomEvent: EventEmitter<boolean> = new EventEmitter();
  get scrolledToBottomEvent(): Observable<any> {
    return this._scrolledToBottomEvent.asObservable();
  }

  private _contentLeftScrolledBottom: boolean = false;
  public get contentLeftScrolledBottom(): boolean {
    return this._contentLeftScrolledBottom;
  }
  public set contentLeftScrolledBottom(value: boolean) {
    this._contentLeftScrolledBottom = value;
    if (this._contentLeftScrolledBottom) {
      this._scrolledToBottomEvent.emit(true);
    }
  }
}

  
  // get staticContentResource(): string {
  //   if (this._authentication.isAuthenticated()) {
  //     return this._authenticatedStaticContent;
  //   } 
  //   else if (IS_DEMO && this._demoStaticContent) {
  //     return this._demoStaticContent.replace(/%LOCALE%/g, this.locale);
  //   }
  //   else if (this._unAuthenticatedStaticContent) {
  //     return this._unAuthenticatedStaticContent.replace(/%LOCALE%/g, this.locale);
  //   }

  //   return 'https://demo.truckmanager.eu/front/cs/index.html';
  // }

  // setLocale(language: string) {
  //   this._translationService.setLocale(language);
  //   this._storage.setItem(TruckManagerLayoutService.CURRENT_LANGUAGE_KEY, language);
  // }

  // get translationsReady(): Boolean {
  //   return this._translationService.isReady();
  // }

  // get locale(): string {
  //   return this._translationService.locale;
  // }
  // set locale(language: string) {
  //   this.setLocale(language);
  // }

  // get locales(): Array<string> {
  //   return this._translationService.locales;
  // }
  
  // private _isDashboardFullMode: boolean = false;
  // get isDashboardFullMode(): boolean {
  //   return this._isDashboardFullMode;
  // }
  // set isDashboardFullMode(value: boolean) {
  //   this._isDashboardFullMode = value;
  // }