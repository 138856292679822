import { Component, Input, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Subscription } from "rxjs";

import { MessageAttachmentObject } from "../../model/message-attachment.object";
import { HttpClientService } from "../../service/http-client.service";
import { AuthenticationService } from "../../service/authentication.service";
import { ObligationService } from "src/app/service/obligation.service";
import { InvoiceService } from "src/app/service/invoice.service";
import { Attachment } from "src/app/model/attachment.object";

declare var $: any;

@Component({
  selector: 'div.message-attachment',
  templateUrl: './r-message-attachment.component.html',
  styleUrls: ['./r-message-attachment.component.css'],
  providers: [InvoiceService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RMessageAttachmentComponent {

  static _cache = {};
  static _subscription: Subscription;

  private _attachment: MessageAttachmentObject;
  get attachment(): MessageAttachmentObject {
    return this._attachment;
  }
  @Input()
  set attachment(attachment: MessageAttachmentObject) {
    this._attachment = attachment;
    if (this._attachment && !this.isRoutingPoint) {
      if (this._attachment.isInvoiceAttachment) {
        // console.log(this._attachment);
      }
      else if (this._attachment.isImage()) {
        this.getThumbnail();
      }
      else {
        this.getSource();
      }
    }
    // custom detection
    this.detectChanges();
  }

  private _msg_key: number = 0;
  public get msg_key(): number {
    return this._msg_key;
  }
  @Input()
  public set msg_key(value: number) {
    this._msg_key = value;
  }

  private _loadingAttachment: boolean = false;
  public get loadingAttachment(): boolean {
    return this._loadingAttachment;
  }

  private _source: string = '';
  public get source(): string {
    return this._source;
  }

  // estimate the width of attachment thumbnail
  @ViewChild('attachmentDiv', {static: false}) _attachmentDiv: ElementRef;
  public get attachmentWidth(): string {
    if (this._attachmentDiv && this._attachmentDiv.nativeElement && this._attachmentDiv.nativeElement.clientWidth) {
      if (this._attachmentDiv.nativeElement.clientWidth > 125) return '125px';
      else if (this._attachmentDiv.nativeElement.clientWidth > 90) return '90px';
      else return '60px';
    }
    return '0px';
  }

  constructor(
    private _httpClient: HttpClientService, 
    private _authService: AuthenticationService,
    private _obligationServ: ObligationService,
    private _invoiceServ: InvoiceService,
    private _cdr: ChangeDetectorRef
  ) {
    if (!RMessageAttachmentComponent._subscription) {
      RMessageAttachmentComponent._subscription = _authService.authenticationResult.subscribe(
        () => {
          RMessageAttachmentComponent._cache = {};
        }
      );
    }
    this._cdr.detach();
  }
  
  public detectChanges(): void {
    // detect changes 200 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 200
    );
  }

  public detectChanges2(): void {
    // detect changes 2000 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 2000
    );
  }

  // OBSOLETE routing_point.xml (no need to download or display)
  get isRoutingPoint(): boolean {
    return this._attachment && this._attachment.fileName.includes('routing_points.xml');
  }

  getThumbnail(): void {
    if (this._attachment.directUrl.includes('files/') 
    && !this._attachment.directUrl.includes('resp;')
    && !this._attachment.directUrl.includes('req;')) {
      let thumbUrl: string = '';
      if (this._attachment.directUrl.includes('/files/company/')) {
        thumbUrl = this._attachment.directUrl.replace('company/', 'company/thumb/');
      }
      else {
        thumbUrl = this._attachment.directUrl + '/thumb';
      }

      // window.setTimeout(
      //   () => {
      this._loadingAttachment = true;
      this.detectChanges();
      this._obligationServ.getAttachment(thumbUrl).subscribe(
        response => {
          let newBlob: any = new Blob([response], { type: response.type });
  
          if (response.type !== '' && (response.type.match(/image|pdf|text/))) {
            this._attachment.thumbnail = {
              content: URL.createObjectURL(newBlob),
              type: response.type,
              blob: newBlob   // keep blob for base 64
            };
            // create base64 via file service
            // this._filesServ.createBase64ForAttachment(attachment);
          }
          this._loadingAttachment = false;
          // custom detection
          this.detectChanges();
          this.detectChanges2();
        },
        error => {
          // set flag after error loading
          console.log(error);
          this._loadingAttachment = false;
          // custom detection
          this.detectChanges();
          this.detectChanges2();
        }
      );
      //   }, this._attachment.timeoutThumbnail
      // )
    }
  }

  getSource(): void {
    if (!this._attachment) {
      this._source = '';
      throw new Error('Attachment must be set');
    }

    let type: string = this._attachment.attachmentType;
    let key = this._attachment.getFormattedUrlString();
    if (!RMessageAttachmentComponent._cache[key] && !this._loadingAttachment) {
      this._loadingAttachment = true;
      RMessageAttachmentComponent._cache[key] = '';
      this._source = '';

      this._httpClient.map = false;
      let headers = new HttpHeaders();
      headers.append('Content-type', type);
      this._httpClient.get(key, {
        headers: headers,
        responseType: "blob"
      }).subscribe(
        (response: Blob) => {
          this._attachment.attachmentType = response.type;
          RMessageAttachmentComponent._cache[key] = window.URL.createObjectURL(response);
          this._source = RMessageAttachmentComponent._cache[key];
          this._loadingAttachment = false;
          // custom detection
          this.detectChanges();
          this.detectChanges2();
        },
        error => {
          console.log(error);
          this._loadingAttachment = false;
          // custom detection
          this.detectChanges();
          this.detectChanges2();
        }
      );
    }
    // return RMessageAttachmentComponent._cache[key];
  }

  // pdf attachment for view
  private _currentPdf: any;
  get currentPdf(): any {
    return this._currentPdf;
  }
  
  downloadAttachment(): void {
    if (this._attachment.directUrl) {
      this._loadingAttachment = true;
      // custom detection
      this.detectChanges();

      // THIS WORKS
      // let url: string = '/files/company/invoice-74409-CarrierContract.pdf?name=CarrierContract.pdf';

      this._invoiceServ.getAttachment(this._attachment.directUrl).subscribe(
        response => {
          let newBlob: any = new Blob([response], { type: (response.type ? response.type : 'application/pdf') });
          
          // set flag after successful loading
          this._loadingAttachment = false;
  
          if (response.type !== '' && (response.type.match(/pdf/))) {
            // set currentPdf property to display attachment
            this._currentPdf = {
              content: URL.createObjectURL(newBlob),
              type: 'application/pdf'
            };
          } 
          // custom detection
          this.detectChanges();
        },
        error => {
          // set flag after error loading
          this._loadingAttachment = false;
          // custom detection
          this.detectChanges();
        }
      );
    }
  }

  /***********************************************************/
  /* Modal management */
  /***********************************************************/
  openModal(): void {
    // modal default show
    (<any>$('#attachmentModal' + this._msg_key)).modal('show');
    // custom detection
    this.detectChanges();
  }
  
  private _imageRotation: number = 0;
  get imageRotation(): string {
    return 'ROTATE(' + this._imageRotation + 'deg)';
  }

  turnLeft() {
    this._imageRotation -= 90;
    // custom detection
    this.detectChanges();
  }

  turnRight() {
    this._imageRotation += 90;
    // custom detection
    this.detectChanges();
  }

}