import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { Vehicle } from "../../model/vehicle.object";
import { defaultLengthUnit } from "../../config";
import { Numbers } from "../../tools/Numbers";

@Component({
  selector: 'vehicle-interruption-component',
  templateUrl: './r-vehicle-interruption.component.html',
  styleUrls: ['./r-vehicle-interruption.component.css']
})
export class RVehicleInterruptionComponent implements OnDestroy, OnInit {

  private subscriptions: Array<Subscription> = [];

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
  }

  private _interruptionCount = 0;
  get interruptionCount(): number {
    return this._interruptionCount
  }

  private _interruptionDistance = 0;
  get interruptionDistance(): string {
    return Numbers.round(this._interruptionDistance, 2) + defaultLengthUnit;
  }

  private _loading: boolean = false;
  get loading(): boolean {
    return this._loading;
  }

  ngOnInit() {
    this._loading = true;
    this.subscriptions.push(
      this._vehicle.trackingDataLoaded.subscribe(
        trackingData => {
          this._interruptionCount = 0;
          this._interruptionDistance = 0;
          trackingData.forEach(
            tracking => {
              if (tracking.truck_status === Vehicle.INTERRUPTION) {
                this._interruptionCount++;
                this._interruptionDistance += tracking.distance;
              }
            }
          );
          this._loading = false;
        }
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe()
    );
  }
}
