import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { ExpressRoute } from '../model/express-route.object';
import { ObligationTools } from '../tools/ObligationTools';
import { IS_DEMO, ServiceConfiguration } from '../config';
import { Obligation } from '../model/obligation.object';

@Injectable({
  providedIn: 'root'
})
export class ExpressRouteService {

  private _expressRoutes: BehaviorSubject<Array<ExpressRoute>> = new BehaviorSubject([]);
  private _expressRoutesCache: Array<ExpressRoute> = [];
  
  private _loadingExpressRoutes: boolean = false;
  public get loadingExpressRoutes(): boolean {
    return this._loadingExpressRoutes;
  }
  
  private _loadingExpressRoute: boolean = false;
  public get loadingExpressRoute(): boolean {
    return this._loadingExpressRoute;
  }

  private _filters: any = {};
  private _sorts: any = {};
  private _page: number = 0;

  private _filterNames: Array<string> = [
    'tf',
    'tt',
    'year',
    'series',
    'status',
    'car_key',
    'updated',
  ];

  private _sortNames: Array<string> = [
    'number',
    'series',
    'status',
    'created'
  ];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) { }

  
  public clearExpressCache(): void {
    this._expressRoutesCache = [];
  }

  public routesFilteredTotalRecords: number = 0;
  public routesFilteredTotalPages: number = 0;

  // GET /express-route/
  getExpressRoutes(filterObj: any, sortObj: any, page: number = 0, size: number = 20): Observable<Array<ExpressRoute>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // init attributtes
      this._filters = filterObj;
      this._sorts = sortObj;
      this._page = page;
      // default url without any filter
      let url: string = ServiceConfiguration.express.api + '?page=' + this._page + '&size=' + size;

      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);
      
      this._loadingExpressRoutes = true;

      // handling full response (with headers) not just body as usual
      let httpOptions = {
        observe: 'response' as 'body'
      };

      this._http.get(url, httpOptions).subscribe(
        response => {
          if (response && response.body) {
            if (response.headers) {
              if (response.headers.get('X-TM-API-Total-Records')) {
                this.routesFilteredTotalRecords = response.headers.get('X-TM-API-Total-Records');
              }
              if (response.headers.get('X-TM-API-Total-Pages')) {
                this.routesFilteredTotalPages = response.headers.get('X-TM-API-Total-Pages');
              }
            } 
            this._expressRoutesCache = this.buildExpressRoutesFromData(response.body);
            this._expressRoutes.next(this._expressRoutesCache);
            this._loadingExpressRoutes = false;
          }
        },
        error => {
          // handle error
          console.log(error);
          this._expressRoutesCache = [];
          this._expressRoutes.next(this._expressRoutesCache);
          this._loadingExpressRoutes = false;
        }
      );
    }

    return this._expressRoutes.asObservable();
  }

  // GET /express-route/
  // same as above not using observable attribute
  getExpressRoutesRequest(filterObj: any, sortObj: any, page: number = 0, size: number = 20): Observable<Array<ExpressRoute>> {
    let result: BehaviorSubject<Array<ExpressRoute>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.express.api + '?page=' + page + '&size=' + size;

      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);

      this._loadingExpressRoutes = true;
      this._http.get(url).subscribe(
        response => {
          let express_routes = this.buildExpressRoutesFromData(response);
          result.next(express_routes);
          this._loadingExpressRoutes = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingExpressRoutes = false;
        }
      );
    }

    return result.asObservable();
  }
  
  // GET /express-route/<express_key>
  getExpressRoute(express_key: number): Observable<ExpressRoute> {
    let result: BehaviorSubject<ExpressRoute> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.express.apiExpressKey;
      url = url.replace('%EXPRESS_KEY%', express_key.toString());

      this._loadingExpressRoute = true;
      this._http.get(url).subscribe(
        response => {
          let express_routes = this.buildExpressRoute(response);
          result.next(express_routes);
          this._loadingExpressRoute = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingExpressRoute = false;
        }
      );
    }

    return result.asObservable();
  }
  
  // method for creating url string for filtering
  filterUrl(filterObj: any) {
    let result = '';
    let filterKeys: Array<string> = Object.keys(filterObj);
    filterKeys.forEach(
      key => {
        // check possible filters for express
        if (this._filterNames.includes(key)) {
          result += '&' + key + '=' + filterObj[key];
        }
      }
    );
    return result;
  }
  
  // method for creating url string for sorting
  sortUrl(sortObj: any) {
    let result = '';
    let sortKeys: Array<string> = Object.keys(sortObj);
    sortKeys.forEach(
      key => {
        // check possible sorts for express (should be always only one sort param)
        if (this._sortNames.includes(key)) {
          result += '&sort=' + key + ',' + sortObj[key];
        }
      }
    );
    return result;
  }


  // POST /express-route/
  createExpressRoute(express: ExpressRoute): Observable<ExpressRoute> {
    let result: BehaviorSubject<ExpressRoute> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = express.apiObject;
      let url: string = ServiceConfiguration.express.api;

      this._http.post(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Dokládková trasa byla úspěšně vytvořena.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          // observable next
          result.next(this.buildExpressRoute(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření dokládkové trasy - kontaktujte podporu.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }
  
  // PUT /express-route/<express_key>
  updateExpressRoute(express: ExpressRoute): Observable<ExpressRoute> {
    let result: BehaviorSubject<ExpressRoute> = new BehaviorSubject(null);
    
    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = express.apiObject;
      let url: string = ServiceConfiguration.express.apiExpressKey;
      url = url.replace('%EXPRESS_KEY%', express.express_key.toString());

      this._http.put(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Dokládková trasa byla úspěšně upravena.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          // observable next
          result.next(this.buildExpressRoute(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě dokládkové trasy - kontaktujte podporu.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // DELETE /express-route/<express_key>
  deleteExpressRoute(express: ExpressRoute): void {
    if (this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.express.apiExpressKey;
      url = url.replace('%EXPRESS_KEY%', express.express_key.toString());

      // delete request
      this._http.delete(url).subscribe(
        response => {
          // OK <=> 204 no content
          let alertSuccess: string = $localize`Dokládková trasa byla úspěšně odstraněna.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          // invoke new GET request for current filters (it refreshes list of express routes)
          this.getExpressRoutes(this._filters, this._sorts, 0).subscribe();
        },
        error => {
          // handle error
          console.log(error);
          let alertError: string = $localize`Chyba při odstranění dokládkové trasy - kontaktujte podporu.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }


  // obligations of express route
  // GET /express-route/<express_key>/obligations
  getExpressObligations(express: ExpressRoute): Observable<Array<Obligation>> {
    let result: BehaviorSubject<Array<Obligation>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.express.apiObligations;
      url = url.replace('%EXPRESS_KEY%', express.express_key.toString());

      this._http.get(url).subscribe(
        response => {
          let obligations = ObligationTools.buildObligationsFromData(response);
          result.next(obligations);
        },
        error => {
          // handle error
          console.log(error);
          result.next([]);
        }
      );
    }

    return result.asObservable();
  }

  
  // method for creating events array
  private buildExpressRoutesFromData(data: Array<any>): Array<ExpressRoute> {
    let express_routes: Array<ExpressRoute> = [];
    data.forEach(
      o => {
        let express: ExpressRoute = this.buildExpressRoute(o);
        express_routes.push(express);
      }
    );
    return express_routes;
  }

  // method for creating a single event object
  private buildExpressRoute(o: any): ExpressRoute {
    let express: ExpressRoute = new ExpressRoute();
    for (let key in o) {
      express[key] = o[key];
    }
    return express;
  }
}
