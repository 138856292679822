export class DateTools {

  private static format: string = '%day.%month.%year - %hours:%minutes';

  static formatLocaleString(date: Date, format?: string, fullNumbers?: boolean): string {
    if (!date) {
      return '';
    }
    let currentFormat = this.format;
    if (format) {
      currentFormat = format;
    }
    return currentFormat.replace(/%day/g, (date.getDate() < 10 && fullNumbers ? '0' : '') + date.getDate().toString())
      .replace(/%month/g, (date.getMonth() + 1 < 10 && fullNumbers ? '0' : '') + (date.getMonth() + 1).toString())
      .replace(/%year/g, date.getFullYear().toString())
      .replace(/%hours/g, (date.getHours() < 10 ? '0' : '') + date.getHours().toString())
      .replace(/%minutes/g, (date.getMinutes() < 10 ? '0' : '') + date.getMinutes().toString())
      .replace(/%seconds/g, (date.getSeconds() < 10 ? '0' : '') + date.getSeconds().toString());
  }

  static isoFix(date: string): Date {
    if (date && date.match(/\+\d\d$/)) {
      date += ':00';
    } else if (!date) {
      return null;
    }
    return new Date(date);
  }

  static getYesterday(date: Date): Date {
    return new Date((date.getTime() - 24 * 60 * 60000));
  }

  static getTomorrow(date: Date): Date {
    return new Date((date.getTime() + 24 * 60 * 60000));
  }

  static getHumanReadHoursAndMinutes(milisecond: number): string {
    if (isNaN(milisecond)) {
      return '--:--';
    }
    var hh = Math.floor(milisecond / 3600000);
    var mm = Math.floor((milisecond % 3600000) / 60000);
    if (hh > 999) {
      return '999+'
    }
    var displays = "";
    var negate = false;
    if (hh < 0) {
      negate = true;
    }
    hh = Math.abs(hh);
    mm = Math.abs(mm);

    if (hh < 10) {
      displays += '0';
    }
    displays += hh;
    displays += ":";

    if (mm < 10) {
      displays += '0';
    }
    displays += mm;
    if (negate) {
      displays = '-' + displays;
    }
    return displays;
  }

  // 2018-05-18T21:10:39.13202:00
  static toIso(date: Date): string {
    let offset: number = date.getTimezoneOffset() * 60 * (-1000);
    return date.toISOString().replace(
      /Z$/, (date.getTimezoneOffset() != 0 ? (offset > 0 ? '+' : '') + DateTools.getHumanReadHoursAndMinutes(offset) : 'Z')
    );
  }

  // 2018-05-18T21:10:39.132%2B0200
  static toApiFormat(date: Date): string {
    return DateTools.toIso(date).replace(/:\d\d$/, '00').replace('+', '%2B');
  }


  /* these two methods are used for keeping the same time in iso format */
  /* the methods above ignores time zone offset ang decrementing that */
  static toCustomIso(date: Date): string {
    let offset: number = date.getTimezoneOffset() * 60 * (-1000);
    let customIsoDate: Date = new Date(date.getTime() + offset);

    return customIsoDate.toISOString().replace(
      /Z$/, (date.getTimezoneOffset() != 0 ? (offset > 0 ? '+' : '') + DateTools.getHumanReadHoursAndMinutes(offset) : 'Z')
    )
  }
  
  // 2019-09-24T10:15:00.000%2B0200
  static toCustomIsoApiFormat(date: Date): string {
    return DateTools.toCustomIso(date).replace(/:\d\d$/, '00').replace('+', '%2B');
  }

  /* these two methods are used for PUT/POST format */
  // 2020-01-10T23:38:39+00
  // https://stackoverflow.com/a/17415677 - we could ignore offset mins as well
  static toIsoWithoutMilisec(date: Date): string {
    let offset: number = -date.getTimezoneOffset();
    let dif: string = offset >= 0 ? '+' : '-';

    return date.getFullYear() +
      '-' + this.decFormat(date.getMonth() + 1) +
      '-' + this.decFormat(date.getDate()) +
      'T' + this.decFormat(date.getHours()) +
      ':' + this.decFormat(date.getMinutes()) +
      ':' + this.decFormat(date.getSeconds()) +
      dif + this.decFormat(offset / 60);
      // + ':' + this.decFormat(offset % 60);
  }

  // "9" -> "09", "12" -> "12" 
  static decFormat(numb: number): string {
    let norm = Math.floor(Math.abs(numb));
    return (norm < 10 ? '0' : '') + norm;
  }

  /* these two methods are used to parsing string after GET */
  // creates date from iso (not completed) string
  static apiIsoToDate(apiIso: string): Date {
    if (!apiIso) return null;
    // date.parse needs format 2020-01-12T09:55:00+01:00
    if (apiIso.slice(-1) != 'Z') {
      apiIso += '00';
    }
    return new Date(Date.parse(apiIso));
  }

  // creates correct iso format for datetime-local inputs 2020-01-10T23:38:39(without timezone)
  // https://stackoverflow.com/a/28149561
  static isoForDatetimeInput(date: Date): string {
    if (!date) return null;
    // timezone offset in milliseconds
    var tzoffset = date.getTimezoneOffset() * 60000;
    // removes 'Z' - last character for Zulu timezone
    var localISOTime = (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
    // keep result without seconds!
    return localISOTime.substring(0, 16);
  }
}
