import { DateTools } from "../tools/DateTools";

export class WarehouseEvent {
  private _event_key: number;
  get event_key(): number {
    return this._event_key;
  }
  set event_key(value: number) {
    this._event_key = value;
  }
  
  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }
  
  private _city_key: string;
  get city_key(): string {
    return this._city_key;
  }
  set city_key(value: string) {
    this._city_key = value;
  }
  
  private _containers_in: number;
  get containers_in(): number {
    return this._containers_in;
  }
  set containers_in(value: number) {
    this._containers_in = value;
  }
  
  private _containers_out: number;
  get containers_out(): number {
    return this._containers_out;
  }
  set containers_out(value: number) {
    this._containers_out = value;
  }

  private _length: number;
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
  }

  private _order_key: number;
  get order_key(): number {
    return this._order_key;
  }
  set order_key(value: number) {
    this._order_key = value;
  }

  private _order_number: string;
  get order_number(): string {
    return this._order_number;
  }
  set order_number(value: string) {
    this._order_number = value;
  }

  private _origin: string;
  get origin(): string {
    return this._origin;
  }
  set origin(value: string) {
    this._origin = value;
  }
  
  private _pos_gps: string;
  get pos_gps(): string {
    return this._pos_gps;
  }
  set pos_gps(value: string) {
    this._pos_gps = value;
  }

  private _time: string;
  get time(): string {
    return this._time;
  }
  set time(value: string) {
    this._time = value;
    
    if (value) {
      this.timeDate = DateTools.apiIsoToDate(value);
      this.timeInput = DateTools.isoForDatetimeInput(DateTools.apiIsoToDate(value));
      // HOTFIX + hours - remove after api update
      // this.timeDate.setHours(this.timeDate.getHours() + 2);
    }
  }
  
  // custom time objects
  private _timeDate: Date;
  get timeDate(): Date {
    return this._timeDate;
  }
  set timeDate(value: Date) {
    this._timeDate = value;
  }

  private _timeInput: string;
  get timeInput(): string {
    return this._timeInput;
  }
  set timeInput(value: string) {
    this._timeInput = value;
  }

  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }

  private _ware_pcs: number;
  get ware_pcs(): number {
    return this._ware_pcs;
  }
  set ware_pcs(value: number) {
    this._ware_pcs = value;
  }

  private _ware_type: string;
  get ware_type(): string {
    return this._ware_type;
  }
  set ware_type(value: string) {
    this._ware_type = value;
  }

  private _weight: number;
  get weight(): number {
    return this._weight;
  }
  set weight(value: number) {
    this._weight = value;
  }

  public get apiObject(): any {
    let data = {
      car_key: this.car_key,
      containers_in: null, // this.containers_in,
      containers_out: null, // this.containers_out,
      length: null, // this.length,
      order_key: this.order_key,
      order_number: this.order_number,
      pos_gps: this.pos_gps,
      type: this.type,
      ware_pcs: this.ware_pcs,
      ware_type: this.ware_type,
      weight: this.weight
    };
    
    if (this.timeInput) {
      data['time'] = DateTools.toIsoWithoutMilisec(new Date(this.timeInput));
    }

    return data;
  }
}