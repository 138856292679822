import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IS_DEMO, ServiceConfiguration } from '../config';
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { Email } from '../model/email.object';

@Injectable({
  providedIn: 'root'
})
export class EmailingService {

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) { }

  
  /**********************************************************************************/
  /* Invoice emailing service methods */
  /**********************************************************************************/
  /* Methods for email invoicing */
  private _sendingEmail: boolean = false;
  public get sendingEmail(): boolean {
    return this._sendingEmail;
  }

  private _sendCompleted: boolean = false;
  public set sendCompleted(value: boolean) {
    this._sendCompleted = value;
  }
  public get sendCompleted(): boolean {
    return this._sendCompleted;
  }

  private _sendOK: boolean = false;
  public set sendOK(value: boolean) {
    this._sendOK = value;
  }
  public get sendOK(): boolean {
    return this._sendOK;
  }

  // send email
  sendEmail(emailObj: any, showAlert: boolean = true): Observable<any> {
    let email: BehaviorSubject<any> = new BehaviorSubject(null);

    console.log(emailObj);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._sendingEmail = true;
      
      // post data
      let url: string = ServiceConfiguration.emailing.send;
      this._http.post(url, emailObj).subscribe(
        response => {
          console.log(response);
          this._sendingEmail = false;
          this._sendCompleted = true;
          this._sendOK = true;
          if (showAlert) {
            // success alert
            let alertSuccess: string = $localize`Email byl úspěšně odeslán.`;
            this._notificationService.alert(alertSuccess, 'success', 3500);
            email.next(response);
          }
        },
        error => {
          console.log(error);
          this._sendingEmail = false;
          this._sendCompleted = true;
          if (showAlert) {
            // error alert
            let alertError: string = $localize`Nastala chyba při odesílání emailu.`;
            this._notificationService.alert(alertError, 'error', 3500);
          }
        }
      );
    }
    
    return email.asObservable();
  }

  // send email with file in attachments
  sendEmailWithFile(emailObj: any): Observable<any> {
    let email: BehaviorSubject<any> = new BehaviorSubject(null);

    console.log(emailObj);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._sendingEmail = true;
      
      // post data
      let url: string = ServiceConfiguration.emailing.sendFile;
      this._http.post(url, emailObj).subscribe(
        response => {
          console.log(response);
          this._sendingEmail = false;
          this._sendCompleted = true;
          this._sendOK = true;
          // success alert
          let alertSuccess: string = $localize`Email byl úspěšně odeslán.`;
          this._notificationService.alert(alertSuccess, 'success', 3500);
          email.next(response);
        },
        error => {
          console.log(error);
          this._sendingEmail = false;
          this._sendCompleted = true;
          // error alert
          let alertError: string = $localize`Nastala chyba při odesílání emailu.`;
          this._notificationService.alert(alertError, 'error', 3500);
        }
      );
    }
    
    return email.asObservable();
  }


  // method for email header
  getEmailHeader(message_id: string): Observable<Email> {
    let emailHeader: BehaviorSubject<Email> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.header.replace('%MESSAGE_ID', message_id);
      this._http.get(url).subscribe(
        response => {
          let header: Email = this.buildEmail(response);
          emailHeader.next(header);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return emailHeader.asObservable();
  }

  // method for loading detail html page about email sent
  getEmailShow(message_id: string): Observable<any> {
    let showHtml: BehaviorSubject<any> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.show.replace('%MESSAGE_ID', message_id);
      // let headers: HttpHeaders = new HttpHeaders();

      this._http.get(url, {responseType: "text"}).subscribe(
        response => {
          // console.log(response);
          showHtml.next(response);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return showHtml.asObservable();
  }

  buildEmail(header: any): Email {
    let email: Email = new Email();
    for (let key in header) {
      email[key] = header[key];
    }

    return email;
  }
}
