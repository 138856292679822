<div class="message-reply transitions open">
  <form (submit)="sendMessage($event)" class="row">
    <!-- <div class="col-{{attachment && isAttachmentImage() ? 6 : 9}}">
      <textarea name="message" [(ngModel)]="message" style="overflow-wrap: break-word; white-space: pre-wrap"></textarea>
    </div> -->
    <div *ngIf="!attachment" class="col-7">
      <textarea name="message" [(ngModel)]="message" placeholder="Napište zprávu"
      style="resize:vertical; overflow-wrap:break-word; white-space:pre-wrap"></textarea>
    </div>

    <div *ngIf="attachment && isAttachmentImage()" class="col-7">
      <img [src]="attachment_source|safeResource" alt="Náhled" height="80px" class="float-right"
      ngbTooltip="Malý náhled nahraného souboru" container="body" placement="left auto"/>
    </div>

    <div class="col-5">
      <a *ngIf="uploading" class="btn btn-primary">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only"></span>
        </div>
      </a>
      <div *ngIf="!uploading">
        <div *ngIf="!attachment">
          <div class="text-center mb-2">
            <button class="btn btn-outline-secondary px-1 py-1" type="button" style="width: 90%" 
            [ngbPopover]="messagePopover" container="body" placement="left auto">
              <ng-container i18n>Vzory zpráv</ng-container>
            </button>
            <ng-template #messagePopover>
              <a *ngFor="let pattern of messagesPatterns; let i=index" (click)="setPattern(pattern)"
              class="d-block" href="javascript:void(0)">
                {{i+1}}. {{pattern}}
              </a>
            </ng-template>
          </div>
          <div class="d-flex justify-content-center">
            <div class="file-input-container mr-1 px-1 py-1" style="width: 45%" 
            ngbTooltip="Přiložit soubor" placement="left auto" container="body">
              <input type="file" #fileInput (change)="attachmentChange($event)"/>
              <span class="d-none d-sm-block" i18n>Soubor</span>
            </div>
            <button type="submit" class="btn btn-success ml-1 px-1 py-1" style="width: 45%"
            ngbTooltip="Odeslat textovou zprávu" placement="left auto" container="body">
              <ng-container i18n>Odeslat</ng-container>
            </button>
          </div>
        </div>
        <div *ngIf="attachment">
          <a class="btn btn-success mx-1 px-1 py-1" (click)="sendAttachment()"
          ngbTooltip="Odeslat soubor" placement="left auto" container="body">
            <img src="assets/icons-new/upload.svg" alt="upload" height="17px"/> 
            <ng-container i18n>Odeslat</ng-container>
          </a>
          <a class="btn btn-outline-secondary mx-1 px-1 py-1" (click)="attachmentCancel()">
            <ng-container i18n>Zrušit</ng-container>
          </a>
        </div>
      </div>
    </div>
  </form>
</div>