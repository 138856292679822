
export class AddressBookItemCar {
  private _book_dest_key: number;
  public get book_dest_key(): number {
    return this._book_dest_key;
  }
  public set book_dest_key(value: number) {
    this._book_dest_key = value;
  }
  
  private _book_key: number;
  public get book_key(): number {
    return this._book_key;
  }
  public set book_key(value: number) {
    this._book_key = value;
  }
  
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _adr: string = null;
  public get adr(): string {
    return this._adr;
  }
  public set adr(value: string) {
    this._adr = value;
  }
  
  private _countries: Array<string> = [];
  public get countries(): Array<string> {
    return this._countries;
  }
  public set countries(value: Array<string>) {
    this._countries = value;
  }
  
  public includesCountry(country: string): boolean {
    if (this._countries && this._countries.includes(country)) {
      return true;
    }
    return false;
  }

  public setCountry(country: string): void {
    if (this.includesCountry(country)) {
      this._countries = this._countries.filter(c => c != country);
    }
    else {
      this._countries.push(country);
    }
  }
  public readonly CAR_DEST_COUNTRIES: any = {
    'AL': 'AL-' + $localize`Albánie`,
    'AD': 'AD-' + $localize`Andora`,
    'AM': 'AM-' + $localize`Arménie`,
    'AT': 'AT-' + $localize`Rakousko`,
    'AZ': 'AZ-' + $localize`Ázerbajdžán`,
    'BE': 'BE-' + $localize`Belgie`,
    'BG': 'BG-' + $localize`Bulharsko`,
    'BA': 'BA-' + $localize`Bosna a Hercegovina`,
    'BY': 'BY-' + $localize`Bělorusko`,
    'CY': 'CY-' + $localize`Kypr`,
    'CZ': 'CZ-' + $localize`Česká republika`,
    'DE': 'DE-' + $localize`Německo`,
    'DK': 'DK-' + $localize`Dánsko`,
    'EE': 'EE-' + $localize`Estonsko`,
    'EL': 'EL-' + $localize`Řecko`,
    'ES': 'ES-' + $localize`Španělsko`,
    'FI': 'FI-' + $localize`Finsko`,
    'FL': 'FL-' + $localize`Lichtenštejnsko`,
    'FR': 'FR-' + $localize`Francie`,
    'GB': 'GB-' + $localize`Velká Británie`,
    'GE': 'GE-' + $localize`Gruzie`,
    'HR': 'HR-' + $localize`Chorvatsko`,
    'HU': 'HU-' + $localize`Maďarsko`,
    'CH': 'CH-' + $localize`Švýcarsko`,
    'IE': 'IE-' + $localize`Irsko`,
    'IT': 'IT-' + $localize`Itálie`,
    'KZ': 'KZ-' + $localize`Kazachstán`,
    'LT': 'LT-' + $localize`Litva`,
    'LU': 'LU-' + $localize`Lucembursko`,
    'LV': 'LV-' + $localize`Lotyšsko`,
    'MD': 'MD-' + $localize`Moldavsko`,
    'ME': 'ME-' + $localize`Černá hora`,
    'MK': 'MK-' + $localize`Severní Makedonie`,
    'MT': 'MT-' + $localize`Malta`,
    'NL': 'NL-' + $localize`Nizozemsko`,
    'NO': 'NO-' + $localize`Norsko`,
    'PL': 'PL-' + $localize`Polsko`,
    'PT': 'PT-' + $localize`Portugalsko`,
    'RO': 'RO-' + $localize`Rumunsko`,
    'SM': 'SM-' + $localize`San Marino`,
    'RU': 'RU-' + $localize`Rusko`,
    'SE': 'SE-' + $localize`Švédsko`,
    'SI': 'SI-' + $localize`Slovinsko`,
    'SK': 'SK-' + $localize`Slovensko`,
    'RS': 'RS-' + $localize`Srbsko`,
    'TR': 'TR-' + $localize`Turecko`,
    'UA': 'UA-' + $localize`Ukrajina`,
    'XK': 'XK-' + $localize`Kosovo`
  };
  
  private _sizes: Array<string> = [];
  public get sizes(): Array<string> {
    return this._sizes;
  }
  public set sizes(value: Array<string>) {
    this._sizes = value;
  }
  
  public includesSize(size: string): boolean {
    if (this._sizes && this._sizes.includes(size)) {
      return true;
    }
    return false;
  }

  public setSize(size: string): void {
    if (this.includesSize(size)) {
      this._sizes = this._sizes.filter(s => s != size);
    }
    else {
      this._sizes.push(size);
    }
  }
  
  public readonly CAR_DEST_SIZES: any = {
    'Q': $localize`Autobus`,
    'A': $localize`Autojeřáb`,
    'V': $localize`Dodávka`,
    'R': $localize`Minibus`,
    'P': $localize`Osobní auto`,
    'Y': $localize`PickUp`,
    'W': $localize`Tahač`
  };
  
  private _types: Array<string> = [];
  public get types(): Array<string> {
    return this._types;
  }
  public set types(value: Array<string>) {
    this._types = value;
  }

  public includesType(type: string): boolean {
    if (this._types && this._types.includes(type)) {
      return true;
    }
    return false;
  }

  public setType(type: string): void {
    if (this.includesType(type)) {
      this._types = this._types.filter(t => t != type);
    }
    else {
      this._types.push(type);
    }
  }
  
  public readonly CAR_DEST_TYPES: any = {
    'T': $localize`Cisterna`,
    'C': $localize`Cívky / Mulda`,
    'F': $localize`Frigo`,
    'I': $localize`Izoterm`,
    'J': $localize`Jumbo`,
    'K': $localize`Kontejner`,
    'Z': $localize`Lowdeck`,
    'H': $localize`Podvalník`,
    'U': $localize`Odtah vozidel`,
    'N': $localize`Plachta`,
    'O': $localize`Plato`,
    '1': $localize`Posuvná podlaha`,
    'E': $localize`Přeprava aut`,
    '2': $localize`Přeprava dřeva`,
    'G': $localize`Přeprava zvířat`,
    'S': $localize`Silo`,
    'D': $localize`Sklápěcí`,
    'B': $localize`Skříň`,
    'X': $localize`Speciál`,
    'M': $localize`Stěhovací`,
    'L': $localize`Tautliner`
  };
  
  private _tonnage: number = null;
  public get tonnage(): number {
    return this._tonnage;
  }
  public set tonnage(value: number) {
    this._tonnage = value;
  }

  get apiObject(): any {
    // Greece (GR -> EL) - we need 'EL' in db
    // google charts supports only GR
    let index = this._countries.indexOf('GR');
    if (index !== -1) {
      this._countries[index] = 'EL';
    }

    return {
      adr: this._adr,
      countries: this._countries,
      tonnage: this._tonnage,
      sizes: this._sizes,
      types: this._types
    }
  }
}